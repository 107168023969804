import React from 'react';
import {Link} from "react-router-dom";
import './Footer.css';
import Parse from "parse";

export const Footer = () => {
    const currentUser = Parse.User.current();
    return (
        <>
            {/*{currentUser &&*/}
            <div className="siteFooterWrap">
                <div className="footer-list">
                    <Link to="/terms">
                        <p className="footer-list-child">TERMS OF USE</p>

                    </Link>  <span style={{marginTop:'-3px', marginLeft: '15px'}}>|</span>
                    <Link to="/privacy">
                        <p className="footer-list-child">PRIVACY POLICY </p>

                    </Link> <span style={{marginTop:'-3px', marginLeft: '15px'}}>|</span>
                    {/*<Link to="/amlcft">*/}
                    {/*    <p className="footer-list-child">(AML/CFT) POLICY</p>*/}
                    {/*</Link>*/}
                    <a href="https://defi.fanbase.io/faq" target="_blank" onClick={()=> window.scrollTo(0, 0)} >
                        <p className="footer-list-child">QUESTIONS</p>
                    </a>
                    {/*<Link to="/about" onClick={()=> window.scrollTo(0, 0)} >*/}
                    {/*    <p className="footer-list-child">QUESTIONS</p>*/}
                    {/*</Link>*/}
                </div>
            </div>
            {/*}*/}
        </>

    );
};
