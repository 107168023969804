// import jsPDF from "jspdf";
// import 'jspdf-autotable';

export const generatePdfTable = ({mail, account}) => {
    // const doc = new jsPDF({
    //     orientation: 'landscape',
    // });
    // doc.setFont('Roboto');
    // doc.setFontSize(16);
    // doc.text(14, 15, 'Fanbase Report: ' + new Date().toString().substring(0, 25));
    // doc.text(14, 22, `User: ${mail}`);
    // doc.text(14, 30, `Account: ${account}`);
    // doc.setFont('Roboto', 'bold')
    // doc.text(14, 40, `SUMMARY:`);
    // doc.autoTable({
    //     startY: 43,
    //     head: [['Year', 'Total Buys (USD)', 'Total Quantity Sold (ZRX)', 'Total Sells (USD)', 'Total Gains/Losses (USD)']],
    //     body: [
    //         ['2019', '25851.42', '116335.06', '28093.04', '2241.62'],
    //         ['2019', '25851.42', '116335.06', '28093.04', '2241.62'],
    //         ['2019', '25851.42', '116335.06', '28093.04', '2241.62'],
    //         ['2019', '25851.42', '116335.06', '28093.04', '2241.62'],
    //     ],
    // })
    // const finalY1 = doc.previousAutoTable.finalY;
    // doc.setFont('Roboto', 'bold')
    // doc.text(`DETAILS:`, 14, finalY1 + 20);
    // doc.setFont('Roboto', 'normal')
    // doc.text(`BUYS:`, 14, finalY1 + 30);
    // doc.autoTable({
    //     startY: finalY1 + 33,
    //     head: [['Received Transaction ID', 'Received Date', 'Received Description', 'Received Price Per Coin (USD)', 'Total Gains/Losses (USD)']],
    //     body: [
    //         ['5cd98f11f955af00216e39f9', '2019-05-13 08:36:40 -0700', 'Bought using PNC - Standard Checking ******8768', '0.30', '5000.00'],
    //         ['5cd98f11f955af00216e39f9', '2019-05-13 08:36:40 -0700', 'Bought using PNC - Standard Checking ******8768', '0.30', '5000.00'],
    //         ['5cd98f11f955af00216e39f9', '2019-05-13 08:36:40 -0700', 'Bought using PNC - Standard Checking ******8768', '0.30', '5000.00'],
    //         ['5cd98f11f955af00216e39f9', '2019-05-13 08:36:40 -0700', 'Bought using PNC - Standard Checking ******8768', '0.30', '5000.00'],
    //     ],
    // })
    // const finalY2 = doc.previousAutoTable.finalY;
    // doc.text(`SELLS:`, 14, finalY2 + 10);
    // doc.autoTable({
    //     startY: finalY2 + 13,
    //     head: [['Sent Transaction ID', 'Sent Date', 'Sent Description', 'Sent Price Per Coin (USD)', 'Sent Quantity (FANBOY7)', 'Sent Total (USD']],
    //     body: [
    //         ['5cdc6213f824fe0014e106e2', '2019-05-15 12:01:39 -0700', 'Sent to Fanbase', '0.35', '16741.76608364', '5878.33'],
    //         ['5cdc6213f824fe0014e106e2', '2019-05-15 12:01:39 -0700', 'Sent to Fanbase', '0.35', '16741.76608364', '5878.33'],
    //         ['5cdc6213f824fe0014e106e2', '2019-05-15 12:01:39 -0700', 'Sent to Fanbase', '0.35', '16741.76608364', '5878.33'],
    //         ['5cdc6213f824fe0014e106e2', '2019-05-15 12:01:39 -0700', 'Sent to Fanbase', '0.35', '16741.76608364', '5878.33'],
    //     ],
    // });
    //
    // const finalY3 = doc.previousAutoTable.finalY;
    // doc.text(`GAIN / LOSS:`, 14, finalY3 + 10);
    // doc.autoTable({
    //     startY: finalY3 + 13,
    //     head: [['Gains / Losses', 'Short / Long Term Capital Gains']],
    //     body: [
    //         ['878.33', 'Short Term (held less than 1 yr)'],
    //         ['878.33', 'Short Term (held less than 1 yr)'],
    //         ['878.33', 'Short Term (held less than 1 yr)'],
    //         ['878.33', 'Short Term (held less than 1 yr)'],
    //     ],
    // })
    // doc.save('Transactions.pdf');
};
