import React from 'react';
import ColorPickerBody from './Body';
import './style.css';

class ColorPicker extends React.Component {
  /* static propTypes = {
    color: PropTypes.string.isRequired, // it's only a hex string - "#000000", color.length = 7;
    pos: PropTypes.string, // 'top' || 'bottom'
    onChange: PropTypes.func.isRequired // example: (hex) => { this.setState({ textColor: hex }) };
  } */

  state = { isOpen: false, rect: null };

  render(){
    const { color } = this.props;
    const style = {
      background: (color) ? color : '#ff0000'
    };

    return (
      <div className='cp-content'>
        {this.state.isOpen && <ColorPickerBody {...this.props} rect={this.state.rect} handleClose={this.handleClose}/>}
        <button 
          className='cp-input-body'
          ref={(ref) => this.input = ref}
          onClick={(e) => this.setState({ isOpen: true, rect: this.input.getBoundingClientRect() })}
        >
          <div className='cp-input-color' style={style}/>
        </button>
      </div>
    )
  }

  handleClose = (e) => {
    this.setState({ isOpen: false });
    window.__cpOpen = false;
  }
}

export default ColorPicker;