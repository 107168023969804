import ethClient from "../../app/eth_client";

const initialState = {
    tokens: [],
    projects:[],
    walletAddresses:[],
    tickets:[],
    orders:[],
    percetnageLevels:[],
    mainWalletAddress: ethClient.eth.getActiveAccountAddress(),
    fnbd:{balance:0},
    tixProjects:[],
    eth:{balance:0},
    cart:[],
    banners: [

    ],
    cartNew: []
};
//

const reducer = (state = initialState, action) => {
    // console.log(action, "--------------action", action.x)
    switch (action.type) {
        case 'GET_WALLET':
            return { ...state, };
        case 'INITIAL_CARD':
            return state;
        case 'CHANGE_CARD':
            return { ...state, cart: action.payload };
            case 'CHANGE_BANNERS':
            return { ...state, banners: action.payload };
        default:
            return state;
    }
};
export default reducer;




//DEPRICATED =======>>>

// console.log(actions, "--------------actions")
// const reducer =(state={initialState}, action)=> {
//     console.log(initialState, "--------------action")
// //     // [actions.setERC721Tokens]: (state, tokens) => {
// //     //     console.log('----------reducer')
// //     //     return {
// //     //         ...state,
// //     //         tokens: tokens
// //     //     };
// //     // },
// //     // [actions.setProjects]:(state, projects) => {
// //     //     return {
// //     //         ...state,
// //     //         projects
// //     //     };
// //     // },
// //     // [actions.setWalletAddresses]:(state, walletAddresses) => {
// //     //     // localStorage.setItem("walletAddress", walletAddress);
// //     //     return {
// //     //         ...state,
// //     //         walletAddresses,
// //     //         mainWalletAddress:walletAddresses[0]
// //     //     };
// //     // },
// //     [actions.setMainWalletAddress]:(state, mainWalletAddress) =>{
// //         console.log("mainWalletAddress ----> ", mainWalletAddress);
// //         return {
// //             ...state,
// //             mainWalletAddress
// //         }
// //     },
//
// // const reducer = (state
// //                    = initialState, action) => {
//
//
//     // [actions.setAuctionDetail]:(state, data) => {
// //     //     const {auctionDetail, pId} = data;
// //     //     // console.log("pId ====>", pId);
// //     //     // console.log("auctionDetail ====>", auctionDetail);
// //     //     let tokens = state.tokens;
// //     //     tokens.forEach((ele, index) => {
// //     //         if(ele.id == pId){
// //     //             if(ele.auctions == undefined) ele.auctions = {};
// //     //             ele.auctions = {...ele.auctions, [auctionDetail.id]:auctionDetail};
// //     //             tokens[index] = ele;
// //     //         }
// //     //     });
// //     //     return {...state, tokens:Array.from(tokens)};
// //     // }
// //     [actions.setMainWalletAddress]:(state, data) =>{
// //
// // }
// // }
// // export default reducer;
//
// //
// //     // [actions.setWalletJSON]:(state, data) =>{
// //     //     console.log("data ===> ", data);
// //     //     return {
// //     //         ...state,
// //     //         walletJSON:data.walletJSON
// //     //     }
// //     // },
// //     // [actions.setUsersTickets]:(state, data) => {
// //     //     console.log("TICKET --->", data.tickets);
// //     //     return {
// //     //         ...state,
// //     //         tickets:data.tickets
// //     //     }
// //     // },
// //     // [actions.setPercentageLevels]:(state, data) => {
// //     //     console.log("data ====> ", data);
// //     //     return {
// //     //         ...state,
// //     //         percetnageLevels:data.levels
// //     //     }
// //     // },
// //     // [actions.setCreatedOrders]:(state, data) => {
// //     //     console.log("data ====> ", data);
// //     //     return {
// //     //         ...state,
// //     //         orders:data.orders
// //     //     }
// //     // },
// //     // [actions.setFNBDCoinDetail]:(state, data) => {
// //     //     console.log("data ====> usdw ", data);
// //     //     return {
// //     //         ...state,
// //     //         fnbd:data.fnbd
// //     //     }
// //     // },
// //     // [actions.setTixProjects]:(state, data) => {
// //     //     const tixProjects = state.tixProjects.concat(data.projects);
// //     //     return {
// //     //         ...state,
// //     //         tixProjects
// //     //     }
// //     // },
//
//     // switch (action.type) {
//     //     case 'GET_NEWS':
//     //         return { ...state, loading: true };
//     //     case 'NEWS_RECEIVED':
//     //         return { ...state, news: action.json[0], loading: false }
//     //     default:
//     //         return state;
//     // }
//
//
//
//   //   setMainWalletAddress:(state={initialState}, data) => {
//   //       // const {eth, fnbd} = data;
//   //       console.log("__________________setUser_ETH_FNBD_blance____________________");
//   //       // console.log(eth);
//   //       // console.log(fnbd);
//   //       return {
//   //           ...state,
//   //           // eth,
//   //           // fnbd
//   //       }
//   // },
//     switch (action.type) {
//         case 'GET_NEWS':
//             return { ...state};
//         default:
//             return state;
//     }
//
// //     // [actions.setAuctionDetail]:(state, data) => {
// //     //     const {auctionDetail, pId} = data;
// //     //     // console.log("pId ====>", pId);
// //     //     // console.log("auctionDetail ====>", auctionDetail);
// //     //     let tokens = state.tokens;
// //     //     tokens.forEach((ele, index) => {
// //     //         if(ele.id == pId){
// //     //             if(ele.auctions == undefined) ele.auctions = {};
// //     //             ele.auctions = {...ele.auctions, [auctionDetail.id]:auctionDetail};
// //     //             tokens[index] = ele;
// //     //         }
// //     //     });
// //     //     return {...state, tokens:Array.from(tokens)};
// //     // }
// };
//
// export default reducer;
