"use strict";

const axios = require("axios");
const configs = require("./configs");
const { IsObjectExists, ValidationArray } = require("./helpers");
const { ethers } = require("ethers");

export const erc721Factory = (_networkEndPoint, _contractAddress) => {
  const provider = new ethers.providers.JsonRpcProvider(_networkEndPoint);
  return {
    /**
     * Get last block number
     * @returns {Promise<number>} Last block number
     */
    getCurBlock: async () => {
      return await provider.getBlockNumber();
    },
    /**
     * Get balance of token' holder address
     * @param {string} address Holder address
     * @returns {number} Balance
     */
    getBalance: async (address) => {
      const newContract = new ethers.Contract(
        _contractAddress,
        configs.CONTRACT_ABI.ERC721_ABI,
        provider
      );

      const balance = await newContract.balanceOf(address);

      return balance.toNumber();
    },

    /**
     * Get token Id(unit256) from HolderAddress by index in list of all having tokens
     * @param {string} ownerAddress Holder address
     * @param {number} _index Index
     * @returns {string} Token Id (unit256) 
     */
    getTokenOfOwnerByIndex: async (ownerAddress, _index) => {
      const newContract = new ethers.Contract(
        _contractAddress,
        configs.CONTRACT_ABI.ERC721_ABI,
        provider
      );

      const holderBalance = await newContract.balanceOf(ownerAddress);

      if (typeof _index === "number") {
        const checkHolderQuantity = Number(holderBalance);
        const checkIndex = Number(_index);
        if (checkIndex >= checkHolderQuantity) {
          _index = checkHolderQuantity - +1;
        }
        const resTokenId = await newContract.tokenOfOwnerByIndex(
          ownerAddress,
          _index
        );
        return resTokenId.toString();
      } else return "";
    },

    /**
     * Return structure of Token metadata
     * @typedef {Object} TokenMetadata
     * @property {string} name Name of unique item
     * @property {string} description Full description of unique item
     * @property {string} image URL for image of unique item
     */
    /**
     * Return structure of token information
     * @typedef {Object} TokenInfo
     * @property {string} address Token Id 
     * @property {string} name Token Name
     * @property {string} symbol Token Symbol
     * @property {string} URI URL for download token-metadata
     * @property {TokenMetadata} metadataJSON Metadata of token ERC-721
     */

    /**
     * Get information about unique ERC-721 token + metadata
     * @param {string} _id ERC-721 token Id (uint256)
     * @returns {TokenInfo}
     */
    getTokenMetadata: async (_id) => {
      const newContract = new ethers.Contract(
        _contractAddress,
        configs.CONTRACT_ABI.ERC721_ABI,
        provider
      );
      const name = await newContract.name();
      const symbol = await newContract.symbol();
      const tokenURI = await newContract.tokenURI(_id);

      let metaData;

      try {
        if (IsObjectExists(tokenURI)) {
          const response = await axios.get(tokenURI, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          });
          if (IsObjectExists(response)) metaData = response.data;
        }
      } catch (e) {
        console.debug(`Err axios: ${e.message}`);
      } finally {
        metaData = null;
      }

      return {
        address: _id,
        name: name,
        symbol: symbol,
        URI: tokenURI,
        metadataJSON: IsObjectExists(metaData)
          ? {
            name: metaData.name,
            description: metaData.description,
            image: metaData.image,
          }
          : "",
      };
    },

    /**
     * Get holder address by token Id
     * @param {string} _id ERC-721 token Id (uint256)
     * @returns {string}
     */
    getOwnerOf: async (_id) => {
      const newContract = new ethers.Contract(
        _contractAddress,
        configs.CONTRACT_ABI.ERC721_ABI,
        provider
      );

      if (IsObjectExists(_id)) {
        return await newContract.ownerOf(_id);
      } else return "";
    },

    /**
     * Return structure of mined transaction
     * @typedef {Object} TransactionResult
     * @property {string} from Address from
     * @property {string} to Receiver address
     * @property {string} gasUsed blockchain sysinfo(never mind)
     * @property {number} blockNumber Number of current block
     * @property {string} blockHash Hash-Header of current block
     * @property {string} transactionHash Transaction hash
     * @property {string} contractAddress Contract address
     * @property {Array} logs Transaction logs
     */
    /**
     * Send one token ERC-721 from current account to receiver
     * @param {string} privateKey Wallet private key from Mnemonic
     * @param {string} addressFrom You selected account address
     * @param {string} receiverAddress Address Receiver of token
     * @param {string} tokenId Selected token id (uint256)
     * @param {number} gasPrice Selected gas price (Gwei)
     * @param {string} _data by default '0x00'
     * @returns {Promise<TransactionResult>} Transaction object with fields (from, to, gasUsed, blockNumber, blockHash, transactionHash, contractAddress, logs)
     */
    safeTransferFrom: async (
      privateKey,
      addressFrom,
      receiverAddress,
      tokenId,
      gasPrice,
      _data
    ) => {
      var wallet = new ethers.Wallet(privateKey, provider);

      if (IsObjectExists(receiverAddress) && IsObjectExists(tokenId)) {
        if (receiverAddress.length > 20) {
          const newContract = new ethers.Contract(
            _contractAddress,
            configs.CONTRACT_ABI.ERC721_ABI,
            wallet
          );
          const calcGasPrice = ethers.utils.parseUnits(`${gasPrice}`, "gwei");

          console.debug(
            "calcGasPrice: ",
            calcGasPrice.toString(),
            "hex: ",
            calcGasPrice.toHexString()
          );

          //const ContractWithSigner = newContract.connect(wallet);
          try {
            const tx = await newContract.safeTransferFrom(
              addressFrom,
              receiverAddress,
              tokenId,
              _data,
              {
                gasPrice: calcGasPrice.toHexString(),
              }
            );
            console.debug("not mined tx: ", tx);
            const _txResult = await tx.wait();
            if (IsObjectExists(_txResult)) {
              return {
                from: _txResult.from,
                to: _txResult.to,
                gasUsed: IsObjectExists(_txResult.gasUsed)
                  ? _txResult.gasUsed.toString()
                  : "",
                blockNumber: _txResult.blockNumber,
                blockHash: _txResult.blockHash,
                transactionHash: _txResult.transactionHash,
                contractAddress: IsObjectExists(_txResult.contractAddress)
                  ? _txResult.contractAddress.toString()
                  : "",
                logs:
                  ValidationArray(_txResult.logs) == true ? _txResult.logs : [],
              };
            } else return {};
          } catch (e) {
            console.error(`${e.message}`);
          }
        }
      }
    },
  };
};

/*
TransactionResponse{
    hash: string;
    blockNumber?: number;
    blockHash?: string;
    timestamp?: number;
    confirmations: number;
    from: string;
    raw?: string;
    wait: (confirmations?: number) => Promise<TransactionReceipt>;
}
 */
// //ABI MUST BE => ARRAY!
// const funcABI_safeTransferFrom = configs.CONTRACT_ABI.ERC721_ABI.filter(
//   (item) => {
//     if (
//       item.type === "function" &&
//       item.name === "safeTransferFrom" &&
//       item.inputs.filter(
//         (e) => e.name === "_data" && e.type === "bytes"
//       ).length > 0
//     )
//       return item;
//   }
// );
// //console.debug(funcABI_safeTransferFrom);
// //Encoding method 'safeTransferFrom'
// const iface = new ethers.utils.Interface(funcABI_safeTransferFrom);
// const encodedABI = iface.encodeFunctionData("safeTransferFrom", [
//   addressFrom,
//   receiverAddress,
//   tokenId,
//   _data,
// ]);
// //console.debug(`encodedABI =>`, encodedABI);
// const nonce = await wallet.getTransactionCount("pending");
// //console.debug(`nonce =>`, nonce);
// //convert Gwei to Wei (* 10^^9)
// const calcGasPrice = ethers.utils.parseUnits(`${gasPrice}`, "gwei");
// const txObj = {
//   nonce,
//   from: addressFrom,
//   to: _contractAddress,
//   //value: ethers.utils.formatEther(gasPrice), //value, ether
//   data: encodedABI,
//   // gasLimit: ethers.BigNumber.from(
//   //   (8 * 10 ** 6).toString()
//   // ).toHexString(), //limit
//   gasPrice: calcGasPrice.toHexString(),
// };
// console.debug("txObj: ", txObj);
// // Signing a transaction
// let signedTx;
// try {
//   signedTx = await wallet.signTransaction(txObj);
//   console.debug("signedTx: ", signedTx);
// } catch (e) {
//   console.error(`${e.message}`); //"\n" ${e.stack}
// }
// if (IsObjectExists(signedTx)) {
//   // const newContract = new ethers.Contract(
//   //   _contractAddress,
//   //   configs.CONTRACT_ABI.ERC721_ABI,
//   //   provider
//   // );    
//   // const ContractWithSigner = newContract.connect(wallet);
//   try {
//     const tx = await provider.sendTransaction(signedTx);
//     console.debug("not mined tx: ", tx);
//     return provider.waitForTransaction(tx.hash);
//     //return await tx.wait();
//   } catch (e) {
//     console.error(`${e.message}`);
//   }
// }
