import React, {Fragment, useEffect, useRef, useState} from "react";
import ethClient from "../../eth_client";
import NavButtons from './NavButtons';
import {message} from "../../../utils/message";

const MyTicketsOrderForm = ({calculation, order_place, activeItem, ...props}) => {

    const [orderFormType, setOrderFormType] = useState('buy');
    const [activeTab, setActiveTab] = useState('market');

    // console.log("props.fnbd ========>", props.fnbd);

    useEffect(() => {
    });

    const buttonSettings = {
        text: 'Place buy order',
        cssClassForButton: 'myTicketsFinalizeOrder__doOrderBtn'
    };

    if (orderFormType === 'sell') {
        buttonSettings.text = 'Place sell order';
        buttonSettings.cssClassForButton += ' myTicketsFinalizeOrder__doOrderBtn--sell';
    } else {
        buttonSettings.cssClassForButton += ' myTicketsFinalizeOrder__doOrderBtn--buy';
    }

    let tabElement = null;
    const buttonsSssClasses = {
        marketBtn: 'orderSettingsNav__btn',
        stopBtn: 'orderSettingsNav__btn',
        limitBtn: 'orderSettingsNav__btn',
        parentOfButtons: 'orderSettingsNav'
    };

    switch (activeTab) {
        case 'limit':
            tabElement = <SubTab calculation={calculation} orderFormType={orderFormType} tokenDetail={activeItem}
                                 activeTab={activeTab} order_place={order_place} activeItem={activeItem}
                                 percetnageLevels={props.percetnageLevels} fnbd={props.fnbd}/>;
            // document.querySelector('.stopprice').style.display = 'none';
            // document.querySelector('.limitprice').style.display = '';
            buttonsSssClasses.limitBtn += ' orderSettingsNav__btn--active';
            buttonsSssClasses.parentOfButtons += ' orderSettingsNav--secondSelected';
            break;

        case 'stop':
            tabElement = <SubTab calculation={calculation} orderFormType={orderFormType} tokenDetail={activeItem}
                                 activeTab={activeTab} order_place={order_place} activeItem={activeItem}
                                 percetnageLevels={props.percetnageLevels} fnbd={props.fnbd}/>;
            // document.querySelector('.stopprice').style.display = '';
            // document.querySelector('.limitprice').style.display = 'none';
            buttonsSssClasses.stopBtn += ' orderSettingsNav__btn--active';
            buttonsSssClasses.parentOfButtons += ' orderSettingsNav--thirdSelected';
            break;

        default:
            if (document.querySelector('.stopprice') != null) {
                // document.querySelector('.stopprice').style.display = 'none';
                // document.querySelector('.limitprice').style.display = 'none';
            }
            tabElement = <SubTab calculation={calculation} orderFormType={orderFormType} tokenDetail={activeItem}
                                 activeTab={activeTab} order_place={order_place} activeItem={activeItem}
                                 percetnageLevels={props.percetnageLevels} fnbd={props.fnbd}/>;
            buttonsSssClasses.marketBtn += ' orderSettingsNav__btn--active';
            buttonsSssClasses.parentOfButtons += ' orderSettingsNav--firstSelected';
    }

    return (
        <div className="myTicketsOrderForm">
            <NavButtons orderFormType={orderFormType}
                        setOrderFormType={setOrderFormType}/>

            <div className="myTicketsOrderSettings">
                <div className={buttonsSssClasses.parentOfButtons}>
                    <button className={buttonsSssClasses.marketBtn}
                            onClick={() => setActiveTab('market')}>
                        Market
                    </button>

                    <button className={buttonsSssClasses.limitBtn}
                            onClick={() => setActiveTab('limit')}>
                        Limit
                    </button>

                    <button className={buttonsSssClasses.stopBtn}
                            onClick={() => setActiveTab('stop')}>
                        Stop
                    </button>
                </div>
                {tabElement}
            </div>
        </div>
    );
};

const SubTab = ({calculation, orderFormType, tokenDetail, activeTab, order_place, activeItem, ...props}) => {

    const [state, setState] = useState({
        amount: 0,
        price: 0,
        marketPrice: 0,
        fee: {taker: 0, maker: 0, total: 0}
    })
    // console.log("---------activeItem-------", activeItem)

    const amountInputRef = useRef();
    const priceInputRef = useRef();

    useEffect(() => {
        updateOrderForm();
        // console.log("this is updating....");
    }, [orderFormType, activeTab]);

    useEffect(() => {
        if (activeItem.address) {
            // ToDo check blockchain api for call contract
            // getMarketPrice();
        }
    }, [activeItem.address])

    const buttonSettings = {
        text: 'Place buy order',
        cssClassForButton: 'myTicketsFinalizeOrder__doOrderBtn'
    };

    if (orderFormType === 'sell') {
        buttonSettings.text = 'Place sell order';
        buttonSettings.cssClassForButton += ' myTicketsFinalizeOrder__doOrderBtn--sell';
    } else {
        buttonSettings.cssClassForButton += ' myTicketsFinalizeOrder__doOrderBtn--buy';
    }

    const getMarketPrice = async () => {
        let marketPrice = await ethClient.matcher.getMarketPrice(activeItem.address);
        setState({...state, marketPrice});
    }

    async function updateOrderForm() {

        let amount = (amountInputRef.current) ? amountInputRef.current.value : 0;
        let price = (priceInputRef.current) ? priceInputRef.current.value : state.marketPrice;
        console.log("price ===>", price);

        if (isNaN(parseFloat(amount))) amount = 0;
        if (isNaN(parseFloat(price))) price = 0;
        let userType = (orderFormType == "buy") ? "buyer" : "seller";
        console.log(amount, price);
        ///{TODO: this crashed app, need to check and fix ===>>}
        // let calcFeeObj = ethClient.matcher.calculateFee(amount, price, activeTab, userType, props.percetnageLevels);

        // setState({...state, amount, price, fee: calcFeeObj});

    }

    async function PlaceOrder() {

        if (state.amount > 0 && activeItem.balance > state.amount) {
            let orderType = 0;
            let userType = 0;

            if (activeTab == "market") orderType = 0;
            if (activeTab == "limit") orderType = 1;
            if (activeTab == "stop") orderType = 2;

            if (orderFormType == "buy") userType = 0;
            if (orderFormType == "sell") userType = 1;

            let quantity = state.amount;
            order_place({
                tokenAddress: activeItem.address,
                quantity,
                price: state.price,
                userType,
                orderType,
                fee: state.fee
            });
        } else if (activeItem.balance < state.amount) {
            return message('error', 'The balance is not enough!');
        } else {
            return message('error', 'You should provide amount to place order!')
        }
    }

    return (
        <Fragment>

            <div className="myTicketsOrderSettingsInput">
                <h4>Amount</h4>

                <div className="buy-tickets-block">
                    <button className="max-btn">MAX</button>
                    <input type="number" className="buy-tickets-inputs" ref={amountInputRef} placeholder="0.00"
                           onKeyUp={updateOrderForm}/>

                    <label> {activeItem ? activeItem.symbol : "USDW"}
                        {/*{TradeDetails.PairDetails.fromCurrency.currencySymbol}*/}
                    </label>
                </div>
            </div>

            {activeTab == "stop" &&
            <div className="myTicketsOrderSettingsInput">
                <h4>Stop Price </h4>
                <div>
                    <input type="number" placeholder="0.00" ref={priceInputRef} onKeyUp={updateOrderForm}/>

                    <label>USDW</label>
                </div>
            </div>
            }

            {activeTab == "limit" &&
            <div className="myTicketsOrderSettingsInput">
                <h4>Limit Price </h4>
                <div>
                    <input type="number" ref={priceInputRef} placeholder="0.00" onKeyUp={updateOrderForm}/>
                    <label> USDW </label>
                </div>
            </div>
            }

            <div className="myTicketsFinalizeOrder">
                <div className="myTicketsFinalizeOrder__total">
                    {activeTab == "limit" &&
                    <div>
                        <span>Maker Fee</span>
                        <span>{state.fee.maker} USDW</span>
                    </div>
                    }
                    {
                        (activeTab == "market" || activeTab == "stop") &&
                        <div>
                            <span>Taker Fee</span>
                            <span>{state.fee.taker} USDW</span>
                        </div>
                    }
                    <div>
                        <span>Total</span>
                        <span>{state.fee.total} USDW</span>
                    </div>
                </div>
            </div>

            <div className={buttonSettings.cssClassForButton}></div>

            <div className="myTicketsFinalizeOrder">
                <div className={buttonSettings.cssClassForButton}>
                    <button onClick={PlaceOrder}>{buttonSettings.text}</button>
                </div>
            </div>
        </Fragment>
    );
};


export default MyTicketsOrderForm;
