import axios from "axios";
import constants from "../../constant";

const auth = constants.auth;

class Matcher {

    constructor() {

    }

    async getCreatedOrders(callback) {
        const result = await axios({
            method: 'get',
            url: `${constants.apiRoot}/contracts/matcher/getOrders`,
            responseType: 'json',
            auth
        });
        return result.data.orders;
    }

    reportCreatOrderTransaction(data, callback){
        axios({
            method: 'post',
            url: `${constants.apiRoot}/contracts/matcher/reportCreatOrderTransaction`,
            data,
            responseType: 'json',
            auth
        }).then(callback);
    }
}

export default new Matcher();
