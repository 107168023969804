import * as setMainWalletAddress from "./blockchain.action";
import ethClient from "../../app/eth_client";
import EthApi from "../../app/api/tendermint";
import rootReducer from "././../store";
import * as cartActions from './blockchain.action'
import {put,call,select, takeEvery} from "redux-saga/effects";



function* setMainWalletAddressFunc(data){
    console.log(data.payload, "--------------data")

    // const { walletAddress } = data.payload;
    // const fnbd = yield ethClient.eth.getFNBCoin(walletAddress);
    // console.log("usdw ===>", fnbd);
    // yield put('blockchainActions.setFNBDCoinDetail'({fnbd}));
}
// function* addToBanners(data){
//     const state= yield select();
//     let banners = data.payload.banners;
//     yield put(cartActions.setBanners(banners))
// }

function* changeCartData(data){
    const state= yield select();
    let operation = data.payload.operation;
    // console.log(operation, "--------------operation")

    //operation could be "+" or "-"
    const cartItem = data.payload.selectedLicense;
    const cart = state.blockchain.cart;
    let cartArray = cart;

    let NewArr = []
    if (operation==="+") {
        if (cart.length===0) {
            cartArray.push(cartItem)
        }  else {
            let existItem = new Promise((resolve)=>{
                let existCount = false;
                cartArray.forEach((i)=> {
                    // console.log(i, cartItem, "--------------i, cartItem")
                    if (i ===cartItem) {
                        // console.log(111111111, "--------------111111111")
                        existCount = true;
                    }

                });
                resolve(existCount)
            });

            existItem.then((res)=> {
                if(res===false) {
                    cartArray.push(cartItem)
                }
            })
    }
        yield put(cartActions.setMainWallet3(cartArray))
    }
    if (operation==="-") {
        let existItems= new Promise((resolve)=>{

            cartArray.forEach((i)=> {
                if (i !==cartItem) {
                    NewArr.push(i)
                }
            });
            resolve(NewArr)
        });

        console.debug(existItems, "--------------afterRemoved")
        yield put(cartActions.setMainWallet3(NewArr))
    }
}



export function* watchFetchBlockchain() {
    yield takeEvery('GET_WALLET', setMainWalletAddressFunc)
    yield takeEvery('INITIAL_CARD', changeCartData)
    // yield takeEvery('CHANGE_BANNERS', addToBanners)
}

























//DEPRICATED ===>>>

//
// function* getAllFeePercetage(){
//     const arr = yield ethClient.matcher.getAllFeePercetage();
//     console.log("getAllFeePercetage ===========>", arr);
//     yield put(blockchainActions.setPercentageLevels({levels:arr}));
// }
//

// function* fetchProducts() {
//     const products = yield Api.fetch('/products')
//     console.log(products)
// }
//
// function* getAllTicket(data){
//     const { walletAddress } = data.payload;
//     let tickets = yield EthApi.contract.tickets.getMyTickets(walletAddress);
//     console.log("tickets =====>", tickets);
//     yield put(blockchainActions.setUsersTickets({tickets}));
//
// }
//
// function* getCreatedOrders(){
//     let orders = yield EthApi.contract.matcher.getCreatedOrders();
//     yield put(blockchainActions.setCreatedOrders({orders}));
// }
//
// function* createOrder(data){
//     const {walletAddress, password, params} = data.payload;
//     const { tokenAddress, quantity, fee} = params;
//     let result = true;
//
//     console.log(params);
//
//     if(params.userType == 0 ){ //buy
//         let allowance = fee.total;
//         console.log("buyer --allowance -->", allowance);
//         result = yield ethClient.eth.approvieCoinsForMatcher(walletAddress, password, allowance);
//         result = true;
//     }
//     if(params.userType == 1 ){ //sell
//         let allowance = quantity;
//         console.log("seller --allowance -->", allowance);
//         result = yield ethClient.ticket.approvieTixForMatcher(walletAddress, password, tokenAddress, allowance);
//     }
//
//     //now you can proceed to create order
//     if(result){
//         result = yield ethClient.matcher.createOrder(walletAddress, password, params);
//         const transaction_hex = result.transactionHash;
//         if(params.orderType == 1){//limit order
//             EthApi.contract.matcher.reportCreatOrderTransaction({transaction_hex}, ()=>{console.log("transaction_hex", transaction_hex);});
//         }
//     }
//
//     if(result){
//         yield* getCreatedOrders();
//     }else{
//         //decrease allownce back
//         if(params.userType == 0 ){ //buy
//             result = yield ethClient.eth.decreaseAllowancCoinsForMatcher(walletAddress, password, quantity);
//         }
//         if(params.userType == 1 ){ //sell
//             result = yield ethClient.ticket.decreaseTixForMatcher(walletAddress, password, tokenAddress, quantity);
//         }
//     }
// }
//
// function* cancelOrder(data){
//     const { walletAddress, password, orderObj } = data.payload;
//     console.log("orderObj ===>", orderObj);
//     let result = yield ethClient.matcher.cancelOrder(walletAddress, password, orderObj.orderID);
//     if(result){
//         console.log(result);
//         yield* getCreatedOrders();
//     }
// }
//
// function* approveTixForMatcher(data){
//     const { walletAddress, password, tokenAddress, amount } = data.payload;
//     const result = yield ethClient.matcher.approvieTixForMatcher(walletAddress, password, tokenAddress, amount);
// }
//
// function* getAllProjects(data){
//     console.log(data, "--------------data333")
//     const { offset, limit, licenseType } = data.payload;
//     const result = yield EthApi.contract.project.getAllProjects(offset, limit, licenseType);
//     if(result){
//         yield put(blockchainActions.setTixProjects(result));
//     }
// }
//
// function* fetchWalletTixesBalance(){
//     let activeAccount = ethClient.eth.getActiveAccountAddress();
//     let ethBalance = yield ethClient.eth.getETHBalance(activeAccount);
//     let fnbCoinDetail = yield ethClient.eth.getFNBCoin(activeAccount);
//     yield put(blockchainActions.setUser_ETH_FNBD_blance(ethBalance, fnbCoinDetail));
// }
//
// function* setStartCollectibleAuction(data){
//     const { walletAddress, password, params } = data.payload;
//     //get user's collecibles
//     yield ethClient.collectibles.setStartAuction(walletAddress, password, params);
// }
//
// function* approveCollectibleForMatcherSaga(data){
//     const { walletAddress, password, params } = data.payload;
//     //get user's collecibles
//     yield ethClient.collectibles.setStartAuction(walletAddress, password, params);
// }
//
// function* getOwnedProject(data){
//     const { walletAddress } = data.payload;
//     const projects = yield ethClient.project.getOwnedProjects(walletAddress);
//     yield put(blockchainActions.setProjects(projects));
// }
//
// function* getAuctionDetail(data){
//     const { tokenId, pId } = data.payload;
//     console.log("tokenId --->", tokenId);
//     const auctionDetail = yield ethClient.collectibles.getAuctionDetail(tokenId);
//     yield put(blockchainActions.setAuctionDetail({auctionDetail, pId}));
//
// }
//
// function* buyCollectibleNow(data){
//     const { walletAddress, password, tokenId, price } = data.payload;
//     yield ethClient.matcher.buyNowCollectible(walletAddress, password, tokenId, price);
// }
//
// function* getOwnedCollectibles(data){
//     const {walletAddress} = data.payload;
//     const collectibles = yield ethClient.collectibles.getTokensOfOwner(walletAddress);
//     yield put(blockchainActions.setERC721Tokens(collectibles));
// }
//
// export function* watchFetchBlockchain() {
    // yield fork(loginWatcherSaga);
    // yield takeEvery(blockchainActions.getAavailableFeePercetageLevelsSaga, getAllFeePercetage);
    // yield takeEvery('GET_NEWS', getFNBDCoinDetail);
    // yield takeEvery(blockchainActions.getFNBDCoinDetailSaga, getFNBDCoinDetail);
    // yield takeEvery(blockchainActions.createOrderSaga, createOrder);
    // yield takeEvery(blockchainActions.getAllTicketSaga, getAllTicket);
    // yield takeEvery(blockchainActions.getCreatedOrdersSaga, getCreatedOrders);
    // yield takeEvery(blockchainActions.cancelOrderSaga, cancelOrder);
    // yield takeEvery(blockchainActions.approveTixForMatcherSaga, approveTixForMatcher);
    // yield takeEvery(blockchainActions.getAllProjectSaga, getAllProjects);
    // yield takeEvery(blockchainActions.updateWalletBalanceSaga, fetchWalletTixesBalance);
    // yield takeEvery(blockchainActions.setStartCollectibleAuctionSaga, setStartCollectibleAuction);
    // yield takeEvery(blockchainActions.getOwnedProjectSaga, getOwnedProject);
    // yield takeEvery(blockchainActions.getAuctionDetailSaga, getAuctionDetail);
    // yield takeEvery(blockchainActions.buyCollectibleNowSaga, buyCollectibleNow);
    // yield takeEvery(blockchainActions.getOwnedCollectiblesSaga, getOwnedCollectibles);
// }

// function mapStateToProps({blockchain, cart}) {
//     return {
//         // tokens: blockchain.tokens,
//         // walletAddresses: blockchain.walletAddresses,
//         mainWalletAddress: blockchain.mainWalletAddress,
//         // projects: blockchain.projects,
//         // cartItems: cart.cartItems
//     };
// }
