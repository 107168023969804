import React, {Fragment} from 'react';
import Parse from "parse";
import speakeasy from "speakeasy";
import {LostTwoStepCode} from '../LostTwoStepCode/LostTwoStepCode';
import GAuth from '../../../../assets/images/google-auth.png'
import {sendParseRequest} from "../../../../utils/sendParseRequest";
import startLogin from "../../../../utils/startLogin";
import shouldUserConfirmStatus from "../../../../utils/shouldUserConfirmStatus";

export class TwoStepVerification extends React.Component {
    state = {
        isCodeValidated: true,
        secret: null,
        code: null,
        thirtyDayCheckbox: false,
        loading: false
    };

    componentDidMount = () => {
        if (Parse.User.current()) return this.props.history.push('/');

        const userEmail = atob(localStorage.getItem('email'));

        const searchData = {
            query: 'User',
            select: ['secretKeyForGoogleAuthentication'],
            equal: [{field: 'email', value: userEmail}]
        }

        if (userEmail) {
            sendParseRequest('getInfo', searchData)
                .then(res => {
                    this.setState({secret: res[0].get('secretKeyForGoogleAuthentication')})
                })
        }
    };

    handleCodeChange = (e) => {
        this.setState({code: e.target.value, isCodeValidated: true});
    };

    onSubmit = (e) => {
        if (e === 'submit' || e.key === 'Enter') {
            this.setState({loading: true});

            const {secret, code} = this.state;
            const userEmail = atob(localStorage.getItem('email'));
            const userPassword = atob(localStorage.getItem('password'));

            const verified = speakeasy.totp.verify({
                secret: secret.hex,
                encoding: 'hex',
                token: code
            });

            if (verified) {
                startLogin(userEmail, userPassword, true)
                    .then(() => {
                        shouldUserConfirmStatus(this.props.history.push, '/');
                    })
            } else {
                this.setState({loading: false});
                if (e.which === 13 || e === "submit") {
                    this.setState({
                        isCodeValidated: false
                    })
                }
            }
        }
    };

    render() {
        const {isCodeValidated} = this.state;
        return (
            <Fragment>
                <div className="loginScreen thankyouScreen"
                     style={{minHeight: Parse.User.current() ? 'calc(100% - 201px)' : 'calc(100% - 62px)'}}>
                    <div className="screenWrapper">
                        <div className="two-step_first-wrapper">
                            <div className="two-step_second-wrapper">
                                <span className="two-step_title">2-Step Verification</span>
                                <span className="two-step_desc">Enter the 2-step verification generated by your authenticator app</span>
                            </div>
                            <div className="auth-img_block"><img src={GAuth} alt=''/></div>
                            <div className="enter-code">
                                <div className="controls">
									<span>
										<strong>Enter 2-step verification code:</strong>
									</span>
                                    <div className="unput-two-step">
                                        <input className={isCodeValidated ? "code-input" : "code-input red"} type="text"
                                               id="token"
                                               maxLength="6"
                                               autoComplete="off" onChange={this.handleCodeChange}
                                               onKeyPress={this.onSubmit}/>
                                        {this.state.loading
                                            ? <i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                                                 style={{
                                                     width: '25px',
                                                     height: '24px',
                                                     fontSize: '25px',
                                                     color: '#0162D0',
                                                     margin: '9px 0 0 10px'
                                                 }}
                                            />
                                            : <input className="btn-two-step"
                                                     type="submit"
                                                     value="Verify"
                                                     id="step_two_verify"
                                                     onClick={() => this.onSubmit("submit")}
                                                     data-disable-with="Verifying..."
                                            />}
                                    </div>

                                </div>
                            </div>
                            <div className="two-step_footer">
                                {/*<input type="checkbox" checked={thirtyDayCheckbox} onChange={this.handleThirtyDayCheckbox}/>*/}
                                {/*<span*/}
                                {/*className="two-step_desc">Don't ask me for the code again for 30 days when I use this computer.</span>*/}
                            </div>
                            <LostTwoStepCode/>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
