import constants from "../../constant";

const auth = constants.auth;

class User {

/*    async setWalletAddress(userid, walletAddress){
        return await axios({
            method:"post",
            url: `${constants.apiRoot}/contracts/users/setWalletAddress`,
            data:{
                userid,
                walletAddress
            },
            responseType:'json',
            auth
        });
    }
    // getWalletAddress
    async getWalletAddress(userid){

        return await axios({
            method:"get",
            url: `${constants.apiRoot}/contracts/users/getWalletAddress`,
            params:{
                userid
            },
            responseType:'json',
            auth,
        });
    }*/
}

export default new User();