const prepareImagesPathForMinify = (image, width, height, fit = 'cover') => {
    if (image) {
        if (typeof image === 'object') return image.src;

        const domainForRequestRegex = /https:\/\/dov9rgu7pr3en.cloudfront.net\//;
        const domainForRequest = "https://dov9rgu7pr3en.cloudfront.net/";
        const fullImgUrl = image;
        const relativeAddress = fullImgUrl.split('.com/')[1];

        if (domainForRequestRegex.test(fullImgUrl) || !relativeAddress) return fullImgUrl;

        const bucket = relativeAddress.split('/')[0];
        const imagePath = fullImgUrl.split(`${bucket}/`)[1];
        const requestBodyInBase64 = btoa(
            JSON.stringify({
                bucket,
                key: imagePath,
                edits: {
                    resize: {
                        width,
                        height,
                        fit
                    },
                    flatten: true,
                    normalise: true
                }
            })
        );

        return domainForRequest + requestBodyInBase64;
    } else {
        return "https://i.stack.imgur.com/l60Hf.png"
    }
};


export default prepareImagesPathForMinify;
