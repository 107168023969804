import "../../../assets/style/main.css";
import "../../Components/Exchange/exchange.css";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import * as blockChainAction from "../../../modules/blockchain/blockchain.action";
import FanancersCard from "./FanancersCard"
import {categoriesForInvestors} from "./fanancersData.js";

const Fanancers = (props) => {
    const [state, setState] = useState({
        slide1: "1",
        slide2: "1",
        slide3: "1",
    })
   

    useEffect(() => {
        document.title = "Fanbase";
        window.scrollTo(0, 0);

        // if (props.tixProjects.length === 0) {
        //     props.getAllTixProjectSaga();
        // }

    }, [
      // props.tixProjects.length
    ]);

    return (
        <div className="page" style={{paddingBottom: "0"}}>
            <div className="pageContent">
                <div className="mainHeader">
                    <p className="mainTitle">
                        We are the movers and shakers, of the world, forever, it seems...
                    </p>
                    <div className="tokenSummarySiteWrap"/>
                </div>
                <div className="fnbXTokenAnythingWrap card6">
                    <p className="subDescription"
                       style={{
                           width: "800px",
                           textAlign: "justify",
                           color: "grey",
                           marginBottom: "50px"
                       }}
                    >
                        The Fanbase launchpad allows fans to invest in film,
                        music, digital media productions, digital technologies and companies.
                        Like financiers, label owners, or executive producers, Fanbase investors
                        can earn royalties or dividends on their investments.
                    </p>
                    <div style={{"display": "flex", "flexDirection": "column"}}>
                                <div className="rightSideModuleWrap homePageFutures">
                                    <div className="leftSideModule" style={{width: "40%"}}>
                                        {state.slide2 === "1" &&
                                        <img src={require("../../../assets/images/fanwally_logo.png")} className='pulse' alt='img'/>}
                                        {state.slide2 === "2" &&
                                        <img src={require("../../../assets/images/fanwally_logo.png")} alt='img' className='pulse'/>}
                                        {state.slide2 === "3" &&
                                        <img src={require("../../../assets/images/fanwally_logo.png")} alt='img'/>}
                                        {state.slide2 === "4" &&
                                        <img src={require("../../../assets/images/coffee611.jpeg")} alt='img'/>}
                                        {state.slide2 === "5" &&
                                        <img src={require("../../../assets/images/coffee611.jpeg")} alt='img'/>}
                                    </div>
                                    <div className="rightSideModule" style={{width: "60%"}}>
                                        <div className="featuresListUI" style={{"textAlign": "left"}}>
                                            <button onClick={() => setState({slide2: "1"})}
                                                    className={"active-yellow"}
                                            >
                                                Fanbase Music
                                            </button>
                                            <button onClick={() => setState({slide2: "2"})}
                                                    className={"active-yellow"}
                                            >
                                                Fanbase Comics
                                            </button>
                                            <button onClick={() => setState({slide2: "3"})}
                                                    className={"active-yellow"}
                                                    
                                            >
                                                Fanbase Film
                                            </button>
                                        </div>
                                        {state.slide2 === "1" &&
                                        <p className="lareVIALogo pulse" style={{fontSize: "40px"}}>
                                            fanbase
                                        </p>}
                                        {state.slide2 === "2" &&
                                        <p className='fanVIALogo-purple pulse' style={{fontSize: "40px"}}>
                                            fanbase
                                        </p>
                                        }
                                        {state.slide2 === "3" &&
                                        <p className='lareVIALogo pulse' style={{fontSize: "40px"}}>
                                            fanbase
                                        </p>
                                        }
                                        {state.slide2 === "1" &&
                                        <span>
                                        <p className="title pulse">...is for musicians!</p>
                                        <p className="subtitle">
                                        Have you every imagined you would own a piece of a record label? On Fanbase,
                                        fans finance labels or albums from their favorite musicians.
                                        When that audio succeeds at auction or sells to a mass audience,
                                        fans can earn lump sum or royalty payments.
                                        </p>
                                    </span>
                                        }
                                        {state.slide2 === "2" &&
                                        <span>
                                        <p className="title pulse">...is for artists!</p>
                                        <p className="subtitle">
                                        Have you ever imagined you would own a publishing company like Marvel?
                                        On Fanbase, fans finance comic book artists,
                                        publishers and creative companies of all sorts.
                                        When those companies succeed,
                                        fans can earn lump sum or royalty payments.
                                        </p>
                                    </span>
                                        }
                                         {state.slide2 === "3" &&
                                        <span>
                                        <p className="title pulse">...is for filmmakers!</p>
                                        <p className="subtitle">
                                        Have you ever imagined you would own a film production company?
                                        On Fanbase, fans finance feature films, short films,
                                        moving images and videos from cinematographers,
                                        directors and producers. When they sell,
                                        fans can earn lump sum or royalty payments.
                                        </p>
                                        
                                    </span>
                                        }
                                        <br/>
                                    </div>
                                    <br style={{clear: "both"}}/>
                                </div>
                            </div>
                    <hr/>
                    <div className="row2">
                        <p className="firstTitle" style={{textAlign: "center", color: "black"}}>
                            Want to Invest?
                        </p>
                        <p className="paraDescr" style={{color: "grey"}}>
                            Explore the various categories of projects seeking fan financers. If you see a
                            project you like, you can choose to invest! Once a
                            project is ticketized and goes to market, financers can earn monthly or quarterly payments
                            as royalty shares or dividends. Tickets that are minted on fanbase can be traded on
                            our decentralized exchange.  {" "}
                        </p>
                    </div>
                    <div className="row2">
                        <p className="firstTitle" style={{color: "black", fontSize: "20px"}}>
                            Categories for Investors
                        </p>
                    </div>
                    <div className="row3">
                        <div className="row5 categoriesForInvestors">
                            {<FanancersCard number={0} categoriesForInvestors={categoriesForInvestors}/>}
                            {<FanancersCard number={1} categoriesForInvestors={categoriesForInvestors}/>}
                            {<FanancersCard number={2} categoriesForInvestors={categoriesForInvestors}/>}
                            {<FanancersCard number={3} categoriesForInvestors={categoriesForInvestors}/>}
                            {<FanancersCard number={4} categoriesForInvestors={categoriesForInvestors}/>}
                            {<FanancersCard number={5} categoriesForInvestors={categoriesForInvestors}/>}
                            {<FanancersCard number={6} categoriesForInvestors={categoriesForInvestors}/>}
                            {<FanancersCard number={7} categoriesForInvestors={categoriesForInvestors}/>}
                            {<FanancersCard number={8} categoriesForInvestors={categoriesForInvestors}/>}
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        </div>
    );
}

function mapStateToProps({parse}) {
    return {
        tixProjects: parse.tixProjects
    };
}

export default Fanancers;

// export default connect(
//     mapStateToProps,
//     {...blockChainAction}
// )(Fanancers);
