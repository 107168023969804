import React, {useState, useEffect} from 'react';
import errorIcon from "../../../assets/images/errorPage__icon.png";
import FanWallyLogo from "../../../assets/images/fanwally_logo.png";
import {sendParseRequest} from "../../../utils/sendParseRequest";
import ethClient from "../../eth_client";
import encryptBack from "../../helpers/encryptBack";
import axios from "axios";
import constants from "../../api/constant";
import {Link} from 'react-router-dom';

const auth = constants.auth;
const ConfirmAuctionPage = () => {
  const [auction, setAuction] = useState(null);
  const [usdw, setUsdw] = useState('');
  const [popup, setPopup] = useState('');
  let walletAddress = ethClient.eth.getActiveAccountAddress();
  let privKey = ethClient.eth.getActivePrivateKey();
  console.log(privKey,walletAddress, "--------------privKey")

  const getAuction = (gettedCode) => {
    sendParseRequest('getInfo', {
      query: "AuctionsCodes",
      toJSON: true,
      equal: [{field: "code", value: gettedCode}],
      // containedIn: {field: "objectId", value: projectsId},
      select: ["auctId", "status"],
    })
      .then(projectInfos => {
        if (projectInfos) {
          let auctionId = projectInfos[0].auctId;
          sendParseRequest('getInfo', {
            query: "Auctions",
            toJSON: true,
            equal: [{field: "objectId", value: auctionId}],
            select: ["winners", "licenseID", "name", "logo", "projectId", "tickerSymbol", "owner", "objectId", "idOfLicense"],
          }).then((res) => {
            if (res) {
              setAuction(res[0])
            }
          })
        }
      })
  };

  const confirmTransfer = async () => {
    if (auction) {
      let license = auction.licenseID;
      let id = "";
      let auctionId = auction.objectId;
      let amount = auction.winners.winnerFirst.currentBid;
      let owner = auction.owner;
      //TODO: should set 24 hrs for confirm
      let deadline = Math.floor(+auction.winners.winnerFirst.expiredConfirmUnix + 186400);

      // let signed = await signTransferOnBehalfPermission(privKey, walletAddress, "", amount, deadline);
      let signed = "";

      console.log(signed, "--------------signed")

      if (signed) {
        let signString = encryptBack(signed.signature);
        let transferData = {
          xString: signString,
          amount: amount,
          walletAddress: walletAddress,
          owner: owner,
          deadline: deadline,
          licenseID: license,
          auctionId: auctionId
        };

        sendParseRequest('confirmAuctionTransfer', transferData)
          .then(res => {
            console.log(res)
          }).catch((e) => console.log(e));
      }
    }
  };

  const getUSDW =async ()=> {
    try {
      const result = await axios({
        method: 'get',
        url: `${constants.apiRoot}/contracts/usdw/balanceOf?account=${walletAddress}`,
        responseType: 'json',
        auth
      });
      if (result) {
        setUsdw(result.data.result)
        console.log(result, "--------------result")
      }
    }

    catch (e){
      console.log(e, "--------------e")
    }


  };

  useEffect(() => {
    if (window.location.hash.split('=')[1]) {
      let gettedCode = window.location.hash.split('=')[1];
      getAuction(gettedCode)
      if (gettedCode) {
        getUSDW()
        // Новый эндпоинт -- GET http://3.220.210.43:8081/api/v2/eth/contracts/usdw/balanceOf с параметром account (адрес, у кого нужно проверить баланс)
      }
    }
  }, [window.location.hash]);


  return (<>
      {!popup ?
    <div className='wallyHome_wrap'>
      <div className='wallyHome_container'>
        <div className='wallyHome_block'>
          <div style={{
            width: '100%',
            display: 'flex',
            // height: '70%',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>

            <p style={{
              width: '100%',
              marginBottom: '10px'
            }}><b>Wallet address:</b> {walletAddress}</p>
            <p style={{
              textAlign: 'left',
              width: '100%',
              marginBottom: '30px'
            }}><b>Balance:</b> {usdw} USDW</p>

          </div>
            <h3>Congratulations! You won the auction for...</h3>

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              {auction && <>
                <img src={auction.logo}
                     style={{
                       width: '100px',
                       height: '100px',
                       borderRadius: '90px',
                       marginTop: '30px'
                     }}
                />

                <div style={{
                  marginLeft: '30px',
                  display: 'flex',

                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flexStart'
                }}>
                  <h3>{auction.name}</h3>
                  <h3>{auction.tickerSymbol} (#{auction.idOfLicense})</h3>
                </div>
              </>
              }

            </div>
          <p>You must confirm this trade within 24 hours. View the terms&#160;
            <Link to={'/terms'}  target="_blank">here</Link>.
          </p>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <button style={{
                width: '110px',
                marginTop: '40px',
                marginRight: '30px'
              }} className='blue-button' onClick={()=>setPopup(true)}>Cancel
              </button>
              <button
                onClick={() => confirmTransfer()}
                style={{
                  width: '110px',
                  marginTop: '40px'
                }} className='blue-button'>Confirm
              </button>

            </div>


        </div>
      </div>
    </div>
        :
        <div className='wallyHome_wrap'>
          <div className='wallyHome_container'>
            <div className='wallyHome_block'>

              <p>If you cancel this trade, you could lose the right to use our Service. <br/>View the terms&#160;
                <Link to={'/terms'}  target="_blank">here</Link>.
              </p>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
                <button
                  onClick={() => setPopup(false)}
                  style={{
                    width: '110px',
                    marginTop: '40px',
                    marginRight: '30px'
                  }} className='blue-button'>Back
                </button>


                <button style={{
                  width: '110px',
                  marginTop: '40px',

                }} className='blue-button'>Cancel trade
                </button>


              </div>


            </div>
          </div>
        </div>

      }
    </>
  );
};

export default ConfirmAuctionPage;
