const crypto = require('crypto');

const IV_LENGTH = 16; // For AES, this is always 16
const ENCRYPTION_KEY = "PoRtvDnIFuwsQLNBuYX7PiwEHmmeW5PO"; // Must be 256 bytes (32 characters)

const encryptBack = (text) => {
  let iv = crypto.randomBytes(IV_LENGTH);
  let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(ENCRYPTION_KEY), iv);
  let encrypted = cipher.update(text);

  encrypted = Buffer.concat([encrypted, cipher.final()]);

  return iv.toString('hex') + ':' + encrypted.toString('hex');
};

module.exports = encryptBack;
