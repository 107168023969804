import React, {Component} from 'react';
import Parse from 'parse';
import {Redirect} from 'react-router-dom';
import {sendParseRequest} from "../../../utils/sendParseRequest";

export default class ConfirmPrincipalOrCollaborator extends Component {
    state = {
        isLoading: true,
        confirmed: false,
        counter: 10
    };

    async componentDidMount() {
        const {id, type} = this.props.match.params;
        const currUrl = this.props.match.url;
        const currUser = Parse.User.current();

        localStorage.setItem('principals_or_collaborators_confirm', currUrl);

        if (!currUser) return;

        const {
            isLoading
        } = this.state;

        if (isLoading) {
            sendParseRequest('updateInfo', {
                searchData: {
                    query: 'Project_Collaborators',
                    containedIn: {field: 'objectId', value: ['IOEfKyQHyL', 'UDGuh6JWTL', "yOWuog3qbF"]}
                },
                updateData: [{field: 'confirmed', value: true}]
            }).then(() => {
                localStorage.removeItem('principals_or_collaborators_confirm');

                this.setState((state) => ({
                    ...state,
                    confirmed: true,
                    isLoading: false
                }));
            }).catch(() => {
                localStorage.removeItem('principals_or_collaborators_confirm');

                this.setState((state) => ({
                    ...state,
                    confirmed: false,
                    isLoading: false
                }));
            })
        }

        this.counter = setInterval(() => {
            this.setState((state) => ({
                ...state,
                counter: state.counter - 1
            }));
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.counter);
    }

    render() {
        const {
            confirmed,
            counter,
            isLoading
        } = this.state;

        const confirmSuccessMsg = (
            <h3>The confirmation was successful, you will be redirected to the main page after:</h3>);
        const confirmErrorMsg = (
            <h3>Something went wrong during confirmation, you will be redirected to the main page after:</h3>);

        if (counter === 0) {
            return (
                <Redirect to="/"/>
            );
        }

        return (
            <div className="page confirmPrincipalOrCollaboratorPage">
                <div className="pageContent">
                    <div className="confirmPrincipalOrCollaboratorPage__confirmInfo">

                        {isLoading
                            ? <div className='image-spinner-frame'>
                                <div className="loader__blue">Loading...</div>
                            </div>
                            : confirmed ? confirmSuccessMsg : confirmErrorMsg}

                        <span>{counter}</span>
                    </div>
                </div>
            </div>
        );
    }
}