import Web3 from "web3";

class Utils {

    isAddress(address){
        const web3 = new Web3();
        return web3.utils.isAddress(address);
    }
    
    groupBy(source, key){
        return source.reduce((rv, x)=>{
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        },{});
    }

    findMatchedBlockChainInfo(source, key, id){
        let result;
        source.forEach(element => {
            if (element[key] === id) {
                result = element;
            }
        });

        return result;
    }
}

export default new Utils();