import React, {useEffect, useRef, useState} from 'react';

import DropDownList from './DropDownList';
import mediaDetails from '../../Pages/Contract/mediaDetails.json';

const DropDownListWrapper = ({additionalData, mediaCategory}) => {
  const dropDownListElem = useRef(null);
  const [selectedAdditionalData, setAdditionalData] = useState([]);
  const [dataForList, setDataForList] = useState([]);
  const [isDropDownListOpen, toggleDropDownList] = useState(false);
  const [quantityOfAdditionalData, setAdditionalDataQuantity] = useState(0);
  const dataOfThisCategory = mediaDetails[mediaCategory] ? mediaDetails[mediaCategory].mediaCategoryData : [];

  useEffect(() => {
    updateAdditionalData();
  }, [additionalData]);

  const updateAdditionalData = () => {
    let selectedData = [];
    let allAdditionalData = [];
    let quantity = 0;

    for (let additionalProperty in additionalData) {
      const oneData = typeof additionalData[additionalProperty] === 'string' ? [additionalData[additionalProperty]] : additionalData[additionalProperty];

      selectedData = [
        ...selectedData,
        ...oneData
      ];

      allAdditionalData = [
        ...allAdditionalData,
        ...dataOfThisCategory.filter(({title, data}) => {
          const isTitlesEqual = title.replace(':', '') === additionalProperty;

          if (isTitlesEqual) {
            quantity += data.length;
          }

          return isTitlesEqual;
        })
      ];
    }

    setAdditionalDataQuantity(quantity);
    setDataForList(allAdditionalData);
    setAdditionalData(selectedData);
  };

  if (dataForList.length === 0) return null;
  // console.debug('_________dataForList++++:', dataForList);
  return (

    <div style={{maxHeight: isDropDownListOpen && dropDownListElem.current ? `${dropDownListElem.current.offsetHeight + 50}px` : '900px'}}
         className="dropDownListWrapper">

      <div ref={dropDownListElem}>
        {
          dataForList.map(({title, data}, idx) => (
            <DropDownList
                          mediaCategory={mediaCategory}
                          data={data}
                          // defaultValue={defaultValue}
                          selectedData={selectedAdditionalData}
                          title={title}
                          key={title}
            />
          ))
        }
      </div>

      {/*<button className="dropDownListWrapper__showBtn"*/}
              {/*onClick={() => toggleDropDownList((currState) => (!currState))}>*/}
        {/*{isDropDownListOpen ? 'Hide' : 'Show'} All {quantityOfAdditionalData} additional data*/}
      {/*</button>*/}
    </div>
  );
};

export default DropDownListWrapper;
