import React, {Component, Fragment} from 'react';
import toBase64 from "../../../utils/toBase64";
import prepareImagesPathForMinify from '../../../utils/prepareImagesPathForMinify';

import deleteIcon from '../../../assets/images/rubbish-bin.svg';
import editIcon from '../../../assets/images/pencil-edit-button.svg';
import settingIcon from '../../../assets/images/settings-work-tool.svg';
import saveIcon from '../../../assets/images/check-symbol.svg';
import './showImages.css';
import {sendParseRequest} from "../../../utils/sendParseRequest";

class ShowImage extends Component {
    state = {
        imageIsLoading: false,
    };

    onLoadImg = async (evt) => {
        const {
            onChangeImg,
            imageIdx
        } = this.props;
        const files = evt.target.files;

        if (files.length > 0) {
            if (files[0].type === "image/png" || files[0].type === "image/jpeg" || files[0].type === "image/jpg") {
                const file1Base64 = await toBase64(files[0]);

                this.setState({imageIsLoading: true});

                sendParseRequest('uploadProjectImage', {
                    "base64": file1Base64,
                    "type": files[0].type,
                }).then(res => {
                    this.setState({imageIsLoading: false});
                    onChangeImg(imageIdx, res);
                }).catch(() => this.setState({imageIsLoading: false}))
            }
        }
    };

    render() {
        const {
            classCss,
            source,
            onDeleteImg,
            imageIdx,
            setStateByPropName,
            isDraggable,
            saveImages,
            isOwner,
            onImageClick
        } = this.props;
        const {
            imageIsLoading,
        } = this.state;
        const style = `${source === 'deleted' ? 'mode--deletedPhoto' : ''}`;
        const imageUrl = source && !isDraggable ? prepareImagesPathForMinify(source, 900, 900) : source;
        const spinner = <div className='image-spinner-frame'
                             style={{position: 'static'}}>
            <div className="loader__blue">Loading...</div>
        </div>;
        const controlPanel = <ControlPanel onMoveImage={setStateByPropName}
                                           onDeleteImg={() => onDeleteImg(imageIdx)}
                                           saveImages={saveImages}
                                           isDraggable={isDraggable}/>;

        return (
            <div className={`${classCss || ''} ${isDraggable ? 'draggableImage' : ''}`}>
                <div className={`${style} showImageContainer`}>

                    {
                        imageIsLoading ? spinner :
                            source === 'deleted' ? <UploadInput onLoadImg={this.onLoadImg}/> :
                                <Fragment>
                                    {!isOwner ? null : controlPanel}

                                    <img src={imageUrl} className='image' onClick={() => onImageClick(source)}
                                         alt='Show something'/>
                                </Fragment>
                    }
                </div>
            </div>
        )
    }
}

export const UploadInput = ({onLoadImg}) => (
    <div className="uploadImgInputBox">
        <input onChange={onLoadImg}
               className="uploadImgInputBox__input"
               id="inputChangeImg"
               type="file"
               hidden/>

        <label className="uploadImgInputBox__label"
               htmlFor="inputChangeImg"> </label>
    </div>
);

const ControlPanel = ({onDeleteImg, onMoveImage, isDraggable, saveImages}) => (
    <div className={`controlPanel ${isDraggable ? 'controlPanel--draggable controlPanel-internal' : ''}`}>
        <div className="controlPanelActiveSetting">
            <div className="controlPanel__element">
                <button onClick={onDeleteImg}>
                    <img src={deleteIcon} alt="delete button"/>
                </button>
            </div>

            <div className="controlPanel__element">
                <button onClick={() => onMoveImage('startImageMove', true)}>
                    <img src={editIcon} alt="delete button"/>
                </button>
            </div>
        </div>
        <div className="controlPanel__element controlPanel__element--setting">
            <button onClick={isDraggable ? saveImages : null}>
                <img src={isDraggable ? saveIcon : settingIcon} alt="delete button"
                     style={{transform: isDraggable ? 'rotate(180deg)' : ''}}/>
            </button>
        </div>
    </div>
);

export default ShowImage;
