import React, {useEffect} from 'react';

const ResetPassword = ({location}) => {
    useEffect(() => {
        getDataFromUrl();
    }, []);

    const getDataFromUrl = () => {
        let urlParams = {};
        let pair;
        let tokenize = /([^&=]+)=?([^&]*)/g;
        let re_space = (s) => decodeURIComponent(s.replace(/\+/g, " "));
        let querystring = location.search.substring(1);
        const base = 'https://fanbaseprod.herokuapp.com/parse';

        while (pair = tokenize.exec(querystring)) {
            urlParams[re_space(pair[1])] = re_space(pair[2]);
        }

        const id = urlParams['id'];

        document.getElementById('form').setAttribute('action', base + '/apps/' + id + '/request_password_reset');
        document.getElementById('username').value = urlParams['username'];
        document.getElementById('username_label').appendChild(document.createTextNode(urlParams['username']));
        document.getElementById('token').value = urlParams['token'];

        if (urlParams['error']) {
            document.getElementById('error').appendChild(document.createTextNode(urlParams['error']));
        }

        if (urlParams['app']) {
            console.log(urlParams['app'])
        }
    };

    const handlePassword = (object) => {
        const e = object;
        const value = e.target.value;
        const allLi = document.querySelector(".passwordVerify").children;
        const rules = {
            "1 lowercase": /[a-z]/,
            "1 uppercase": /[A-Z]/,
            "1 number": /[0-9]/,
            "8 characters": /.{8,}/
        };

        e.target.classList.toggle('hasValue', e.target.value);

        for (let i = 0; i < allLi.length; i++) {
            let valid,
                ruleName = allLi[i].innerText.toLowerCase();
            if (rules[ruleName]) {
                valid = new RegExp(rules[ruleName]).test(value);
                allLi[i].classList.toggle('pass', valid);
            }
        }
    };

    const resetPassword = () => {
        const checks = document.querySelector('.pass');
        const passwordField = document.getElementById('passwordField');
        if (passwordField.volume === "" || checks.length !== 4) {
            passwordField.style.backgroundColor = "rgba(255,0,0,0.05)"
        } else {
            console.log('workign now');
            return true;
        }
        return false;
    };

    return (
        <div className="page resetPasswordPage">
            <div className="pageContent phoneVerifiedWrapone">
                <div className="screenWrapper">
                    <p className="mainTitle">New Password</p>
                    <div className="loginWrap" style={{height: '220px'}}>
                        <div className="rgTextW field">
                            <ul className="passwordVerify">
                                <li>1 lowercase</li>
                                <li>1 uppercase</li>
                                <li>1 number</li>
                                <li>8 characters</li>
                            </ul>
                            <div className='error' id='error'/>
                            <form id='form' action='#' method='POST' onSubmit={resetPassword}>
                                <label>
                                    <span style={{display: 'none'}} id='username_label'/>
                                </label>
                                <input style={{width: '100%'}} name="new_password"
                                       onKeyUp={(evt) => handlePassword(evt)}
                                       id="passwordField" type="password" placeholder="Choose a password"
                                />
                                <input name='utf-8' type='hidden' value='✓'/>
                                <input name="username" id="username" type="hidden"/>
                                <input name="token" id="token" type="hidden"/>
                                <p style={{textAlign: 'center'}}>
                                    <button className="loadingButton">Change password</button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;