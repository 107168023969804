import React, {Fragment} from 'react';
import Parse from "parse";
import {sendParseRequest} from "../../../utils/sendParseRequest";

export class AuthenticatorManager extends React.Component {
    state = {
        id: '',
        secret: null,
        closeModal: true,
        isCodeVerified: true,
    };

    componentDidMount = () => {
        let self = this;
        const modal = this.modal;
        window.onclick = function (event) {
            if (event.target === modal) {
                self.hideModal();
            }
        };

        sendParseRequest('getUserInfoById', {id: Parse.User.current().id})
            .then(res => {
                this.setState({
                    secret: res.get('secretKeyForGoogleAuthentication'),
                    isCodeVerified: res.get('isCodeVerified')
                })
            })
    };

    disableAuthenticatorApp = () => {
        this.switchTwoStepAuthenticationEnabled(false);
    };

    hideModal = () => {
        this.props.handleAuthenticatorManager()
    };

    openSettingModal = () => {
        this.props.handleAuthenticatorSettings()
    };

    switchTwoStepAuthenticationEnabled = (bool) => {
        this.props.switchTwoStepAuthentication({isTwoStepAuthenticationEnabled: bool, isSMSAuthenticator: false});
        this.hideModal();
    };

    changeDevice = () => {
        this.openSettingModal();
        this.hideModal();
    };

    render() {
        return (
            <Fragment>
                <div className="auth-modal" ref={node => {
                    this.modal = node;
                }}>
                    <div className="two-step_first-wrapper">
                        <div className="two-step_header">
                            <span className="two-step_title">Manage Authenticator App</span>
                        </div>
                        <div className="two-step_footer footer-manager">
                            <div className="enable-authenticator">
                                <div className="enable-text-group">
                                    <span className="enable_title">Authenticator App</span>
                                    <span className="enable_desc green">Enabled</span>
                                </div>
                                <div>
                                    <input className="btn-two-step" type="submit" value="Change device"
                                           onClick={this.changeDevice}/>
                                    <input className="btn-two-step" type="submit" value="Disable"
                                           onClick={this.disableAuthenticatorApp}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}