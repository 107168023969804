import React, {useEffect} from "react";
import SettingsPageHeader from "../../../Components/SettingsPageHeader";
import "../Settings.css";
import {useHistory} from 'react-router-dom';

export const Limits = () => {
    useEffect(() => {
        document.title = "Limits – FNBD";
    }, []);

    const history = useHistory();

    return (
        <div className="page settingsPage">
            <div className="limits-page">
                <div className="dashWrapperB">
                    <div className="dashModal seperator" style={{"width": "10%"}}/>
                    <div className="dashModal contentPage" style={{"width": "80%"}}>
                        <div className="content " style={{"height": "auto"}}>
                            <SettingsPageHeader/>
                            <div className="settingsWrapper Preferences">
                                <p className="title">Change Limits</p>
                                <br/>
                                <div className="pull-left twoFactor2" style={{"width": "50%"}}>
                                    <p className="changePictureText" style={{"paddingLeft": "0"}}>
                                        <span>Limit the amount you can buy/send or trade:</span>
                                        <br/>
                                        <label style={{display: 'flex', alignItems: 'center'}}>
                                            <input defaultChecked type="radio" name=""/>
                                            <span>$2,000</span>
                                        </label>
                                    </p>
                                    <br/>
                                    <button className='blue-button'
                                            onClick={() => history.push('/Support/rsl')}>
                                        Limit the amount
                                    </button>
                                    <br/>
                                </div>
                                <div className="pull-left twoFactor2" style={{"width": "50%"}}>
                                    <p className="changePictureText" style={{"paddingLeft": "0"}}>
                                        <span>Raise the withdrawal limits:</span>
                                        <br/>
                                        <label style={{display: 'flex', alignItems: 'center'}}>
                                            <input defaultChecked type="radio" name=""/>
                                            <span>$2,000</span>
                                        </label>
                                    </p>
                                    <br/>
                                    <button className='blue-button'
                                            onClick={() => history.push('/Support/rwl')}>
                                        Raise Limits
                                    </button>
                                </div>
                            </div>
                            <br style={{"clear": "both"}}/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                    <div className="dashModal seperator" style={{"width": "10%"}}/>
                </div>
            </div>
        </div>
    )
}
