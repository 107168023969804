import React, {Fragment} from 'react';
import ColorPicker from '../ColorPicker';
import toBase64 from '../../../../../utils/toBase64';

import galleryIcon from '../../../../../assets/images/gallery.svg';

class LogoMakerBody extends React.Component {
  initialXY = () => {
    if (typeof window !== 'object') {
      return 500;
    } else {
      if (window.innerWidth <= 1024 && window.innerWidth >= 980) {
        return 400;
      } else if (window.innerWidth <= 979 && window.innerWidth >= 800) {
        return 350;
      } else if (window.innerWidth <= 799 && window.innerWidth >= 720) {
        return 300;
      } else if (window.innerWidth <= 719 && window.innerWidth >= 420) {
        return 270;
      } else if (window.innerWidth <= 419) {
        return 240;
      } else {
        return 500;
      }
    }
  };

  state = {
    height: this.initialXY(), width: this.initialXY(), 
    fontFamily: 'Shadows_Into_Light',
    fontSize: this.initialXY() / 5,
    text: 'DID', 
    posX: 0, posY: 0, 
    color: '#55a68b', 
    secondColor: '#ffffff', 
    textColor: '#ffffff',
    createCustomLogo: true,
    uploadedImage: ''
  };

  componentDidMount() {
    this.initializateCanvas();

    if (typeof window === 'object') {
      window.addEventListener("resize", (e) => {
        const newWidth = this.initialXY();
        const { width, fontSize } = this.state;
        if (width !== newWidth) {
          this.setState({ width: newWidth, height: newWidth, fontSize: fontSize * newWidth / width });
        }
      })

      window.addEventListener("orientationchange", () => {
        const newWidth = this.initialXY();
        const { width, fontSize } = this.state;
        if (width !== newWidth) {
          this.setState({ width: newWidth, height: newWidth, fontSize: fontSize * newWidth / width });
        }
      });
    }
  }

  componentDidUpdate() {
    const { canvas } = this;
    const { width, height, fontSize } = this.state;

    this.renderCanvas(canvas, width, height, fontSize);
  }

  updateState = (fieldName, value) => this.setState({
    [fieldName]: value,
    uploadedImage: ''
  });

  onUploadImage = async (evt) => {
    const {width, height} = this.state;
    const currEl = evt.target;
    const uploadedLogo = currEl.files[0];

    if (!uploadedLogo) {
      return false;
    }

    const ctx = this.canvas.getContext('2d');
    const logoInBase64 = await toBase64(uploadedLogo);
    const newLogo = new Image();

    newLogo.src = logoInBase64;

    newLogo.addEventListener('load', () => {
      const imageSettings = this.getImageSize(newLogo.width, newLogo.height);

      this.setState({uploadedImage: logoInBase64});

      ctx.clearRect(0, 0, width, height);
      ctx.drawImage(newLogo, imageSettings.dx, imageSettings.dy, imageSettings.width, imageSettings.height);
    });
  };

  getImageSize = (imageWidth, imageHeight) => {
    const {
      width: canvasWidth,
      height: canvasHeight
    } = this.state;
    let imageSettings = {
      dx: 0,
      dy: 0,
      width: 0,
      height: 0
    };
    let scalePercentage = ((imageWidth * 100) / canvasWidth) / 100;

    imageSettings.width = imageWidth / scalePercentage;
    imageSettings.height = imageHeight / scalePercentage;
    imageSettings.dx = (canvasWidth / 2) - (imageSettings.width / 2);
    imageSettings.dy = (canvasHeight / 2) - (imageSettings.height / 2);

    return imageSettings;
  };
  
  render () {
    const {
      width,
      height,
      fontSize,
      posX,
      posY,
      text,
      color,
      secondColor,
      textColor,
      createCustomLogo,
      uploadedImage
    } = this.state;

    const {allowToUploadPhoto} = this.props;

    const buttonIsValid = createCustomLogo || !createCustomLogo && uploadedImage.length > 0;

    return (
      <div className="logo-maker lm-flex">
        <div id='lm-canvas-block'>
          <canvas
            ref={(canvas) => this.canvas = canvas} 
            width={width} 
            height={height}
            id='lm-main-canvas'
          />
        </div>
        <div className='lm-flex lm-column logo-maker-settings rightLogoMakerBlock'>
          {
            allowToUploadPhoto && <NavPanel changeSection={this.updateState}
                                            isCreateCustomLogo={createCustomLogo}/>
          }

          {
            createCustomLogo ?
              <LogoCreator fontSize={fontSize}
                           posX={posX}
                           posY={posY}
                           text={text}
                           color={color}
                           secondColor={secondColor}
                           textColor={textColor}
                           createFontsBodyDropDown={this.createFontsBodyDropDown}
                           handleText={this.handleText}
                           updateState={this.updateState}
                           width={width}/> :
              <UploadImage onUploadImage={this.onUploadImage}/>
          }
          <button id='logo-maker-submit-btn'
                  onClick={this.exportImage}
                  disabled={!buttonIsValid}>Save</button>
        </div>
      </div>
    );
  }

  handleText = (e) => {
    const { value } = e.target
    if (value.length <= 3) {
      this.setState({ text: e.target.value });
    }
  }

  initializateCanvas = () => {
    const { canvas, drawBackground, drawText } = this;

    if (canvas.getContext) {
      const ctx = canvas.getContext("2d");
      const { width, height } = this.state;

      drawBackground({ ctx, radius: width });
      drawText({ ctx, width, height });
    }
  }

  renderCanvas = (canvas, width, height, fontSize, factor = 1) => {
    const { drawBackground, drawText, clearCanvas } = this;
    const { fontFamily, text, posX, posY  } = this.state;
    const ctx = canvas.getContext("2d");

    clearCanvas(ctx);
    drawBackground({ ctx, radius: width });
    drawText({ ctx, fontSize, fontFamily, width, height, text, posX: posX * factor, posY: posY * factor });
  };

  clearCanvas = (ctx) => {
    const { width, height } = this.state;

    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, width, height);
  };

  drawBackground = ({ ctx, radius, color = this.state.color, secondColor = this.state.secondColor }) => {
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(
      radius / 2, 
      radius / 2, 
      (radius - radius / 30) / 2,
      0,2*Math.PI
    );
    ctx.fill();

    ctx.fillStyle = secondColor;
    ctx.beginPath();
    ctx.arc(
      radius / 2, 
      radius / 2, 
      (radius - radius / 13.65) / 2,
      0,2*Math.PI
    );
    ctx.fill();

    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(
      radius / 2, 
      radius / 2, 
      (radius - radius / 6.65) / 2,
      0,2*Math.PI
    );
    ctx.fill();
  }
  
  drawText = ({ ctx, fontSize = this.state.width / 5, fontFamily = "Shadows_Into_Light", color = this.state.textColor, text = this.state.text, height = this.state.height, width = this.state.width, posX = this.state.posX, posY = this.state.posY }) => {
    ctx.font = `${fontSize}px ${fontFamily}`;
    ctx.fillStyle = color;
    ctx.textBaseline = 'middle';
    ctx.textAlign = 'center';
    ctx.fillText(text, ( width / 2 ) + +posX, ( height / 2 ) - +posY);
  };


  createFontsBodyDropDown = () => {
    const { fonts, isFontsLoaded } = this.props;
    const { fontFamily } = this.state;

    if (!isFontsLoaded) {
      return <select disabled value='1'><option value='1'>Fonts are loading</option></select>;
    }
    
    const fontsEl = fonts.map(font => {
      return <option style={{ fontFamily: font }} id={font} value={font} key={font}>{font}</option>;
    });

    return (
      <select style={{ fontFamily: fontFamily }} value={fontFamily} onChange={(e) => this.setState({ fontFamily: e.target.value })}>
        {fontsEl}
      </select>
    )
  }

  //you can use FETCH here to save image on the server 
  exportImage = (e) => {
    const {
      createCustomLogo,
      uploadedImage
    } = this.state;

    if (!createCustomLogo) {
      this.props.insertLogo(uploadedImage);

      return;
    }

    const downloadCanvas = document.createElement('canvas');
    const widthForExport = 5000, heightForExport = 5000; //you can change width and height
    const factor = widthForExport / this.state.width;
    const exportFontSize = this.state.fontSize * factor;
    downloadCanvas.width  = widthForExport; //if
    downloadCanvas.height = heightForExport;
    this.renderCanvas(downloadCanvas, widthForExport, heightForExport, exportFontSize, factor);

    const image1 = downloadCanvas.toDataURL('image/png', 1.0);

    // --> YOUR CODE to save/fetch an image starts here
    this.props.insertLogo(image1);

    // const windowTab = window.open('about:blank','image from canvas'); //you can delete it
    // windowTab.document.write("<img src='"+image1+"' alt='from canvas'/>"); //you can delete it
  };

  // readDeviceOrientation = () => {
  //   if (typeof window !== 'object') return null;
  //
  //   switch (window.orientation) {
  //     case 0:
  //     return 'portrait';
  //
  //     case 180:
  //       return 'portrait';
  //
  //     case -90:
  //       return 'landscape';
  //
  //     case 90:
  //       return 'landscape';
  //
  //     default:
  //       return 'landscape';
  //     }
  // }
}

const NavPanel = ({changeSection, isCreateCustomLogo}) => (
  <div className="logoMakerNavPanel">
    <button onClick={() => changeSection('createCustomLogo', false)}
            className={!isCreateCustomLogo && 'logoMakerNavPanel--active'}>Upload Logo</button>
    <button onClick={() => changeSection('createCustomLogo', true)}
            className={isCreateCustomLogo && 'logoMakerNavPanel--active'}>Create Logo</button>
  </div>
);

const UploadImage = ({onUploadImage}) => (
  <div className="logoMakerUploadImg">
    <label htmlFor="uploadImage">
      <input type="file"
             id="uploadImage"
             hidden
             onChange={onUploadImage}/>

      <img src={galleryIcon} alt=""/>

      <span>Upload your logo</span>
    </label>
  </div>
);

const LogoCreator = (props) => {
  const {
    fontSize,
    posX,
    posY,
    text,
    color,
    secondColor,
    textColor,
    createFontsBodyDropDown,
    handleText,
    updateState,
    width
  } = props;

  return(
    <Fragment>
      <div className='lm-flex lm-column lm-block'>
        <h2 className='section-sub-title'>Background</h2>
        <label>
          <p className='s-input-title'>Main Color: </p>
          <ColorPicker onChange={(hex) => updateState('color', hex)} color={color} pos='top' />
        </label>
        <label>
          <p className='s-input-title'>Second Color: </p>
          <ColorPicker onChange={(hex) => updateState('secondColor', hex)} color={secondColor} pos='top' />
        </label>
      </div>
      <div className='lm-flex lm-column lm-block'>
        <h2 className='section-sub-title'>Text Settings</h2>
        <label>
          <p className='s-input-title'>Font Family: </p>
          {createFontsBodyDropDown()}
        </label>
        <label>
          <p className='s-input-title'>Font Size: </p>
          <input onChange={(e) => updateState('fontSize', e.target.value)} type="range" min={width / 4.5} max={width / 1.3} step="2" value={fontSize} />
        </label>
        <label>
          <p className='s-input-title'>Position X: </p>
          <input onChange={(e) => updateState('posX', e.target.value)} type="range" min={-(width / 5)} max={width / 5} step="2" value={posX} />
        </label>
        <label>
          <p className='s-input-title'>Position Y: </p>
          <input onChange={(e) => updateState('posY', e.target.value)} type="range" min={-(width / 5)} max={width / 5} step="2" value={posY} />
        </label>
        <label>
          <p className='s-input-title'>Text: </p>
          <input type="text" onChange={handleText} value={text} />
        </label>
        <label>
          <p className='s-input-title'>Text Color: </p>
          <ColorPicker onChange={(hex) => updateState('textColor', hex)} color={textColor} pos='bottom' />
        </label>
      </div>
    </Fragment>
  );
};

export default LogoMakerBody;