import React, {useEffect, useState} from "react";
import '../../Widgets/widgets.css';
import PortfolioSquareItem from "../PortfolioSquareItem/index";
import ScheduleAnAuction from "../ScheduleAnAuction";
import axios from "axios";
import constants from "../../../api/constant";
import {useSelector} from "react-redux";


const auth = constants.auth;
export const CollectiblePortfolio = (props) => {

    const blockchainRedux = useSelector(state => state.blockchain);
    const cartRedux = useSelector(state => state.blockchain.cart);

    const [state, setState] = useState({
        activePage: 1,
        numberOfElementOnOnePage: 10,
        highlighted: null,
        showSellAllModal: false,
        showModal: false,
        isShowScheduleBtn: false,
        project: props.project,
        selectedLicene: null
    });

    const [tokens, setTokens] = useState(null);
    const [pagination, setPagination] = useState(false);
    const [markedToken, setMarkedToken] = useState(null);
    const [indexItem, setIndexItem] = useState(null);
    const [cartItems, setCartItems] = useState(null);
    const [showCart, setSHowCart] = useState(false);
    const [totalLicenses, setTotalLicenses] = useState(false);
    // const [activePage, setActivePage] = useState(1)


    const requestForCollectibles = async (pages, projectId, activePage) => {
        if (pages, projectId, activePage) {

            const result = await axios({
                method: 'get',
                url: `${constants.apiRoot}/contracts/collectibles/collectiblesOfOwner?address=${props.walletAddress}&project_id=${projectId}&page=${activePage}&per_page=${pages}&is_auctioned=${false}`,
                responseType: 'json',
                auth
            });
            if (result) {
                return (result)
            }
        }
    };

    const getTokens = async (projectId, pages, activePage) => {
        let collectiblesRes = await requestForCollectibles(pages, projectId, activePage);
        if (collectiblesRes) {
            // console.log(collectiblesRes, "--------------collectiblesRes")

            if (cartRedux.length > 0) {
                console.log(collectiblesRes.data, '=======collectiblesRes.data.collectibles;collectiblesRes.data.collectibles;collectiblesRes.data.collectibles;')
                let arr4 = collectiblesRes.data.items;
                let q = collectiblesRes.data.items;

                cartRedux.forEach((i) => {
                    arr4.forEach((item, idx) => {
                        if (item.properties.id === i.properties.id) {
                            q.splice(idx, 1)
                        }
                    })
                });

                // {TODO: add offset for pagination}
                //   let newPages = 10+(q.length);
                // let newCollectibles = await requestForCollectibles(newPages, projectId)
                //   console.log(newCollectibles, "--------------newCollectibles")
                //   console.log(q.length, "--------------q.length")
// console.log(q, '----q')
                setTokens(q);
            } else {
                // console.log(collectiblesRes.data.items)
                setTotalLicenses(collectiblesRes.data.total)
                setTokens(collectiblesRes.data.items)
            }
        }
    };


    useEffect(() => {
            // if (props.project.tokenURI) {
            // console.log(888, "--------------888");
            // console.log(props.project, '=======proJJJJJ=====')
            getTokens(props.project.id.toUpperCase(), 10, 1)
            // }
        },
        [props.project.id]);

    useEffect(() => {
        currentPages(0);
        handleHighlight(null);
    }, [])


    const prevLink = () => {
        const {activePage} = state;
        setState({...state, activePage: activePage - 1});
        getTokens(props.project.id.toUpperCase(), 10, activePage - 1);
    };


    const nextLink = () => {
        const {activePage} = state;
        setState({...state, activePage: activePage + 1});

        getTokens(props.project.id.toUpperCase(), 10, activePage + 1);
    };

    const currentPages = (page) => {
        const {activePage} = state;
        if (page !== activePage) {
            setState({...state, activePage: page})
            getTokens(props.project.id.toUpperCase(), 10, page);
        }
    };

    const handleHighlight = (node) => {
        setState({...state, highlighted: node});
    };

    const highlightAll = () => {
        // const { allLicenses } = state;
        // const idsLicenses = allLicenses.map((license) => {
        //   return license.id;
        // });
        // setState({...state,  arrayOfHighlighted: idsLicenses })
    };

    const handleModal = () => {
        setState({...state, showModal: !state.showModal});
    };

    const showScheduleBtn = (license) => {
        setState({...state, isShowScheduleBtn: true, license})
    };

    const sellAllLicenses = () => {
        setState({...state, showSellAllModal: !state.showSellAllModal})
    };

    const onClickLicense = (id, token) => {
        setState({...state, selectedLicene: id, isShowScheduleBtn: true});
        // setToken(token)
        setMarkedToken(token)
    };

    const render = () => {
        const {
            activePage,
            numberOfElementOnOnePage,
            highlighted,
            showSellAllModal,
            showModal,
            isShowScheduleBtn,
            license
        } = state;
        const {
            isSellAllLicenses,
            handleSellAllLicenses,
            project,
            token,
            privKey,
            licenseIsLoading,
        } = props;

        let licenses = [];
        if (token) {
            licenses = token.licenses;
        }


        let numberOfPages = Math.ceil(totalLicenses / numberOfElementOnOnePage);


        // tokens && console.log("TOKEN ---->", tokens);
        const licensesRequestResult = (token == null) ? (
            <div className='image-spinner-frame' style={{position: 'static', minHeight: '355px', height: '100%'}}>
                <div className="loader__blue">Loading...</div>
            </div>
        ) : (<div className="processDataMessage"> Buy collectibles to fill your portfolio</div>);

        // console.log(props,'=======PROPS?<=======s')
        return (
            <div className="collectible-portfolio">
                <div>
                    <div className={props.type === "v" ? "pS" : "pShoriz"} style={{
                        position: 'relative',
                        minHeight: '355px'
                    }}>
                        {!tokens ?
                            licensesRequestResult
                            :
                            <div>
                                <div className="collectible-portfolio-body">
                                    <ul className="pieID legend">
                                        {
                                            tokens.map((ele, index) =>
                                                    <PortfolioSquareItem
                                                        privKey={privKey}
                                                        properties={props}
                                                        index={index + 1}
                                                        tokens={tokens}
                                                        setTokens={setTokens}
                                                        id={ele.properties.id}
                                                        key={`portfolio-square-item-key-${index}`}
                                                        license={ele}
                                                        setIndexItem={setIndexItem}
                                                        selectedLicene={state.selectedLicene}
                                                        onClickLicense={onClickLicense}
                                                        logo={props.project.logoAttach[0]}
                                                        isReadyToSendToOtherWallets={false}
                                                        // isReadyToSendToOtherWallets={checkLicense(item.projectId)}
                                                        handleHighlight={handleHighlight}
                                                        highlighted={highlighted}
                                                        isSellAllLicenses={isSellAllLicenses}
                                                        highlightAll={highlightAll}
                                                        showScheduleBtn={showScheduleBtn}
                                                        handleSellAllLicenses={handleSellAllLicenses}/>
                                                // if (index >= numberOfElementOnOnePage * (activePage) && index < numberOfElementOnOnePage * (activePage + 1)) {
                                                //     const id = ele.id;
                                                //     const licenseObj = {
                                                //         id,
                                                //         logo:token.extraInfo.logoAttach[0],
                                                //         tickerSymbol:token.symbol,
                                                //         numOfLicenses:licenses.length,
                                                //         AuctionId:"",
                                                //         isReadyToSendToOtherWallets:false
                                                //     };
                                                //
                                                //     return <PortfolioSquareItem
                                                //       id={11}
                                                //                                 index = {index+1}
                                                //                                 key={`portfolio-square-item-key-${index}`}
                                                //                                 license={ele}
                                                //                                 selectedLicene = {state.selectedLicene}
                                                //                                 onClickLicense = {onClickLicense}
                                                //         // isReadyToSendToOtherWallets={checkLicense(item.projectId)}
                                                //                                 handleHighlight={handleHighlight}
                                                //                                 highlighted={highlighted}
                                                //                                 isSellAllLicenses={isSellAllLicenses}
                                                //                                 highlightAll={highlightAll}
                                                //                                 showScheduleBtn={showScheduleBtn}
                                                //                                 handleSellAllLicenses={handleSellAllLicenses}/>
                                                // }
                                            )
                                        }
                                    </ul>
                                </div>
                                <div>
                                    {
                                        isShowScheduleBtn && state.selectedLicene !== null && !isSellAllLicenses &&
                                        <div className="schedule-show-btn" onClick={() => handleModal()}>
                                            Schedule an auction
                                        </div>
                                    }
                                    {
                                        licenses.length > 0 && isSellAllLicenses &&
                                        <div className="schedule-show-btn" onClick={sellAllLicenses}>
                                            Sell all
                                        </div>
                                    }

                                    <Paginate activePage={activePage}
                                              prevLink={prevLink}
                                              numberOfPages={numberOfPages}
                                              currentPages={currentPages}
                                              nextLink={nextLink}/>

                                </div>
                            </div>
                        }
                    </div>
                </div>
                {
                    showModal && !showSellAllModal ?
                        <ScheduleAnAuction
                            privKey={privKey}
                            handleModal={handleModal} indexItem={indexItem} logo={props.project.logoAttach[0]}
                            license={state.selectedLicene}
                            token={markedToken}
                            type="single"/> : null
                }
                {/* {
                    showSellAllModal && !showModal ?
                        <ScheduleAnAuction handleModal={sellAllLicenses} licenses={licenses} license={licenses[0]}
                                           type="all"/> : null
                } */}
            </div>
        )
    }

    return render();
}

const Paginate = ({activePage, prevLink, numberOfPages, nextLink, currentPages}) => {
    console.log(currentPages, "------------- currentPages")
    if (numberOfPages < 2) return null;
    return (
        <div className="paginate" style={{position: "relative"}}>
            <div className="paginate-space">
                {	//link to previous page
                    activePage >= 1 ?
                        <div className="paginate-prev paginate-unit"
                             onClick={() => prevLink()}>{'Previous'}</div>
                        :
                        <div className="disabled paginate-prev paginate-unit">{'Previous'}</div>
                }
                { //selecting a page
                    [...Array(numberOfPages)].map((e, index) => {
                        if (index >= activePage - 2 && index <= activePage + 2 && index !== 0)

                            return <div onClick={() => currentPages(index)}
                                        className={"paginate-unit " + (activePage === index ? "active" : "")}
                                        key={index}>
                                {index}  </div>
                    })}
                {	//link to next page
                    activePage >= 0 && activePage + 1 !== numberOfPages ?
                        <div className="paginate-next paginate-unit" onClick={() => nextLink()}>{'Next'}</div>
                        :
                        <div className="paginate-next paginate-unit disabled">{'Next'}</div>
                }
            </div>
        </div>
    );
};
