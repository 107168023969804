import React, {Fragment} from "react";
import PaperPlane from '../../../assets/images/paper-plane.png'
import IncomingMail from '../../../assets/images/gmail-logo.png'
import './messages.css'
import ReadMail from '../../../assets/images/read-email.png';
import Parse from "parse";
import UploadMessageToIpfs from "./UploadMessageIPFS";
import {sendParseRequest} from "../../../utils/sendParseRequest";
import {message} from "../../../utils/message";


export class MessagesPages extends React.Component {
    state = {
        messageTab: 'send',
        openedSelect: false,
        projectList: null,
        selectedProject: null,
        uploaded: false,
        filehash: null,
        sented: false,
        itemsData: [],
        activeSendType: '',
        mailText: '',
        currentUser: null,
        isSentMessage: false,
    };


    async componentDidMount() {
        let currentUser = Parse.User.current();

        const list = await sendParseRequest('getInfo', {
            query: "Projects",
            toJSON: true,
            equal: [
                {field: "creator", value: {"__type": "Pointer", "className": "_User", "objectId": currentUser.id}},
                {field: "licenseType", value: "limited"}
            ],
            select: ["projectTitle", "objectId"],
        });

        this.setState({projectList: list, currentUser: currentUser.id});

        if (this.props.location.search !== '') {
            this.changeTab('messageTab', 'incoming')
            this.setState({messageTab: 'incoming'});
        }
    };

    SendMessage = () => {
        this.setState({isSentMessage: true})
        let body = {
            "projectId": this.state.selectedProject.objectId,
            "pdfFile":
                {
                    "multiHash": this.state.filehash,
                    "type": "application/pdf"
                },
        };

        sendParseRequest('messageSavePdf', body)
            .then(res => {
                sendParseRequest('getUserInfoById', {id: res})
                    .then(res => {
                        const redirectUrl = 'http://localhost:3000/#/messages?page=incoming'

                        const recipients = res.map(user => {
                            return user.getUsername()
                        })

                        sendParseRequest('sendEmail', {
                            emailToSend: recipients,
                            emailSendFrom: 'info@fanthebase.com',
                            htmlCode: `
                               <p>You have an important message from your ${this.state.selectedProject.projectTitle} community. Please log on to Fanbase to read, or click this button</p>
                               <a style="font-size: 12px" href="${redirectUrl}">Click</a><br/>
                               <p>You can disable general emails, however, if you are a member of a community, and your vote is required, we will email you after 7 days. After 10 days, we will text you.</p>`,
                        }).then(() => {
                            if (this.state.activeSendType === 'mail') {
                                message('success', 'Message sent')
                            } else {
                                message('success', 'Mail sent')
                            }
                            this.setState({ 
                                sented: true,
                                isSentMessage: false,
                                selectedProject: null,
                                activeSendType: '',
                                uploaded: false})
                        }).catch(() => {
                            message('error', 'Mail was not send')
                        })
                    })
            })
    };

    sendMail = async () => {
        if (this.state.mailText.length > 0) {


            const licenseOwners = await sendParseRequest('getInfo', {
                    query: "Licenses",
                    equal: [{
                        field: "Project", value: {
                            __type: "Pointer",
                            className: "Projects",
                            objectId: this.state.selectedProject.objectId
                        }
                    }],
                    distinct: "Owner"
                }
            ).then(res => res.map(obj => obj.id))

            await sendParseRequest('getUserInfoById', {id: licenseOwners})
                .then(async res => {
                    const recipients = res.map(user => {
                        return user.getUsername()
                    })
                    await sendParseRequest('sendEmail', {
                        emailToSend: recipients,
                        emailSendFrom: 'info@fanthebase.com',
                        message: this.state.mailText,
                    }).then(() => {
                        this.setState({sented: true, mailText: ''})
                        document.getElementById('mailTextarea').value = ''
                    })
                })
        }
    };


    setItemsToIpfs = ({file, addToIpfs, licenseType}) => {
        if (file.type !== 'application/pdf') {
            this.setState({
                [licenseType + 'FileWarning']: true
            });
            return
        } else {
            this.setState({
                [licenseType + 'FileWarning']: false
            })
        }
        this.addToIpfs = addToIpfs;

        let stateItem;

        this.setState({[stateItem]: file, onLoadFile: true}, () => {
            new Promise(async (resolve) => {
                const attachHash = await this.addToIpfs(this.state[stateItem]);
                resolve(attachHash);
            })
                .then(res => {
                        this.setState({
                            onLoadFile: false,
                            uploaded: true,
                            filehash: res
                        })
                    }
                )
        });
    };

    getIncomingMail = (type) => {
        let obj = {type: type, roleId: this.state.currentUser,};

        sendParseRequest('getMessages', obj)
            .then(res => {
                if (res !== 'empty') {
                    this.setState({itemsData: res})
                } else {
                    this.setState({itemsData: []})
                }
            })
    };

    readMessage = (value) => {
        let obj = {userId: this.state.currentUser, message: value.id};

        sendParseRequest('readMessages', obj)
            .then(() => {
                this.getIncomingMail('newMessage')
            })
    };


    changeTab = (type, value) => {
        this.setState({sented: false});

        let stateTypeValue = this.state[type]

        type === "openedSelect"
            ? this.setState({[type]: !stateTypeValue})
            : this.setState({
                [type]: value,
            });

        type === 'messageTab' && value === 'incoming' && this.getIncomingMail('newMessage')
        type === 'messageTab' && value === 'readMessage' && this.getIncomingMail('readMessage')


    };


    selectProject = (project) => {
        this.setState({selectedProject: project, openedSelect: false, uploaded: false});
    };


    render() {
        const {
            messageTab, openedSelect,
            projectList, selectedProject,
            uploaded, filehash, sented,
            itemsData, isSentMessage
        } = this.state;

        return (
            <div className="page profilePage aboutMePage">
                <div className="profilePageWrapper message_wrapper">
                    <div className="profileContentBox message-block" style={{"width": "100%"}}>
                        <div className="profileHeaderT">
                            <p className="title">Messages</p>
                        </div>
                        <div className="messages-container">
                            <div className="messages-left">
                                <div className={messageTab === "send"
                                    ? "messages-left-send messages-active"
                                    : "messages-left-send"}
                                     onClick={() => this.changeTab('messageTab', 'send')}>
                                    <img src={PaperPlane} alt="send"/>Send message
                                </div>
                                <div className={messageTab === "incoming"
                                    ? "messages-left-incoming messages-active"
                                    : "messages-left-incoming"}
                                     onClick={() => this.changeTab('messageTab', 'incoming')}>
                                    <img src={IncomingMail} alt="incoming"/> Incoming mail
                                </div>
                                <div className={messageTab === "readMessage"
                                    ? "messages-left-incoming messages-active"
                                    : "messages-left-incoming"}
                                     onClick={() => this.changeTab('messageTab', 'readMessage')}>
                                    <img src={ReadMail} alt="incoming"/> Read messages
                                </div>
                            </div>
                            {messageTab === "send" &&
                            <div className="messages-right">
                                {isSentMessage ?
                                <div className='image-spinner-frame' style={{'position': 'inherit', 'height': 'fit-content'}}>
                                    <div className="loader__blue">Loading...</div>
                                </div>
                                    :
                                <Fragment>
                                    {projectList && <div className="messages-select_container">
                                        <div className="messages-select_block"
                                             onClick={() => this.changeTab('openedSelect')}>{selectedProject ? selectedProject.projectTitle :
                                            <p>Select your project</p>}
                                        </div>
                                        <ul className={openedSelect ? "messages-select_ul messages-ul_active " : "messages-select_ul"}>
                                            {projectList && projectList.map((project, idx) =>
                                                <li key={project.projectTitle + idx}
                                                    onClick={() => this.selectProject(project)}>{project.projectTitle}</li>
                                            )}
                                        </ul>
                                    </div>}
                                    {selectedProject && this.state.activeSendType === '' &&
                                    <div className="selected-proj-block">
                                        What do you want to send to the investment group
                                        of {selectedProject.projectTitle}?
                                        <div className='selected-proj-block__actions'>
                                            <button onClick={() => this.setState({
                                                activeSendType: 'mail',
                                                sented: false
                                            })}>
                                                Mail
                                            </button>
                                            <button onClick={() => this.setState({
                                                activeSendType: 'pdf',
                                                sented: false
                                            })}>
                                                Pdf with mail
                                            </button>
                                        </div>
                                    </div>}
                                    {this.state.activeSendType === 'pdf' &&
                                    <div className='selected-proj-block__send-block'>
                                        <button className='selected-proj-block__send-block__back-btn'
                                                onClick={() => this.setState({activeSendType: '', uploaded: false})}>
                                            &lt; Back
                                        </button>
                                        <UploadMessageToIpfs setItemsToIpfs={this.setItemsToIpfs}
                                                             uploaded={uploaded}
                                                             onLoadFile={this.state.onLoadFile}
                                                             formats={['pdf']}
                                        />
                                        {uploaded && filehash &&
                                        <button onClick={() => this.SendMessage()} className='finalBttn'>Send</button>}
                                        {/* {sented && <div>Your message successfully sent</div>} */}
                                    </div>}
                                    {this.state.activeSendType === 'mail' &&
                                    <div className='selected-proj-block__send-block'>
                                        <button className='selected-proj-block__send-block__back-btn'
                                                onClick={() => this.setState({activeSendType: ''})}>
                                            &lt; Back
                                        </button>
                                        <textarea className='selected-proj-block__send-block__mail-textarea'
                                                  placeholder='message...'
                                                  id='mailTextarea'
                                                  onChange={e => this.setState({mailText: e.target.value})}
                                        />
                                        <button onClick={this.sendMail} className='finalBttn'>Send All</button>
                                        {/* {sented && <div>Your message successfully sent</div>} */}
                                    </div>}
                                </Fragment>}
                            </div>}
                            {messageTab === "incoming" && <div className="message-pdf-view">{
                                itemsData.length > 0
                                    ? itemsData.map((i) =>
                                        <div className='message-pdf-block'>
                                            <p>New message! Wait, while file from ipfs will loading</p>
                                            <button onClick={() => this.readMessage(i)}>mark message as read</button>
                                            {/* TODO find solution for  */}
                                            <embed src={`https://ipfs.io/ipfs/${i.multihash}`} type='application/pdf'/>
                                            {/* <object src={`https://ipfs.io/ipfs/${i.multihash}`} type='application/pdf'/> */}
                                            {/* <embed src={`https://ipfs.io/ipfs/${i.multihash}`} type='application/pdf' pluginspage="http://www.adobe.com/products/acrobat/readstep2.html" alt="pdf"/> */}
                                            {/* <embed src={`https://docs.google.com/viewer?url=https://ipfs.io/ipfs/${i.multihash}&a=gt`} type="application/pdf" /> */}
                                        </div>
                                    )
                                    : <p>No Incoming Messages</p>}
                            </div>}
                            {messageTab === "readMessage" && <div className="message-pdf-view">{
                                itemsData.length > 0
                                    ? itemsData.map((i) =>
                                        <div className='message-pdf-block'>
                                            <p>You already read this message</p>
                                            <embed src={`https://ipfs.io/ipfs/${i.multihash}`} type='application/pdf'/>
                                        </div>
                                    )
                                    : <p>No read messages</p>}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
