"use strict";
const configs = require("./configs");
const { IsObjectExists, ValidationArray, div_decimals, mul_decimals } = require("./helpers");
const { ethers } = require("ethers");
const axios = require("axios");

export const swapTokens_Main_WallyFactory = (networkEndPoint) => {
  const provider = new ethers.providers.JsonRpcProvider(networkEndPoint);

  async function ERC20Transfer(contractAddress, POST_URL, privateKey, toAddress, amount, gasPrice) {
    if (!IsObjectExists(contractAddress)) {
      throw new Error(`Token address is invalid!`);
    }
    if (!IsObjectExists(toAddress)) {
      throw new Error(`User-Receiver address is invalid!`);
    }
    const signer = new ethers.Wallet(privateKey, provider);

    const contract = new ethers.Contract(contractAddress, configs.CONTRACT_ABI.ERC20ForSwap_ABI, signer);
    const decimals = await contract.decimals();

    const calcGasPrice = ethers.utils.parseUnits(`${gasPrice}`, "gwei");

    try {
      const tx = await contract.transfer(toAddress, mul_decimals(amount, decimals), {
        gasPrice: calcGasPrice.toHexString(),
      });
      console.debug("not mined tx: ", tx);
      //console.debug("tx.v: ", tx.v, " typeof => ", typeof tx.v);
      const _txResult = await tx.wait();
      if (IsObjectExists(_txResult)) {
        //console.debug("receipt tx tx: ", _txResult);

        console.debug("POST_URL: ", POST_URL);

        try {
          const res = await axios.post(POST_URL, {
            txhash: _txResult.transactionHash,
          });
          console.debug("res.data ", res.data);
        } catch (error) {
          console.error(error.message);
          console.error(error.response.data.error);
          throw new Error(`${error.message}. ${error.response.data.error}`);
        }

        return {
          from: _txResult.from,
          to: _txResult.to,
          gasUsed: IsObjectExists(_txResult.gasUsed) ? _txResult.gasUsed.toString() : "",
          blockNumber: _txResult.blockNumber,
          blockHash: _txResult.blockHash,
          transactionHash: _txResult.transactionHash,
          contractAddress: IsObjectExists(_txResult.contractAddress) ? _txResult.contractAddress.toString() : "",
          logs: ValidationArray(_txResult.logs) == true ? _txResult.logs : [],
        };
      } else return {};
    } catch (e) {
      console.error(`Error transaction. ${e.message}`);
      throw new Error(e.message);
    }
  }

  return {
    /**
     * @Description Get token balance on current network
     * @param {string} contractAddress Address of ERC-20 contract (token)
     * @param {string} walletAddress Address of user wallet
     * @returns {String}
     */
    getBalance: async (contractAddress, walletAddress) => {
      if (IsObjectExists(walletAddress) == false) {
        throw new Error(`Incorrect wallet address: (${walletAddress})`);
      }
      const contract = new ethers.Contract(contractAddress, configs.CONTRACT_ABI.ERC20ForSwap_ABI, provider);

      const decimals = await contract.decimals();
      const tokenBalance = await contract.balanceOf(walletAddress);                         

      return IsObjectExists(tokenBalance) ? div_decimals(tokenBalance, decimals) : "";
    },

    /**
     * Return structure of mined transaction
     * @typedef {Object} TransactionResult
     * @property {string} from Address from
     * @property {string} to Receiver address
     * @property {string} gasUsed blockchain sysinfo(never mind)
     * @property {number} blockNumber Number of current block
     * @property {string} blockHash Hash-Header of current block
     * @property {string} transactionHash Transaction hash
     * @property {string} contractAddress Contract address
     * @property {Array} logs Transaction logs
     */
    /**
     * @Description Method for trade wally tokens to ethereum tokens
     * @param {Boolean} isWally Switcher, what operation user want to exec (buy wally tokens(for eth tokens) or sell wally tokens(to get eth))
     * @param {string} contractAddress Address of ERC-20 contract (token)
     * @param {string} mainIP IP address of host, which deployed coin-server
     * @param {string} privateKey Wallet private key from Mnemonic
     * @param {string} amount Amount to send
     * @param {number} gasPrice Selected gas price (Gwei)
     * @returns {Promise<TransactionResult>} Transaction object with fields (from, to, gasUsed, blockNumber, blockHash, transactionHash, contractAddress, logs)
     */
      transferTokens: async (isWally = true, contractAddress, mainIP, privateKey, amount, gasPrice) => {
      let route = "";
      let toAddress = "";
      if (isWally) {
        route = "/tradetokenswallymain/sell";
        toAddress = configs.HEX_CONSTANTS.ONE_ADDRESS;
      } else {
        route = "/tradetokenswallymain/buy";
        toAddress = configs.HEX_CONSTANTS.ADMIN_WALLET;
      }
      return await ERC20Transfer(contractAddress, `${mainIP}${route}`, privateKey, toAddress, amount, gasPrice);
    },

    /**
     * @Description Returns the transaction object
     * @param {string} txHash Transaction hash
     * @returns {any}
     */
    getTransaction: async (txHash) => {
      if (IsObjectExists(txHash) == false) {
        throw new Error(`Incorrect transaction Hash: (${txHash})`);
      }
      const tx = await provider.getTransaction(txHash);
      return tx;
    },

    /**
     * @Description Returns the transaction receipt object
     * @param {string} txHash Transaction hash
     * @returns {any}
     */
    getTransactionReceipt: async (txHash) => {
      if (IsObjectExists(txHash) == false) {
        throw new Error(`Incorrect transaction Hash: (${txHash})`);
      }
      const tx = await provider.getTransactionReceipt(txHash);
      return tx;
    },
  };
};

// /tradetokenswallymain/buy <- eth tokens to wally
// /tradetokenswallymain/sell  <- wally tokens to eth
