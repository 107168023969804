export const categoriesForInvestors = [
    {
        title: "MUSIC, BEATS & SOUNDS",
        titleForCategory: "Finance Albums, Songs and Sounds!",
        description: "On Fanbase, musicians and audio engineers can auction and trade various types of recordings. There may even be a collectors market for bird or whale songs...",
        image: "Thedrumsetonthestage2.jpg",
        link: "/fanancers/music",
    },

    {
        title: "FILM, VIDEO & MOVING IMAGES",
        titleForCategory: "Finance Films, Shorts & Cool Videos!",
        description: "On Fanbase, filmmakers can finance new films and auction footage. There may even be a collectors market for short films or auctions for videos with the potential to go viral...",
        image: "VideoCamera.jpg",
        link: "/fanancers/film",
    },

    {
        title: "PHOTOS, GRAPHICS, GIFS & MEMES",
        titleForCategory: "Finance Digital Images & Funny Pics!",
        description: "On Fanbase, photographers and graphic designers can auction and exchange various images. There may even be a market for funny, brandable memes...",
        image: "Photography-origin.jpg",
        link: "/fanancers/photo",
    },
    {
        title: "MAGAZINES, NEWSPAPERS & JOURNALISM",
        titleForCategory: "Finance Digital News!",
        description: "On Fanbase, editors can finance new publications and writers can auction stories. There may even be a marketplace for articles to be optioned as screenplays...",
        image: "NEwspaper-2.jpg",
        link: "/fanancers/magazine",
    },
    {
        title: "RADIO, PODCASTS & AUDIO CURRICULA",
        titleForCategory: "Finance Radio, Podcasts & Learning!",
        description: "On Fanbase, show hosts and educators can auction and trade various types of audio broadcasts. There may even be a collectors market for specialized curricula or inside tips...",
        image: "dg3.jpg",
        link: "/fanancers/radio",
    },
    {
        title: "GAMES, AUGMENTED REALITY & VR",
        titleForCategory: "Finance Digital Games & Virtual Reality!",
        description: "On Fanbase, game designers can auction and trade various types of games. There may even be a collectors market for individualized content or specialized augmentation...",
        image: "ComputerGames2.jpg",
        link: "/fanancers/games",
    },
    {
        title: "BOOKS, COMICS, SCRIPTS & STORIES",
        titleForCategory: "Finance Digital Novels & Comic Books!",
        description: "On Fanbase, writers can auction and trade various types of literary and pictorial items. There may even be a market for creating and auctioning limited edition comics...",
        image: "books.jpg",
        link: "/fanancers/books",
    },
    {
        title: "APPS, SOFTWARE, TECH & WEBSITES",
        titleForCategory: "Finance Innovative Media Technologies!",
        description: "On Fanbase, programmers can auction and trade various types of tech. There may even be a collectors market for quirky apps. We do not accept hardware tech...",
        image: "App.jpg",
        link: "/fanancers/apps",
    },
    {
        title: "TV & WEB SERIES",
        titleForCategory: "Finance New TV Shows & Web Series!",
        description: "On Fanbase, producers can auction and trade various types of programs. We think there will be a cool collectors market for special episodes like alternate endings...",
        image: "VintageTV.jpg",
        link: "/fanancers/tv",
    },
];

export const cardsData = [
    {
        title: 'It\'s like owning a record label...',
        text: 'On Fanbase, fans finance albums from their favorite\n' +
            '                            musicians. When those musicians sell music, fans earn\n' +
            '                            royalty payments like members of the band.',
        sliderTitle: "Music, Beats, Songs & Sounds"
    }, {
        title: 'It\'s like being the executive producer of a movie...',
        text: 'On Fanbase, fans finance feature films, short films, moving images and videos from\n' +
            '                            cinematographers, directors and producers. When those films sell to audiences, fans can earn dividends or royalty payments over time.\n' +
            '                            ',
        sliderTitle: "Film, Video & Moving Images"
    }, {
        title: 'It\'s like owning a baseball card shop...',
        text: 'On Fanbase, fans finance funny memes, collectible cards, or digital images from their\n' +
            '                            favorite photographers, graphic designers or models. When those images succeed at auction or\n' +
            '                            sell to a mass audiences, fans earn lump sum payments or royalty payments over time,\n' +
            '                            respectively.',
        sliderTitle: "Photography, Graphics, Gifs & Memes"
    }, {
        title: 'It\'s like owning a newspaper chain...',
        text: 'On Fanbase, fans finance digital periodicals, dailies, magazines, ezines & digital\n' +
            '                            newspapers.\n' +
            '                            When that journalism succeeds at auction or sells to a mass audience, fans earn lump sum\n' +
            '                            payments, or royalties payments over time, respectively.',
        sliderTitle: "Magazines, Newspapers, & Journalism (Digital)"
    }, {
        title: 'It\'s like owning a broadcast network...',
        text: 'On Fanbase, fans finance radio shows, podcasts, and curricula\n' +
            '                            from their favorite personalities, hosts, DJs, curators or educators. In return, fans earn\n' +
            '                            royalties when\n' +
            '                            those programs achieve audiences and advertisers. In some instances, there may be\n' +
            '                            talk show content that seeks a private audience or collectors market. When that media sells\n' +
            '                            at auction,\n' +
            '                            financers earn lump sum payments.',
        sliderTitle: "Radio, Podcasts & Audio Curricula"
    }, {
        title: 'It\'s like owning a game design lab...',
        text: 'On Fanbase, fans finance games, computer games, video games, virtual reality, and augmented\n' +
            '                            reality,\n' +
            '                            from their favorite game designers. When a a game sells at auction,\n' +
            '                            fans earn lump sum payments. When a game seeks mass appeal, fans earn royalties over time.\n' +
            '                            There are new markets for limited edition or obscure games, as well as mass markets.',
        sliderTitle: "Games, Augmented Reality & Virtual Reality"
    }, {
        title: 'It\'s like owning a publishing company...',
        text: 'On Fanbase, fans finance digital books, comics,\n' +
            '                            stories, literature and words\n' +
            '                            from their favorite writers. In return, fans earn lump sum payments if those works are\n' +
            '                            auctioned, or\n' +
            '                            royalties if those works sell to mass markets.',
        sliderTitle: "Books, Comics, Short Stories & Passages"
    }, {
        title: 'It\'s like owning your own tech VC...',
        text: 'On our platform, fans finance apps, software & media technologies\n' +
            '                            from their favorite developers. When those technologies sell, or increase in value,\n' +
            '                            fans earn lump sum payments or royalty payments over time.',
        sliderTitle: "Apps, Software & Websites"
    }, {
        title: 'It\'s like owning your own TV studio...',
        text: 'On our platform, fans finance television shows and web series from their favorite actors,\n' +
            '                            hosts, directors and producers. When those shows sell, or succeed as narrowcasts or\n' +
            '                            broadcasts, fans earn lump sum payments or royalty payments over time.',
        sliderTitle: "TV & Web Series"
    }
]