import React, {useState} from 'react';
import uploadIcon from '../../../assets/images/cloud-file-upload.svg';
import checkMarkIcon from '../../../assets/images/tick.svg';

const UploadInsuranceToIpfs = ({setInsuranceItem, insuranceItem, insuranceFileType, name, node, blockName}) => {

    const [sizeWarning, setSizeWarning] = useState(false);

        const addToIpfs = async (file) => {

            const {path} = await node.add(file);
            console.log(path, '--path--')
            if (path) {
                console.log(path);
                // await node.stop();
                // setSNode(false)
                return path
            }
        };

        const onFileDownload = async (evt) => {
            if (evt.target.files[0] && node) {
                let file = evt.target.files[0];
                const hash = await addToIpfs(file);

                setInsuranceItem({
                    file,
                    hash,
                    blockName,
                   tab: "projectAttach"
                });
            }
        };

    return (

        <div className="section-input-wrap">
            <div className="uploadItems-wrapper uploadAuctionItems" >
                <label >
                    <input type="file"
                           id="test123"
                           hidden={true}
                           onChange={onFileDownload}
                           name="attachItem"
                           data-isrequiredfield={false}
                           data-action="compare"
                           data-valueforcheck=""/>

                    {insuranceFileType || !insuranceItem ? <img src={!insuranceItem ? uploadIcon : checkMarkIcon} alt="icon"/>
                        : <div className="creator_label-incorrect"> Incorrect format, please download pdf <br/>
                            <img src={uploadIcon} alt="icon"/>
                        </div>}
                </label>


            </div>
        </div>
    );
};

export default UploadInsuranceToIpfs;

