//Actual utils for encrypt/decrypt wallets in local storage. Add wallets. Oct 2021.
import Encryptor from '../app/helpers/encrypt'

class LocalStore {
  constructor(masterKey) {
    this.masterKey = masterKey;
  }

  get(key) {
    const encryptor = new Encryptor(this.masterKey);
    console.log(key, "--------------key")

    const encrypted = localStorage.getItem(key);
    if (encrypted) {
      return encryptor.decrypt(encrypted);

    } else {
      return []
    }
  }

  set(key, value) {
    const encryptor = new Encryptor(this.masterKey);
    console.log(key, encryptor.encrypt(value), "--------------key, encryptor.encrypt(value)")
    localStorage.setItem(key, encryptor.encrypt(value));
  }

  getPlain(key) {
    return localStorage.getItem(key);
  }

  setPlain(key, value) {
    localStorage.setItem(key, value);
  }

  getDecoded(key) {
    let data = localStorage.getItem(key);
    if (!data) {
      return null;
    } else {
      let buff = new Buffer(data, 'base64');
      return buff.toString('ascii');
    }
  }

  setEncoded(key, value) {
    console.log(key, value, "--------------key, value")
    if (!value) {
      localStorage.setItem(key, '');
    } else {
      let buff = new Buffer(value);
      localStorage.setItem(key, buff.toString('base64'));
    }
  }
}

export default LocalStore
