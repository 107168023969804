import React, {useEffect, useState, Fragment} from "react";
import '../../Widgets/widgets.css';
import PortfolioSquareItem from "../PortfolioSquareItem/index";
import ScheduleAnAuction from "../ScheduleAnAuction";
import axios from "axios";
import constants from "../../../api/constant";
import {connect} from "react-redux";
import * as blockChainActions from "../../../../modules/blockchain/blockchain.action";
import checkMarkIcon from "../../../../assets/images/check-symbol.svg";
import prepareImagesPathForMinify from "../../../../utils/prepareImagesPathForMinify";
import defaultLogo from "../../../../assets/images/F_in_White.png";
import MyCollectibles from "../MyCollectibles";


const auth = constants.auth;
const CartCollectibles = (props) => {


    // console.log(props.project, "--------------propsPROJJJJJJJJJJJ")
    const [state, setState] = useState({
        activePage: 0,
        numberOfElementOnOnePage: 10,
        highlighted: null,
        showSellAllModal: false,
        showModal: false,
        isShowScheduleBtn: false,
        // project: props.project,
        selectedLicene: null
    });


    const {privKey} = props;
    const [tokens, setTokens] = useState(null);
    const [markedToken, setMarkedToken] = useState(null);
    const [indexItem, setIndexItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [lotAvailable, setLotAvailable] = useState(false);


    const handleModal = () => {
        setShowModal(!showModal)
    };

    useEffect(() => {
        if (props.properties.cart.length > 1) {
            setLotAvailable(true)
        }
    }, [props.properties.cart]);

    const removeFromCart = async (selectedLicense) => {
        let reduxItemObj = {
            selectedLicense: selectedLicense,
            operation: "-"
        };
        props.properties.changeCartItems(reduxItemObj);

        if (props.properties.cart.length === 1) {
            props.setPortfolioTab('collectible')
        }
    };


    const render = () => {
        const {
            activePage,
            numberOfElementOnOnePage,
            highlighted,
            showSellAllModal,
            isShowScheduleBtn,
            license
        } = state;

        const {cart, onSelectProject, tokens} = props.properties;
        cart && console.log(cart, 'license.properties', '---license.propertiespro')

        let className = "portfolioSquareItem";
        return (
            <div className="collectible-portfolio">
                <div>
                    <div className={props.type === "v" ? "pS" : "pShoriz"} style={{
                        position: 'relative',
                        minHeight: '355px'
                    }}>

                        <div>
                            <div className="collectible-portfolio-body">
                                <ul className="pieID legend">
                                    {cart.length > 0 ? <>
                                            {cart && cart.map((license, index) => <Fragment key={index}>


                                                <>
                                                    <li style={{'position': 'relative'}}
                                                        className='portfolioItem_li'

                                                    >
                                                        <div className={className}
                                                            // onClick={() => onClickItem(index)}
                                                        >
                                                            {/*       {*/}
                                                            {/*         isReadyToSendToOtherWallets &&*/}
                                                            {/*         <span className="portfolioSquareItem--isReadyToSendToOtherWallets">*/}
                                                            {/*  <img src={checkMarkIcon} alt="readyToSend"/>*/}
                                                            {/*</span>*/}
                                                            {/*       }*/}

                                                            <div className="logo-small"
                                                                 style={
                                                                     {
                                                                         backgroundImage: `url(${prepareImagesPathForMinify(license.logo || defaultLogo)})`,
                                                                         // backgroundImage: `url(${prepareImagesPathForMinify(logo, 25, 25) || defaultLogo})`,
                                                                         backgroundPosition: "center",
                                                                         backgroundSize: "cover",
                                                                         backgroundRepeat: "no-repeat",
                                                                     }
                                                                 }
                                                            />
                                                            <span
                                                                className="dataPoint">#{license.properties.id.split('-')[1]}</span>
                                                            <p className="title">       {license.properties.tickerSymbol}</p>

                                                            {/*<span className="dataPoint totallicenses">{numOfLicenses}</span>*/}
                                                        </div>
                                                        <div className='portfolioSquareItem_bin-img'
                                                             onClick={() => removeFromCart(license)}
                                                        />
                                                    </li>
                                                </>


                                            </Fragment>)}</>
                                        : <p>You are haven't licenses at the cart yet </p>
                                    }
                                </ul>
                            </div>
                            <div>


                            </div>
                            {lotAvailable && <button className="buyNowBttn tradeBttn" type="button" value="Send"
                                                     onClick={() => handleModal()}>Set lot into auction</button>}
                            {showModal && <ScheduleAnAuction
                                handleModal={handleModal}
                                privKey={privKey}
                                removeFromCart={removeFromCart}
                                lots={props.properties.cart}
                                indexItem={indexItem} logo={defaultLogo} license={'state.selectedLicen'}
                                token={'markedToken'}
                                type="lot"/>


                            }
                        </div>


                    </div>
                </div>


            </div>
        )
    };

    return render();
};


function mapStateToProps({blockchain}) {
    return {
        // tokens: blockchain.tokens,
        // walletAddresses: blockchain.walletAddresses,
        mainWalletAddress: blockchain.mainWalletAddress,
        // projects: blockchain.projects,
        cart: blockchain.cart
    };
}

export default connect(
    mapStateToProps,
    {...blockChainActions}
)(CartCollectibles);

