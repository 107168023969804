import Parse from "parse";

const checkSessionToken = ({error, success}) => {
    Parse.Session.current()
        .then(currSession => {
            const expiresAt = new Date(currSession.get('expiresAt')).getTime();
            const currTime = new Date().getTime();

            if (expiresAt <= currTime) success();
        })
        .catch(() => error());
};

export default checkSessionToken;