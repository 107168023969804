export const mail = (name, song, url) => {
    return (
        `
        <!doctype html>
        <html lang="en">
        <head>
        <meta charset="UTF-8">
        <meta name="viewport"
          content="width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0">
        <meta http-equiv="X-UA-Compatible" content="ie=edge">
        <style type='text/css'>
            .blue-button {
                padding: 12px;
                font-size: 12px;
                font-weight: 400;
                letter-spacing: 0.5px;
                color: #fff !important;
                background: #2567e5;
                border-radius: 3px;
                margin-top: 10px;
                border: 1px solid rgb(6, 103, 208);
                cursor: pointer !important;
                text-decoration: none;
                display: block;
                width: 101px;
            }
        </style>
        </head>
        <body>
            <p>Your Friend <b>${name}</b> uses Fanbase and has gifted you some intellectual property rights. Login here to see the song <b>${song}</b> picked for you</p>
            <a class="blue-button" href='${url}'>Login to Fanbase</a>
        </body>
        </html>`
    )
}