import "./index.css";
import 'react-intl-tel-input/dist/main.css';
import Parse from "parse";
import React from "react";
import ReactDOM from "react-dom";
import {HashRouter, Route, Switch} from "react-router-dom";
import store from "./modules/store";
import {connect, Provider} from "react-redux";
import PageNotFound from "./app/Pages/PageNotFound/";
import * as blockChainActions from "./modules/blockchain/blockchain.action";
import Login from "./app/Pages/AuthPages/Login/Login";
import {Exchange, Exchange2, ExchangeDetailsRoute,} from "./app/Pages/Exchange";
import {Support} from "./app/Pages/Support/support";
import MyTokens from "./app/Pages/WalletMyTokensPageOld/index";
import Wallets3 from "./app/Pages/WalletMyCollectiblesPage";
import CentralToken from "./app/Pages/WalletMyTokensPage";
import ActivityPage from "./app/Pages/Settings/ActivityPage";
import MyTickets from "./app/Pages/WalletMyTicketsPage";
import Contract from "./app/Pages/Contract/Contract";
import Projects from "./app/Pages/Projects/Projects";
import Fanancers from "./app/Pages/Fanancers/Fanancers";
import withAuth from "./app/Components/withAuth";
import ProjectAuctionPage from "./app/Pages/ProjectAuctionPage";
import WallyHome from './app/Pages/WalletsMainPage/wallyHome'
import ImportWallet from './app/Pages/WalletsMainPage/importWallet/importWallet'
import ProjectInvestPage from "./app/Pages/ProjectInvestPage";
import {
    FAQ,
    TwoStepVerification,
    TwoStepVerificationSMS,
} from "./app/Components/TwoStepVerification/TwoStepContainer.js";
import ConfirmPrincipalOrCollaborator from "./app/Pages/ConfirmPrincipalOrCollaborator";
import ResetPassword from "./app/Pages/AuthPages/ResetPassword/ResetPassword";
import ErrorPage from "./app/Pages/ErrorPage";
import {MessagesPages} from "./app/Pages/Messages";
import {Footer} from "./app/Components/Footer/Footer";
import {Registration} from "./app/Pages/AuthPages/Registration/Registration";
import Header from "./app/Components/Header/Header";
import Compliance from "./app/Pages/Settings/Compliance/Compliance";
import {ThankYou} from "./app/Pages/ThankYou/ThankYou";
import {VerificationSummary} from "./app/Pages/AuthPages/VerificationSummary/VerificationSummary";
import {Referrals} from "./app/Pages/Referrals/Referrals";
import {Preferences} from "./app/Pages/Settings/Preferences/Preferences";
import {Security} from "./app/Pages/Settings/Security/Security";
import {Limits} from "./app/Pages/Settings/Limits/Limits";
import {ForgotPassword} from "./app/Pages/AuthPages/ForgotPassword/ForgotPassword";
import {Terms} from "./app/Pages/LegalPages/Terms";
import {Privacy} from "./app/Pages/LegalPages/Privacy";
import {AML_CFT} from "./app/Pages/LegalPages/AML_CFT";
import {About} from "./app/Pages/LegalPages/About";
import {Assets} from "./app/Pages/Assets/Assets";
import {MainPage} from "./app/Pages/MainPage/MainPage";
import Card from "./app/Pages/Fanancers/Card";
import {UserProfile} from "./app/Pages/UserProfile/UserProfile";
import {sendParseRequest} from "./utils/sendParseRequest";
import ConfirmAuctionPage from "./app/Pages/ConfirmAuctionPage/ConfirmAuctionPage";
import ProjectLotPage from "./app/Pages/ProjectLotPage/ProjectLotPage";
// import {Creators} from "./app/Pages/Creators/Creators";


Parse.initialize("ZF3GT5g2UuyztbZSZgkeP8WLC4qKYHrkJX2Vccf8wcxAyzWJKabpjzytBf", 'agFNUVQfcSFmuQr2EY3Af2vHegHw8hhzhgdRrN9fMFfuuawgL3d4ukNG8q');
// Parse.initialize("ZF3GT5g2UuyztbZSZgkeP8WLC4qKYHrkJX2Vccf8wcxAyzWJKabpjzytBf",'agFNUVQfcSFmuQr2EY3Af2vHegHw8hhzhgdRrN9fMFfuuawgL3d4ukNG8q' );


Parse.serverURL = "https://fanbaseprod.herokuapp.com/parse";
// Parse.serverURL = "http://localhost:1337/parse";

// Parse.masterKey = 'agFNUVQfcSFmuQr2EY3Af2vHegHw8hhzhgdRrN9fMFfuuawgL3d4ukNG8q';

  if (window.location.href === 'http://www.fanbase.io/') {
      window.location.replace("https://www.fanbase.io/#/");
    // Router.push({
    //   pathname: "https://mearsy.com",
    // });
  }

class App extends React.Component {
    render() {
        return (
            <HashRouter onUpdate={() => window.scrollTo(0, 0)} >
                <Switch>
                    <Route exact path="/" component={MainPage}/>
                    <Route exact path="/confirm_auction"
                           component={withAuth(ConfirmAuctionPage)}
                    />
                    <Route exact path="/fanancers" component={Fanancers}/>
                    <Route exact path="/fanancers/*" component={Card}/>
                    <Route exact path="/messages" component={withAuth(MessagesPages)}/>
                    <Route exact path="/massmarket/:tokenURI" component={withAuth(ProjectInvestPage)}/>
                    <Route exact path="/projectLot/:id" component={withAuth(ProjectLotPage)}/>
                    <Route exact path="/projectLot" component={withAuth(ProjectLotPage)}/>
                    <Route exact path="/collectormarket/:tokenURI" component={(ProjectAuctionPage)}/>
                    <Route exact path="/terms" component={Terms}/>
                    <Route exact path="/privacy" component={Privacy}/>
                    <Route exact path="/amlcft" component={AML_CFT}/>
                    {/*<Route exact path="/about" component={About}/>*/}
                    <Route exact path="/assets" component={Assets}/>
                    {/* <Route path={["/register", "/register/:key"]} component={Registration}/> */}
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/forgot" component={ForgotPassword}/>
                    <Route exact path="/verifyEmail/:key" component={VerificationSummary}/>
                    <Route path="/thankyou" component={ThankYou}/>
                    <Route exact path="/signin_step_two" component={TwoStepVerification}/>
                    <Route exact path="/signin_step_two_sms" component={TwoStepVerificationSMS}/>
                    <Route exact path="/faq" component={FAQ}/>
                    <Route path="/wallets" component={withAuth(MyTokens)}/>
                    <Route path="/wallets2" component={withAuth(MyTickets, 'noWallet')}/>
                    <Route path="/tokens" component={withAuth(CentralToken)}/>
                    <Route path="/collectibles" component={withAuth(Wallets3, 'noWallet')}/>
                    <Route exact path="/exchange" component={withAuth(Exchange)}/>
                    <Route exact path="/exchange2" component={withAuth(Exchange2)}/>
                    <Route path="/exchange/:key" component={withAuth(ExchangeDetailsRoute)}/>
                    <Route exact path="/referrals" component={withAuth(Referrals)}/>
                    <Route exact path={["/Support", "/Support/:key"]} component={withAuth(Support)}/>
                    <Route exact path="/contract" component={withAuth(Contract, 'noWallet')}/>
                    <Route exact path="/projects" component={withAuth(Projects, 'noWallet')}/>
                    <Route exact path="/settings/compliance" component={withAuth(Compliance)}/>
                    <Route exact path="/settings/preferences" component={withAuth(Preferences)}/>
                    <Route exact path="/settings/security" component={withAuth(Security)}/>
                    <Route exact path="/settings/limits" component={withAuth(Limits)}/>
                    <Route exact path="/settings/activity" component={withAuth(ActivityPage)}/>
                    <Route exact path="/profile" component={withAuth(UserProfile)}/>
                    <Route exact path="/wallet" component={withAuth(WallyHome)}/>
                    <Route exact path="/import-wallet" component={withAuth(ImportWallet)}/>
                    <Route exact path="/confirm/:type/:id" component={
                        withAuth((props) => (<ConfirmPrincipalOrCollaborator {...props} />))}
                    />

                    <Route exact path="/reset_password" component={ResetPassword}/>
                    <Route exact path="/error" component={ErrorPage}/>
                    <Route exact path="/404" component={PageNotFound}/>
                    {/*<Route exact path="/creators" component={Creators}/>*/}
                    <Route path="*/**" component={PageNotFound}/>
                </Switch>
            </HashRouter>
        );
    }
}

const MyApp = connect(null, {...blockChainActions})(App);

ReactDOM.render(
    <Provider store={store}>
        <HashRouter>
            <>
                <Header/>
                <MyApp/>
                <Footer/>
            </>
        </HashRouter>
    </Provider>,
    document.getElementById("fanbase")
);
