//Utils for work with wallet + eth wallet (get addresses + balance). Oct 2021. Actual

import LocalStore from "./localStore";
import {walletService} from "../app/api/tendermint/main"
import constants from "../app/eth_client_public/constants"
import ethClient2 from "../app/eth_client_public";
import axios from "axios";
import constantsApi from "../app/api/constant";


/**
 * Description: local func for get active WALLY (Actually, USDW) balance
 * [active_address] active address WALLY our tendermint)
 *
 * return [balance: num]
 * @static
 */
const getWallyBalanceFunc = async (active_address) => {
    if (active_address) {
        let gettedBalance = await walletService.getUSDWBalance(active_address)
        if (gettedBalance) return gettedBalance.balance;
    }
}


/**
 *
 * Description: this local func for get info about all wallets (wally-usdw and eth-based)
 * [wallyActiveAddress] active WALLY(actually, USDW) address string <br/>
 * [ethActiveAddress] active ETH wallet address <br/>
 * [wallyWalletsString] decoded from local storage string with all WALLY (actually, USDW) wallets <br/>
 * [ethWalletsString] decoded from local storage string with all ETH (actually, USDW) wallets <br/>
 * [pinCodeLS] decrypted pin-code password for localstorage  <br/>
 */
const getWalletsInfo = () => {
    let localStore = new LocalStore()
    let masterkeyLs = localStore.getDecoded('userToken');
    if (masterkeyLs) {
        const localStoreMaster = new LocalStore(masterkeyLs);
        const walletsString = localStoreMaster.get('walletsList');
        const walletsStringEth = localStoreMaster.get('walletsListEth');

        let active_address, walletsArrFirst, active_addressEth;
        if (walletsString || walletsStringEth

        ) {

            if (localStorage.getItem('active_address')) {
                active_address = localStorage.getItem('active_address')
            }
            if (localStorage.getItem('active_addressEth')) {
                active_addressEth = localStorage.getItem('active_addressEth')
            }


            if (active_address && active_addressEth) {
                console.log(4444)
                return {
                    wallyActiveAddress: active_address,
                    ethActiveAddress: active_addressEth,
                    wallyWalletsString: walletsString,
                    ethWalletsString: walletsStringEth,
                    pinCodeLS: localStoreMaster
                }
            } else if (active_address) {
                console.log(6666666)
                return {
                    wallyActiveAddress: active_address,
                    wallyWalletsString: walletsString,
                    pinCodeLS: localStoreMaster,
                    ethActiveAddress: null,
                    ethWalletsString: null
                }
            } else if (active_addressEth) {
                console.log(333333)
                return {
                    ethActiveAddress: active_addressEth,
                    ethWalletsString: walletsStringEth,
                    wallyActiveAddress: null,
                    wallyWalletsString: null,
                    pinCodeLS: localStoreMaster
                }
            } else {
                console.log(5555)
                return {
                    ethActiveAddress: null,
                    ethWalletsString: null,
                    wallyActiveAddress: null,
                    wallyWalletsString: null,
                    pinCodeLS: null
                }
            }
        }

    } else return false

}


/**
 * Description: this EXPORT func for get active wally (in a fact - actually, USDW) for tendermint blockchain (from localStorage)
 */
export const getWallyWalletAddress = async () => {
    let wallyActiveAddress = await getWalletsInfo().wallyActiveAddress
    return (wallyActiveAddress)
}


/**
 * Description: this EXPORT func for get BALANCE for active wally (in a fact - actually, USDW)
 */
export const getUSDWWalletBalance = async () => {
    let activeAddressWally = await new Promise(async (resolve, reject) => {
        let active_address = await getWalletsInfo().wallyActiveAddress
        if (active_address) {
            resolve(active_address)
        } else {
            reject({error: 'error wallet address'})
        }
    })
    console.log(activeAddressWally, '---activeAddressWally')
    if (activeAddressWally) {
        let wallyBalance = await getWallyBalanceFunc(activeAddressWally)
        return (+wallyBalance)
    }
}


/**
 * Description: this EXPORT func for get active address of Eth-based wallet (from localStorage) and privateKey
 */
export const getEthWalletAddress = async () => {
    let walletsInfo = await getWalletsInfo();
    if (walletsInfo.ethActiveAddress !== undefined) {
        console.log(111111111111111111111)
        let ethActiveAddress = walletsInfo.ethActiveAddress
        if (ethActiveAddress) {
            let ethWalletString = JSON.parse(await getWalletsInfo().ethWalletsString)
            let activePrivateKey;
            ethWalletString.map((wallet) => {
                if (wallet.address === ethActiveAddress) {
                    activePrivateKey = wallet.privKey

                }
            })
            return ({ethActiveAddress: ethActiveAddress, ethActivePrivateKey: activePrivateKey})
        }
    } else return null
}


/**
 * Description: this EXPORT func for get active address of Eth-based wallet (from localStorage)
 */
export const getWalletsInfoUtil = async () => {
    let walletsInfo = await getWalletsInfo()
    return (walletsInfo)
}


/**
 * Description: get balance of alt coins on eth-based wallet
 */
export const getAltCoinsBalance = async () => {
    let {wallyActiveAddress, ethActiveAddress} = await getWalletsInfo();
    console.log('🐍🐍🐍🐍🐍🐍🐍🐍🐍🐍')
    console.log(wallyActiveAddress, ethActiveAddress, 'wallyActiveAddress, ethActiveAddress')
    if (ethActiveAddress) {
        const response = await ethClient2.getBalances(ethActiveAddress, constants.ASSETS);
        console.log(response, '------res--RESSS--response')
        const _balances = {FNBD: 1000};

        constants.ASSETS.map(a => {
            console.log(a, '----a', 'USDW')
            if (a !== "USDW")
                if (a !== "FNBD") {
                    _balances[a] = response[a]
                }
        })

        console.log(_balances, '----_balances')

        return _balances
    }

}


/**
 * Description: get decrypted WALLY wallet object
 * need a password!
 */
export const getWallyWalletObject = async (password) => {
    console.log(11111, password)
    if (password) {
        let wallyWalletArr = JSON.parse(await getWalletsInfo().wallyWalletsString);
        let activeWalletAddress = await getWalletsInfo().wallyActiveAddress;

        // getActualWalletData Promise - need for take serJsonString from active wallet.
        let getActualWalletData = await new Promise((resolve, reject) => {
            let walletObject = {
                address: null,
                serialString: null
            };
            wallyWalletArr && wallyWalletArr.forEach((wallet) => {
                if (wallet !== null) {
                    if (wallet.addresses[0]) {
                        if (wallet.addresses[0] === activeWalletAddress) {
                            walletObject.address = activeWalletAddress;
                            walletObject.serialString = wallet.serJsonString;
                        }
                    }
                }
            })

            if (walletObject.address && walletObject.serialString) resolve(walletObject);
            else reject({error: "you haven't active wallet or walletString"})
        })

        if (getActualWalletData) {

            let deсryptedWalletObj = await walletService.decryptSerializedJsonStr(getActualWalletData.serialString, password)
            if (deсryptedWalletObj) return deсryptedWalletObj

        }
    } else return ({error: "you should enter the password"})
}


/**
 * Description: get WALLY wallet balance (password not need)
 */
export const getWallyBalance = async () => {
    let activeWalletAddress = await getWalletsInfo().wallyActiveAddress
    let deсryptedWalletObj = {walletObject: 'crash endless ask grid inquiry radar certain ship eyebrow kangaroo fresh roast'}
    if (activeWalletAddress) {
        let wallyBalance = await walletService.getWalletBalance(deсryptedWalletObj, activeWalletAddress, 'wally')
        if (wallyBalance) return +wallyBalance
    } else return false

}


/**
 * Description: sent USDW to other wallet
 */
export const sentUSDWToWallet = async (ownerWallet, toWallet, sign, amount) => {
    //===>>>this code actual for AUCTIONS
    let otherWallet;
    if (toWallet) {
        otherWallet = toWallet
    } else {
        otherWallet = constantsApi.adminUSDW
    }
    let data = {
        "isUsdwTransfer": true,
        "from": ownerWallet,
        "to": otherWallet,
        "tx": sign,
        "amount": amount,
    }

    console.log(data, 'data----')
    // const res = await axios.post(
    //     `${constantsApi.apiRoot}/contracts/wally/transferOnBehalf`, {
    //     data
    // });
    // console.log(data, res, '-----datttta3')

}


export const callBurnTokensUSDW = async (password, amount) => {
    if (password) {
        let {wallyActiveAddress, ethActiveAddress} = await getWalletsInfo();

        let decryptWalletObject = await getWallyWalletObject(password)
        if (wallyActiveAddress && ethActiveAddress) {
            console.log(wallyActiveAddress, decryptWalletObject, '--decryptWalletObject22')

            let burnFuncResponse = await walletService.burnUSDW(decryptWalletObject, wallyActiveAddress, amount * 100)
            console.log(burnFuncResponse, '======🍟🍟🍟🍟🍟🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🥑🍟🍟🍟🍟🍟🍟🍟🍟🍟🍟---')
            if (burnFuncResponse) {
                const res = await axios.post(`${constantsApi.usdwHost}sales/sell`, {
                    "txHash": burnFuncResponse.toString(),
                    "ethereumAddress": ethActiveAddress
                });
                return (res);
            }
        }
    }
}
