import React from "react";
import {sendParseRequest} from "../../../utils/sendParseRequest";
import {message} from "../../../utils/message";
import {useHistory} from "react-router";

export const ThankYou = () => {
    const history = useHistory();
    const status = history.location.state?.status;
    const user = history.location.state?.user;

    if (!status) {
        history.push('/')
    }

    const sendEmail = () => {
        sendParseRequest('verifyEmailSignup', {
            email: user.getUsername(),
            objectId: user.id,
            fname: user.get('fname')
        }).then(res => {
            if (res.SendEmailResult) {
                message('success', 'The letter was sent')
            }
        })
    }

    return (
        <div className="loginScreen thankyouScreen" style={{minHeight: 'calc(100% - 62px)'}}>
            <div className="screenWrapper">
                <div className="wrapper-1">
                    <div className="wrapper-2">
                        <p className="thankyouMessage"
                           style={{color: "rgb(46, 204, 113) !important"}}>
                            Thank you!
                        </p>
                        {status === 'individual' ?
                            <>
                                <p>We sent you an email.</p>
                                <p><b>Verify</b> your <b>email address</b> quickly to get started!</p>
                            </>
                            :
                            <p>Legal entities are thoroughly reviewed.<br/> We'll email confirmation or denial
                                within 30 days</p>
                        }
                        <button className='blue-button'
                                onClick={() => history.push('/login')}
                                style={{margin: '20px auto'}}
                        >
                            Login
                        </button>
                    </div>
                    <div className="footer-like">
                        <a onClick={sendEmail}>Click here to send letter again.</a>
                    </div>
                </div>
            </div>
        </div>
    )
}