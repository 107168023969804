import React from 'react';

const OptionsBlock = ({onOptionSelect, percentOfBid}) => (
  <div className="optionsOfProofOfWorkPoll">
    <div className="optionsOfProofOfWorkPoll__option">
      <label>
        <input type="radio"
               onChange={onOptionSelect}
               value="yes"
               hidden={true}
               name="ProofOfWorkPoll"/>

        <div/>

        Yes (+{percentOfBid}%)
      </label>
    </div>

    <div className="optionsOfProofOfWorkPoll__option">
      <label>
        <input type="radio"
               onChange={onOptionSelect}
               value="not yet"
               hidden={true}
               name="ProofOfWorkPoll"/>

        <div/>

        Not yet
      </label>
    </div>
  </div>
);

export default OptionsBlock;