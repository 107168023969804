import React, {useEffect, useState} from 'react';
import Parse from 'parse';
import {sendParseRequest} from "../../../utils/sendParseRequest";
import HeaderTop from "./HeaderTop/HeaderTop";
import HeaderBottom from "./HeaderBottom/HeaderBottom";
import MobileHeader from "./MobileHeader/MobileHeader";
import "./Header.css";
import {useHistory} from "react-router";

const Header = () => {
    const history = useHistory();
    const currentUser = Parse.User.current();

    const [showMenu, setShowMenu] = useState(false)
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const [incomingMailStatus, setIncomingMailStatus] = useState(false)
    const [activePage, setActivePage] = useState('');

    useEffect(() => {
        setActivePage(window.location.hash.substring(2))
    }, []);

    const hideMenu = () => {
        setShowMenu(false)
        setShowMobileMenu(false)
    }

    useEffect(() => {
        currentUser && getIncomingMail(currentUser)
    }, []);

    const getIncomingMail = (currentUser) => {
        sendParseRequest('getMessages', {type: "newMessage", roleId: currentUser.id})
            .then(res => {
                if (res !== 'empty') {
                    setIncomingMailStatus(true)
                }
            })
    };

    const handleLogout = () => {
        Parse.User.logOut().then(() => {
            localStorage.removeItem('isCodeVerified');
            localStorage.removeItem('isSMSAuthenticator');
            localStorage.removeItem('isTwoStepAuthenticationEnabled');
            localStorage.removeItem('active_address');
            localStorage.removeItem('web3js_wallet');
            setShowMenu(false);
            setShowMobileMenu(false);
            window.location.href = "/#/login";
            window.location.reload();
        });
    }

    return (
        <div className='header__wrapper'>
            <div className="header__menu_open__shadow" onClick={hideMenu}
                 style={{"display": showMenu || showMobileMenu ? "block" : "none"}}/>
            {/*<button onClick={test}>123</button>*/}
            <HeaderTop currentUser={currentUser}
                       handleLogout={handleLogout}
                       hideMenu={hideMenu}
                       incomingMailStatus={incomingMailStatus}
                       setShowMenu={setShowMenu}
                       showMenu={showMenu}
                       setActivePage={setActivePage}
                       history={history}
            />
            {currentUser && <HeaderBottom currentUser={currentUser}
                                          activePage={activePage}
                                          setActivePage={setActivePage}
            />}
            <MobileHeader currentUser={currentUser}
                          showMobileMenu={showMobileMenu}
                          setShowMobileMenu={setShowMobileMenu}
                          setActivePage={setActivePage}
                          history={history}
            />
        </div>
    );
};

export default Header;