import { sendParseRequest } from './sendParseRequest';
import Parse from 'parse';
import { message } from './message';

export const addPrincipalOrCollaborator = async (names, projectId, projectTitle, type, roles) => {
	await sendParseRequest('createPrincipalOrCollaborator', {
		candidateEmail: names.email,
		userId: Parse.User.current().id,
		projectId,
		names,
		roles,
		type
	}).then(async (res) => {
		if (res.className) {
			await sendParseRequest('getUserInfoById', { id: Parse.User.current().id }).then(async (user) => {
				await sendParseRequest('sendInvitationEmail', {
					type: type,
					email: user.get('email'),
					idOfUser: res.id,
					fname: user.get('fname'),
					lname: user.get('lname'),
					projectName: projectTitle,
					emails: [ names.email ]
				}).then(() => {
					return message('success', `${names.fname} was sent an email to confirm as a Principal member.`);
					window.location.reload();
				});
			});
		}
	});
};
