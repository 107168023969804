import React, {useState, useEffect} from "react";
import {Loader} from "../../Loader";
import formatDateToMDY from '../../../../utils/formatDateToMDY';
import EthApi from "../../../api/tendermint";
import {sendParseRequest} from "../../../../utils/sendParseRequest";
import {connect} from "react-redux";
import * as blockChainActions from "../../../../modules/blockchain/blockchain.action";
import axios from "axios";
import constants from "../../../api/constant";
import ethClient from "../../../eth_client";
import encryptBack from "../../../helpers/encryptBack";
import {Link, NavLink} from 'react-router-dom';

const auth = constants.auth;

const CollectiblesTransactionsTable = ({}) => {


    const [results, setResults] = useState([]);
    const [showAreYouSure, setShowAreYouSure] = useState(false);
    const [openedAuctions, setOpenedAuctions] = useState(null);
    const [showedOrders, setShowedOrders] = useState(null);
    const [currentSelectedAuction, setCurrentSelectedAuction] = useState(null);
    const [activeAuctions, setActiveAuctions] = useState(null);
    const [selectedAuction, setSelectedAuction] = useState(null);

    const getActiveAuctions = async () => {
        const walletAddress = await ethClient.eth.getActiveAccountAddress();
        let encryptedWallet = encryptBack(walletAddress);
        console.log(encryptedWallet, '=====encyptedWALLETS===🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀🍀')
        // const result1 = await axios({
        //     method: 'get',
        //     url: `${constants.apiRoot}/contracts/collectibles/collectiblesOfOwner?address=${walletAddress}&is_auctioned=true`,
        //     responseType: 'json',
        //     auth
        // });

        if (encryptedWallet) {
            sendParseRequest('getActiveAuctionsByUser', {userData: encryptedWallet})
                .then(res => {
                    setActiveAuctions(res);
                    console.log(res, "--------------res11111")
                })

        };
    }

    const updateAuctions = (auctions) => {
        setActiveAuctions(auctions)
    };

    useEffect(() => {
    }, [activeAuctions]);

    useEffect(() => {
        setOpenedAuctions(true);
        getActiveAuctions()

    }, []);


    const showOrders = (auction, index) => {
        setShowedOrders(index);
        setCurrentSelectedAuction(auction)
    };

    const back = () => {
        setShowedOrders(null);
        setCurrentSelectedAuction(null);
    };

    return (
        <div>
            {activeAuctions && <div className="auctionTable collectible">
                <Loader showLoader={openedAuctions === null} style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#FFFFFF",
                }}>
                    {showAreYouSure && <div className="areyousureModalBgCover"/>}
                    {
                        currentSelectedAuction ?
                            <CustomTable showAreYouSure={showAreYouSure}
                                         setShowAreYouSure={setShowAreYouSure}
                                         opened={currentSelectedAuction.licenses}
                                         updateAuctions={updateAuctions}
                                         setActiveAuctions={setActiveAuctions}
                                         activeAuctions={activeAuctions}
                                         selectedAuction={selectedAuction}
                                         tickerSymbol={currentSelectedAuction.tickerSymbol}
                                         back={back}
                                         setCurrentSelectedAuction={setCurrentSelectedAuction}
                                         getActiveAuctions={getActiveAuctions}

                            />
                            :
                            <MainTable openedAuctions={openedAuctions} showOrders={showOrders}
                                       setSelectedAuction={setSelectedAuction}
                                       activeAuctions={activeAuctions}/>
                    }
                </Loader>
            </div>
            }

            {results.length > 2 &&
            <div className="viewalltransactions">
                <a href="">View All</a>
            </div>}
        </div>
    )

};


const deleteAuction = async (activeAuction, opened, activeAuctions,
                             setActiveAuctions, updateAuctions,
                             setCurrentSelectedAuction,
                             selectedAuction, getActiveAuctions, setShowAreYouSure) => {

    let id = {auctionId: activeAuction.licenseID};

    let firstPromise = await new Promise((resolve => {
        let auctionsArray = activeAuctions;

        activeAuctions.length > 0 && activeAuctions.forEach((item, idx) => {

                if (item.projectId === activeAuction.projectId) {
                    let removed = item.auctions;

                    item.auctions.forEach((auct, index) => {
                        if (auct.licenseID === id.auctionId) {
                            removed.splice(index, 1);
                        }
                    });
                    auctionsArray[idx].auctions = removed;
                }
            }
        );
        resolve(auctionsArray)
    }));

    if (firstPromise) {
        updateAuctions(firstPromise);
        //change status for auction to false at blockchain
        sendParseRequest('deleteAuction', id)
            .then(res => {
                if (res) {
                    console.debug(res, "--------------res")
                }
            }).catch((e) => console.log(e));

        setCurrentSelectedAuction(null);
        if (selectedAuction.auctions.length === 0) {
            getActiveAuctions();
            setCurrentSelectedAuction(null);
        }
        if (selectedAuction.auctions.length > 0) {
            setCurrentSelectedAuction(true)
        }
    }
    setShowAreYouSure(false)
};


const CustomTable = ({
                         showAreYouSure, opened, tickerSymbol, back, selectedAuction, activeAuctions,
                         setActiveAuctions, updateAuctions, setCurrentSelectedAuction,
                         getActiveAuctions, setShowAreYouSure
                     }) => {
    let resObj = {};
    const [activeAuction, setActiveAuction] = useState(null);

    let resArr = [];
    for (let key in resObj) {
        resArr.push({
            dateTill: key,
            idArr: resObj[key].idArr
        })
    }

    return (<>
            <table className="activeAuctionInfo">
                <thead className="headerOfActiveAuctionInfo">
                <tr>
                    <th>Date Till:</th>
                    <th>Ticker symbol:</th>
                    <th>LicenseID:</th>
                    <th>Buy It Now Price:</th>
                    <th>Auction Start Price:</th>
                    <th>Current bid</th>
                    <th>Operations</th>
                    <th className="back-btn" onClick={back}>{"<"}</th>
                </tr>
                </thead>
                {showAreYouSure &&
                <>
                    <div className="areyousureModal">
                        <p className="closeScreenI" style={{cursor: "pointer"}}
                           onClick={(e) => setShowAreYouSure(false)}>
                            <i className="fa fa-close"/></p>
                        <p className="areyoursureTitle">Are you sure?</p>
                        <p className="areyoursureSubtitle">Once you <b>cancel this transaction</b>, it will <b>no
                            longer</b> be
                            active.</p>
                        <a onClick={(e) => setShowAreYouSure(false)}
                           className="nevermindBttn">Nevermind</a>
                        <a onClick={() => deleteAuction(activeAuction, opened,
                            activeAuctions, setActiveAuctions, updateAuctions, setCurrentSelectedAuction, selectedAuction, getActiveAuctions, setShowAreYouSure)}
                           className="nevermindBttn confirmBttn">Confirm</a>
                    </div>
                </>
                }
                <tbody className="bodyOfActiveAuctionInfo">
                {
                    selectedAuction.auctions.length > 0 && selectedAuction.auctions.map((auction, index) => (
                        <tr key={index}
                            className="itemOfActiveAuction">
                            <td className="propertyOfActiveAuctionItem">
                                <span className="propertyOfActiveAuctionItem__title">Date Till: {index}</span>

                                <span
                                    className="propertyOfActiveAuctionItem__content">{formatDateToMDY(new Date(auction.auctionDate))}</span>
                            </td>

                            <td className="propertyOfActiveAuctionItem">
                                <span className="propertyOfActiveAuctionItem__title">Ticker symbol:</span>
                                <span className="propertyOfActiveAuctionItem__content">{auction.tickerSymbol}</span>
                            </td>

                            <td
                                className="propertyOfActiveAuctionItem">
                                <span className="propertyOfActiveAuctionItem__title">LicenseID:</span>
                                <span className="auction_td-link propertyOfActiveAuctionItem__content">
                  <span>{auction.idOfLicense.split('-')[1]}</span>
                    </span>
                            </td>

                            <td className="propertyOfActiveAuctionItem">
                                <span className="propertyOfActiveAuctionItem__title">Buy It Now Price:</span>
                                <span
                                    className="auction_td-link propertyOfActiveAuctionItem__content">{auction.priceBuyNow}</span>
                            </td>

                            <td className="propertyOfActiveAuctionItem">
                                <span className="propertyOfActiveAuctionItem__title">Auction Start Price:</span>
                                <span
                                    className="auction_td-link propertyOfActiveAuctionItem__content">{auction.priceStart}</span>
                            </td>
                            <td className="propertyOfActiveAuctionItem">
                                <span className="propertyOfActiveAuctionItem__title">Current bid:</span>
                                <span
                                    className="auction_td-link propertyOfActiveAuctionItem__content">{auction.currentBid[0].currentBid} USDW</span>
                            </td>
                            <td className="propertyOfActiveAuctionItem">
                                <Link to={`/collectormarket/${auction.projectId}`} target='_blank'>
                  <span className='auction_td-view'><p
                      style={{width: '120px', marginBottom: '5px'}}>View project</p></span>
                                </Link>

                                <span
                                    className="auction_td-delete propertyOfActiveAuctionItem"
                                    onClick={() => {
                                        setShowAreYouSure(true);
                                        setActiveAuction(auction)
                                    }}>
                    <p style={{width: '120px'}}>delete</p></span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};


const MainTable = ({openedAuctions, showOrders, activeAuctions, setSelectedAuction}) => {
    console.log(activeAuctions, 'activeAuctionsactiveAuctionsactiveAuctions')
    return <table>
        <thead>
        <tr>
            <th>Active auctions:</th>
        </tr>
        </thead>
        <tbody>
        {
            activeAuctions.length > 0 && activeAuctions.map((auction, index) => (
                <tr key={`main-table-key-${index}`} className="cursor">

                    <td onClick={() => {
                        setSelectedAuction(auction);
                        showOrders(auction, index)
                    }}>
                        {auction.auctions[index] ?
                            `Auctioning ${auction.project} with tickersymbol
              ${auction.auctions[index].tickerSymbol}
              . You are selling licenses:
                            ${auction.arrIdsOfLicenses.toString()}` : null
                        }
                    </td>
                </tr>
            ))
        }
        </tbody>
    </table>
};

export default CollectiblesTransactionsTable;
