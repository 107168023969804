import React from 'react';


export function Assets() {
    return (
        <div className="page" style={{"paddingBottom": "0"}}>
          <div className="card2"
          style={{paddingTop: "30px", display: "flex", flexDirection: "column"}}>
              <img src={window.location.origin + '/fanbaselogo200.png'}
                   alt='logo32' style={{paddingBottom: "20px", width: "200px"}}/>
                   <img src={window.location.origin + '/fnbwally128.png'}
                   alt='logo32' style={{paddingBottom: "20px", width: "128px"}}/>
              <img src={window.location.origin + '/fnbwally84.png'}
                   alt='logo32' style={{paddingBottom: "24px", width: "84px"}}/>
            <img src={window.location.origin + '/fnbwally64.png'}
            alt='logo64' style={{paddingBottom: "20px", width: "64px"}}/>
              <img src={window.location.origin + '/fnbwally32.png'}
              alt='logo32' style={{paddingBottom: "20px", width: "20px"}}/>

            <embed style={{display: 'none'}} src={window.location.origin + '/FanbaseNetwork_RequestForComment.pdf'} type='application/pdf'/>
            <embed style={{display: 'none'}} src={window.location.origin + '/SmartContractAudit.pdf'} type='application/pdf'/>
            <embed style={{display: 'none'}} src={window.location.origin + '/SmartContractAudit2.pdf'} type='application/pdf'/>
            <embed style={{display: 'none'}} src={window.location.origin + '/SmartContractAudit3.pdf'} type='application/pdf'/>
          </div>
        </div>
    )
}
