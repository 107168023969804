export const amountInTIX = (amount, decimal = 18, comman = false) => {
  let intAmount = parseInt(amount);

  let intDecimal = parseInt(decimal);
  let x = intAmount / (10 * intDecimal);
  if (comman) return numberWithCommas(x);
  return x;
};

export const buyNowPlusFee = (amount, decimal = 1,) => {

  return (amount + (amount * decimal / 100))
};

export const numberWithCommas = (num) => {
  var str = num.toString().split('.');
  if (str[0].length >= 5) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
};

export const amountInSubDecimal = (amount, decimal = 18) => {
  let intAmount = parseFloat(amount);
  let intDecimal = parseInt(decimal);
  return intAmount * (10 ** intDecimal);
};
export const toFixed   = (x) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      x /= Math.pow(10, e);
      x += (new Array(e + 1)).join('0');
    }
  }
  return String(x);
};

export const calculateIP = (x, y) => {
  const cal = parseFloat(x) / parseFloat(y);
  return Math.ceil(cal * 100) + "%";
};

export const convertUinxTimeStampToDate = (t) => {
  // const a = new Date(parseInt(t));
  const a = new Date(parseInt(t));
  const year = a.getFullYear();
  const month = "0" + `${a.getMonth() + 1}`;
  const date = "0" + a.getDate();
  const hour = "0" + a.getHours();
  const min = "0" + a.getMinutes();
  return {year, month: month.substr(-2), date: date.substr(-2), hour: hour.substr(-2), min: min.substr(-2)};
};
