import React, {Fragment, useState} from "react";

const HistoryBlock = ({TradeDetails}) => {
  /*The error occurs due to lack of data for TradeHistory.
  In the parent component, TradeDetails.PairDetails is [].
  There is no logic of its filling in the Loaddatas function
  */

  const [personalHistoryAreOpen, setPersonalHistoryAreOpen] = useState(false);

  const togglePersonalHistory = () => setPersonalHistoryAreOpen((prevState) => !prevState);

  let historyBlockBody = null;

  if (personalHistoryAreOpen) {
    // historyBlockBody = <TradeHistory isPersonalHistory={personalHistoryAreOpen}
    //                                  togglePersonalHistory={togglePersonalHistory}  Tradehis={TradeDetails}/>;
    historyBlockBody = <div></div>;
  } else {
    
    historyBlockBody = (
      <Fragment>
        <div className="myTicketsBars">
          {/*<BuyAndSellBar data={TradeDetails.PairDetails.buyOrders}*/}
          {/*               type="buy" />*/}

          {/*<BuyAndSellBar data={TradeDetails.PairDetails.sellOrders}*/}
          {/*               type="sell"/>*/}
        </div>

        {/*<TradeHistory isPersonalHistory={personalHistoryAreOpen}*/}
        {/*              togglePersonalHistory={togglePersonalHistory} Tradehis={TradeDetails}/>*/}
      </Fragment>
    );
  }

  return (
    <div className={`myTicketsHistoryBlock ${personalHistoryAreOpen ? 'myTicketsHistoryBlock--personalHistory' : ''}`}>
      {historyBlockBody}
    </div>
  );
};

export default HistoryBlock;