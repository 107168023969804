

export const GET_WALLET = 'GET_WALLET';
export const INITIAL_CARD = 'INITIAL_CARD';
export const CHANGE_CARD = 'CHANGE_CARD';
export const CHANGE_BANNERS = 'CHANGE_BANNERS';


export const setMainWalletAddress = (data) => ({
  type: GET_WALLET,
  payload: data
});

export const changeCartItems= (data) => ({
  type: INITIAL_CARD,
  payload: data
});
export const setMainWallet3= (data) => ({
  type: CHANGE_CARD,
  payload: data
});

// export const setBanners= (data) => ({
//   type: CHANGE_BANNERS,
//   payload: data
// });
export const setBanners= (data) => ({
  type: CHANGE_BANNERS,
  payload: data
});




/*
DEPRICATED =====>>>>
 */

// import {createAction} from "redux-act";

// import setMainWalletAddress from './'

// export const setERC721Tokens = createAction("set tokens");
// export const setProjects = createAction("set Projects");
// export const getOwnedProjectSaga = createAction("get owned Projects saga", (walletAddress)=>({walletAddress}));
// export const setWalletAddresses = createAction("set Wallet addresses");
// export const setMainWalletAddress = createAction("set main Wallet address");
// export const setWalletJSON = createAction("set Wallet JSON");
// export const getFNBDCoinDetailSaga = createAction("get fnbd coin detail", (walletAddress) => ({walletAddress}));
// export const setFNBDCoinDetail = createAction("set fnbd coin detail");
// export const setPercentageLevels = createAction("set percentage levels");
// export const getAllTicketSaga = createAction("get user's all ticket", (walletAddress) => ({walletAddress}));
// export const setUsersTickets = createAction("set user's tickets");
// export const getAavailableFeePercetageLevelsSaga = createAction("get available all fee percetage saga");
// export const getCreatedOrdersSaga = createAction("get created all orders saga");
// export const setCreatedOrders = createAction("set created orders");
// export const createOrderSaga = createAction("create order saga", (walletAddress, password, params) => ({walletAddress, password, params}));
// export const cancelOrderSaga = createAction("Cancel Order Saga", (walletAddress, password, orderObj)=>({walletAddress, password, orderObj}));
// export const approveTixForMatcherSaga = createAction("approve tix for matcher", (walletAddress, password, tokenAddress, amount) => ({walletAddress, password, tokenAddress, amount}));
// // export const approveCollectibleForMatcherSaga = createAction("approve collectibles for matcher", (walletAddress, password) => ({walletAddress, password}));
// export const getAllProjectSaga = createAction("get all Projects", (offset, limit, licenseType)=>({offset, limit, licenseType}));
// export const setTixProjects = createAction("set tix project", (projects)=>({projects}));
// export const updateWalletBalanceSaga = createAction("update Wallet balance sage");
// export const setUser_ETH_FNBD_blance = createAction("set User ETH FNBD blance", (eth, fnbd)=>({eth, fnbd}));
// export const setStartCollectibleAuctionSaga = createAction("set start collecitble auction saga", (walletAddress, password, params) => ({walletAddress, password, params}));
// export const getAuctionDetailSaga = createAction("get auction detail saga", (pId, tokenId) => ({pId, tokenId}));
// export const setAuctionDetail = createAction("set auction detail");
//
// export const buyCollectibleNowSaga = createAction("buy collectible now saga", (walletAddress, password, tokenId, price)=>({walletAddress, password, tokenId, price}));
// export const getOwnedCollectiblesSaga = createAction("get owned collectible saga", (walletAddress) => ({walletAddress}));
