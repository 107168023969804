import React from "react";
import ToTopArrow from "../../Components/ToTopArrow";
import {Link} from "react-router-dom";
import ImageGallery from 'react-image-gallery';
import Partners from '../../../assets/images/partners.png'
import FNWallet2 from '../../../assets/images/fbwallet2.png'
import FNWallet3 from '../../../assets/images/fbwallet3.png'
import BTMImg1 from '../../../assets/images/btmimg1.png'
import BTMImg2 from '../../../assets/images/btmimg2.png'
import Fbvideo from '../../../assets/images/fbvideo.mp4'
import Fbvideo2 from '../../../assets/images/defi-fanbase.mp4'
import MainImage from '../../../assets/images/FanbasWalletNoBackground.png'
import TwitterImg from './../../../assets/images/twitter1.png'
import LinkedInImg from './../../../assets/images/linkedin1.png'
import TelegramImg from './../../../assets/images/telegram1.png'
import BJ from './../../../assets/images/b2.png'
import FanbaseStakeImg from "../../../assets/images/btmimg2.png";
import FanbaseStakeImg2 from "../../../assets/images/btmimg3.png";
import FanbaseWalletNFTs from "../../../assets/images/FanbaseWalletNFTs.png";

export class MainPage extends React.Component {


  state = {
    slide1: "1",
    slide2: "1",
    slide3: "1",
    slide4: "1",
    copied: false,
  };

  componentDidMount() {
    document.title = "Fanbase";
    window.scrollTo(0, 0);
  }


  render() {
    const images2 = [
      {
        original: BTMImg2,
        thumbnail: BTMImg2
      },
      {
        original: BTMImg1,
        thumbnail: BTMImg1
      }
    ]

    const images = [
      {
        original: FNWallet3,
        thumbnail: FNWallet3,
      },
      // {
      //   original: FNWallet1,
      //   thumbnail: FNWallet1,
      // },
      {
        original: FNWallet2,
        thumbnail: FNWallet2,
      },
      // {
      //   original: FNWallet4,
      //   thumbnail: FNWallet4,
      // },
    ];


    return (
      <div className="page main-page__wrapper">
        <div className="pageContent" style={{fontFamily: 'sans-serif'}}>
          <div className="mainHeader2" style={{height: '80px'}}>
            {/*<p className="mainTitle">We are the music makers, and</p>*/}
            {/*<p className="mainTitle"> we are the dreamers of</p>*/}
            {/*<p className="mainTitle"> dreams...</p>*/}
          </div>
          <div className="fnbXTokenAnythingWrap card6">
            <div className=" presaleWrapper" style={{borderTop: "none"}}>

              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>

                <h2 className='mainPage_mob-center top-ttl'
                    style={{
                      // "textAlign": "justify",
                      color: "black",
                      fontFamily: 'Archivo Black, sans-serif',
                      maxWidth: '72%',
                      margin: '60px auto 0 auto'
                      // marginBottom: "50px"
                    }}
                >
                  Welcome to Fanbase Wallet...
                </h2><p className='mainPage_mob-center mainPage_text-offers'
                        style={{marginTop: '15px', fontSize: '20px', textAlign: 'center !important'}}>A cool place for
                streaming your NFTs and storing your digital assets.</p>

                <div className='mainPage_top-description'
                     style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <img style={{height: '355px', marginTop: '25px', marginRight: '40px'}}
                       src={MainImage} alt='img'/>

                </div>
                <div style={{display: 'flex', width: '80%', margin: '20px auto 0 auto', justifyContent: 'center'}}>
                  <button style={{
                    width: '139px',
                    marginTop: '10px',
                  }}
                          onClick={() => window.open('https://chrome.google.com/webstore/detail/fanbaseio/ehkdpgmlbmklaiibhcdggpbohgggjibl', '_blank')}

                          className='blue-button'>Download now
                  </button>
                </div>
                <p className="mainPage_text-offers"
                   style={{margin: '55px auto 0 auto', textAlign: 'center', fontSize: '20px', maxWidth: '60%'}}>
                  Fanbase
                  Wallet is the official wallet of Fanbase Smart Chain and your <br/> bridge to a promising new
                  ecosystem
                  for creators (coming v. 1.4).</p>
                <h1
                  style={{
                    width: "60%",
                    fontSize: "33px",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "Archivo Black, sans-serif",
                    marginTop: '90px',
                  }}
                >
                  Trade with Fanbase Swap
                </h1>
                <p className="mainPage_text-offers"
                   style={{margin: '55px auto 0 auto', textAlign: 'center', fontSize: '20px', maxWidth: '60%'}}>
                  Find the best price on the decentralized web by comparing pools from Uniswap, 1inch (API), Sushiswap,
                  Pancakeswap, Curve, NiftySwap and Fanbase.io. Fanbase DeFi now supports ERC20 and ERC1155 trades on
                  20+ EVM networks, including Ethereum, Avalanche, Polygon and BSC! </p>
                <img
                  src={FanbaseStakeImg2}
                  style={{width: "50%", margin: "40px 0 40px 0"}}
                  alt="fanbase-nft"
                />
                <div style={{
                  display: 'flex',
                  width: '100%',
                  margin: '20px auto 0 auto',
                  justifyContent: 'center',
                  flexDirection: "column",
                  alignItems: "center"
                }}>

                  <button style={{
                    width: '139px',
                    marginTop: '30px',
                  }}
                          onClick={() => window.open('https://defi.fanbase.io/', '_blank')}

                          className='blue-button'>Trade now
                  </button>
                  <p className="mainPage_text-offers"
                     style={{margin: '55px auto 0 auto', textAlign: 'center', fontSize: '20px', maxWidth: '60%'}}>Want
                    to earn free WFNB? Join our group and perform some promotional tasks
                    <a href="https://www.bonjourno.com/groups/60636aa308144b00174b10c0" target="_blank"> here</a>.
                    If you are a developer or digital creator, view our swap APIs and NFT widgets
                    <a href="https://www.plan.fanbase.io" target="_blank"> here</a>. Swap, stake and sell tokens or NFTs
                    on your own site! Great for music, media, and tickets!</p>
                </div>
                <div style={{
                  maxWidth: '900px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '50px auto'
                }}>

                  <ImageGallery style={{margin: '0 auto'}} items={images2} showThumbnails={false} showPlayButton={false}
                                showBullets={true}
                                showNav={false} showFullscreenButton={false}/>
                </div>
                <div style={{display: 'flex', width: '80%', margin: '20px auto 0 auto', justifyContent: 'center'}}>
                  <button style={{
                    width: '139px',
                    marginTop: '10px',
                    // marginRight: '30px'
                  }}
                          onClick={() => window.open('https://defi.fanbase.io/dashboard', '_blank')}

                          className='blue-button'>Try now
                  </button>
                </div>
                <p className="mainPage_text-offers"
                   style={{margin: '55px auto 0 auto', textAlign: 'center', fontSize: '20px', maxWidth: '60%'}}>Whether
                  you are a creator, a label, a producer, a developer, a network, or a crypto company, Fanbase DeFi
                  provides you simple tools to attract, reward and engage token holders. Our staking protocol helps you
                  create loyal and long-term fan communities.</p>
                <h2 className="mainPage_before-video" style={{
                  textAlign: 'center',
                  marginBottom: '40px',
                  fontFamily: 'Archivo Black',
                  marginTop: '80px',
                }}> Fanbase NFT Superstore...</h2>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  maxWidth: '900px',
                  margin: '0 auto',
                  flexDirection: "column",
                  alignItems: "center"
                }}>
                  <video className="mainPage_video" width="100%" height="500" controls style={{margin: '0 auto'}}>
                    <source src={Fbvideo2} type="video/mp4"/>
                  </video>
                  <p style={{
                    textAlign: 'center',
                    fontStyle: "regular",
                    fontSize: "18px",
                    marginBottom: '40px',
                    // fontFamily: 'Archivo Black',
                    marginTop: '30px',
                  }}>Coming soon to Fanbase.io.</p>
                </div>

                <h1
                  style={{
                    fontSize: "33px",
                    // width: "60px",
                    fontWeight: "bold",
                    textAlign: "center",
                    fontFamily: "Archivo Black, sans-serif",
                    marginTop: '30px',
                    width: "70%"
                  }}
                >
                  A staking platform for your fans
                </h1>
                <p className="mainPage_text-offers"
                   style={{margin: '20px auto 0 auto', textAlign: 'center', fontSize: '20px', maxWidth: '60%'}}>
                  Earn rewards by staking ERC20, ERC721 and ERC1155 tokens on 20+ EVM networks including Ethereum,
                  Polygon, Avalanche and BSC! View the full list of supported networks <a
                  href="https://fanbase-io.gitbook.io/docs/" target="_blank"> here</a>.

                </p>
                <img
                  src={FanbaseWalletNFTs}
                  style={{width: "50%", margin: "40px 0 40px 0"}}
                  alt="fanbase-nft"
                />
                <div style={{display: 'flex', width: '80%', margin: '20px auto 0 auto', justifyContent: 'center'}}>
                  <button style={{
                    width: '139px',
                    marginTop: '-20px',
                    marginBottom: "50px"
                  }}
                          onClick={() => window.open('https://defi.fanbase.io/dashboard', '_blank')}

                          className='blue-button'>
                    Stake now
                  </button>
                </div>
                {/*<h1*/}
                {/*  className="homePage_descr-secondTitle"*/}
                {/*  style={{*/}
                {/*    fontSize: "33px",*/}
                {/*    fontWeight: "bold",*/}
                {/*    textAlign: "center",*/}
                {/*    marginTop: "20px",*/}
                {/*    fontFamily: "Archivo Black, sans-serif",*/}
                {/*  }}*/}
                {/*>*/}
                {/*  Fanbase Staking makes Defi easy*/}
                {/*</h1>*/}
                {/*<p style={{color: "black", width: "60%"}}>*/}
                {/*  Fanbase helps creators reward early adopters and diehard fans in an easy-to-use way. Creators often offer rewards when participants lock their stakes for extended periods of time. This helps stakeholders demonstrate a committed fanbase.*/}
                {/*</p>*/}
                {/*<img*/}
                {/*  src={FanbaseStakeImg}*/}
                {/*  style={{width: "50%", margin: "40px 0 40px 0"}}*/}
                {/*  alt="fanbase-nft"*/}
                {/*/>*/}


                <div className='homePage_statistic'>
                  <div className="homePage_statistic-container">
                    <h1><span style={{color: '#0162d0'}}>#</span>23</h1>
                    <p style={{color: 'black', textAlign: 'center', marginTop: '-10px'}}>
                      Token holders</p>
                  </div>
                  <div className="homePage_statistic-container">
                    <h1><span style={{color: '#0162d0'}}>$</span>.005</h1>
                    <p style={{color: 'black', textAlign: 'center', marginTop: '-10px'}}>Token price</p>
                  </div>
                  <div className="homePage_statistic-container">
                    <h1><span style={{color: '#0162d0'}}>#</span>10</h1>
                    <p style={{color: 'black', textAlign: 'center', marginTop: '-10px'}}>Wallet users</p>
                  </div>

                  <div className="homePage_statistic-container">
                    <h1><span style={{color: '#0162d0'}}>$</span>90k</h1>
                    <p style={{color: 'black', textAlign: 'center', marginTop: '-10px'}}>
                      Invested (estimated)</p>
                  </div>
                </div>


                <div style={{
                  backgroundColor: '#2b70de',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '20px',
                  color: 'white',
                  paddingBottom: '20px'
                }}>
                  <h2 className="mainPage_before-video" style={{
                    textAlign: 'center',
                    marginBottom: '40px',
                    fontFamily: 'Archivo Black'
                  }}>Fanbase brand platforms...</h2>
                  <div style={{display: 'flex', justifyContent: 'center', maxWidth: '900px', margin: '0 auto'}}>
                    <video className="mainPage_video" width="100%" height="500" controls style={{margin: '0 auto'}}>
                      <source src={Fbvideo} type="video/mp4"/>
                    </video>

                  </div>
                  <p className="mob-txt" style={{width: '60%', margin: '40px auto 0 auto', textAlign: 'center'}}>One
                    token
                    powers Fanbase Wallet, Staking, Blockchain, DEX & Marketplace. WFNB token lives on Ethereum and
                    swaps
                    one-to-one with FNB which lives on Fanbase Smart Chain.</p>
                </div>
                <h2 className="mainPage_text-bitTitle" style={{
                  maxWidth: '900px', margin: '50px auto 20px auto', textAlign: 'center', color: "black",
                  fontFamily: 'Archivo Black, sans-serif'
                }}>Fanbase Wallet makes it easy to view and transfer your NFTs</h2>
                <div style={{
                  maxWidth: '900px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '50px auto'
                }}>

                  <ImageGallery style={{margin: '0 auto'}} items={images} showThumbnails={false} showPlayButton={false}
                                showBullets={true}
                                showNav={false} showPlayButton={false} showFullscreenButton={false}/>
                </div>

                <div style={{display: 'flex', width: '80%', margin: '20px auto 50px auto', justifyContent: 'center'}}>
                  <button style={{
                    width: '139px',
                    marginTop: '10px',
                    // marginRight: '30px'
                  }}
                          onClick={() => window.open('https://chrome.google.com/webstore/detail/fanbaseio/ehkdpgmlbmklaiibhcdggpbohgggjibl', '_blank')}

                          className='blue-button'>Download now
                  </button>
                </div>

                <p className="mainPage_text-offers"
                   style={{margin: '55px auto 0 auto', textAlign: 'center', fontSize: '20px', maxWidth: '60%'}}>We added
                  streaming for audio and video NFTs, plus a swapping feature which compares prices from across the
                  internet to offer you the best price. Stake directly in our wallet!</p>

              </div>


              <div className="mainPage_content-block" style={{marginBottom: '50px', maxWidth: '1200px'}}>

                <h2 style={{fontFamily: 'Archivo Black', marginTop: '80px'}}>Enjoying our products?</h2>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '30px',

                  alignItems: 'space-between',
                  width: '100%'
                }}>
                  <div className='mainPage_block-blue' style={{
                    display: 'flex', backgroundColor: '#2567e5',
                    borderRadius: '5px', color: 'white', padding: '20px', marginBottom: '30px'
                  }}>
                    <h1 style={{
                      fontSize: '150px',
                      width: '40%',
                      maxWidth: '113px',
                      marginLeft: '20px',
                      marginRight: '50px'
                    }}>1</h1>
                    <div style={{
                      display: 'flex',
                      paddingLeft: '20px',
                      flexDirection: 'column',
                      color: 'white',
                      borderLeft: '5px solid white'
                    }}>

                      <h3>Fanbase Staking


                      </h3>
                      <p>Earn rewards on more than 20 EVM networks, including Ethereum, Polygon, BSC, and Avalanche,
                        using Fanbase.io! Then, add WFNB multipliers each time you stake an ERC20! The platform has
                        different types of pools, such as competitive, friendly and NFT staking, to attract new types of
                        DeFi customers from media, film, music, gaming and crypto industries.</p>
                    </div>


                  </div>


                  <div className='mainPage_block-blue' style={{
                    display: 'flex', backgroundColor: '#2567e5',
                    borderRadius: '5px', color: 'white', padding: '20px', marginBottom: '30px'
                  }}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                      <h1 style={{
                        fontSize: '150px',
                        maxWidth: '113px',
                        width: '40%',
                        marginLeft: '20px',
                        marginRight: '80px'
                      }}>2</h1>
                    </div>
                    <div style={{
                      display: 'flex',
                      paddingLeft: '20px',
                      flexDirection: 'column',
                      color: 'white',
                      borderLeft: '5px solid white'
                    }}>

                      <h3>Fanbase Wallet </h3>
                      <p>Stream audio or video NFTs, and swap ERC20s and ERC1155s with Fanbase Wallet! Download the
                        extension from Google Web Store, and the mobile application from the App Store. Fanbase Wallet
                        supports 20+ EVM networks and the browser wallet now facilitates staking. Today, the wallet is a
                        cool place to store your NFTs and a safe place to store your digital assets. Version 1.4 will
                        bridge Ethereum to Fanbase Smart Chain.</p>

                    </div>


                  </div>
                  <div className='mainPage_block-blue' style={{
                    display: 'flex', backgroundColor: '#2567e5',
                    borderRadius: '5px', color: 'white', padding: '20px', marginBottom: '30px'
                  }}>
                    <h1 style={{
                      fontSize: '150px',
                      maxWidth: '113px',
                      width: '40%',
                      marginLeft: '20px',
                      marginRight: '60px'
                    }}>3</h1>
                    <div style={{
                      display: 'flex',
                      paddingLeft: '20px',
                      flexDirection: 'column',
                      color: 'white',
                      borderLeft: '5px solid white'
                    }}>

                      <h3>Fanbase DEX</h3>
                      <p>Swap tokens on 20+ networks, such as Ethereum, Polygon, Avalanche and Binance Smart Chain on
                        Fanbase DEX, the preferred liquidity pool choice for creators who want to distribute tokens
                        related to music, film, gaming, metaverse, news and media. Fanbase DEX helps you find the best
                        deal by comparing prices from Uniswap, Sushiswap, 1inch (API), Niftyswap, Pancakeswap, Curve and
                        Fanbase.io. You can also use our widget to sell or stake tokens and NFTs from your own website,
                        which is great for wholesaling music and tickets.</p>
                    </div>
                  </div>
                  <div className='mainPage_block-blue' style={{
                    display: 'flex', backgroundColor: '#2567e5',
                    borderRadius: '5px', color: 'white', padding: '20px', marginBottom: '30px'
                  }}>
                    <h1 style={{
                      fontSize: '150px',
                      maxWidth: '113px',
                      width: '40%',
                      marginLeft: '20px',
                      marginRight: '50px'
                    }}>4*</h1>
                    <div style={{
                      display: 'flex',
                      paddingLeft: '20px',
                      flexDirection: 'column',
                      color: 'white',
                      borderLeft: '5px solid white'
                    }}>

                      <h3>Fanbase Marketplace


                      </h3>
                      <p>Conduct Initial Decentralized Offerings (IDOs) and NFT sales for different types of digital
                        content using Fanbase.io! You can buy and sell rare comics, photos, songs, images, cards,
                        characters, games, memes and more. You can also view your digital certificates of authenticity
                        (COAs) in your wallet and trade them with other collectors, like baseball cards. The Fanbase
                        Marketplace widget helps you sell tickets, music and media as NFTs, directly to your fans, on
                        your own website. Moreover, we help film, music and media companies launch their own tokens.


                        {/*Read more <Link to="/about"*/}
                        {/*                onClick={() => window.scrollTo(0, 0)}><span*/}
                        {/*  style={{color: 'white', fontStyle: 'bold', textDecoration: 'underline'}}> here</span></Link>.*/}
                      </p>
                    </div>


                  </div>
                  <div className='mainPage_block-blue' style={{
                    display: 'flex', backgroundColor: '#2567e5',
                    borderRadius: '5px', color: 'white', padding: '20px', marginBottom: '30px'
                  }}>
                    <h1 style={{
                      fontSize: '150px',
                      maxWidth: '113px',
                      width: '40%',
                      marginLeft: '20px',
                      marginRight: '50px'
                    }}>5*</h1>
                    <div style={{
                      display: 'flex',
                      paddingLeft: '20px',
                      flexDirection: 'column',
                      color: 'white',
                      borderLeft: '5px solid white'
                    }}>


                      <h3>Fanbase Smart Chain


                      </h3>
                      <p>Enjoy lightning fast transaction speeds using Fanbase Smart Chain. Designed on top of
                        Tendermint Byzantine fault-tolerant (BFT) consensus algorithm and Cosmos SDK Delegated
                        Proof-of-Stake (DPoS) consensus model, the network acts as a Cosmos Zone, and communicates with
                        the rest of the Cosmos Ecosystem through the Inter Blockchain Communication Protocol (IBC). The
                        CosmWasm smart contracts interoperate natively within the Cosmos blockchain ecosystem.</p>


                    </div>

                  </div>
                  <p>* launching soon</p>

                  {/*<p className="mainPage_want-read"*/}
                  {/*   style={{margin: '55px auto 0 auto', textAlign: 'center', fontSize: '20px', maxWidth: '60%'}}>Want*/}
                  {/*  to*/}
                  {/*  read about the other great technologies Fanbase.io is building?</p>*/}
                  {/*/!*<h4 style={{*!/*/}
                  {/*/!*  maxWidth: '900px', margin: '50px auto 20px auto', textAlign: 'center', color: "black",*!/*/}
                  {/*/!*  fontFamily: 'Archivo Black, sans-serif'*!/*/}
                  {/*/!*}}>Want to read about the other great technologies Fanbase is building?</h4>*!/*/}
                  {/*<div style={{display: 'flex', width: '80%', margin: '20px auto 50px auto', justifyContent: 'center'}}>*/}
                  {/*  <button style={{*/}
                  {/*    // width: '139px',*/}
                  {/*    marginTop: '10px',*/}
                  {/*    // marginRight: '30px'*/}
                  {/*  }}*/}
                  {/*          onClick={() => window.open(`${window.location.origin}/FanbaseReport_RequestForComment.pdf`, '_blank')}*/}

                  {/*          className='blue-button'>Read the document*/}
                  {/*  </button>*/}
                  {/*</div>*/}


                </div>
              </div>


              <div className="mainPage_content-block padding-30" style={{marginBottom: '50px'}}>

                <h2 style={{fontFamily: 'Archivo Black'}}>Team</h2>

                <p>Interested in contributing in exchange for tokens? Do you think Fanbase Smart Chain could be the next
                  Solana? We need developers, journalists, and marketers too.</p>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'left', width: '100%'}}>
                  <a href='mailto:jobs@fanbase.io' style={{marginTop: '10px '}}>Fanbase Jobs for Tokens</a>
                  {/*<a href='https://immunefi.com'> Fanbase Wallet Bug Bounty </a>*/}
                  {/*<a href='https://immunefi.com'> Fanbase Staking Bug Bounty </a>*/}
                  {/*<a href='https://immunefi.com'> BonJourno Bug Bounty </a>*/}
                  {/*<p> Fanbase Smart Chain Bug Bounty (Q3 2022)</p>*/}
                  {/*<p> Fanbase Marketplace & IDO Platform Bug Bounty (Q4 2022) </p>*/}
                  <br/>
                  <p>Questions about our existing team? Click <a href="https://defi.fanbase.io/faq">here</a>.</p>
                </div>
                {/*<button style={{*/}
                {/*  // width: '110px',*/}
                {/*  marginTop: '10px',*/}
                {/*  // marginRight: '30px'*/}
                {/*}}*/}
                {/*        onClick={() => window.open('https://www.bonjourno.com/groups/60636aa308144b00174b10c0', '_blank')}*/}

                {/*        className='blue-button'>Sign up</button>*/}
              </div>

              <div className="mainPage_content-block padding-30">

                <h2 style={{fontFamily: 'Archivo Black', textAlign: 'center', marginTop: '45px'}}>Join our BonJourno
                  group</h2>

                <p style={{marginTop: '5px'}}>BonJourno is a social media site on Fanbase Smart Chain. Questions? Join
                  our
                  chat there.</p>
                <button style={{
                  width: '139px',
                  marginTop: '30px',
                  // width: '139px'
                  // marginRight: '30px'
                }}
                        onClick={() => window.open('https://www.bonjourno.com/groups/60636aa308144b00174b10c0', '_blank')}

                        className='blue-button'>Join
                </button>
              </div>
              <div className="mainPage_content-block padding-30">

                <h2 className="top-ttl" style={{
                  fontFamily: 'Archivo Black',
                  textAlign: 'center',
                  marginTop: '90px',
                  marginBottom: '45px'
                }}>Projects on Fanbase Smart Chain</h2>
                <img src={Partners} style={{maxWidth: '350px', width: '100%', marginBottom: '30px', opacity: '.6'}}
                     alt='partners'/></div>
              <p className="mainPage_last-text" style={{margin: '0 auto 50px auto ', maxWidth: '700px'}}>Fanbase Smart
                Chain is gearing up for launch. The above brands will conduct commercial activity using Fanbase
                Marketplace and chain. Wrapped versions of the tokens may exist on other chains, but financial
                operations
                will primarily exist on Fanbase Smart Chain. BonJourno is a social publishing site, Loftit is a payments
                tool for landlords, and Fanboy Universe is an NFT and record label. Many more launches to announce
                Q3.</p>


              {/*<div className="leftSideModuleWrap presaleWrapper" style={{paddingBottom: "0px"}}>*/}
              {/*    <p className="firstTitle  main_sliders-title">*/}
              {/*        Categories for Collectors*/}
              {/*    </p>*/}
              {/*    <MultipleSliders slidersData={slidersData}/>*/}
              {/*    <br style={{clear: "both"}}/>*/}
              {/*</div>*/}
              {/*<div className="mainPage_content-block padding-30" style={{marginBottom: '50px'}}>*/}

              {/*  <h2 className="top-ttl" style={{*/}
              {/*    fontFamily: 'Archivo Black',*/}
              {/*    textAlign: 'center',*/}
              {/*    marginTop: '90px',*/}
              {/*    marginBottom: '45px'*/}
              {/*  }}>Connect with us</h2>*/}
              {/*  /!*<img src={Partners} style={{maxWidth: '350px', width: '100%', marginBottom: '30px', opacity: '.6'}}*!/*/}
              {/*  /!*     alt='partners'/>*!/*/}
              {/*  /!*<p className="mainPage_last-text" style={{margin: '0 auto 50px auto ', maxWidth: '700px'}}>Fanbase Smart*!/*/}
              {/*  /!*  Chain is gearing up for launch. The above brands will conduct commercial activity using Fanbase*!/*/}
              {/*  /!*  Marketplace and chain. Wrapped versions of the tokens may exist on other chains, but financial operations*!/*/}
              {/*  /!*  will primarily exist on Fanbase Smart Chain. BonJourno is a social publishing site, Loftit is a payments*!/*/}
              {/*  /!*  tool for landlords, and Fanboy Universe is an NFT and record label. Many more launches to announce Q3.</p>*!/*/}
              {/*  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'left', width: '100%'}}>*/}
              {/*    <div style={{*/}
              {/*      display: 'flex',*/}
              {/*      justifyContent: 'space-between',*/}
              {/*      maxWidth: '200px',*/}
              {/*      width: '100%',*/}
              {/*      margin: '0 auto'*/}
              {/*    }}>*/}
              {/*      <a href='https://t.me/+xsN4Qep4n3Y4OTlh'><img src={TwitterImg} style={{width: '50px'}}/></a>*/}
              {/*      <a href='https://twitter.com/FanbaseCoin'> <img src={TelegramImg} style={{width: '50px'}}/></a>*/}
              {/*      <a href='https://etherscan.io/token/0x7016c6f4dba9a9a48a47a2fe73a1b9271432d1d5'> <img*/}
              {/*        src={LinkedInImg} style={{width: '50px'}}/></a>*/}
              {/*    </div>*/}
              {/*    <span style={{marginTop: '20px', color: 'black', textAlign: 'center'}}>Token address: <a*/}
              {/*      href='https://etherscan.io/token/0x7016c6f4dba9a9a48a47a2fe73a1b9271432d1d5'>*/}
              {/*       https://etherscan.io/token/0x7016c6f4dba9a9a48a47a2fe73a1b9271432d1d5</a></span>*/}
              {/*    <span style={{marginTop: '0', color: 'black', textAlign: 'center'}}>WFNB is Wrapped Fanbase Coin</span>*/}
              {/*    <span style={{marginTop: '20px', color: 'black', textAlign: 'center'}}>Purchase WFNB on Uniswap using ETH or USDC: <a*/}
              {/*      href='https://etherscan.io/token/0x7016c6f4dba9a9a48a47a2fe73a1b9271432d1d5'>*/}
              {/*       https://etherscan.io/token/0x7016c6f4dba9a9a48a47a2fe73a1b9271432d1d5</a></span>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {/*<div className="mainPage_content-block padding-30" style={{marginBottom: '50px', marginTop: '20px'}}>*/}

              {/*<h2 style={{fontFamily: 'Archivo Black', marginTop: '20px', marginBottom: '35px'}}>Purchase WFNB on Uniswap</h2>*/}

              {/*<p style={{ marginBottom: '25px'}}>You can purchase WFNB token at Uniswap.org using Metamask. First, make sure you have ETH in your Wallet*/}
              {/*  to purchase the token, and pay the gas fee. Next, import the WFNB address (0x...d5) listed below. Then*/}
              {/*  confirm the purchase in Uniswap, and in your wallet. </p>*/}
              {/*<div style={{display: 'flex', justifyContent: 'center', maxWidth: '900px', margin: '23px auto'}}>*/}
              {/*  <video className="mainPage_video" width="100%" height="400" controls style={{margin: '0 auto'}}>*/}
              {/*    <source src={UniswapVideo} type="video/mp4"/>*/}
              {/*  </video>*/}
              {/*</div>*/}
              {/*<p style={{textAlign: 'center'}} className="token-addr-mob">Did you miss the address in the video? Copy it here: 0x7016C6f4dBa9A9a48a47A2FE73A1b9271432D1d5*/}
              {/*  <CopyToClipboard text={'0x7016C6f4dBa9A9a48a47A2FE73A1b9271432D1d5'}*/}
              {/*                   onCopy={() =>{ this.setState({copied: true});  message('success', "Copied!")}}>*/}
              {/*    {this.state.copied === true*/}
              {/*      ?  <CopyOutlined   style={{color: '#2cc74f', marginLeft: '5px', fontSize: '12px', paddingTop: '-4px !important'}}/>*/}
              {/*      : <CopyOutlined style={{marginLeft: '5px', fontSize: '12px', paddingTop: '-4px !important'}}/>}*/}
              {/*  </CopyToClipboard>*/}

              {/*</p>*/}

              {/*<p style={{margin: '30px auto 10px'}}>You can also purchase WFNB with USDC. To do this, you will still need*/}
              {/*  some ETH to pay the gas fee. Alternatively, if you prefer a centralized exchange, WFNB is listed on*/}
              {/*  Coinsbit.io beginning May 16th!</p>*/}
              {/*</div>*/}
              <div className="mainPage_content-block padding-30" style={{marginBottom: '50px'}}>

                <h2 className="top-ttl" style={{
                  fontFamily: 'Archivo Black',
                  textAlign: 'center',
                  marginTop: '70px',
                  marginBottom: '45px'
                }}>Connect with us</h2>
                {/*<img src={Partners} style={{maxWidth: '350px', width: '100%', marginBottom: '30px', opacity: '.6'}}*/}
                {/*     alt='partners'/>*/}
                {/*<p className="mainPage_last-text" style={{margin: '0 auto 50px auto ', maxWidth: '700px'}}>Fanbase Smart*/}
                {/*  Chain is gearing up for launch. The above brands will conduct commercial activity using Fanbase*/}
                {/*  Marketplace and chain. Wrapped versions of the tokens may exist on other chains, but financial operations*/}
                {/*  will primarily exist on Fanbase Smart Chain. BonJourno is a social publishing site, Loftit is a payments*/}
                {/*  tool for landlords, and Fanboy Universe is an NFT and record label. Many more launches to announce Q3.</p>*/}
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'left', width: '100%'}}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    maxWidth: '230px',
                    width: '100%',
                    margin: '0 auto'
                  }}>
                    <a href='https://www.bonjourno.com/groups/60636aa308144b00174b10c0'><img src={BJ}
                                                                                             style={{width: '50px'}}/></a>
                    <a href='https://t.me/+xsN4Qep4n3Y4OTlh'><img src={TelegramImg} style={{width: '50px'}}/></a>
                    <a href='https://twitter.com/FanbaseCoin'> <img src={TwitterImg} style={{width: '50px'}}/></a>
                    <a href='https://www.linkedin.com/company/fanbasesmartchain/'> <img
                      src={LinkedInImg} style={{width: '50px'}}/></a>
                  </div>
                  {/*    <span className="token-addr-mob" style={{marginTop: '20px', color: 'black', textAlign: 'center'}}>Token address: <a*/}
                  {/*      href='https://etherscan.io/token/0x7016c6f4dba9a9a48a47a2fe73a1b9271432d1d5'>*/}
                  {/*       https://etherscan.io/token/0x7016c6f4dba9a9a48a47a2fe73a1b9271432d1d5</a></span>*/}
                  {/*    <span style={{marginTop: '0', color: 'black', textAlign: 'center'}}>WFNB is Wrapped Fanbase Coin</span>*/}
                  {/*    <span style={{marginTop: '20px', color: 'black', textAlign: 'center'}}>Purchase WFNB on Uniswap using ETH or USDC: <a*/}
                  {/*      href='https://uniswap.org/'>*/}
                  {/*    https://uniswap.org/*/}
                  {/*</a></span>*/}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToTopArrow/>

      </div>
    );
  }
}
