import React from 'react';

import InfoTooltip from '../InfoTooltip';

const DropDownList = ({data, selectedData, title, defaultValue, mediaCategory}) => {
    console.debug('_________data, selectedData, title, defaultValue, mediaCategory:', data, selectedData, title, defaultValue, mediaCategory);
    return (
        <div className="dropDownListInfo">
            <h3 className="dropDownListInfo__title">{title}</h3>
            <ul className="dropDownList">
                {
                    data.map((dataEl, idx) => {
                        // const classForActiveEl = data.indexOf(dataEl) >= 0 ? 'dropDownList__item--active' : '';
                        let titleName = false;
                        let plusVariable = false;
                        if (title === "Shows:"
                            || title === "Number of Seasons:"
                            || title === "Editions Per Year:"
                            || title === "Number of Years:"
                            || title === "File type:"
                        ) {
                            titleName = title.slice(0, -1);

                        }

                        //change image name (from + symbol to %2b as file name on AWS and change ":" on ".")
                        let replaceString = dataEl.replace(":", ".").replace(":", ".");
                        replaceString && (plusVariable = replaceString);
                        let arr = dataEl.split('+');
                        if (arr) {
                            if (arr[0] !== undefined && arr[1] !== undefined) {
                                plusVariable = `${arr[0]}%2B${arr[1]}`;
                            }
                        }
                        if (dataEl === "advertisement") {
                            plusVariable = "pr-icon"
                        }

                        if (dataEl === "C++") {
                            plusVariable = "C%2B%2B"
                        }
                        if (dataEl === "C#") {
                            plusVariable = "C%23"
                        }

                        if (title === "File type:") {
                            plusVariable = dataEl.substring(1);
                        }

                        if (typeof defaultValue === 'string' && idx === 0 || dataEl === 'Genre') {
                            return null;
                        }
                        let status = false;
                        selectedData.map((i) => {
                            if (dataEl === i) {
                                status = true
                            }
                        });

                        if (status) {
                            return (
                                <li key={`dropdown_item_key_${idx}`} className={`dropDownList__item`}>
                            <span>
                                <img src={`https://s3.amazonaws.com/fanbase.platform/projects_icons/${mediaCategory}/${
                                    (titleName !== false) ?
                                        (plusVariable ?
                                            `${titleName}/${plusVariable}`
                                            : `${titleName}/${dataEl}`)
                                        : (plusVariable
                                        ? plusVariable
                                        : dataEl)}.png`}
                                     alt="icon"/>
                            </span>
                                    {dataEl}
                                    <InfoTooltip position="top"
                                                 message="The project title is the working title. You can edit, refine, or change this title at any time.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                </li>
                            );
                        }
                    })
                }
            </ul>
        </div>
    );
};

export default DropDownList;
