import React from 'react';
import ReactDOM from "react-dom";

export const message = (type, message, delay) => {
  let icon;

  switch (type) {
    case 'error':
      icon = <span className="fa fa-times-circle fa-lg messageAlert__icon_error" aria-hidden="true"/>;
      break;
    case 'warning':
      icon = <span className="fa fa-exclamation-triangle fa-lg messageAlert__icon_warning" aria-hidden="true"/>;
      break
    case 'success':
      icon = <span className="fa fa-check-circle fa-lg messageAlert__icon_success" aria-hidden="true"/>;
      break
    case 'info':
      icon = <span className="fa fa-info-circle fa-lg messageAlert__icon_info" aria-hidden="true"/>;
      break
  }

  const element = (
    <div id='messageAlertContent' className='messageAlert__wrapper z-top'>
      <p>{icon} {message}</p>
    </div>
  );

  const messageElement = document.createElement('div');
  messageElement.id = 'messageAlert';
  document.body.prepend(messageElement);
  ReactDOM.render(element, document.getElementById('messageAlert'));

  setTimeout(() => {
    document.getElementById('messageAlertContent').className = 'messageAlert__wrapper messageAlert__wrapper_active'
  })

  setTimeout(() => {
    document.getElementById('messageAlertContent').className = 'messageAlert__wrapper'
  }, delay || 2000)

  setTimeout(() => {
    messageElement.parentNode.removeChild(messageElement)
  }, delay + 1000 || 3000)
}
