import React, {Component, Fragment} from 'react';
import Parse from "parse";
import checkSessionToken from '../../../utils/checkSessionToken';
import ethClient from "../../eth_client";

export default function withAuth(ComponentToProtect, nowallet) {
    return class extends Component {
        componentDidMount = () => {
            checkSessionToken({
                success: () => {
                    Parse.User.logOut().then(() => {
                        window.location.href = "/#/login";
                        window.location.reload();
                    });
                },
                error: () => {
                    Parse.User.logOut().then(() => {
                        window.location.href = "/#/login";
                        window.location.reload();
                    });
                }
            });
            if (nowallet){
                let ownerWaller = ethClient.eth.getActiveAccountAddress();
                if (!ownerWaller) {
                    window.location.href = "/#/wallet";
                    window.location.reload();
                }
            }
        };

        render() {
            return (
                <Fragment>
                    <ComponentToProtect {...this.props} />
                </Fragment>
            );
        }
    }
}
