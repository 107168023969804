import React, {useEffect, useState} from 'react';
import Parse from 'parse';
import InfoTooltip from '../../../Components/InfoTooltip';
import OptionsBlock from './OptionsBlock';
import ProgressBlock from './ProgressBlock';
import {Loader} from '../../../Components/Loader';
import {sendParseRequest} from "../../../../utils/sendParseRequest";

const ProofOfWorkPoll = ({proofOfWork, projectId, financingGoal, showPoll, project, investedMoney}) => {
    const [isInvestor, setIsInvestor] = useState(false);
    const [isPollFinished, setIsPollFinished] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [bidOfInvestor, setBidOfInvestor] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    // const yesPercentage = 100 * project
    var yesPercentage = 0;
    if (investedMoney) {
        yesPercentage = 100 * investedMoney / parseInt(project.goal);
    }

    useEffect(() => {
        if (projectId) {
            setDumyInvestoreAtTheMOment();
            getBidOfUser();
        }
    }, [showPoll, projectId]);

    const onOptionSelect = (evt) => {
        const currEl = evt.target;
        const valueOfOption = currEl.value;

        setSelectedOption(valueOfOption);
        setIsPollFinished(true);
    };

    const onChangeMind = () => {
        setSelectedOption(null);
        setIsPollFinished(false);
    };

    const getBidOfUser = () => {
        const currUser = Parse.User.current();

        if (!currUser) return false;

        const userId = currUser.id;
//ToDo: fix request getInfo, nothing in response
        sendParseRequest('getInfo', {
            query: "Investors",
            select: ["investments"],
            equal: [
                {field: "investor", value: {"__type": "Pointer", "className": "_User", "objectId": userId}},
                {field: "projectInvest", value: {"__type": "Pointer", "className": "Projects", "objectId": projectId}}
            ]
        })
            .then(res => {
                console.log(res, "setBidOfInvestor--------------")

                if (res[0]) {
                    setIsInvestor(true);
                    // The old server function used .first()
                    // It may not be right


                    setBidOfInvestor(res[0].investments);
                    setIsLoading(false);
                }

            })
    };

    const setDumyInvestoreAtTheMOment = () => {
        setIsInvestor(true);
        setIsLoading(false);
    }

    const getPercentOfVote = () => ((bidOfInvestor / financingGoal) * 100).toFixed(6);

    // if (proofOfWork === '' || !isInvestor) {
    //   return null;
    // }

    if (proofOfWork === '') {
        return null;
    }

    return (
        <Loader showLoader={isLoading} style={{
            height: '159px',
            position: 'static',
            maxWidth: '417px'
        }}>
            <div className="proofOfWorkPollContainer">
                <div className="cBody">
                    <div className="proofOfWorkPoll">
                        <h4 className="s-input-title proofOfWorkPoll__title">
                            Fund the next stage?

                            <InfoTooltip position="top"
                                         message="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae, debitis non qui reprehenderit sequi sint sunt.">
                                <i className="fa fa-info-circle"> </i>
                            </InfoTooltip>
                        </h4>

                        {
                            isPollFinished ?
                                <ProgressBlock percentOfBid={yesPercentage}
                                               selectedOption={selectedOption}/> :
                                <OptionsBlock onOptionSelect={onOptionSelect}
                                              percentOfBid={yesPercentage}/>
                        }

                        {
                            selectedOption === 'not yet' &&
                            <button className="proofOfWorkPoll__changeMindBtn"
                                    onClick={onChangeMind}>You choosed "Not yet", but you can change your mind.</button>
                        }
                    </div>
                </div>
            </div>
        </Loader>
    );
};

export default ProofOfWorkPoll;