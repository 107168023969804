import React, {Fragment} from 'react';
import '../TwoStep.css'

export class LostTwoStepCode extends React.Component {
    state = {
        lostMenu: false,
    };

    switchLostMenu = () => {
        this.setState({lostMenu: !this.state.lostMenu})
    };

    changePage = (page) => {
        page === "home"
            ? this.props.history.push('/')
            : (page === "faq" ? this.props.history.push('faq') : this.props.history.push('signin_step_two_sms'))
    };

    render() {
        const {lostMenu} = this.state;
        return (
            <Fragment>
                <div className="disabled cursor-pointer" onClick={this.switchLostMenu}>I am unable to submit the
                    code
                </div>
                <div className={lostMenu ? "lost-menu_visible" : "lost-menu_unvisible"}>
                    <div className={lostMenu ? "lost-title_visible" : "lost-title_unvisible"}
                         onClick={() => this.changePage("faq")}>
                        My authenticator codes are not working
                    </div>
                    <div className={lostMenu ? "lost-title_visible" : "lost-title_unvisible"}
                         onClick={() => this.changePage("signin_step_two_descr")}>My authenticator app is lost
                    </div>
                    <div className={lostMenu ? "lost-title_visible" : "lost-title_unvisible"}
                         onClick={() => this.changePage("home")}>Cancel my login
                    </div>
                </div>
            </Fragment>
        )
    }
}
