const {ethers} = require("ethers");
const ADMIN_WALLET = "0x72DE235Fa8841ba352B6547a538CfE794C70C71f";
const FAKE_USDC_ADDRESS = "0x68ec573C119826db2eaEA1Efbfc2970cDaC869c4";

export const swapUsdc = async (ethereumPrivateKey, amount) => {

let stringAmount = amount.toString()
    const signer = new ethers.Wallet(
        ethereumPrivateKey,
        ethers.getDefaultProvider("ropsten")
    );

    console.log(signer, 'sogner--')
    const abi = [
        "function transfer(address to, uint256 amount)",
        "function decimals() view returns (uint8)",
    ];
    const usdc = new ethers.Contract(FAKE_USDC_ADDRESS, abi, signer);
    const decimals = await usdc.decimals();
    const tx = await usdc.transfer(
        ADMIN_WALLET,
        ethers.utils.parseUnits(stringAmount, decimals)
    );
    console.log(tx, 'tx')
    const {transactionHash} = await tx.wait();
    console.log(transactionHash, 'transactionHash')
    return transactionHash;
// Этот хэш отправишь в поле txHash
};
