import Parse from "parse";
import {sendParseRequest} from "./sendParseRequest";
import {message} from "./message";

const startLogin = (email, password, saveUser, history) => new Promise((resolve, reject) => {
    console.log(9999999999)
    if (saveUser) {
        Parse.User.enableUnsafeCurrentUser();
        localStorage.removeItem('email');
        localStorage.removeItem('password');
    } else {
        console.log(33333)
        Parse.User.disableUnsafeCurrentUser();
        localStorage.setItem('email', btoa(email));
        localStorage.setItem('password', btoa(password));
    }
    console.log(email.toLowerCase(), password)

    Parse.User.logIn(email.toLowerCase(), password)
        .then(user => {
            console.log(777777)

            sendParseRequest('getInfo', {
                query: "User",
                equal: [
                    {field: "objectId", value: user.id},
                    {field: "legalAffiliation", value: "legalEntity"},
                    {field: "legalEntityApproveStatus", value: false}
                ], useMasterKey: true
                
            })
                .then(res => {
                    if (res.length > 0) {
                        history.push({
                            pathname: '/thankyou',
                            state: {status: 'legalEntity', user: res[0]}
                        });
                        return false
                    } else {
                        const types = detectmob();

                        sendParseRequest('createFSession', {
                            id: user.id,
                            date: new Date(),
                            type: types,
                            description: "Login",
                            email: email.toLowerCase()
                        });
                        resolve(user);
                    }
                })
        }).catch(err => {
        message('error', err.message);
        reject(err)
    });
});

const detectmob = () => {
    return !!(navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i));
};

export default startLogin;