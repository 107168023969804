import React, { useEffect, useState } from 'react';
// import "./ConfirmPhrase.css";
// import Header from "../Components/Header/Header";
import { useHistory } from "react-router";
import '../walletPage.sass'
import {
    walletService,
    IsERC1155TicketId,
    isCollectibleId,
    erc721Factory
} from "../../../api/tendermint/main"

// import {message} from "antd";
// import EthWallet from '../../helpers/wallet'; 
// import { useAuth } from '../../context/authContext';
// import { useWallet } from '../../context/walletContext';
import EthWalletHelper from '../../../helpers/wallet';
import LocalStore from '../../../../utils/localStore';
import { message } from "../../../../utils/message";


const ConfirmPhrase = ({ mnemonic, acc, password, walletType }) => {
    console.log('mnemonic, acc, password}', mnemonic, acc, password, 'walletType===>', walletType);

    const history = useHistory();
    const [wallets, setWallets] = useState([]);
    let localStore = new LocalStore()
    let masterkey = localStore.getDecoded('userToken');
    console.log(masterkey, "--------------masterkey")
    const [randomCode, setRandomCode] = useState([]);
    useEffect(() => {
        if (mnemonic) {
            const arrayMnemonic = mnemonic.split(' ');
            setRandomCode(arrayMnemonic.sort(() => {
                return Math.random() - 0.5;
            }));
        }
    }, []);

    const [selectedWords, setSelectedWords] = useState([]);

    const show = word => {
        if (selectedWords.includes(word)) {
            const duplicate = [...selectedWords];
            const idx = duplicate.indexOf(word);
            duplicate.splice(idx, 1);
            return setSelectedWords(duplicate);
        } else {
            setSelectedWords(prev => {
                return [...prev, word]
            })
        }
    };

    const [isConfirm, setIsConfirm] = useState(false);
    useEffect(() => {
        selectedWords.length === 12 && setIsConfirm(true)
    }, [selectedWords]);


    const saveWallet = async (newWallet) => {
        let arr = [];
        if (localStorage.getItem('walletsArr')) {
            arr = JSON.parse(localStorage.getItem('walletsArr'))
        }
        localStorage.setItem('active_address', newWallet.address);
        await newWallet.address && arr.push(newWallet.address);
        return (arr)
    };

    const addAccount = async (mnemonic, password, acc, walletType) => {
        console.log('mnemonic, password', 'acc', mnemonic, password, acc);
        if (walletType === 'eth') {
            //this is ETH wallet lib ==>
            const nonce = acc.nonce + 1;
            const ethWallet = new EthWalletHelper();
            const localStore = new LocalStore(masterkey);
            const walletsStringEth = localStore.get('walletsListEth');




            const newWalletEth = ethWallet.generateWalletFromSeed(acc.seed, acc.nonce);
            // console.log(newWallet, "--------------newWallet11111111")
            let walletObjectEth = {
                address: newWalletEth.address,
                privKey: newWalletEth.privKey
            };
            console.log('walletObjectEth', walletObjectEth);



            let walletsArrayEth = [];
            if (walletsStringEth.length > 0) {
                let newWalletsArrayEth = JSON.parse(walletsStringEth);

                newWalletsArrayEth.push(walletObjectEth)
                const onlyUnique = (value, index, self) => {
                    return self.indexOf(value) === index;
                };
                walletsArrayEth = newWalletsArrayEth.filter(onlyUnique);


            }
            else {
                walletsArrayEth = [...walletsArrayEth, walletObjectEth];
                console.log(walletsArrayEth, 'walletsArraywalletsArraywalletsArray')

            }
            localStore.set('walletsListEth', JSON.stringify(walletsArrayEth));
            localStorage.setItem('active_addressEth', walletObjectEth.address);





        } else {
            //this is fanbase wallet lib ==>
            let returnedObj = await walletService.createWallet(mnemonic, password, 1)
            console.log(returnedObj, '<=================returnedObg======')



            const localStore = new LocalStore(masterkey);
            const walletsString = localStore.get('walletsList');
            console.log(walletsString, "--------------walletsString")
            // const walletObj1 = JSON.parse(walletsString);
            // console.log(walletObj1, "--------------walletObj111111")
            console.log('acc.seed, acc.nonce', acc.seed, acc.nonce, 'acc.seed, acc.nonce/////');


            let walletsArray = [];
            console.log(returnedObj, "--------------walletObject")
            if (walletsString.length > 0) {
                console.log(1111, "--------------1111")

                // TODO: add wallets to wallets ARRAY
                // console.log(walletsString, "--------------walletsString")
                let newWalletsArray = JSON.parse(walletsString);
                // console.log(newWalletsArray, "--------------newWalletsArray")
                // console.log(returnedObj, "--------------walletObject")

                newWalletsArray.push(returnedObj)
                const onlyUnique = (value, index, self) => {
                    return self.indexOf(value) === index;
                };
                walletsArray = newWalletsArray.filter(onlyUnique);


            }
            else {

                console.log('elseee');
                // console.log('returnedObj', returnedObj, 'returnedObjreturnedObjreturnedObjreturnedObj');

                walletsArray = [...walletsArray, returnedObj];
                console.log(walletsArray, 'walletsArraywalletsArraywalletsArray')

            }
            localStore.set('walletsList', JSON.stringify(walletsArray));

            localStorage.setItem('active_address', returnedObj.addresses[0]);
            // const walletsString2 = localStore.get('walletsList');
            // console.log(walletsString2, "--------------walletsString")
            // !!!!!!!
            // history.push('/wallet');


            return returnedObj;
        }





        message('success', 'You have successfully added your new wallet');



    };


    const confirmPhrase = (mnemonic, password, acc, walletType) => {
        const candidatePhrase = [];
        const field = document.querySelector('.confirmPhrase__field').childNodes;
        field.forEach(child => {
            candidatePhrase.push(child.innerText);
        });

        console.log(mnemonic)
        if (mnemonic === candidatePhrase.join(' ')) {
            console.log(mnemonic, "--------------mnemonic333")
            // message.success('Success');

            // generate first wallet


            addAccount(mnemonic, password, acc, walletType);


            // !!!!!!!!!!!!
            // setTimeout(() => {
            //     history.push('/')
            // }, 250)


        } else {
            message('error', 'Invalid mnemonic phrase');

        }
    };

    return (
        <div className='confirmPhrase__wrapper'>
            {/*<Header/>*/}
            {/*<button onClick={() => history.push('/wallet')} className='wallet-button wallet-btn'>&#60; Back</button>*/}
            <h1>Confirm your Alternate Phrase</h1>
            <p>Choose each phrase in exact order.</p>
            <div className='confirmPhrase__field'>
                {selectedWords.map(word => {
                    return <div className='confirmPhrase__word' key={word}>
                        {word}
                    </div>
                })}
            </div>
            <div className='confirmPhrase__word-grid'>
                {randomCode.map(word => {

                    return <div className={selectedWords.includes(word)
                        ? 'confirmPhrase__word-active'
                        : 'confirmPhrase__word'}
                        onClick={() => show(word)}
                        key={word}
                    >
                        {word}
                    </div>
                })}
            </div>

            <button className={isConfirm ? 'primaryButton blue-btn' : 'disableButton blue-btn'}
                style={{ marginLeft: '10px', marginTop: '20px' }}
                disabled={!isConfirm}
                onClick={() => confirmPhrase(mnemonic, password, acc, walletType)

                    // history.push('/confirmPhrase')
                }
            >
                Next
            </button>
        </div>
    );
};

export default ConfirmPhrase;
