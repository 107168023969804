import React from "react";
import '../exchange.css';
// import GPayButton from 'react-google-pay-button'
import {comma} from "../../../../utils/comma";


const paymentMethods = [
    // {
    //     type: 'CARD',
    //     parameters: {
    //         allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    //         allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA']
    //     },
    //     tokenizationSpecification: {
    //         type: 'PAYMENT_GATEWAY',
    //         parameters: {
    //             'gateway': 'stripe',
    //             'stripe:version': '2019-03-14',
    //             'stripe:publishableKey': '<YOUR_PUBLIC_STRIPE_KEY>'
    //         }
    //     }
    // },
    {
        type: 'PAYPAL',
        parameters: {
            'purchase_context': {
                'purchase_units': [{
                    'payee': {
                        'merchant_id': '<YOUR PAYPAL_ACCOUNT_ID>'
                    }
                }]
            }
        },
        tokenizationSpecification: {
            type: 'DIRECT'
        }
    }
]


export class ExchangeDetailsBuy extends React.Component {
    // loadPaymentDataHandler = paymentData => {
    //     const paymentToken = paymentData.paymentMethodData.tokenizationData.token
    // }
    render() {
        const prop = this.props.stateInfo;
        return (
            <div>
                <div className="cBody exchangeDetails exchangeDetails__container">
                    <p className="initialTitle">You are buying</p>
                    <p className="amountofFnb">{calculateFnbAmount(prop, "total")} USDW</p>{/* {calculateFnbAmount(prop,"t")}*/}
                    <p className="exchangeRate">@ ${prop.bonusExchangeRate} per UDSW</p>
                    {/*<GPayButton*/}
                    {/*    totalPriceStatus={'FINAL'}*/}
                    {/*    totalPrice={'2.45'}*/}
                    {/*    currencyCode={'GBP'}*/}
                    {/*    countryCode={'GB'}*/}
                    {/*    allowedPaymentMethods={paymentMethods}*/}
                    {/*    development={true}*/}
                    {/*    merchantInfo={{*/}
                    {/*        merchantName: 'USDW',*/}
                    {/*        // A Google merchant identifier issued after your website is approved by Google ✅*/}
                    {/*        merchantId: '<YOUR MERCHANT ID>'*/}
                    {/*    }}*/}
                    {/*    onLoadPaymentData={this.loadPaymentDataHandler}*/}
                    {/*/>*/}
                    <div className="bodyWrap">
                        <div className="">
                            <div>
                                <p className="exchange-calc_title">Payment method:</p>
                                <div className='exchange-calc_container'>
                                    <p className="subtitle">{convertToCoin(prop)}</p>
                                    <p className="subtitle">{prop.pCurrency}</p>
                                </div>
                                <p className="exchange-calc_title">Buy amount:</p>
                                <div className='exchange-calc_container'>
                                    <p className="subtitle">{calculateFnbAmount(prop, "b")}</p>
                                    <p className="subtitle">USDW</p>
                                </div>
                                {/*<p className="Exchange-calc_title">Today's Bonus:</p>*/}
                                {/*<div className='Exchange-calc_container'>*/}
                                {/*<p className="subtitle" id="totalFnbAMount">{calculateFnbAmount(prop, "t")}</p>*/}
                                {/*<p className="subtitle" id="totalFnbAMount">USDW</p>*/}
                                {/*</div>*/}
                                <p className="exchange-calc_title">Total Purchase:</p>
                                <div className='exchange-calc_container'>
                                    <p className="subtitle">{calculateFnbAmount(prop, "total")}</p>
                                    <p className="subtitle">USDW</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="finalFeeWrap exchange-container_wrap">
                        <span className="pull-left title">Subtotal</span>
                        <span className="pull-right title">${calculateFnbAmount(prop, "subtotal")}</span>
                        <div className="verticalLine exchange-container_wrap"></div>
                    </div>
                    <div className="finalFeeWrap exchange-container_wrap">
                        <span className="pull-left title">Transaction Fee (1%)</span>
                        <span className="pull-right title">${calculateFnbAmount(prop, "fee")}</span>
                        <div className="verticalLine exchange-container_wrap"></div>
                    </div>
                    <div className="finalFeeWrap exchange-container_wrap">
                        <span className="pull-left title">Total</span>
                        <span className="pull-right title">${calculateFnbAmount(prop, "totalA")}</span>
                        <div className="verticalLine exchange-container_wrap"></div>
                    </div>
                </div>
                <div className="confirmPurchase-container">
                    {
                        prop.pCurrency != "USD" ?
                            prop.amount == "" || parseFloat(prop.amount) < prop.minimum ?
                                <a className="confirmPurchase_errorPurchase">{"$" + prop.minimum + " minimum"}</a>
                                :
                                <a onClick={this.props.handleCreateTransaction}
                                   className="confirmPurchase_confirmPurchase">Checkout</a>
                            :
                            prop.amount == "" || parseFloat(prop.amount) < 100 ?
                                <a className="confirmPurchase_errorPurchase">{"$100 minimum"}</a>
                                :
                                <a onClick={this.props.handleCreateTransaction}
                                   className="confirmPurchase_confirmPurchase">Checkout</a>
                    }
                </div>
            </div>
        );
    }
}


function calculateFnbAmount(object, t) {
    let rRate = 5;
    if (object.amount == "") {
        if (t == "totalA" || t == "fee" || t == "subtotal") {
            return "0.00"
        } else {
            return "0.00000"
        }
    }


    let totalPerDollar = Math.abs(1 / parseFloat(object.exchangeRate));
    let fee;
    if (object.pCurrency == "USD") {
        fee = parseFloat(object.USDFeeRate) / 100;
    } else {
        fee = parseFloat(object.feeRate) / 100;
    }


    let fnbAmount = (parseFloat(object.amount) * totalPerDollar).toFixed(rRate);
    let fFnb = (fnbAmount - (fnbAmount * fee).toFixed(rRate));

    // ===> here is bonus commented
    if (t == "b") {
        // return comma((fFnb * parseFloat(object.bonusRate) / 100).toFixed(rRate));
        return comma((fFnb / 100).toFixed(rRate));
    } else if (t == "t") {
        return comma(fFnb);
    }

    // ===> here is bonus commented
    else if (t == "total") {
        // let bonus = (fFnb * parseFloat(object.bonusRate) / 100)
        // let bonus = (fFnb / 100)
        // return comma((bonus + fFnb).toFixed(rRate))
        return comma((fFnb).toFixed(rRate))
    } else if (t == "subtotal") {
        return comma((object.amount - (object.amount * fee)).toFixed(2))
    } else if (t == "fee") {
        return comma((object.amount * fee).toFixed(2))
    } else if (t == "totalA") {
        return comma(parseFloat(object.amount).toFixed(2))
    }
}


function convertToCoin(object) {
    let btcinDollar = object.btcinDollar;
    let pCurrency = object.pCurrency;
    let array = object.rates;

    if (pCurrency == "BTC") {
        return comma((btcinDollar * object.amount).toFixed(8));
    } else if (pCurrency == "USD") {

        if (object.amount == "") {
            return "0.00000000";
        } else {
            let amounts = parseFloat(object.amount).toFixed(2)
            return comma(amounts.toString());
        }


    } else {
        if (array != null) {
            let rate = startsWith(array, pCurrency)[0].split(':')[1];
            let amount = ((btcinDollar * object.amount) / rate).toFixed(8);
            return comma(amount)
        }

    }
}

function startsWith(array, key) {
    const matcher = new RegExp(`^${key}`, 'g');
    return array.filter(word => word.match(matcher));
}
