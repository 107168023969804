import Parse from "parse";
import {message} from "./message";
import axios from 'axios'

export const sendAxiosRequest = async (urlAddress,  data = {}, type) => {
    console.log(urlAddress, data)
    // console.log(urlAddress, data)
    const api1 = 'ZF3GT5g2UuyztbZSZgkeP8WLC4qKYHrkJX2Vccf8wcxAyzWJKabpjzytBf';
    const key1 = 'agFNUVQfcSFmuQr2EY3Af2vHegHw8hhzhgdRrN9fMFfuuawgL3d4ukNG8q';
    let updatedUrl;
    let updatedHeaders;
    if (type==="nonParse") {
        updatedUrl=`http:///localhost:1337/${urlAddress}`
        updatedHeaders = {"Access-Control-Allow-Origin": "*",}
    } else {
        updatedUrl  =  `https://fanbaseprod.herokuapp.com/parse/functions/${urlAddress}`;
        updatedHeaders={
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, PATCH, OPTIONS",
            "Access-Control-Allow-Headers": "X-Requested-With, content-type, Authorization",
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': `${api1}`,
            'X-Parse-REST-API-Key': `${key1}`,
            'X-Parse-Revocable-Session': '1'
        }
    }
    var options = {
        method: 'POST',
        // url: `https://fanbaseprod.herokuapp.com/parse/functions/${urlAddress}`,
        url: updatedUrl,
        data: data,
        headers: updatedHeaders
    };

    let PromiseFirst = await new Promise(async (resolve) => {
        axios.request(options).then((response) => {
            console.log(response, 'resp')
            resolve(response.data);
        }).catch(err => {
            if (err.code === 209) {
                Parse.User.logOut()
                    .then(() => {
                        window.location.href = "/login";
                        window.location.reload();
                    });
            }
            message('error', err.message)
        })
    })


    if (PromiseFirst) return PromiseFirst

}