import React, {useEffect, useState} from 'react';
import Parse from 'parse';

import {Loader} from '../Loader';
import {sendParseRequest} from "../../../utils/sendParseRequest";
import ethClient from "../../eth_client";
import axios from "axios";
import constants from "../../api/constant";

const auth = constants.auth;
const TradeHistory = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [tradeHistory, setTradeHistory] = useState(null);
  const walletAddress = ethClient.eth.getActiveAccountAddress();

  useEffect(() => {
    const userId = Parse.User.current().id;

    sendParseRequest('getTransactionInfo', {userId})
      .then(transactions => {
        setIsLoading(false);
        setTransactions(transactions);
      }).catch(() => setIsLoading(false))
  }, []);

  const getHistory = async () => {
    const result1 = await axios({
      method: 'get',
      url: `${constants.apiRoot}/contracts/collectibles/transfersOfOwner?address=${walletAddress}`,
      responseType: 'json',
      auth
    });
    // console.log(result1.data.projects, "--------------result1")
    // const result = await axios({
    //   method: 'get',
    //   url: `${constants.apiRoot}/contracts/collectibles/collectiblesOfOwner?address=${walletAddresse}`,
    //   responseType: 'json',
    //   auth
    // });
    console.log(result1, "--------------result111111")
    setTradeHistory(result1.data.transfers)
    return result1.data.projects
    // http://3.236.249.5:8081/api/v2/eth/contracts/collectibles/transfersOfOwner?address=0x0690e78642c3574a9612d7d804d2dc440664aa7a

  }
  useEffect(() => {
    getHistory()
  }, []);
  return (
    <div className={`tradeHistory ${isLoading ? 'tradeHistory--isLoading' : ''}`}>
      <Loader showLoader={isLoading}>
        {/*<div className="headerOfTradeHistory">*/}
        {/*    <span>Date</span>*/}
        {/*    <span>Buyer</span>*/}
        {/*    <span>Seller</span>*/}
        {/*    <span>License</span>*/}
        {/*    <span>Price</span>*/}
        {/*</div>*/}

        <ul className="listOfTradeHistory">
          {tradeHistory && tradeHistory.map((item, idx) => (
            <li className="tradeHistoryListItem"
                key={`trade_history_item_${idx}`}>
              <span className="tradeHistoryListItem__date"> </span>

              <span className="tradeHistoryListItem__buyer">Item: {item.item}</span>

              <span
                className="tradeHistoryListItem__seller">From: {item.from}</span> <span
              className="tradeHistoryListItem__seller">To:{item.to}</span>

              <span className="tradeHistoryListItem__license">
                {/*<Link to={`/projectauction/${license.auctionId}`}>*/}
                {/*                <img src={license.logo} alt={`logo of ${license.tickerSymbol}`}/>*/}
                {/*  <span>{license.tickerSymbol}</span>*/}
                {/*</Link>*/}
              </span>

              {/*<span*/}
              {/*    className={`tradeHistoryListItem__price ${transactionType === 'sell' ? 'tradeHistoryListItem__price--sell' : 'tradeHistoryListItem__price--buy'}`}>{price}</span>*/}
            </li>
          ))}
        </ul>
      </Loader>
    </div>
  );
};

export default TradeHistory;
