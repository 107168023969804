import React from 'react';
import {NavLink} from "react-router-dom";
import "./HeaderBottom.css";
import {mearsyLink} from "../../../../static/constants";

const HeaderBottom = ({currentUser, activePage, setActivePage}) => {
    return (
        <>
            <div className='header_bottom__wrapper'>
                <nav className='header_bottom__menu'>
                    <NavLink to={'/collectibles'}
                             onClick={() => {
                                 setActivePage('collectibles')
                             }}
                             className={activePage === 'collectibles'
                                 ? 'header_bottom__menu__item_select'
                                 : 'header_bottom__menu__item'}>
                        Collectibles
                    </NavLink>
                    <NavLink to={'/wallets2'}
                             onClick={() => {
                                 setActivePage('wallets2')
                             }}
                             className={activePage === 'wallets2'
                                 ? 'header_bottom__menu__item_select'
                                 : 'header_bottom__menu__item'}>
                        Tickets
                    </NavLink>
                    <NavLink to={'/tokens'} onClick={() => {
                        setActivePage('tokens')
                    }}
                             className={activePage === 'tokens'
                                 ? 'header_bottom__menu__item_select'
                                 : 'header_bottom__menu__item'}>
                        Tokens
                    </NavLink>
                    {/*<a href={mearsyLink}*/}
                    {/*   target="_blank"*/}
                    {/*   className='header_bottom__menu__item'*/}
                    {/*   onClick={() => {*/}
                    {/*       setActivePage('mearsyNews')*/}
                    {/*       window.open(mearsyLink, '_blank')*/}
                    {/*   }}>*/}
                    {/*    Community*/}
                    {/*</a>*/}
                </nav>
                <NavLink to="/settings/compliance"
                         className={currentUser.attributes.accountVerifiedStatus === 'Complete'
                             ? 'header_bottom__verify-btn_complete'
                             : 'header_bottom__verify-btn_not-complete'}>
                    {currentUser.attributes.accountVerifiedStatus === 'Complete'
                        ? <p>ACCOUNT VERIFIED</p>
                        : <p>VERIFY ACCOUNT</p>}
                </NavLink>
            </div>
        </>
    );
};

export default HeaderBottom;