import React from 'react';
import {Link} from "react-router-dom";
import WhiteLogoImage from "../../../../assets/images/F_logo_in_White_C.jpg";
import prepareImagesPathForMinify from "../../../../utils/prepareImagesPathForMinify";
import F_in_White from "../../../../assets/images/F_in_White.png";
import WhiteDropdownImage from "../../../../assets/images/dropdown.png";
import NewMailIcon from "../../../../assets/images/mail-icon.png";
import "./HeaderTop.css";

const HeaderTop = ({
                       currentUser,
                       setShowMenu,
                       showMenu,
                       hideMenu,
                       incomingMailStatus,
                       handleLogout,
                       setActivePage,
                       history
                   }) => {

    const closeMenuWithHighlight = () => {
        hideMenu();
        setActivePage('')
    }

    return (
        <>
            <div className='header_top__wrapper'>
                <div className='header_top'>
                    <Link to="/" onClick={() => setActivePage('')}>
                        <img src={WhiteLogoImage} className='header_top__logo' alt='logo'/>
                    </Link>

                    {!currentUser
                        ? <div className='header_top__login'>
                            {/*<Link onClick={() => setActivePage('')} to="/login" className='header_top__login__item'>*/}
                            {/*    LOG IN*/}
                            {/*</Link>*/}
                            {/*<Link onClick={() => setActivePage('')} to="/register" className="header_top__login_btn">*/}
                            {/*    REGISTER*/}
                            {/*</Link>*/}
                            {/* <Link onClick={() => setActivePage('')} to="/faq" className="header_top__login__item">
                                FAQ
                            </Link> */}
                        </div>
                        : <div className='header_top__menu'>
                            <div className="header_top__menu__user-avatar"
                                 style={{
                                     backgroundImage: currentUser.attributes.avatarLink
                                         ? "url('" + prepareImagesPathForMinify(currentUser.attributes.avatarLink, 50, 50) + "')"
                                         : "url('" + prepareImagesPathForMinify(F_in_White, 50, 50) + "')"
                                 }}
                                 onClick={() => {
                                     setActivePage('');
                                     history.push('/profile')
                                 }}
                            />
                            <div className='header_top__menu__user-name__wrapper'
                                 onClick={() => setShowMenu(!showMenu)}>
                                <p className="header_top__menu__user-name">
                                    {(currentUser.attributes.fname.length > 20
                                        ? currentUser.attributes.fname.substr(0, 20) + '...'
                                        : currentUser.attributes.fname)}
                                    &nbsp;
                                    {(currentUser.attributes.lname.length > 20
                                        ? currentUser.attributes.lname.substr(0, 20) + '...'
                                        : currentUser.attributes.lname)}
                                </p>
                                <img className="header_top__menu__arrow-btn"
                                     style={{"transform": showMenu ? "rotate(180deg)" : "rotate(0deg)"}}
                                     src={WhiteDropdownImage} alt='arrow'
                                />
                            </div>
                        </div>
                    }
                </div>
              <div style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '2%', marginTop: '-35px'}}>
                {/*<Link to="/" className='header_top__links__item' onClick={() => setActivePage('')}>*/}
                {/*    COLLECTORS*/}
                {/*</Link>*/}
                {/*<Link to='/fanancers' className='header_top__links__item' onClick={() => setActivePage('')}>*/}
                {/*    FANANCERS*/}
                {/*</Link>*/}
                <a href='https://defi.fanbase.io/' className='header_top__links__item' onClick={() => setActivePage('')}>
                  <button style={{fontSize: '12px', boxShadow: '0 4px 1px 1px rgba(0, 0, 0, 0.2)',
                    cursor: 'pointer', padding: '5px 20px', width:  '139px', outline: 'none', border: 'none', height: '41px', borderRadius: '3px'}}>
                    DeFi app</button>
                </a>
              </div>
            </div>

            {currentUser && <div className={showMenu ? "header__menu__dropdown" : "header__menu__dropdown_hide"}>
                <ul className='header__menu__dropdown__list'>
                    <li className="header__menu__dropdown__list__header">
                        <div className="header__menu__dropdown__list__item__user-avatar"
                             style={{
                                 backgroundImage: currentUser.attributes.avatarLink
                                     ? "url('" + prepareImagesPathForMinify(currentUser.attributes.avatarLink, 50, 50) + "')"
                                     : "url('" + prepareImagesPathForMinify(F_in_White, 50, 50) + "')"
                             }}
                             onClick={() => {
                                 setShowMenu(false);
                                 setActivePage('');
                                 history.push('/profile')
                             }}
                        />
                        <Link onClick={closeMenuWithHighlight} to="/profile">
                            <p className="header__menu__dropdown__list__item__user-name">
                                {currentUser.attributes.fname} {currentUser.attributes.lname}
                            </p>
                            <p className="header__menu__dropdown__list__item__user-id">
                                ID: {currentUser.id}
                            </p>
                        </Link>
                        <p className="header__menu__dropdown__list__item__user-email">
                            {currentUser.attributes.email}
                        </p>
                    </li>
                    <Link onClick={closeMenuWithHighlight} to="/wallet">
                        <li className='header__menu__dropdown__list__item'>Wallets</li>
                    </Link>
                    <Link onClick={closeMenuWithHighlight} to="/projects">
                        <li className='header__menu__dropdown__list__item'>Projects</li>
                    </Link>

                    <Link onClick={closeMenuWithHighlight} to="/contract">
                        <li className='header__menu__dropdown__list__item'>Contracts</li>
                    </Link>
                    <Link onClick={closeMenuWithHighlight} to="/messages">
                        <li className='header__menu__dropdown__list__item'>
                            Messages
                            {incomingMailStatus &&
                            <img src={NewMailIcon} className="header-newMail-icon" alt='mailIcon'/>
                            }
                        </li>
                    </Link>
                    <Link onClick={closeMenuWithHighlight} to="/settings/compliance">
                        <li className='header__menu__dropdown__list__item'>Settings</li>
                    </Link>
                    <Link onClick={closeMenuWithHighlight} to="/referrals">
                        <li className='header__menu__dropdown__list__item'>
                            Referrals
                        </li>
                    </Link>
                    <Link onClick={closeMenuWithHighlight} to="/support">
                        <li className='header__menu__dropdown__list__item'>
                            Support
                        </li>
                    </Link>
                    <Link onClick={closeMenuWithHighlight} to="/faq">
                        <li className='header__menu__dropdown__list__item'>
                            FAQ
                        </li>
                    </Link>
                    {currentUser.attributes.type === 'Admin' &&
                    <Link onClick={closeMenuWithHighlight} to="/">
                        <li className='header__menu__dropdown__list__item'>
                            Dashboard
                        </li>
                    </Link>}
                    <div onClick={handleLogout}>
                        <li className='header__menu__dropdown__list__item'>
                            Logout
                        </li>
                    </div>
                </ul>
            </div>}
        </>
    );
};

export default HeaderTop;
