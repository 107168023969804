import eth from "./eth";
import project from "./project";
import ticket from "./ticket";
import collectibles from "./collectible";
import matcher from "./matcher";

export default {
  eth,
  project,
  ticket,
  collectibles,
  matcher
};