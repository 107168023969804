import React from 'react';
import './ToTopArrow.css'
import arrowIcon from '../../../assets/images/straight-arrow-.svg';

const ToTopArrow = () => {
    const toTop = () => {
        window.scroll({
            left: 0,
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <div className='toTopArrow__wrapper' onClick={toTop}>
            <img src={arrowIcon} alt="arrow"/>
        </div>
    );
};

export default ToTopArrow;