import React from 'react';
import ethClient from '../../eth_client';

const OpenOrders = (props) => {

  const { activeItem }  = props;

  function roundsof(n,decimal) {
    return (+n).toFixed(decimal)
  }

  const cancelOrder = (e,orderObj) =>{
    e.preventDefault();
    props.cancelOrder(orderObj);
  }

  return(
    <section className="chartOpenOrders">
      <div className="chartOpenOrders__titles">
        <span>User Type</span>
        <span>Order Type</span>
        <span>Amount in {activeItem.symbol}</span>
        <span>Price USDW</span>
        <span>Total USDW</span>
        <span>Action</span>
      </div>

      <ul className="listOfOpenOrders">

        {
          props.orders.map((ele, index) =>{
            const feeObj = ethClient.matcher.calculateFee(ele.quantity, ele.price, ele.orderType, ele.userType, props.percetnageLevels);
            return (
            <li className = "listOfOpenOrders__item" key = {index}>
              <span>{ele.userType}</span>
              <span>{ele.orderType}</span>
              <span>{ele.quantity}</span>
              <span>{ele.price}</span>
              <span>{feeObj.total}</span>
              <span><button onClick = {(e)=>cancelOrder(e, ele)}>Cancel</button></span>
            </li>)
          })
        }
      </ul>

    </section>
  );
};

export default OpenOrders;
