import React, {Component, useEffect, useState} from 'react';
import defaultLogo from "../../../../assets/images/F_in_White.png";
import prepareImagesPathForMinify from '../../../../utils/prepareImagesPathForMinify';
import AddBag from '../../../../assets/images/cart/bag.png'
import checkMarkIcon from '../../../../assets/images/check-symbol.svg';
import {connect} from "react-redux";
import * as blockChainAction from "../../../../modules/blockchain/blockchain.action";
// import * as cartAction from "../../../../modules/cart/cart.action";

// export default class PortfolioSquareItem extends Component {
const PortfolioSquareItem = ({privKey, properties,setTokens, tokens, index, id, license, selectedLicene, onClickLicense, setIndexItem, isReadyToSendToOtherWallets, handleHighlight, highlighted, isSellAllLicenses, highlightAll, showScheduleBtn, handleSellAllLicenses, logo}) => {
 console.log(index, id, license, selectedLicene, 'properties,setTokens, tokens, index, id, license, selectedLicene')
  // console.log(tokens, '====tokens===', properties, 'licenses', license, '========>>>>>>>>>>>>>>>>>===========')
  // console.log(index.id, "--------------index. id")
  // console.log(properties, "--------------properties")
  // console.log(selectedLicene, "--------------logo")
  // console.log(index,   id,key,license,selectedLicene,onClickLicense,handleHighlight,highlighяted, isSellAllLicenses, highlightAll, showScheduleBtn,handleSellAllLicenses, "--------------this.propsЦЦЦЦ")
  // state = {
  //   scheduleAuction: false,
  // };

  // console.log(license, "--------------license")
  // console.log(privKey, "--------------privKey")
  const [scheduleAuction, setScheduleAuction] = useState(false)
  const [addedItemToCart, setAddedItemToCart] = useState(false)
  const [hideLicense, setHideLicense] = useState(false)

  const setHighlight = () => {
    // this.portfolioItem.classList.add("highlight");
    // this.props.handleHighlight(this.portfolioItem);
    // handleSellAllLicenses(true);
    // this.setState({scheduleAuction: true});
  };

  useEffect(()=> {
    properties.properties.cart.lenght>0 &&  properties.properties.cart.forEach((i)=> {
      if (i===license) {
        setHideLicense(true)
      }
    })
  }, [properties.properties.cart

  ])
  const onClickItem = (indexItem) => {
    console.log(indexItem, "--------------indexItem")
    console.log(selectedLicene, "--------------selectedLicene")
    console.log(license, "--------------license")
    setIndexItem(indexItem)
    onClickLicense(id, license);
    setScheduleAuction(true)
    setHighlight();
    // showScheduleBtn(license);
  };

  const changeCart =async (selectedLicense, operator, logo) => {
      selectedLicense.logo = logo;
    if (operator ==='+') {
      tokens.forEach((i, idx)=> {
        if (i===selectedLicense) {
          console.log( "SOOOOOOQA1111AA--------------")
          let a = tokens;
          let c = [1,2,3,4,5,6,7,8]
          console.log(idx, "--------------idx")
           a.splice(idx, 1)
          setTokens(a)
          console.log(a, "--------------b")
        }
      })
    }

    console.log(tokens, selectedLicense,"--------------tokens")
    let reduxItemObj = {
      selectedLicense: selectedLicense,
      operation: operator
    }
    properties.properties.changeCartItems(reduxItemObj);
    setAddedItemToCart(!addedItemToCart)
    // cartAction.addToCart()
    // console.log('44', "--------------'44'")
     // let a = await cartAction.provideToCart(selectedLicense)
    // console.log(a, "--------------a")
    // console.log(properties.properties, "--------------properties.properties")
    // console.log(properties.properties.provideToCart(selectedLicense), "--------------selectedLicense")
  }


  // render() {
  //   const {
  //     index,
  //     license,
  //     selectedLicene,
  //     license: {
  //       logo,
  //       tickerSymbol,
  //       numOfLicenses,
  //       AuctionId: auctionId,
  //       isReadyToSendToOtherWallets
  //     },
  //     highlighted,
  //     id,
  //     isSellAllLicenses,
  //   } = this.props;

  // try {
  // if (highlighted !== this.portfolioItem) {
  //   this.portfolioItem.classList.remove("highlight")
  // }
  // if (~selectedLicenses.indexOf(id) && isSellAllLicenses) {
  //   // this.setHighlight()
  //   this.portfolioItem.classList.add("highlight");
  // }
  // } catch (e) {
  // this.portfolioItem && console.log('Error message', e.message);
  // }

  let className = "portfolioSquareItem";
  if (selectedLicene === id) className += " highlight";
  return (<>{!hideLicense &&
       <>
      <li style={{'position': 'relative'}}
          className='portfolioItem_li'

      >
        <div className={className} onClick={() => {if (!addedItemToCart){ onClickItem(index)} }}>
          {
            isReadyToSendToOtherWallets &&
            <span className="portfolioSquareItem--isReadyToSendToOtherWallets">
              <img src={checkMarkIcon} alt="readyToSend"/>
            </span>
          }

          <div className="logo-small"
               style={
                 {
                   backgroundImage: `url(${prepareImagesPathForMinify(logo, 25, 25) || defaultLogo})`,
                   backgroundPosition: "center",
                   backgroundSize: "cover",
                   backgroundRepeat: "no-repeat",
                 }
               }
          />
          <span className="dataPoint">#{license.properties.id.split('-')[1]}</span>
          <p className="title">{license.name}</p>

          {/*<span className="dataPoint totallicenses">{numOfLicenses}</span>*/}
        </div>
        {/*{!addedItemToCart ?*/}
          <div className='portfolioSquareItem_addbag-img' onClick={() => changeCart(license, '+', logo)}/>
        {/*  :*/}
        {/*  <div className='portfolioSquareItem_bin-img' onClick={() => changeCart(license, '-')}/>*/}
        {/*}*/}
      </li>
    </>
    }</>
  );

};

function mapStateToProps({cart}) {
  return {
    // cartItems: cart.cartItems
  };
}

export default connect(
  mapStateToProps,
)(PortfolioSquareItem);



