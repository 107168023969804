import React, {useEffect, useRef, useState} from "react";
import {portfolioDWTitle, portfolioTab, portfolioTabDepositTickers, portfolioTabTitle} from "../centralTokenData";
import closeSvg from "../../../../assets/images/close--blue.svg";
import ethClient from "../../../eth_client";
import {swapUsdc} from '../../../api/tendermint/usdcToAdminSwap'

import QRCode from "qrcode.react";
import {generatePdfTable} from "../../../../utils/generatePdfTable";
import axios from "axios";
import constants from "../../../api/constant";
import {getWallyWalletAddress} from "../../../../utils/temndermintWalletsUtils";
import {message} from "../../../../utils/message";

const CentralTokenDepositModal = ({
                                      ethWallet,
                                      makeDeposit,
                                      walletAddress,
                                      handleInputChange,
                                      isLoading,
                                      balances,
                                      activeTabDeposit,
                                      popupPage,
                                      closeModal,
                                      statusPopup,
                                  }) => {


    const [withdrawals, setWithdrawals] = useState([])
    const [deposits, setDeposits] = useState([])
    const [currentDeposit, setCurrentDeposit] = useState("deposit")
    const [currentDepositTab, setcurrentDepositTab] = useState("Balances")
    const [usdcValue, setUsdcValue] = useState(0)
    const [errorMessage, setErrorMessage] = useState(false)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (loading) {
            const resetProgressBar = () => {
                width = 1;
                clearInterval(interval)
                elem.style.width = width + '%';
            }

            let elem = document.getElementById("progress-bar");
            console.log(elem, '--elem')
            let width = 1;
            let interval;


            resetProgressBar();

            interval = setInterval(frame, 580);

            function frame() {
                if (width >= 100) {
                    clearInterval(interval);
                } else {
                    width++;
                    elem.style.width = width + '%';
                }
            }


        }
    }, [loading])
    const startProgressBar = () => {
        // const  resetProgressBar=()=> {
        //     width = 1;
        //     clearInterval(interval)
        //     elem.style.width = width + '%';
        // }
        //
        // let elem = document.getElementById("progress-bar");
        // console.log(elem, '--elem')
        // let width = 1;
        // let interval;
        //
        //
        //     resetProgressBar();
        //
        //     interval = setInterval(frame, 100);
        //
        //     function frame() {
        //         if (width >= 100) {
        //             clearInterval(interval);
        //         } else {
        //             width++;
        //             elem.style.width = width + '%';
        //         }
        //     }


    }

    const getWithdrawalHistory = async () => {
        const res = await ethClient.eth.getWithdrawalHistory(walletAddress, ["ETH", "USDC", "LOOM"]);
        setcurrentDepositTab('Withdraw')
        setWithdrawals(res)
    }

    const getDepositHistory = async () => {
        // const res = await ethClient.getDepositHistory(walletAddress, ["ETH", "USDC", "LOOM"]);
        // setDeposits(res)
        setcurrentDepositTab('Deposits')
    }

    const formatDate = timestamp => {
        let date = new Date(null);
        date.setSeconds(timestamp);
        return date
            .toISOString()
            .slice(0, 19)
            .replace(/-/g, "/")
            .replace("T", " ");
    };

    const swapUSDCFunc = async () => {
        if (usdcValue <= 0) {
            message('error', 'your input value should be more that 0 ', 3000)

        } else {
            setLoading(true)


            let wallyWallet = await getWallyWalletAddress();
            if (wallyWallet) {

                if (ethWallet) {
                    console.log(wallyWallet, 'wallyWallet')
                    // ethWallet.ethActivePrivateKey - private key for active ETH wallet
                    let txHash = await swapUsdc((ethWallet.ethActivePrivateKey).toString(), usdcValue)
                    console.log(txHash, '----txHash')
                    if (txHash) {
                        const res = await axios.post(`${constants.usdwHost}sales/buy`, {
                            "txHash": txHash.toString(),
                            "wallyAddress": wallyWallet
                        });
                        console.log(res, '----res')
                        if (res) {
                            message('success', 'Your USDW deposit success confirmed ', 3400)
                            setUsdcValue(0)
                            console.log(txHash, '---👻👻👻👻👻👻👻---')
                            setLoading(false)
                        } else {
                            message('error', 'Server Error', 3000)
                        }

                    } else {
                        message('error', 'Something goes wrong', 3000)
                    }


                }
            }
        }
    }

    const downloadPdfTransactions = () => {
        const mail = 'ronald.mears.jr@gmail.com';
        const account = 'FANBOY7 Wallet';
        generatePdfTable({mail, account})
    }

    const setupUSDCValue = (value) => {
        if (value && value > 0) {
            setUsdcValue(value) && setErrorMessage(false)
        } else {
            setUsdcValue(0) && setErrorMessage("you should input more, that 0");

        }
    }

    return (
        // !statusPopup && popupPage !== "deposit" &&
        statusPopup && popupPage === "deposit" &&
        <div className="slideshowModal popup_token-block">
            <button className="slideshowModalWrapper__closeButton slideshowModalButton">
                <img src={closeSvg} alt="close" onClick={() => closeModal(false, null)}/>
            </button>
            <div className=" popup-withdraw popup_tokens">
                <div className={`myTicketsListContainer__header myTickets--title ${activeTabDeposit}`}>
                    <button className={`btn-tab ${(currentDeposit === "deposit") ? "active" : ""}`}
                            onClick={() => setCurrentDeposit("deposit")}>
                        Deposit
                    </button>
                    <button className={`btn-tab ${(currentDeposit === "portfolio") ? "active" : ""}`}
                            onClick={() => setCurrentDeposit("portfolio")}>
                        PORTFOLIO
                    </button>
                </div>
                {currentDeposit === 'deposit'
                    ?
                    <div className="withDraw-flex">
                        Fanbase Account

                        <div>USDC>USDW</div>
                        You want to buy <div>
                        <input type="number" min={0} defaultValue={0}
                               onChange={(e) => setupUSDCValue(e.target.valueAsNumber)}/>
                        USDW</div>
                        this is {usdcValue} USDC
                        <div>Transaction gas is 0.00085 ETH</div>

                        {/*<select name="coin_name" onChange={handleInputChange}>*/}
                        {/*    <option value="USDC">USDC</option>*/}

                        {/*<option value="ETH">ETH</option>*/}
                        {/*<option value="BAT">BAT</option>*/}
                        {/*<option value="LINK">LINK</option>*/}
                        {/*<option value="LOOM">LOOM</option>*/}
                        {/*<option value="REP">REP</option>*/}
                        {/*<option value="ZRX">ZRX</option>*/}
                        {/*<option value="DAI">DAI</option>*/}
                        {/*</select>*/}

                        {/*<div className="wallets-block">*/}
                        {/*    Wallet Address*/}
                        {/*    <p>{walletAddress}</p>*/}
                        {/*</div>*/}
                        {/*<QRCode value={`address:${walletAddress}`}/>*/}
                        {/*<div className="token_popup-inputs">*/}
                        {/*    <p className="token_popup-amounts">Current ETH Portfolio</p>*/}
                        {/*    <p className="token_popup-amounts">2233 ETH</p>*/}
                        {/*</div>*/}
                        {loading && <div className="slide-progress-bar">
                            <div className="progress-bar" id="progress-bar"/>

                        </div>
                        }
                        {!loading && <div className="mt-3">
                            <button type="button" className="btn btn-primary btn-md btn-block"
                                    disabled={isLoading} onClick={() => swapUSDCFunc()}>
                                Make Deposit
                            </button>
                        </div>}
                    </div>


                    : <div className="portfolio-popup">
                        <div className="portfolio-tab-block">
                            <button className={currentDepositTab === "Balances"
                                ? "portfolio-btn-active"
                                : "portfolio-tab-btn"}
                                    onClick={() => setcurrentDepositTab('Balances')}>
                                Balances
                            </button>
                            <button className={currentDepositTab === "Deposits"
                                ? "portfolio-btn-active"
                                : "portfolio-tab-btn"}
                                    onClick={getDepositHistory}>
                                Deposits
                            </button>
                            <button className={currentDepositTab === "Withdraws"
                                ? "portfolio-btn-active"
                                : "portfolio-tab-btn"}
                                    onClick={getWithdrawalHistory}>
                                Withdraws
                            </button>
                            <button className='portfolio-btn-download'
                                    onClick={downloadPdfTransactions}
                            >
                                Download Full Table
                            </button>
                        </div>
                        <table className="portfolio-table">
                            <thead>
                            <tr>
                                {currentDepositTab === "Balances" &&
                                portfolioTabTitle.map((i, k) =>
                                    <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
                                }
                                {currentDepositTab === "Deposits" &&
                                portfolioDWTitle.map((i, k) =>
                                    <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
                                }
                                {currentDepositTab === "Withdraws" &&
                                portfolioDWTitle.map((i, k) =>
                                    <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {currentDepositTab === "Balances" && portfolioTab.map((i, idx) =>
                                <tr key={idx}>
                                    <td><p>{i.Asset}</p></td>
                                    <td><p>{i.Holdings}%</p></td>
                                    <td><p>${i.Balance}</p></td>
                                    <td><p>{i.Available}</p></td>
                                    <td><p>${i.Price}</p></td>
                                </tr>)
                            }
                            {currentDepositTab === "Deposits" && portfolioTabDepositTickers.map((i, idx) =>
                                <tr key={idx}>
                                    <td><p>{i.Date}</p></td>
                                    <td><p>{i.Asset}</p></td>
                                    <td><p>{i.Quantity}</p></td>
                                    <td><p>${(i.Amount / i.Quantity).toFixed(2)}</p></td>
                                    <td><p>${i.Amount}</p></td>
                                </tr>)
                            }
                            {currentDepositTab === "Withdraws" && withdrawals.map((i, k) =>
                                <tr key={k}>
                                    <td><p>{formatDate(i.timestamp)}</p></td>
                                    <td><p>{i.asset}</p></td>
                                    <td><p>{i.value}</p></td>
                                    <td><p>$0</p></td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </div>}
            </div>
            }
        </div>
    );


    /*    (
            statusPopup && popupPage === "deposit" &&
            <div className="slideshowModal popup_token-block">
                <button className="slideshowModalWrapper__closeButton slideshowModalButton">
                    <img src={closeSvg} alt="close" onClick={() => closeModal(false, null)}/>
                </button>
                <div className=" popup-withdraw popup_tokens">
                    <div className={`myTicketsListContainer__header myTickets--title ${activeTabDeposit}`}>
                        <button className={`btn-tab ${(currentDeposit === "deposit") ? "active" : ""}`}
                                onClick={() => setCurrentDeposit("deposit")}>
                            Deposit
                        </button>
                        <button className={`btn-tab ${(currentDeposit === "portfolio") ? "active" : ""}`}
                                onClick={() => setCurrentDeposit("portfolio")}>
                            PORTFOLIO
                        </button>
                    </div>
                    {currentDeposit === 'deposit'
                        ?
                        <div className="withDraw-flex">
                            Fanbase Account
                            <select name="coin_name" onChange={handleInputChange}>
                                <option value="ETH">ETH</option>
                                <option value="USDC">USDC</option>
                                <option value="BAT">BAT</option>
                                <option value="LINK">LINK</option>
                                <option value="LOOM">LOOM</option>
                                <option value="REP">REP</option>
                                <option value="ZRX">ZRX</option>
                                <option value="DAI">DAI</option>
                            </select>

                            <div className="wallets-block">
                                Wallet Address
                                <p>{walletAddress}</p>
                            </div>
                            <QRCode value={`address:${walletAddress}`}/>
                            <div className="token_popup-inputs">
                                <p className="token_popup-amounts">Current ETH Portfolio</p>
                                <p className="token_popup-amounts">2233 ETH</p>
                            </div>
                            <div className="mt-3">
                                <button type="button" className="btn btn-primary btn-md btn-block"
                                        disabled={isLoading} onClick={makeDeposit}>
                                    Make Deposit
                                </button>
                            </div>
                        </div>
                        : <div className="portfolio-popup">
                            <div className="portfolio-tab-block">
                                <button className={currentDepositTab === "Balances"
                                    ? "portfolio-btn-active"
                                    : "portfolio-tab-btn"}
                                        onClick={() => setcurrentDepositTab('Balances')}>
                                    Balances
                                </button>
                                <button className={currentDepositTab === "Deposits"
                                    ? "portfolio-btn-active"
                                    : "portfolio-tab-btn"}
                                        onClick={getDepositHistory}>
                                    Deposits
                                </button>
                                <button className={currentDepositTab === "Withdraws"
                                    ? "portfolio-btn-active"
                                    : "portfolio-tab-btn"}
                                        onClick={getWithdrawalHistory}>
                                    Withdraws
                                </button>
                                <button className='portfolio-btn-download'
                                        onClick={downloadPdfTransactions}
                                >
                                    Download Full Table
                                </button>
                            </div>
                            <table className="portfolio-table">
                                <thead>
                                <tr>
                                    {currentDepositTab === "Balances" &&
                                    portfolioTabTitle.map((i, k) =>
                                        <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
                                    }
                                    {currentDepositTab === "Deposits" &&
                                    portfolioDWTitle.map((i, k) =>
                                        <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
                                    }
                                    {currentDepositTab === "Withdraws" &&
                                    portfolioDWTitle.map((i, k) =>
                                        <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {currentDepositTab === "Balances" && portfolioTab.map((i, idx) =>
                                    <tr key={idx}>
                                        <td><p>{i.Asset}</p></td>
                                        <td><p>{i.Holdings}%</p></td>
                                        <td><p>${i.Balance}</p></td>
                                        <td><p>{i.Available}</p></td>
                                        <td><p>${i.Price}</p></td>
                                    </tr>)
                                }
                                {currentDepositTab === "Deposits" && portfolioTabDepositTickers.map((i, idx) =>
                                    <tr key={idx}>
                                        <td><p>{i.Date}</p></td>
                                        <td><p>{i.Asset}</p></td>
                                        <td><p>{i.Quantity}</p></td>
                                        <td><p>${(i.Amount / i.Quantity).toFixed(2)}</p></td>
                                        <td><p>${i.Amount}</p></td>
                                    </tr>)
                                }
                                {currentDepositTab === "Withdraws" && withdrawals.map((i, k) =>
                                    <tr key={k}>
                                        <td><p>{formatDate(i.timestamp)}</p></td>
                                        <td><p>{i.asset}</p></td>
                                        <td><p>{i.value}</p></td>
                                        <td><p>$0</p></td>
                                    </tr>)
                                }
                                </tbody>
                            </table>
                        </div>}
                </div>
                }
            </div>
        );*/
};

export default CentralTokenDepositModal;
