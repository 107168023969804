import React from 'react';

class ColorPickerBody extends React.Component {
  /* static propTypes = {
    color: PropTypes.string.isRequired, // it's only a hex string - "#000000", color.length = 7;
    onChange: PropTypes.func.isRequired // example: (hex) => { this.setState({ textColor: hex }) };
  } */

  hexToRGB = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result 
      ? 'rgba(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',1)'
      : null;
  };

  state = {
    blockIsDragged: false,
    stripIsDragged: false,
    hex: (this.props.color !== undefined) ? this.props.color : '#ff0000',
    rgbaColor: (this.props.color !== undefined) ? this.hexToRGB(this.props.color) : 'rgba(255,0,0,1)'
  };

  colorPickerClose = (evt) => {
    if (!this.elBody) return false;

    const currEl = evt.target;
    const hasElBodyChild = this.elBody.contains(currEl);

    if (this.elBody !== currEl && !hasElBodyChild) {
      this.props.handleClose();
    }
  };

  componentDidMount() {
    this.initializeColorPicker();

    if (typeof window === 'object') {
      
      if (window.__cpOpen === true) {
        const modalClose = window.document.onclick;

        if (typeof modalClose === 'function') {
          modalClose(null, true);
        }
      }

      window.__cpOpen = true;

      window.document.addEventListener('click', this.colorPickerClose);
    }
  }

  componentWillUnmount() {
    const { colorBlock, colorStrip } = this;

    colorStrip.removeEventListener("mousedown", this.mousedown, false);
    colorStrip.removeEventListener("mouseup", this.mouseup, false);
    colorStrip.removeEventListener("mousemove", this.mousemove, false);

    window.document.removeEventListener('click', this.colorPickerClose);

    colorBlock.removeEventListener("mousedown", this.mousedown, false);
    colorBlock.removeEventListener("mouseup", this.mouseup, false);
    colorBlock.removeEventListener("mousemove", this.mousemove, false);
  }
 
  render () {
    const { rect, pos } = this.props;
    let x = -164, y = 0;
    if (pos === 'top') {
      y = -4;
    } else {
      y = -190;
    }

    const style = (rect !== null) ? {
      position: 'absolute',
      top: ~~rect.top + y + 'px',
      left: ~~rect.left + x + 'px'
    } : null;

    return (
      <div id="cp-body" style={style} ref={(ref) => this.elBody = ref}>
        <input type="checkbox" id="cp-color-input" checked readOnly />
        <div id="cp-color-picker" onMouseLeave={(e) => this.setState({ stripIsDragged: false, blockIsDragged: false })}>
          <div id="cp-canvas-block">
            <canvas
              id="cp-color-block"
              ref={(ref) => this.colorBlock = ref} 
              height="150" width="180"
            />    
            <canvas 
              id="cp-color-strip"
              ref={(ref) => this.colorStrip = ref} 
              height="150" width="15"
            />
          </div>
          <div id='cp-input-block'>
            <label 
              htmlFor="cp-color-input"
              ref={(ref) => this.colorLabel = ref}
              id="cp-color-label" 
              style={{ backgroundColor: this.state.rgbaColor }}
            />
            <input 
              type='text' 
              id='cp-hex-input'
              value={this.state.hex} 
              onChange={this.handleInput} 
            />
            <button onClick={(e) => {e.preventDefault();this.props.handleClose(e)}} id='cp-close-btn'>Close</button>
          </div>
        </div>
      </div>
    );
  }

  handleInput = (e) => {
    const { value } = e.target;

    if (value.length < 1) {
      if (this.state.hex !== '#') {
        this.setState({ hex: '#' });
      }
      return;
    }

    if (value.length > 7) {
      return;
    }

    if (value.substring(1).search(/[^a-f0-9]/) === -1) {
      if (value.length === 7) {
        const { colorBlock, colorStrip } = this;
        const ctx1 = colorBlock.getContext('2d');
        const ctx2 = colorStrip.getContext('2d');
        const width = colorBlock.width;
        const height = colorBlock.height;

        const { onChange } = this.props;

        this.fillGradient(ctx1, ctx2, width, height);

        if (typeof onChange === 'function') {
          onChange('#' + value.substring(1));
        }
      } else {
        this.setState({ hex: '#' + value.substring(1) });
      }
    }
  }

  initializeColorPicker = () => {
    const { colorBlock, colorStrip } = this;
    const ctx1 = colorBlock.getContext('2d');
    const width1 = colorBlock.width;
    const height1 = colorBlock.height;

    const ctx2 = colorStrip.getContext('2d');
    const width2 = colorStrip.width;
    const height2 = colorStrip.height;

    ctx1.rect(0, 0, width1, height1);
    this.fillGradient(ctx1, ctx2, width1, height1);

    ctx2.rect(0, 0, width2, height2);
    const grd1 = ctx2.createLinearGradient(0, 0, 0, height1);
    grd1.addColorStop(0, 'rgba(255, 0, 0, 1)');
    grd1.addColorStop(0.17, 'rgba(255, 255, 0, 1)');
    grd1.addColorStop(0.34, 'rgba(0, 255, 0, 1)');
    grd1.addColorStop(0.51, 'rgba(0, 255, 255, 1)');
    grd1.addColorStop(0.68, 'rgba(0, 0, 255, 1)');
    grd1.addColorStop(0.85, 'rgba(255, 0, 255, 1)');
    grd1.addColorStop(1, 'rgba(255, 0, 0, 1)');
    ctx2.fillStyle = grd1;
    ctx2.fill();

    colorStrip.addEventListener("mousedown", this.mousedown('strip', ctx1, ctx2, width1, height1), false);
    colorStrip.addEventListener("mouseup", this.mouseup('strip'), false);
    colorStrip.addEventListener("mousemove", this.mousemove(ctx1, ctx2, width1, height1), false);

    colorBlock.addEventListener("mousedown", this.mousedown('block', ctx1), false);
    colorBlock.addEventListener("mouseup", this.mouseup('block'), false);
    colorBlock.addEventListener("mousemove", this.mousemove(ctx1), false);
  }

  mousedown = (key, ctx, ctx2, width, height) => (e) => {
    if (key === 'block') {
      this.setState({ blockIsDragged: true })
      this.changeColor(ctx)(e);
    }
    if (key === 'strip') {
      this.setState({ stripIsDragged: true })
      this.handleClick(ctx, ctx2, width, height)(e);
    }
  }

  mousemove = (ctx, ctx2, width, height) => (e) => {
    if (this.state.blockIsDragged) {
      this.changeColor(ctx)(e);
    }

    if (this.state.stripIsDragged) {
      this.handleClick(ctx, ctx2, width, height)(e);
    }
  }

  mouseup = (key) => (e) => {
    if (key === 'block') {
      this.setState({ blockIsDragged: false });
    }

    if (key === 'strip') {
      this.setState({ stripIsDragged: false });
    }
  }

  handleClick = (ctx1, ctx2, width, height) => (e) => {
    if (ctx1 === undefined || ctx2 === undefined) {
      return;
    }

    const x = e.offsetX;
    const y = e.offsetY;
    const imageData = ctx2.getImageData(x, y, 1, 1).data;
    const rgbaColor = 'rgba(' + imageData[0] + ',' + imageData[1] + ',' + imageData[2] + ',1)';

    this.setState({ rgbaColor }, (e) => this.fillGradient(ctx1, ctx2, width, height));
  }

  changeColor = (ctx) => (e) => {
    const { onChange } = this.props;

    const x = e.offsetX;
    const y = e.offsetY;
    const imageData = ctx.getImageData(x, y, 1, 1).data;
    const r = imageData[0];
    const g = imageData[1];
    const b = imageData[2];
    this.colorLabel.style.backgroundColor = 'rgba(' + r + ',' + g + ',' + b + ',1)';
    const hex = this.RGBToHex(r, g, b);
    this.setState({ hex });
    
    if (typeof onChange === 'function') {
      onChange(hex);
    }
  }

  fillGradient = (ctx1, ctx2, width, height) => {
    const { rgbaColor } = this.state;
    ctx1.fillStyle = rgbaColor;
    ctx1.fillRect(0, 0, width, height);

    const grdWhite = ctx2.createLinearGradient(0, 0, width, 0);
    grdWhite.addColorStop(0, 'rgba(255,255,255,1)');
    grdWhite.addColorStop(1, 'rgba(255,255,255,0)');
    ctx1.fillStyle = grdWhite;
    ctx1.fillRect(0, 0, width, height);

    const grdBlack = ctx2.createLinearGradient(0, 0, 0, height);
    grdBlack.addColorStop(0, 'rgba(0,0,0,0)');
    grdBlack.addColorStop(1, 'rgba(0,0,0,1)');
    ctx1.fillStyle = grdBlack;
    ctx1.fillRect(0, 0, width, height);
  }

  RGBToHex = (r, g, b) => {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
  
    if (r.length === 1) r = "0" + r;     
    if (g.length === 1) g = "0" + g;     
    if (b.length === 1) b = "0" + b;
      
    return "#" + r + g + b;
  }
}

export default ColorPickerBody;
