import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

export class ModalWindow extends Component {

    componentWillMount() {
        this.root = document.createElement('div');
        document.body.appendChild(this.root);
    }

    componentDidMount = () => {
        const self = this;
        const modal = this.modal;
        //event for hiding the modal
        window.onclick = function (event) {
            if (event.target === modal) {
                self.hideModal();
            }
        };
    };

    //for using portal
    componentWillUnmount() {
        document.body.removeChild(this.root);
    }

    hideModal = () => {
        this.props.hideModal();
    };

    render() {
        const {
            children,
            title
        } = this.props;

        return ReactDOM.createPortal(
            <div className="modal-window" ref={node => {
                this.modal = node;
            }}>
                <div className="modal-window__wrapper">
                    <div className="modal-window__header">
                        <div className="modal-window__header-title">
                            {title}
                        </div>
                        <div className="modal-close-button" onClick={this.hideModal}/>
                    </div>
                    <div className="modal-window__body">
                        {children}
                    </div>
                </div>
            </div>,
            this.root
        );
    }
}

ModalWindow.propTypes = {
    // color: PropTypes.string.isRequired, // it's only a hex string - "#000000", color.length = 7;
    // pos: PropTypes.string, // 'top' || 'bottom'
    //onChange: PropTypes.func.isRequired // example: (hex) => { this.setState({ textColor: hex }) };
    // isOpen: PropTypes.bool,
    hideModal: PropTypes.func.isRequired,
};
