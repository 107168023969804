import React from "react";
import './exchange.css';
import BitcoinLogo from "../../../assets/images/bitcoinLogo.png";
import EthereumLogo from "../../../assets/images/ethereumLogo.png";
import LitecoinLogo from "../../../assets/images/litecoin.png";
import Monero from "../../../assets/images/monero.png";
import NeoLogo from "../../../assets/images/neo.png";
import DashLogo from "../../../assets/images/dash.png";
import VergeLogo from "../../../assets/images/verge.png";
import ZcashLogo from "../../../assets/images/zcash.png";
import LogoSW from "../../../assets/images/f-in-blue1.png";
import ThumbnailChart from '../ThumbnailChart/thumbnailChart.js';
import {chart, footer, header} from '../ThumbnailChart/thumbnailChart.json';

export class ExchangeLeftSide extends React.Component {
    constructor(props) {
        super(props);
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.props.handleCreateTransaction();
        }
    };

    render() {
        const {activeTab, updateTab} = this.props;
        return (
            <div>
                <div className="cHeader">
                    <ul>
                        <li className={activeTab === "buy" ? "active" : null}
                            onClick={() => updateTab("buy")}>
                            Buy
                        </li>
                        <li className={activeTab === "sell" ? "active" : null}
                            onClick={() => updateTab("sell")}>
                            Sell
                        </li>
                    </ul>
                </div>


                <div className="cBody">
                    <div className="paymentMethod">
                        <p className="title">{activeTab === "buy" ? "Purchase USDW" : "Sell USDW"}</p>
                        <ul className="coinWrapperUL" style={{"textAlign": "left"}}>
                            <li>
                                <div>
                                    <ThumbnailChart header={header} chart={chart} footer={footer} logo={LogoSW}/>
                                </div>
                            </li>


                            {/*<li>
								<div className="content disabled" style={{"text-align":"center"}}>
									<div className="logoP bitcoin">
										<img src={BitcoinLogo} />
									</div>
									<p className="coinTitle">Bitcoin</p>
								</div>
								<span className="pull-right tag green">Coming Soon</span>
							</li>
							<li className="hideinMobile">
								<div className="content disabled" style={{"text-align":"center"}}>
									<div className="logoP bitcoincash">
										<img src={BitcoinLogo} />
									</div>
									<p className="coinTitle">Bitcoin Cash</p>
								</div>
								<span className="pull-right tag green">Coming Soon</span>
							</li>
							<li>
								<div className="content disabled" style={{"text-align":"center"}}>
									<div className="logoP ethereum">
										<img src={EthereumLogo} />
									</div>
									<p className="coinTitle">Ethereum</p>
								</div>
								<span className="pull-right tag green">Coming Soon</span>
							</li>
							<li className="hideinMobile">
								<div className="content disabled" style={{"text-align":"center"}}>
									<div className="logoP litecoin">
										<img src={LitecoinLogo} />
									</div>
									<p className="coinTitle">Litecoin</p>
								</div>
								<span className="pull-right tag green">Coming Soon</span>
		</li>*/}

                        </ul>
                    </div>
                    <hr/>
                    <br/>


                    <div className="paymentMethod">
                        <p className="title">{activeTab === "buy" ? "Payment Method" : "Convert to"}</p>
                        <ul className="coinWrapperUL">
                            <li>
                                <div className={this.props.pCurrency == "USD" ? "content active" : "content"}
                                     id="USD:USD" onClick={this.props.handPaymentSelection}>
                                    <div className="logoP usd" id="USD:USD">
                                        <i className="fa fa-usd" id="USD:USD"/>
                                    </div>
                                    <p className="coinTitle" id="USD:USD">USD</p>
                                </div>
                            </li>

                            <li>
                                <div className={this.props.pCurrency == "BTC" ? "content active" : "content"}
                                     id="BTC:Bitcoin" onClick={this.props.handPaymentSelection}>
                                    <div className="logoP bitcoin" id="BTC:Bitcoin">
                                        <img src={BitcoinLogo} id="BTC:Bitcoin"/>
                                    </div>
                                    <p className="coinTitle" id="BTC:Bitcoin">Bitcoin</p>
                                </div>
                            </li>

                            <li>
                                <div className={this.props.pCurrency == "BCH" ? "content active" : "content"}
                                     id="BCH:Bitcoin Cash" onClick={this.props.handPaymentSelection}>
                                    <div className="logoP bitcoincash" id="BCH:Bitcoin Cash">
                                        <img src={BitcoinLogo} id="BCH:Bitcoin Cash"/>
                                    </div>
                                    <p className="coinTitle" id="BCH:Bitcoin Cash">Bitcoin Cash</p>
                                </div>
                            </li>
                            <li>
                                <div className={this.props.pCurrency == "ETH" ? "content active" : "content"}
                                     id="ETH:Ethereum" onClick={this.props.handPaymentSelection}>
                                    <div className="logoP ethereum" id="ETH:Ethereum">
                                        <img src={EthereumLogo} id="ETH:Ethereum"/>
                                    </div>
                                    <p className="coinTitle" id="ETH:Ethereum">Ethereum</p>
                                </div>
                            </li>
                            <li>
                                <div className={this.props.pCurrency == "LTC" ? "content active" : "content"}
                                     id="LTC:Litecoin" onClick={this.props.handPaymentSelection}>
                                    <div className="logoP litecoin" id="LTC:Litecoin">
                                        <img src={LitecoinLogo} id="LTC:Litecoin"/>
                                    </div>
                                    <p className="coinTitle" id="LTC:Litecoin">Litecoin</p>
                                </div>
                            </li>
                            <li>
                                <div className={this.props.pCurrency == "XMR" ? "content active" : "content"}
                                     id="XMR:Monero" onClick={this.props.handPaymentSelection}>
                                    <div className="logoP monero" id="XMR:Monero">
                                        <img src={Monero} id="XMR:Monero"/>
                                    </div>
                                    <p className="coinTitle" id="XMR:Monero">Monero</p>
                                </div>
                            </li>
                            <li>
                                <div className={this.props.pCurrency == "NEO" ? "content active" : "content"}
                                     id="NEO:NEO" onClick={this.props.handPaymentSelection}>
                                    <div className="logoP neo" id="NEO:NEO">
                                        <img src={NeoLogo} id="NEO:NEO"/>
                                    </div>
                                    <p className="coinTitle" id="NEO:NEO">NEO</p>
                                </div>
                            </li>
                            <li>
                                <div className={this.props.pCurrency == "DASH" ? "content active" : "content"}
                                     id="DASH:Dash" onClick={this.props.handPaymentSelection}>
                                    <div className="logoP dash" id="DASH:Dash">
                                        <img src={DashLogo} id="DASH:Dash"/>
                                    </div>
                                    <p className="coinTitle" id="DASH:Dash">Dash</p>
                                </div>
                            </li>
                            <li>
                                <div className={this.props.pCurrency == "XVG" ? "content active" : "content"}
                                     id="XVG:Verge" onClick={this.props.handPaymentSelection}>
                                    <div className="logoP verge" id="XVG:Verge">
                                        <img src={VergeLogo} id="XVG:Verge"/>
                                    </div>
                                    <p className="coinTitle" id="XVG:Verge">Verge</p>
                                </div>
                            </li>
                            <li>
                                <div className={this.props.pCurrency == "ZEC" ? "content active" : "content"}
                                     id="ZEC:Zcash" onClick={this.props.handPaymentSelection}>
                                    <div className="logoP zcash" id="ZEC:Zcash">
                                        <img src={ZcashLogo} id="ZEC:Zcash"/>
                                    </div>
                                    <p className="coinTitle" id="ZEC:Zcash">Zcash</p>
                                </div>
                            </li>

                            {/*
							<li>
								<div className={this.props.pCurrency == "USD" ? "content active" : "content"} id="USD:USD" onClick={this.props.handPaymentSelection}>
									<div className="logoP usd" id="USD:USD">
										<i className="fa fa-usd" id="USD:USD" />
									</div>
									<p className="coinTitle" id="USD:USD">USD</p>
								</div>
							</li>*/}

                        </ul>
                    </div>
                    {activeTab === "buy" ?
                    <div className="amountToSend buy-sell__block">
                        {
                            this.props.pCurrency === "USD" ?
                                this.props.amount === "" || parseFloat(this.props.amount) < this.props.minimum ?
                                    <p className="title">Buy Amount<span
                                        className="pull-right minimumAmount">${this.props.minimum} minimum</span></p>
                                    :
                                    <p className="title">Buy Amount</p>

                                :

                                this.props.amount === "" || parseFloat(this.props.amount) < 100 ?
                                    <p className="title buy-sell__block-title">Buy Amount<span className="pull-right minimumAmount">$100 minimum</span>
                                    </p>
                                    :
                                    <p className="title">Buy Amount</p>
                        }
                        <input value={this.props.amount} onKeyPress={this._handleKeyPress}
                               onChange={this.props.handleInputAmount} type="tel" className="amountInput exchangeInput" maxLength="9"
                               placeholder="0.00"/>
                        <select dir="rtl" className="amountSelect ">
                            <option>{activeTab === "buy" ? "USD" : "USDW"}</option>
                        </select>
                        <br style={{"clear": "both"}}/>
                    </div>

                    :<div className="amountToSend buy-sell__block">
                        {
                            this.props.pCurrency === "USD" ?
                                this.props.amount === "" || parseFloat(this.props.amount) < this.props.minimum ?
                                    <p className="title">Sell Amount<span
                                        className="pull-right minimumAmount">{this.props.minimum} USDW minimum</span></p>
                                    :
                                    <p className="title">Sell Amount</p>
                                :
                                this.props.amount === "" || parseFloat(this.props.amount) < 100 ?
                                    <p className="title buy-sell__block-title">Sell Amount<span className="pull-right minimumAmount">100 USDW minimum</span>
                                    </p>
                                    :
                                    <p className="title">Sell Amount</p>
                        }
                        <input value={this.props.amount} onKeyPress={this._handleKeyPress}
                               onChange={this.props.handleInputAmount} type="tel" className="amountInput exchangeInput" maxLength="9"
                               placeholder="0.00"/>
                        <select dir="rtl" className="amountSelect">
                            <option>{activeTab === "buy" ? "USD" : "USDW"}</option>
                        </select>
                        <br style={{"clear": "both"}}/>
                    </div>
                    }
                </div>
            </div>
        )
    }
}

