import React from 'react';
import deleteIcon from "../../../assets/images/rubbish-bin.svg";
import saveIcon from "../../../assets/images/check-symbol.svg";
import settingIcon from "../../../assets/images/settings-work-tool.svg";
import toBase64 from "../../../utils/toBase64";
import {sendParseRequest} from "../../../utils/sendParseRequest";

const InternalControlPanel = ({onDeleteImg, isDraggable, saveImages, index, onChangeImage, isDeleted}) => {
    const onLoadImg = async (evt) => {
        const files = evt.target.files;

        if (files.length > 0) {
            if (files[0].type === "image/png" || files[0].type === "image/jpeg" || files[0].type === "image/jpg") {
                const file1Base64 = await toBase64(files[0]);

                sendParseRequest('uploadProjectImage', {base64: file1Base64, type: files[0].type})
                    .then(res => onChangeImage(index, res))
            }
        }
    };

    return (
        <div>
            <div
                className={`controlPanel controlPanel-internal ${isDraggable ? 'controlPanel--draggable' : ''} controlPanel-mobile`}>
                <div className="controlPanelActiveSetting">
                    <div className="controlPanel__element">
                        <button onClick={() => onDeleteImg(index)}>
                            <img className='controlPanel__delete-icon' src={deleteIcon} alt="delete button"/>
                        </button>
                    </div>
                </div>
                <div className="controlPanel__element controlPanel__element--setting">
                    <button onClick={isDraggable ? saveImages : null}>
                        <img src={isDraggable ? saveIcon : settingIcon} alt="delete button"/>
                    </button>
                </div>
            </div>
            {isDeleted && <div className="uploadImgInputBox-internal">
                <input onChange={onLoadImg}
                       className="uploadImgInputBox__input"
                       id="inputChangeImg"
                       type="file"
                       hidden
                />
                <label className="uploadImgInputBox__label-internal"
                       htmlFor="inputChangeImg"> </label>
            </div>}
        </div>

    );

};

export default InternalControlPanel;