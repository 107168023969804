"use strict";
import axios from "axios";
import {SigningCosmWasmClient} from "@cosmjs/cosmwasm-stargate";
import {coins, GasPrice} from "@cosmjs/stargate";
import {TxRaw} from "@cosmjs/stargate/build/codec/cosmos/tx/v1beta1/tx";
import {MsgExecuteContract} from "cosmjs-types/cosmwasm/wasm/v1beta1/tx";
import {toAscii, fromHex, toHex} from "@cosmjs/encoding";

const WALLY_PREFIX = "wally";
const TENDERMINT_NODE_RPC_URL = "http://3.236.143.194:26657";
const REGISTER_URL = " http://3.220.210.43:8081/api/v3/trm/contracts/wally/registerAccount";
const WALLY_CONTRACT_ADDRESS = "wally18vd8fpwxzck93qlwghaj6arh4p7c5n8986lqhq";
const SERVICE_WALLET = "wally1efx0r5pysr2xvveeung22ezwsxj6mcltk50jtu";
const USDW_CONTRACT_ADDRESS = "wally10pyejy66429refv3g35g2t7am0was7yar2ln3p";

/**
 * @Description This is component for sign transaction by wallet
 * @param {string} wallet Decrypt wallet object (with mneminic phrase)
 * @param {string} from Wallet address for seller
 * @param {id} id of selling license or undefined if sent USDW
 * @param {amount} how licenses/USDW will sent
 * @returns {Promise<sighInTendermint>} - sign string
 */

const sighInTendermint = async (wallet, from, id, amount) => {
    const client = await SigningCosmWasmClient.connectWithSigner(
        TENDERMINT_NODE_RPC_URL,
        wallet,
        {
            broadcastTimeoutMs: 175000,
            gasPrice: GasPrice.fromString("0wally"),
            prefix: WALLY_PREFIX,
        }
    );
    const [firstAccount] = await wallet.getAccounts();

    await axios.post(REGISTER_URL, {account: from})

    let signature;
    if (id) {
        signature = await client.sign(
            firstAccount.address,
            [
                {
                    typeUrl: "/cosmwasm.wasm.v1beta1.MsgExecuteContract",
                    value: MsgExecuteContract.fromPartial({
                        sender: from,
                        contract: WALLY_CONTRACT_ADDRESS,
                        msg: toAscii(
                            JSON.stringify({
                                send_from: {
                                    from,
                                    to: SERVICE_WALLET,
                                    token_id: id,
                                    value: amount.toString(),
                    },
                            })
                        ),
                        funds: [],
                    }),
                },
            ],
            { amount: coins(0, "wally"), gas: "890000" },
            ""
        );
    } else {
        signature = await client.sign(
            firstAccount.address,
            [
                {
                    typeUrl: "/cosmwasm.wasm.v1beta1.MsgExecuteContract",
                    value: MsgExecuteContract.fromPartial({
                        sender: from,
                        contract: USDW_CONTRACT_ADDRESS,
                        msg: toAscii(
                            JSON.stringify({
                                transfer: {
                                    recipient: SERVICE_WALLET,
                                    amount: amount.toString(),
                                },
                            })
                        ),
                        funds: [],
                    }),
                },
            ],
            { amount: coins(0, "wally"), gas: "890000" },
            ""
        );
    }

    return toHex(TxRaw.encode(signature).finish());
}


export default sighInTendermint;
