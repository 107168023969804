import React from 'react';

const ProgressBlock = ({percentOfBid, selectedOption}) => {
  const worthOfVote = selectedOption === 'yes' ? percentOfBid : 0;
  const percentForYes = worthOfVote;
  const percentForNotYet = 100 - worthOfVote;

  return (
    <div className="progressBlockOfPoll">
      <div className="progressBlockOfPoll__barContainer progressBlockOfPoll__barContainer--positive">
        <span>Yes: {percentForYes}%</span>

        <div className="progressBlockBar"
             style={{
               width: `${percentForYes}%`,
               minWidth: '1px'
             }}>
          <span>Yes: {percentForYes}%</span>
        </div>
      </div>

      <div className="progressBlockOfPoll__barContainer progressBlockOfPoll__barContainer--negative">
        <span>Not yet: {percentForNotYet}%</span>

        <div className="progressBlockBar"
             style={{
               width: `${percentForNotYet}%`,
               minWidth: '1px'
             }}>
          <span>Not yet: {percentForNotYet}%</span>
        </div>
      </div>
    </div>
  );
};

export default ProgressBlock;