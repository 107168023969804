import React from "react";
import {ModalWindow} from "../../../Components/ModalWindow/ModalWindow";
import LogoMaker from "../../../Components/LogoMaker";

export class AttachmentsWrapper extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      "attachments": [],
      "errStatus": false,
      "errMessage": "",
      isOpenLogoMaker: false,
    }
    this.fileInput3 = React.createRef();
  }

  componentDidMount() {
    if (this.props.chosenData.length > 0) {
      this.setState({
        "errStatus": false,
        "errMessage": "",
        isOpenLogoMaker: false,
        attachments: this.props.chosenData
      })
    } else {
      this.setState({
        "attachments": [],
        "errStatus": false,
        "errMessage": "",
        isOpenLogoMaker: false,
      })
    }
  }


  // e -> files
  handleFile3Change = (files) => {
    let r = this;
    // let maxUploads = this.props.maxUploads;
    let maxSize = this.props.maxSize;
    let maxFileSize = this.props.maxFileSize;
    let maxPDFSize = this.props.maxPDFSize;
    let allowPDF = this.props.allowPDF;
    let _URL = window.URL || window.webkitURL;
    if (files.length > 0) {
      let file = files[0];
      let fileSize = (file.size / 1024 / 1024).toFixed(2);
      if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "application/pdf") {
        if (allowPDF && file.type === 'application/pdf') {
          if (maxPDFSize) {
            //fileSize > maxPDFSize -> Number(fileSize) > Number(maxPDFSize)
            if (Number(fileSize) > Number(maxPDFSize)) {
              r.setState({"errStatus": true, "errMessage": 'The size is too big. (' + fileSize + 'mb)'});


            } else {
              r.setState({"errStatus": false});
              let reader = new FileReader();
              reader.onloadend = function () {
                r.setState({
                  attachments: r.state.attachments.concat({
                    "name": file.name,
                    "type": file.type,
                    "src": reader.result
                  })
                })
              }
              reader.readAsDataURL(file);
              setTimeout(() => {
                r.props.handleChange(r.state.attachments)
              }, 100)
            }
          }
        } else if (file.type === "application/pdf") {
          r.setState({"errStatus": true, "errMessage": 'Invalid File Format. Must be png/jpg'});
        } else {
          let img = new Image();
          img.onload = function () {
            if (maxSize) {
              if (this.width > maxSize || this.height > maxSize) {
                r.setState({
                  "errStatus": true,
                  "errMessage": 'The size is too big. (' + this.width + '/' + this.height + 'px)'
                });
              } else {
                r.setState({"errStatus": false});
                let reader = new FileReader();
                reader.onloadend = function () {
                  r.setState({
                    attachments: r.state.attachments.concat({
                      "type": file.type,
                      "src": reader.result
                    })
                  })
                }
                reader.readAsDataURL(file);
                setTimeout(() => {
                  r.props.handleChange(r.state.attachments)
                }, 100)
              }
            } else if (maxFileSize) {
              // fileSize > maxFileSize ->
              if (Number(fileSize) > Number(maxFileSize)) {
                r.setState({
                  "errStatus": true,
                  "errMessage": 'The size is too big. (' + fileSize + 'mb)'
                });
              } else {
                r.setState({"errStatus": false});
                let reader = new FileReader();
                reader.onloadend = function () {
                  r.setState({
                    attachments: r.state.attachments.concat({
                      "type": file.type,
                      "src": reader.result
                    })
                  })
                }
                reader.readAsDataURL(file);
                setTimeout(() => {
                  r.props.handleChange(r.state.attachments)
                }, 100)
              }
            }
          };
          // if(     localStorage.getItem("logoAttach", this.state.logoAttach)) {
          //                     //     img.src = this.state.logoAttach
          //                     // } else {
          //                     //
          //                     // }

          img.src = _URL.createObjectURL(file);

        }
      } else {
        r.setState({"errStatus": true});
        if (allowPDF) {
          r.setState({"errMessage": 'Invalid File Format. Must be png/jpg/pdf'});
        } else {
          r.setState({"errMessage": 'Invalid File Format. Must be png/jpg'});
        }


      }
    }
  };
  handleAttachRemove = (e, place) => {
    e.preventDefault();
    let attachments = this.state.attachments;
    attachments.splice(place, 1);
    this.setState({attachments})

  };
  /* Roys code start  */
  onDragOver = (event) => {
    event.preventDefault()
  };
  onDrop = (event) => {
    event.preventDefault();
    this.handleFile3Change(event.dataTransfer.files)
  };
  /* Roys code end */


  insertLogo = (logo) => {


    const self = this;
    let img = new Image();
    img.onload = () => {
      self.setState(state => {
        return {
          isOpenLogoMaker: !state.isOpenLogoMaker,
          errStatus: false,
          attachments: [{
            "type": "image/png",
            "src": logo
          }]
        }
      })
    };
    setTimeout(() => {
      self.props.handleChange(self.state.attachments)
    }, 100);
    img.src = logo;
  };


  handleLogoMakerWindow = () => {
    this.setState(state => {
      return {isOpenLogoMaker: !state.isOpenLogoMaker}
    })
  };


  render() {

    const {isOpenLogoMaker, attachments} = this.state;
    const {
      showBtn,
      maxUploads,
      inputName,
      isRequired,
      valueForCheck,
      action
    } = this.props;

    return (
      <div className={"attachment-wrapper " + this.props.extraClasses}>
        {
          this.state.attachments.map((e, i) => {
            if (e.type === "application/pdf") {
              return (
                <div className="attached-box-upload">
                  <div onClick={(e) => this.handleAttachRemove(e, i)}
                       className="delete-attach-box">
                    <svg width="20" height="20" viewBox="0 0 24 24">
                      <path
                        d="M14.8284 12l4.2427 4.2426c.781.781.781 2.0474 0 2.8285-.781.781-2.0474.781-2.8285 0L12 14.8284l-4.2426 4.2427c-.781.781-2.0474.781-2.8285 0-.781-.781-.781-2.0474 0-2.8285L9.1716 12 4.9289 7.7574c-.781-.781-.781-2.0474 0-2.8285.781-.781 2.0474-.781 2.8285 0L12 9.1716l4.2426-4.2427c.781-.781 2.0474-.781 2.8285 0 .781.781.781 2.0474 0 2.8285L14.8284 12z"
                        fillRule="evenodd"/>
                    </svg>
                  </div>
                  <p className="pdf-title">{e.name}</p>
                </div>
              )
            } else {
              return (
                <div className="attached-box-upload" key={inputName + Math.random()}>
                  <div
                    onMouseDown={(e) => this.handleAttachRemove(e, i)}
                    onClick={(e) => this.handleAttachRemove(e, i)} className="delete-attach-box">
                    <svg width="20" height="20" viewBox="0 0 24 24">
                      <path
                        d="M14.8284 12l4.2427 4.2426c.781.781.781 2.0474 0 2.8285-.781.781-2.0474.781-2.8285 0L12 14.8284l-4.2426 4.2427c-.781.781-2.0474.781-2.8285 0-.781-.781-.781-2.0474 0-2.8285L9.1716 12 4.9289 7.7574c-.781-.781-.781-2.0474 0-2.8285.781-.781 2.0474-.781 2.8285 0L12 9.1716l4.2426-4.2427c.781-.781 2.0474-.781 2.8285 0 .781.781.781 2.0474 0 2.8285L14.8284 12z"
                        fillRule="evenodd"/>
                    </svg>
                  </div>
                  <img src={e.src} alt=''/>
                </div>
              )
            }
          })
        }


        {this.state.errStatus ?
          <div ref="uploadContainer"
               className='attachment-box'
               style={{"backgroundColor": "rgba(255, 0, 0,0.05)"}}>
            <div onClick={() => this.setState({"errStatus": false})} className="delete-attach-box">
              <svg width="20" height="20" viewBox="0 0 24 24">
                <path
                  d="M14.8284 12l4.2427 4.2426c.781.781.781 2.0474 0 2.8285-.781.781-2.0474.781-2.8285 0L12 14.8284l-4.2426 4.2427c-.781.781-2.0474.781-2.8285 0-.781-.781-.781-2.0474 0-2.8285L9.1716 12 4.9289 7.7574c-.781-.781-.781-2.0474 0-2.8285.781-.781 2.0474-.781 2.8285 0L12 9.1716l4.2426-4.2427c.781-.781 2.0474-.781 2.8285 0 .781.781.781 2.0474 0 2.8285L14.8284 12z"
                  fillRule="evenodd"/>
              </svg>
            </div>


            <p className="attachment-title" style={{"color": "red"}}>
              {this.state.errMessage}
            </p>


            {/* Roys code start*/}
            <input type="file"
                   onChange={(e) => this.handleFile3Change(e.target.files)}
                   onClick={(e) => e.target.value = null}
                   hidden
                   ref={this.fileInput3}
                   name={inputName}
                   data-valueforcheck={valueForCheck}
                   data-action={action}
                   data-isrequiredfield={isRequired}/>
            {/* Roys code end*/}
          </div>
          :
          +maxUploads !== attachments.length ?
            <div ref="uploadContainer"
                 onClick={() => this.fileInput3.current.click()}
                 className='attachment-box'
              /* Roys code start*/
                 onDragOver={this.onDragOver}
                 onDrop={this.onDrop}>
              {/* Roys code end*/}
              <p className="attachment-title">
                <img src={require('../../../../assets/images/upload-button.png')} alt=''/>
                Click to add a png/jpg{this.props.allowPDF ? "/pdf" : ""}
              </p>
              {
                this.props.maxSize ?
                  <p className="attach-max-size">{this.props.maxSize} by {this.props.maxSize} (px)</p>
                  : this.props.maxPDFSize ?
                  <p className="attach-max-size">{this.props.maxPDFSize} (mb)</p>
                  : this.props.maxFileSize ?
                    <p className="attach-max-size">{this.props.maxFileSize} (mb)</p>
                    : ""
              }
              {/* Roys code start*/}
              <input type="file"
                     onChange={(e) => this.handleFile3Change(e.target.files)}
                     onClick={(e) => e.target.value = null}
                     hidden
                     ref={this.fileInput3}
                     name={inputName}
                     data-valueforcheck={valueForCheck}
                     data-action={action}
                     data-isrequiredfield={isRequired}/>
              {/* Roys code end*/}
            </div>
            : ""
        }
        <div className="btn-wrapper">
          <div/>
          {
            showBtn &&
            <button className="openLogoMaker-btn" onClick={this.handleLogoMakerWindow}>Create logo</button>
          }
          {
            showBtn && isOpenLogoMaker &&
            <ModalWindow title={"The Logo Maker"} hideModal={this.handleLogoMakerWindow}>
              <LogoMaker insertLogo={this.insertLogo}/>
            </ModalWindow>
          }
        </div>
      </div>
    )
  }
}
