import React from 'react';
import {Link} from "react-router-dom";


const FanancersCard = (props) => {
    //image is its name in the "site" folder
    const {title, titleForCategory, description, imageName, number, categoriesForInvestors} = props;
    const image = require(`../../../assets/images/${imageName || categoriesForInvestors[number].image}`);
    const stylesForImgInDiv = {
        backgroundImage: `url(${image})`,
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover"
    };

    return (
        <div className="column">
            <div className="card1">
                <Link to={categoriesForInvestors[number].link}>
                    <div className="image-in-card1" style={stylesForImgInDiv}/>
                </Link>
                <br/>
                <small style={{color: "black"}}>
                    <b>{title || categoriesForInvestors[number].title}</b>
                </small>
                <p style={{color: "purple", fontSize: "15px"}}>
                    {titleForCategory || categoriesForInvestors[number].titleForCategory}
                </p>
                <hr/>
                <small className="text-muted role">
                    {
                        description || categoriesForInvestors[number].description
                    }
                </small>
                <br/>
                <br/>
            </div>
        </div>
    )
}

export default FanancersCard
