import React, {Fragment, useEffect, useRef, useState} from "react";

import {connect} from "react-redux";
import * as blockchainActions from "../../../modules/blockchain/blockchain.action";
import {Link} from 'react-router-dom';
import constants from "../../api/constant";
import './projectLotPage.css'
import {sendParseRequest} from "../../../utils/sendParseRequest";
import {sendAxiosRequest} from "../../../utils/sendAxiosRequest";
import LotAuctionCard from './lotAuctionCard'

const auth = constants.auth;

const ProjectLotPage = (props) => {
  const [openedLicense, setOpenedLicense] = useState(false);
  const [openedCount, setOpenedCount] = useState(0);
  const [itemIdx, setItemIdx] = useState(0);
  const [gettedLots, setGettedLots] = useState(null);
  const [showModal, setShowModal] = useState(null);
  const [showList, setShowList] = useState(null);
  const [showTransfer, setShowTransfer] = useState(null);


  useEffect(() => {

    let gettedProject;
    if (window.location.hash.split('projectLot')[1]) {
      gettedProject = window.location.hash.split('projectLot/')[1]
    } else {
      gettedProject = null;
    }

    console.log(gettedProject, "--------------gettedProject")
    sendAxiosRequest('getLots', {projectId: gettedProject})
      .then(res => {
        setGettedLots(res.result)
      })

  }, []);


  const showUpdateRow = () => {
    setOpenedCount(0)
  };


  return (
    <div className="page wallets3Page">
      {showModal && <div className="areyousureModalBgCover"
                         onClick={() => {
                           setShowModal(false);
                           setShowList(false);
                           setShowTransfer(false)
                         }}
      />}
      {showList &&
      <>
        <div className="areyousureModal" style={{width: '70%', height: '70%'}}>
          <div style={{position: 'relative', height: '100%'}}>
            <div style={{maxHeight: '400px', overflow: 'auto'}}>
              <table style={{width: '100%'}} className='lot-list-table'>
                <tr style={{width: '90%'}} className="lot-list-tr">
                  <td>Logo</td>
                  <td>Project</td>
                  <td>Ticker Symbol</td>
                  <td>License Id</td>

                </tr>
                {openedLicense && openedLicense.licenses.map((item, idx) =>
                  <tr key={idx} style={{width: '90%'}} className="lot-list-tr">
                    <td className='lot-list-logo ' style={{backgroundImage: `url("${item.logo}")`}}/>
                    <td><Link to={`/collectormarket/${item.projectId}`} target="_blank">{item.projectTitle}</Link></td>
                    <td>{item.tickerSymbol}</td>
                    <td>{item.idOfLicense}</td>

                  </tr>
                )}


              </table>
            </div>
            <div className="card__details-btns_block" style={{position: 'absolute', bottom: '20px', width: '100%'}}>
              <button
                style={{width: '200px'}}
                className="btn btn_link"
                onClick={() => {

                  setShowModal(false);
                  setShowList(false);
                  setOpenedLicense(null)
                }}
              >
                BACK
              </button>
              <button
                onClick={() => {
                  setShowList(false)
                  setShowTransfer(true);

                }}
                style={{width: '200px'}}
                className="btn btn_link"
                // onClick={() => sell721sToken(collectible)}
              >
                SET A BID FOR A LOT
              </button>
            </div>
          </div>
        </div>
      </>
      }


      {showTransfer &&
      <>
        <div className="areyousureModal"
             style={{width: '80%', height: '90%', display: 'flex', justifyContent: 'center', overflow: 'auto'}}>

          <LotAuctionCard activeAuctions={openedLicense} projectID={openedLicense.licenses[0].projectId}
                          setShowTransfer={setShowTransfer}
                          setShowModal={setShowModal}
          />
        </div>
      </>
      }


      <div className="dashModal create-wallet__wrapper" style={{margin: '10px 0'}}>
        <p className="section-title__projects-page" style={{margin: '20px 0 0 60px'}}>Available lots</p>
        {gettedLots && <div className="artboard horisontal-artbook">
          {gettedLots &&
          gettedLots.map((collectible, idx) => (
            <div className="card horisontal-card" key={idx} onMouseEnter={() => showUpdateRow()}
              // onMouseLeave={()=>hideUpdateRow()
              // }
            >
              <div className="card__side card__side--back" style={{position: 'relative'}}>
                {openedCount >= 1 &&
                <p style={{position: 'absolute', left: '20px', top: '15px', cursor: 'pointer'}} onClick={() => {
                  setOpenedCount(openedCount - 1);
                  setItemIdx(idx)

                }}>{'< previous item'}</p>}


                <div className="card__cover">
                  <img src={itemIdx === idx ? collectible.licenses[openedCount].logo : collectible.licenses[0].logo}/>
                </div>
                {openedCount <= (collectible.licenses.length - 2) &&
                <p style={{position: 'absolute', right: '20px', top: '15px', cursor: 'pointer'}}
                   onClick={() => {
                     setOpenedCount(openedCount + 1);
                     setItemIdx(idx)


                   }}>next item ></p>}
                <div className="card__details colletibls-block">
                  <ul>
                    <li>
                      <h4>{itemIdx === idx ? collectible.licenses[openedCount].projectTitle : collectible.licenses[0].name}</h4>
                    </li>
                    {/*<li>{collectible.title}</li>*/}
                    <li>TickerSymbol: {itemIdx === idx ? collectible.licenses[openedCount].tickerSymbol : collectible.licenses[0].tickerSymbol}</li>
                    <li>License Id:
                      #{itemIdx === idx ? collectible.licenses[openedCount].idOfLicense : collectible.licenses[0].idOfLicense}</li>
                    <li>{collectible.licenses[openedCount] && collectible.licenses[openedCount].description}</li>
                  </ul>
                  <div className="flex-column-lot">
                    <button
                      className="btn btn_link" style={{margin: '0 10px 10px 10px'}}
                      onClick={() => {
                        setShowModal(true);
                        setOpenedLicense(collectible);
                        setShowList(true)
                      }}

                    >
                      SHOW ALL LICENSES AS A LIST
                    </button>
                    <div className="card__details-btns_block">
                      <Link to={`/collectormarket/${collectible.licenses[openedCount].projectId}`} target="_blank">
                        <button
                          className="btn btn_link"
                        >
                          VIEW PROJECT
                        </button>
                      </Link>
                      <button
                        className="btn btn_link"
                        onClick={() => {
                          setShowTransfer(true);
                          setShowModal(true);
                          setOpenedLicense(collectible);
                        }}
                      >
                        SET A BID FOR A LOT
                      </button>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className="card__side card__side--front"
                style={{
                  backgroundImage: `linear-gradient(to top right, rgba(1, 90, 208, 1), rgba(57, 165, 251, 0.25)), url(${collectible.licenses[0].banners[0]})`,
                }}
              >
                <div className="card__theme">
                  <div className="card__theme-box">
                    <p className="card__subject">{collectible.name}</p>
                    <p className="card__title">LOT</p>
                    <p className="">Include {collectible.licenses.length} licenses</p>

                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        }
      </div>
    </div>
  );
};


function mapStateToProps({blockchain}) {
  return {
    mainWalletAddress: blockchain.mainWalletAddress
  };
}

export default connect(
  mapStateToProps,
  {...blockchainActions}
)(ProjectLotPage);
