import React, {useEffect, useState} from 'react';

const ShowProjectMediaFile = ({onClose, tab, itemsData}) => {
  console.log(itemsData, '<<<<<<<----=======ITEMSDATA=======------>>>>>>>>')

  
  const [item, setItem] = useState('');

  useEffect(() => {
    if (itemsData.length === 0) return;
    getHtmlElementForFile(itemsData[0].multiHash, itemsData[0].type)
  }, []);

  const onCloseComponent = (evt) => {
    const currEl = evt.target;

    if (currEl.classList.contains('showProjectMediaFile') || currEl.classList.contains('showProjectMediaFile__closeBtn')) {
      onClose();
    }
  };

  const getHtmlElementForFile = (linkToFile, fileType) => {
    const addressToFile = `https://ipfs.io/ipfs/${linkToFile}`;

    switch (fileType) {
      case 'audio/wav' || 'audio/mp3' || 'audio/mpeg':
        setItem(
          <audio controls>
            <source src={addressToFile} type={fileType}/>
            Your browser does not support the HTML5 audio.
          </audio>
        );
        break;
      case 'video/mp4':
        setItem(
          <div className='contentItem__wrapper'>
            <video controls autoPlay>
              <source src={addressToFile} type='video/mp4'/>
              Your browser does not support the HTML5 video.
            </video>
          </div>);
        break;
      case 'image/jpeg' || 'image/png':
        setItem(
          <div className='contentItem__wrapper'>
            <img src={addressToFile} alt='Auction item'/>
          </div>
        );
        break;
      default:
        setItem(<embed src={addressToFile} type='application/pdf'/>);
    }
  };

  const onContextMenuCall = (evt) => evt.preventDefault()
  return (
    <div className="showProjectMediaFile" onClick={onCloseComponent}>
      <div className="mediaFileWrapper" onContextMenu={onContextMenuCall}>
        <ul className='auctionItem__list'>
          {
            itemsData.length > 0
              ? itemsData.map(item => {
                return <li key={item.name}
                           onClick={() => getHtmlElementForFile(item.multiHash, item.type)}>
                  {item.name}
                </li>
              })
              : <p>no data</p>
          }
        </ul>
        {item}
      </div>
      <button className="showProjectMediaFile__closeBtn"/>
    </div>
  );
};

export default ShowProjectMediaFile;
