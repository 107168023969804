import React from 'react';
import NavButtonM from "../../../../assets/images/navButtonW.png";
import WhiteLogoImage from "../../../../assets/images/F_logo_in_White_C.jpg";
import prepareImagesPathForMinify from "../../../../utils/prepareImagesPathForMinify";
import F_in_White from "../../../../assets/images/F_in_White.png";
import {Link, NavLink} from "react-router-dom";
import {exchangeSVG, referralsSVG, userProfileSVG, walletSVG} from "../../../../assets/images/svg/headerSvg";
import "./MobileHeader.css";

const MobileHeader = ({
                          currentUser,
                          showMobileMenu,
                          setShowMobileMenu,
                          setActivePage,
                          history
                      }) => {
    return (
        <div className='mobile-header__wrapper'>
            <div className="mobile-header">
                <img src={NavButtonM}
                     alt='mobileMenuIcon'
                     className="mobile-header__menu-icon"
                     onClick={() => setShowMobileMenu(true)}
                     style={{display: currentUser ? 'block' : 'none'}}
                />
                <img src={WhiteLogoImage}
                     className='header_top__logo' alt='logo'
                     onClick={() => {
                         setActivePage('');
                         history.push('/')
                     }}
                />
                {currentUser
                    ? <div className="header_top__menu__user-avatar"
                           style={{
                               backgroundImage: currentUser.attributes.avatarLink
                                   ? "url('" + prepareImagesPathForMinify(currentUser.attributes.avatarLink, 50, 50) + "')"
                                   : "url('" + prepareImagesPathForMinify(F_in_White, 50, 50) + "')"
                           }}
                           onClick={() => {
                               setActivePage('');
                               history.push('/profile')
                           }}
                    />
                    : <div className='header_top__login'>
                        {/* <Link to="/login" className='header_top__login__item'>LOG IN</Link>
                        <Link to="/register" className="header_top__login_btn">REGISTER</Link> */}
                    </div>}
            </div>
            <div className='mobile-header__menu'
                 style={{display: showMobileMenu ? 'flex' : 'none'}}
                 onClick={() => setShowMobileMenu(false)}
            >
                <NavLink onClick={() => setActivePage('')} to="/profile" exact className='mobile-header__menu__item'>
                    <span>{userProfileSVG}</span> Profile
                </NavLink>
                <NavLink onClick={() => setActivePage('')} to="/wallets2" className='mobile-header__menu__item'>
                    <span>{exchangeSVG}</span> Buy Coins
                </NavLink>
                <NavLink onClick={() => setActivePage('')} to="/wallet" className='mobile-header__menu__item'>
                    <span>{walletSVG}</span> My Wallets
                </NavLink>
                <NavLink onClick={() => setActivePage('')} to="/referrals" className='mobile-header__menu__item'>
                    <span>{referralsSVG}</span> Referrals
                </NavLink>
            </div>
        </div>
    );
};

export default MobileHeader;