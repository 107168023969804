'use strict'

const axios = require('axios')
const configs = require('./configs')
const {
  IsObjectExists,
  ValidationArray,
  valueDivPow10__18,
  valueMulPow10__18,
  IsERC1155TicketId,
} = require('./helpers')
const { ethers } = require('ethers')

export  const ticketsFactory = (_networkEndPoint, _contractAddress) => {
  const provider = new ethers.providers.JsonRpcProvider(_networkEndPoint)
  let FanbaseContract = new ethers.Contract(
    _contractAddress,
    configs.CONTRACT_ABI.FANBASE_ABI,
    provider,
  )

  return {
    /**
     * @Description Get balance of user ticket(token)
     * @param {string} WalletAddress User wallet address
     * @param {string} Id Identifier of ERC-1155 token
     * @returns {string}
     */
    getBalance: async (WalletAddress, Id) => {
      const balance = await FanbaseContract.balanceOf(WalletAddress, Id)

      return IsObjectExists(balance) ? balance.toString() : (0).toString()
      // return IsObjectExists(balance)
      //   ? valueDivPow10__18(balance.toString()).toString()
      //   : "";
    },

    /**
     * Return structure of Token metadata
     * @typedef {Object} TokenMetadata
     * @property {string} name Name of token
     * @property {number} decimals Number of decimal places
     * @property {string} description Full description of token
     * @property {string} imageUri URL for image of token
     * @property {Object} properties JSON with format -> { symbol: "" }
     * @property {string} symbol Text symbol of token
     */
    /**
     * @Description Get metadata of ticket
     * @param {string} id Identifier of ERC-1155 token
     * @returns {TokenMetadata}
     */
    getTokenInfo: async id => {
      let getProperties = { symbol: '' }
      const defaultRetObj = {
        name: '',
        decimals: 0,
        description: '',
        imageUri: '',
        properties: getProperties,
        symbol: '',
      }

      const filter = FanbaseContract.filters.Metadata(id)
      const [data] = await FanbaseContract.queryFilter(filter)

      if (IsObjectExists(data)) {
        if (IsObjectExists(data.args)) {
          //JSON.parse(data.args.properties)
          try {
            getProperties = JSON.parse(data.args.properties)
          } catch (e) {
            console.debug(
              'Error with parsing [data.args.properties] -> ',
              e.message,
            )
          }
          //ret value
          return {
            ...data.args,
            properties: getProperties,
            symbol: getProperties.symbol,
          }
        } else {
          return defaultRetObj
        }
      } else {
        return defaultRetObj
      }
    },

    /**
     * Return structure of mined transaction
     * @typedef {Object} TransactionResult
     * @property {string} from Address from
     * @property {string} to Receiver address
     * @property {string} gasUsed blockchain sysinfo(never mind)
     * @property {number} blockNumber Number of current block
     * @property {string} blockHash Hash-Header of current block
     * @property {string} transactionHash Transaction hash
     * @property {string} contractAddress Contract address
     * @property {Array} logs Transaction logs
     */
    /**
     * Send value of token ERC-1155 from current account to receiver
     * @param {string} privateKey Wallet private key from Mnemonic
     * @param {string} addressFrom You selected account address
     * @param {string} toAddress Address Receiver of token
     * @param {string} tokenId Selected ERC-1155 token (uint256)
     * @param {string} value Amount of ERC-1155 token to be sent (uint256)
     * @param {number} gasPrice Selected gas price (Gwei)
     * @returns {Promise<TransactionResult>} Transaction object with fields (from, to, gasUsed, blockNumber, blockHash, transactionHash, contractAddress, logs)
     */
    safeTransferFrom: async (
      privateKey,
      addressFrom,
      toAddress,
      tokenId,
      value,
      gasPrice,
    ) => {
      /*Validation section */
      if (!IsObjectExists(addressFrom)) {
        throw new Error(`User wallet address is invalid!`)
      }
      if (!IsObjectExists(toAddress)) {
        throw new Error(`User-Receiver address is invalid!`)
      }
      if (!IsERC1155TicketId(tokenId)) {
        throw new Error(
          `This token id (${tokenId}) don't meet the standard ERC-1155!`,
        )
      }
      const _data = '0x00'
      const wallet = new ethers.Wallet(privateKey, provider)
      FanbaseContract = FanbaseContract.connect(wallet)

      const calcGasPrice = ethers.utils.parseUnits(`${gasPrice}`, 'gwei');
    //   try {
        const tx = await FanbaseContract.safeTransferFrom(
          addressFrom,
          toAddress,
          tokenId,
          value,
          _data,
          {
            gasPrice: calcGasPrice.toHexString(),
          },
        )
        console.debug('not mined tx: ', tx)
        const _txResult = await tx.wait()
        if (IsObjectExists(_txResult)) {
          return {
            from: _txResult.from,
            to: _txResult.to,
            gasUsed: IsObjectExists(_txResult.gasUsed)
              ? _txResult.gasUsed.toString()
              : '',
            blockNumber: _txResult.blockNumber,
            blockHash: _txResult.blockHash,
            transactionHash: _txResult.transactionHash,
            contractAddress: IsObjectExists(_txResult.contractAddress)
              ? _txResult.contractAddress.toString()
              : '',
            logs: ValidationArray(_txResult.logs) == true ? _txResult.logs : [],
          }
        } else return {}
    //   } catch (e) {
    //     console.error(`${e.message}`)
    //   }
    },
  }
}
