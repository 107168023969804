import React, {useEffect, useRef, useState} from 'react';
import {TagInput} from "../TagInput/TagInput";
import {rolesArray} from "../dataForSelects";
import isEmail from "../../../../utils/isEmail";
import InfoTooltip from "../../../Components/InfoTooltip";
import {AttachmentsWrapper} from "../AttachmentsWrapper/AttachmentsWrapper";

const AddTeamMember = ({membersList, handleChange, type}) => {
    console.log(membersList, handleChange, '---membersList, handleChange')
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [role, setRole] = useState([]);
    const [email, setEmail] = useState('');
    const [allowCollab, setAllowCollab] = useState(true);
    const [allowMask, setAllowMask] = useState(false);

    const errorCollabMessage = useRef();
    const fnameInput = useRef();

    useEffect(() => {
        fnameInput.current.focus()
    }, []);


    const addToList = () => {
        let newMembersList = [...membersList];

        let filtered = newMembersList.filter((e) => {
            return e.email.toLowerCase() === email.toLowerCase();
        })

        if (filtered.length === 0) {
            newMembersList.push({
                fname: fname,
                lname: lname,
                role: role,
                email: email,
            })
            handleChange(newMembersList);
        } else {
            errorCollabMessage.current.style.color = "#f03f59";
            errorCollabMessage.current.innerHTML = 'There is already a collaborator with that email.';
        }
    }

    return (
        <div className="collaborator-wrap ">
            <div onClick={() => handleChange(membersList)} className="delete-attach-box">
                <svg width="20" height="20" viewBox="0 0 24 24">
                    <path
                        d="M14.8284 12l4.2427 4.2426c.781.781.781 2.0474 0 2.8285-.781.781-2.0474.781-2.8285 0L12 14.8284l-4.2426 4.2427c-.781.781-2.0474.781-2.8285 0-.781-.781-.781-2.0474 0-2.8285L9.1716 12 4.9289 7.7574c-.781-.781-.781-2.0474 0-2.8285.781-.781 2.0474-.781 2.8285 0L12 9.1716l4.2426-4.2427c.781-.781 2.0474-.781 2.8285 0 .781.781.781 2.0474 0 2.8285L14.8284 12z"
                        fillRule="evenodd"/>
                </svg>
            </div>
            <div className="section-input-wrap">
                <p ref={errorCollabMessage} className="s-input-title">
                    Invite a new User
                </p>
                <div className="section-input-wrap two">
                    <p className="s-input-title">
                        Name <span className="required-star">Required *</span>
                    </p>
                    <input ref={fnameInput} value={fname}
                           onChange={e => setFname(e.target.value)}
                           type="text"
                           placeholder="First Name"
                    />
                    <input value={lname}
                           onChange={e => setLname(e.target.value)}
                           type="text"
                           placeholder="Last Name"
                    />
                </div>
                <div className="section-input-wrap">
                    <p className="s-input-title">
                        Email <span className="required-star">Required *</span>
                    </p>
                    <input value={email}
                           onChange={e => setEmail(e.target.value)}
                           type="text"
                           placeholder="Email Address"
                    />
                </div>
                <div className="section-input-wrap">
                    <p className="s-input-title">
                        Up to 6 roles <span className="required-star">Required *</span>
                    </p>
                    <TagInput chosenData={role}
                              maxSize="6"
                              onChange={e => setRole(e)}
                              data={rolesArray}
                    />
                </div>

                {type === "collaborator" && <div className="section-input-wrap">
                    <p className="s-input-title">
                        Select options
                    </p>
                    <div style={{display: 'flex', flexDirection: 'column'}} onChange={(e)=>
                    console.log(e.target.value)}>
                        <label style={{display: 'flex', alignItems: "center"}}>
                            <input type="radio"
                                   value="allow"
                                   name="showContract"
                                 style={{width: '5%'}}/>
                            <span>Allow members to read the contract.
                        </span>
                        </label>
                        <label style={{display: 'flex', alignItems: "center"}}>
                            <input type="radio"
                                   value="mask"
                                   name="showContract"

                                   style={{width: '5%'}}/>
                            <span> Mask the contract from
                            visibility</span> </label>

                    </div>
                    <div className="section-input-wrap">
                        <div className="s-input-title">
                            Promotional Images:
                            <InfoTooltip position="top"
                                         message="The promotional images will be displayed at the top of your project page. You must upload five promotional images. You can edit and reorganize the images at any time.">
                                <i className="fa fa-info-circle" />
                            </InfoTooltip>
                            <span
                                className="character-count">Up to 5</span>

                        </div>
                        {

                            <AttachmentsWrapper isRequired={true}
                                                inputName="promotionalBanners"
                                                action="less"
                                                valueForCheck={5}

                                                maxUploads={5}
                                                maxFileSize="5"
                                                extraClasses="promotional-banners-attach"
                                                // handleChange={(e) => this.setState({ "promotionalBanners": e })}
                            />
                        }
                    </div>
                </div>
                }

                {fname.replace(/ /g, '') !== "" && role.length !== 0 && isEmail(email) !== false
                    ? <a onClick={addToList} className="add-social-platform pull-right">
                        Add {type}
                    </a>
                    : <a className="add-social-platform pull-right">Fill in the fields</a>
                }
            </div>
        </div>
    );
};

export default AddTeamMember;