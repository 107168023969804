import React, {useState} from 'react';
// import useIpfs from '../../../hooks/useIpfs';

import uploadIcon from '../../../assets/images/cloud-file-upload.svg';

const UploadMessageToIpfs = ({ setItemsToIpfs, onLoadFile, uploaded, formats }) => {
    const [sizeWarning, setSizeWarning] = useState(false);
    const [fileWarning, setFileWarning] = useState(false);

    const {ipfs, ipfsInitError} = "useIpfs({commands: ['id']})";
    // const {ipfs, ipfsInitError} = useIpfs({commands: ['id']});

    const addToIpfs = async (file) => {
        for await (const inputFile of ipfs.add({ path: 'randompath.txt', content: file })) {

            console.log(inputFile.cid.toString(), 'inputFile.cid.toString()---')
            return inputFile.cid.toString()

        }
    };

    const onFileDownload = (evt) => {
        const currEl = evt.target;
        const file = currEl.files[0];
        const type = file.type.split('/')
        
        if (file.size > 2147483648) {
            setSizeWarning(true);
            return;
        } else {
            setSizeWarning(false);
        }

        if (!formats.includes(type[1])) {
            setFileWarning(true)
        } else {
            setFileWarning(false)
        }

        if (file) {
            setItemsToIpfs({
                file,
                addToIpfs,

            });
        }
    };

    return (
        <div className="section-input-wrap" style={{"paddingLeft": "30px"}}>
            <p className="s-input-title">

                     'PDF for investment group'

                <span className="required-star">Required *</span>
                <i className="character-count" style={{'fontStyle': 'normal'}}>UP TO 6</i>
            </p>

            {onLoadFile && <p className='download-message'>Download may take some time</p>}
            <div
                className= 'uploadItems-wrapper uploadInvestItems'>

                         <label>
                             {!uploaded && <input type={onLoadFile ? '' : 'file'}
                                   hidden={true}
                                   onChange={onFileDownload}
                                   name="auctionItem"
                                   data-isrequiredfield="true"
                                   data-action="compare"
                                   data-valueforcheck=""
                            />}
                            {onLoadFile
                                ? <a className="loadingButton loading-creators__wrapper"><span
                                    className='spinner loading-creators__spinner'/></a>
                                : !uploaded &&<img src={uploadIcon} alt="icon"/>
                            }
                             {uploaded && <p>Upload successfully completed</p>}

                        </label>
            </div>
            {
                fileWarning &&
                <p className='warning-message'>Invalid File Format. Must be {formats.map(format => '/' + format + ' ')}</p>
            }
            {sizeWarning && <p className='warning-message'>Maximum file size - 2gb</p>}

        </div>
    );
};

export default UploadMessageToIpfs;
