import React from "react";

const NavButtons = ({orderFormType, setOrderFormType}) => {
  const cssClasses = {
    buyButton: 'myTicketsOrderType__btn',
    sellButton: 'myTicketsOrderType__btn',
    parentBlock: 'myTicketsOrderType'
  };

  if (orderFormType === 'sell') {
    cssClasses.parentBlock += ' myTicketsOrderType--sellActive';
    cssClasses.sellButton += ' myTicketsOrderType__btn--active';
  } else {
    cssClasses.buyButton += ' myTicketsOrderType__btn--active';
  }

  return (
    <div className={cssClasses.parentBlock}>
      <button className={cssClasses.buyButton}
              onClick={() => setOrderFormType('buy')}>
        Buy
      </button>

      <button className={cssClasses.sellButton}
              onClick={() => setOrderFormType('sell')}>
        Sell
      </button>
    </div>
  );
};


export default NavButtons;