import React, {Fragment, useEffect, useRef, useState} from "react";
import defaultLogo from "../../../../assets/images/F_in_White.png";
import Calendar from "../../Calendar";
import {Loader} from "../../Loader";
import PropTypes from 'prop-types';
import timezoneData from '../../../Pages/Settings/Preferences/timezone.json';
import {connect} from "react-redux";
import {randomBytes, createHash} from 'crypto';
import * as blockchainActions from "../../../../modules/blockchain/blockchain.action";
import {amountInTIX, convertUinxTimeStampToDate} from "../../../eth_client/helper";
import {sendParseRequest} from "../../../../utils/sendParseRequest";
import {sendAxiosRequest} from "../../../../utils/sendAxiosRequest";
import axios from "axios";
import constants from "../../../api/constant";
import ethClient from "../../../eth_client";
import sighInTendermint from "../../../api/tendermint/signIn";
import encryptBack from '../../../helpers/encryptBack'
import {walletService} from "../../../api/tendermint/main";
import closeSvg from "../../../../assets/images/close--blue.svg";
import {getWallyWalletObject, sentUSDWToWallet, callBurnTokensUSDW} from '../../../../utils/temndermintWalletsUtils'
const auth = constants.auth;

const ScheduleAnAuction = (props) => {

    const {type} = props;
    const [state, setState] = useState({
        showCalendar: false,
        isValidatedDate: false,
        isValidatedPrice: false,
        isValidatedStartPrice: false,
        date: null,
        isLoading: false
    });
    const [lot, setLot] = useState(null);
    const [password, setPassword] = useState(null);
    const [checkPassword, setCheckedPassword] = useState(false);
    const [statusPopup, setStatusPopup] = useState(false);

    useEffect(() => {
            if (props.lots) {
                setLot(props.lots);
            }
        },
        [props]);


    const privateKey = props.privKey;
    // console.log(props, "--------------props")
    let modalRef = useRef();
    let endDateRef = useRef();
    let endTimeRef = useRef();
    let passwordRef = useRef();
    let timezoneOfAuctionRef = useRef();
    let startPriceRef = useRef();
    let priceRef = useRef();

    const hideModal = () => {
        props.handleModal();
    };
    //
    const handleDate = () => {

        const {value} = endDateRef.current.value
        const isValidatedDate = validateDate(endDateRef.current.value);
        let date = null;
        if (isValidatedDate) {
            date = new Date(value)
        }
        setState({
            ...state,
            duration: value,
            isValidatedDate,
            date
        });
    };
    //
    const handlePrice = () => {
        setState({
            ...state,
            isValidatedStartPrice: validatePrice(startPriceRef.current),
            isValidatedPrice: validatePrice(priceRef.current),
        });
    };


    //
    const setDate = (date) => {
        endDateRef.current.value = parseDateToValue(date);
        setState({
            ...state,
            duration: endDateRef.current.value,
            isValidatedDate: validateDate(endDateRef.current),
            date
        })
    };


    const handleCalendar = () => {
        setState({...state, showCalendar: !state.showCalendar});
    };

    const startAuction = async () => {

        const {license, token, logo, type} = props;
        const timeOfAuction = endTimeRef.current.value.length === 5 ? endTimeRef.current.value : '00:00';

        let year = state.date.getFullYear();
        let month = state.date.getMonth();
        let day = state.date.getDate();
        let hour = Number(timeOfAuction.split(':')[0]);
        let minutes = Number(timeOfAuction.split(':')[1]);
        let auctionDate = new Date(year, month, day, hour, minutes, 0, 0);
        const dateTill = endDateRef.current.value;
        const priceBuyNow = priceRef.current.value;
        const priceStart = startPriceRef.current.value;

        console.log(timezoneOfAuctionRef)
        // const idxOfActiveTimezone = timezoneOfAuctionRef.current.selectedIndex;
        // const selectedTimezone = timezoneOfAuctionRef.current.options[idxOfActiveTimezone].value;
        let ownerWaller = ethClient.eth.getActiveAccountAddress();
        let licenseItem;
        if (type === 'single') {
            licenseItem = license;
        } else {
            let licenseArray = []
            lot && lot.forEach((item) => {
                licenseArray.push(item)
            })
            if (licenseArray.length > 0) {
                licenseItem = licenseArray;
            }
        }


        let auctionDateUnix = ((auctionDate.getTime()) / 1000).toFixed(3);
        let approvedData = +auctionDateUnix + 259200;
        let walletAddress = ethClient.eth.getActiveAccountAddress();


        if (type === 'lot') {

            let generateId = async () => {
                const chars =
                    'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz' + '0123456789';
                let objectId = '';

                const bytes = randomBytes(15);
                for (let i = 0; i < bytes.length; ++i) {
                    objectId += chars[bytes.readUInt8(i) % chars.length];
                }
                return objectId;
            };

            let generatedId = await generateId();
            let preparedLots = [];

            let {removeFromCart} = props;

            let count = 0;
            console.log(props.lots, '------propsLOTS');

            let walletsObj = await ethClient.eth.getActivePrivateKey()
            if (walletsObj[0]) {
                let walletInLocalStorage = JSON.parse(walletsObj[0].serJsonString)

                // let importedObj = await walletService.decryptSerializedJsonStr(walletInLocalStorage, '80081866fgQ')
                let importedObj = await walletService.decryptSerializedJsonStr(walletInLocalStorage, passwordRef.current.value)
                props.lots && props.lots.forEach(async (lot) => {
                    let signed = await sighInTendermint(importedObj.walletObject, walletAddress, `${lot.properties.id}`, 1)

                    let signString = encryptBack(signed);
                    console.log(signed, signString, '==========.>>>signed<<<.=========')
                    let auctionData = {
                        licenseID: lot.properties.id,
                        name: lot.name,
                        logo: lot.image,
                        idOfLicense: lot.properties.index,
                        licenseType: lot.properties.licenseType,
                        projectId: lot.properties.tokenURI,
                        tickerSymbol: lot.properties.tickerSymbol,
                        auctionDate: auctionDate,
                        priceStart: priceStart,
                        priceBuyNow: priceBuyNow,
                        // timeZone: selectedTimezone,
                        // idxOfActiveTimezone: idxOfActiveTimezone,
                        owner: ownerWaller,
                        xstring: signString,
                        deadline: approvedData,
                        lotId: generatedId

                    };
                    let data = {[lot.properties.id]: true};
                    console.log(data, '-----data', auctionData, '---------auctionData')
                    // axios({
                    //   method: 'post',
                    //   url: `${constants.apiRoot}/contracts/auctions/updateAuctionItems`,
                    //   responseType: 'json',
                    //   auth,
                    //   data
                    // }).then((response) => {
                    //   console.log(response.data);
                    //   if (response) {
                    //
                    //     count=count+1;
                    //     removeFromCart(lot)
                    // };
                    //
                    // });
                    //
                    //
                    sendParseRequest('createAuctionLicenses', auctionData)
                        .then(res => {
                            console.log(res, 'res33333------333333----3333333-333333-33333----3333')
                        }).catch((e) => console.log(e));


                })


                if (count === props.lots.length) {
                    hideModal()
                }

            }
// Returns a new random alphanumeric string suitable for object ID.


            // let auctionData = {
            //   licenseID: licenseItem,
            //   name: token.name,
            //   logo: logo,
            //   idOfLicense: token.properties.index,
            //   licenseType: token.properties.licenseType,
            //   projectId: token.properties.projectId,
            //   tickerSymbol: token.properties.tickerSymbol,
            //   auctionDate: auctionDate,
            //   priceStart: priceStart,
            //   priceBuyNow: priceBuyNow,
            //   timeZone: selectedTimezone,
            //   idxOfActiveTimezone: idxOfActiveTimezone,
            //   owner: ownerWaller,
            //   xstring: signString,
            //   deadline: approvedData
            // };

            // console.log(auctionData, "--------------auctionData")


        } else {
            // sign transfer for future buyers

            let passwordForWally = passwordRef.current.value;

            // console.log(  walletAddress,ethWallet,'  walletAddress,ethWallet,',   passwordRef.current.value)
            let wallyDecryptedObject = await getWallyWalletObject(passwordForWally)
            if (wallyDecryptedObject) {
                console.log(wallyDecryptedObject, '----wallyDecryptedObject---🪲🪲🪲🪲🪲🪲🪲🪲🪲🪲🪲🪲-----🪲🪲🪲🪲🪲🪲')



                    // let signed = await sighInTendermint(importedObj.walletObject, walletAddress, undefined, 100)
                    let signed = await sighInTendermint(wallyDecryptedObject.walletObject, walletAddress, `${token.properties.id}`, 1)
                    console.log(signed, '==========.>>>signed<<<.=========')

                    let signString = encryptBack(signed);
                    let toDateData = auctionDate.toUTCString();
                    let ownerWallet = encryptBack(walletAddress);

                    let auctionData = {
                        licenseID: licenseItem,
                        name: token.name,
                        logo: logo,
                        ownerWallet: ownerWallet,
                        idOfLicense: token.properties.id,
                        licenseType: token.properties.licenseType,
                        projectId: token.properties.tokenURI,
                        tickerSymbol: token.properties.tickerSymbol,
                        auctionDate: new Date(toDateData),
                        priceStart: priceStart,
                        priceBuyNow: priceBuyNow,
                        // timeZone: selectedTimezone,
                        // idxOfActiveTimezone: idxOfActiveTimezone,
                        owner: ownerWaller,
                        xstring: signString,
                        deadline: approvedData
                    };


                    let data = {[licenseItem]: true};
                    console.log(data, licenseItem, '<<<data>>>')

                    // axios({
                    //   method: 'post',
                    //   url: `${constants.apiRoot}/contracts/auctions/updateAuctionItems`,
                    //   responseType: 'json',
                    //   auth,
                    //   data
                    // }).then((response) => {
                    //   console.log(response.data, "____>>>>RESSSSPONSSSSSEEEE<<<<<<++++++");
                    //   if (response.data) {
                    //     hideModal()
                    //   }
                    // });

                    console.log(auctionData, '-----------((((((((auctionData0))))))))))-------------------')
                    sendParseRequest('createAuctionLicenses', auctionData)
                        .then(res => {
                            if (res) {
                                hideModal()
                            }
                            console.log(res)
                        }).catch((e) => console.log(e));

                } else setCheckedPassword('incorrect password')

        }

    };

    const getOptionsForAuctionTime = () => {
        let optionsForAuctionTime = [];

        for (let i = 0; i < 24; i++) {
            let hours = i < 10 ? `0${i}` : i;
            let minutes = '00';

            for (let j = 0; j < 60; j += 15) {
                minutes = j < 10 ? `0${j}` : j;
                const time = `${hours}:${minutes}`;

                optionsForAuctionTime.push(
                    <option value={time}
                            key={`auction-time-key-${j}-${i}`}>
                        {time}
                    </option>
                );
            }
        }

        return optionsForAuctionTime;
    };

    const getOptionForTimeZone = () => {
        let optionsForSelect = [];

        for (let timezone in timezoneData) {
            const timezoneForValue = timezone.match(/([\w]+[\+\-][\d]+:[\d]+)/g)[0];

            optionsForSelect.push(
                <option value={timezoneForValue || timezone}
                        key={`option-timezone-key-${timezone}`}>{timezone}</option>
            );
        }

        return optionsForSelect;
    };

    const render = () => {
        const {licenses, license} = props;

        const {showCalendar, isValidatedDate, date, isValidatedStartPrice, isValidatedPrice, isLoading} = state;


        return (<>{statusPopup &&
            <div className="slideshowModal ">
                <button className="slideshowModalWrapper__closeButton slideshowModalButton">
                    <img src={closeSvg} alt="close"
                         onMouseDown={() => setStatusPopup(false)}
                    />
                </button>
                <div className=" popup-withdraw popup_tokens" style={{height: "40%"}}>
                    <div className="creator-popup-block"
                         style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                        <h2> Enter your wallet's password</h2>
                        {/*<p style={{marginTop: "10px"}}>for <b>{activeWallet}</b></p>*/}
                        <input type="password" className="your-bid" ref={passwordRef}
                               style={{marginTop: "10px", width: '100%', padding: "0 10px", height: "40px"}}/>
                        {checkPassword &&
                        <p style={{color: 'red', margin: "2px 0", textAlign: 'left'}}>{checkPassword}</p>}
                    </div>
                    <button type="button" className="popup_tokens-btn"
                            onClick={() => startAuction()}
                    >Set an auction
                    </button>

                </div>
            </div>}

                <div className="schedule-modal" ref={modalRef}>
                    <div className="schedule-modal__wrapper">
                        <div className="schedule-modal__header">
                            <div className="schedule-modal__header-title">
                                Schedule an auction
                            </div>zz


                            <div className="modal-close-button" onClick={hideModal}/>
                        </div>
                        <Loader showLoader={isLoading} style={{
                            width: "auto",
                            height: "auto",
                            backgroundColor: "#FFFFFF",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "static"
                        }}>
                            <div className="schedule-modal__body">
                                <div className="schedule-modal__body_card">
                                    <Logo logo={props.logo}/>
                                    {
                                        // !licenses &&
                                        // <Description
                                        //   auctionId={""}
                                        //   indexItem={props.indexItem}
                                        //   tickerSymbol={props.token.properties.tickerSymbol}
                                        // numOfLicenses={props.token.licenses.length}
                                        // />
                                    }

                                    {
                                        licenses && <Fragment>
                                            <div className="schedule-modal__card-title">
                                                {
                                                    // `Auctioning ${props.token.symbol} lot of ${licenses.length} / ${props.token.licenses.length}.`
                                                    `Auctioning ${props.token.properties.tickerSymbol} lot of ${licenses.length} / .`

                                                }
                                            </div>
                                            {/*< div className="schedule-modal__card-sub-title">*/}
                                            {/*  {tickerSymbol}*/}
                                            {/*</div>*/}
                                            <div className="schedule-modal__card-totallicenses">
                                                {
                                                    // `The auction includes ${licenses.map(license => license.AuctionId)}`
                                                }
                                            </div>
                                        </Fragment>
                                    }
                                </div>
                                <div className="schedule-modal__body__pricing">
                                    <label> set date for auction:
                                        <div className={"input-wrapper " + (isValidatedDate ? "green" : '')}>
                                            <input
                                                value={endDateRef.current ? endDateRef.current.value : ''}
                                                ref={endDateRef}
                                                onChange={() => handleDate()}
                                                required
                                                placeholder="MM.DD.YYYY"
                                            />
                                            <div className={"date-setting calendar-image"}
                                                 onClick={handleCalendar}/>
                                        </div>
                                    </label>
                                    {
                                        showCalendar &&
                                        <Calendar date={date} setDate={setDate}
                                                  handleCalendar={handleCalendar}/>
                                    }
                                    <label> SET TIME FOR AUCTION

                                        <select className="input-body"
                                                ref={endTimeRef}>
                                            {getOptionsForAuctionTime()}
                                        </select>

                                    </label>

                                    {/*<label> SET TIMEZONE*/}

                                    {/*    <select className="input-body" ref={timezoneOfAuctionRef}>*/}
                                    {/*        {getOptionForTimeZone()}*/}
                                    {/*    </select>*/}

                                    {/*</label>*/}

                                    <label> Auction Start Price
                                        <input
                                            className={"input-body" + (isValidatedStartPrice ? " green" : "")}
                                            placeholder="$"
                                            ref={startPriceRef}
                                            onChange={() => console.log(startPriceRef.current.value)}
                                            // onChange={handlePrice}
                                        />
                                    </label>
                                    <label> Buy It Now Price
                                        <input className={"input-body" + (isValidatedPrice ? " green" : "")}
                                               placeholder="$"
                                               ref={priceRef}
                                            // onChange={handlePrice}
                                        />
                                    </label>
                                    {/*<label> Wallet's password*/}
                                    {/*    <input className={"input-body" + (password ? " green" : "")}*/}
                                    {/*           placeholder="*****"*/}
                                    {/*           type="password"*/}
                                    {/*           ref={passwordRef}*/}
                                    {/*        onChange={()=> setPassword(passwordRef.current.value)}*/}
                                    {/*    />*/}
                                    {/*</label>*/}
                                </div>
                            </div>
                        </Loader>

                        <div className="schedule-modal__body__button-section">
                            <input className="btn-sell" type="submit" value="Cancel"
                                // onClick={hideModal}
                            />
                            {/* <input className={"btn-sell blue" + (isValidated() || isLoading ? "" : " disabled")} */}
                            <input className={"btn-sell blue"}

                                //  disabled={!isValidated() || isLoading}
                                   onClick={() => setStatusPopup(true)}

                                   type="submit" value="Sell"/>
                        </div>
                    </div>
                </div>
            </>

        )
            ;
    }



    return render();
}


ScheduleAnAuction.propTypes = {
    licenses: PropTypes.array,
    license: PropTypes.string.isRequired,
};

const Description = ({indexItem, tickerSymbol, numOfLicenses}) => {
    console.log(indexItem, tickerSymbol, numOfLicenses, 5555555)
    return <Fragment>
        <div className="schedule-modal__card-title">
            #{indexItem}
        </div>

        <div className="schedule-modal__card-sub-title">
            {tickerSymbol}
        </div>
        {/*<div className="schedule-modal__card-totallicenses">*/}
        {/*    {numOfLicenses}*/}
        {/*</div>*/}
    </Fragment>
};

const Logo = ({logo}) => {
    return <div className={"circleL"} style={{
        backgroundImage: `url(${logo || defaultLogo})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    }}>
    </div>
};

function parseDateToValue(date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${month > 9 ? month : '0' + month}.${day > 9 ? day : '0' + day}.${year}`
}

function validatePrice(node) {
    try {
        const regex = /^[1-9]\d*(?:\.\d{0,2})?$/;
        return regex.test(node.value);
    } catch (e) {
        return false
    }
}

function validateDate(node) {
    const isLeapYear = (year) => {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    };

    const allowBlank = true;
    const minYear = (new Date()).getFullYear();
    const maxYear = (new Date()).getFullYear() + 2;

    let errorMsg = "";

    // regular expression to match required date format
    const regs = node.value.toString().match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/);
    let year, month, day, date, maxDay = 31;
    try {
        year = regs[3];
        month = regs[1];
        day = regs[2];

        date = new Date(node.value);
        if (isLeapYear(year) && (month === '2' || month === '02')) {
            maxDay = 29
        }
    } catch (e) {
        errorMsg = "Invalid date format: " + node.value;
        return false;
    }

    if (node !== '') {
        if (regs) {
            if (day < 1 || day > maxDay) {
                errorMsg = "Invalid value for day: " + regs[3];
            } else if (month < 1 || month > 12) {
                errorMsg = "Invalid value for month: " + regs[2];
            } else if (year < minYear || year > maxYear) {
                errorMsg = "Invalid value for year: " + regs[1];
            } else if (date < new Date().setDate(new Date().getDate() + 1)) {
                errorMsg = "Invalid value";
            }
        } else {
            errorMsg = "Invalid date format: " + node.value;
        }
    } else if (!allowBlank) {
        errorMsg = "Empty date not allowed!";
    }

    if (errorMsg !== "") {
        node.focus();
        return false;
    }

    return true;
}

function mapStateToProps({blockchain}) {
    return {
        mainWalletAddress: blockchain.mainWalletAddress
    };
}

export default connect(
    mapStateToProps,
    {...blockchainActions}
)(ScheduleAnAuction);

