import React, {useEffect, useState} from 'react';
import NewSlider from "../../Components/NewSlider/Index";
import {useLocation} from "react-router";
import {cardsData} from './fanancersData';


const Card = () => {
    const location = useLocation();

    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [sliderTitle, setSliderTitle] = useState('');

    useEffect(() => {
        document.title = "Fanbase";
        window.scrollTo(0, 0);

        switch (location.pathname.substring(11)) {
            case 'music':
                setData(0)
                break;
            case 'film':
                setData(1)
                break;
            case 'photo':
                setData(2)
                break;
            case 'magazine':
                setData(3)
                break;
            case 'radio':
                setData(4)
                break;
            case 'games':
                setData(5)
                break;
            case 'books':
                setData(6)
                break;
            case 'apps':
                setData(7)
                break;
            case 'tv':
                setData(8)
                break;
        }
    }, []);

    const setData = idx => {
        setTitle(cardsData[idx].title)
        setText(cardsData[idx].text)
        setSliderTitle(cardsData[idx].sliderTitle)
    }

    return (
        <div className="page" style={{paddingBottom: "0"}}>
            <div className="pageContent">
                <div className="mainHeader">
                    <p className="mainTitle category-title">
                        {title}
                    </p>
                    <div className="tokenSummarySiteWrap"/>
                </div>
                <div className="fnbXTokenAnythingWrap card6">
                    <p className="subDescription"
                       style={{
                           width: "800px",
                           "textAlign": "justify",
                           color: "grey",
                           marginBottom: "50px"
                       }}
                    >
                        {text}
                    </p>
                    {/*{sliderTitle &&*/}
                    {/*<NewSlider title={sliderTitle}*/}
                    {/*           display={{description: true, progress: true, status: true}}*/}
                    {/*/>}*/}
                </div>
                <br/>
            </div>
        </div>
    );
};

export default Card;
