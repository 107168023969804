import React from 'react';
import errorIcon from "../../../assets/images/errorPage__icon.png";
import './ErrorPage.css';

const ErrorPage = () => {
    return (
        <div>
            <div className='error__wrapper'>
                <img className='errorImg' src={errorIcon} alt="not found"/>
                <div className='error-message__wrapper'>
                    <p className='error-message__oops'>Oops..</p>
                    <p className='error-message__title'>Internal Server Error</p>
                    <p className='error-message__text'><span className='error-message__span'/> An error occured while
                        processing this request</p>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;