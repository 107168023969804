import React from 'react';
import {Link} from 'react-router-dom';
import "./UserProfile.css";
import Parse from "parse";
import BlankImage from "../../../assets/images/FanbaseHeader.jpg";
import prepareImagesPathForMinify from './../../../utils/prepareImagesPathForMinify';
import {generatePdfTable} from "../../../utils/generatePdfTable";   
import {sendParseRequest} from "../../../utils/sendParseRequest";

export class UserProfile extends React.Component {
    state = {
        src: BlankImage,
        coverImage: BlankImage,
        fullname: "",
        userEmail: "",
        currentState: 1,
        userId: "",
        RegisteredDate: "",
        lastUpdated: "",
        accreditedStatus: "",
    }

    componentDidMount() {
        document.title = "About me - FNBD";

        sendParseRequest('getUserInfoById', {id: Parse.User.current().id})
            .then(res => {
                this.setState({
                    userId: res.id,
                    RegisteredDate: res.get('createdAt').toString(),
                    lastUpdated: res.get('updatedAt').toString(),
                    userEmail: res.get('username'),
                    accreditedStatus: res.get('accreditedStatus') === "Complete",
                    src: res.get('avatarLink') || BlankImage,
                    coverImage: res.get('coverLink') || BlankImage,
                    fullname: res.get('fname') + " " + res.get('lname'),
                })
            })
    }

    getAmazonUrl = (file, loadingType) => {
        if (file) {
            let type = file.type;
            let src = file.src;

            sendParseRequest('uploadProjectImage', {base64: src, type: type})
                .then(results => {
                    sendParseRequest('updateInfo', {
                        searchData: {
                            query: 'User',
                            equal: [{field: 'objectId', value: Parse.User.current().id}]
                        },
                        updateData: [{field: loadingType, value: results}]
                    }).then(res => {
                        switch (loadingType) {
                            case 'avatarLink':
                                this.setState({
                                    src: res[0].get('avatarLink')
                                })
                                break;
                            case 'coverLink':
                                this.setState({
                                    coverImage: res[0].get('coverLink')
                                });
                                break;
                        }
                    })
                })
        }
    };

    uploadImage = (e, loadingType) => {
        const file = e.currentTarget.files[0];
        if (file === undefined) return;
        const _URL = window.URL || window.webkitURL;
        if (file && file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
            const img = new Image();
            img.onload = () => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    new Promise((resolve) => {
                        this.setState({
                            [loadingType]: [].concat({
                                "name": file.name,
                                "type": file.type,
                                "src": reader.result
                            })
                        });
                        resolve(this.state)
                    }).then((state) => {
                        this.getAmazonUrl(state[loadingType][0], loadingType)
                    })

                };
                reader.readAsDataURL(file);
            };
            img.src = _URL.createObjectURL(file);
        }
    };

    simulateClick = (id) => {
        let event = new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': true
        });
        let cb = document.getElementById(id);
        cb.dispatchEvent(event);
    };

    downloadPdfTransactions = () => {
        const mail = 'ronald.mears.jr@gmail.com';
        const account = 'FANBOY7 Wallet';
        generatePdfTable({mail, account})
    }

    render() {
        const {currentState} = this.state;

        return (
            <div className="dashWrapperB user-info__wrapper">
                <div className="page profilePage aboutMePage">
                    <div className="profilePageWrapper">
                        <div className={this.state.fullname.length > 20
                            ? "backgroundCoverImage backgroundCoverImage-longName" : "backgroundCoverImage"}
                        >
                            <img src={this.state.coverImage} alt=''/>
                            <div>
                                <div className="sideBttn" onClick={() => this.simulateClick('hUpload2')}>
                                    <a>Change cover <i className="fa fa-upload"/></a>
                                </div>
                                <input id='hUpload2'
                                       type='file'
                                       onChange={e => this.uploadImage(e, 'coverLink')}
                                       hidden
                                />
                            </div>
                            <Link className="pull-right changeCoverBttn"
                                  style={{"display": "block", "color": "black", "textDecoration": "none"}}
                                  to="/settings/compliance">
                                Account Settings
                            </Link>
                            <div className="profileImageCover">
                                <div className="profileImage">
                                    <img src={prepareImagesPathForMinify(this.state.src, 200, 200)} alt=''/>
                                    <div onClick={() => this.simulateClick('hUpload')}
                                         className="onHoverProfileSettings">
                                        <a><i className="fa fa-upload"/></a>
                                    </div>
                                    <input onChange={e => this.uploadImage(e, 'avatarLink')} type="file"
                                           id="hUpload" hidden/>
                                </div>
                                <div className="profileDetails">
                                    <p className="fullname">
                                        {this.state.fullname.length > 20
                                            ? this.state.fullname.substr(0, 20) + '...'
                                            : this.state.fullname
                                        }
                                    </p>
                                    <p className="userEmail">{this.state.userEmail.toLowerCase()}</p>
                                </div>
                            </div>
                        </div>
                        <br style={{"clear": "both"}}/>
                        <div className="profileContentBox my-profile__wrapper" style={{"width": "100%"}}>
                            <div className="profileHeaderT">
                                <p className="title">My Profile</p>
                            </div>
                            <div className="myProfileblow prfl-myProfileblow">
                                <div className="aboutmeLeftSide">
                                    <ul>
                                        <li onClick={() => this.setState({currentState: 1})}
                                            className={currentState === 1 ? "active" : ""}>Registration
                                        </li>
                                        <li onClick={() => this.setState({currentState: 2})}
                                            className={currentState === 2 ? "active" : ""}>Taxes
                                        </li>
                                        <li onClick={() => this.setState({currentState: 3})}
                                            className={currentState === 3 ? "active" : ""}>Banking
                                        </li>
                                        <li onClick={() => this.setState({currentState: 4})}
                                            className={currentState === 4 ? "active" : ""}>Address book
                                        </li>
                                        <li onClick={() => this.setState({currentState: 5})}
                                            className={currentState === 5 ? "active" : ""}>Limits
                                        </li>
                                        <li onClick={() => this.setState({currentState: 6})}
                                            className={currentState === 6 ? "active" : ""}>Statements
                                        </li>
                                    </ul>
                                </div>
                                <div className="aboutmeRightSide">
                                    {currentState === 1 &&
                                    <div className="mainContainer">
                                        <div className="textRow" style={{"marginBottom": "0"}}>
                                            <span className="contact30 pull-left">User ID:</span>
                                            <span className="contact70 pull-left">{this.state.userId}</span>
                                            <br style={{"clear": "both"}}/>
                                        </div>
                                        <hr/>
                                        <div className="textRow" style={{"marginBottom": "0"}}>
                                            <span className="contact30 pull-left">Registered Date:</span>
                                            <span
                                                className="contact70 pull-left">{this.state.RegisteredDate}</span>
                                            <br style={{"clear": "both"}}/>
                                        </div>
                                        <hr/>
                                        <div className="textRow" style={{"marginBottom": "0"}}>
                                            <span className="contact30 pull-left">Last Updated:</span>
                                            <span
                                                className="contact70 pull-left">{this.state.lastUpdated}</span>
                                            <br style={{"clear": "both"}}/>
                                        </div>
                                        <hr/>
                                        <div className="textRow" style={{"marginBottom": "0"}}>
                                            <span className="contact30 pull-left">User Status:</span>
                                            <span className="contact70 pull-left">
                                                {this.state.accreditedStatus === true ? "Accredited" : "Not Accredited"}
                                            </span>
                                            <br style={{"clear": "both"}}/>
                                        </div>
                                    </div>
                                    }
                                    {currentState === 2 &&
                                    <div className='getPdf__block'>
                                        <button className='getPdf__button'
                                                onClick={this.downloadPdfTransactions}>
                                            Get Collectiblzes tax pdf
                                        </button>
                                        <button className='getPdf__button'>Get Tickets tax pdf</button>
                                        <button className='getPdf__button'>Get Tokens tax pdf</button>
                                    </div>
                                    }
                                    {currentState === 3 &&
                                    <div className='getPdf__block'/>
                                    }
                                    {currentState === 4 &&
                                    <div className='getPdf__block'/>
                                    }
                                    {currentState === 5 &&
                                    <div className='getPdf__block'/>
                                    }
                                    {currentState === 6 &&
                                    <div className='getPdf__block'/>
                                    }
                                </div>
                                <br style={{"clear": "both"}}/>
                            </div>
                        </div>
                        <br style={{"clear": "both"}}/>
                    </div>
                </div>
            </div>
        )
    }
}