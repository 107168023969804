import React from 'react';
import Parse from "parse";
import SettingsPageHeader from "../../../Components/SettingsPageHeader";
import Timezone from "./timezone.json";
import {sendParseRequest} from "../../../../utils/sendParseRequest";

export class Preferences extends React.Component {
    state = {
        language: "",
        localCurrency: "",
        timezone: ""
    }

    componentDidMount() {
        document.title = "Preferences – FNBD";

        sendParseRequest('getUserInfoById', {id: Parse.User.current().id})
            .then(res => {
                this.setState({timezone: res.get('timezone')})
            })
    }

    handleTimeZonChange = (e) => {
        const value = e.target.value;
        sendParseRequest('updateInfo', {
            searchData: {
                query: 'User',
                equal: [{field: 'objectId', value: Parse.User.current().id}]
            },
            updateData: [{field: 'timezone', value: value}]
        }).then(() => {
            this.setState({timezone: value});
        })
    }

    render() {
        return (
            <div className="page settingsPage">
                <div className="dashWrapperB" style={{marginBottom: '20px'}}>
                    <div className="dashModal seperator" style={{"width": "10%"}}/>
                    <div className="dashModal contentPage" style={{"width": "80%"}}>
                        <div className="content " style={{"height": "auto"}}>
                            <SettingsPageHeader/>
                            <div className="settingsWrapper Preferences">
                                <p className="title">Preferences</p>
                                <div className="nextDetailsInput" style={{"width": "100%"}}>
                                    <div className="documentRightSide" style={{"width": "100%", "float": "none"}}>
                                        <div className="documentRightSide__inputWrapper">
                                            <label>Select Language</label>
                                            <select className="withArrow"
                                                    onChange={(e) => this.setState({"language": e.target.value})}>
                                                <option value="english">English</option>
                                            </select>
                                        </div>
                                        <div className="documentRightSide__inputWrapper">
                                            <label>Local Currency</label>
                                            <select className="withArrow"
                                                    onChange={(e) => this.setState({"localCurrency": e.target.value})}>
                                                <option value="usd">United States Dollar (USD)</option>
                                            </select>
                                        </div>
                                        <div className="documentRightSide__inputWrapper">
                                            <label>Time Zone</label>
                                            <select className="withArrow" value={this.state.timezone}
                                                    onChange={this.handleTimeZonChange}>
                                                {Object.keys(Timezone).map((type, i) => {
                                                    return (<option key={i} value={type}>{type}</option>);
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashModal seperator" style={{"width": "10%"}}/>
                    <br style={{"clear": "both"}}/>
                </div>
            </div>
        )
    }
}
