import React, {Component, Fragment} from 'react';
import ShowProjectMediaFile from '../../ShowProjectMediaFile';
import prepareImagesPathForMinify from '../../../../utils/prepareImagesPathForMinify';

import defaultLogo from '../../../../assets/images/F_in_White.png';
import {sendParseRequest} from "../../../../utils/sendParseRequest";

export default class MyCollectibleItem extends Component {
    state = {
        hashOfAuctionItem: '',
        auctionItemType: '',
        openFileViewer: false
    };


    sellAll = () => {
        console.log(1111, "--------------1111")
        const node = this.props.highlighted;
        if (node === this.collectibleItem || this.props.index === 0 && node === null) {
            this.props.handleSellAllLicenses(true);
        } else {
            this.props.handleSellAllLicenses(false);
        }
    };

    getProjectMedia = (projectId) => {
        console.log(projectId, '😈😈😈😈😈😈😈😈======😈😈😈😈', 'projectID')
        sendParseRequest('getInfo', {
            query: "Projects",
            equal: [{field: "objectId", value: projectId}],
            select: ["auctionItems"]
        })
            .then(res => {


                this.setState({
                    itemsData: res[0].get('auctionItems'),
                    openFileViewer: true
                })
            }).catch(() => this.setState({openFileViewer: true})
        )
    };

    onFileViewerClose = () => this.setState({
        openFileViewer: false,
        hashOfAuctionItem: ''
    });

    render() {
        const {
            project,
//                     value,
//
            onSelectProject,
            setPortfolioTab,
            isSelected
        } = this.props;


        console.log(this.state, "--------------THHHISSSS STATEEE")
        const {
            hashOfAuctionItem,
            auctionItemType,
            openFileViewer
        } = this.state;
        return (
            <Fragment>
                {
                    openFileViewer && <ShowProjectMediaFile onClose={this.onFileViewerClose}
                                                            itemsData={this.state.itemsData}
                    />
                }

                <div className={`collectibleItem ${isSelected ? 'highlight' : ''}`}
                     onClick={() => {
                         onSelectProject(project);
                         setPortfolioTab('collectible')
                     }}
                >
                    <div style={{"display": "flex", "alignItems": "center", "justifyContent": "spaceBetween"}}>
                        <div className="logo">
                            {project.metadata ?
                                <img src={prepareImagesPathForMinify(project.logoAttach[0], 40, 40) || defaultLogo}
                                     alt="Logo"/>
                                : <img src={defaultLogo} alt="Logo"/>

                            }

                        </div>
                        <div className="porfolioDetails">
                            <span>
                                {project.metadata && project.metadata.tickerSymbol}
                            </span>
                            <span>
                                {/*{value && value}*/}
                            </span>
                        </div>
                    </div>
                    <div className="buyNowBtn-section">
                        <div className="btn_section-right">
                            <input className="buyNowBttn deposit" type="button" value="Buy+"/>
                            <input className="buyNowBttn tradeBttn"
                                   type="button"
                                   value="View"
                                   onClick={() => this.getProjectMedia(project.metadata.tokenURI)}
                            />
                        </div>
                        <div className="btn_section-right">

                            <input className="buyNowBttn tradeBttn" type="button" value="Sell" onClick={() => {
                                this.sellAll()
                            }}/>
                            <input className="buyNowBttn tradeBttn" type="button" value="Send"/>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}
