import React, {Component} from 'react';
import Parse from 'parse';
import ShowProjectMediaFile from '../../Components/ShowProjectMediaFile';
import prepareImagesPathForMinify from '../../../utils/prepareImagesPathForMinify.js';
import ImagesLayout from '../../Components/FiveImages/imagesLayout';
import AuctionCard from '../../Components/AuctionCard';
import AddUserListContainer from '../../Components/AddUserList';
import formatArrToString from '../../../utils/formatArrToString';
import formatDateToMDY from '../../../utils/formatDateToMDY';
import DropDownList from '../../Components/DropDownList';
import ModalWindow from "../../Components/ModalWindow";
import LogoMaker from "../../Components/LogoMaker";
import './ProjectAuctionPage.css'
import UploadAttachTOIpfs from "../Contract/UploadAttachTOIpfs";
import ethClient from '../../eth_client';
import {sendParseRequest} from "../../../utils/sendParseRequest";
import {sendAxiosRequest} from "../../../utils/sendAxiosRequest";
import {message} from "../../../utils/message";
import axios from "axios";
import {Link} from 'react-router-dom';

export default class ProjectAuctionPage extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();

    this.haтьndleWatchVideo = this.handleWatchVideo.bind(this);
    this.state = {
      videoShow: false,
      bannerImages: [],
      projectTitle: "",
      projectSubtitle: "",
      creatorId: "",
      tickerSymbol: "",
      logoAttach: [],
      rookieRank: "",
      mainLanguage: "",
      ageRestrictions: "",
      additionalLanguage: [],
      projectDescription: "",
      projectPerks: "",
      additionalAttachments: '',
      numOfLicenses: "",
      stageName: "",
      mediaCategory: "",
      auctionTime: "",
      principals: [],
      collaborators: [],
      editState: "0",
      projectID: "",
      collData: "",
      lotExist: null,
      lastAuction: {},
      nextAuctions: null,
      isShowPrincipalAdd: false,
      a: 'p',
      isOwnerOfTheProject: false,
      auctionsCount: 0,
      additionalData: {},
      isLogoMakerOpen: false,
      openFileViewer: false,
      hashOfAttachItem: '',
      attachItemType: '',
      attachInput: false,
      attachItem: '',
      attachFileType: true,
      attachFileName: '',
      tab: "",
      attachName: false,
      isReportDescription: false,
      showDescription: true,
      isReportPerks: false,
      showPerks: true,
      project: null,
      activeAuctions: null
    };
  }

   getAuctionLisences = ()=> {
     sendParseRequest('getActiveAuctionsLicensesByProject', {
       projectId: this.state.projectID,
       type: 'licenses'
     })
       .then(activeAuctions => {
         this.setState({activeAuctions: activeAuctions})

       })
  };


  async componentDidMount() {


    let auctionId = +window.location.href.split('=#')[1];

    const {match: {params}} = this.props;
console.log(this.props, "--------------propsZZZZZZZZZ")

    // if (isNaN(+params.projectId)) {
    //     this.props.history.push('/404');
    //     return
    // }

    let projects;

    // const project = await EthApi.contract.project.getProjectById(params.projectId);
    let address = localStorage.getItem('active_address')
    console.log(address, "--------------address", params)
    let projectData = await axios({
      method: "post",
      url: `http://3.220.210.43:8081/api/v3/trm/contracts/projects/getProjectsByTokenURIs`,
      data: {tokenUrls: params.tokenURI}
    });
    console.log(projectData, '11112222')
    let project = projectData.data.projects[0]


    // const project1 = await ethClient.project.getLastAuctionData(params.tokenURI, address);
    // console.log(project1, "--------------project1")
    // console.log(project, "--------------projecSSSSS")
    // this.setState({project})
    // console.log(project, "--------------project")

    // if (!project[1]) {
    //     this.props.history.push('/404');
    //     return
    // }

    // const projectId = '';

    project && console.log(project.properties, "--------------project", params, project, '-----TTTYYYY')
    // console.log(project, "--------------project")
    // const project = await EthApi.contract.project.getProjectById(params.projectId);
console.log(params.tokenURI, 'params.tokenURI')
    const projectId = params.tokenURI;


    this.setState({projectID:params.tokenURI })
    let metadata;
    console.log(project, '---PROJECT')
    if (project.metadata) {
      metadata = project.metadata
    }
    console.log(metadata, project, "--------------metadata.stageName11111")
    projectId && this.getProjectMedia(projectId);
    this.setState({project});
    this.getAuctionLisences(projectId)

    sendAxiosRequest('getLots', {projectId: projectId})
      .then(res => {
        if (res.result.length>0) {
          this.setState({lotExist: true})
        }
       console.log(res, "--------------resLOTTTTTTTTTTTT")
      })

    // const projectId = project.tokenURI;
    // let metadata ;
    // if (project.metadata) {
    //     metadata=JSON.parse(project.metadata)
    // }
    // this.setState({"projectID": projectId});
    // projectId && this.getProjectMedia(projectId);
    document.title = "Fanbase";
    window.scrollTo(0, 0);

    const currUserId = Parse.User.current() ? Parse.User.current().id : '';
    const Projects = Parse.Object.extend("Projects");
    const query = new Parse.Query(Projects);
    query.include('creator');
    query.select('promotionalBanners', 'projectTitle', 'projectSubtitle', 'creator',
      'tickerSymbol', 'logoAttach', 'rookieRank', 'mainLanguage',
      'ageRestrictions', 'additionalLanguage', 'projectDescription',
      'projectPerks', 'additonalAttachments', 'numOfLicenses', 'stageName',
      'mediaCategory', 'auctionTime', 'additionalData', 'showAuctionData', 'creator', "attachItem",
    );
    query.equalTo("objectId", projectId);
    query.find()
      .then((results) => {
        console.log(results, '======================8888888888=======================')
        if (results[0]) {


          projects = results[0].attributes;
          this.setState({
            "projectId": results[0].id,
            "isOwnerOfTheProject": projects.creator.id === currUserId,
            "bannerImages": projects.promotionalBanners,
            "projectTitle": projects.projectTitle,
            "projectSubtitle": projects.projectSubtitle,
            "creatorId": projects.creator.id,
            "tickerSymbol": metadata.tickerSymbol,
            "logoAttach": projects.logoAttach,
            "rookieRank": metadata.rookieRank,
            "mainLanguage": metadata.mainLanguage,
            "ageRestrictions": metadata.ageRestrictions,
            "additionalLanguage": metadata.additionalLanguage,
            "projectDescription": projects.projectDescription,
            "projectPerks": projects.projectPerks,
            "additionalAttachments": formatArrToString(projects.additonalAttachments, '\n'),
            "numOfLicenses": projects.numOfLicenses,
            fName: projects.creator.get('fname'),
            lName: projects.creator.get('lname'),
            "stageName": metadata.stageName,
            "mediaCategory": metadata.mediaCategory,
            "auctionTime": projects.auctionTime,
            "additionalData": metadata.additionalData,
            "showDescription": projects.showAuctionData[0].showDescription,
            "showPerks": projects.showAuctionData[1].showPerks,
            "itemsData": metadata.attachItem.attachHash && [{
              multiHash: metadata.attachItem.attachHash,
              type: metadata.attachItem.type,
              name: metadata.attachItem.name,
            }]
          })
        }
      }, (error) => {
        console.log(error);
      }).then(() => this.checkReports());

    const Principals = Parse.Object.extend("Project_Principals");
    const pplQuery = new Parse.Query(Principals);
    pplQuery.select('no_user_details', 'role', 'confirmed');
    pplQuery.equalTo("creator", Parse.User.current());
    pplQuery.equalTo("project", {"__type": "Pointer", "className": "Projects", "objectId": projectId});
    pplQuery.find()
      .then((results) => {
        if (results && results.length > 0) {
          const principals = results.map(prncpl => ({
            names: prncpl.get('no_user_details'),
            roles: prncpl.get('role'),
            id: prncpl.id,
            projectId: this.state.projectID,
            confirmed: prncpl.get('confirmed')
          }));

          this.setState({
            principals
          });
        }
      }, (error) => {
        console.log(error);
      });


    const Collab = Parse.Object.extend("Project_Collaborators");
    const collabQuery = new Parse.Query(Collab);
    collabQuery.select('no_user_details', 'role', 'confirmed');
    collabQuery.equalTo("creator", Parse.User.current());
    collabQuery.equalTo("project", {"__type": "Pointer", "className": "Projects", "objectId": projectId});
    collabQuery.find()
      .then((results) => {
        if (results && results.length > 0) {
          const collaborators = results.map(collab => ({
            names: collab.get('no_user_details'),
            roles: collab.get('role'),
            id: collab.id,
            title: this.state.projectTitle,
            projectId: this.state.projectID,
            confirmed: collab.get('confirmed')
          }));

          this.setState({
            collaborators
          });
        }
      }, (error) => {
        console.log(error);
      });

    // Parse.Cloud.run('getLastLicenseOnAuction', {projectId, auctionId: auctionId}, {
    //     success: (license) => {
    //         let nextLicensesArr = [];
    //         let lastLicens, count;
    //         license.nextAuction.length < 4 ? count = license.nextAuction.length : count = 4;

    //         for (let i = 1; i < count; i++) {
    //             nextLicensesArr.push(license.nextAuction[i])
    //         }
    //         if (license.license) {
    //             lastLicens = license.license;
    //         }
    //         if (license.license.length === 0) {
    //             lastLicens = license.nextAuction[0];
    //         }

    //         this.setState({
    //             lastAuction: lastLicens,
    //             nextAuctions: nextLicensesArr,
    //             auctionsCount: license.nextAuction[0].numOfLicenses
    //         })
    //     },
    //     error: (e) => {
    //         console.log('error', e.message);
    //     }
    // });


    //test
    const activeAccount = ethClient.eth.getActiveAccountAddress();
    // const collectibleIds = await ethClient.getUserCollectibleIds(activeAccount);
    // console.log("collectibleIds ----->", collectibleIds);
    // await ethClient.approveCollectiblesForMatcher(activeAccount, "password");


  }

  openViewer = () => {
    this.setState({openFileViewer: true})
  };

  getProjectMedia = (projectId) => {
    sendParseRequest('getInfo', {
      query: "Projects",
      equal: [{field: "objectId", value: projectId}],
      select: ["attachItem"],
    })
      .then(res => {
        console.log(res, "--------------res")
        //ToDo : add hashOfAttachItem, attachName,attachNameFile and attachItemType when it will add in blockchain!

        // res[0] && this.setState({
        //     hashOfAttachItem: res[0].attachItem.attachHash,
        //     attachName: res[0].attachItem.name,
        //     attachNameFile: res[0].attachItem.name,
        //     attachItemType: res[0].attachItem.type,
        // })
      })
  };

  saveAttachName = (inputRef) => {
    if (this.state.inputValue.length < 3) {
      inputRef.current.style.backgroundColor = '#ffadad';
      inputRef.current.placeholder = "3 characters minimum";
      return false
    }

    let value = inputRef.current.value;

    let attachItem = {
      "attachHash": this.state.hashOfAttachItem,
      "type": "application/pdf",
      "name": `${value}.pdf`
    };

    const Project = Parse.Object.extend("Projects");
    const projectsQuery = new Parse.Query(Project);
    projectsQuery.select('objectId', 'AttachItem');
    projectsQuery.equalTo("objectId", this.state.projectId);
    projectsQuery.find()
      .then((results) => {
        results.map((i) => {
          i.set('attachItem', attachItem);
          i.save();
          this.setState({
            attachName: attachItem.name,
            attachNameFile: attachItem.name,
            attachInput: !this.state.attachInput,
            itemsData: [{
              multiHash: attachItem.attachHash,
              name: attachItem.name,
              type: "application/pdf",
            }]
          })
        });
      })
  };

  validateInput = e => {
    this.setState({
      inputValue: e.target.value
    });
  };

  openInput = () => {
    this.setState({
      attachInput: !this.state.attachInput
    })
  };

  handleWatchVideo() {
    this.setState({videoShow: !this.state.videoShow});
  }

  handleInput = (type, value) => {
    this.setState({
      [type]: value
    });
  };

  updateProjectData = (dataName, dataValue) => {
    switch (dataName) {
      case 'projectDescription':
        this.setState({
          descriptionWarning: false,
        });
        break;
      case 'projectPerks':
        this.setState({
          perksWarning: false,
        });
        break;
    }

    sendParseRequest('getAllReportsByType', {type: 'auctionData'});

    if (dataValue) {
      const Projects = Parse.Object.extend("Projects");
      const projects = new Parse.Query(Projects);
      projects.equalTo("objectId", this.state.projectID);
      projects.first({
        success: (object) => {
          object.set(dataName, dataName === "additonalAttachments" ? formatArrToString(dataValue, '\n', true) : dataValue);
          object.save()
            .then(() => {
              this.setState({editState: "0"});
            });
        },
        error: (error) => {
          message('error', "Error: " + error.code + " " + error.message);
        }
      });
    } else {
      this.setState({editState: "0"});
    }
  };

  onFileViewerClose = () => this.setState({
    openFileViewer: false,
    hashOfAttachItem: ''
  });

  toggleLogoMaker = () => (this.setState(({isLogoMakerOpen}) => ({
    isLogoMakerOpen: !isLogoMakerOpen
  })));

  saveProjectLogo = (logoInBase64) => {
    sendParseRequest('uploadProjectImage', {base64: logoInBase64, type: 'image/png'})
      .then(res => {
        this.setState({logoAttach: [res]});
        this.updateProjectData('logoAttach', [res]);
        this.toggleLogoMaker();
      })
  };

  setAttachItem = ({file, addToIpfs, tab}) => {
    this.addToIpfs = addToIpfs;
    let type = file.name.split(".")[1];
    if (type === "pdf") {
      this.setState({
        attachItem: file,
        attachFileName: file.name.split(".")[0]
      }, () => this.setAttachInfo(tab));
    } else {
      this.setState({
        attachFileType: false,
        attachItem: true
      });
    }
  };

  setAttachInfo = async (tab) => {
    if (tab === "projectAttach") {
      const attachItemInfo = {};
      const projectId = this.state.projectID;

      if (await this.state.attachItem !== '') {
        attachItemInfo.attachHash = await this.addToIpfs(this.state.attachItem);
        attachItemInfo.type = this.state.attachItem.type;
        attachItemInfo.name = this.state.attachItem.name;
      }

      const projectQuery = new Parse.Query("Projects");
      projectQuery.equalTo("objectId", projectId);

      projectQuery.find().then(res => {
        res.map(i => {
          i.set("attachItem", attachItemInfo);
          i.save().then(() => window.location.reload())
        })
      })

    }
  };

  checkReports = () => {
    if (this.state.isOwnerOfTheProject) {
      sendParseRequest('getInfo', {
        query: 'Reports',
        equal: [{field: "objectId", value: this.state.projectId}]
      })
        .then(reports => {
          for (let report of reports) {
            if (report.get('showUp') === true && report.get('hasChanged') === false) {
              switch (report.get('reportTarget')) {
                case 'projectDescription':
                  this.setState({
                    descriptionWarning: true
                  });
                  break;
                case 'projectPerks':
                  this.setState({
                    perksWarning: true
                  });
                  break;
              }
            }
          }
        })
    }
  };

  showArea = (area) => {
    const userId = Parse.User.current().id;
    const projectId = this.state.projectId;
    switch (area) {
      case 'description':
        this.showWarningReportInProgress(userId, projectId, 'projectDescription', '', 'isReportDescription');
        break;
      case 'perks':
        this.showWarningReportInProgress(userId, projectId, 'projectPerks', '', 'isReportPerks');
        break;
    }
  };

  showWarningReportInProgress = (userId, projectId, reportTarget, targetField, checkReportField) => {
    sendParseRequest('getInfo', {
      query: "Reports",
      count: true,
      equal: [
        {field: "reportCreator", value: userId},
        {field: "projectId", value: projectId},
        {field: "reportTarget", value: reportTarget},
        {field: "targetField", value: targetField}
      ]
    }).then(res => {
      if (res) {
        this.setState({
          reportInProgress: true
        });
        setTimeout(() => {
          this.setState({
            reportInProgress: false
          })
        }, 3000);
        return false
      } else {
        this.setState({
          [checkReportField]: !this.state[checkReportField],
          reportInProgress: false
        });
      }
    })
  };

  getReportMessage = (area) => {
    if (!area.currentTarget.value) return;

    const reportData = [
      {field: 'reportMessage', value: area.currentTarget.value},
      {field: 'tickerSymbol', value: this.state.tickerSymbol},
      {field: 'projectTitle', value: this.state.projectTitle},
      {field: 'projectUrl', value: this.props.match.url},
      {field: 'projectCreator', value: this.state.creatorId},
      {field: 'reportCreator', value: Parse.User.current().id},
      {field: 'Type', value: 'auctionData'},
      {field: 'targetField', value: ''},
      {field: 'projectId', value: this.state.projectId},
      {field: 'hasChanged', value: false},
      {
        field: 'project', value: {
          __type: "Pointer",
          className: 'Projects',
          objectId: this.state.projectId,
        }
      },
    ]

    switch (area.target.id) {
      case 'description':
        reportData.push(
          {field: 'showUp', value: this.state.showDescription},
          {field: 'reportTarget', value: 'projectDescription'},
          {field: 'prevValue', value: this.state.projectDescription},
        )
        this.setState({reportMessageDescription: reportData});
        break;
      case 'perks':
        reportData.push(
          {field: 'showUp', value: this.state.showPerks},
          {field: 'reportTarget', value: 'projectPerks'},
          {field: 'prevValue', value: this.state.projectPerks},
        )
        this.setState({reportMessagePerks: reportData});
        break;
    }
  };

  submitReport = (reportData, isFieldReportName, isSubmitReportField) => {
    if (!reportData) return;

    sendParseRequest('createDatabaseObject', {query: 'Reports', saveData: reportData})
      .then(() => {
        this.setState({
          [isFieldReportName]: !this.state[isFieldReportName],
          [isSubmitReportField]: true
        });

        setTimeout(() => this.setState({[isSubmitReportField]: false}), 2000)
      })
  };

  renderLastAuction = () => {
    const project = this.state.project;
    console.log(project, "--------------project" +
      " ====================================================================================================================")

    if (project) {
      if (project.lastAuction) {
        return <AuctionCard lastAuction={project.lastAuction} tickerSymbol={project.symbol}/>
      }
    }
    return null;
  }


  render() {
    const {activeAuctions,
      editState, projectTitle, projectSubtitle, creatorId,
      tickerSymbol, logoAttach, rookieRank, mainLanguage,
      ageRestrictions, additionalLanguage, projectDescription,
      projectPerks, additionalAttachments, numOfLicenses, stageName,
      mediaCategory, auctionTime, principals, collaborators, lastAuction, nextAuctions, isOwnerOfTheProject,
      projectID, auctionsCount, additionalData, isLogoMakerOpen, openFileViewer, fName, lName,
      project
    } = this.state;

    const additionalAttachmentsArr = formatArrToString(additionalAttachments, '\n', true);
    // const logoOfProject = logoAttach ? prepareImagesPathForMinify(logoAttach[0], 80, 80) :
    //     prepareImagesPathForMinify("https://s3.amazonaws.com/fanbase.platform/projects/assets/0aec73.jpeg", 80, 80);

    // if(principals && principals.length > 0){
    //   for(var i = 0; i < principals.length; i++){
    //     prNames.push({"names":principals[i].get('no_user_details'),"roles":principals[i].get('role')});
    //   }
    // }

    // if(collaborators && collaborators.length > 0){
    //   for(var i = 0; i < collaborators.length; i++){
    //     collNames.push({
    //       "names":collaborators[i].get('no_user_details'),
    //       "roles":collaborators[i].get('role'),
    //       "id": collaborators[i].id,
    //       "title": projectTitle,
    //       "projectId": this.state.projectID
    //     });
    //   }
    // }

    return (
      <div className="page auction-page">
        {this.state.reportInProgress ?
          <p className='warning-reportInProgress__auction-page'>Report in
            progress</p> : false
        }
        <div className="pageContent projectPage">
          {/*<div className="mainHeader">
              <p className="mainTitle">Bed Romance</p>
              <p className="mainTitle">- GaGe "Singer, actor"</p>
              <div className="tokenSummarySiteWrap">
              {/*<div className="cHeader">
                  <p className="title">Join Our Sale Now</p>
                </div>
                <div className="cBody">
                  <TokenSummary />
                </div>
              </div>
              {/*<a onClick={this.handleWatchVideo} className="waTchVideoBttn">Watch Video</a>
              <Link className="getStartedBttn" to="/register">
                Buy Gage Coins
              </Link>
            </div>
          */}

          {/*<div className="fnbXTokenAnythingWrap card6">*/}
          <div
            className="leftSideModuleWrap presaleWrapper projectPage__contentBody"
            style={{paddingBottom: "80px"}}
          >
            {/*<div className="rightSideModule" style={{"width":"70%","margin":"0 auto","float":"none","paddingRight":"0","textAlign":"center"}}>
                <img className="fanbaseGraph" />
                <table className="saleTable mainTable">
									<tbody>
										<tr className="saleTablerow mainTablerow">
                      <th>Tier</th>
                      <th>Issued Tokens</th>
                      <th>Bonus</th>
                      <th>Total</th>
                    </tr>
									  <tr className="saleTablerow mainTablerow">
											<td><p className="tiersTitle">Presale</p></td>
											<td><p className="amountCoinsValue">2,000,000,000 USDW</p></td>
											<td><p className="amountCoinsValueDescription">100%</p></td>
											<td><p className="amountCoinsValue">4,000,000,000 USDW</p></td>
									  </tr>
								  </tbody>
							  </table>
              </div>
            */}

            {/*  Projects page Start*/}
            <ImagesLayout isOwner={isOwnerOfTheProject} projectID={projectID}
                          bannerImages={this.state.bannerImages}/>

            <div className="project-page leftSideModule">
              <div className="section-input-wrap">
                <label htmlFor="limitedLicense"
                       style={{"display": "inline-block", "width": "570px", "verticalAlign": "middle"}}>
                  {this.state.editState === "1" ?
                    <div className="editBlock--alignedCenter">
                      <div className="editBlock__button">
                        <button className="editBtn editBtn--withoutBorder"
                                onClick={() => this.updateProjectData('projectTitle', this.state.projectTitle)}
                        >Update
                        </button>
                      </div>
                      <div className="editBlock__input">
                        {/* <p className="s-input-title" style={{"fontSize":"40px"}}> Title:</p> */}
                        <input defaultValue={projectTitle} style={{marginBottom: '10px'}}
                               maxLength="100" type="text"
                               className="editBox" placeholder={projectTitle}
                               onChange={(e) => this.handleInput('projectTitle', e.target.value)}
                        />
                      </div>

                    </div>
                    :
                    <div className="editBlock--alignedCenter">
                      <div className="editBlock__button">
                        {
                          isOwnerOfTheProject &&
                          (<button className="editBtn editBtn--withoutBorder"
                                   onClick={() => this.setState({editState: "1"})}>
                            Edit
                          </button>)
                        }
                      </div>
                      <p className="editBlock__text s-input-title text-center"
                         style={{"fontSize": "40px"}}>
                        {projectTitle} {/* Title: Fanboy- Baseship */}
                      </p>
                    </div>
                  }

                  {this.state.editState === "2" ?
                    <div className="editBlock--alignedCenter">
                      <div className="editBlock__button">
                        <button className="editBtn editBtn--withoutBorder"
                                onClick={() => this.updateProjectData('projectSubtitle', this.state.projectSubtitle)}
                        >Update
                        </button>
                      </div>
                      <div className="editBlock__input">
                        {/* <p className="s-input-title" style={{"fontSize":"25px"}}> Tag Line:</p> */}
                        <input defaultValue={projectSubtitle} maxLength="100" type="text"
                               className="editBox" placeholder={projectSubtitle}
                               onChange={(e) => this.handleInput('projectSubtitle', e.target.value)}
                        />
                      </div>

                    </div>
                    :
                    <div className="editBlock--alignedCenter">
                      <div className="editBlock__button">
                        {
                          isOwnerOfTheProject &&
                          (<button className="editBtn editBtn--withoutBorder"
                                   onClick={() => this.setState({editState: "2"})}>
                            Edit
                          </button>)
                        }
                      </div>
                      <div className="editBlock__text col-sm-12 p-0 text-center"
                           style={{fontStyle: 'italic'}}>
                        <p className="s-input-descr" style={{"fontSize": "25px"}}>
                          {projectSubtitle}
                        </p>  {/* Tag Line: Climb Aboard My Baseship */}
                      </div>

                    </div>
                  }

                  {stageName ?
                    <div className="text-center"><p className="s-input-descr"
                                                    style={{"fontSize": "25px"}}>by {stageName}</p>
                    </div>
                    :
                    <div className="text-center"><p className="s-input-descr"
                                                    style={{"fontSize": "25px"}}>by {fName} {lName}</p>
                    </div>  // {/* Fanboy */}
                  }
                </label>
              </div>

              <div className="section-input-wrap">
                <label htmlFor="limitedLicense"
                       style={{"display": "inline-block", "width": "570px", "verticalAlign": "middle"}}>
                  <p className="s-input-title ticker-title" style={{"fontSize": "18px"}}>Ticker
                    <span style={{position: "relative"}}>
                                            {
                                              isOwnerOfTheProject && <span className="changeLogoBlock"
                                                                           onClick={this.toggleLogoMaker}>Edit</span>
                                            }
                      {logoAttach[0] &&
                      <img src={prepareImagesPathForMinify(logoAttach[0], 80, 80)}
                           className="logoImage" alt='logo of project'/>}
                                        </span>
                  </p>
                  <p className="s-input-descr"
                     style={{"fontSize": "15px"}}>{tickerSymbol}</p>   {/* FANBOY1 */}

                </label>
                {
                  isLogoMakerOpen &&
                  <ModalWindow title={"The Logo Maker"} hideModal={this.toggleLogoMaker}>
                    <LogoMaker insertLogo={this.saveProjectLogo}
                               allowToUploadPhoto={true}/>
                  </ModalWindow>
                }
              </div>

              <div className="section-input-wrap">
                <label htmlFor="limitedLicense"
                       style={{"display": "inline-block", "width": "570px", "verticalAlign": "middle"}}>
                  <p className="s-input-title" style={{"fontSize": "18px"}}>
                    <i className=""/>

                    Project Status
                  </p>

                  <p className="s-input-descr"
                     style={{"fontSize": "15px", "textAlign": "justify"}}>
                    This collectible item is designated as a <i>{rookieRank}</i> that is produced,
                    sellable and
                    ready for auction. The item <i className="" style={{"color": "#228b22"}}> </i>
                    is presented in <i>{mainLanguage}</i> for an age range described
                    as <i>{ageRestrictions}</i>.
                    There
                    will <i>{(additionalLanguage === "Unidentified" || additionalLanguage === undefined) ? 'not' : ''}</i>

                    be
                    additional <i>{(additionalLanguage === "Unidentified" || additionalLanguage === undefined) ?
                    ' ' : additionalLanguage.map((language, idx, currArr) => {
                      let elAfterLanguage = idx >= currArr.length - 2 ? '' : ',';
                      let elBeforeLanguage = idx === currArr.length - 1 ? 'and ' : '';

                      if (currArr.length < 2) {
                        elAfterLanguage = '';
                        elBeforeLanguage = '';
                      }

                      return ` ${elBeforeLanguage}${language}${elAfterLanguage} `;
                    })
                  }</i> language versions. </p>
                </label>
              </div>

              <div className="section-input-wrap">
                <label htmlFor="limitedLicense"
                       style={{"display": "inline-block", "width": "570px", "verticalAlign": "middle"}}>
                  <p className="s-input-title" style={{"fontSize": "18px"}}>Terms
                    of Auction</p>
                  <p className="s-input-descr"
                     style={{"fontSize": "15px", "textAlign": "justify"}}>
                    This is a limited edition collector's item from
                    the <i>{mediaCategory}</i> category.
                    This is the auction of the
                    {lastAuction ? ` ${lastAuction.auctionId} ` : null}
                    license of this collectible
                    item
                    and there are {auctionsCount} total licenses worldwide,
                    with the terms of the auction stating that the <i>{auctionTime}</i>.</p>
                </label>
              </div>

              <br/><br/><br/>
              <hr/>

              <div className="section-input-wrap">
                <label htmlFor="limitedLicense"
                       style={{"display": "inline-block", "width": "570px", "verticalAlign": "middle"}}>
                  {this.state.showDescription
                    ? this.state.editState === "3"
                      ? <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="s-input-title"
                             style={{"fontSize": "15px"}}>Description</p>
                          <button className="updateBtn editBtn editBtn--withoutBorder"
                                  onClick={() => this.updateProjectData('projectDescription', this.state.projectDescription)}>
                            Update
                          </button>
                        </div>
                        <p className="s-input-descr"
                           style={{"fontSize": "15px", "textAlign": "justify"}}>
                                                <textarea defaultValue={projectDescription} maxLength="1000" rows="5"
                                                          placeholder={projectDescription}
                                                          onChange={(e) => this.handleInput('projectDescription', e.target.value)}
                                                />
                        </p>
                      </div>
                      : <div>
                        <div className="d-flex align-items-center justify-content-between"
                             style={{position: "relative"}}>
                          <p className="s-input-title"
                             style={{"fontSize": "15px"}}>Description</p>
                          {
                            isOwnerOfTheProject &&
                            (<button className="editBtn editBtn--withoutBorder"
                                     onClick={() => this.setState({editState: "3"})}>
                              Edit
                            </button>)
                          }
                          {
                            !isOwnerOfTheProject && projectDescription.length > 0 ?
                              (<button className="editBtn editBtn--withoutBorder"
                                       onClick={() => this.showArea('description')}>
                                Report
                              </button>) : false
                          }
                        </div>
                        <p className="s-input-descr description__block"
                           style={{"fontSize": "15px", "textAlign": "justify"}}>
                          {projectDescription}
                        </p>
                        {this.state.descriptionWarning === true ?
                          <p className='warningLinkMessage'>Received a complaint, correct the
                            description</p> : false
                        }
                        <div
                          className={this.state.isReportDescription ? 'report__block-on' : 'report__block-off'}>
                                                            <textarea id='description' onBlur={this.getReportMessage}
                                                                      className={this.state.isReportDescription ? 'report__block-on' : 'report__block-off'}/>
                          <button onClick={() => {
                            this.submitReport(
                              this.state.reportMessageDescription,
                              'isReportDescription',
                              'isSubmitDescription'
                            )
                          }}
                                  className={this.state.isReportDescription ? 'blue-button submitReport__button report__block-on' : 'blue-button submitReport__button report__block-off'}
                          >
                            Submit
                          </button>
                        </div>
                        <p
                          className={this.state.isSubmitDescription ? 'afterSubmit-message report__block-on' : 'afterSubmit-message report__block-off'}>
                          Thanks for the info!
                        </p>
                      </div>
                    : <p className='banned-message'>project description banned</p>
                  }
                </label>
              </div>

              <br/><br/>
              <hr/>

              <div className="section-input-wrap">
                <label htmlFor="limitedLicense"
                       style={{"display": "inline-block", "width": "570px", "verticalAlign": "middle"}}>
                  {this.state.showPerks
                    ? this.state.editState === "4"
                      ? <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="s-input-title" style={{"fontSize": "15px"}}>
                            Special Notes </p>
                          <button className="updateBtn editBtn editBtn--withoutBorder"
                                  onClick={() => this.updateProjectData('projectPerks', this.state.projectPerks)}
                          >Update
                          </button>
                        </div>
                        <textarea defaultValue={projectPerks} placeholder={projectPerks}
                                  rows="5"
                                  maxLength="1000"
                                  onChange={(e) => this.handleInput('projectPerks', e.target.value)}
                        />
                      </div>
                      : <div>
                        <div className="d-flex align-items-center justify-content-between"
                             style={{position: "relative"}}>
                          <p className="s-input-title" style={{"fontSize": "15px"}}>
                            Project Perks </p>
                          {
                            isOwnerOfTheProject &&
                            (<button className="editBtn editBtn--withoutBorder"
                                     onClick={() => this.setState({editState: "4"})}>
                              Edit
                            </button>)
                          }
                          {
                            !isOwnerOfTheProject && projectPerks.length > 0 ?
                              (<button className="editBtn editBtn--withoutBorder"
                                       onClick={() => this.showArea('perks')}>
                                Report
                              </button>) : false
                          }
                        </div>
                        <p className="s-input-descr "
                           style={{
                             "fontSize": "15px",
                             "textAlign": "justify",
                             marginBottom: "20px"
                           }}>
                          {projectPerks}
                        </p>
                        {this.state.perksWarning === true ?
                          <p className='warningLinkMessage'>Received a complaint, correct the
                            project perks</p> : false
                        }
                        <div
                          className={this.state.isReportPerks ? 'report__block-on' : 'report__block-off'}>
                                                            <textarea id='perks' onBlur={this.getReportMessage}
                                                                      className={this.state.isReportPerks ? 'report__block-on' : 'report__block-off'}/>
                          <button onClick={() => {
                            this.submitReport(
                              this.state.reportMessagePerks,
                              'isReportPerks',
                              'isSubmitPerks'
                            )
                          }}
                                  className={this.state.isReportPerks ? 'blue-button submitReport__button report__block-on' : 'blue-button submitReport__button report__block-off'}
                          >
                            Submit
                          </button>
                        </div>
                        <p
                          className={this.state.isSubmitPerks ? 'afterSubmit-message report__block-on' : 'afterSubmit-message report__block-off'}>
                          Thanks for the info!
                        </p>
                      </div>
                    : <p className='banned-message'>project perks banned</p>
                  }
                </label>
              </div>

              <br/><br/>
              <hr/>

              <div className="section-input-wrap">
                <p className="s-input-title" style={{"fontSize": "15px"}}>Additional Data</p>
                <DropDownList additionalData={additionalData}
                              mediaCategory={mediaCategory}/>
              </div>

              <hr/>

              <div className="section-input-wrap">
                <label htmlFor="limitedLicense"
                       style={{"display": "inline-block", "width": "570px", "verticalAlign": "middle"}}>
                  <div>
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="s-input-title" style={{"fontSize": "15px"}}>
                        Additional Attachments
                      </p>
                    </div>
                    {this.state.attachName ?
                      <div className="attachments__block">
                        <p>{this.state.attachNameFile}</p>
                        <button onClick={() => this.openViewer()}
                                className="editBtn editBtn--withoutBorder view-attach__btn">
                          View
                        </button>

                        {openFileViewer &&
                        <ShowProjectMediaFile itemsData={this.state.itemsData}
                                              onClose={this.onFileViewerClose}
                                              tab="project"
                        />}

                        {isOwnerOfTheProject &&
                        (<button className="editBtn editBtn--withoutBorder edit-attach__btn"
                                 onClick={this.openInput}>
                          Edit file name
                        </button>)}

                        {this.state.attachInput ?
                          <div className="attach-name__block">
                            <input
                              defaultValue={this.state.attachName.split('.')[0]}
                              ref={this.inputRef}
                              onChange={this.validateInput}
                            />
                            <button className="pull-right viewallA attach_save"
                                    onClick={() => this.saveAttachName(this.inputRef)}
                            >
                              save
                            </button>
                          </div>
                          : null
                        }
                        {/*                                                {
                                                    !isOwnerOfTheProject && projectDescription.length > 0 ?
                                                        (<button className="editBtn editBtn--withoutBorder"
                                                                 onClick={() => this.showArea('attachments')}>
                                                            Report
                                                        </button>) : false
                                                }*/}
                      </div>
                      : <UploadAttachTOIpfs setAttachItem={this.setAttachItem}
                                            attachItem={this.state.attachItem}
                                            attachFileType={this.state.attachFileType}
                                            name={this.state.attachFileName}
                      />
                    }
                  </div>
                </label>
              </div>
            </div>

            {/*  Projects page End*/}


            <div className="leftSideModule moduleInvestForm">
              {/* <AuctionCard ladsfastAuction={lastAuction} /> */}
              {activeAuctions &&
              <AuctionCard activeAuctions={activeAuctions}  getAuctionLisences={this.getAuctionLisences} projectID={projectID} />
              }
              {this.renderLastAuction()}
              {this.state.lotExist &&
                // nextAuctions &&
                <div className="cBody auctions_flex">
                  <p className="s-input-title" style={{"fontSize": "18px"}}>
                    <label  style={{paddingRight: '10px'}}>Licenses of this project are also sold in lots,
                      <Link to={`/projectLot/${this.state.projectId}`}  style={{paddingRight: '10px'}}> see now!</Link>
                    </label>
                  </p>
                  {/*{nextAuctions.map((nextAuction) =>*/}
                  <div className="s-input-descr addUserList__item  auctions_flex-item"
                       style={{"fontSize": "15px"}}>
                    <p>
                      {/*{nextAuction.tickerSymbol}*/}
                    </p>
                    <p>
                      {/*{nextAuction.auctionId}*/}
                    </p>
                    {/*<p> {formatDateToMDY(nextAuction.dateTill)}</p>*/}
                  </div>
                  {/*)}*/}
                </div>
              }

              {/*<div className="card" >*/}
              {/*-4  <div className="cBody exchangeDetails">*/}
              {/*    <p className="initialTitle">You are bidding on...</p>*/}
              {/*    <p className="amountofFnb">{tickerSymbol}</p>/!* {calculateFnbAmount(prop,"t")}*!/*/}
              {/*    <p className="exchangeRate">#1 of {numOfLicenses}</p>*/}
              {/*    <hr />*/}
              {/*    <div className="bodyWrap">*/}
              {/*      <div className="centerLineWrap">*/}
              {/*        <div className="centerLine"></div>*/}
              {/*        <div className="centerCircles">*/}
              {/*          <div className="leftCircle">*/}
              {/*          */}
              {/*          </div>*/}
              {/*          <div className="leftCircle">*/}
              {/*          */}
              {/*          </div>*/}
              {/*          <div className="leftCircle">*/}
              {/*          */}
              {/*          </div>*/}
              {/*          <div className="leftCircle">*/}
              {/*          */}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="ExchangeDetailsWrap">*/}
              {/*        <ul>*/}
              {/*          <li>*/}
              {/*            <p className="title">Current Bid</p>*/}
              {/*            <p className="subtitle"> 1500 FNBD/USD</p>*/}
              {/*            <hr />*/}
              {/*          </li>*/}
              {/*          <li>*/}
              {/*            <p className="title">Your Bid</p>*/}
              {/*            <p className="subtitle" id="totalFnbAMount">2000 FNBD/USD</p>*/}
              {/*            <hr />*/}
              {/*          </li>*/}
              {/*          <li>*/}
              {/*            <p className="title">Auction Closes</p>*/}
              {/*            <p className="subtitle">09/23/19 @ 10am ET</p>*/}
              {/*            <hr />*/}
              {/*          </li>*/}
              {/*          <li>*/}
              {/*            <p className="title">Item ID</p>*/}
              {/*            <p className="subtitle">#1</p>*/}
              {/*          </li>*/}
              {/*          */}
              {/*        </ul>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <hr style={{"margin":"0","padding":"0","paddingBottom":"10px"}} />*/}
              {/*    <div className="finalFeeWrap">*/}
              {/*      <span className="pull-left title">Subtotal</span>*/}
              {/*      <span className="pull-right title"></span>*/}
              {/*      <div className="verticalLine"></div>*/}
              {/*    </div>*/}
              {/*    <div className="finalFeeWrap">*/}
              {/*      <span className="pull-left title">Transaction Fee (1%)</span>*/}
              {/*      <span className="pull-right title"></span>*/}
              {/*      <div className="verticalLine"></div>*/}
              {/*    </div>*/}
              {/*    <div className="finalFeeWrap">*/}
              {/*      <span className="pull-left title">Total</span>*/}
              {/*      <span className="pull-right title"></span>*/}
              {/*      <div className="verticalLine"></div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*  <p className="mini-amount"> Authori
            ze Bid</p>*/}
              {/*</div>*/}

              <div className="principalsAndCollaborators">
                <div className="cBody principalsAndCollaboratorsDetails">
                  <AddUserListContainer userList={principals}
                                        listType="principal"
                                        title="Principals"
                                        isOwnerOfTheProject={isOwnerOfTheProject}
                                        projectId={this.state.projectId}
                                        projectTitle={this.state.projectTitle}

                  />
                </div>
              </div>
              <div className="principalsAndCollaborators">
                <div className="cBody principalsAndCollaboratorsDetails">
                  <AddUserListContainer userList={collaborators}
                                        listType="collaborator"
                                        title="Collaborators"
                                        isOwnerOfTheProject={isOwnerOfTheProject}
                                        projectId={this.state.projectId}
                                        projectTitle={this.state.projectTitle}
                  />
                </div>
              </div>

              {/* <p className="title">Project Details</p>
                <p className="subtitle">Working Title: </p>
                <p className="subtitle">Tag Line: </p>
                <p className="subtitle">Promotional Image: </p>
                <p className="subtitle">Attach Promotional Image: </p>
                <p className="subtitle">Description: </p>
                <p className="subtitle">Video Pitch Thumbnail: </p>
                <p className="subtitle">Video Pitch: </p>
                <p className="subtitle">Video Promo Thumbnail: </p>
                <p className="subtitle">Additional Attachments: </p>
                <p className="subtitle">Additional Links: </p>
                <p className="subtitle">Social Media: </p>
                <p className="subtitle">Project Website: </p>
                <br />
              */}
              {/*<div className="presaleContainer">
                  <b>Release Date</b>
                  <span>March 4th, 2018</span>
                </div>
                <hr />
                <div className="presaleContainer">
                  <b>End Date:</b>
                  <span>July 4th, 2018</span>
                </div>
                <br /><br />
                <br /><br />
                <Link className="getStartedBttn" to="/register">Claim $10 in USDW</Link>*/}
            </div>
            <br style={{clear: "both"}}/>
          </div>

          {/*                    <div
                        className="leftSideModuleWrap presaleWrapper"
                        style={{paddingBottom: "80px"}}
                    >
                        <div
                            className="rightSideModule"
                            style={{
                                width: "70%",
                                margin: "0 auto",
                                float: "none",
                                paddingRight: "0"
                            }}
                        >
                            <table className="saleTable mainTable">
                                <tbody>
                                <tr className="saleTablerow mainTablerow">
                                    <th>Tier</th>
                                    <th>Issued Tokens</th>
                                    <th>Bonus</th>
                                    <th>Total</th>
                                </tr>
                                <tr className="saleTablerow mainTablerow">
                                    <td><p className="tiersTitle">11</p></td>
                                    <td><p className="amountCoinsValue">691,200,000 FNBD</p></td>
                                    <td><p className="amountCoinsValueDescription">4%</p></td>
                                    <td><p className="amountCoinsValue">718,848,000 FNBD</p></td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                        <Slider/>

                        <div className="leftSideModule" style={{"width":"40%","margin":"0 auto","float":"none","padding":"0","paddingTop":"70px"}}>
							<p className="title">Related Project</p>
							<p className="subtitle" style={{"lineHeight":"25px"}}>Working Title: (Must Login to pitch a project)</p>
							<br />
							<div className="presaleContainer">
								<b>Start Date:</b>
								<span>July 4th, 2018</span>
							</div>
							<hr />
							<div className="presaleContainer">
								<b>End Date:</b>
								<span>January 4th, 2019</span>
							</div>
						</div>
                        <br style={{clear: "both"}}/>
                    </div>*/}
        </div>
        {/*<EndWrapper />*/}
      </div>
    );
  }
}
