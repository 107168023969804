import React, {Component, Fragment} from 'react';
import Parse from 'parse';
import paginationLinksFormat from '../../../../utils/paginationLinksFormat';
import SettingsPageHeader from '../../../Components/SettingsPageHeader';
import getFullDate from '../../../../utils/getFullDate';
import DateInputs from './DateInputs';
import {sendParseRequest} from "../../../../utils/sendParseRequest";

export default class ActivityPage extends Component {
    state = {
        accountActivityPage: 0,
        accountActivityTotal: 7,
        webSessionsPage: 0,
        webSessionsTotal: 0,
        webSessions: [],
        sessionData: [],
        queryLimit: 10,
        accountActivityFilter: {
            dateTo: new Date(),
            dateFrom: new Date(Date.now() - 6.048e+8)
        },
    }

    componentDidMount() {
        document.title = "Activity – FNBD";

        const {
            accountActivityPage,
            webSessionsPage,
            accountActivityFilter
        } = this.state;

        this.loadFSession(accountActivityPage, accountActivityFilter);
        this.loadWebSession(webSessionsPage);
    }

    loadFSession = (pageNumber, accountActivityFilter) => {
        this.setState({accountActivityPage: pageNumber});

        const {dateTo, dateFrom} = accountActivityFilter;

        const userPointer = {
            "__type": 'Pointer',
            "className": '_User',
            "objectId": Parse.User.current().id
        };

        const searchData = {
            dateTo,
            dateFrom,
            userPointer,
            limit: this.state.queryLimit,
            query: 'FSession',
            pageNumber,
            descending: 'createdAt',
            lessThan: {field: 'createdAt', value: dateTo},
            greaterThanOrEqualTo: {field: 'createdAt', value: dateFrom},
        };

        sendParseRequest('getUserSessionOrFSession', {searchData})
            .then(res => {
                this.setState({
                    sessionData: res.results,
                    accountActivityTotal: Math.round(res.count / this.state.queryLimit),
                })
            })
    };

    loadWebSession = (pageNumber) => {
        this.setState({webSessionsPage: pageNumber});

        const userPointer = {
            "__type": 'Pointer',
            "className": '_User',
            "objectId": Parse.User.current().id
        };

        const searchData = {
            userPointer,
            limit: this.state.queryLimit,
            query: '_Session',
            pageNumber,
        };

        sendParseRequest('getUserSessionOrFSession', {searchData})
            .then(res => {
                this.setState({
                    webSessions: res.results,
                    webSessionsTotal: Math.round(res.count / this.state.queryLimit),
                })
            })
    };

    getCellForActivity = (rowItem) => {
        let createdAt = null;
        let browser = null;
        let ipAddress = null;
        let near = null;
        let isCurrent = null;

        if (rowItem) {
            createdAt = rowItem.get('createdAt').toString();
            browser = rowItem.get('browser');
            ipAddress = rowItem.get('ipAddress');
            near = rowItem.get('near');
            isCurrent = <i className="fa fa-check"/>;
        }

        return (
            <Fragment>
                <td>{createdAt}</td>
                <td>{browser}</td>
                <td>{ipAddress}</td>
                <td>{near}</td>
                <td>{isCurrent}</td>
            </Fragment>
        );
    };

    getCellForWebSession = (rowItem) => {
        let description = null;
        let type = null;
        let browser = null;
        let country = null;
        let ipAddress = null;
        let createdAt = null;

        if (rowItem) {
            description = rowItem.get('description');
            type = rowItem.get('type');
            browser = rowItem.get('Browser');
            country = rowItem.get('Country');
            ipAddress = rowItem.get('ipAddress');
            createdAt = getFullDate(rowItem.get('createdAt').toString());
        }

        return (
            <Fragment>
                <td>{description}</td>
                <td>{type}</td>
                <td>{browser}</td>
                <td>{country}</td>
                <td>{ipAddress}</td>
                <td>{createdAt}</td>
            </Fragment>
        );
    };

    getRowsForTable = (dataForRows, pageNumber, getCell) => {
        const {queryLimit} = this.state;
        const rowsForWebSessionTable = [];
        const numberOfFirstRowInTable = ((pageNumber * queryLimit) + 1);

        for (let i = 0; i < queryLimit && dataForRows[i] !== undefined; i++) {
            const rowItem = dataForRows[i];

            rowsForWebSessionTable.push(
                <tr key={`table_row_key_${i}`}>
                    <td>{numberOfFirstRowInTable + i}</td>

                    {getCell(rowItem)}
                </tr>
            );
        }

        return rowsForWebSessionTable;
    };

    updateDateFilterForAccountActivity = (filterData) => {
        const {accountActivityPage} = this.state;

        this.setState(({accountActivityFilter: prevAccountActivityFilter}) => {
            const newAccountActivityFilter = {
                ...prevAccountActivityFilter,
                ...filterData
            };

            this.loadFSession(accountActivityPage, newAccountActivityFilter);

            return ({accountActivityFilter: newAccountActivityFilter});
        });
    };

    render() {
        const {
            webSessionsTotal,
            webSessionsPage,
            accountActivityTotal,
            accountActivityPage,
            webSessions,
            sessionData,
            accountActivityFilter
        } = this.state;

        console.log("state", this.state)

        return (
            <div className="page settingsPage" style={{height: 'auto'}}>
                <div className="dashWrapperB" style={{marginBottom: '20px'}}>
                    <div className="dashModal seperator" style={{"width": "10%"}}/>
                    <div className="dashModal contentPage" style={{"width": "80%"}}>
                        <div className="content " style={{"height": "auto"}}>
                            <SettingsPageHeader/>
                            <div className="settingsWrapper Preferences">
                                <p style={{"border": "0"}} className="title">Web Sessions</p>
                                <p style={{"marginBottom": "20px"}} className="description">
                                    These sessions are currently signed in to your account.
                                    {/*<a onClick={(e)=> this.removeAllSessions({"type":"All"})} className="pull-right" style={{"cursor":"pointer","color":"blue"}}>Sign out all other sessions</a>*/}
                                </p>
                                <div className="dashTableWrap accountActivityTableSettings">
                                    <hr style={{"margin": "0", "padding": "0"}}/>
                                    <div className="accountActivityTableSettings__table">
                                        <table className="dashTable">
                                            <tbody>
                                            <tr className="dashTabHeader">
                                                <th>S.no</th>
                                                <th>Signed in</th>
                                                <th>Browser</th>
                                                <th>IP Address</th>
                                                <th>Near</th>
                                                <th>Current</th>
                                                {/*<th>Action</th>*/}
                                            </tr>
                                            {this.getRowsForTable(webSessions, webSessionsPage, this.getCellForActivity)}
                                            </tbody>
                                        </table>
                                    </div>
                                    {webSessionsTotal !== 0 &&
                                    <Pagination totalPage={webSessionsTotal}
                                                paginationCssClass="accountActivityTableSettings__pagination"
                                                currPage={webSessionsPage}
                                                onChangePage={this.loadWebSession}/>
                                    }
                                </div>
                                <br/><br/>
                                <p style={{"border": "0"}} className="title">Account Activity</p>
                                <p style={{"marginBottom": "20px"}} className="description">
                                    Recent activity on your account.
                                </p>
                                <div className="dashTableWrap accountActivityTableSettings">
                                    <hr style={{"margin": "0", "padding": "0"}}/>
                                    <div className="accountActivityTableSettings__filters">
                                        <DateInputs inputDateHandler={this.updateDateFilterForAccountActivity}/>
                                    </div>
                                    <div className="accountActivityTableSettings__table">
                                        <table className="dashTable">
                                            <tbody>
                                            <tr className="dashTabHeader">
                                                <th>S.no</th>
                                                <th>Action</th>
                                                <th>Authenticator</th>
                                                <th>Browser</th>
                                                <th>Country</th>
                                                <th>IP Address</th>
                                                <th>When</th>
                                            </tr>

                                            {this.getRowsForTable(sessionData, accountActivityPage, this.getCellForWebSession)}
                                            </tbody>
                                        </table>
                                    </div>
                                    {accountActivityTotal !== 0 &&
                                    <Pagination totalPage={accountActivityTotal}
                                                paginationCssClass="accountActivityTableSettings__pagination"
                                                currPage={accountActivityPage}
                                                filters={accountActivityFilter}
                                                onChangePage={this.loadFSession}/>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashModal seperator" style={{"width": "10%"}}/>
                    <br style={{"clear": "both"}}/>
                </div>
            </div>
        )
    }
}

const Pagination = ({totalPage, currPage, onChangePage, paginationCssClass, filters}) => {
    let paginationLinks = [];

    for (let i = 0; i < totalPage; i++) {
        paginationLinks.push(
            <li onClick={() => onChangePage(i, filters)} className={currPage === i ? "active" : ""}
                key={`settingsPage-pagination-key-${i}`}>{i + 1}</li>
        );
    }

    return (
        <div style={{"paddingRight": "0px"}}
             className={paginationCssClass}>
            <span colSpan="2">
                Showing {currPage + 1} of {totalPage} pages
             </span>
            <div className="bttmFooterPaginate" colSpan="4">
                <ul>
                    {currPage > 0
                        ? <li onClick={() => onChangePage(currPage - 1, filters)}>Previous</li>
                        : <li className="disabled">Previous</li>
                    }
                    {paginationLinksFormat(currPage, paginationLinks, 8)}
                    {currPage >= 0 && currPage + 1 !== totalPage
                        ? <li onClick={() => onChangePage(currPage + 1, filters)}>Next</li>
                        : <li className="disabled">Next</li>
                    }
                </ul>
            </div>
        </div>
    );
};
