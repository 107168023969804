import React from "react";

export class TagInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "tags": [],
      "filtered": [],
      "inputValue": "",
      "chosenTags": [],
      "link": '',
    }
    this.tagDropDown = React.createRef();
  };



  componentDidMount() {
    const {isSingleSelect, data} = this.props;
    // console.log(data, "--------------data")
    if (isSingleSelect) {
      this.setState({
        chosenTags: [data[0]],
        tags: data
      });
      return;
    }

    this.setState({tags: data});
  };

  componentWillReceiveProps({chosenData, isSingleSelect, data}) {
    console.log(this.props.type, "--------------this.props.type")
    this.setState({tags: data})

    if (this.state.chosenTags !== chosenData) {
      const {tags} = this.state;
      const {defaultValue} = this.props;
      let newChosenData = chosenData;

      if (isSingleSelect) {
        newChosenData = [newChosenData === defaultValue ? tags[0] : newChosenData];
      }
      this.setState({"chosenTags": newChosenData, tags: data});
    }
  };

   isValidUrl=(url) =>{
    let RegExp = /^((ftp|http|https):\/\/)?(www\.)?([A-Za-zА-Яа-я0-9]{1}[A-Za-zА-Яа-я0-9\-]*\.?)*\.{1}[A-Za-zА-Яа-я0-9-]{2,8}(\/([\w#!:.?+=&%@!\-\/])*)?/;

    if(RegExp.test(url)){    this.props.updateSocial(url);}

  };



  handleChange = (e) => {
    const value = e.target.value;
    const data = this.state.tags;
    const filtered = data.filter((e) => {
      return e.toLowerCase().startsWith(value.toLowerCase());
    })
    if (filtered.length > 0) {
      this.tagDropDown.current.classList.add('visible')
    } else {
      this.tagDropDown.current.classList.remove('visible')
    }
    if (value === "") {
      this.tagDropDown.current.classList.remove('visible')
    }

    this.setState({"inputValue": value, filtered});
  };

  addTag = (value) => {
    this.tagDropDown.current.previousElementSibling.classList.remove('fieldIsNotValid');

    const {
      isSingleSelect,
      maxSize,
      onChange,
    } = this.props;

    const {chosenTags} = this.state;

    if (maxSize > chosenTags.length && !value.startsWith('none,')) {
      let newTags = isSingleSelect ? [value] : [...chosenTags, value];
      // const newValue = tags[0] === value ? defaultValue : value;
      this.setState({chosenTags: newTags});

      onChange(isSingleSelect ? value : newTags)
    }
  };

  removeTag = (value) => {
    const chosenTags = this.state.chosenTags;
    const index = chosenTags.indexOf(value);

    if (index >= 0) {
      chosenTags.splice(index, 1);
      this.setState({chosenTags});
      this.props.onChange(chosenTags)
    }
  };

  render() {
    const {
      refName,
      placeholder,
      isRequired,
      inputName,
      isSingleSelect,
      valueForCheck,
      action,
      type,
      chosenData
    } = this.props;

    let chosenTags;

    if (type === 'walletAddress' || type === 'socials') {
      chosenTags = chosenData;
    } else {
      if (chosenData === '0%' && type!=="royalty") {
        chosenTags = ['None, this project is market-ready']
      } else {
        chosenTags = this.state.chosenTags;
      }
    }

    return (
      <div className="tag-input-wrap ">
        {isSingleSelect ? null :
          <div className="chosen-tags-wrap">

            <>
              {chosenTags && chosenTags.length > 0 && chosenTags.map((e, i) => {
                return (<span onClick={() => this.removeTag(e)} key={i}>{e}</span>)
              })}
            </>
          </div>
        }

        {
          this.props.type === 'socials' ?
            <><input placeholder='https://www...' onChange={(e) => this.setState({link: e.target.value})}
                     value={this.state.link}/>
              <a onClick={() => {
                this.isValidUrl(this.state.link);
                this.setState({link: ''})
              }}
                 className={"add-collab-bttn"}>
                <svg width="20" height="20" viewBox="0 0 24 24">
                  <path
                    d="M14.8284 12l4.2427 4.2426c.781.781.781 2.0474 0 2.8285-.781.781-2.0474.781-2.8285 0L12 14.8284l-4.2426 4.2427c-.781.781-2.0474.781-2.8285 0-.781-.781-.781-2.0474 0-2.8285L9.1716 12 4.9289 7.7574c-.781-.781-.781-2.0474 0-2.8285.781-.781 2.0474-.781 2.8285 0L12 9.1716l4.2426-4.2427c.781-.781 2.0474-.781 2.8285 0 .781.781.781 2.0474 0 2.8285L14.8284 12z"
                    fillRule="evenodd"/>
                </svg>
              </a>
            </>

            : <> <input ref={refName}
                        name={inputName}
                        data-isrequiredfield={isRequired}
                        onFocus={() => this.tagDropDown.current.classList.add('visible')}
                        onBlur={() => this.tagDropDown.current.classList.remove('visible')}
                        onChange={e => this.handleChange(e)}
                        defaultValue={isSingleSelect ? chosenTags[0] : this.state.inputValue}
                        type="text"
                        data-valueforcheck={`${valueForCheck}`}
                        data-action={action}
                        placeholder={placeholder}
                        className='withArrow wallets-input'
                        autoComplete="off"
                        disabled={this.props.disabled}
            />
              <div ref={this.tagDropDown} className="tags-all-dropdown wallets-dropdown">
                <ul>
                  {this.state.filtered.length > 0
                    ? this.state.filtered.map((e, i) => {
                      if (!chosenTags.includes(e) && !isSingleSelect) {
                        return (<li onClick={() => this.addTag(e)} key={i}>{e}</li>)
                      } else if (isSingleSelect) {
                        return (<li onClick={() => this.addTag(e)} key={i}>{e}</li>)
                      }
                    })
                    : this.state.tags.map((e, i) => {
                      if (!chosenTags.includes(e) && !isSingleSelect) {
                        return (<li onClick={() => this.addTag(e)} key={i}>{e}</li>)
                      } else if (isSingleSelect) {
                        return (<li onClick={() => this.addTag(e)} key={i}>{e}</li>)
                      }
                    })
                  }
                </ul>
              </div>
            </>
        }

      </div>
    )
  }
}
