import React from "react";

import {Loader} from "../Loader";
import EthApi from "../../api/tendermint";
import {connect} from "react-redux";
import ethClient from "../../eth_client";
import {amountInTIX} from "../../eth_client/helper";
import {message} from "../../../utils/message";

class Card extends React.Component {
    state = {
        bid: 0,
        isValidatedBid: false,
        fee: 0.01,
        shouldUpdatePollData: true,
        investedMoney: 0,
        tixInfo: {price: 0, preSellAmount: 0}
    };

    async componentDidMount() {

    }

    //ToDo add componentDidUpdate with  EthApi.contract.project.getPollData when it will fix
    //
    // async componentDidUpdate(prevProps, prevState) {
    //
    //     if (this.props.project && this.state.shouldUpdatePollData) {
    //         EthApi.contract.project.getPollData(this.props.project.id).then((pollData) => {
    //             const investedMoney = parseInt(pollData[0]);
    //             if (investedMoney !== this.state.investedMoney) {
    //                 this.setState({investedMoney, shouldUpdatePollData: false});
    //                 this.props.onUpdateInvestedMoney(investedMoney);
    //             }
    //         });
    //     }
    //     let tixInfo = await ethClient.ticket.getTixDetail(this.props.project.tokenAddress);
    //     if (prevState.tixInfo.preSellAmount != tixInfo.preSellAmount) {
    //         this.setState({tixInfo});
    //     }
    // }


    handleBid = () => {
        const value = this.bidValue ? this.bidValue.value : '';
        const isValidatedBid = validateBid(this.bidValue);
        let bid = !isNaN(parseInt(value)) ? parseInt(value) : 0;
        if (bid > parseInt(this.state.tixInfo.preSellAmount)) {
            bid = this.state.tixInfo.preSellAmount;
        }
        this.setState({bid, isValidatedBid})
    };

    onInvestToProject = async () => {


        const {bid} = this.state;
        const tokenAddress = this.props.project.tokenAddress;
        const account_address = ethClient.eth.getActiveAccountAddress();

        const ethBalance = this.props.eth.balance;
        const fnbdBalance = this.props.fnbd.balance;
        let verifiyed = true;
        if (bid * this.state.tixInfo.price > fnbdBalance) {
            verifiyed = false;
        }
        if (verifiyed) {
            // async approveTix(account_address, password, token_address, spender_address, amount)
            let result = await ethClient.eth.approvieCoinsForMatcher(account_address, "password", bid * amountInTIX(this.state.tixInfo.price));

            result = await ethClient.project.investOnProject(account_address, "password", {tokenAddress, bid});
            let tixInfo = await ethClient.ticket.getTixDetail(tokenAddress);
            this.setState({tixInfo});
            this.props.updateWalletBalanceSaga();
        } else {
            message('error', "YOU DONT HAVE ENOUGH USDW!");
        }

    };

    render() {
        const {
            bid,
            isValidatedBid,
            fee,
            investedMoney,
            tixInfo
        } = this.state;
        const {
            ipPercentage,
            tickerSymbol,
            numOfLicenses,
            project
        } = this.props;

        var goal = "";
        if (project) goal = project.goal;
        const leftgoal = amountInTIX(tixInfo.price) * parseInt(tixInfo.preSellAmount);


        const isLoading = tickerSymbol === undefined;
        return <div className="auction-card">
            <Loader showLoader={isLoading} style={{height: "620px", width: "377px"}}>
                <div className="auction-card__header">
                    <div className="auction-card__header__sub-title">You are investing in...</div>
                    <div className="auction-card__header__title">
                        {tickerSymbol}
                    </div>
                    {/* <div className="auction-card__header__sub-title">
            {`Unlimited License Item ${tickerSymbol} `}
          </div> */}
                </div>
                <div className="auction-card__body">
                    <BodySector title={"Remaining Goal"} value={leftgoal} oppositeValue={"USDW/USD"}/>
                    <div className="body-sector">
                        <div className="bid-header">
                            Amount
                        </div>
                        <div
                            className={`bid-value flex justify-content__space-between align-items__center  ${isValidatedBid ? "border-green" : ""}`}>
                            <input className="your-bid" placeholder={`Up to ${goal - investedMoney}`} ref={node => {
                                this.bidValue = node
                            }} value={bid ? bid : ''} onChange={this.handleBid}/>
                            <span>{tickerSymbol}</span>
                        </div>
                    </div>
                    <BodySector title={"Investor stake"} value={ipPercentage}/>
                    <BodySector title={"Price"} value={amountInTIX(tixInfo.price)}/>
                </div>
                <div className="auction-card__btn-section">
                    <DiscountSector value={"Subtotal"} oppositeValue={bid}/>
                    <DiscountSector value={"Transaction Fee (1%)"} oppositeValue={bid * fee}/>
                    <DiscountSector value={"Total"} oppositeValue={bid + bid * fee}/>
                    <button className="do-bid__btn"
                            onClick={this.onInvestToProject}
                            disabled={!isValidatedBid}>
                        Invest
                    </button>
                </div>
            </Loader>
        </div>
    }
}

const BodySector = ({title, value, oppositeValue = ''}) => (
    <div className="body-sector">
        <div className="bid-header">
            {title}
        </div>
        <div className="bid-value flex justify-content__space-between align-items__center">
            <span>{value}</span>
            <span>{oppositeValue}</span>
        </div>
    </div>
);

const DiscountSector = ({value, oppositeValue = 0}) => (
    <div className="discount-description">
        <span className="pull-left title">{value}</span>
        <span className="pull-right title">{oppositeValue}</span>
        <div className="dashedVerticalLine"/>
    </div>
);

const validateBid = (node) => {
    try {
        const regex = /^[1-9]\d*(?:\.\d{0,2})?$/;
        return regex.test(node.value);
    } catch (e) {
        return false
    }
};

function mapStateToProps({blockchain}) {
    return {
        mainWalletAddress: blockchain.mainWalletAddress,
        eth: blockchain.eth,
        fnbd: blockchain.fnbd
    };
}

export default connect(
    mapStateToProps,
    null
)(Card);
