import React, {useState, useRef, Fragment, useEffect} from 'react';
import FanWallyLogo from '../../../assets/images/fanwally_logo.png'
// import Highlight from 'react-highlight';
// import {message} from "antd";
// import {ReactTypeformEmbed} from 'react-typeform-embed';
import {Link} from "react-router-dom";
import BackupPhrase from "./backupPhrase/backupPrhase";
import LocalStore from '../../../utils/localStore'
import EthWallet from '../../helpers/wallet';
import ImportWallet from './importWallet/importWallet';
import {CopyToClipboard} from "react-copy-to-clipboard";
import BackArrow from '../../../assets/images/left-chevron.svg'
import UnlockWallets from "./unlockWallets";
import WelcomeWallets from './welcomeWallets'
import Parse from "parse";
import {walletService} from "../../api/tendermint/main"
import {getWallyWalletAddress, getUSDWWalletBalance, getWalletsInfoUtil} from '../../../utils/temndermintWalletsUtils'
import {Loader} from "../../Components/Loader";

const ethWallet = new EthWallet();

const WallyHome = () => {
    const [selectedTypeOfWallet, setSelectedTypeOfWallet] = useState(false);
    const [openMnemonic, setOpenMnemonic] = useState(false)
    const [openImport, setOpenImport] = useState(false)
    const [createWallet, setCreateWallet] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [welcomeWalletPage, setWelcomeWalletPage] = useState(true)
    const [walletFunc, setWalletfunc] = useState(null)
    const [createPasswordPage, setCreatePasswordPage] = useState(false)
    const [walletType, setWalletType] = useState(null)
    const pswdRegEx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    const [password, setPassword] = useState('');
    const [confirmPaswd, setConfirmPaswd] = useState('');
    const [passwordLenght, setPasswordLenght] = useState(false);
    const [passwordUppercase, setPasswordUppercase] = useState(false);
    const [passwordLowercase, setPasswordLowercase] = useState(false);
    const [passwordNumbers, setPasswordNumbers] = useState(false);
    const [error, setError] = useState(false);
    const [mnemonic, setMnemonic] = useState(false);
    const [acc, setAcc] = useState(false);
    const [activeAddress, setActiveAddress] = useState(false);
    const [activeWallet, setActiveWallet] = useState(false);
    const [activeWalletEth, setActiveWalletEth] = useState(false);
    const [walletsArray, setWalletsArray] = useState(null)
    const [walletsArrayEth, setWalletsArrayEth] = useState(null)
    const [walletsArr, setWalletsArr] = useState(null)
    const [walletsArrEth, setWalletsArrEth] = useState(null)
    const [copied, setCopied] = useState(false);
    const [copiedEth, setCopiedEth] = useState(false);
    const [masterKey, setMasterKey] = useState(false);
    const [masterKeyInput, setMasterKeyInput] = useState(false);
    const [unlocked, setUnlocked] = useState(false);
    const [masterKeyData, setMasterKeyData] = useState(false);
    const [walletsStoreArr, getWalletsStoreArr] = useState(null);
    const [errorMasterKey, setErrorMasterKey] = useState(false);
    const [actWallet, setActWallet] = useState(null);
    const termsCheckbox = useRef();
    const selectAddressCheckbox = useRef();
    const selectAddressCheckboxEth = useRef();
    const [usdwWallet, setUsdwWallet] = useState('loading');
    const [preloader, setPreloader] = useState(true);


    useEffect(() => {
        const getWalletsData = async () => {

            const {
                wallyActiveAddress,
                ethActiveAddress,
                wallyWalletsString,
                ethWalletsString
            } = await getWalletsInfoUtil();

            let usdWbalance = await getUSDWWalletBalance();
            setUsdwWallet(usdWbalance)
            getWalletsInfo(wallyWalletsString, wallyActiveAddress, 'walletsArrFirst', 'fanbase')
            getWalletsInfo(ethWalletsString, ethActiveAddress, 'walletsArrFirst', 'eth')
            setPreloader(false)
        }
        getWalletsData()
    }, [])

    useEffect(() => {
        console.log(activeWallet, activeWalletEth, "😏")
        //check active wallets and combine active wallet with all wallets
        //in user's localstorage for remove duplicate active-address from all wallets array
        if (activeWallet) combineActiveWalletWithWalletsArray('fanbase', activeWallet, walletsArr)
        if (activeWalletEth) combineActiveWalletWithWalletsArray('eth', activeWalletEth, walletsArrEth)

    }, [activeWallet, walletsArr, activeWalletEth, walletsArrEth])


    const savePassword = () => {
        if (!termsCheckbox.current.checked) {
            // return message.error('Must accept terms of use')
        } else if (!password.match(pswdRegEx) || password !== confirmPaswd) {
            // message.error('Password does not meet the requirements');
            return setError(true)
        } else {
            setIsDisabled(false);
            setOpenMnemonic(true)
            setCreatePasswordPage(false)
            createPassword(password);
        }
    };


    const checkPassword = (e) => {
        if (e.target.value.match(/[A-Z]/g)) {
            setPasswordUppercase(true)
        } else {
            setPasswordUppercase(false)
        }
        if (e.target.value.match(/[a-z]/g)) {
            setPasswordLowercase(true)
        } else {
            setPasswordLowercase(false)
        }
        if (e.target.value.length < 8) {
            setPasswordLenght(false)
        } else (setPasswordLenght(true));
        if (/\d/.test(e.target.value)) {
            setPasswordNumbers(true)
        } else {
            setPasswordNumbers(false)
        }
        setPassword(e.target.value)
    };

    const checkConfirmedPassword = (e) => {
        setConfirmPaswd(e.target.value)
    };


    const combineWalletAddresses = (active_address, walletsArr, type) => {
        let walletsListArr = [active_address];
        if (walletsArr) {
            walletsArr.forEach((i) => {
                type === 'fanbase' ? walletsListArr.push(i[0]) : walletsListArr.push(i.address)
            })

            if (walletsArr.length < walletsListArr.length) {
                let combinedArrayWithAddress = walletsListArr.filter((item, index) => {
                    return walletsListArr.indexOf(item) === index
                });
                return combinedArrayWithAddress;
            }
        } else return null
    };

    const selectActiveAddress = (type) => {
        type === 'fanbase'
            ? localStorage.setItem('active_address', selectAddressCheckbox.current.value)
            : localStorage.setItem('active_addressEth', selectAddressCheckboxEth.current.value)
    };


    const checkWalletKey = () => {

        if (masterKeyData === masterKeyInput) {
            setUnlocked(true)
        } else {
            setErrorMasterKey('Incorrect master key. Try again')
        }
    };


    const getWalletsInfo = (gettedWallet, active_address, walletsArrFirst, type) => {

        if (gettedWallet && gettedWallet.length > 0) {
            let newArr = []
            console.log('gettedWallet', gettedWallet, type)
            let prepareWalletsArr = JSON.parse(gettedWallet);
            console.log(prepareWalletsArr, '====prepareWalletsArr===')
            prepareWalletsArr.forEach((wallet) => {

                if (wallet) {
                    console.log(wallet, "======WAAAAAALEEEEEEET======")
                    console.log(wallet.addresses, "======WAAAAAALEEEEEEET===addressesaddressesaddressesaddresses===")
                    type === 'fanbase' ? newArr.push(wallet.addresses) : newArr.push(wallet)
                }

            });

            walletsArrFirst = newArr;
            type === 'fanbase' ? setWalletsArr(walletsArrFirst) : setWalletsArrEth(walletsArrFirst)
            console.log("WAAAAAALEEEEEETSSSSS", walletsArrFirst, 'walletsArrFirst')

        }


        // if (!localStorage.getItem('active_address') && gettedWallet.length > 0) {
        //   console.log(333333)
        //   let selectedAddress = JSON.parse(gettedWallet)[0].address;
        //   console.log(selectedAddress, '----selectedAddress1111111')
        //   localStorage.setItem('active_address', selectedAddress)
        //   setActiveAddress(true)
        //   setActiveWallet(selectedAddress)
        // }


        // if (!localStorage.getItem('active_address') && gettedWallet.length === 0) {
        //   setWelcomeWalletPage(false)
        //   setCreatePasswordPage(true)
        // }


        if (active_address) {
            if (active_address !== "undefined") {
                setActiveAddress(true)
                type === 'fanbase' ?
                    setActiveWallet(active_address)
                    : setActiveWalletEth(active_address)

            } else {
                type === 'fanbase' ?
                    setActiveWallet(walletsArrFirst[0][0])
                    : setActiveWalletEth(walletsArrFirst[0])
                setActiveAddress(true)
                setActWallet(walletsArrFirst[0][0])
            }
        }
    }


    const combineActiveWalletWithWalletsArray = (type, activeWallet, walletsArr) => {
        type === 'fanbase'
            ? setWalletsArray(combineWalletAddresses(activeWallet, walletsArr, 'fanbase'))
            : setWalletsArrayEth(combineWalletAddresses(activeWallet, walletsArr, 'eth'))
    }


    const createPassword = (password) => {
        const currentUser = Parse.User.current();
        let mail = currentUser.attributes.email

        const mnemonicValue = ethWallet.createMnemonic(password, mail);
        setMnemonic(mnemonicValue);
        register(password, mnemonicValue);
    };


    const register = async (password, mnemonic) => {
        const ethWallet = new EthWallet();
        const localStore = new LocalStore(password);
        const seed = await ethWallet.createMasterSeed(mnemonic, password);
        setAcc({mnemonic: mnemonic, seed: seed, password: password, nonce: 0});

        localStore.set('session', JSON.stringify({
            mnemonic,
            nonce: 0
        }));

        localStore.setEncoded('token', password);
    };


    const saveMasterKey = () => {
        const localStore = new LocalStore(password);
        localStore.setEncoded('userToken', masterKeyInput)
        setMasterKey(true)
    };


    return (
        <div className='wallyHome_wrap'>

            {
                //if user already enter key and not unlock wallet component
                !unlocked && (masterKey && walletsStoreArr)
                    ? <UnlockWallets
                        error={error}
                        setMasterKeyInput={setMasterKeyInput}
                        checkWalletKey={checkWalletKey}
                        errorMasterKey={errorMasterKey}
                    />
                    :


                    <>
                        {welcomeWalletPage && !openImport &&
                        <div>
                            {preloader &&
                            <div className='wallyHome_container'>
                                <div className='wallyHome_block '><Loader showLoader={preloader} style={{
                                    width: "auto",
                                    height: "auto",
                                    backgroundColor: "transparent",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "static"
                                }}/>
                                </div>
                            </div>
                            }


                            {activeAddress && !preloader &&
                            <>
                                <div className='wallyHome_container'>
                                    <div className='wallyHome_block main_wallet_page'>
                                        <img src={FanWallyLogo} alt="fanWally logo"/>
                                        <h2>Your wallet's dashboard</h2>
                                        <p style={{marginTop: '-10px'}}>Select your active address or create new just
                                            now!</p>
                                        {activeWallet && walletsArray && <>
                                            <p style={{marginTop: '20px'}}>Your Fanbase wallets:</p>
                                            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                                                <select className='wally-select' style={{marginTop: '10px'}}
                                                        ref={selectAddressCheckbox}
                                                        onChange={() => selectActiveAddress('fanbase')}>
                                                    {walletsArray && walletsArray.map((i, idx) =>
                                                        <option key={idx}>{i}</option>
                                                    )}
                                                </select>

                                                <CopyToClipboard text={activeWallet}
                                                                 onCopy={() => setCopied(true)}>
                                                    {copied === true
                                                        ?

                                                        <button className={'primaryButton copy-btn   copyLink copied'}
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    marginTop: '0px',
                                                                    marginBottom: '0 !important'
                                                                }}

                                                        >
                                                            Copied!
                                                        </button>

                                                        : <button className={'primaryButton copy-btn'}
                                                                  style={{
                                                                      marginLeft: '10px',
                                                                      marginTop: '20px',
                                                                      marginBottom: '0 !important'
                                                                  }}

                                                        >
                                                            Copy address
                                                        </button>}
                                                </CopyToClipboard>

                                            </div>
                                        </>}

                                        {activeWallet && <p>Your USDW balance: {usdwWallet} USDW</p>}

                                        {activeWalletEth && walletsArrayEth && <>
                                            <p style={{marginTop: '20px'}}>Your ETH-wallets:</p>
                                            <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: '20px'}}>
                                                <select className='wally-select' style={{marginTop: '10px'}}
                                                        ref={selectAddressCheckboxEth}
                                                        onChange={() => selectActiveAddress('eth')}>
                                                    {walletsArrayEth && walletsArrayEth.map((i, idx) =>
                                                        <option key={idx}>{i}</option>
                                                    )}
                                                </select>
                                                <CopyToClipboard text={activeWalletEth}
                                                                 onCopy={() => setCopiedEth(true)}>
                                                    {copiedEth === true
                                                        ?

                                                        <button className={'primaryButton copy-btn   copyLink copied'}
                                                                style={{
                                                                    marginLeft: '10px',
                                                                    marginTop: '0px',
                                                                    marginBottom: '0 !important'
                                                                }}

                                                        >
                                                            Copied!
                                                        </button>

                                                        : <button className={'primaryButton copy-btn'}
                                                                  style={{
                                                                      marginLeft: '10px',
                                                                      marginTop: '20px',
                                                                      marginBottom: '0 !important'
                                                                  }}

                                                        >
                                                            Copy address
                                                        </button>}
                                                </CopyToClipboard>


                                            </div>
                                        </>}


                                        <div className='wallyHome_block-flex'>
                                            <div className='wallyHome_block-flex-column'>
                                                {/*<PlusOutlined style={{fontSize: '86px', color: '#08c'}}/>*/}
                                                <h4> I want to create one more new wallet!</h4>
                                                <button className="blue-btn" onClick={() => {
                                                    setCreateWallet(true);
                                                    setActiveAddress(false)
                                                    setWalletfunc('create')
                                                }
                                                }>Create a Wallet
                                                </button>
                                            </div>
                                            <div className='wallyHome_block-flex-column'>
                                                {/*<DownloadOutlined style={{fontSize: '86px', color: '#08c'}}/>*/}
                                                <h4>I already have a wally phrase and want to import wallet.</h4>
                                                <button className="blue-btn" onClick={() => {
                                                    setOpenImport(true);
                                                    setWalletfunc('import')
                                                }}>Import Wallet
                                                </button>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </>
                            }


                            {!activeAddress && !preloader && <WelcomeWallets
                                selectedTypeOfWallet={selectedTypeOfWallet}
                                setSelectedTypeOfWallet={setSelectedTypeOfWallet}
                                setWelcomeWalletPage={setWelcomeWalletPage}
                                walletFunc={walletFunc}
                                setWalletfunc={setWalletfunc}
                                setWalletType={setWalletType}
                                setCreatePasswordPage={setCreatePasswordPage}
                                setWelcomeWalletPage={setWelcomeWalletPage}
                                setCreateWallet={setCreateWallet}
                                setOpenImport={setOpenImport}/>


                            }


                        </div>


                        }


                        {
                            createPasswordPage &&

                            <> {
                                masterKey ?

                                    <div className='wallyHome_container'>
                                        <div className='wallyHome_block' style={{"position": "relative"}}>
                                            <img src={BackArrow} className='wallet_psw_back'
                                                 onClick={() => setMasterKey(false)}/>
                                            <>
                                                <h2>Please create password for
                                                    your {walletType ? walletType : 'new'} wallet</h2>

                                                <div className="password_check_container">

                                                    <div
                                                        className={passwordLowercase ? "password_check_block-green" : "password_check_block-red"}>1
                                                        lowercase
                                                    </div>
                                                    <div
                                                        className={passwordUppercase ? "password_check_block-green" : "password_check_block-red"}>1
                                                        uppercase
                                                    </div>
                                                    <div
                                                        className={passwordNumbers ? "password_check_block-green" : "password_check_block-red"}>1
                                                        number
                                                    </div>
                                                    <div
                                                        className={passwordLenght ? "password_check_block-green" : "password_check_block-red"}>8
                                                        characters
                                                    </div>
                                                </div>
                                                <div className={('pswd-input__block' + ' ') + (error ? 'error' : '')}>
                                                    <label htmlFor='password'>New Password</label>
                                                    <input type="password"
                                                           id='password'
                                                           autoComplete='false'
                                                           onChange={e => {
                                                               setError(false);
                                                               checkPassword(e)

                                                           }}/>
                                                </div>
                                                <div className={('pswd-input__block' + ' ') + (error ? 'error' : '')}>
                                                    <label htmlFor='confirm'>Confirm Password</label>
                                                    <input type="password"
                                                           id='confirm'
                                                           autoComplete='false'
                                                           onChange={e => {

                                                               setError(false);
                                                               checkConfirmedPassword(e)
                                                           }}/>
                                                </div>
                                                <div className='terms-of-use'>
                                                    <input type="checkbox" ref={termsCheckbox}
                                                           onClick={() => setIsDisabled(!isDisabled)}/>
                                                    <p>I have read and agree to the <a href="">Terms of Use</a>.</p>
                                                </div>
                                                <button className={isDisabled ? 'disableButton' : 'blue-btn'}
                                                        onClick={savePassword}
                                                        disabled={isDisabled}> Create
                                                    {/*{btnText}*/}
                                                </button>
                                            </>
                                        </div>
                                    </div>


                                    :


                                    <div className='wallyHome_container'>
                                        <div className='wallyHome_block'>
                                            <>
                                                {/*<h4 style={{width: '600px', marginBottom: '10px', fontSize: '20px', color: 'black', textAlign: 'center'}}>The password to your wallet is called your masterkey. Enter your masterkey:</h4>*/}
                                                <h2>Please create your passcode</h2>


                                                <div className={('pswd-input__block' + ' ') + (error ? 'error' : '')}>
                                                    <label htmlFor='confirm' style={{
                                                        width: '600px',
                                                        marginBottom: '10px',
                                                        marginTop: '10px',
                                                        fontSize: '18px',
                                                        color: 'black',
                                                        textAlign: 'center'
                                                    }}>The password to access the wallet page, on this specific device,
                                                        is called your passcode. <br/><br/>

                                                        <p className="pwallet_p" style={{
                                                            width: '100% !important',
                                                            textAlign: 'center',
                                                            fontSize: '14px',
                                                            color: 'black',
                                                            maxWidth: '100% !important'
                                                        }}>
                                                            Enter your masterkey:
                                                        </p>
                                                    </label>
                                                    <input type="password"
                                                           id='confirm'
                                                           autoComplete='false'
                                                           onChange={e => {
                                                               setMasterKeyInput(e.target.value)
                                                           }}/>
                                                </div>
                                                {/*<div className='terms-of-use'>*/}
                                                {/*  <input type="checkbox" ref={termsCheckbox} onClick={() => setIsDisabled(!isDisabled)}/>*/}
                                                {/*  <p>I have read and agree to the <a href="">Terms of Use</a>.</p>*/}
                                                {/*</div>*/}
                                                <button className={'blue-btn'}
                                                        onClick={() =>
                                                            saveMasterKey()
                                                        }
                                                > Create
                                                    {/*{btnText}*/}
                                                </button>
                                            </>
                                        </div>
                                    </div>


                            }
                            </>

                        }

                        {
                            openMnemonic && <div className='wallyHome_container'>
                                <div className='wallyHome_block'>
                                    <>
                                        <BackupPhrase mnemonic={mnemonic} acc={acc} password={password}
                                                      walletType={walletType}/>
                                    </>
                                </div>
                            </div>
                        }
                        {openImport &&


                        <ImportWallet walletType={walletType} combineWalletAddresses={combineWalletAddresses}
                                      setWalletsArray={setWalletsArray}
                                      setOpenImport={setOpenImport} setWelcomeWalletPage={setWelcomeWalletPage}
                                      setActiveAddress={setActiveAddress}/>

                        }


                    </>

            }
        </div>
    );
};

export default WallyHome;
