import {entropyToMnemonic, setDefaultWordlist, mnemonicToSeedSync} from 'bip39';
import leftpad from 'leftpad';
import hdkey from 'ethereumjs-wallet/hdkey';
import Web3 from 'web3';
import { constants } from '../../constants'

setDefaultWordlist('english');

class EthWalletHelper {
  getWeb3Instance(network) {
    let web3 = new Web3(new Web3.providers.HttpProvider(constants.RPC))
    if (network === 'ropsten') {
      web3 = new Web3(new Web3.providers.HttpProvider(constants.ROPSTEN_RPC));
    } else if (network === 'rinkeby') {
      web3 = new Web3(new Web3.providers.HttpProvider(constants.RINKEBY_RPC));
    } else if (network === 'usdw') {
      web3 = new Web3(new Web3.providers.HttpProvider(constants.PRIVATE_RPC));
    }

    return web3;
  }

  async getBalance(address) {
    const web3 = window.web3;
    const currentBalance = await web3.eth.getBalance(address);
    return web3.utils.fromWei(currentBalance, 'ether');
  }

  async getTokenBalance(address, tokenAddress) {
    const web3 = window.web3;
    const tokenContract = new web3.eth.Contract(constants.ERC20_ABI, tokenAddress);

    return await tokenContract.balances.call(address);
  }

  async getTxHistory(address) {
    const web3 = window.web3;

    const lastBlock = await web3.eth.getBlockNumber();
    const totalTxCount = await web3.eth.getTransactionCount(address);
    const transactions = await this.getTransactionsByAccount(address, totalTxCount, 50, lastBlock);

    return transactions;
  }

  async getWalletData(address) {
    const web3 = window.web3;
    let currentBalance = await web3.eth.getBalance(address);
    currentBalance = web3.utils.fromWei(currentBalance, 'ether');

    const lastBlock = await web3.eth.getBlockNumber();
    const totalTxCount = await web3.eth.getTransactionCount(address);
    const transactions = await this.getTransactionsByAccount(address, totalTxCount, 20, lastBlock);

    return [currentBalance, transactions];
  }

  async getTransactionsByAccount(address, totalCount, limit, startBlock) {
    let transactions = [];
    const web3 = window.web3;

    let i = startBlock;
    let txCount = 0;
    let stopPoint = (totalCount < limit) ? totalCount : limit;
    let blockLimit = 30;

    console.log(`traversing blocks...`);
    while (txCount < stopPoint && (startBlock - i) < blockLimit) {
      const block = await web3.eth.getBlock(i, true);
      console.log(block);
      for (let j = 0; j < block.transactions.length; j++) {
        if (this.isEqualAddress(block.transactions[j].from, address) || this.isEqualAddress(block.transactions[j].to, address)) {
          transactions.push({
            ...block.transactions[j],
            timestamp: block.timestamp
          });
          txCount++;
        }
      }
      i--;
    }

    console.log(transactions);

    const finalTransactions = transactions.map(rawTx => {
      let title = '';
      let type = '';

      if (rawTx.input === "0x") {
        if (this.isEqualAddress(rawTx.from, address)) {
          title = 'Sent ETH';
          type = 'withdraw';
        } else if (this.isEqualAddress(rawTx.to, address)) {
          title = 'Received ETH';
          type = 'deposit';
        }
      } else {
        title = 'Contract Interaction';
        type = 'contract';
      }

      console.log(rawTx.timestamp);
      return {
        title,
        type,
        hash: rawTx.hash,
        from: rawTx.from,
        to: rawTx.to || '',
        value: parseInt(rawTx.value, 10) > 0 ? web3.utils.fromWei(rawTx.value, 'ether') : 0,
        date: (new Date(rawTx.timestamp * 1000)).toLocaleDateString(),
        timestamp: rawTx.timestamp,
        symbol: 'ETH',
        status: 'completed',
        rawTx: rawTx
      };
    });

    return finalTransactions;
  }

  createMnemonic(entropy, mail) {

    let entropyVariable = entropy;
    let code = "";
    let symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 16; i++) {
      code += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    // console.log(entropyVariable, "--------------entropyVariable")
    if (code !="") {
      const hexEntropy = Buffer.from(leftpad(code, 16, '0'), 'utf8').toString('hex');
      return entropyToMnemonic(hexEntropy);
    }
  }

  createMasterSeed(mnemonic, password) {
    return mnemonicToSeedSync(mnemonic, password)
  }

  generateWalletFromSeed(seed, nonce) {

console.log('seed', seed, nonce, 'nonce----------->>>>>>>>>>>');


    const hdWallet = hdkey.fromMasterSeed(seed);
    const wallet_hdpath = "m/44'/60'/0'/0/";
    const wallet = hdWallet.derivePath(wallet_hdpath + nonce).getWallet();
    const address = `0x${wallet.getAddress().toString('hex')}`;
    const privKey = wallet.getPrivateKey().toString('hex');

    return {wallet, address, privKey};
  }

  isEqualAddress(a, b) {
    return !!a && !!b && a.toLowerCase() === b.toLowerCase();
  }
}

export default EthWalletHelper;
