import React from 'react';
import LogoMakerBody from './Body';
import './style.css';
import './fonts.css';

class LogoMakerPreloader extends React.Component {
    state = {isFontsLoaded: false, isFontsLoading: false, fontsCounter: 0};

    //If you want to change fonts you must remove all fonts from 'fonts' directory, then change 'fonts.css' and add your fonts. And after you may change this array to array with your fonts names.
    fonts = ["Alegreya Sans", "Amatic SC", "Berkshire Swash", "Bubblegum Sans", "Bungee Shade", "Chivo", "Comfortaa", "Cookie", "Courgette", "East Sea Dokdo", "Eczar", "Fenix", "Inknut Antiqua", "Kaushan Script", "Monoton", "Montez", "Poiret One", "Poppins", "Princess Sofia", "Rambla", "Righteous", "Rock Salt", "Sacramento", "Satisfy", "Shadows_Into_Light", "Spectral", "Trocchi", "Vast Shadow"];

    componentDidMount() {
        const {isFontsLoaded} = this.state;

        if (!isFontsLoaded) {
            this.startLoadingFonts();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevState.isFontsLoading && this.state.isFontsLoading) {
            window.document.fonts.ready.then((fontFaceSet) => {
                this.setState({isFontsLoaded: true});
            });
        }
    }

    render() {
        const {isFontsLoaded, isFontsLoading} = this.state;
        const {allowToUploadPhoto} = this.props;

        return (
            <div id="logo-maker">
                {
                    isFontsLoaded && <LogoMakerBody fonts={this.fonts}
                                                    allowToUploadPhoto={allowToUploadPhoto}
                                                    isFontsLoaded={isFontsLoaded}
                                                    insertLogo={this.props.insertLogo}/>
                }
                {/*{isFontsLoading && !isFontsLoaded && <h1 className='logo-maker-h1-loader'>Loading...</h1>}*/}
                {/*{isFontsLoading && !isFontsLoaded && (*/}
                {/*  <div id='logo-maker-loading'>*/}
                {/*      {this.fonts.map(font => <p key={font} style={{ fontFamily: font }}>font</p>)}*/}
                {/*  </div>*/}
                {/*)}*/}
            </div>
        );
    }

    startLoadingFonts = () => {
        //if you use server side rendering it will safe you from errors
        if (typeof window !== 'object') {
            return '';
        }

        this.setState({isFontsLoading: true});
    }
}

export default LogoMakerPreloader;
