import Parse from "parse";
import { message } from "./message";
import axios from 'axios'
export const sendParseRequest = async (urlAddress, data = {}) => {
console.log(urlAddress, data, '--------------urlAddress, data------------------------------')
  return Parse.Cloud.run(urlAddress, data)
      .then(res => {
          {console.log(res, '--res2'); return res}
      })
      .catch(err => {
          if (err.code === 209) {
              Parse.User.logOut()
                  .then(() => {
                      window.location.href = "/login";
                      window.location.reload();
                  });
          }
          message('error', err.message)
          // console.log("ERROR sendParseRequest ==> ", JSON.stringify(err))
          return new Promise((resolve, reject) => reject(err))
      })
}
