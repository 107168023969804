import React, {Fragment, useEffect} from 'react';
import {Link} from 'react-router-dom';

const RadioBtnBlock = ({data, fieldNameForChange, onSelectType, name, selectedData, checkboxType, checkboxTypeSelected, downLoadType}) => {
    const onRadioBtnChange = (text, shouldOpenOtherBlock, selectedValue) => {
        // 'checkboxTypeSelected'
        let objForState;

        switch (fieldNameForChange) {
            case 'additionalDataForLimited':
                objForState = {
                    limitedLicenseType: text,
                    quantityOfTotalOwnership: shouldOpenOtherBlock ? '1' : undefined,
                };
                break;
            case 'additionalDataForUnlimited':
                objForState = {
                    unlimitedLicenseType: text,
                    quantityOfTotalOwnership: shouldOpenOtherBlock ? '1' : undefined,
                };
                break;
            case 'additionalLicenseForUnlimited':
                objForState = text;
                break;
        }

        // let objForState = {
        //   unlimitedLicenseType: text,
        //   quantityOfTotalOwnership: shouldOpenOtherBlock ? '1' : undefined,
        // };
        //

        if (checkboxType === 'musicContentQuestion') {
            onSelectType(objForState, fieldNameForChange, 'musicContentQuestion', text);
        } else if (checkboxType === 'typeOfDownload') {
            downLoadType(text);
        }  else {
            onSelectType(objForState, fieldNameForChange);
        }
        if (fieldNameForChange === 'dataForSecurity') {
            onSelectType(objForState, fieldNameForChange, 'dataForSecurity', text, selectedValue);
        }


    };

    /*  useEffect(() => {
        if (!selectedData.unlimitedLicenseType) {
          let objForState = {
            unlimitedLicenseType: data[0].text,
            quantityOfTotalOwnership: undefined,
          };

          onSelectType(objForState, fieldNameForChange);
        }
      }, [selectedData]);*/

    useEffect(() => {
        let objForState = {};

        switch (fieldNameForChange) {
            case 'additionalDataForLimited':
                objForState = {
                    limitedLicenseType: data[0].text,
                    quantityOfTotalOwnership: undefined,
                };
                break;
            case 'additionalDataForUnlimited':
                objForState = {
                    unlimitedLicenseType: data[0].text,
                    quantityOfTotalOwnership: undefined,
                };
                break;
            case 'additionalLicenseForUnlimited':
                objForState = '';
                break;
        }

        onSelectType && onSelectType(objForState, fieldNameForChange);
    }, [fieldNameForChange]);

    return (
        <Fragment>
            {
                data.map(({text, className, linkToTerms, shouldOpenOtherBlock, checked, selectedValue}, idx) => (
                    <label className={className} key={`radio-btn-block-key-${idx}`}>
                        <input type="radio"
                            // checked={selectedData.unlimitedLicenseType === undefined && idx === 0 || selectedData.limitedLicenseType === undefined && idx === 0 || selectedData.unlimitedLicenseType === text || selectedData.limitedLicenseType === text}
                               defaultChecked={checked}
                               name={name}
                               value={text}
                               selectedValue={selectedValue}
                               className="license-radio-input"
                               checkboxtype={checkboxType}
                               onChange={() => onRadioBtnChange(text, shouldOpenOtherBlock, selectedValue)}/>
                        <span className="radio-select-text">{text} {linkToTerms.length !== 0 &&
                        <Link to={linkToTerms} target="_blank">terms.</Link>
                        }</span>
                    </label>
                ))
            }
        </Fragment>
    );
};

export default RadioBtnBlock;
