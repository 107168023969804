import React, {useEffect} from 'react';
import "./legalPages.css";

export function Terms() {
  useEffect(() => {
    document.querySelector('#fanbase').scrollTop = 0;
  }, [])
  return (
    <div className="page" style={{"paddingBottom": "0", backgroundColor: 'white'}}>
      <div className="pageContent">

        <div
          style={{boxShadow: 'rgba(0, 0, 0, 0.4) 4px 5px 13px -1px  ', borderRadius: "15px"}}
        >
          <div className="mainHeaderTerms">
            <p style={{"padding": "40px 0"}} className="mainTitle">
              {/*Website Terms and Conditions of Use*/}
            </p>
          </div>
          <div className="row2">
            {/*<p className="firstTitle" style={{"textAlign": "center"}}>Effective date: September 20, 2018</p>*/}
            {/*<p className="paraTitle">Terms</p>*/}
            <div className="privacyPolicy__page">
              {/* <Header/> */}

              <div className="privacyPolicy__page_mainTitle">
                <h3>TERMS OF USE</h3>
                <p>Last Updated June 25th, 2024  </p>
                
              </div>
              <p>Fanbase Technology Co. ("Fanbase", "We", “we” "Us", “us” "Our", “our” or the "Company") collects and uses
                your data in accordance with GDPR guidelines, and provides you (“You”, “you”, “Your”, “your”, “User” or
                “user”) the website (“Site”), applications (“Apps”) and browser plug-in (“Extension”), with Our
                marketing and promotional materials which include text, images, audio, code and other materials
                (collectively, the “Content”), located at
                https://chrome.google.com/webstore/detail/fanbaseio/ehkdpgmlbmklaiibhcdggpbohgggjibl and
                https://www.fanbase.io. Collectively the Site, Apps, Extension, and Content in addition to other
                features we might include, make-up our services (“Services”). We recognize that You, the User, value the decentralized nature
                of our browser wallet (“Fanbase Wallet“), and enjoy the affordances of the decentralized system.
                Therefore, in these Terms, We provide you with access to various blockchains, while positioning you as the authority on your transactions. Our open platform is entirely non-custodial. All
                assets remain on the public blockchain.</p>
              <p>
                Please carefully review Our Terms of Use (“Terms”) before signing on, sharing personal data, and using
                the
                Services. When you agree to our Terms where that option is made available and enter the Service, you (A)
                accept
                and agree to these Terms; (B) consent to the collection, handling, disclosure and other use of
                information as
                described in our Privacy Policy and; (C) agree to any additional terms, rules and conditions of
                participation
                issued by Us in the future. If you do not agree to these Terms, then you are expressly prohibited from
                accessing
                or using our Services. 
              </p>
              <p>
              THE COMPANY INTERFACE (AS DEFINED BELOW) IS NOT AVAILABLE TO PERSONS OR ENTITIES WHO RESIDE IN, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A REGISTERED OFFICE IN THE UNITED STATES OF AMERICA OR CANADA (COLLECTIVELY, “BLOCKED PERSONS”). MOREOVER, NO SERVICES (AS DEFINED BELOW) ARE OFFERED TO PERSONS OR ENTITIES WHO RESIDE IN, ARE CITIZENS OF, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A REGISTERED OFFICE IN ANY RESTRICTED TERRITORY (AS DEFINED BELOW, AND ANY SUCH PERSON OR ENTITY FROM A RESTRICTED TERRITORY, A “RESTRICTED PERSON”). WE DO NOT MAKE EXCEPTIONS; THEREFORE, IF YOU ARE A BLOCKED PERSON, THEN DO NOT ATTEMPT TO USE THE COMPANY INTERFACE AND IF YOU ARE A RESTRICTED PERSON, THEN DO NOT ATTEMPT TO USE ANY OF THE SERVICES. USE OF A VIRTUAL PRIVATE NETWORK (“VPN”) TO CIRCUMVENT THE RESTRICTIONS SET FORTH HEREIN IS PROHIBITED.
              </p>
              <p>
                For any dispute not subject to arbitration, you agree to submit to and shall be subject to the exclusive
                jurisdiction and courts of Wyoming, USA. You further agree to accept service of
                process by mail or email, and hereby waive any and all jurisdictional and venue defenses otherwise
                available. The Terms of Use and the relationship you have with Us shall be governed without regard to
                conflict of legal provisions. Tokens swapped or catalogued on this platform are not associated with Us, nor do they represent ownership in
                the Company or convey any rights to holders. If You are (1) on any list of restricted or prohibited
                parties by the European Union, its individual Member States, the United Nations Security Council, or by
                the U.S. Government, including the Entity List of the U.S. Department of Commerce, the Specially
                Designated Nationals List, the Foreign Sanctions Evaders List of the U.S. Department of Treasury, or any
                other applicable government authority, You may not use this Site or our Services. If you are located in
                a jurisdiction that is embargoed or sanctioned by the United States, You may not use this Site or our
                Services. If you are a resident or citizen in a jurisdiction where owning cryptocurrency is illegal, You
                may not use our Services. Residents of Singapore may not use our Services. Residents of New York, U.S.
                Citizens or U.S. Residents, including any U.S. citizen, U.S. lawful permanent resident, individual who
                meets the “substantial presence” test described in section 7701(b)(3) of the U.S. Internal Revenue Code
                of 1986 (as amended), protected individual under section 1324b(a)(3) of the U.S. Immigration and
                Nationality Act, or individual who holds a passport issued by the United States Government, may not use
                our Services. U.S. Financial Institutions including any U.S. Person and any of its affiliates, branches,
                offices, or agents incorporated, organized, or located in the United States or Territory or Insular
                Possession of the United States that is engaged in the business of: (i) accepting deposits, (ii) making,
                granting, transferring, holding, or brokering remittances, loans, or credits, or (iii) purchasing or
                selling foreign exchange, securities, commodity futures or options, or procuring purchases and sellers
                thereof, whether as principal or agent, and this term applies to affiliates, branches, offices, and
                agencies of any foreign financial institution that are located in the United States or Territory or
                Insular Possession of the United States, but not such foreign financial institution’s affiliates,
                branches, offices, or agencies located outside the United States and Territory or Insular Possession of
                the United States, may not use our Services.
              </p>
              <p>
                1. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Eligibility.&nbsp;</span>

                You are eligible to use the Services because you agree that you enter into Our Terms willingly and that
                you will
                be bound by our conditions, obligations, affirmations, representations and warranties. We provide a
                unique
                Service, and by using our Service you warrant that you are of the legal age of majority in your
                jurisdiction. If
                you are not of the legal age of majority you must cease viewing the Content and using the Service. By
                using our
                Service You also represent that owning cryptographic tokens is legal in Your jurisdiction and that you
                are
                responsible for behaving under the auspices of the law in whichever jurisdiction you reside, or are
                traveling
                in, as demonstrated on any ID documents you have produced, or when it becomes necessary to produce said
                documents, on your own behalf. We, the Company, are not liable for your lack of compliance with such
                laws.
              </p>
              <p>
                2. <span
                style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Account Passwords and Security.&nbsp;</span>
                When registering an account with us, You must store your secret phrases and passwords privately. We
                cannot
                retrieve your passwords for you nor do we have any way to access them. You must (A) never use a password
                on our
                Service which you have used elsewhere; (B) always keep your secret phrase and password confidential and
                never
                share them with anyone; and (C) immediately notify Us of any suspicious activity, breach or security
                issue. We
                are not responsible for any loss or damage which occurs to you during your use of our non-custodial
                wallet,
                tokens, or coins, but we will try to help and warn our community whenever possible.
              </p>
              <p>
                3. <span
                style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Modification of Terms of Use.&nbsp;</span>
                Except for the “Binding Arbitration and Waiver of Class Action Rights” section, we may alter the Terms
                of Use at
                any time. Your use of our Services following our changes to the Terms means that you accept our altered
                Terms.
                The contemporaneous Terms are posted, dated and visible at the beginning or end of the Privacy Policy
                and/or
                Terms of Use sections of our Service. It is your duty to familiarize yourself with all updated Terms of
                Use and
                Privacy Policies.
              </p>
              <div className="privacyPolicy__page_title">
                <h4>INHERENT RISKS</h4>
              </div>
              <p>
                1. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Service Disclaimers.</span> You
                understand and
                expressly agree that your use of the Service is solely at , without any warranty, either express or
                implied,
                including, without limitation, implied warranties of merchantability, fitness for purpose or
                non-infringement.{' '}your risk. The Service (including the Service and the
                Content) are provided on an “as is” and “as available” basis
              </p>
              <p>
                You accept that the Company has no control over, and no duty to take any action regarding whether or not
                you
                gain access to or use the Service, the impact the Content may have on you, or any actions you may take
                as a
                result of having been exposed to the Content. You release the Company from all liability when you have
                obtained
                or not obtained Content through the Service. The Company makes no representations concerning any Content
                contained in or accessed through the Service, and will not be liable for the accuracy, copyright
                compliance,
                legality or decency of material contained in or accessed through the Service. You acknowledge that the
                Company
                is not responsible for transmitting, safeguarding, or maintaining your private keys or any digital
                currency
                associated therewith. If you do lose, mishandle or have stolen your digital currency private keys, you
                may never
                recover the associated digital currency, and the Company is not responsible for such loss. You
                acknowledge that
                the Company is not responsible for any loss, damage or liability arising from your failure to comply
                with the
                Terms of Use, or arising from issues with your private, non-custodial accounts.
              </p>
              <p>
                2.<span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}> New Regulations.</span> The Company
                could be
                adversely impacted by one or more regulatory inquiries or actions, which could impede or limit our
                ability to
                continue to develop the product. Regulatory actions in your jurisdiction could also limit your ability
                to access
                or use the Services.{' '}
              </p>
              <p>
                3. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Bad Actors.</span> You understand and
                accept
                that blockchain is a burgeoning and risky field. Advances in code cracking or technical advances such as
                the
                development of quantum computers may present risks to cryptocurrencies, cryptography and our Services,
                including
                our delivery of Content, which could result in the theft or loss of your digital rights, tokens, coins,
                or
                Content. To the extent possible, the Company updates the Services and security to account for any
                advances in
                cryptography hacking. However, the Company does not guarantee or otherwise represent full security of
                the
                system. By using the Service or accessing the Content, you acknowledge the inherent risks.
              </p>
              <p>
                4. <span
                style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Digital Asset Price Fluctuations. </span>
                You understand and acknowledge that the Company, its blockchain technologies, its blockchain APIs and
                partners,
                as well as other cryptocurrencies, are highly volatile as a result of factors including but not limited
                to the
                security risks involved, public adoption and technological learning curves. You hereby agree that the
                costs of
                transacting with such technologies are subject to change and may increase at any time. Price changes
                will
                thereby impact commercial activity taking place on the Service. You acknowledge these risks and
                represent that
                you will not hold the Company liable for changes in price or cost.
              </p>
              <p>
                5. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Application Security.</span> You do
                acknowledge
                and accept that the Company’s applications are code and therefore subject to security breach. You
                acknowledge
                that you are solely responsible for the evaluation of any code or Content provided by or on the
                Services. In
                cases where code has not been publicly visible, you acknowledge the risk and agree to not hold the
                Company
                liable for loss. You understand that the reliability and integrity of smart-contracts, third-party
                websites or
                products, or other Content you access or use through the Service may have susceptibilities or
                weaknesses. You
                also clearly represent and understand that applications using our Service can be written maliciously or
                negligently, and that the Company does not assume legal responsibility for your interaction with such
                applications. Use of the Company Services may bring about loss of identity, property or biographical
                information. The Company does not have an on-going duty to alert you to the potential risks of the
                Service,
                beyond these Terms of Use.{' '}
              </p>
              <p>
                6.<span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}> Mobile Application.</span> You
                understand and
                acknowledge that the Services are beta testing and are still not officially released. As a result, you
                acknowledge and accept that the Services we provide are not intended to be relied on for any other
                purpose or
                used in a production environment, that the Services may contain bugs, that the Services may be subject
                to
                defects which impact functionality, that the Services may be subject to periods of downtime or be
                unavailable.
                Depending on the circumstance, such defects may result in data loss or data corruption and as a result
                the
                Services may be improved, enhanced, restructured and/or modified, at any time, including through the
                release of
                subsequent versions, all with or without notice to you. The availability of the Services is on an “as
                is” basis
                for the exclusive purpose of evaluating performance, suitability, quality, usability, defect and gaining
                other
                feedback.{' '}
              </p>
              <p>
                7. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Blockchain Systems.</span> By making
                use of the
                Service you represent that you understand the inherent risks associated with cryptographic systems and
                warrant
                that you have an understanding of its usage and the intricacies of cryptographic tokens, stablecoins,
                smart
                contracts, and other software-systems that follow decentralized protocols. If you would like additional
                information about ERC20 tokens in general, tools are located at
                https://github.com/ethereum/EIPs/issues/20. {' '}
              </p>
              <p>
                8. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Clearing. </span>
                You acknowledge and accept that some features of the Service are experimental or interactive, and rely
                on third
                party applications. You acknowledge and accept that there are risks to using the Services, including but
                not
                limited to incapacity to access digital assets that are connected, keyed and/or locked in the smart
                contract or
                the payment channel, which could result from errors, software bugs, communication defects,
                inaccessibility of
                third party networks, third party errors or for any other reason. You acknowledge and accept that the
                Company is
                not responsible for any losses or delays you experience while accessing your digital assets or our
                Services.
                Furthermore, the company may limit, increase, or decrease account limits or round off fractions of
                amounts,
                without prior or subsequent notice to you.
              </p>
              <p>
                9. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Indemnity. </span>
                You accept and agree to indemnify, release, defend and hold harmless the Company, its parent companies,
                subsidiaries, associated companies, agencies, affiliates, directors, employees, and agents, including
                but not
                limited to its representatives as well as shareholders, from and against any loss or liability, costs,
                damages,
                (including legal practitioner’s fees and litigation costs) claims or actions of any kind arising from
                your use
                of the Service as well as any act, including those of omission, caused by you, which violates our Terms
                of Use
                or brings about defamation or privacy invasion. The Company reserves the right to exclusively assume
                control of
                any matter and/or procedures otherwise subject to indemnification by you and, in such case, you agree
                and accept
                to fully participate, support and cooperate with the Company in the defense of such matters or
                procedures.
              </p>
              <p>
                10. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Limitation on Liability. </span>You
                hereby
                acknowledge and accept total responsibility for your use of our Services. Furthermore, you acknowledge
                and
                accept that any information you send or receive in the course of using the Service may be subject to
                interception, or otherwise acquired by unauthorized parties.
              </p>
              <p>
                You understand and fully agree that your use of the Service is conducted wholly at your own risk. You
                acknowledge and accept that, to the fullest extent allowed by the law, the Company, its suppliers,
                licensors or
                third-parties will not be liable to you for any compensatory, incidental, consequential, punitive,
                nominal,
                exemplary, special, or other forms of damages, including without limitation, damages for use, profit
                loss, data
                loss or concrete or intangible losses or damages of any kind based on contract, strict liability, tort
                or any
                other legal concept, contention or conjecture (even if the Company has been counseled on the possibility
                of such
                damages), resulting from your use of the Service, or for any other reason.
              </p>
              <p>The Company is not liable for any unlawful access or manipulation of your data. The Company is not
                liable for
                actions or statements by third parties or users of the Service. The Company is not liable for human
                errors,
                actions or oversights, including those made based on any inaccurate information you send to us,
                technical flaws,
                typographical errors, latent defects, interruption of utilities, damages of any kind or crash of any
                device,
                network, or software, including but not limited to those that allow for participation in the service.
                Furthemore, the Company is not liable for losses due to tampering or theft, illegal access to content,
                inability
                or failure to access the Service, or data that is belated, inconclusive, wrongly processed, incomplete
                and/or
                lost, or as a result of any combination thereof, or any other matter as it relates to the Service. </p>
              <p>Some jurisdictions do not provide for the exclusion of warranties or the limitation or exclusion of
                liability
                for incidental or consequential damages. Therefore, some of the above stated limitations may not apply
                to
                you.</p>
              <div className="privacyPolicy__page_title">
                <h4>INTELLECTUAL PROPERTY</h4>
              </div>
              <p>
                1. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Proprietary Rights. </span>All title,
                ownership and intellectual property rights extended on the Service are communicated, but not guaranteed
                by the
                Company, its affiliates, subsidiaries or licensors. You acknowledge and accept that the Service contains
                proprietary and confidential data that is protected by applicable intellectual property laws and other
                laws, but
                that the value or rights associated with tokens are in no way held or supported by the Company. The
                Company
                facilitates peer-to-peer interactions by providing a forum to do so. The company takes a fee for that
                Service,
                but does not guarantee any of the claims by buyers or sellers of goods and services on the Service.
                Except as
                expressly authorized, you agree not to copy, amend, alter, modify, rent, lease, loan, sell, distribute,
                perform,
                display or create derivative works from Content accessed on the Service, either in whole or in part,
                from the
                Company, its Users, or licensors.
              </p>
              <p>
                2.<span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}> Beneficiaries.</span> You acknowledge
                and
                agree that, except as otherwise expressly stated in these Terms, third party beneficiaries to the Terms
                do not
                and will not apply.
              </p>
              <p>
                3.<span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}> Limited License.</span> The permission
                we give
                in making use of the Service, Site and App (collectively, “Access”) is non-exclusive, limited and
                revocable
                subject to certain conditions already acknowledged under the Terms of Use. The access we allow remains
                in effect
                until you are in breach of our Terms or they are terminated by you.
              </p>
              <p>
                The Company’s Services are not transferable, hence they are not sold or transferred to you. The Company
                and its
                licensors retain complete ownership of all copies of the software applications, even after installation
                on
                personal devices like computers, mobile handsets, tablets, wearable devices, speakers and/or any other
                devices
                known now or in the future. The Company’s service marks, trade names, logos, domain names, and
                trademarks and
                any other items are the exclusive property of the Company or its licensors. The Terms do not authorize
                your
                Usage of its marks for commercial or non-commercial use. You consent to not use the Service in any way
                or manner
                that is not expressly granted in these Terms, and you agree to follow Our guidelines.
              </p>
              <p>Except for the rights expressly granted in the Terms, the Company affords you no right, title, or
                interest in
                the Service. Third party software, like open source software libraries, included in the Service are
                available to
                you under the relevant third party’s terms. Nothing in the Terms entitles you to use, copy, fork,
                modify, alter,
                change or create derivative works based on the Service or source code, for commercial purposes, without
                our
                prior written consent.</p>
              <p>
                4. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Terminating Accounts. </span>At any
                time, the
                Company may terminate or suspend parts of the Service as well as your access, without prior notice or
                liability,
                based on our belief that you breached the Terms, or for any other reason. Upon terminating your access,
                your
                right to use the Service is foreclosed, but the Terms of Service which you agreed to while using the
                Service,
                survive any termination. For example, any indemnities, warranties, disclaimers, proprietary rights,
                limited
                licenses, links, terminating accounts, beneficiaries, copyright claims, class action waivers, or general
                information remain in place.
              </p>
              <p>
                5. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Copyright Claims.</span>  If it is your opinion that your copyright has been violated and/or contravened, immediately make available the following information to the Company’s Copyright Agent:

              </p>
              <ol type="A">
                <li>
                  The description of the infringed intellectual property work and/or that of the copyrighted work;
                </li>
                <li>
                  A physical signature or an electronic signature of the person who was authorized to act on behalf of the copyright owner or other intellectual property interest;

                </li>
                <li>
                  A clear description of the location of the material that you claim is an infringement on the Service;

                </li>
                <li>
                  A detailed provision of your bio information as to include: address, telephone number, and email address and such alike if specifically requested;
                </li>
                <li>
                  A written and signed statement by you showing good faith and belief that the disputed use is not in any way authorized by the copyright owner, its agent, privies, principals or the law;
                </li>
                <li>
                  A sworn affidavit by you, stating that the above information in your Notice is true and correct and that you are the copyright or intellectual property owner or the person who has been legally authorized to act on the copyright or intellectual property owner’s behalf. You can do so by transmitting a written and signed notice via email
                   to admin@fanbase.io.
                  
                </li>
              </ol>
              <p>
                6.<span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}> Links.</span> The Company provides
                links to
                other services, sites, applications, resources and third parties. The Company exercises no control over
                third
                party operations, is not accountable for external resources, and does not approve of or remain liable
                for
                external services. The Company is not liable for any loss or damage which occurs, or is alleged to have
                occurred, in connection with Your use of third parties.
              </p>
              <div className="privacyPolicy__page_title">
                <h4>CLASS ACTION WAIVER</h4>
              </div>
              <p>1. Class Action Waivers and Binding Resolution clauses affect your right to file a lawsuit. Please
                carefully
                review the following, and understand that by using the Company’s Services, you agree to be bound by
                these
                conditions.
              </p>
              <p>
                2.<span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}> Disagreement Resolution. </span>The
                parties
                shall make every possible effort to settle any conflict, claim, question, or dispute and engage in
                negotiations
                based on good faith as a pre-condition of resorting to lawsuit or arbitration.
              </p>
              <p>
                3. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Binding Arbitration. </span>Where the
                parties
                fail to agree to a settlement within a 30 day time frame from the Initial Dispute Resolution, either
                party may
                proceed to a binding arbitration committee as the exclusive medium for resolving claims subject to the
                terms.
              </p>
              <p>
                All claims borne out of Your use of the Service shall be subject to Binding Arbitration, to the
                exclusion of any rules
                or
                procedures governing or permitting class actions.
              </p>
              <p>The arbitrator or arbitration panel, to the exclusion of all federal, state or local court or agencies,
                shall
                have free and absolute authority to resolve all disputes borne out of or relating to the formation,
                interpretation, applicability, or enforceability of these Terms, including, but not limited to claims or
                actions
                that parts of these Terms or the entire Terms, are voidable, or whether a claim is subject to
                arbitration. The
                arbitrator is empowered to grant reliefs as would be available in a competent court under law or in
                equity, and
                the arbitrator’s award shall be written, binding, entered as a judgment in any court of competent
                jurisdiction,
                and enforced upon application for recognition and enforcement in line with the procedural rules for
                enforceability in any jurisdiction where the judgment is sought to be enforced.
              </p>
              <p>
                The parties agree that, devoid of this obligatory requirement, they would have the right to sue in court
                and be
                entitled to a jury trial. They further understand that, in certain cases, the costs of arbitration
                outweigh the
                financial obligations of litigation. In some cases, the right to discovery may be more limited in
                arbitration.
              </p>
              <p>
                4. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Location.</span> Binding Arbitration
                shall take place in Cheyenne Wyoming. By using the Service, You agree to submit to the personal
                jurisdiction of this court system- for arbitration, to stay proceedings pending arbitration, or to
                confirm, modify, vacate or enter judgment on an award entered by the arbitrator.
              </p>
              <p>
                5.{' '}
                <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Class Action Waiver.</span>{' '}
                The parties also agree that arbitration procedures shall be conducted not as a class action or other
                representative action, but in their individual capacities, and the parties clearly waive their right to
                maintain
                a class action or seek relief on such basis. You accept that you and the Company and/or its agents,
                principals,
                or privies may maintain an action against each other only in a one on one capacity, and not as a
                plaintiff in
                any representative proceedings or class. Where it is determined by a court of competent jurisdiction or
                the
                arbitration panel under this Terms, that the class action waiver hereunder is unenforceable or void, or
                that an
                arbitration committee or panel can pursue a class action, in that case, the above stated arbitration
                clause set
                above shall be deemed null and void and all parties will be considered not to have such arbitration
                agreement.{' '}
              </p>
              <p>
                6.<span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Claims in Court.</span>{' '}
                Either party may bring a claim or action to safeguard its intellectual property rights or work, such as
                patents,
                copyrights, moral rights, trademarks, and trade secrets, but not privacy or publicity rights, without
                regard to
                an earlier decision to resolve disputes through arbitration. The parties may also seek and pursue relief
                in
                small claims court or magistrate courts as referred to in some regions for disputes or claims within the
                scope
                of that court’s jurisdiction.
              </p>
              <p>
                7.<span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Option to Get Out.</span>{' '}
                You can exercise your right to opt-out at any point in time and not be subject to the class action
                waiver
                provisions and arbitration process prescribed herein. You can do so by transmitting a written and signed
                notice
                of your decision not to follow through to admin@fanbase.io. For this and other legal matters we are represented by
                Fanbase Network at hi@fanbaseconsulting.com or the following address:
              </p>
              <p>--</p>
              <p>Fanbase Network UAB
              </p>
              <p>C/O Legal</p>
              <p>Girulių g. 20, 12123</p>
              <p>Vilnius, Lithuania</p>
              <p>European Union</p>
              <p>--</p>
              <p>
                You must transmit notice within 30 days from January 20th, 2022 or from the date of your first use of
                the
                Service, whichever is later. If you fail to do so, you shall be bound by Binding Arbitration. Should you
                opt out
                from these arbitration provisions at any point in time, the Company is also no longer bound.
              </p>
              <p>
                8.{' '}
                <span className="privacyPolicy__page_italic">
          Changes to this Section.
        </span>{' '}
                The Company will provide 60-days’ notice of any changes to Class Action Waiver or Terms. Those changes
                will become valid and effective on the 60th day, and will apply to claims arising after the 60th day.
                With regard to disputes not subject to arbitration you and the Company agree to submit to the personal
                and exclusive jurisdiction of SVG. You further agree to accept service of process by mail
                or in person, and hereby waive all jurisdictional and venue defenses which may ordinarily apply. The
                Terms and the relationship between you and the Company shall be Governed by the laws of SVG without
                regard to conflict of legal guidelines and/or provisions.
              </p>
              <div className="privacyPolicy__page_title">
                <h4>GENERAL INFORMATION</h4>
              </div>
              <p>
                1. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Entire Agreement.</span>{' '}
                These Terms, and any additional terms, rules and conditions of participation that the Company may post
                on the
                Service, form the entire agreement between you and the Company. These Terms supersedes prior agreements
                or
                arrangements, be it oral or written, between you and the Company. In the event of a conflict between
                these Terms
                and any additional terms, rules or conditions of participation, the latter will prevail.
              </p>
              <p>
                2.
                <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>
          Waiver and Severability of Terms.
        </span>{' '}
                Where the Company fails to exercise or enforce a right or any provision of the Terms, such act or
                omission shall
                not constitute a waiver of Terms or provisions. If a competent court of law or arbitration committee
                finds any
                of the Terms to be invalid, the parties, regardless of such fact, agree that the court or committee
                should honor
                the intentions of that provision, while the remaining Terms remain in full force and effect.
              </p>
              <p>
                3.{' '}
                <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>
          Statute of Limitations.
        </span>{' '}
                You accept that any claim originating from or relating to your use of the Service must be filed within a
                period
                of one year after such cause of action occurred. If you fail to do so, any action becomes permanently
                barred.
              </p>
              <p>
                4.<span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}> Section Titles.</span>{' '}
                The section titles in the Terms are mainly for purposes of convenience and don't have any legal or
                contractual
                effect.
              </p>
              <p>
                5. <span style={{fontFamily: "Sans-serif", fontStyle: "italic"}}>Communications.</span>{' '}
                Please direct all general communications to admin@fanbase.io. For any legal matters we are represented by
                Fanbase Network at hi@fanbaseconsulting.com located at the following address:
              </p>
              <p>-or-</p>
              <p>Fanbase Network UAB
              </p>
              <p>C/O Legal</p>
              <p>Girulių g. 20, 12123</p>
              <p>Vilnius, Lithuania</p>
              <p>European Union.</p>
              <p className="privecyPage__page_footer_title">
                Last Updated June 25th, 2024
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
