import "./Contract.css";
import React, {Fragment} from "react";
import {Link, Redirect} from "react-router-dom";
import Parse from 'parse';
import EthApi from "../../api/tendermint";
import ethClient from "../../eth_client";
import * as blockchainAction from "../../../modules/blockchain/blockchain.action";
import {connect} from "react-redux";
import PopupWithProgressBar from '../../Components/PopupWithProgressBar';
import RadioBtnBlock from './RadioBtnBlock';
import UploadAttachItemToIpfs from './UploadAttachTOIpfs';
import UploadInsuranceToIpfs from './UploadInsuranceToIpfs';
import InfoTooltip from '../../Components/InfoTooltip';
import CreatorPopup from './popup';
import CustomUsersFields from './CustomUsersFields';

import {
    auctionTimeArray,
    ipArray,
    languageArray,
    royaltyArray,
    licensesArray,
    mediaCategory,
    proofOfWork,
    restrictAgeArray,
    rolesArray,
    rookieRankArray
} from './dataForSelects';
import mediaDetails from './mediaDetails';
import UploadItemsToIpfs from "./UploadItemsToIpfs";
import {AttachmentsWrapper} from "./AttachmentsWrapper/AttachmentsWrapper";
import {TagInput} from "./TagInput/TagInput";
import {sendParseRequest} from "../../../utils/sendParseRequest";
import {addPrincipalOrCollaborator} from "../../../utils/addPrincipalOrCollaborator";
import AddTeamMember from "./AddTeamMember/AddTeamMember";
import {message} from "../../../utils/message";
import SubmitContract from './SubmitContract';

console.log(React.version);

class Contract extends React.Component {
    state = {
        creatorPopup: false,
        collabStatus: false,
        principalStatus: false,
        DownloadDataForLimited: '',
        projectId: null,
        loadingStatus: true,
        collaborators: [],
        showCollab: false,
        principals: [],
        showPrincipals: false,
        stageName: "",
        roles: [],
        freezeData: ['none', ' 6 months', '12 months', '18 months', '24 months', '30 months', '36 months'],
        selectedFreeze: 'none',
        submitPopup: false,
        companyWebsite: "",
        projectTitle: "",
        projectWebsite: [{link: "", showUp: "true"}],
        logoAttach: [],
        projectSubtitle: "",
        projectDescription: "",
        projectMedium: "default",
        projectPerks: "",
        socialMedia: [{link: "", showUp: "true"}],
        promotionalBanners: [],
        videoPitch: [{thumbnailUrl: "", videoLink: "", showUp: "true"}],
        videoPromo: [{thumbnailUrl: "", videoLink: "", showUp: "true"}],
        additonalAttachments: [],
        licenseType: '',
        ipPercentage: "Unidentified",
        royaltyPercentage: "None, this project is not seeking royalties",
        tickerSymbol: "",
        mainLanguage: "",
        additionalLanguage: [],
        proofOfWork: "",
        ageRestrictions: "Unidentified",
        mediaCategory: "",
        numOfLicenses: "Unidentified",
        financingGoal: "",
        rookieRank: "",
        auctionTime: "",
        auctionItem: [],
        investItem: [],
        auctionItemInfo: [],
        investItemInfo: [],
        attachItemInfoIPFS: [],
        attachItem: "",
        showProgressPopup: false,
        projectWasCreate: false,
        additionalData: {},
        attachFileType: "",
        attachFileName: "",
        insuranceItem: "",
        insuranceFileType: "",
        auctionItemNames: [],
        insuranceHash: "",
        insuranceFileName: "",
        licensesPercentageForCreator: '',
        additionalDataForUnlimited: {},
        additionalLicenseForUnlimited: '',
        additionalDataForLimited: {},
        musicContentQuestion: '',
        walletAddress: "",
        showAuctionData: [{showDescription: true}, {showPerks: true}, {showAttachments: true}],
        showInvestData: [{showDescription: true}, {showPerks: true}, {showAttachments: true}],
        additionalItemNamesIPFS: [],
        investItemNames: [],
        showPrincipalsBlock: false,
        typeOfDownload: localStorage.getItem('typeOfDownload') || 'Yes',
        newProjectId: '',
        disabledLicensesPercentage: true,
        affordableInterestForCreator: ["Choose one option"],
        proReview: false,
        reviewProjects: null,
        nodeIPFS: null,
        massMarketQuantity: 1000000,
        dataForSecurity: 'security',
        fieldsArr: [],
        customFieldsState: [],
        socialsArr: []
    };


    updateSocial = (value) => {
        console.log(value, "--------------value")
        let socialsArray = this.state.socialsArr;
        socialsArray.push(value);
        this.setState({socialsArr: socialsArray})
    };

    UpdateFieldsArr = (fieldsArrArg, field) => {
        // console.log(fieldsArrArg, "--------------fieldsArrArg", field)
        this.setState({[field]: fieldsArrArg})
        console.log(this.state.customFieldsState, "--------------this.state.customFieldsState")
    };

    removeCustomFIeldItem = (idx) => {
        let array = this.state.customFieldsState;
        array.splice(idx, 1);
        console.log(array, "--------------array")
        this.setState({customFieldsState: array})
    };


    prepareCustomFields = () => {
        let arrOfCustomFields = {};
        this.state.customFieldsState.map((i) => {
            let obj = {};
            obj[i.name] = i.text;
            if (obj[i.name] !== undefined) {
                obj[i.name] = i.text;
                arrOfCustomFields[i.name] = i.text
                // arrOfCustomFields.push(obj)
            }
        });
        return (arrOfCustomFields);

    };

    ipfsFunc = async () => {
        console.log('==================1', window.IpfsCore)

        const node = window.IpfsCore.create({
            repo: `fanbase${+Math.random()}`,
            init: {
                emptyRepo: true,
                algorithm: "ed25519",
            },
        });
        node.then((i) => {

            console.log(node, '-------node')
            this.setState({nodeIPFS: i})
        })

        console.log(this.state.nodeIPFS, '--nodeIPFS')
        console.log(node, '=====NODAAAA')


    };


    componentDidMount() {
        this.ipfsFunc();

        let ownerWaller = ethClient.eth.getActiveAccountAddress();
        console.log(ownerWaller, "--------------ownerWaller");


        const userId = Parse.User.current().id;
        sendParseRequest('getInfo', {
            query: "Projects",
            equal: [
                {field: "creator", value: {"__type": "Pointer", "className": "_User", "objectId": userId}},
                {field: "proReviewStatus", value: true},
            ]
        }).then((res) => {
            this.setState({
                reviewProjects: res
            });
            console.log(res)

        });


        if (localStorage.getItem('submitPopup') === true) {
            this.setState({submitPopup: true})
        } else {
            this.setState({submitPopup: false})
        }

        if (localStorage.getItem('saved')) {
            this.setState({
                loadDidMount: true,
                stageName: localStorage.getItem('stageName'),
                roles: JSON.parse(localStorage.getItem('roles')),
                companyWebsite: localStorage.getItem('companyWebsite'),
                projectTitle: localStorage.getItem('projectTitle'),
                projectWebsite: JSON.parse(localStorage.getItem('projectWebsite')),
                projectSubtitle: localStorage.getItem('projectSubtitle') || '',
                projectDescription: localStorage.getItem('projectDescription'),
                projectMedium: localStorage.getItem('projectMedium'),
                videoPitch: JSON.parse(localStorage.getItem('videoPitch')),
                videoPromo: JSON.parse(localStorage.getItem('videoPromo')),
                projectPerks: localStorage.getItem('projectPerks'),
                socialMedia: JSON.parse(localStorage.getItem('socialMedia')),
                collaborators: localStorage.getItem('collaborators') === undefined
                    ? [] : JSON.parse(localStorage.getItem('collaborators')),
                ipPercentage: localStorage.getItem('ipPercentage'),
                mainLanguage: localStorage.getItem('mainLanguage'),
                additionalLanguage: localStorage.getItem('additionalLanguage') === undefined
                    ? [] : JSON.parse(localStorage.getItem('additionalLanguage')),
                proofOfWork: localStorage.getItem('proofOfWork'),
                ageRestrictions: localStorage.getItem('ageRestrictions'),
                principals: localStorage.getItem('principals') === undefined
                    ? [] : JSON.parse(localStorage.getItem('principals')),
                mediaCategory: localStorage.getItem('mediaCategory'),
                numOfLicenses: localStorage.getItem("numOfLicenses"),
                licensesPercentageForCreator: localStorage.getItem("licensesPercentageForCreator"),
                financingGoal: localStorage.getItem("financingGoal"),
                rookieRank: localStorage.getItem("rookieRank"),
                auctionTime: localStorage.getItem("auctionTime"),
                additionalDataForUnlimited: localStorage.getItem("additionalDataForUnlimited") !== null
                    ? JSON.parse(localStorage.getItem("additionalDataForUnlimited")) : {},
                additionalLicenseForUnlimited: localStorage.getItem("additionalLicenseForUnlimited") || '',
                musicContentQuestion: localStorage.getItem("musicContentQuestion"),
                additionalDataForLimited: localStorage.getItem("additionalDataForLimited") !== null
                    ? JSON.parse(localStorage.getItem("additionalDataForLimited")) : {},
                walletAddress: ethClient.eth.getActiveAccountAddress(),
                typeOfDownload: localStorage.getItem("typeOfDownload"),
                logoAttach: localStorage.getItem('logoAttach') !== null ? JSON.parse(localStorage.getItem('logoAttach')) : [],
                promotionalBanners: this.props.banners.length !== 0 ? JSON.parse(this.props.banners) : [],
                licenseType: localStorage.getItem('licenseType') !== null ? localStorage.getItem('licenseType') : '',
                auctionItemNames: localStorage.getItem('auctionItemNames') !== null ? JSON.parse(localStorage.getItem('auctionItemNames')) : [],
                investItemNames: localStorage.getItem('investItemNames') !== null ? JSON.parse(localStorage.getItem('investItemNames')) : [],
                auctionItemInfo: localStorage.getItem('auctionItemInfo') !== null ? JSON.parse(localStorage.getItem('auctionItemInfo')) : [],
                investItemInfo: localStorage.getItem('investItemInfo') !== null ? JSON.parse(localStorage.getItem('investItemInfo')) : [],

            }, () => console.log(this.state, '----state'))

        }
        this.setState({loadDidMount: true})
    };


    componentDidUpdate(prevProps, nextProps, prevState, nextState) {
        console.log((prevProps, 'prev'));
        if (prevState !== nextState) {
            console.log("-------------")
        }

        if (prevProps !== nextProps && !this.state.getDataLocal) {

            this.setState({
                licenseType: localStorage.getItem('licenseType') !== null
                    ? localStorage.getItem('licenseType') : 'not selected',
                getDataLocal: true
            })
        }
    }


    getAmazonUrl = (file, callback) => {
        if (file) {
            let type = file.type === undefined ? "application/pdf" : file.type;
            let src = file.src === undefined ? file : file.src;

            sendParseRequest('uploadProjectImage', {base64: src, type: type})
                .then(res => callback(res))
        }
        //     else {
        //     callback ("Unidentified");
        // }
    };

    clearDataOfLimitedLicense = () => {
        document.getElementById('licenseTypeTitle').style.backgroundColor = 'white';

        this.handleGeneralInput('unlimited', 'licenseType');

        this.setState({
            numOfLicenses: 'Unidentified',
            licensesPercentageForCreator: '',
            auctionTime: '',
            rookieRank: '',
            additionalDataForLimited: {},
            auctionItemInfo: [],
            auctionItemNames: []
        });

        localStorage.removeItem('auctionItemNames');
        localStorage.removeItem('auctionItemInfo');
    };

    handleGeneralInputDownload = (text) => {
        this.setState({
            DownloadDataForLimited: text
        })
    };

    handleTypeMedia = (text) => {
        document.getElementById('typeOfDownloads').style.backgroundColor = 'white';
        this.setState({
            typeOfDownload: text
        })
    };

    handleGeneralInput = (value, type, requiredEl, checkboxTypeSelected) => {

        // if (type = "massMarketQuantity") {
        //   if (value>0 && value < 1000000) {
        //     console.log(value, "--------------value", 'value', type)
        //   }
        //     }

        if ((type === "stageName") && value.length > 0) {
            requiredEl.classList.remove('fieldIsNotValid');
        }

        if (type === "musicContentQuestion") {
            this.setState({
                musicContentQuestion: checkboxTypeSelected
            });
        } else {
            if (requiredEl) {
                requiredEl.classList.remove('fieldIsNotValid');
            }

            if (type === 'projectWebsite') {
                this.setState({
                    [type]: [{
                        link: value,
                        showUp: 'true'
                    }]
                });
                return;
            }

            this.setState({
                [type]: value
            });
        }
    };

    checkReservedSymbols = (value) => {
        if (value.length < 6) {
            this.setState({
                tickerSymbol: ''
            });
            return message('error', 'Minimum length 6 characters')
        }

        const symbols = value.slice(0, 9);
        const input = document.getElementById('tickerSymbolInput')

        sendParseRequest('getInfo', {
            query: "Reserved_Symbols",
            count: true,
            equal: [{field: "reservedSymbols", value: symbols}]
        })
            .then((res) => {
                if (!res) {
                    input.className = '';
                    this.setState({
                        tickerSymbol: symbols
                    });
                } else {
                    this.setState({
                        tickerSymbol: ''
                    });
                    message('error', 'This symbol is already in use')
                    input.value = '';
                }
            })
    };

    handleSaveLocal = () => {
        localStorage.setItem('saved', 'true');
        localStorage.setItem('stageName', this.state.stageName);
        localStorage.setItem('royalty', [
            {"January": 0},
            {"February": 0},
            {"March (Q1)": 0},
            {"April": 0},
            {"May": 0},
            {"June (Q2)": 0},
            {"July": 0},
            {"August": 0},
            {"September (Q3)": 0},
            {"October": 0},
            {"November": 0},
            {"December (Q4)": 0},
        ]);
        localStorage.setItem('roles', JSON.stringify(this.state.roles));
        localStorage.setItem('verification', JSON.stringify({"approve": true}));
        localStorage.setItem('companyWebsite', this.state.companyWebsite);
        localStorage.setItem('projectTitle', this.state.projectTitle);
        localStorage.setItem('projectWebsite', JSON.stringify(this.state.projectWebsite));
        localStorage.setItem('projectSubtitle', this.state.projectSubtitle);
        localStorage.setItem('projectDescription', this.state.projectDescription);
        localStorage.setItem('royaltyPercentage', this.state.royaltyPercentage);
        localStorage.setItem('projectMedium', this.state.projectMedium);
        localStorage.setItem('projectPerks', this.state.projectPerks);
        localStorage.setItem('videoPitch', JSON.stringify(this.state.videoPitch));
        localStorage.setItem('videoPromo', JSON.stringify(this.state.videoPromo));
        localStorage.setItem('socialMedia', JSON.stringify(this.state.socialMedia));
        localStorage.setItem('collaborators', JSON.stringify(this.state.collaborators));
        localStorage.setItem('ipPercentage', this.state.ipPercentage);
        localStorage.setItem('mainLanguage', this.state.mainLanguage);
        localStorage.setItem('additionalLanguage', JSON.stringify(this.state.additionalLanguage));
        localStorage.setItem('proofOfWork', this.state.proofOfWork);
        localStorage.setItem('ageRestrictions', this.state.ageRestrictions);
        localStorage.setItem('principals', JSON.stringify(this.state.principals));
        localStorage.setItem('mediaCategory', this.state.mediaCategory);
        localStorage.setItem('numOfLicenses', this.state.numOfLicenses);
        localStorage.setItem('licensesPercentageForCreator', this.state.licensesPercentageForCreator);
        localStorage.setItem('financingGoal', this.state.financingGoal);
        localStorage.setItem('rookieRank', this.state.rookieRank);
        localStorage.setItem('auctionTime', this.state.auctionTime);
        localStorage.setItem('attachItem', this.state.attachItem);
        localStorage.setItem('musicContentQuestion', this.state.musicContentQuestion);
        localStorage.setItem('additionalDataForUnlimited', JSON.stringify(this.state.additionalDataForUnlimited));
        localStorage.setItem('additionalDataForLimited', JSON.stringify(this.state.additionalDataForLimited));
        localStorage.setItem('submitPopup', this.state.submitPopup);
        localStorage.setItem('typeOfDownload', this.state.typeOfDownload);
        localStorage.setItem('logoAttach', JSON.stringify(this.state.logoAttach));
        // localStorage.setItem('promotionalBanners', JSON.stringify(this.state.promotionalBanners));
        localStorage.setItem('licenseType', this.state.licenseType);

        this.props.setBanners(JSON.stringify(this.state.promotionalBanners));


    };

    handleEraseLocal = () => {
        localStorage.removeItem('saved');
        localStorage.removeItem('stageName');
        localStorage.removeItem('royalty');
        localStorage.removeItem('roles');
        localStorage.removeItem('royaltyPercentage');
        localStorage.removeItem('verification');
        localStorage.removeItem('companyWebsite');
        localStorage.removeItem('projectTitle');
        localStorage.removeItem('projectWebsite');
        localStorage.removeItem('projectSubtitle');
        localStorage.removeItem('projectDescription');
        localStorage.removeItem('projectMedium');
        localStorage.removeItem('projectPerks');
        localStorage.removeItem('videoPitch');
        localStorage.removeItem('videoPromo');
        localStorage.removeItem('socialMedia');
        localStorage.removeItem('collaborators');
        localStorage.removeItem('ipPercentage');
        localStorage.removeItem('mainLanguage');
        localStorage.removeItem('additionalLanguage');
        localStorage.removeItem('proofOfWork');
        localStorage.removeItem('ageRestrictions');
        localStorage.removeItem('principals');
        localStorage.removeItem('mediaCategory');
        localStorage.removeItem('tvGenre');
        localStorage.removeItem('numOfLicenses');
        localStorage.removeItem('licensesPercentageForCreator');
        localStorage.removeItem('financingGoal');
        localStorage.removeItem('rookieRank');
        localStorage.removeItem('auctionTime');
        localStorage.removeItem('attachItem');
        localStorage.removeItem('additionalDataForUnlimited');
        localStorage.removeItem('musicContentQuestion');
        localStorage.removeItem('additionalDataForLimited');
        localStorage.removeItem('submitPopup');
        localStorage.removeItem('typeOfDownload');
        localStorage.removeItem('logoAttach');
        // localStorage.removeItem('promotionalBanners');
        localStorage.removeItem('licenseType');
        localStorage.removeItem('auctionItemNames');
        localStorage.removeItem('auctionItemInfo');
        localStorage.removeItem('investItemNames');
        localStorage.removeItem('investItemInfo');
    };


    removeCollabOrPrincipal = (email, listType) => {
        let cloneList;

        if (listType === 'collaborators') {
            cloneList = [...this.state.collaborators];
        } else {
            cloneList = [...this.state.principals];
        }

        const candidateIdx = cloneList.findIndex(e => e.email === email);

        cloneList.splice(candidateIdx, 1);

        this.setState({[listType]: cloneList});
    };

    _handleKeyPress = (e) => {
        const financingGoal = (e.target.validity.valid) ? e.target.value : this.state.financingGoal;
        this.setState({"financingGoal": financingGoal});
    };

    handleInputAmount = (e) => {
        this.setState({"financingGoal": e.replace(/\D/g, '')});
    };


    checkRequiredField = (fieldName, valueForCheck, action) => {
        if (this.state.auctionItemNames.length === 0 && document.querySelector('.uploadAuctionItems')) {
            document.querySelector('.uploadAuctionItems').style.backgroundColor = '#FFF2F2';
        } else if (this.state.investItemNames.length < 6 && document.querySelector('.uploadInvestItems')) {
            document.querySelector('.uploadInvestItems').style.backgroundColor = '#FFF2F2';
        } else if (this.state.licenseType === '') {
            document.getElementById('licenseTypeTitle').style.backgroundColor = 'rgb(267, 230, 230)';
        }

        let fieldIsValid = true;
        const firstValueForCheck = typeof this.state[fieldName] === 'object' ? this.state[fieldName].length : this.state[fieldName];
        const secondValueForCheck = typeof firstValueForCheck === 'number' ? +valueForCheck : valueForCheck;

        switch (action) {
            case 'compare':
                compare(firstValueForCheck, secondValueForCheck);
                break;
            case 'less':
                less(firstValueForCheck, secondValueForCheck);
                break;
        }

        function compare(firstValue, secondValue) {
            if (firstValue === secondValue) {
                fieldIsValid = false;
            }
        }

        function less(firstValue, secondValue) {
            if (firstValue < secondValue) {
                fieldIsValid = false;
            }
        }

        return fieldIsValid;
    };

    onLoadingEnd = () => {
        if (this.state.loadingStatus) {
            this.setState({
                creatorPopup: true,
                loadingStatus: false
            })
        }
    };

    setAdditionalData = (data, title, defaultValue) => {
        const nameOfField = title.replace(':', '');

        this.setState(({additionalData}) => {
            const newAdditionalData = {
                ...additionalData,
                [nameOfField]: data
            };
            const defaultValueIsObj = typeof defaultValue === 'object';


            if (newAdditionalData[nameOfField] === defaultValue ||
                (defaultValueIsObj && newAdditionalData[nameOfField].length === 0)) {
                delete newAdditionalData[nameOfField];
            }

            return ({additionalData: newAdditionalData});
        });
    };

    // setAttachItem = ({file, addToIpfs}) => {
    //   console.log(99999999, "--------------")
    //   console.log(file, addToIpfs)
    //   if (!file) return;
    //   this.addToIpfs = addToIpfs;
    //   let type = file.name.split(".")[1];
    //   let attachFileType;
    //   attachFileType = type === "pdf";
    //   this.setState({
    //     attachItem: file,
    //     attachFileType: attachFileType,
    //     attachFileName: file.name.split(".")[0]
    //   });
    //
    // };

    setItemsToIpfs = ({file, addToIpfs, licenseType, blockName, hash}) => {


        console.log(file, "---------{file-----{file", addToIpfs, '---addToIpfs', licenseType, '--licenseType')


        // if (this.state.auctionItemNames.includes(file.name) || this.state.investItemNames.includes(file.name)) return;
        if (file.type !== 'audio/wav' && file.type !== 'application/pdf' && file.type !== 'audio/mp3' && file.type !== 'audio/mpeg' && file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'video/mp4') {
            this.setState({
                [licenseType + 'FileWarning']: true
            });
            return
        } else {
            this.setState({
                [licenseType + 'FileWarning']: false
            })
        }
        this.addToIpfs = addToIpfs;


        let stateItem;
        let stateItemInfo;
        let stateItemNames;
        let itemsInfo = {};

        switch (licenseType) {
            case 'auction':
                stateItem = 'auctionItem';
                stateItemInfo = 'auctionItemInfo';
                stateItemNames = 'auctionItemNames';
                document.querySelector('.uploadAuctionItems').style.backgroundColor = 'white';
                break;
            case 'invest':
                stateItem = 'investItem';
                stateItemInfo = 'investItemInfo';
                stateItemNames = 'investItemNames';
                if (this.state.investItemInfo.length >= 5) {
                    document.querySelector('.uploadInvestItems').style.backgroundColor = 'white';
                }
                break;
            case 'additionalAttachmentsIpfs':
                stateItem = 'attachItem';
                stateItemInfo = 'attachItemInfoIPFS';
                stateItemNames = 'additionalItemNamesIPFS';
                if (this.state.investItemInfo.length >= 5) {
                    // document.querySelector('.additionalAttachmentsIpfs').style.backgroundColor = 'white';
                }
                break;
        }

        this.setState({[stateItem]: file, onLoadFile: true}, () => {
            new Promise(async (resolve) => {
                console.log(4444, "--------------4444", this.state[stateItem])
                let node = this.state.nodeIPFS;
                const {path} = await node.add(this.state[stateItem]);
                console.log(path, "--------------path")
                resolve(path);
            })
                .then(res => {
                    this.setState({
                        onLoadFile: false
                    });
                    itemsInfo.multiHash = res;
                    itemsInfo.type = this.state[stateItem].type;
                    itemsInfo.name = this.state[stateItem].name;
                    this.setState(prevState => {
                        localStorage.setItem([stateItemNames], JSON.stringify([
                            ...prevState[stateItemNames],
                            this.state[stateItem].name
                        ]));
                        localStorage.setItem([stateItemInfo], JSON.stringify([
                            ...prevState[stateItemInfo],
                            itemsInfo
                        ]));
                        return {
                            [stateItemNames]: [
                                ...prevState[stateItemNames],
                                this.state[stateItem].name
                            ],
                            [stateItemInfo]: [
                                ...prevState[stateItemInfo],
                                itemsInfo
                            ],
                        }
                    })
                });
        });
    };


    removeItem = ({name, blockName}) => {
        console.log(name, "--------------name", this.state.licenseType)
        const {
            licenseType,
            investItemNames,
            investItemInfo,
            auctionItemNames,
            attachItemInfoIPFS,
            additionalItemNamesIPFS,
            auctionItemInfo,
        } = this.state;

        if (blockName === 'additionalAttachmentsIpfs') {
            const updatedAdditionalItemNamesIPFS = additionalItemNamesIPFS.filter(item => item !== name);
            const updatedAttachItemInfoIPFS = attachItemInfoIPFS.filter(item => item.name !== name);
            this.setState({
                additionalItemNamesIPFS: updatedAdditionalItemNamesIPFS,
                attachItemInfoIPFS: updatedAttachItemInfoIPFS
            });
            localStorage.setItem('additionalItemNamesIPFS', JSON.stringify(updatedAdditionalItemNamesIPFS));
            localStorage.setItem('attachItemInfoIPFS', JSON.stringify(updatedAttachItemInfoIPFS));
        } else {
            if (licenseType === 'limited') {
                const updatedAuctionItemNames = auctionItemNames.filter(item => item !== name);
                const updatedAuctionItemInfo = auctionItemInfo.filter(item => item.name !== name);
                this.setState({auctionItemNames: updatedAuctionItemNames, auctionItemInfo: updatedAuctionItemInfo});
                localStorage.setItem('auctionItemNames', JSON.stringify(updatedAuctionItemNames));
                localStorage.setItem('auctionItemInfo', JSON.stringify(updatedAuctionItemInfo));
            } else {
                const updatedInvestItemNames = investItemNames.filter(item => item !== name)
                const updatedInvestItemInfo = investItemInfo.filter(item => item.name !== name)
                this.setState({investItemNames: updatedInvestItemNames, investItemInfo: updatedInvestItemInfo})
                localStorage.setItem('investItemNames', JSON.stringify(updatedInvestItemNames));
                localStorage.setItem('investItemInfo', JSON.stringify(updatedInvestItemInfo));
            }
        }


    };

    setInsuranceItem = async ({file, hash, blockName}) => {
        console.log(blockName, "--------------blockName")
        if (!file) return;


        let type = file.name.split(".")[1];
        let insuranceFileTypes;
        insuranceFileTypes = type === "pdf";

        console.log(file, "--------------file", insuranceFileTypes, '--------insuranceFileTypes', file.name.split(".")[0], '---insuranceFileName', hash, '--hash')
        this.setState({
            insuranceItem: file,
            insuranceFileType: insuranceFileTypes,
            insuranceFileName: file.name.split(".")[0],
            insuranceHash: hash
        });
    };

    closeModal = (status) => {
        !status && this.setState({
            creatorPopup: true
        }, () => {
            this.props.history.push({pathname: '/projects', search: `?id=${this.state.newProjectId}`})
        })
    };

    closeSubmit = () => {
        this.setState({
            submitPopup: false
        })
    };

    saveNumOfLicenses = percent => {
        this.setState({licensesPercentageForCreator: ''});

        if (percent.startsWith('Choose')) {
            percent = 'Unidentified'
            this.setState({
                numOfLicenses: percent,
                disabledLicensesPercentage: true,
                affordableInterestForCreator: ["Choose one option"],
            })
        } else {
            const numbOfLicense = +percent;
            const licensesPercentageForCreator = ["Choose one option"];

            for (let i = 0; i <= 100; i++) {
                const percent = numbOfLicense / 100 * i;
                const remainder = (percent % 1);
                if (remainder === 0 && percent === 0) {
                    licensesPercentageForCreator.push('None, this project is market-ready')
                } else if (remainder === 0) {
                    licensesPercentageForCreator.push(i + '%')
                }
            }

            this.setState({
                numOfLicenses: percent,
                disabledLicensesPercentage: false,
                affordableInterestForCreator: licensesPercentageForCreator
            })
        }
    };

    getQuantityOfTotalOwnership = (defaultValue) => {
        const quantityOfTotalOwnership = [defaultValue];

        for (let i = 2; i <= 77; i++) {
            const value = i === 77 ? 'Life of copyright' : `${i}`

            quantityOfTotalOwnership.push(value);
        }

        return quantityOfTotalOwnership;
    };

    saveLocalAndValidate = async () => {
        this.handleSaveLocal();

        this.setState({
            collabStatus: !this.state.collabStatus,
            principalStatus: !this.state.principalStatus
        })

        let allInvalidFields = [];

        const thisDomEl = document.getElementById('projectCreator')

        thisDomEl.querySelectorAll('[data-isrequiredfield="true"]').forEach((field) => {
            const valueForCheck = field.dataset.valueforcheck;
            const action = field.dataset.action;
            const isValidField = this.checkRequiredField(field.name, valueForCheck, action);

            if (isValidField) return;

            if (field.hidden) {
                // this.closeSubmit(false);
                field.parentElement.classList.add('fieldIsNotValid');
                allInvalidFields.push(field.parentElement);
                return;
            }
            field.classList.add('fieldIsNotValid');
            allInvalidFields.push(field);
        });

        if (allInvalidFields.length === 0) {
            this.setState({
                submitPopup: true,
            })
        } else {
            this.closeSubmit(false);
            allInvalidFields[0].scrollIntoView();
        }
    };

    createProReview = (statusPro, createProject) => {
        if (statusPro) {
            this.setState({proReview: statusPro});
            createProject();
        }
    };

    createProject = async () => {
        this.closeSubmit(false);

        this.setState({showProgressPopup: true});

        const {
            logoAttach,
            promotionalBanners,
            additonalAttachments,
            stageName,
            roles,
            royaltyPercentage,
            insuranceHash,
            companyWebsite,
            projectTitle,
            projectWebsite,
            projectSubtitle,
            projectDescription,
            projectMedium,
            projectPerks,
            socialMedia,
            videoPitch,
            videoPromo,
            licenseType,
            ipPercentage,
            tickerSymbol,
            mainLanguage,
            additionalLanguage,
            proofOfWork,
            ageRestrictions,
            mediaCategory,
            numOfLicenses,
            licensesPercentageForCreator,
            financingGoal,
            rookieRank,
            auctionTime,
            auctionItemInfo,
            investItemInfo,
            attachItemInfoIPFS,
            attachItem,
            additionalData,
            additionalDataForUnlimited,
            additionalDataForLimited,
            musicContentQuestion,
            showAuctionData,
            proReviewStatus,
            showInvestData,
            principals
        } = this.state;

        const attachItemInfo = {};

        if (attachItem !== '') {
            attachItemInfo.attachHash = await this.addToIpfs(attachItem);
            attachItemInfo.type = attachItem.type;
            attachItemInfo.name = attachItem.name;
        }

        const newPromotionalBanners = [];

        this.getAmazonUrl(logoAttach[0], (url) => {
            logoAttach[0] = url;
            if (promotionalBanners.length !== 0) {
                promotionalBanners.forEach(file => {
                    this.getAmazonUrl(file, (url) => {
                        newPromotionalBanners.push(url);
                        uploadAdditonalAttachments(this);
                    });
                });
            } else if (additonalAttachments.length !== 0) {
                uploadAdditonalAttachments(this);
            } /*else {
                if (videoPromo.length === 1) {
                    if (videoPromo[0].thumbnailData) {
                        this.getAmazonUrl(videoPromo[0].thumbnailData.src, (url) => {
                            delete videoPromo[0].thumbnailData;
                            videoPromo[0].thumbnailUrl = url;


                            if (videoPitch.length === 1) {
                                if (videoPitch[0].thumbnailData) {
                                    this.getAmazonUrl(videoPitch[0].thumbnailData.src, (url) => {
                                        delete videoPitch[0].thumbnailData;
                                        videoPitch[0].thumbnailUrl = url;
                                        finalizeAll(this)
                                    });
                                } else {
                                    finalizeAll(this)
                                }
                            } else {
                                finalizeAll(this)
                            }
                        });
                    } else {
                        finalizeAll(this)
                    }
                } else if (videoPitch.length === 1) {
                    if (videoPitch[0].thumbnailData) {
                        this.getAmazonUrl(videoPitch[0].thumbnailData.src, (url) => {
                            delete videoPitch[0].thumbnailData;
                            videoPitch[0].thumbnailUrl = url;
                            finalizeAll(this)
                        });
                    } else {
                        finalizeAll(this)
                    }
                } else {
                    finalizeAll(this)
                }
            }*/
        });

        function uploadAdditonalAttachments(e) {
            if (additonalAttachments.length !== 0) {
                additonalAttachments.map((file, index) => {
                    e.getAmazonUrl(file.src, (url) => {
                        additonalAttachments[index] = url;
                        if (index === additonalAttachments.length - 1) {
                            finalizeAll(e)
                        }
                    })
                })
            } else {
                if (newPromotionalBanners.length !== promotionalBanners.length) return;

                finalizeAll(e);
            }
        }

        const finalizeAll = (e) => {
            console.log(this.state.customFieldsState, "--------------this.state.customFieldsState")
            const saveData = [
                {
                    field: 'creator', value: {
                        __type: "Pointer",
                        className: "_User",
                        objectId: Parse.User.current().id
                    }
                },

                {field: 'roles', value: roles},
                {
                    field: 'royalty',

                    value: [{"January": 0},
                        {"February": 0},
                        {"March (Q1)": 0},
                        {"April": 0},
                        {"May": 0},
                        {"June (Q2)": 0},
                        {"July": 0},
                        {"August": 0},
                        {"September (Q3)": 0},
                        {"October": 0},
                        {"November": 0},
                        {"December (Q4)": 0},]
                },

                //in database
                {field: 'projectTitle', value: projectTitle},
                {field: 'projectSubtitle', value: projectSubtitle},
                {field: 'projectDescription', value: projectDescription},
                {field: 'projectPerks', value: projectPerks},
                {field: 'logoAttach', value: logoAttach},
                {field: 'videoPitch', value: videoPitch},
                {field: 'videoPromo', value: videoPromo},
                {field: 'promotionalBanners', value: newPromotionalBanners},
                {field: 'proReviewStatus', proReviewStatus},
                {field: 'musicContentQuestion', musicContentQuestion},
                {field: 'verification', value: {"approve": true}},
                {field: 'projectMedium', value: projectMedium},

                //


                //may be in bc
                {field: 'stageName', value: stageName},
                {field: 'socialMedia', value: socialMedia},
                {field: 'projectWebsite', value: projectWebsite},
                {field: 'companyWebsite', value: companyWebsite},


                //bch
                {field: 'additionalLanguage', value: additionalLanguage},
                {field: 'mainLanguage', value: mainLanguage},
                {field: 'tickerSymbol', value: tickerSymbol},
                {field: 'proofOfWork', value: proofOfWork},
                {field: 'ageRestrictions', value: ageRestrictions},
                {field: 'principals', value: principals},
                {field: 'insuranceHash', value: insuranceHash},


                {field: 'licenseType', value: licenseType},
                {field: 'additonalAttachments', value: additonalAttachments},
                {field: 'ipPercentage', value: ipPercentage},

                {field: 'mediaCategory', value: mediaCategory},
                {field: 'numOfLicenses', value: numOfLicenses},
                {field: 'licensesPercentageForCreator', value: licensesPercentageForCreator},
                {field: 'financingGoal', value: financingGoal},
                {field: 'rookieRank', value: rookieRank},
                {field: 'auctionTime', value: auctionTime},
                {field: 'auctionItems', value: auctionItemInfo},
                {field: 'investItems', value: investItemInfo},

                {field: 'attachItem', value: attachItemInfo},
                {field: 'attachItemInfoIPFS', value: attachItemInfoIPFS},
                {field: 'remainedLicenses', value: parseInt(numOfLicenses) >= 10 ? (parseInt(numOfLicenses) - 10) : 0},
                {field: 'additionalData', additionalData},
                {field: 'additionalDataForUnlimited', additionalDataForUnlimited},
                {field: 'additionalDataForLimited', additionalDataForLimited},

            ];

            if (licenseType === 'limited') {
                saveData.push({field: 'showAuctionData', value: showAuctionData})
            } else {
                saveData.push({field: 'showInvestData', value: showInvestData})
            }
            if (!this.state.proReview) {
                sendParseRequest('createDatabaseObject', {query: 'Projects', saveData})
                    .then((saveResult) => {
                            const newProjectId = saveResult.id;

                            this.setState({newProjectId})

                            sendParseRequest('createDatabaseObject', {
                                query: 'Reserved_Symbols', saveData: [
                                    {field: 'reservedSymbols', value: this.state.tickerSymbol},
                                    {
                                        field: 'user',
                                        value: {__type: "Pointer", className: "_User", objectId: Parse.User.current().id}
                                    }
                                ]
                            });

                            if (e.state.collaborators.length > 0) {
                                e.state.collaborators.forEach(async collaborator => {
                                    await addPrincipalOrCollaborator(collaborator, newProjectId, projectTitle, 'collaborator', collaborator.type)
                                })
                            }

                            if (e.state.principals.length > 0) {
                                e.state.principals.forEach(async principal => {
                                    await addPrincipalOrCollaborator(principal, newProjectId, projectTitle, 'principal', principal.type)
                                })
                            }


                            const walletAddress = this.props.mainWalletAddress;

                            //this IF need for check submit type. we have proReview in state. As default - this is
                            // false. If user want to do pro Review => he click on pro review button=> proReview on state
                            // changed to true. this mean that project should be create in database, but not MINT.
                            // after save in database, admin can change project fields and next user can mint this.
                            // if user click on MINT => proReview is false as default and project should be mint on blockchain.


                            /**
                             * create project with project id(that will be TokenURI) from db
                             * @param uri The Project ID that will be TokenURI of the ERC721 token on the blockchain
                             * @param from The address for the user who create project
                             * @param count The count of licenses
                             * @param auction_required the flag if the auction is required for the transaction
                             * @param ipPercentage the value of percentage that the project will be met for realease.
                             * @param POW the value of proof of work (only available if ipPercentage is not none of this ...)
                             * @param goal the value of project when it is created to get invested (only available if ipPercentage is not none of this ...)
                             * @param licenseType the value that determin to create collectibles(ERC721) or Tickets(ERC20)
                             * @param symbol the value of ticker Symbol
                             */

                                // console.log(additionalData, "--------------additionalData")
                            let customFields = this.prepareCustomFields();


                            let additionDataForBlockchain = {
                                //in database
                                'royalty': royaltyPercentage,
                                'projectTitle': projectTitle,
                                'projectDescription': projectDescription,
                                'stageName': stageName,
                                'socialMedia': socialMedia,
                                'projectWebsite': projectWebsite,
                                'attachItemInfoIPFS': attachItemInfoIPFS,
                                'companyWebsite': companyWebsite,
                                'additionalLanguage': additionalLanguage,
                                'mainLanguage': mainLanguage,
                                'tickerSymbol': tickerSymbol,
                                'proofOfWork': proofOfWork,
                                'ageRestrictions': ageRestrictions,
                                'principals': principals,
                                'insuranceHash': insuranceHash,
                                'licenseType': licenseType,
                                'auctionItems': auctionItemInfo,
                                'additonalAttachments': additonalAttachments,
                                'ipPercentage': ipPercentage,
                                'mediaCategory': mediaCategory,
                                'numOfLicenses': numOfLicenses,
                                'licensesPercentageForCreator': licensesPercentageForCreator,
                                'financingGoal': financingGoal,
                                'rookieRank': rookieRank,
                                'auctionTime': auctionTime,
                                'investItems': investItemInfo,
                                'attachItem': attachItemInfo,
                                'remainedLicenses': parseInt(numOfLicenses) >= 10 ? (parseInt(numOfLicenses) - 10) : 0,
                                'additionalData': additionalData,
                                'additionalDataForUnlimited': additionalDataForUnlimited,
                                'additionalDataForLimited': additionalDataForLimited,
                                'dataForSecurity': this.state.licenseType !== "limited" ? this.state.dataForSecurity : null,
                                'customFields': customFields
                            };


                            // console.log(additionDataForBlockchain, "--------------additionDataForBlockchain")
                            console.log(walletAddress, "--------------walletAddress1111111")
                        //set royalty percentage for store in blockchain;
                            let royaltyValue=0;
                            // e.startsWith('None') ? royaltyValue = 0 : royaltyValue = +`0.${this.state.royaltyPercentage.split('%')[0]}`

                            console.log({
                                to: walletAddress,
                                count: (this.state.numOfLicenses === "Unidentified") ? this.state.massMarketQuantity : parseInt(this.state.numOfLicenses),
                                tokenURI: newProjectId,
                                aucReq: true,
                                goal: this.state.financingGoal,
                                pow: this.state.proofOfWork,
                                ipPercentage: this.state.ipPercentage,
                                licenseType: this.state.licenseType,
                                symbol: this.state.tickerSymbol,
                                metadata: additionDataForBlockchain,
                                royaltyFee: royaltyValue
                            }, "-----------");


                            // EthApi.contract.project.createProject({
                            //         to: walletAddress,
                            //         id: newProjectId.toUpperCase(),
                            //         count: (this.state.numOfLicenses === "Unidentified") ? this.state.massMarketQuantity : parseInt(this.state.numOfLicenses),
                            //         tokenURI: newProjectId,
                            //         aucReq: true,
                            //         goal: this.state.financingGoal,
                            //         pow: this.state.proofOfWork,
                            //         ipPercentage: this.state.ipPercentage,
                            //         licenseType: this.state.licenseType,
                            //         symbol: this.state.tickerSymbol,
                            //         metadata: additionDataForBlockchain,
                            //         royaltyFee: royaltyValue
                            //     },
                            //     async () => {
                            //         this.handleEraseLocal();
                            //         this.setState({projectWasCreate: true, projectId: newProjectId});
                            //         // console.log(newProjectId, "--------------newProjectId")
                            //
                            //         ////{TODO: need to fix this shit}
                            //         // const projectDetail = await ethClient.project.getProjectDetailByTokenURI(newProjectId);
                            //         // const tokenAddress = projectDetail.tokenAddress;
                            //
                            //
                            //         // console.log(projectDetail, "--------------projectDetail")
                            //         //approve tix as amount for presale
                            //         //TODO: check  ethClient.project.getProjectDetailByTokenURI
                            //         const ipPercentage = this.state.ipPercentage;
                            //         const licenseType = this.state.licenseType;
                            //
                            //         let amount;
                            //         if (ipPercentage === "Unidentified") {
                            //             amount = 0
                            //         } else {
                            //             amount = 1000000 * parseFloat(ipPercentage.replace("%", "")) / 100
                            //         }
                            //         // this.props.approveTixForMatcherSaga(walletAddress, "password", tokenAddress, amount);
                            //
                            //     });

















                            // }
                            // else {
                            //
                            //
                            //
                            //
                            //
                            //
                            //
                            //   this.setState({projectWasCreate: true, projectId: newProjectId})
                            // }


                        }, (
                        error
                        ) => {
                            message('error', error.message);
                        }
                    );
            } else {
                saveData.push({field: 'proReview', value: true})
                console.log(saveData, "--------------saveData");
                sendParseRequest('createDatabaseObject', {query: 'Projects', saveData})
                    .then((saveResult) => {
                        console.log(saveResult, "--------------saveResult")
                    })

            }
        }
    }
    ;


    render() {
        const {
            showProgressPopup,
            projectWasCreate,
            creatorPopup,
            submitPopup,
            auctionItemNames,
        } = this.state;

        if (this.props.banners.length <= 0) {

            console.log(555552222222222222222, "--------------555522222222222222")
            console.log('----royaltyPercentage', +`0.${this.state.royaltyPercentage.split('%')[0]}`, '----royaltyPercentage')
            console.log(222222222222222222222, "--------------222222222222222222222")
            console.log(this.props.banners, 'this.props.banners')
        }
        const categoryDetails = mediaDetails[this.state.mediaCategory];
        const dataForTypeOfUnlimitedLicense = [
            {
                text: 'I am tokenizing the company or intellectual property as an ERC-1155 Mass Market token. I accept these',
                className: 'radioBtnForUnlimited',
                linkToTerms: '/terms',
                checked: true
            },
            {
                text: 'I am tokenizing the royalties or sharing dividends as an ERC-1155 Mass Market token for a limited period of time. I accept these',
                shouldOpenOtherBlock: true,
                className: 'radioBtnForUnlimited',
                linkToTerms: '/terms'
            }
        ];

        const dataForUnlimitedLicenseAccept = [
            {
                text: 'I accept these',
                className: 'radioBtnForUnlimited',
                linkToTerms: '/terms',
                checked: true
            },
            {
                text: 'I decline these',
                className: 'radioBtnForUnlimited',
                linkToTerms: '/terms'
            }
        ];

        const musicContentQuestion = [
            {
                text: 'Yes',
                className: 'radioBtnForUnlimited',
                linkToTerms: ''
            },
            {
                text: 'No',
                className: 'radioBtnForUnlimited',
                linkToTerms: ''
            },
        ];

        const dataForTypeOfLimitedLicense = [
            {
                text: 'I am pre-selling certificates of authenticity for this digital collectible.',
                className: 'radioBtnForUnlimited',
                linkToTerms: '',
                checked: true
            },
            // Commented out as directed by Ronald
            // {
            //     text: 'I am pre-selling a percentage of the intellectual property; I am not pre-selling certificates of authenticity for this collectible.',
            //     className: 'radioBtnForUnlimited',
            //     linkToTerms: ''
            // },
        ];

        const dataForTypeOfDownload = [
            {
                text: 'Yes',
                className: 'radioBtnForUnlimited',
                linkToTerms: '',
                checked: this.state.typeOfDownload === 'Yes'
            },
            {
                text: 'No',
                className: 'radioBtnForUnlimited',
                linkToTerms: '',
                checked: this.state.typeOfDownload === 'No'
            }
        ];

        console.log("STATE Contract", this.state)

        return (
            <div className="page indexPageWrap">
                <PopupWithProgressBar showPopup={showProgressPopup}
                                      loaded={projectWasCreate}
                                      onLoadingEnd={this.onLoadingEnd}
                />
                {creatorPopup && <CreatorPopup type={this.state.licenseType}
                                               projectId={this.state.projectId}
                                               additionalDataForLimited={this.state.additionalDataForLimited.limitedLicenseType}
                                               closeModal={() => this.closeModal()}
                                               statusPopup={creatorPopup}
                />
                }
                <div className="creator-main-wrapper" id="projectCreator">
                    {submitPopup && <SubmitContract createProReview={this.createProReview} props={this.state}
                                                    closeSubmit={this.closeSubmit} createProject={this.createProject}/>}

                    <p className="section-title">Create a Project</p>
                    {this.state.reviewProjects ? <div className='projectReview'>
                        <span>You have project(s) on active PRO REVIEW NOW.</span>
                        {this.state.reviewProjects.map((i) =>
                            <p>Title: {i.get('projectTitle')}</p>
                        )}
                        <br/>
                        <span>We will notify you when the project is approved.</span>
                    </div> : null}
                    <div className="section-main">
                        <p className="section-sub-title">Project Details</p>
                        <div className="section-left-side">
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Project Title:
                                    <InfoTooltip position="top"
                                                 message="The project title is the working title. You can edit, refine, or change this title at any time.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span style={{"paddingLeft": "7px"}}
                                          className="character-count">
                    {100 - this.state.projectTitle.length}
                  </span>
                                    <span className="required-star">Required *</span>
                                </div>
                                <input data-isrequiredfield="true"
                                       name="projectTitle"
                                       data-valueforcheck=""
                                       data-action="compare"
                                       value={this.state.projectTitle}
                                       maxLength="100"
                                       onChange={(e) => this.handleGeneralInput(e.target.value, 'projectTitle', e.target)}
                                       type="text"
                                       placeholder="i.e. Ghost Busters, Lebron James Trading Cards, Jaws"/>
                            </div>
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Tagline:
                                    <InfoTooltip position="top"
                                                 message="The tagline is an aspect of your pitch. You can edit and refine this tagline at any time.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="character-count">
                    {150 - this.state.projectSubtitle.length}
                  </span>
                                </div>
                                <input value={this.state.projectSubtitle} maxLength="150"
                                       onChange={(e) => this.handleGeneralInput(e.target.value, 'projectSubtitle')}
                                       type="text" placeholder="i.e. Who you gonna call?"/>
                            </div>
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Description:
                                    <InfoTooltip position="top"
                                                 message="The description is an aspect of your pitch. You can edit and refine this description at any time, but you cannot make misleading claims. You cannot discuss your project as a money making enterprise.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="character-count">
                    {1000 - this.state.projectDescription.length}
                  </span>
                                </div>
                                <textarea value={this.state.projectDescription}
                                          maxLength="1000"
                                          onChange={(e) => this.handleGeneralInput(e.target.value, 'projectDescription')}
                                          rows="12"
                                          placeholder="The description is the synopsis, concept, or summary. It is often shared in story form: i.e. Imagine a world where (dinosaurs roam the earth)..."/>
                            </div>
                        </div>
                        <div className="section-right-side">
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Logo:
                                    <InfoTooltip position="top"
                                                 message="The logo will be prominent when people buy, sell and trade this item. You can edit and repost this logo at any time.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="required-star">Required *</span>
                                </div>

                                {this.state.loadDidMount &&
                                <AttachmentsWrapper inputName="logoAttach"
                                                    state={this.state}
                                                    isRequired={true}
                                                    valueForCheck={0}
                                                    action="compare"
                                                    defaultValue={this.state.logoAttach}
                                                    chosenData={this.state.logoAttach}
                                                    extraClasses="promotional-banners-attach logo-banner"
                                                    maxUploads={1}
                                                    maxFileSize="2.5"
                                                    handleChange={(e) => this.setState({"logoAttach": e})}
                                                    showBtn={true}
                                />
                                }
                            </div>
                            <div className="section-input-wrap gap gapPerks">
                                <div className="s-input-title">
                                    Perks:
                                    <InfoTooltip position="top"
                                                 message="The perks are an aspect of your pitch, but are not an aspect of the contract. You can refine the perks without breaking the contract, however, any changes could be flagged by the community and generate negative publicity.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="character-count">
                    {2500 - this.state.projectPerks.length}
                  </span>
                                </div>
                                <textarea value={this.state.projectPerks}
                                          maxLength="2500"
                                          onChange={(e) => this.handleGeneralInput(e.target.value, 'projectPerks')}
                                          rows="12"
                                          placeholder="The perks are events, screenings, releases, giveaways, and titles like Executive Producer that community members should expect, based on the criteria you establish."/>
                            </div>
                        </div>
                    </div>
                    <div className="section-main">
                        <p className="section-sub-title">Project Attachments</p>
                        <div className="section-left-side">
                            <div className="section-input-wrap">
                                <p className="s-input-title">Promotional Video:
                                    <InfoTooltip position="top"
                                                 message="The promotional video is an advertisement which promotes the project. It is sometimes called a teaser.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip></p>
                                {this.state.videoPitch && <input type="text" placeholder='https://www.youtube.com/...'
                                    // value={this.state.videoPitch[0].videoLink}
                                                                 value={this.state.videoPitch[0].videoLink}
                                                                 onChange={e => this.setState({
                                                                     videoPitch: [{
                                                                         "thumbnailUrl": "",
                                                                         "videoLink": e.currentTarget.value,
                                                                         "showUp": "true"
                                                                     }]
                                                                 })}/>}
                            </div>
                            {/*<YoutubeLinkThumb chosenData={this.state.videoPromo} title="Promotional Video:"
                                              placeholder="http:// This is a two minute teaser video"
                                              handleChange={(e) => this.setState({"videoPromo": e})}
                                              infoMessage="Teaser videos are slick, professionally produced ads which promote or sell the media product."/>*/}
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Promotional Images:
                                    <InfoTooltip position="top"
                                                 message="The promotional images will be displayed at the top of your project page. You must upload five promotional images. You can edit and reorganize the images at any time.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span
                                        className="character-count">Up to 5</span>
                                    <span className="required-star">Required * &nbsp;</span>
                                </div>
                                {
                                    this.state.loadDidMount &&
                                    <AttachmentsWrapper isRequired={true}
                                                        inputName="promotionalBanners"
                                                        action="less"
                                                        valueForCheck={5}
                                                        chosenData={this.state.promotionalBanners}
                                                        chosenData2={this.state.promotionalBanners}
                                                        maxUploads={5}
                                                        maxFileSize="5"
                                                        extraClasses="promotional-banners-attach"
                                                        handleChange={(e) => this.setState({"promotionalBanners": e})}/>
                                }
                            </div>
                        </div>
                        <div className="section-right-side">
                            <div className="section-input-wrap">
                                <p className="s-input-title">Pitch Video:
                                    <InfoTooltip position="top"
                                                 message="The pitch video is a personal appeal to your community where you discuss the merits of your project. Do not include anyone in the video who is not a principal or lead creator.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip></p>

                                {this.state.videoPromo && <input type="text" placeholder='https://www.youtube.com/...'
                                                                 value={this.state.videoPromo[0].videoLink}
                                                                 onChange={e => this.setState({
                                                                     videoPromo: [{
                                                                         "thumbnailUrl": "",
                                                                         "videoLink": e.currentTarget.value,
                                                                         "showUp": "true"
                                                                     }]
                                                                 })}/>}
                            </div>
                            {/*<YoutubeLinkThumb chosenData={this.state.videoPitch} title="Pitch Video:"
                                              placeholder="http:// This is a two minute personal appeal"
                                              handleChange={(e) => this.setState({"videoPitch": e})}
                                              infoMessage="The pitch video is the personal or emotional appeal to your audience. Include you and your team discussing the merits of the project. You should not include anyone in the video who is not contractually bound to the project."/>*/}
                        </div>
                    </div>
                    <div className="section-main">


                        <div className="section-sub-title">
                            Project Team
                            <div className='creator-team-collab'>
                                <p className="section-sub-title"
                                   onClick={() => this.setState({"showCollab": !this.state.showCollab})}>Add
                                    Collaborators</p>
                                <InfoTooltip position="top"
                                             message="Collaborators are contractual members of the team. You can change collaborators without breaching investor contracts, unlike your Principals list. Do not include Principals here. You will list Principals in the next section.">
                                    <i className="fa fa-info-circle"/>
                                </InfoTooltip>
                                <span className="character-count">Up to 25</span>
                            </div>
                        </div>
                        <div className="section-left-side add__block">
                            <div className="collaborators-all-wrap">
                                {this.state.collaborators.map((e, idx) => {
                                    return <span key={e.email + idx}
                                                 onClick={() => this.removeCollabOrPrincipal(e.email, 'collaborators')}>
                    {e.email}
                  </span>
                                })
                                }
                                {this.state.collaborators.length < 25 ?
                                    <a onClick={() => this.setState({"showCollab": !this.state.showCollab})}
                                       className={this.state.showCollab ? "add-collab-bttn close" : "add-collab-bttn"}>
                                        <svg width="20" height="20" viewBox="0 0 24 24">
                                            <path
                                                d="M14.8284 12l4.2427 4.2426c.781.781.781 2.0474 0 2.8285-.781.781-2.0474.781-2.8285 0L12 14.8284l-4.2426 4.2427c-.781.781-2.0474.781-2.8285 0-.781-.781-.781-2.0474 0-2.8285L9.1716 12 4.9289 7.7574c-.781-.781-.781-2.0474 0-2.8285.781-.781 2.0474-.781 2.8285 0L12 9.1716l4.2426-4.2427c.781-.781 2.0474-.781 2.8285 0 .781.781.781 2.0474 0 2.8285L14.8284 12z"
                                                fillRule="evenodd"/>
                                        </svg>
                                    </a>
                                    : ""
                                }
                            </div>
                            {this.state.showCollab &&
                            <AddTeamMember membersList={this.state.collaborators}
                                           type='collaborator'
                                           handleChange={(e) => this.setState({
                                               collaborators: e,
                                               showCollab: false
                                           })}
                            />
                            }
                        </div>
                    </div>

                    <p className="section-title">Create a Contract</p>
                    <div className="section-main">
                        <div className="section-sub-title">Creator Contract</div>
                        <div className="section-left-side">
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Name, Company, Alias or Stage Name:
                                    <InfoTooltip position="top"
                                                 message="The name you submit should match your ID. Only choose an alias if you represent a legal person, like a company or band, with its own trademark.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="required-star">Required *</span>
                                </div>
                                <input value={this.state.stageName}
                                       data-isrequiredfield="true"
                                       name="stageName"
                                       data-valueforcheck=""
                                       className="stageNameId"
                                       data-action="compare"
                                       onChange={(e) => this.handleGeneralInput(e.target.value, 'stageName', e.target)}
                                       type="text"
                                       placeholder="i.e. Brad Pitt, Disney, Fanboy, Banksy, or The Rolling Stones"/>
                            </div>
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Project Website:
                                    <InfoTooltip position="top"
                                                 message="The project website will help the community verify the authenticity of the project.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                </div>
                                <input defaultValue={this.state.projectWebsite ? this.state.projectWebsite[0].link : ""}
                                       onChange={(e) => this.handleGeneralInput(e.target.value, 'projectWebsite')}
                                       style={{"marginTop": "10px"}}
                                       type="text"
                                       placeholder="https://www..."
                                />
                            </div>
                        </div>
                        <div className="section-right-side">
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Creator Role(s):
                                    <InfoTooltip position="top"
                                                 message="The creator roles are contractual. You cannot change this creator role without breaching the contract so choose only one role, if unsure, to avoid breaching the contract later.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="required-star">Required *</span>
                                </div>
                                <TagInput placeholder="Choose up to three roles"
                                          isRequired={true}
                                          valueForCheck={0}
                                          action="compare"
                                          inputName="roles"
                                          refName="role"
                                          chosenData={this.state.roles}
                                          maxSize="3"
                                          onChange={(e) => this.setState({"roles": e})}
                                          data={rolesArray}
                                />
                            </div>
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Company Website:
                                    <InfoTooltip position="top"
                                                 message="The company website will help the community verify the authenticity of your company. Do not include the link unless the project is majority owned by this company.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                </div>
                                <input value={this.state.companyWebsite}
                                       onChange={(e) => this.handleGeneralInput(e.target.value, 'companyWebsite')}
                                       type="text"
                                       placeholder="https://www..."/>
                            </div>
                        </div>
                        <div className="section-input-wrap">
                            <div style={{marginBottom: "22px"}} className="s-input-title">
                                Social Media & Explorer:
                                <InfoTooltip position="top"
                                             message="The social media and block explorer links will be connected to token scanner sites. You cannot exceed three links in the contract.">
                                    <i className="fa fa-info-circle"/>
                                </InfoTooltip>
                            </div>
                            <TagInput placeholder="Choose up to three roles"
                                      isRequired={true}
                                      valueForCheck={0}
                                      action="compare"
                                      inputName="roles"
                                      refName="role"
                                      chosenData={this.state.socialsArr}
                                      maxSize="3"
                                      onChange={(e) => this.setState({"roles": e})}
                                      data={rolesArray}
                                      type='socials'
                                      updateSocial={this.updateSocial}
                            />
                            <div className="section-input-wrap">

                                {/*<input type="text" placeholder='https://www...'*/}
                                {/*       value={this.state.socialMedia[0].link}*/}
                                {/*       onChange={e => this.setState({*/}
                                {/*         socialMedia: [{*/}
                                {/*           link: e.currentTarget.value,*/}
                                {/*           showUp: "true"*/}
                                {/*         }]*/}
                                {/*       })}/>*/}
                            </div>
                        </div>
                    </div>
                    <div className="section-main">

                        <div className="section-sub-title">
                            Project Team
                            <div className='creator-team-collab'>
                                <p className="section-sub-title"
                                   onClick={() => this.setState({"showPrincipalsBlock": !this.state.showPrincipalsBlock})}> Add
                                    Principals</p>
                                <InfoTooltip position="top"
                                             message="The principals are key individuals who are contractually bound to the project. If a principal leaves the project, the contract is breached and financing is returned to investors. Generally this occurs through the insurance policy.">
                                    <i className="fa fa-info-circle"/>
                                </InfoTooltip>
                                <span className="character-count">Up to 3</span>
                            </div>
                        </div>
                        <div className="section-left-side add__block">
                            <div className="collaborators-all-wrap">
                                {this.state.principals.map((e, idx) => {
                                    return <span key={e.email + idx}
                                                 onClick={() => this.removeCollabOrPrincipal(e.email, 'principals')}>
                    {e.email}
                  </span>
                                })
                                }
                                {this.state.principals.length < 3 ?
                                    <a onClick={() => this.setState({"showPrincipals": !this.state.showPrincipals})}
                                       className={this.state.showPrincipals ? "add-collab-bttn close" : "add-collab-bttn"}>
                                        <svg width="20" height="20" viewBox="0 0 24 24">
                                            <path
                                                d="M14.8284 12l4.2427 4.2426c.781.781.781 2.0474 0 2.8285-.781.781-2.0474.781-2.8285 0L12 14.8284l-4.2426 4.2427c-.781.781-2.0474.781-2.8285 0-.781-.781-.781-2.0474 0-2.8285L9.1716 12 4.9289 7.7574c-.781-.781-.781-2.0474 0-2.8285.781-.781 2.0474-.781 2.8285 0L12 9.1716l4.2426-4.2427c.781-.781 2.0474-.781 2.8285 0 .781.781.781 2.0474 0 2.8285L14.8284 12z"
                                                fillRule="evenodd"/>
                                        </svg>
                                    </a>
                                    : ""
                                }
                            </div>
                            {this.state.showPrincipals &&
                            <AddTeamMember membersList={this.state.principals}
                                           type='principal'
                                           handleChange={(e) => this.setState({
                                               principals: e,
                                               showPrincipals: false
                                           })}
                            />
                            }
                        </div>
                    </div>
                    <div className="section-main">
                        <div className="section-sub-title">Contract Details</div>
                        <div className="section-left-side">
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Media Category:
                                    <InfoTooltip position="top"
                                                 message="The media category you select will help you focus your contract with your community. Additional questions appear once you select the category.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="required-star">Required *</span>
                                </div>
                                <TagInput placeholder="Choose one media category"
                                          isRequired={true}
                                          action="compare"
                                          valueForCheck=""
                                          inputName="mediaCategory"
                                          refName="mediaCategory"
                                          chosenData={this.state.mediaCategory}
                                          maxSize="2"
                                          onChange={(e) => {
                                              if (e.startsWith('Choose')) {
                                                  e = ''
                                              }
                                              this.setState({mediaCategory: e, additionalData: {}})
                                          }}
                                          isSingleSelect={true}
                                          defaultValue='Unidentified'
                                          data={mediaCategory}/>
                            </div>
                            {this.state.mediaCategory === "Music, Beats, Songs & Sounds" &&
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Is there a music video included as part of the collectible?
                                </div>
                                <RadioBtnBlock data={musicContentQuestion}
                                               selectedData={this.state.musicContentQuestion}
                                               onSelectType={this.handleGeneralInput}
                                               name="musicContentQuestion"
                                               fieldNameForChange="musicContentQuestion"
                                               checkboxType="musicContentQuestion"
                                               checkboxTypeSelected={this.checkboxTypeSelected}
                                />
                            </div>
                            }
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Main Language:
                                    <InfoTooltip position="top"
                                                 message="The main language of your media must be identified. If it is a film, this would be the predominant spoken language. If it is a magazine it is the predominant text language.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="required-star">Required *</span>
                                </div>
                                <TagInput placeholder="Choose one main language"
                                          isRequired={true}
                                          valueForCheck=""
                                          action="compare"
                                          inputName="mainLanguage"
                                          refName="mainLanguage"
                                          chosenData={this.state.mainLanguage}
                                          maxSize="2"
                                          onChange={(e) => {
                                              if (e.startsWith('Choose')) {
                                                  e = ''
                                              }
                                              this.setState({"mainLanguage": e})
                                          }}
                                          isSingleSelect={true}
                                          defaultValue='Unidentified'
                                          data={["Choose one main language", ...languageArray]}/>
                            </div>
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Additional Language:
                                    <InfoTooltip position="top"
                                                 message="The additional language version must be completed or the contract will be breached. These include subtitles, voice overs or text. If unsure, leave blank, to avoid breaching the contract.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                </div>
                                <TagInput placeholder="Choose one additional language"
                                          refName="additionalLanguage"
                                          chosenData={this.state.additionalLanguage}
                                          maxSize="1"
                                          onChange={(e) => {
                                              this.setState({"additionalLanguage": e})
                                          }}
                                          data={languageArray}/>
                            </div>
                        </div>
                        <div className="section-right-side">
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Ticker Symbol:
                                    <InfoTooltip position="top"
                                                 message="The ticker symbol will identify your ticket or token as a tradable item.  It is not editable once forged.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="required-star">
                    Required * <span className="character-count">min 6</span>
                  </span>
                                </div>
                                <input defaultValue={this.state.tickerSymbol}
                                       data-valueforcheck=""
                                       data-action="compare"
                                       data-isrequiredfield="true"
                                       name="tickerSymbol"
                                       minLength={6}
                                       maxLength={10}
                                       onBlur={(e) => this.checkReservedSymbols(e.target.value)}
                                       type="text"
                                       placeholder="i.e. NKOTB1, BTS1, NSYNC98"
                                       id='tickerSymbolInput'
                                />
                            </div>
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Proof of Work:
                                    <InfoTooltip position="top"
                                                 message="The proof of work option allows the community to vote on each round of financing. To receive financing, a majority upvote is required. Once an upvote is granted it cannot be undone. A non-vote translates to ‘no’ and financing is not released until 50 percent are affirmed. Each ticket held is worth one voting right.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                </div>
                                <TagInput placeholder="Upfront (no PoW)"
                                          isRequired={false}
                                          inputName="proofOfWork"
                                          refName="proofOfWork"
                                          chosenData={this.state.proofOfWork}
                                          maxSize="2"
                                          onChange={(e) => {
                                              if (e.startsWith('Upfront')) {
                                                  e = ''
                                              }
                                              this.setState({"proofOfWork": e})
                                          }}
                                          isSingleSelect={true}
                                          defaultValue=''
                                          data={proofOfWork}/>
                            </div>
                            <div className="section-input-wrap">
                                <div className="s-input-title">
                                    Age Restrictions:
                                    <InfoTooltip position="top"
                                                 message="The age restrictions are relevent to the sellability of your project. If the project is not rated for the minimum range selected by all regulators in all jurisdictions, the contract is breached. Many creators leave this question blank to avoid a contract breach.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                </div>
                                <TagInput placeholder="Choose an age range"
                                          isRequired={false}
                                          inputName="ageRestrictions"
                                          refName="ageRestrictions"
                                          chosenData={this.state.ageRestrictions}
                                          maxSize="2"
                                          onChange={(e) => {
                                              if (e.startsWith('Choose')) {
                                                  e = ''
                                              }
                                              this.setState({"ageRestrictions": e})
                                          }}
                                          isSingleSelect={true}
                                          defaultValue='Unidentified'
                                          data={restrictAgeArray}/>
                            </div>
                            {/*<div className="section-input-wrap">
                                <p className="s-input-title">Intellectual Property Being Pre-sold:<InfoTooltip
                                    position="top"
                                    message="Common choices are 50%; or 33.3% or 25% if creator has additional partners. Do not post a project, without pre-selling some IP, unless it is already fully produced and saleable."><i
                                    className="fa fa-info-circle"></i></InfoTooltip></p>
                                <TagInput placeholder="None, this project is market-ready"
                                          isRequired={false}
                                          inputName="ipPercentage"
                                          refName="ipPercentage"
                                          chosenData={this.state.ipPercentage}
                                          maxSize="2"
                                          onChange={(e) => this.setState({"ipPercentage": e})}
                                          isSingleSelect={true}
                                          defaultValue='Unidentified'
                                          data={ipArray}/>
                            </div>
                            {(this.state.ipPercentage != "" && this.state.ipPercentage != "Unidentified") &&
                            <div className="financingGoalFieldWrapper">
                                <p className="s-input-title">Financing Goal (USDW):<InfoTooltip position="top"
                                                                                                    message="Input the full dollar amount without commas or decimals. Do not include cents. Please round to the closest dollar."><i
                                    className="fa fa-info-circle"></i></InfoTooltip><span
                                    className="character-count">{9 - this.state.financingGoal.length}</span> <span
                                    className="required-star">Required * </span></p>
                                <input value={this.state.financingGoal}
                                       data-valueforcheck=""
                                       data-action="compare"
                                       onKeyPress={this._handleKeyPress}
                                       onChange={(e) => this.handleInputAmount(e.target.value)}
                                       type="tel"
                                       pattern="[0-9]*"
                                       data-isrequiredfield="true"
                                       name="financingGoal"
                                       className='amountInput'
                                       maxLength="9"
                                       placeholder="0.00"/>
                                <select dir="rtl" className="amountSelect">
                                    <option>USD</option>
                                </select>
                                <br style={{"clear": "both"}}/>
                            </div>
                            }*/}
                        </div>
                    </div>
                    {categoryDetails &&
                    <div className="section-main additionalDataWrapper">
                        <div className="section-sub-title additionalData__title">{categoryDetails.subTitle}</div>
                        {categoryDetails.mediaCategoryData.map(({
                                                                    data,
                                                                    title,
                                                                    infoMessage,
                                                                    placeholder,
                                                                    isRequired,
                                                                    inputName,
                                                                    ref,
                                                                    maxSize,
                                                                    isSingleSelect,
                                                                    defaultValue,
                                                                    dependencies
                                                                }, idx) => {
                            if (dependencies) {
                                let isShow = true;
                                for (let i = 0; i < dependencies.length; i++) {
                                    const compareType = dependencies[i].type;
                                    const name = dependencies[i].name;
                                    const value = dependencies[i].value;
                                    const additionalData = this.state.additionalData[name] || defaultValue;
                                    if (compareType === 'equal') {
                                        isShow = additionalData === value;
                                    } else {
                                        isShow = additionalData !== value;
                                    }
                                    if (!isShow) return null;
                                }
                            }
                            const nameOfFields = title.replace(':', '');
                            const chosenData = this.state.additionalData[nameOfFields] || defaultValue;
                            return (
                                <div key={idx} className="section-input-wrap additionalData__select">
                                    <div className="s-input-title">
                                        {title}
                                        <InfoTooltip position="top"
                                                     message={infoMessage}>
                                            <i className="fa fa-info-circle"/>
                                        </InfoTooltip>
                                    </div>
                                    <TagInput placeholder={placeholder}
                                              isRequired={isRequired}
                                              inputName={inputName}
                                              refName={ref}
                                              chosenData={chosenData}
                                              maxSize={maxSize}
                                              onChange={(newData) => this.setAdditionalData(newData, title, defaultValue)}
                                              isSingleSelect={isSingleSelect}
                                              defaultValue={defaultValue}
                                              data={data}/>
                                </div>
                            );
                        })}
                    </div>
                    }

                    <div className="section-main">


                        <div className="section-sub-title">
                            Bonus Content
                            <div className='creator-team-collab'>
                                <p className="section-sub-title"
                                >Add bonus content</p>
                                <InfoTooltip position="top"
                                             message="Add easter eggs or bonus content with conditions">
                                    <i className="fa fa-info-circle"/>
                                </InfoTooltip>
                                {/*<span className="character-count">Up to 25</span>*/}
                            </div>
                        </div>
                        <div className="section-left-side add__block">

                        </div>
                    </div>


                    <div className="section-main"
                         style={{'marginBottom': '120px'}}>
                        <div className="row">
                            <p className="section-sub-title">License Data</p>
                        </div>
                        <div className="section-input-wrap" style={{"display": "flex"}}>
                            {/*<div className="upload_block_wrapper">*/}


                            {/*{this.state.nodeIPFS && <UploadAttachItemToIpfs setAttachItem={this.setAttachItem}*/}
                            {/*                                                node={this.state.nodeIPFS}*/}
                            {/*                                                attachItem={this.state.attachItem}*/}
                            {/*                                                attachFileType={this.state.attachFileType}*/}
                            {/*                                                name={this.state.attachFileName}*/}
                            {/*/>*/}

                            <UploadItemsToIpfs setItemsToIpfs={this.setItemsToIpfs}
                                               node={this.state.nodeIPFS}
                                               itemsName={this.state.additionalItemNamesIPFS}
                                               blockName='additionalAttachmentsIpfs'
                                               licenseType='additionalAttachmentsIpfs'
                                               fileWarning={this.state.auctionFileWarning}
                                               onLoadFile={this.state.onLoadFile}
                                               removeItem={this.removeItem}
                            />


                            {/*{this.state.attachFileName}*/}
                            {/*</div>*/}
                            <div className="upload_block_wrapper" style={{"marginLeft": "50px"}}>
                                <div className="s-input-title ">
                                    Insurance Document:
                                    <InfoTooltip position="top"
                                                 message="Attach your insurance policy here. All large scale projects, like feature films, should be insured.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                </div>

                                {/* TODO: ATTACH */}

                                <UploadInsuranceToIpfs setInsuranceItem={this.setInsuranceItem}
                                                       node={this.state.nodeIPFS}
                                                       blockName='uploadInsuranceToIpfs'
                                                       insuranceItem={this.state.insuranceItem}
                                                       insuranceFileType={this.state.insuranceFileType}
                                                       name={this.state.insuranceFileName}
                                />
                                {this.state.insuranceFileName}
                            </div>
                        </div>

                        <div className="section-input-wrap  small-wrap">
                            <div className="s-input-title">
                                Royalty:
                                <InfoTooltip position="top"
                                             message="If this is a collectors license, you are choosing to pre-sell certificates of authenticity prior to completion. If this is a mass market license you must identify whether you are pre-selling the intellectual property or just the royalties.">
                                    <i className="fa fa-info-circle"/>
                                </InfoTooltip>
                            </div>
                            <TagInput placeholder="None, this project is not seeking royalties"
                                      isRequired={false}
                                      inputName="royaltyPercentage"
                                      refName="royaltyPercentage"
                                      chosenData={this.state.royaltyPercentage}
                                      maxSize="2"
                                      type="royalty"
                                      onChange={(e) => {
                                          if (e.startsWith('None')) {
                                              e = 'None, this project is not seeking royalties'
                                          }
                                          this.setState({"royaltyPercentage": e})
                                      }}
                                      isSingleSelect={true}
                                      defaultValue='Unidentified'
                                      data={royaltyArray}/>
                        </div>


                        <CustomUsersFields
                            customFieldsState={this.state.customFieldsState}
                            fieldsArr={this.state.customFieldsState}
                            UpdateFieldsArr={this.UpdateFieldsArr}
                            removeCustomFIeldItem={this.removeCustomFIeldItem}
                        />

                        <div className="section-input-wrap  small-wrap">
                            <div className="section-left-side unlim-radio__block">
                                <div className="section-input-wrap radio-select-block radioBtnBlockForUnlimited ">
                                    <div className="s-input-title">
                                        <span id='typeOfDownloads'>Downloadable Files?</span>
                                        <InfoTooltip position="top"
                                                     message="The downloadable files option allows buyers to download media files directly from their wallet. To allow this leak choose yes. For stream, view or read only, choose no. ">
                                            <i className="fa fa-info-circle"/></InfoTooltip>
                                        <span className="required-star">Required *</span>
                                    </div>
                                    <RadioBtnBlock data={dataForTypeOfDownload}
                                                   data-valueforcheck=""
                                                   data-action="compare"
                                                   data-isrequiredfield="true"
                                                   checkboxType="typeOfDownload"
                                                   selectedData={this.state.typeOfDownload}
                                                   downLoadType={this.handleTypeMedia}
                                                   name="typeOfDownload"
                                    />
                                </div>
                            </div>


                            <div className="s-input-title">
                                Percentage Being Sold Prior to Completion:
                                <InfoTooltip position="top"
                                             message="If this is a collectors license, you are choosing to pre-sell certificates of authenticity prior to completion. If this is a mass market license you must identify whether you are pre-selling the intellectual property or just the royalties.">
                                    <i className="fa fa-info-circle"/>
                                </InfoTooltip>
                            </div>
                            <TagInput placeholder="None, this project is market-ready"
                                      isRequired={false}
                                      inputName="ipPercentage"
                                      refName="ipPercentage"
                                      chosenData={this.state.ipPercentage}
                                      maxSize="2"
                                      onChange={(e) => {
                                          if (e.startsWith('None')) {
                                              e = 'Unidentified'
                                          }
                                          this.setState({"ipPercentage": e})
                                      }}
                                      isSingleSelect={true}
                                      defaultValue='Unidentified'
                                      data={ipArray}/>


                            <div className="s-input-title">
                                Freeze the release of these tokens from the date of sale::
                                <InfoTooltip position="top"
                                             message="Freeze the release of these tokens from the date of sale:">
                                    <i className="fa fa-info-circle"/>
                                </InfoTooltip>
                            </div>
                            <TagInput placeholder="None, this project is market-ready"
                                      isRequired={false}
                                      inputName="ipPercentage"
                                      refName="ipPercentage"
                                      chosenData={this.state.selectedFreeze}
                                      maxSize="2"
                                      onChange={(e) => {
                                          this.setState({"selectedFreeze": e})
                                      }}
                                      isSingleSelect={true}
                                      defaultValue='Unidentified'
                                      data={this.state.freezeData}/>


                        </div>
                        <div className="row">
                            <p className="section-sub-title">
                                <span id='licenseTypeTitle'>Choose a License Type</span>
                                <span className="required-star">Required *</span>
                            </p>
                        </div>
                        {this.state.ipPercentage !== "Unidentified" &&
                        <div className="financingGoalFieldWrapper small-wrap-financing">
                            <div className="s-input-title">
                                Financing Goal (USDW):
                                <InfoTooltip position="top"
                                             message="Please round to the closest dollar without commas or decimals.">
                                    <i className="fa fa-info-circle"/>
                                </InfoTooltip>
                                <span className="character-count">
                    {9 - this.state.financingGoal.length}
                  </span>
                                <span className="required-star">Required * </span>
                            </div>
                            <input value={this.state.financingGoal}
                                   data-valueforcheck=""
                                   data-action="compare"
                                   data-isrequiredfield="true"
                                   name="financingGoal"
                                   onKeyPress={this._handleKeyPress}
                                   onChange={(e) => this.handleInputAmount(e.target.value)}
                                   type="tel"
                                   pattern="[0-9]*"
                                   className='amountInput '
                                   maxLength="9"
                                   placeholder="0.00"/>
                            <select dir="rtl" className="amountSelect financing-select">
                                <option>USD</option>
                            </select>
                            <br style={{"clear": "both"}}/>
                        </div>
                        }
                        <div className="section-input-wrap unlim-license__block">
                            {this.state.licenseType && <input onChange={() => this.clearDataOfLimitedLicense()}
                                                              data-isrequiredfield={true}
                                                              name="licenseType"
                                                              type="radio"
                                                              value="limited"
                                                              id="unlimitedLicense"
                                                              defaultChecked={this.state.licenseType === 'unlimited' ? true : false}/>}

                            <label htmlFor="unlimitedLicense">

                                <p className="s-input-title">MASS MARKET LICENSE</p>
                                <div className="s-input-descr text-justify">I am stating that my project will
                                    be a mass market item monetized on all exploitable services, in
                                    perpetuity, in this universe and any yet undiscovered. I accept the
                                    <Link className="licenses-p" to="/terms">unlimited license</Link> terms of service.

                                </div>
                            </label>
                        </div>
                        {this.state.licenseType === "unlimited" &&
                        <Fragment>
                            <div className="section-left-side unlim-radio__block">


                                <div className="section-input-wrap">
                                    <div className="s-input-title">
                                        Total Supply:
                                        <InfoTooltip position="top"
                                                     message="Enter number of licenses, witch you want to mint.">
                                            <i className="fa fa-info-circle"/>
                                        </InfoTooltip>
                                    </div>
                                    <input
                                        defaultValue={this.state.massMarketQuantity}
                                        onChange={(e) => this.handleGeneralInput(e.target.value, 'massMarketQuantity')}
                                        style={{"marginTop": "10px"}}
                                        type="number"
                                    />
                                </div>


                                <div className="section-input-wrap radio-select-block radioBtnBlockForUnlimited"
                                     style={{"paddingLeft": "30px"}}>
                                    <RadioBtnBlock data={dataForTypeOfUnlimitedLicense}
                                                   selectedData={this.state.additionalDataForUnlimited}
                                                   onSelectType={this.handleGeneralInput}
                                                   name="typeOfUnlimitedLicense"
                                                   fieldNameForChange="additionalDataForUnlimited"/>
                                </div>
                            </div>
                            <div className="s-input-descr text-justify" style={{"padding": "30px 0 0 30px"}}>Would you
                                like to activate exchange activity on this project
                                upon goal being met? This choice cannot be reversed. View the
                                <Link className="licenses-p" to="/terms">fanbase exchange</Link> terms of service.
                            </div>
                            <div className="section-input-wrap radio-select-block radioBtnBlockForUnlimited"
                                 style={{"padding": "20px 0 0 30px"}}>
                                <RadioBtnBlock data={dataForUnlimitedLicenseAccept}
                                               selectedData={this.state.additionalLicenseForUnlimited}
                                               onSelectType={this.handleGeneralInput}
                                               name="additionalLicenseForUnlimited"
                                               fieldNameForChange="additionalLicenseForUnlimited"
                                />
                            </div>
                            {this.state.additionalDataForUnlimited.quantityOfTotalOwnership !== undefined &&
                            <div className="section-input-wrap chooseQuantityOfTotalOwnership"
                                 style={{"paddingLeft": "30px"}}>
                                <p className="s-input-title">Royalty Share Years</p>
                                <TagInput placeholder="Choose one option"
                                          inputName="quantityOfTotalOwnership"
                                          refName="quantityOfTotalOwnership"
                                          chosenData={this.state.additionalDataForUnlimited.quantityOfTotalOwnership}
                                          maxSize="2"
                                          onChange={(e) => this.setState(({additionalDataForUnlimited}) => ({
                                              additionalDataForUnlimited: {
                                                  ...additionalDataForUnlimited,
                                                  quantityOfTotalOwnership: e
                                              }
                                          }))}
                                          isSingleSelect={true}
                                          defaultValue='1'
                                          data={this.getQuantityOfTotalOwnership('1')}/>
                            </div>
                            }
                            {/* {this.state.ipPercentage === "Unidentified" && this.state.licenseType !== '' && */}

                            <UploadItemsToIpfs setItemsToIpfs={this.setItemsToIpfs}
                                               node={this.state.nodeIPFS}
                                               blockName='investBlock'
                                               itemsName={this.state.investItemNames}
                                               licenseType='invest'
                                               fileWarning={this.state.investFileWarning}
                                               onLoadFile={this.state.onLoadFile}
                                               removeItem={this.removeItem}
                            />


                            {/* } */}
                        </Fragment>}
                        <div className="section-input-wrap limited-license__block">
                            {this.state.licenseType && <input onChange={() => {
                                this.setState({
                                    licenseType: 'limited',
                                    additionalDataForUnlimited: {},
                                    investItemNames: [],
                                    investItemInfo: [],
                                })
                                localStorage.removeItem('investItemNames');
                                localStorage.removeItem('investItemInfo')
                            }}
                                                              data-action="compare"
                                                              data-valueforcheck=""
                                                              data-isrequiredfield={true}
                                                              name="licenseType"
                                                              type="radio"
                                                              value="limited"
                                                              id="limitedLicense"
                                                              defaultChecked={this.state.licenseType === 'limited' ? true : false}
                            />
                            }
                            <label htmlFor="limitedLicense">
                                <p className="s-input-title">COLLECTOR LICENSE</p>
                                <div className="s-input-descr text-justify">I am stating that my project will
                                    be a collector’s edition ERC-1155, of a limited number between 1 and 100,000 for
                                    sale first
                                    on the Fanbase platform. I accept the<Link className="licenses-p" to="/terms">limited
                                        license</Link> terms of service.


                                </div>
                            </label>
                        </div>
                        {this.state.licenseType === "limited" &&
                        <div className="section-left-side">
                            <div className="section-left-side unlim-radio__block">
                                <div className="section-input-wrap radio-select-block radioBtnBlockForUnlimited "
                                     style={{"paddingLeft": "30px"}}>
                                    {this.state.ipPercentage !== "Unidentified" ?
                                        <RadioBtnBlock data={dataForTypeOfLimitedLicense}
                                                       selectedData={this.state.additionalDataForLimited}
                                                       onSelectType={this.handleGeneralInput}
                                                       name="typeOfLimitedLicense"
                                                       fieldNameForChange="additionalDataForLimited"/> : null}
                                </div>
                            </div>
                            <div className="section-input-wrap" style={{"paddingLeft": "30px"}}>
                                <div className="s-input-title">
                                    Total Supply:
                                    <InfoTooltip position="top"
                                                 message="The total supply refers to the finite number of licenses or certificates of authenticity forged in the contract. The greater the amount, the less valuable each individual license, all other variables being equal.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="required-star">Required *</span>
                                </div>
                                <TagInput placeholder="Choose one option"
                                          isRequired={true}
                                          action="compare"
                                          valueForCheck="Unidentified"
                                          inputName="numOfLicenses"
                                          refName="numOfLicenses"
                                          chosenData={this.state.numOfLicenses}
                                          maxSize="2"
                                          onChange={(e) => this.saveNumOfLicenses(e)}
                                          isSingleSelect={true}
                                          defaultValue='Unidentified'
                                          data={licensesArray}/>
                            </div>
                            <div className="section-input-wrap" style={{"paddingLeft": "30px"}}>
                                <div className="s-input-title">
                                    Supply Retained:
                                    <InfoTooltip position="top"
                                                 message="The supply retained refers to the percentage of total licenses the creator is allowed to keep beyond the time limit of auction.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="required-star">Required *</span>
                                </div>
                                <TagInput placeholder="Choose one option"
                                          isRequired={true}
                                          action="compare"
                                          valueForCheck=""
                                          inputName="licensesPercentageForCreator"
                                          refName="licensesPercentageForCreator"
                                          chosenData={this.state.licensesPercentageForCreator}
                                          maxSize="2"
                                          onChange={(e) => {
                                              if (e.startsWith('Choose')) {
                                                  e = ''
                                              }
                                              this.setState({"licensesPercentageForCreator": e})
                                          }}
                                          isSingleSelect={true}
                                          defaultValue='Unidentified'
                                          data={this.state.affordableInterestForCreator}
                                          disabled={this.state.disabledLicensesPercentage}
                                />
                            </div>
                            <div className="section-input-wrap" style={{"paddingLeft": "30px"}}>
                                <div className="s-input-title">
                                    Sell-by Date:
                                    <InfoTooltip position="top"
                                                 message="The sell-by date is the final day a creator can own his certificate of authenticity. Fanbase reserves the right to burn or donate any token that is not sold prior to its sell-by date.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="required-star">Required *</span>
                                </div>

                                <TagInput placeholder="Choose a sell-by date"
                                          isRequired={true}
                                          action="compare"
                                          valueForCheck=""
                                          inputName="auctionTime"
                                          refName="auctionTime"
                                          chosenData={this.state.auctionTime}
                                          maxSize="2"
                                          onChange={(e) => {
                                              if (e.startsWith('Choose')) {
                                                  e = ''
                                              }
                                              this.setState({"auctionTime": e})
                                          }}
                                          isSingleSelect={true}
                                          defaultValue=''
                                          data={auctionTimeArray}/>
                            </div>
                            <div className="section-input-wrap" style={{"paddingLeft": "30px"}}>
                                <div className="s-input-title">
                                    Release Classification:
                                    <InfoTooltip position="top"
                                                 message="Choose a release classification. Like baseball cards, 'rookie' items are generally more valuable. To classify something as a 'rookie release' it must be the first in a career, a new character in a series, or a performance under a new identity. 'First Edition' items are digital prints that may have subsequent releases.">
                                        <i className="fa fa-info-circle"/>
                                    </InfoTooltip>
                                    <span className="required-star">Required *</span>
                                </div>
                                <TagInput placeholder="Choose one option"
                                          isRequired={true}
                                          action="compare"
                                          valueForCheck=""
                                          inputName="rookieRank"
                                          refName="rookieRank"
                                          chosenData={this.state.rookieRank}
                                          maxSize="2"
                                          onChange={(e) => this.setState({"rookieRank": e})}
                                          isSingleSelect={true}
                                          defaultValue=''
                                          data={rookieRankArray}/>
                            </div>


                            <UploadItemsToIpfs setItemsToIpfs={this.setItemsToIpfs}

                                               node={this.state.nodeIPFS}
                                               itemsName={this.state.auctionItemNames}
                                               licenseType='auction'
                                               blockName='auctionBlock'
                                               fileWarning={this.state.auctionFileWarning}
                                               onLoadFile={this.state.onLoadFile}
                                               removeItem={this.removeItem}
                            />

                        </div>
                        }
                        <div className="row justify-content-between">
                            <div>
                                <a onClick={this.saveLocalAndValidate} className="finalBttn saveExitBttn">
                                    Submit
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps({blockchain}) {
    return {
        mainWalletAddress: blockchain.mainWalletAddress,
        banners: blockchain.banners,
    };
}

export default connect(
    mapStateToProps,
    {...blockchainAction}
)(Contract);
