import React, {Component} from 'react';
import Parse from "parse";
import ImagesLayout from "../../Components/FiveImages/imagesLayout";
import InvestCard from "../../Components/InvestCard";
import AddUserListContainer from '../../Components/AddUserList';
import formatArrToString from '../../../utils/formatArrToString';
import ProofOfWorkPoll from './ProofOfWorkPoll';
import CommentsBlock from '../../Components/CommentsBlock';
import ShowProjectMediaFile from "../../Components/ShowProjectMediaFile";
import UploadAttachTOIpfs from "../Contract/UploadAttachTOIpfs";
import ModalWindow from "../../Components/ModalWindow";
import LogoMaker from "../../Components/LogoMaker";
import ethClient from '../../eth_client';
import {sendParseRequest} from "../../../utils/sendParseRequest";
import DropDownList from "../../Components/DropDownList";
import "./ProjectInvestPage.css"
import F_in_White from "../../../assets/images/F_in_White.png";
import axios from "axios";

export default class ProjectInvestPage extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.projectTitle = React.createRef();
    this.projectSubtitle = React.createRef();
    this.projectWebsite = React.createRef();
    this.socialMedia = React.createRef();
    this.pitchThumbnail = React.createRef();
    this.videoPitchLink = React.createRef();
    this.promoThumbnail = React.createRef();
    this.promovideoLink = React.createRef();
    this.projectDescription = React.createRef();
    this.projectPerks = React.createRef();
  }

  state = {
    videoShow: false,
    investProjects: [],
    bannerImages: [],
    projectTitle: "",
    projectSubtitle: "",
    creator: "",
    tickerSymbol: "",
    projectlogo: "",
    projectDescription: "",
    proj: "",
    ipPercentage: "",
    projectWebsite: [{link: "", showUp: true}],
    socialMedia: [{link: '', showUp: true}],
    stageName: "",
    videoPitch: [],
    videoPromo: [],
    pitchThumbnail: "",
    videoPitchLink: "",
    promoThumbnail: "",
    promovideoLink: "",
    additonalAttachments: "",
    companyWebsite: "",
    linkToAuction: "",
    technology: "",
    operatingSystem: "",
    codingLanguages: "",
    browsers: "",
    database: "",
    topic: "",
    monetizationStrategy: "",
    collData: "",
    principals: [],
    collaborators: [],
    team: [],
    isOwnerOfTheProject: false,
    showPoll: false,
    showProjectWebsite: true,
    showMedia: true,
    showVideoPitch: true,
    showVideoPromo: true,
    projectId: '',
    attachItem: '',
    attachFileType: true,
    attachFileName: '',
    tab: '',
    openFileViewer: false,
    isReportWebsite: false,
    isReportSocial: false,
    isReportPitchThumb: false,
    isReportPitchLink: false,
    isReportPromoThumb: false,
    isReportPromoLink: false,
    isSubmit: false,
    testOpacity: false,
    isLogoMakerOpen: false,
    attachName: false,
    isReportDescription: false,
    showDescription: true,
    isReportPerks: false,
    showPerks: true,
    projectPerks: '',
    additionalData: {},
    mediaCategory: '',
    defaultUserAvatarLink: '',
    sessionToken: ''
  };

  async componentDidMount() {
    document.title = "Fanbase";

    sendParseRequest('getUserInfoById', {id: Parse.User.current().id})
      .then(res => {
        this.setState({
          defaultUserAvatarLink: (res.get('avatarLink') === undefined) ? F_in_White : res.get('avatarLink'),
          // defaultUserAvatarLink: F_in_White,
          sessionToken: res.get('sessionToken')
        })
      })

    const {match: {params}} = this.props;
    console.log(params.tokenURI, "--------------params")
    console.log(11111111111, "--------------11111111111")

    let projectData = await axios({
      method: "post",
      url: `http://3.220.210.43:8081/api/v2/eth/contracts/projects/getProjectsByTokenURIs`,
      auth: {
        username: 'username',
        password: 'password'
      },
      data: {tokenUrls: params.tokenURI}
    });
    // console.log(a.data.projects[0], "--------------a")
    // .then(res => {console.log(res.data.projects[0], "--------------res")})

    let project = projectData.data.projects[0]
    // var options = {
    //   method: 'POST',
    //   url: 'http://3.236.249.5:8081/api/v2/eth/contracts/projects/getProjectsByTokenURIs',
    //   headers: {
    //     // walletAddress: '0xd915c32677Ff0feF5b60533b1b258CC293Fe9c4',
    //     'Content-Type': 'application/json',
    //     'Authorization': auth
    //   },
    //   data: {tokenUrls: 'ux6GneYih7'}
    // };
    //
    // let a = await axios.request(options)
    // console.log(a, "--------------a")

    //   .then(function (response) {
    //     console.log(response.data);
    // }).catch(function (error) {
    //     console.error(error);
    // });


    // const project = await ethClient.project.getProjectDetailByTokenURI(params);
    // const project = "";

    project && console.log(project.metadata, "--------------project")
    // console.log(project, "--------------project")
    // const project = await EthApi.contract.project.getProjectById(params.projectId);

    const projectId = params.tokenURI;
    let metadata;
    if (project.metadata) {
      metadata = project.metadata
    }
    console.log(metadata.stageName, "--------------metadata.stageName")
    // projectId && this.getProjectMedia(projectId);
    this.setState({project});
    // console.log(project, "--------------project0000000")
    const currUserId = Parse.User.current() ? Parse.User.current().id : '';
    const Projects = Parse.Object.extend("Projects");
    const query = new Parse.Query(Projects);
    query.include('creator');
    query.equalTo("objectId", projectId);

    query.find()
      .then((results) => {
        // if (results[0]) {
        if (results[0] && Object.keys(results[0].attributes).length > 0 && results[0]._objCount > 0) {

          const projects = results[0].attributes;

          this.getCollaboratorsOrPrincipalsOrTeamList('Project_Principals', projects.creator, projectId, 'principals');
          this.getCollaboratorsOrPrincipalsOrTeamList('Project_Collaborators', projects.creator, projectId, 'collaborators');
          this.getCollaboratorsOrPrincipalsOrTeamList('Project_Team', projects.creator, projectId, 'team');
          projects && metadata && this.setState({
            projectId: results[0].id,
            isOwnerOfTheProject: projects.creator.id === currUserId,
            investProjects: projects,
            bannerImages: projects.promotionalBanners,
            projectTitle: projects.projectTitle,
            projectSubtitle: projects.projectSubtitle,
            creator: projects.creator.id,
            tickerSymbol: metadata.tickerSymbol,
            projectlogo: projects.logoAttach,
            projectDescription: projects.projectDescription,
            projectPerks: projects.projectPerks,
            stageName: metadata.stageName,
            ipPercentage: metadata.ipPercentage,
            projectWebsite: metadata.projectWebsite,
            showProjectWebsite: projects.projectWebsite[0].showUp,
            socialMedia: metadata.socialMedia,
            showMedia: projects.socialMedia[0].showUp,
            videoPitch: projects.videoPitch,
            showVideoPitch: projects.videoPitch[0].showUp,
            pitchThumbnail: projects.videoPitch[0].thumbnailUrl,
            videoPitchLink: projects.videoPitch[0].videoLink,
            videoPromo: projects.videoPromo,
            showVideoPromo: projects.videoPromo[0].showUp,
            promoThumbnail: projects.videoPromo[0].thumbnailUrl,
            promovideoLink: projects.videoPromo[0].videoLink,
            additonalAttachments: formatArrToString(metadata.additonalAttachments, '\n'),
            attachItem: metadata.attachItem,
            attachName: metadata.attachItem.name,
            companyWebsite: metadata.companyWebsite,
            linkToAuction: projects.linkToAuction,
            technology: projects.technology,
            operatingSystem: projects.operatingSystem,
            codingLanguages: projects.codingLanguages,
            browsers: projects.browsers,
            database: projects.database,
            topic: projects.topic,
            monetizationStrategy: projects.monetizationStrategy,
            proofOfWork: metadata.proofOfWork,
            financingGoal: metadata.financingGoal,
            itemsData: metadata.attachItem.attachHash && [{
              multiHash: metadata.attachItem.attachHash,
              type: metadata.attachItem.type,
              name: metadata.attachItem.name,
            }],
            showDescription: projects.showInvestData[0].showDescription,
            showPerks: projects.showInvestData[1].showPerks,
            additionalData: metadata.additionalData,
            mediaCategory: metadata.mediaCategory,
            fName: projects.creator.get('fname'),
            lName: projects.creator.get('lname'),
          }, () => {
            console.log(this.state, '=================================this.state.===')
          });
          // }

        }
      }, (error) => {
        console.log(error);
      }).then(() => this.checkReports());
  }

  getCollaboratorsOrPrincipalsOrTeamList = (className, creatorId, projectId, type) => {
    const Query = new Parse.Query(className);
    Query.select('no_user_details', 'role', 'confirmed');
    Query.equalTo("creator", creatorId);
    Query.equalTo("project", {"__type": "Pointer", "className": "Projects", "objectId": projectId});
    Query.find()
      .then((results) => {
        console.log(results, type, className, "--------------results, type, className ")
        if (results && results.length > 0) {
          const usersList = results.map(user => ({
            names: user.get('no_user_details'),
            roles: user.get('role'),
            id: user.id,
            projectId: projectId,
            confirmed: user.get('confirmed')
          }));

          this.setState({
            [type]: usersList
          });
        }
      }, (error) => {
        console.log(error);
      });
  };

  iconImg = () => {
    if (this.state.socialMedia === '') return;

    if (this.state.socialMedia[0].link.includes('facebook')) {
      return "https://s3.amazonaws.com/fanbase.platform/social_icons/facebook.png"
    } else if (this.state.socialMedia[0].link.includes('fb.com')) {
      return "https://s3.amazonaws.com/fanbase.platform/social_icons/facebook.png"
    } else if (this.state.socialMedia[0].link.includes('instagram')) {
      return "https://s3.amazonaws.com/fanbase.platform/social_icons/instagram.png"
    } else if (this.state.socialMedia[0].link.includes('pinterest')) {
      return "https://s3.amazonaws.com/fanbase.platform/social_icons/pinterest.png"
    } else if (this.state.socialMedia[0].link.includes('tumblr')) {
      return "https://s3.amazonaws.com/fanbase.platform/social_icons/tumblr.png"
    } else if (this.state.socialMedia[0].link.includes('twitter')) {
      return "https://s3.amazonaws.com/fanbase.platform/social_icons/twitter.png"
    } else {
      return null
    }
  };

  socialLinkName = () => {
    let socialLinkName = this.iconImg();

    if (socialLinkName.includes('facebook')) {
      return "Facebook link"
    } else if (socialLinkName.includes('fb.com')) {
      return "Facebook link"
    } else if (socialLinkName.includes('instagram')) {
      return "Instagram link"
    } else if (socialLinkName.includes('pinterest')) {
      return "Pinterest link"
    } else if (socialLinkName.includes('tumblr')) {
      return "Tumblr link"
    } else if (socialLinkName.includes('twitter')) {
      return "Twitter link"
    }
  };

  updateInvestProjectData = (dataName, subValue, checkReport) => {
    console.log('dataName', dataName)
    // console.log(this.projectSubtitle.current.value, 'this.projectSubtitle')
    let dataValue;

    if (subValue) {
      dataValue = subValue;
    } else {
      dataValue = this[dataName].current.value;
    }

    if (dataName === 'projectWebsite') {
      dataValue = [{
        link: dataValue,
        showUp: this.state.showProjectWebsite,
      }];

      this.setState({
        projectWebsite: dataValue,
        websiteLinkWarning: false,
      });
    } else if (dataName === 'socialMedia') {
      dataValue = [{
        link: dataValue,
        showUp: this.state.showMedia
      }];

      this.setState({
        socialMedia: dataValue,
        socialMediaLinkWarning: false,
      });
    } else if (dataName === 'pitchThumbnail') {
      dataName = 'videoPitch';
      dataValue = [{
        thumbnailUrl: dataValue,
        videoLink: this.state.videoPitchLink,
        showUp: this.state.showVideoPitch
      }];

      this.setState({
        videoPitch: [{
          thumbnailUrl: dataValue[0].thumbnailUrl,
          videoLink: this.state.videoPitchLink,
        }],
        videoPitchThumbnailLinkWarning: false,
      });
    } else if (dataName === 'videoPitchLink') {
      dataName = 'videoPitch';
      dataValue = [{
        thumbnailUrl: this.state.pitchThumbnail,
        videoLink: dataValue,
        showUp: this.state.showVideoPitch
      }];

      this.setState({
        videoPitch: [{
          thumbnailUrl: this.state.pitchThumbnail,
          videoLink: dataValue[0].videoLink,
        }],
        videoPitchLinkWarning: false,
      });
    } else if (dataName === 'promoThumbnail') {
      dataName = 'videoPromo';
      dataValue = [{
        thumbnailUrl: dataValue,
        videoLink: this.state.promovideoLink,
        showUp: this.state.showVideoPromo
      }];

      this.setState({
        videoPromo: [{
          thumbnailUrl: dataValue[0].thumbnailUrl,
          videoLink: this.state.promovideoLink,
        }],
        videoPromoThumbnailLinkWarning: false,
      });
    } else if (dataName === 'promovideoLink') {
      dataName = 'videoPromo';
      dataValue = [{
        thumbnailUrl: this.state.promoThumbnail,
        videoLink: dataValue,
        showUp: this.state.showVideoPromo
      }];

      this.setState({
        videoPromo: [{
          thumbnailUrl: this.state.promoThumbnail,
          videoLink: dataValue[0].videoLink,
        }],
        videoPromoLinkWarning: false,
      });
    } else if (dataName === 'projectDescription') {
      this.setState({
        descriptionWarning: false,
        projectDescription: dataValue
      });
    } else if (dataName === 'projectPerks') {
      this.setState({
        perksWarning: false,
        projectPerks: dataValue
      });
    } else if (dataName === 'projectSubtitle') {
      this.setState({
        projectSubtitle: dataValue,
      });
    } else {
      this.setState({
        [dataName]: dataValue
      });
    }

    sendParseRequest('updateInfo', {
      searchData: {
        query: 'Projects',
        equal: [{field: 'objectId', value: this.state.projectId}]
      },
      updateData: [{field: dataName, value: dataValue}]
    }).then(() => {
      console.log('projectId', this.state.projectId)
      sendParseRequest('getAllReportsByType', {type: 'Link', projectId: this.state.projectId});
      this.setState({editState: "0"});
    })
  };

  openViewer = () => {
    this.setState({openFileViewer: true})
  };

  onFileViewerClose = () => this.setState({
    openFileViewer: false,
    hashOfAttachItem: ''
  });

  setAttachItem = ({file, addToIpfs, tab}) => {
    this.addToIpfs = addToIpfs;
    let type = file.name.split(".")[1];
    if (type === "pdf") {
      this.setState({
        attachItem: file,
        attachFileName: file.name.split(".")[0]
      }, () => this.setAttachInfo(tab));
    } else {
      this.setState({
        attachFileType: false,
        attachItem: true
      });
    }
  };

  setAttachInfo = async (tab) => {
    if (tab === "projectAttach") {
      const attachItemInfo = {};
      const projectId = this.state.project[1];

      if (await this.state.attachItem !== '') {
        attachItemInfo.attachHash = await this.addToIpfs(this.state.attachItem);
        attachItemInfo.type = this.state.attachItem.type;
        attachItemInfo.name = this.state.attachItem.name;
      }

      const projectQuery = new Parse.Query("Projects");
      projectQuery.equalTo("objectId", projectId);

      projectQuery.find().then(res => {
        res.map(i => {
          i.set("attachItem", attachItemInfo);
          i.save().then(() => window.location.reload())
        })
      })

    }
  };

  openInput = () => {
    this.setState({
      attachInput: !this.state.attachInput
    })
  };

  getProjectMedia = (projectId) => {
    sendParseRequest('getInfo', {
      query: "Projects",
      equal: [{field: "objectId", value: projectId}],
      select: ["attachItem"],
    })
      .then(res => {

        //ToDo : add hashOfAttachItem, attachName,attachNameFile and attachItemType when it will add in blockchain!
        // this.setState({
        //
        //     hashOfAttachItem: res[0].attachItem.attachHash,
        //     attachName: res[0].attachItem.name,
        //     attachNameFile: res[0].attachItem.name,
        //     attachItemType: res[0].attachItem.type,
        // })
      })
  };

  saveAttachName = (inputRef) => {
    if (this.state.inputValue.length < 3) {
      inputRef.current.style.backgroundColor = '#ffadad';
      inputRef.current.placeholder = "3 characters minimum";
      return false
    }

    let value = inputRef.current.value;

    let attachItem = {
      "attachHash": this.state.hashOfAttachItem,
      "type": "application/pdf",
      "name": `${value}.pdf`
    };

    const Project = Parse.Object.extend("Projects");
    const projectsQuery = new Parse.Query(Project);
    projectsQuery.select('objectId', 'AttachItem');
    projectsQuery.equalTo("objectId", this.state.project[1]);
    projectsQuery.find()
      .then((results) => {
        results.map((i) => {
          i.set('attachItem', attachItem);
          i.save();
          this.setState({
            attachName: attachItem.name,
            attachNameFile: attachItem.name,
            attachInput: !this.state.attachInput,
            itemsData: [{
              multiHash: attachItem.attachHash,
              name: attachItem.name,
              type: "application/pdf",
            }]
          })
        });
      });
  };

  validateInput = e => {
    this.setState({
      inputValue: e.target.value
    });
  };

  onUpdateInvestedMoney = (investedMoney) => {
    this.setState({investedMoney});
  };

  showArea = (area) => {
    const userId = Parse.User.current().id;
    const projectId = this.state.project[1];
    switch (area) {
      case 'website':
        this.showWarningReportInProgress(userId, projectId, 'projectWebsite', 'link', 'isReportWebsite');
        break;
      case 'social':
        this.showWarningReportInProgress(userId, projectId, 'socialMedia', 'link', 'isReportSocial');
        break;
      case 'pitchThumb':
        this.showWarningReportInProgress(userId, projectId, 'videoPitch', 'thumbnailUrl', 'isReportPitchThumb');
        break;
      case 'pitchLink':
        this.showWarningReportInProgress(userId, projectId, 'videoPitch', 'videoLink', 'isReportPitchLink');
        break;
      case 'promoThumb':
        this.showWarningReportInProgress(userId, projectId, 'videoPromo', 'thumbnailUrl', 'isReportPromoThumb');
        break;
      case 'promoLink':
        this.showWarningReportInProgress(userId, projectId, 'videoPromo', 'videoLink', 'isReportPromoLink');
        break;
      case 'description':
        this.showWarningReportInProgress(userId, projectId, 'projectDescription', '', 'isReportDescription');
        break;
      case 'perks':
        this.showWarningReportInProgress(userId, projectId, 'projectPerks', '', 'isReportPerks');
        break;
    }
  };

  showWarningReportInProgress = (userId, projectId, reportTarget, targetField, checkReportField) => {
    sendParseRequest('getInfo', {
      query: "Reports",
      count: true,
      equal: [
        {field: "reportCreator", value: userId},
        {field: "projectId", value: projectId},
        {field: "reportTarget", value: reportTarget},
        {field: "targetField", value: targetField}
      ]
    }).then(res => {
      if (res === false) {
        this.setState({
          reportInProgress: true
        });
        setTimeout(() => {
          this.setState({
            reportInProgress: false
          })
        }, 3000);
        return false
      } else {
        this.setState({
          [checkReportField]: !this.state[checkReportField],
          reportInProgress: false
        });
      }
    })
  };

  getReportMessage = (area) => {
    if (!area.currentTarget.value) return;

    const reportData = [
      {field: 'reportMessage', value: area.currentTarget.value},
      {field: 'tickerSymbol', value: this.state.tickerSymbol},
      {field: 'projectTitle', value: this.state.projectTitle},
      {field: 'projectSubtitle', value: this.state.projectSubtitle},
      {field: 'projectUrl', value: this.props.match.url},
      {field: 'projectCreator', value: this.state.creator},
      {field: 'reportCreator', value: Parse.User.current().id},
      {field: 'Type', value: 'Link'},
      {field: 'projectId', value: this.state.project[1]},
      {field: 'hasChanged', value: false},
      {
        field: 'project', value: {
          __type: "Pointer",
          className: 'Projects',
          objectId: this.state.project[1],
        }
      },
    ]

    switch (area.target.id) {
      case 'website':
        reportData.push(
          {field: 'showUp', value: !!this.state.projectWebsite[0].showUp},
          {field: 'reportTarget', value: 'projectWebsite'},
          {field: 'targetField', value: 'link'},
          {field: 'prevValue', value: this.state.projectWebsite[0].link},
        )
        this.setState({reportMessageWebsite: reportData});
        break;
      case 'social':
        reportData.push(
          {field: 'showUp', value: !!this.state.socialMedia[0].showUp},
          {field: 'reportTarget', value: 'socialMedia'},
          {field: 'targetField', value: 'link'},
          {field: 'prevValue', value: this.state.socialMedia[0].link},
        )
        this.setState({reportMessageSocial: reportData});
        break;
      case 'pitchThumb':
        reportData.push(
          {field: 'showUp', value: !!this.state.videoPitch[0].showUp},
          {field: 'reportTarget', value: 'videoPitch'},
          {field: 'targetField', value: 'thumbnailUrl'},
          {field: 'prevValue', value: this.state.videoPitch[0].thumbnailUrl},
        )
        this.setState({reportMessagePitchThumb: reportData});
        break;
      case 'pitchLink':
        reportData.push(
          {field: 'showUp', value: !!this.state.videoPitch[0].showUp},
          {field: 'reportTarget', value: 'videoPitch'},
          {field: 'targetField', value: 'videoLink'},
          {field: 'prevValue', value: this.state.videoPitch[0].videoLink},
        )
        this.setState({reportMessagePitchLink: reportData});
        break;
      case 'promoThumb':
        reportData.push(
          {field: 'showUp', value: !!this.state.videoPromo[0].showUp},
          {field: 'reportTarget', value: 'videoPromo'},
          {field: 'targetField', value: 'thumbnailUrl'},
          {field: 'prevValue', value: this.state.videoPromo[0].thumbnailUrl},
        )
        this.setState({reportMessagePromoThumb: reportData});
        break;
      case 'promoLink':
        reportData.push(
          {field: 'showUp', value: !!this.state.videoPromo[0].showUp},
          {field: 'reportTarget', value: 'videoPromo'},
          {field: 'targetField', value: 'videoLink'},
          {field: 'prevValue', value: this.state.videoPromo[0].videoLink},
        )
        this.setState({reportMessagePromoLink: reportData});
        break;
      case 'description':
        reportData.push(
          {field: 'showUp', value: this.state.showDescription},
          {field: 'reportTarget', value: 'projectDescription'},
          {field: 'targetField', value: ''},
          {field: 'prevValue', value: this.state.projectDescription},
        )
        this.setState({reportMessageDescription: reportData});
        break;
      case 'perks':
        reportData.push(
          {field: 'showUp', value: this.state.showPerks},
          {field: 'reportTarget', value: 'projectPerks'},
          {field: 'targetField', value: ''},
          {field: 'prevValue', value: this.state.projectPerks},
        )
        this.setState({reportMessagePerks: reportData});
        break;
    }
  };

  submitReport = (reportData, isFieldReportName, isSubmitReportField) => {
    if (!reportData) return;

    sendParseRequest('createDatabaseObject', {query: 'Reports', saveData: reportData})
      .then(() => {
        this.setState({
          [isFieldReportName]: !this.state[isFieldReportName],
          [isSubmitReportField]: true
        });

        setTimeout(() => this.setState({[isSubmitReportField]: false}), 2000)
      })
  };


  checkReports = () => {
    if (this.state.isOwnerOfTheProject) {
      sendParseRequest('getInfo', {
        query: 'Reports',
        equal: [{field: "objectId", value: this.state.project[1]}]
      })
        .then(reports => {
          for (let report of reports) {
            if (report.get('showUp') === true && report.get('hasChanged') === false) {
              switch (report.get('reportTarget') + report.get('targetField')) {
                case 'projectWebsitelink':
                  this.setState({
                    websiteLinkWarning: true
                  });
                  break;
                case 'socialMedialink':
                  this.setState({
                    socialMediaLinkWarning: true
                  });
                  break;
                case 'videoPromothumbnailUrl':
                  this.setState({
                    videoPromoThumbnailLinkWarning: true
                  });
                  break;
                case 'videoPromovideoLink':
                  this.setState({
                    videoPromoLinkWarning: true
                  });
                  break;
                case 'videoPitchthumbnailUrl':
                  this.setState({
                    videoPitchThumbnailLinkWarning: true
                  });
                  break;

                case 'videoPitchvideoLink':
                  this.setState({
                    videoPitchLinkWarning: true
                  });
                  break;
                case 'projectDescription':
                  this.setState({
                    descriptionWarning: true
                  });
                  break;
                case 'projectPerks':
                  this.setState({
                    perksWarning: true
                  });
                  break;
              }
            }
          }
        })
    }
  };

  toggleLogoMaker = () => (this.setState(({isLogoMakerOpen}) => ({
    isLogoMakerOpen: !isLogoMakerOpen
  })));

  saveProjectLogo = (logoInBase64) => {
    sendParseRequest('uploadProjectImage', {base64: logoInBase64, type: 'image/png'})
      .then(results => {
        this.setState({projectlogo: [results]});
        this.updateInvestProjectData('logoAttach', [results]);
        this.toggleLogoMaker();
      })
  };

  // handleWatchVideo = () => {
  //     this.setState({videoShow: !this.state.videoShow});
  // }

  render() {
    const {
      principals, collaborators, team, stageName, projectTitle,
      projectSubtitle, tickerSymbol, projectlogo, companyWebsite, linkToAuction,
      projectWebsite, socialMedia, videoPitch, videoPromo, ipPercentage, projectDescription,
      isOwnerOfTheProject, proofOfWork, financingGoal, showPoll, isLogoMakerOpen,
      projectPerks, mediaCategory, additionalData, fName, lName, projectId
    } = this.state;


    // console.log("STATE projectInvest", this.state)

    return (

      <div className="page invest-page">
        <div className="pageContent projectPage">
          {/*<div className="mainHeader">
                        <p className="mainTitle">Bed Romance</p>
                        <p className="mainTitle">- GaGe "Singer, actor"</p>
                        <div className="tokenSummarySiteWrap">
                          {/*<div className="cHeader">
			            					<p className="title">Join Our Sale Now</p>
			            				</div>
			            				<div className="cBody">
			            					<TokenSummary />
		                                </div>
                        </div>
                        {/*<a onClick={this.handleWatchVideo} className="waTchVideoBttn">Watch Video</a>
                        <Link className="getStartedBttn" to="/register">
                          Buy Gage Coins
                        </Link>
                    </div>*/}

          {/*<div className="fnbXTokenAnythingWrap card6">*/}
          <div className="leftSideModuleWrap presaleWrapper projectPage__contentBody"
               style={{paddingBottom: "80px"}}>
            {/*<div className="rightSideModule" style={{"width":"70%","margin":"0 auto","float":"none","paddingRight":"0","textAlign":"center"}}>
							<img className="fanbaseGraph" />
							<table className="saleTable mainTable">
									<tbody>
										<tr className="saleTablerow mainTablerow">
										<th>Tier</th>
										<th>Issued Tokens</th>
										<th>Bonus</th>
										<th>Total</th>
									</tr>
									<tr className="saleTablerow mainTablerow">
											<td><p className="tiersTitle">Presale</p></td>
											<td><p className="amountCoinsValue">2,000,000,000 FNBD</p></td>
											<td><p className="amountCoinsValueDescription">100%</p></td>
											<td><p className="amountCoinsValue">4,000,000,000 FNBD</p></td>
									</tr>

								</tbody>
							</table>
                        </div>*/}
            <ImagesLayout isOwner={isOwnerOfTheProject} projectID={projectId}
                          bannerImages={this.state.bannerImages}/>
            <div className="project-page leftSideModule">
              <div className="section-input-wrap">
                <label htmlFor="limitedLicense" style={{
                  "display": "inline-block",
                  "width": "570px",
                  "paddingLeft": "20px",
                  "verticalAlign": "middle"
                }}>
                  {this.state.editState === "1"
                    ? <div className="editBlock--alignedCenter">
                      <div className="text-right editBlock__button">
                        <button className=" editBtn editBtn--withoutBorder"
                                onClick={() => this.updateInvestProjectData('projectTitle')}
                        >
                          Update
                        </button>
                      </div>
                      <div className="editBlock__input">
                        <input defaultValue={projectTitle}
                               maxLength="100" type="text"
                               className="editBox"
                               placeholder={projectTitle}
                               ref={this.projectTitle}
                        />
                      </div>
                    </div>
                    :
                    <div className="editBlock--alignedCenter">
                      <div className="editBlock__button">
                        {isOwnerOfTheProject &&
                        <button className="editBtn editBtn--withoutBorder"
                                onClick={() => this.setState({editState: "1"})}>
                          Edit
                        </button>
                        }
                      </div>
                      <p className="editBlock__text s-input-title text-center"
                         style={{"fontSize": "40px", "paddingLeft": "15px"}}>
                        {projectTitle}
                      </p>

                    </div>}
                  {this.state.editState === "2"
                    ? <div className="editBlock--alignedCenter">
                      <div className="editBlock__button text-right">
                        <button className="editBtn editBtn--withoutBorder"
                                onClick={() => this.updateInvestProjectData('projectSubtitle')}
                        >
                          Update
                        </button>
                      </div>
                      <div className="editBlock__input">
                        <input defaultValue={projectSubtitle}
                               maxLength="100" type="text"
                               className="editBox"
                               placeholder={projectSubtitle}
                               style={{margin: '0px'}}
                               ref={this.projectSubtitle}
                        />
                      </div>
                    </div>
                    : <div className="editBlock--alignedCenter">
                      <div className="editBlock__button">
                        {isOwnerOfTheProject &&
                        <button className="editBtn editBtn--withoutBorder"
                                onClick={() => this.setState({editState: "2"})}>
                          Edit
                        </button>
                        }
                      </div>
                      <div className="editBlock__text" style={{fontStyle: 'italic'}}>
                        <p className="s-input-descr" style={{"fontSize": "25px"}}>
                          {projectSubtitle}
                        </p>
                      </div>
                    </div>}
                  {stageName
                    ? <div className="text-center">
                      <p className="s-input-descr"
                         style={{"fontSize": "25px"}}>
                        by {stageName}
                      </p>
                    </div>
                    : <div className="text-center">
                      <p className="s-input-descr"
                         style={{"fontSize": "25px"}}>
                        by {fName} {lName}
                      </p>
                    </div>
                  }
                </label>
              </div>
              <div className="section-input-wrap">
                <label htmlFor="limitedLicense" style={{
                  "display": "inline-block",
                  "width": "570px",
                  "paddingLeft": "20px",
                  "verticalAlign": "middle"
                }}>
                  <p className="s-input-title ticker-title" style={{"fontSize": "18px"}}>Ticker
                    <span style={{position: "relative"}}>
                                            {isOwnerOfTheProject &&
                                            <span className="changeLogoBlock"
                                                  onClick={this.toggleLogoMaker}>
                                                Edit
                                            </span>
                                            }
                      {projectlogo.length > 0 &&
                      <img src={projectlogo} className="logoImage" alt='logo image'/>
                      }
                                        </span>
                  </p>
                  <p className="s-input-descr"
                     style={{"fontSize": "15px", "textAlign": "justify"}}>
                    {tickerSymbol}
                  </p>
                </label>
                {isLogoMakerOpen &&
                <ModalWindow title={"The Logo Maker"} hideModal={this.toggleLogoMaker}>
                  <LogoMaker insertLogo={this.saveProjectLogo}
                             allowToUploadPhoto={true}/>
                </ModalWindow>
                }
              </div>
              <div className="section-input-wrap">
                <label htmlFor="limitedLicense" style={{
                  "display": "inline-block",
                  "width": "570px",
                  "paddingLeft": "20px",
                  "verticalAlign": "middle"
                }}>
                  <p className="s-input-title" style={{"fontSize": "18px"}}>Project Links</p>
                  <div className="s-input-descr"
                       style={{"fontSize": "15px", "textAlign": "justify", "position": "relative"}}>
                    {this.state.reportInProgress
                      ? <p className='warning-reportInProgress'>Report in progress</p>
                      : false
                    }
                    <ul style={{"listStyle": "none", "paddingInlineStart": "0px"}}
                        className="project-editLinks">
                      {this.state.showProjectWebsite
                        ? this.state.editState === "11"
                          ? <li className='socialMedia__block-list'>
                            <input type="text"
                                   defaultValue={projectWebsite ? projectWebsite[0].link : ""}
                                   className='socialMedia__block-input'
                                   ref={this.projectWebsite}
                            />
                            {/*<button className="editBtn editBtn--withoutBorder"*/}
                            {/*        onClick={() => this.updateInvestProjectData('projectWebsite', null, true)}*/}
                            {/*>*/}
                            {/*    Update*/}
                            {/*</button>*/}
                          </li>
                          : <div style={{position: "relative"}}>
                            <li className='socialMedia__block-list'>
                              {projectWebsite[0] && projectWebsite[0].link
                                ? <a href={projectWebsite[0].link}>
                                  Project Website link
                                </a>
                                : isOwnerOfTheProject
                                  ?
                                  <span>Click Add to update Project Website link</span>
                                  : 'No links added'

                              }
                              {/*{isOwnerOfTheProject && projectWebsite[0].link.length === 0*/}
                              {/*    ? <button className="editBtn editBtn--withoutBorder"*/}
                              {/*              onClick={() => this.setState({editState: "11"})}>*/}
                              {/*        Add*/}
                              {/*    </button>*/}
                              {/*    : isOwnerOfTheProject*/}
                              {/*        ? <button className="editBtn editBtn--withoutBorder"*/}
                              {/*                  onClick={() => this.setState({editState: "11"})}>*/}
                              {/*            Update*/}
                              {/*        </button>*/}
                              {/*        : false*/}
                              {/*}*/}
                              {!isOwnerOfTheProject && projectWebsite[0].link.length > 0
                                ? <button className="editBtn editBtn--withoutBorder"
                                          onClick={() => this.showArea('website')}>
                                  Report
                                </button>
                                : false
                              }
                            </li>
                            {this.state.websiteLinkWarning === true
                              ? <p className='warningLinkMessage'>
                                Received a complaint, correct the link
                              </p>
                              : false
                            }
                            <div className={this.state.isReportWebsite
                              ? 'report__block-on'
                              : 'report__block-off'}
                            >
                                                            <textarea id='website'
                                                                      onBlur={this.getReportMessage}
                                                                      className={this.state.isReportWebsite ? 'report__block-on' : 'report__block-off'}
                                                            />
                              <button onClick={() => this.submitReport(
                                this.state.reportMessageWebsite,
                                'isReportWebsite',
                                'isSubmitWebsite'
                              )}
                                      className={this.state.isReportWebsite ? 'blue-button submitReport__button report__block-on' : 'blue-button submitReport__button report__block-off'}
                              >
                                Submit
                              </button>
                            </div>
                            <p
                              className={this.state.isSubmitWebsite ? 'afterSubmit-message report__block-on' : 'afterSubmit-message report__block-off'}>
                              Thanks for the info!
                            </p>
                          </div>
                        : <p className='banned-message'>project website link banned</p>
                      }
                      {this.state.showMedia
                        ? this.state.editState === "12"
                          ? <li className='socialMedia__block-list'>
                            <input type="text"
                                   defaultValue={socialMedia ? socialMedia[0].link : ""}
                                   className='socialMedia__block-input'
                                   ref={this.socialMedia}
                            />
                            {/*<button className="editBtn editBtn--withoutBorder"*/}
                            {/*        onClick={() => this.updateInvestProjectData('socialMedia', null, true)}*/}
                            {/*>*/}
                            {/*    Update*/}
                            {/*</button>*/}
                          </li>
                          : <div style={{position: "relative"}}>
                            <li className='socialMedia__block-list'>
                              {socialMedia[0] && socialMedia[0].link
                                ? <div className='socialMedia__block'>
                                  {this.iconImg()
                                    ? <img src={this.iconImg()} alt="icon"/>
                                    : null
                                  }
                                  <a href={socialMedia[0].link}>
                                    {this.iconImg() ? this.socialLinkName() : 'Social platform link'}
                                  </a>
                                </div>
                                : isOwnerOfTheProject ?
                                  <span>Click Add to update Social Platform link</span> : 'No links added'
                              }
                              {/*{isOwnerOfTheProject && socialMedia[0].link.length === 0*/}
                              {/*    ? <button className="editBtn editBtn--withoutBorder"*/}
                              {/*              onClick={() => this.setState({editState: "12"})}>*/}
                              {/*        Add*/}
                              {/*    </button>*/}
                              {/*    : isOwnerOfTheProject*/}
                              {/*        ? <button className="editBtn editBtn--withoutBorder"*/}
                              {/*                  onClick={() => this.setState({editState: "12"})}>*/}
                              {/*            Update*/}
                              {/*        </button>*/}
                              {/*        : false*/}
                              {/*}*/}
                              {!isOwnerOfTheProject && socialMedia[0] && socialMedia[0].link.length > 0
                                ? <button className="editBtn editBtn--withoutBorder"
                                          onClick={() => this.showArea('social')}>
                                  Report
                                </button>
                                : false
                              }
                            </li>
                            {this.state.socialMediaLinkWarning
                              ? <p className='warningLinkMessage'>
                                Received a complaint, correct the link
                              </p>
                              : false
                            }
                            <div className={this.state.isReportSocial
                              ? 'report__block-on'
                              : 'report__block-off'}
                            >
                                                            <textarea id='social'
                                                                      onBlur={this.getReportMessage}
                                                                      className={this.state.isReportSocial ? 'report__block-on' : 'report__block-off'}/>
                              <button onClick={() => this.submitReport(
                                this.state.reportMessageSocial,
                                'isReportSocial',
                                'isSubmitSocial'
                              )}
                                      className={this.state.isReportSocial ? 'blue-button submitReport__button report__block-on' : ' blue-button submitReport__button report__block-off'}
                              >
                                Submit
                              </button>
                            </div>
                            <p
                              className={this.state.isSubmitSocial ? 'afterSubmit-message report__block-on' : 'afterSubmit-message report__block-off'}>
                              Thanks for the info!
                            </p>
                          </div>
                        : <p className='banned-message'>social media link banned</p>
                      }
                      {this.state.showVideoPitch
                        ? this.state.editState === "13"
                          ? <li className='socialMedia__block-list'>
                            <input type="text"
                                   defaultValue={videoPitch.length > 0 ? videoPitch[0].thumbnailUrl : ""}
                                   className='socialMedia__block-input'
                                   ref={this.pitchThumbnail}
                            />
                            <button className="editBtn editBtn--withoutBorder"
                                    onClick={() => this.updateInvestProjectData('pitchThumbnail', null, true)}
                            >
                              Update
                            </button>
                          </li>
                          : <div style={{position: "relative"}}>
                            <li className='socialMedia__block-list'>
                              {videoPitch[0] && videoPitch[0].thumbnailUrl.length > 0
                                ? <a href={videoPitch[0].thumbnailUrl}>
                                  VideoPitch thumbnailUrl
                                </a>
                                : isOwnerOfTheProject
                                  ? <span>
                                                                        Click Add to update VideoPitch Thumbnail link
                                                                      </span>
                                  : 'No links added'
                              }
                              {isOwnerOfTheProject && videoPitch[0].thumbnailUrl.length === 0
                                ? <button className="editBtn editBtn--withoutBorder"
                                          onClick={() => this.setState({editState: "13"})}
                                >
                                  Add
                                </button>
                                : isOwnerOfTheProject
                                  ? <button className="editBtn editBtn--withoutBorder"
                                            onClick={() => this.setState({editState: "13"})}
                                  >
                                    Update
                                  </button>
                                  : false
                              }
                              {!isOwnerOfTheProject && videoPitch[0] && videoPitch[0].thumbnailUrl.length > 0
                                ? <button className="editBtn editBtn--withoutBorder"
                                          onClick={() => this.showArea('pitchThumb')}>
                                  Report
                                </button>
                                : false
                              }
                            </li>
                            {this.state.videoPitchThumbnailLinkWarning === true
                              ? <p className='warningLinkMessage'>
                                Received a complaint, correct the link
                              </p>
                              : false
                            }
                            <div className={this.state.isReportPitchThumb
                              ? 'report__block-on'
                              : 'report__block-off'}
                            >
                                                            <textarea id='pitchThumb'
                                                                      onBlur={this.getReportMessage}
                                                                      className={this.state.isReportPitchThumb ? 'report__block-on' : 'report__block-off'}
                                                            />
                              <button onClick={() => this.submitReport(
                                this.state.reportMessagePitchThumb,
                                'isReportPitchThumb',
                                'isSubmitPitchThumb'
                              )}
                                      className={this.state.isReportPitchThumb ? 'blue-button submitReport__button report__block-on' : 'blue-button submitReport__button report__block-off'}
                              >
                                Submit
                              </button>
                            </div>
                            <p
                              className={this.state.isSubmitPitchThumb ? 'afterSubmit-message report__block-on' : 'afterSubmit-message report__block-off'}>
                              Thanks for the info!
                            </p>
                          </div>
                        : <p className='banned-message'>video pitch thumbnail banned</p>
                      }
                      {this.state.showVideoPitch
                        ? this.state.editState === "14"
                          ? <li className='socialMedia__block-list'>
                            <input type="text"
                                   defaultValue={videoPitch.length > 0 ? videoPitch[0].videoLink : ""}
                                   className='socialMedia__block-input'
                                   ref={this.videoPitchLink}
                            />
                            <button className="editBtn editBtn--withoutBorder"
                                    onClick={() => this.updateInvestProjectData('videoPitchLink', null, true)}
                            >
                              Update
                            </button>
                          </li>
                          : <div style={{position: "relative"}}>
                            <li className='socialMedia__block-list'>
                              {videoPitch[0] && videoPitch[0].videoLink.length > 0
                                ? <a href={videoPitch[0].videoLink}>
                                  VideoPitch videoLink
                                </a>
                                : isOwnerOfTheProject
                                  ? <span>
                                                                        Click Add to update VideoPitch link
                                                                     </span>
                                  : 'No links added'
                              }
                              {isOwnerOfTheProject && videoPitch[0].videoLink.length === 0
                                ? <button className="editBtn editBtn--withoutBorder"
                                          onClick={() => this.setState({editState: "14"})}>
                                  Add
                                </button>
                                : isOwnerOfTheProject
                                  ? <button className="editBtn editBtn--withoutBorder"
                                            onClick={() => this.setState({editState: "14"})}>
                                    Update
                                  </button>
                                  : false
                              }
                              {!isOwnerOfTheProject && videoPitch[0] && videoPitch[0].videoLink.length > 0
                                ? <button className="editBtn editBtn--withoutBorder"
                                          onClick={() => this.showArea('pitchLink')}>
                                  Report
                                </button>
                                : false
                              }
                            </li>
                            {this.state.videoPitchLinkWarning === true
                              ? <p className='warningLinkMessage'>
                                Received a complaint, correct the link
                              </p>
                              : false
                            }
                            <div className={this.state.isReportPitchLink
                              ? 'report__block-on'
                              : 'report__block-off'}
                            >
                                                          <textarea id='pitchLink'
                                                                    onBlur={this.getReportMessage}
                                                                    className={this.state.isReportPitchLink ? 'report__block-on' : 'report__block-off'}/>
                              <button onClick={() => this.submitReport(
                                this.state.reportMessagePitchLink,
                                'isReportPitchLink',
                                'isSubmitPitchLink'
                              )}
                                      className={this.state.isReportPitchLink ? 'blue-button submitReport__button report__block-on' : 'blue-button submitReport__button report__block-off'}
                              >
                                Submit
                              </button>
                            </div>
                            <p
                              className={this.state.isSubmitPitchLink ? 'afterSubmit-message report__block-on' : 'afterSubmit-message report__block-off'}>
                              Thanks for the info!
                            </p>
                          </div>
                        : <p className='banned-message'>video pitch link banned</p>
                      }
                      {this.state.showVideoPromo
                        ? this.state.editState === "15"
                          ? <li className='socialMedia__block-list'>
                            <input type="text"
                                   defaultValue={videoPromo.length > 0 ? videoPromo[0].thumbnailUrl : ""}
                                   className='socialMedia__block-input'
                                   ref={this.promoThumbnail}
                            />
                            <button className="editBtn editBtn--withoutBorder"
                                    onClick={() => this.updateInvestProjectData('promoThumbnail', null, true)}
                            >
                              Update
                            </button>
                          </li>
                          : <div style={{position: "relative"}}>
                            <li className='socialMedia__block-list'>
                              {videoPromo[0] && videoPromo[0].thumbnailUrl.length > 0
                                ? <a href={videoPromo[0].thumbnailUrl}>
                                  VideoPromo thumbnailUrl
                                </a>
                                : isOwnerOfTheProject
                                  ? <span>
                                                                        Click Add to update VideoPromo thumbnail link
                                                                </span>
                                  : 'No links added'
                              }
                              {isOwnerOfTheProject && videoPromo[0].thumbnailUrl.length === 0
                                ? <button className="editBtn editBtn--withoutBorder"
                                          onClick={() => this.setState({editState: "15"})}>
                                  Add
                                </button>
                                : isOwnerOfTheProject
                                  ? <button className="editBtn editBtn--withoutBorder"
                                            onClick={() => this.setState({editState: "15"})}>
                                    Update
                                  </button>
                                  : false
                              }
                              {!isOwnerOfTheProject && videoPromo[0] && videoPromo[0].thumbnailUrl.length > 0
                                ? <button className="editBtn editBtn--withoutBorder"
                                          onClick={() => this.showArea('promoThumb')}>
                                  Report
                                </button>
                                : false
                              }
                            </li>
                            {this.state.videoPromoThumbnailLinkWarning === true
                              ? <p className='warningLinkMessage'>
                                Received a complaint, correct the link
                              </p>
                              : false}
                            <div className={this.state.isReportPromoThumb
                              ? 'report__block-on'
                              : 'report__block-off'}
                            >
                                                            <textarea id='promoThumb'
                                                                      onBlur={this.getReportMessage}
                                                                      className={this.state.isReportPromoThumb ? 'report__block-on' : 'report__block-off'}/>
                              <button onClick={() => this.submitReport(
                                this.state.reportMessagePromoThumb,
                                'isReportPromoThumb',
                                'isSubmitPromoThumb'
                              )}
                                      className={this.state.isReportPromoThumb ? 'blue-button submitReport__button report__block-on' : 'blue-button submitReport__button report__block-off'}
                              >
                                Submit
                              </button>
                            </div>
                            <p
                              className={this.state.isSubmitPromoThumb ? 'afterSubmit-message report__block-on' : 'afterSubmit-message report__block-off'}>
                              Thanks for the info!
                            </p>
                          </div>
                        : <p className='banned-message'>video promo thumbnail banned</p>
                      }
                      {this.state.showVideoPromo
                        ? this.state.editState === "16"
                          ? <li className='socialMedia__block-list'>
                            <input type="text"
                                   defaultValue={videoPromo[0].videoLink}
                                   className='socialMedia__block-input'
                                   ref={this.promovideoLink}
                            />
                            <button className="editBtn editBtn--withoutBorder"
                                    onClick={() => this.updateInvestProjectData('promovideoLink', null, true)}
                            >
                              Update
                            </button>
                          </li>
                          : <div style={{position: "relative"}}>
                            <li className='socialMedia__block-list'>
                              {videoPromo[0] && videoPromo[0].videoLink.length > 0
                                ? <a href={videoPromo[0].videoLink}>VideoPromo
                                  videoLink</a>
                                : isOwnerOfTheProject ?
                                  <span>Click Add to update VideoPromo link</span>
                                  : 'No links added'
                              }
                              {isOwnerOfTheProject && videoPromo[0].videoLink.length === 0
                                ? <button className="editBtn editBtn--withoutBorder"
                                          onClick={() => this.setState({editState: "16"})}>
                                  Add
                                </button>
                                : isOwnerOfTheProject
                                  ? <button className="editBtn editBtn--withoutBorder"
                                            onClick={() => this.setState({editState: "16"})}>
                                    Update
                                  </button>
                                  : false
                              }
                              {!isOwnerOfTheProject && videoPromo[0] && videoPromo[0].videoLink.length > 0
                                ? <button className="editBtn editBtn--withoutBorder"
                                          onClick={() => this.showArea('promoLink')}>
                                  Report
                                </button>
                                : false
                              }
                            </li>
                            {this.state.videoPromoLinkWarning === true
                              ? <p className='warningLinkMessage'>
                                Received a complaint, correct the link
                              </p>
                              : false
                            }
                            <div className={this.state.isReportPromoLink
                              ? 'report__block-on'
                              : 'report__block-off'}
                            >
                                                            <textarea id='promoLink'
                                                                      onBlur={this.getReportMessage}
                                                                      className={this.state.isReportPromoLink ? 'report__block-on' : 'report__block-off'}
                                                            />
                              <button onClick={() => this.submitReport(
                                this.state.reportMessagePromoLink,
                                'isReportPromoLink',
                                'isSubmitPromoLink'
                              )}
                                      className={this.state.isReportPromoLink ? 'blue-button submitReport__button report__block-on' : 'blue-button submitReport__button report__block-off'}
                              >
                                Submit
                              </button>
                            </div>
                            <p
                              className={this.state.isSubmitPromoLink ? 'afterSubmit-message report__block-on' : 'afterSubmit-message report__block-off'}>
                              Thanks for the info!
                            </p>
                          </div>
                        : <p className='banned-message'>video promo link banned</p>
                      }
                      {companyWebsite
                        ? <li><a href={companyWebsite}>{companyWebsite}</a></li>
                        : ""
                      }
                      {linkToAuction
                        ? <li><a href={linkToAuction}>{linkToAuction}</a></li>
                        : ""
                      }
                    </ul>
                  </div>
                </label>
              </div>
              <div className="section-input-wrap">
                <label htmlFor="limitedLicense" style={{
                  "display": "inline-block",
                  "width": "570px",
                  "paddingLeft": "20px",
                  "verticalAlign": "middle"
                }}>
                  <p className="s-input-title" style={{"fontSize": "18px"}}>
                    Item Information
                  </p>
                  <p className="s-input-descr" style={{"fontSize": "15px", "textAlign": "justify"}}>
                    Collectors Item, Medium is Music, Format is Song, Length is 4-5 Mins, 100 Total
                    Licenses, Weekly Auction, No Proof of Work Requirement
                  </p>
                </label>
              </div>
              <hr/>
              <div className="section-input-wrap">
                <label htmlFor="limitedLicense" style={{
                  "display": "inline-block",
                  "width": "570px",
                  "paddingLeft": "20px",
                  "verticalAlign": "middle"
                }}>
                  {this.state.showDescription
                    ? this.state.editState === "3"
                      ? <div>
                        <div
                          className="d-flex align-items-center justify-content-between w-100">
                          <p className="s-input-title" style={{"fontSize": "15px"}}>
                            Description
                          </p>
                          <button className="updateBtn editBtn editBtn--withoutBorder"
                                  onClick={() => this.updateInvestProjectData("projectDescription", null, true)}>
                            Update
                          </button>
                        </div>
                        <div className="s-input-descr"
                             style={{"fontSize": "15px", "textAlign": "justify"}}>
                                                    <textarea defaultValue={projectDescription}
                                                              placeholder={projectDescription}
                                                              rows="5"
                                                              maxLength="1000"
                                                              ref={this.projectDescription}
                                                    />
                        </div>
                      </div>
                      : <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="s-input-title"
                             style={{"fontSize": "15px"}}>Description </p>
                          {isOwnerOfTheProject &&
                          <button className="editBtn editBtn--withoutBorder"
                                  onClick={() => this.setState({editState: "3"})}>
                            Edit
                          </button>
                          }
                          {!isOwnerOfTheProject && projectDescription.length > 0
                            ? <button className="editBtn editBtn--withoutBorder"
                                      onClick={() => this.showArea('description')}>
                              Report
                            </button>
                            : false
                          }
                        </div>
                        <p className="s-input-descr" style={{
                          "fontSize": "15px",
                          "textAlign": "justify"
                        }}>
                          {projectDescription}
                        </p>
                        {this.state.descriptionWarning === true
                          ? <p className='warningLinkMessage'>
                            Received a complaint, correct the description
                          </p>
                          : false
                        }
                        <div className={this.state.isReportDescription
                          ? 'report__block-on'
                          : 'report__block-off'}
                        >
                                                    <textarea id='description'
                                                              onBlur={this.getReportMessage}
                                                              className={this.state.isReportDescription ? 'report__block-on' : 'report__block-off'}/>
                          <button onClick={() => this.submitReport(
                            this.state.reportMessageDescription,
                            'isReportDescription',
                            'isSubmitDescription'
                          )}
                                  className={this.state.isReportDescription ? 'blue-button submitReport__button report__block-on' : 'blue-button submitReport__button report__block-off'}
                          >
                            Submit
                          </button>
                        </div>
                        <p
                          className={this.state.isSubmitDescription ? 'afterSubmit-message report__block-on' : 'afterSubmit-message report__block-off'}>
                          Thanks for the info!
                        </p>
                      </div>
                    : <p className='banned-message'>project description banned</p>
                  }
                </label>
              </div>
              <hr/>
              <div className="section-input-wrap">
                <label htmlFor="limitedLicense" style={{
                  "display": "inline-block",
                  "width": "570px",
                  "paddingLeft": "20px",
                  "verticalAlign": "middle"
                }}>
                  {this.state.showPerks
                    ? this.state.editState === "4"
                      ? <div>
                        <div className="row align-items-center justify-content-between">
                          <p className="s-input-title" style={{"fontSize": "15px"}}>
                            Perks
                          </p>
                          <button className="updateBtn editBtn editBtn--withoutBorder"
                                  onClick={() => this.updateInvestProjectData("projectPerks", null, true)}>
                            Update
                          </button>
                        </div>
                        <div className="s-input-descr"
                             style={{"fontSize": "15px", "textAlign": "justify"}}>
                                                    <textarea defaultValue={projectPerks}
                                                              placeholder={projectPerks}
                                                              rows="5"
                                                              maxLength="1000"
                                                              ref={this.projectPerks}
                                                    />
                        </div>
                      </div>
                      : <div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="s-input-title" style={{"fontSize": "15px"}}>
                            Perks
                          </p>
                          {isOwnerOfTheProject &&
                          <button className="editBtn editBtn--withoutBorder"
                                  onClick={() => this.setState({editState: "4"})}>
                            Edit
                          </button>
                          }
                          {!isOwnerOfTheProject && projectPerks.length > 0
                            ? <button className="editBtn editBtn--withoutBorder"
                                      onClick={() => this.showArea('perks')}>
                              Report
                            </button>
                            : false
                          }
                        </div>
                        <p className="s-input-descr"
                           style={{
                             "fontSize": "15px",
                             "textAlign": "justify"
                           }}>
                          {projectPerks}
                        </p>
                        {this.state.perksWarning === true
                          ? <p className='warningLinkMessage'>
                            Received a complaint, correct the project perks
                          </p>
                          : false
                        }
                        <div className={this.state.isReportPerks
                          ? 'report__block-on'
                          : 'report__block-off'}
                        >
                                                    <textarea id='perks'
                                                              onBlur={this.getReportMessage}
                                                              className={this.state.isReportPerks ? 'report__block-on' : 'report__block-off'}/>
                          <button onClick={() => this.submitReport(
                            this.state.reportMessagePerks,
                            'isReportPerks',
                            'isSubmitPerks'
                          )}
                                  className={this.state.isReportPerks ? 'blue-button submitReport__button report__block-on' : 'blue-button submitReport__button report__block-off'}
                          >
                            Submit
                          </button>
                        </div>
                        <p
                          className={this.state.isSubmitPerks ? 'afterSubmit-message report__block-on' : 'afterSubmit-message report__block-off'}>
                          Thanks for the info!
                        </p>
                      </div>
                    : <p className='banned-message'>project perks banned</p>
                  }
                </label>
              </div>
              <hr/>
              <div className="section-input-wrap">
                <label htmlFor="limitedLicense" style={{
                  "display": "inline-block",
                  "width": "570px",
                  "paddingLeft": "20px",
                  "verticalAlign": "middle"
                }}>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="s-input-title" style={{"fontSize": "15px"}}>
                      Additional Attachments
                    </p>
                  </div>
                  {this.state.attachName
                    ? <div className="attachments__block">
                      <p>{this.state.attachName}</p>
                      <button onClick={() => this.openViewer()}
                              className="editBtn editBtn--withoutBorder view-attach__btn">
                        View
                      </button>
                      {this.state.openFileViewer &&
                      <ShowProjectMediaFile itemsData={this.state.itemsData}
                                            onClose={this.onFileViewerClose}
                                            tab="project"
                      />
                      }
                      {/*{isOwnerOfTheProject &&*/}
                      {/*<button className="editBtn editBtn--withoutBorder edit-attach__btn"*/}
                      {/*        onClick={this.openInput}>*/}
                      {/*  Edit file name*/}
                      {/*</button>*/}
                      {/*}*/}
                      {this.state.attachInput
                        ? <div className="attach-name__block">
                          <input defaultValue={this.state.attachName.split('.')[0]}
                                 ref={this.inputRef}
                                 onChange={this.validateInput}
                          />
                          <button className="pull-right viewallA attach_save"
                                  onClick={() => this.saveAttachName(this.inputRef)}
                          >
                            save
                          </button>
                        </div>
                        : null
                      }
                    </div>
                    : isOwnerOfTheProject
                      ? <UploadAttachTOIpfs setAttachItem={this.setAttachItem}
                                            attachItem={this.state.attachItem}
                                            attachFileType={this.state.attachFileType}
                                            name={this.state.attachFileName}
                      />
                      : 'Not specified by creator'
                  }
                </label>
              </div>
              <hr/>
              <div className="section-input-wrap">
                <p className="s-input-title" style={{"fontSize": "15px", "padding-left": "20px"}}>
                  Additional Data
                </p>
                <DropDownList additionalData={additionalData}
                              mediaCategory={mediaCategory}/>
              </div>
            </div>
            <div className="leftSideModule moduleInvestForm">
              <InvestCard tickerSymbol={tickerSymbol}
                          numOfLicenses={100}
                          projectId={projectId}
                          showPoll={() => this.setState({showPoll: true})}
                          ipPercentage={ipPercentage}
                          project={this.state.project}
                          onUpdateInvestedMoney={this.onUpdateInvestedMoney}/>

              <ProofOfWorkPoll proofOfWork={proofOfWork}
                               projectId={projectId}
                               showPoll={showPoll}
                               financingGoal={financingGoal}
                               project={this.state.project}
                               investedMoney={this.state.investedMoney}/>


              {/*<div className="card" >*/}
              {/*  <div className="cBody exchangeDetails">*/}
              {/*    <p className="initialTitle">You are investing in...</p>*/}
              {/*    <p className="amountofFnb">{tickerSymbol}</p> /!* FANBOY1 {calculateFnbAmount(prop,"t")}*!/*/}
              {/*    <p className="exchangeRate">Limited Edition Item (100 total)</p>*/}
              {/*    <hr />*/}

              {/*    <div className="bodyWrap">*/}
              {/*      <div className="centerLineWrap">*/}
              {/*        <div className="centerLine"></div>*/}
              {/*        <div className="centerCircles">*/}
              {/*          <div className="leftCircle">*/}
              {/*            */}
              {/*          </div>*/}
              {/*          <div className="leftCircle">*/}
              {/*            */}
              {/*          </div>*/}
              {/*          <div className="leftCircle">*/}
              {/*            */}
              {/*          </div>*/}
              {/*          <div className="leftCircle">*/}
              {/*            */}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*      <div className="ExchangeDetailsWrap">*/}
              {/*        <ul>*/}
              {/*          <li>*/}
              {/*            <p className="title">Goal</p>*/}
              {/*            <p className="subtitle"> 100000 FNBD/USD</p>*/}
              {/*            <hr />*/}
              {/*          </li>*/}
              {/*          <li>*/}
              {/*            <p className="title">Your Investment</p>*/}
              {/*            <p className="subtitle" id="totalFnbAMount">25000 FNBD/USD</p>*/}
              {/*            <hr />*/}
              {/*          </li>*/}
              {/*          <li>*/}
              {/*            <p className="title">Investor Stake</p>*/}
              {/*            <p className="subtitle">{ipPercentage}</p> /!* ipPercentage hardcoded=25%*!/*/}
              {/*            <hr />*/}
              {/*          </li>*/}
              {/*          <li>*/}
              {/*            <p className="title">Item ID</p>*/}
              {/*            <p className="subtitle">#1</p>*/}
              {/*          </li>*/}
              {/*          */}
              {/*        </ul>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*    <hr style={{"margin":"0","padding":"0","paddingBottom":"10px"}} />*/}
              {/*    <div className="finalFeeWrap">*/}
              {/*      <span className="pull-left title">Subtotal</span>*/}
              {/*      <span className="pull-right title"></span>*/}
              {/*      <div className="verticalLine"></div>*/}
              {/*    </div>*/}
              {/*    <div className="finalFeeWrap">*/}
              {/*      <span className="pull-left title">Transaction Fee (1%)</span>*/}
              {/*      <span className="pull-right title"></span>*/}
              {/*      <div className="verticalLine"></div>*/}
              {/*    </div>*/}
              {/*    <div className="finalFeeWrap">*/}
              {/*      <span className="pull-left title">Total</span>*/}
              {/*      <span className="pull-right title"></span>*/}
              {/*      <div className="verticalLine"></div>*/}
              {/*    </div>*/}
              {/*  */}
              {/*  </div>*/}
              {/*  <p className="mini-amount"> Submit</p>*/}
              {/*</div>*/}

              {/*<div className="principalsAndCollaborators">*/}
              {/*    <div className="cBody principalsAndCollaboratorsDetails">*/}
              {/*        <AddUserListContainer userList={principals}*/}
              {/*                              listType="principal"*/}
              {/*                              title="Principals"*/}
              {/*                              isOwnerOfTheProject={isOwnerOfTheProject}*/}
              {/*                              projectId={this.state.projectId}*/}
              {/*                              projectTitle={this.state.projectTitle}*/}
              {/*        />*/}

              {/*    </div>*/}
              {/*</div>*/}


              <div className="principalsAndCollaborators">
                <div className="cBody principalsAndCollaboratorsDetails">
                  <AddUserListContainer userList={collaborators}
                                        listType="collaborator"
                                        title="Collaborators"
                                        isOwnerOfTheProject={isOwnerOfTheProject}
                                        projectId={this.state.projectId}
                                        projectTitle={this.state.projectTitle}
                  />
                </div>
              </div>

              <div className="principalsAndCollaborators">
                <div className="cBody principalsAndCollaboratorsDetails">
                  <AddUserListContainer userList={team}
                                        listType="team"
                                        title="Team"
                                        isOwnerOfTheProject={isOwnerOfTheProject}
                                        projectId={this.state.projectId}
                                        projectTitle={this.state.projectTitle}
                  />
                </div>
              </div>
              {/* <p className="title">Project Details</p>
                                        <p className="subtitle">Working Title: </p>
                                        <p className="subtitle">Tag Line: </p>
                                        <p className="subtitle">Promotional Image: </p>
                                        <p className="subtitle">Attach Promotional Image: </p>
                                        <p className="subtitle">Description: </p>
                                        <p className="subtitle">Video Pitch Thumbnail: </p>
                                        <p className="subtitle">Video Pitch: </p>
                                        <p className="subtitle">Video Promo Thumbnail: </p>
                                        <p className="subtitle">Additional Attachments: </p>
                                        <p className="subtitle">Additional Links: </p>
                                        <p className="subtitle">Social Media: </p>
                                        <p className="subtitle">Project Website: </p>
                                        <br />*/}
              {/*
                                        <div className="presaleContainer">
                                        <b>Release Date</b>
                                        <span>March 4th, 2018</span>
                                        </div>
                                        <hr />
                                        <div className="presaleContainer">
                                        <b>End Date:</b>
                                        <span>July 4th, 2018</span>
                                        </div>
                                        <br /><br />
                                        <br /><br />
                                        <Link className="getStartedBttn" to="/register">Claim $10 in FNBD</Link>*/}
            </div>
            <br style={{clear: "both"}}/>
          </div>

          {/*<div*/}
          {/*    className="leftSideModuleWrap presaleWrapper"*/}
          {/*    style={{paddingBottom: "80px"}}*/}
          {/*>*/}
          {/*    <div*/}
          {/*        className="rightSideModule"*/}
          {/*        style={{*/}
          {/*            width: "70%",*/}
          {/*            margin: "0 auto",*/}
          {/*            float: "none",*/}
          {/*            paddingRight: "0"*/}
          {/*        }}*/}
          {/*    >*/}
          {/*
                                        <table className="saleTable mainTable">
                                        <tbody>
                                        <tr className="saleTablerow mainTablerow">
                                        <th>Tier</th>
                                        <th>Issued Tokens</th>
                                        <th>Bonus</th>
                                        <th>Total</th>
                                        </tr>
                                        <tr className="saleTablerow mainTablerow">
                                        <td><p className="tiersTitle">11</p></td>
                                        <td><p className="amountCoinsValue">691,200,000 FNBD</p></td>
                                        <td><p className="amountCoinsValueDescription">4%</p></td>
                                        <td><p className="amountCoinsValue">718,848,000 FNBD</p></td>
                                        </tr>

                                        </tbody>
                                        </table>
                                        */}
          {/*</div>*/}
          {/*<Slider />*/}

          {/*
                                        <div className="leftSideModule" style={{"width":"40%","margin":"0 auto","float":"none","padding":"0","paddingTop":"70px"}}>
                                        <p className="title">Related Project</p>
                                        <p className="subtitle" style={{"lineHeight":"25px"}}>Working Title: (Must Login to pitch a project)</p>
                                        <br />
                                        <div className="presaleContainer">
                                        <b>Start Date:</b>
                                        <span>July 4th, 2018</span>
                                        </div>
                                        <hr />
                                        <div className="presaleContainer">
                                        <b>End Date:</b>
                                        <span>January 4th, 2019</span>
                                        </div>
                                        </div>
                                        */}
          {/*    <br style={{clear: "both"}}/>*/}
          {/*</div>*/}

          {/*{this.state.project*/}
          {/*  ? <CommentsBlock tableName="Projects"*/}
          {/*                   idOfElementForComment={this.state.project ? this.state.project[1] : ''}*/}
          {/*                   defaultAvatar={this.state.defaultUserAvatarLink}*/}
          {/*                   fName={this.state.fName}*/}
          {/*                   sessionToken={this.state.sessionToken}*/}
          {/*    // idOfElementForComment={projectId}*/}
          {/*  />*/}
          {/*  : ""*/}
          {/*}*/}
        </div>
      </div>
    );
  }
}
