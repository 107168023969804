import React from 'react';

import {Link, NavLink} from 'react-router-dom';

const SettingsPageHeader = () => (
  <div className="settingsHeader">
    <nav>
      <NavLink to="/settings/compliance" activeclassname="active">Compliance</NavLink>
      {/*<NavLink to="/settings/verify" activeclassname="active">Verification</NavLink>*/}
      <NavLink to="/settings/preferences" activeclassname="active">Preferences</NavLink>
      <NavLink to="/settings/security" activeclassname="active">Security</NavLink>
      <NavLink to="/settings/limits" activeclassname="active">Limits</NavLink>
      <NavLink to="/settings/activity" activeclassname="active">Activity</NavLink>
      <Link to="/profile" className="pull-right" activeclassname="active">My Profile</Link>
    </nav>
  </div>
);

export default SettingsPageHeader;