"use strict";

const axios = require("axios");
const { ethers } = require("ethers");
const { BN } = require("bn.js");

// First bit: is collectible
// Top 128 bits: asset ID
// Low 128 bits: license index
const ASSET_MASK = new BN(0).notn(128).shln(128);
const COLLECTIBLE_MASK = new BN(1).shln(255);
const LICENSE_INDEX_MASK = new BN(0).notn(128);
// Ticket: 0x0000000000000000000000000000000100000000000000000000000000000000
// Collectible Asset: 0x8000000000000000000000000000000200000000000000000000000000000000
// Collectible License: 0x8000000000000000000000000000000200000000000000000000000000000001

const hexToBn = (hex) => new BN(hex.replace("0x", ""), 16);

const isErc1155Id = (address) => /^0x[0-9a-f]{64}$/.test(address);
const isCollectible = (id) => {
  id = hexToBn(id);
  return id.and(COLLECTIBLE_MASK).eq(COLLECTIBLE_MASK);
};

const isTicket = (id) => !isCollectible(id);

const tenPow9 = ethers.BigNumber.from(10).pow(ethers.BigNumber.from(9));

/**
 * Check that 'checkObj' exists
 * @param {any} checkObj Any object
 * @returns {boolean}
 */
export const IsObjectExists=(checkObj) =>{
  console.log('checkObj', checkObj);
  
  let resultBoolean = true;
  let truthyValue = true;
  if (checkObj) {
    truthyValue = true;
  } else {
    truthyValue = false;
  }

  if (typeof checkObj === "undefined" || checkObj === null || truthyValue == false) {
    resultBoolean = false;
  }

  return resultBoolean;
}

/**
 * Request-Get
 * @param {string} GAS_URL_API URL + API_TOKEN
 * @returns {any}
 */
const GetRequestForGASPRICE = async (GAS_URL_API) => {
  const response = await axios.get(GAS_URL_API);
  return response.data;
};

/**
 * Return structure of recommended gas prices
 * @typedef {Object} GasPrice
 * @property {number} low Actual low gas price (Gwei)
 * @property {number} average Actual average gas price (Gwei)
 * @property {number} fast Actual fast gas price (Gwei)
 */
/**
 * Get actual gas prices (low/average/fast) by public API_KEY
 * @param {string} GAS_URL_API URL + API_TOKEN
 * @returns {GasPrice}
 */
async function GetRecommendedGasPrices(GAS_URL_API) {
  const strJson = await GetRequestForGASPRICE(GAS_URL_API);
  if (IsObjectExists(strJson)) {
    var getJson = strJson;
    return {
      low: Number(getJson.safeLow) / +10,
      average: Number(getJson.average) / +10,
      fast: Number(getJson.fast) / +10,
    };
  }
}

export const  ValidationArray =(_arr) =>{
  let result = true;
  if (IsObjectExists(_arr) == false || Array.isArray(_arr) == false) {
    result = false;
  } else {
    if (typeof _arr.length === "undefined") {
      result = false;
    }
  }
  return result;
}

function valueMulPow10__18(_value) {
  //return ethers.BigNumber.from(_value).mul(tenPow18);
  let res = Number(_value) * 10 ** 9;
  return ethers.BigNumber.from(res.toFixed(0).toString()).mul(tenPow9);
}

function valueDivPow10__18(_value) {
  let partDiv = ethers.BigNumber.from(_value).div(tenPow9);
  return partDiv.toNumber() / 10 ** 9;
}

function mul_decimals(_value, decimals) {
  const tenPow = ethers.BigNumber.from(10).pow(ethers.BigNumber.from(decimals));
  const res = ethers.BigNumber.from(_value.toFixed(0).toString()).mul(tenPow);
  return res.toString();
}
function div_decimals(_value, decimals) {
  const tenPow = ethers.BigNumber.from(10).pow(ethers.BigNumber.from(decimals));
  const res = ethers.BigNumber.from(_value).div(tenPow);
  return res.toString();
}

/**
 * @Description Check format of of ERC-1155 tokenId
 * @param {string} id Identifier of ERC-1155 token
 * @returns {boolean}
 */
function IsERC1155TicketId(id) {
  return isErc1155Id(id);
}

/**
 * @Description This object is сollectible
 * @param {string} id Identifier of ERC-1155 token
 * @returns {boolean}
 */
function isCollectibleId(id) {
  return isCollectible(id);
}

/**
 * @Description Convert array of Uint8 to hex-string
 * @param {Uint8Array} buffer
 * @returns {string}
 */
function Uint8ArrayToHex(buffer) {
  return [...new Uint8Array(buffer)].map((x) => x.toString(16).padStart(2, "0")).join("");
}


// module.exports = {
//   // IsObjectExists,
//   // ValidationArray,
//   GetRecommendedGasPrices,
//   IsERC1155TicketId,
//   isCollectibleId,
//   valueMulPow10__18,
//   valueDivPow10__18,

//   mul_decimals,
//   div_decimals,
//   Uint8ArrayToHex,
// };