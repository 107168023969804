import React, { useEffect, useRef, useState } from 'react'
import LocalStore from "../../../../utils/localStore";
import { walletService } from "../../../api/tendermint/main";

const HDWalletProvider = require("@truffle/hdwallet-provider");
const Web3 = require("web3");




const ImportWallet = ({ setOpenImport, setWelcomeWalletPage, setActiveAddress, combineWalletAddresses, setWalletsArray }) => {
  const [isShow, setIsShow] = useState(true);
  const [confirmPopup, setConfirmPopup] = useState(false);
  let localStore = new LocalStore()
  let masterkey = localStore.getDecoded('userToken');
  console.log(masterkey, "--------------masterkey")

  const mnemonicPhraseRef = useRef();
  const mnemonicPasswordRef = useRef();

  const importWalletFromMnemonic = (mnemonic, password) => {
    //get address from HDWallet with mnemonic and password parameters

    let provider = new HDWalletProvider({
      mnemonic: {
        phrase: mnemonic,
        password: password

      },
      num_addresses: 1,
      providerOrUrl: "http://3.236.143.194:8502"
    });
    // const web3 = new Web3(provider);

    let address = Object.keys(provider.wallets)[0];
    let privKey = provider.wallets[Object.keys(provider.wallets)[0]].privateKey.toString('hex');

    return {
      address: address,
      privKey: privKey
    }

  };


  const importMnemonicFunc = async () => {
    if (mnemonicPhraseRef.current.value && mnemonicPasswordRef.current.value) {
      //call functions for import address from hdwallet

      console.log(mnemonicPhraseRef.current.value, mnemonicPasswordRef.current.value)
      let importedObj = await walletService.importWallet(mnemonicPhraseRef.current.value, mnemonicPasswordRef.current.value)
    console.log('importedObj', importedObj);
    let importedWallet;
      // let importedWallet = await importWalletFromMnemonic(mnemonicPhraseRef.current.value, mnemonicPasswordRef.current.value);











      //next we should add imported address to localStorage (fields: walletsArray, importedWallet). Firstly
      // check, if we have some data to this fields and next add value
      if (masterkey) {
        const localStore = new LocalStore(masterkey);

        const walletsString = localStore.get('walletsList');

        console.log(walletsString, 'walletsString')
        if (walletsString.length > 0) {
          let walletsArr = JSON.parse(walletsString);
          console.log(walletsArr, '---walletsArr2')
          walletsArr.push(importedObj);
console.log(walletsArr, 'walletsArr3')
          //find and remove duplicate
          const onlyUnique = (value, index, self) => {
            return self.indexOf(value) === index;
          };
          let walletsFiltered = walletsArr.filter(onlyUnique);
console.log(walletsFiltered, 'importedWallet', importedObj)
          localStore.set('walletsList', JSON.stringify(walletsFiltered));
          localStorage.setItem('active_address', importedObj.addresses[0]);

//{TODO}: filter for react select 

          // let addressesArr = [];
          // walletsFiltered.forEach((wallet) => {
          //   addressesArr.push(wallet.addresseses[0])
          // });
          // let addressesList = await combineWalletAddresses(importedWallet.addresses[0], addressesArr);
          // setWalletsArray(addressesList)
        } else {


          ///?TODO:import from scratch


        }


      }
      ///DEPRICATED (need to remove later)
      // if (localStorage.getItem('walletsArr')) {
      //     let walletsArray = JSON.parse(localStorage.getItem('walletsArr'));
      //     walletsArray.push(importedWallet);
      //
      //
      //     const onlyUnique = (value, index, self) => {
      //         return self.indexOf(value) === index;
      //     };
      //     let walletsFiltered = walletsArray.filter(onlyUnique);
      //     localStorage.setItem('walletsArr', JSON.stringify(walletsFiltered));
      //     localStorage.setItem('active_address', importedWallet);
      //
      //
      // } else {
      //     localStorage.setItem('walletsArr', JSON.stringify([importedWallet]));
      //     localStorage.setItem('active_address', importedWallet)
      //     let addressesList = await combineWalletAddresses(importedWallet, [importedWallet]);
      //     setWalletsArray(addressesList)
      // }
      //


      //
      //back to mail-wally screen
      setWelcomeWalletPage(true);
      setActiveAddress(true);
      setOpenImport(false);
    }
  };

  return (
    <div className='wallyHome_wrap'>
      <div className='backupPhrase__wrapper'>
        <div className='wallyHome_container'>
          <div className='wallyHome_block'>

            <h1>Import your wallet now</h1>
            <p>Please enter your walletString:</p>
            <textarea type="text"
              className='import-wallet-ta'
              ref={mnemonicPhraseRef}
              autoComplete='false'
            />
            <p>Next enter your password:</p>
            <input type="text"
              ref={mnemonicPasswordRef}
              className='import-wallet-input'
            />

            <button className={isShow ? 'primaryButton blue-btn' : 'disableButton blue-btn'}
              style={{ marginLeft: '10px', marginTop: '20px' }}
              disabled={!isShow}
              onClick={(e) => importMnemonicFunc(e)
              }
            >
              Import
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportWallet;
