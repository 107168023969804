import React, { useEffect, useState } from 'react';
import axios from "axios";
import SettingsPageHeader from "../../../Components/SettingsPageHeader";
import Parse from "parse";
import "./Compliance.css";
import { sendParseRequest } from "../../../../utils/sendParseRequest";

const emojiFlags = require('emoji-flags');

const Compliance = () => {
    const [user, setUser] = useState(Parse.User.current());
    const [apiLoading, setApiLoading] = useState(true);
    const [getIdToken, setGetIdToken] = useState(null);
    const [allowCountry, setAllowCountry] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('AU');
    const [dialCode, setDialCode] = useState('');


let countries = [
    
    'Austria', 'Belgium', 'Bulgaria', 'Croatia',' Republic of Cyprus', 
    'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Ireland', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 'Poland', 
    'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain', 'Sweden'

]


    useEffect(()=> {

        // console.log(navigator.geolocation.getCurrentPosition(), 'navigator.geolocation')
/**
* Get the user IP throught the webkitRTCPeerConnection
* @param onNewIP {Function} listener function to expose the IP locally
* @return undefined
*/
function getUserIP(onNewIP) { //  onNewIp - your listener function for new IPs
    //compatibility for firefox and chrome
    var myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
    var pc = new myPeerConnection({
    iceServers: []
    }),
    noop = function() {},
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
    key;
    function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
    }
    //create a bogus data channel
    pc.createDataChannel("");
    // create offer and set local description
    pc.createOffer().then(function(sdp) {
    sdp.sdp.split('\n').forEach(function(line) {
    if (line.indexOf('candidate') < 0) return;
    line.match(ipRegex).forEach(iterateIP);
    });
    pc.setLocalDescription(sdp, noop, noop);
    }).catch(function(reason) {
    // An error occurred, so handle the failure to connect
    });
    //listen for candidate events
    pc.onicecandidate = function(ice) {
    if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
    };
    }
    // Usage
    getUserIP(function(ip){
        console.log(ip, 'oppp1')
    alert("Got IP! :" + ip);
    });






        var myPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
        console.log(myPeerConnection, 'myPeerConnection')
        var pc = new myPeerConnection({
            iceServers: []
          }),
          noop = function() {},
          localIPs = {},
          ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
          key;
          function ipIterate(ip) {
              console.log(ip, 'iip', !localIPs[ip])
            // if (!localIPs[ip]) onNewIP(ip);
            localIPs[ip] = true;
          }
          pc.createDataChannel("");
          pc.createOffer(function(sdp) {
            sdp.sdp.split('\n').forEach(function(line) {
              if (line.indexOf('candidate') < 0) return;
              line.match(ipRegex).forEach(ipIterate);
            });
            pc.setLocalDescription(sdp, noop, noop);
          }, noop);
          pc.onicecandidate = function(ice) {
            if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
            ice.candidate.candidate.match(ipRegex).forEach(ipIterate);
          };


        console.log(navigator)
        axios({
            method: 'get',
            url: `https://geolocation-db.com/json/`,
            responseType: 'json',
            "headers": {
                // "X-SDK-Key": "0b7860575e8da960eeebb0027e212deac5affdf1837fd54e736b4516c754934c",
                "Content-Type": "application/json"
            },
        }).then((res)=> {
            countries.map((item)=> {
if (item===res.data.country_name) {
    setAllowCountry(true)
}
            })
        })

        fetch('http://ip-api.com/json')
        .then(response => console.log(response)
            // response.json()
            )
        axios.get("https://api.ipify.org?format=jsonp&callback=getIP").then(response => {
            
            let data = response.data || {};
            console.log(data)
    })  
            // self.setState({
            //     ip: data.query || "",
            //     country: data.country || "",
            //     loading: false,
            // });  
    }, [    ])
    useEffect(() => {
        axios({
            method: 'post',
            url: `https://fanclear.sb.getid.dev/sdk/v2/token`,
            responseType: 'json',
            "headers": {
                "X-SDK-Key": "0b7860575e8da960eeebb0027e212deac5affdf1837fd54e736b4516c754934c",
                "Content-Type": "application/json"
            },
        }).then((response) => {
            if (response.data.token) {
                const widget = window.getidWebSdk.init({
                    apiUrl: 'https://fanclear.sb.getid.dev',
                    jwt: response.data.token,
                    containerId: "targetContainer",
                    flowName: 'getid-doc-selfie-liveness',
                    onComplete: (data) => { console.debug("everything is complete" + data) },
                    onFail: ({ code, message }) => { console.log("something went wrong: " + message) },
                });
            }
        });
    }, [])



    
    return (
        <div className='settings__wrapper'>
            <div className='compliance__wrapper'>
               {allowCountry && <SettingsPageHeader />}
                {user.attributes.accountVerifiedStatus === 'Not Completed' && <>

                    <div style={{
                        position: 'relative',
                        maxWidth: '540px',
                        minWidth: '320px',
                        margin: '0 auto'
                    }}>
                        <div className='getId-top_hdlogo' />
                        <div className='getId-top_line' />
                        <div className='getId-right_line' />
                        <div className='getId-left_line' />
                        <div className='getId-bottom_line' />
                        <div id="targetContainer" className="wdj-class" />
                    </div>
                </>
                }
                {user.attributes.accountVerifiedStatus === 'Checked' &&
                    <div className='compliance__content__wrapper'>
                        <p>Your account is in verification status.</p>
                    </div>}
                {user.attributes.accountVerifiedStatus === 'Complete' &&
                    <div className='compliance__content__wrapper'>
                        <p>Your account has been successfully verified.</p>
                    </div>}
            </div>
        </div>
    );
};

export default Compliance;
