import React, {useState} from 'react';
// import useIpfs from "../../../hooks/useIpfs";
import uploadIcon from "../../../assets/images/cloud-file-upload.svg";
import "./DownloadToIpfsOrAws.css";
import toBase64 from "../../../utils/toBase64";
import {sendParseRequest} from "../../../utils/sendParseRequest";

const DownloadToIpfsOrAws = ({downloadType, formats, sizeInMegabyte, saveResult}) => {
    const [sizeWarning, setSizeWarning] = useState(false);
    const [fileWarning, setFileWarning] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploaded, setUploaded] = useState(false);

    // const {ipfs} = useIpfs({commands: ['id']});
    const {ipfs} = "useIpfs({commands: ['id']}";

    const chooseDownload = (file) => {
        const validate = validateFile(file);

        if (validate && downloadType === 'ipfs') {
            return ipfsDownload(file)
        } else if (validate && downloadType === 'aws') {
            return awsDownload(file)
        }
    }

    const validateFile = file => {
        if (!file) return false;

        setLoading(true)
        const type = file.type.split('/');

        if (file.size > sizeInMegabyte * 8388608 || file.size > 2147483648) {
            setLoading(false)
            setFileWarning(false);
            return setSizeWarning(true);
        } else if (!formats.includes(type[1])) {
            setLoading(false)
            setSizeWarning(false);
            return setFileWarning(true);
        } else {
            setSizeWarning(false);
            setFileWarning(false);
            return 'success'
        }
    }

    const ipfsDownload = async (file) => {
        for await (let inputFile of ipfs.add({path: 'randompath.txt', content: file})) {
            setUploaded(true)
            setLoading(false)
            return saveResult(inputFile.cid.toString())
        }
    };

    const awsDownload = async (file) => {
        if (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg") {
            const fileBase64 = await toBase64(file);

            const imgUrl = await sendParseRequest('uploadProjectImage', {
                base64: fileBase64,
                type: file.type,
                page: 'legalEntity'
            })

            if (typeof imgUrl === "string" && imgUrl.length > 0) {
                setUploaded(true)
                setLoading(false)
                saveResult(imgUrl)
            }
        }
    }

    return (
        <div className="downloadToIpfsOrAws__wrapper">
            {loading &&
            <p className='downloadToIpfsOrAws__warning-message'>
                Download may take some time
            </p>}
            <div className='downloadToIpfsOrAws__content'>
                <label>
                    {!uploaded &&
                    <input type='file'
                           hidden={true}
                           onChange={e => chooseDownload(e.target.files[0])}
                    />}
                    {loading
                        ?
                        <a className="loadingButton loading-creators__wrapper downloadToIpfsOrAws__content__spinner-wrapper">
                            <span className='spinner loading-creators__spinner'/>
                        </a>
                        : !uploaded && <img src={uploadIcon} alt="icon"/>
                    }
                    {uploaded && <p>Upload successfully completed</p>}
                </label>
            </div>
            {fileWarning &&
            <p className='downloadToIpfsOrAws__warning-message'>
                Invalid File Format. Must be {formats.map(format => '/' + format + ' ')}
            </p>
            }
            {sizeWarning &&
            <p className='downloadToIpfsOrAws__warning-message'>
                Maximum file size - {sizeInMegabyte}mb
            </p>}
        </div>
    );
};

export default DownloadToIpfsOrAws;
