import React, {Fragment} from 'react';
import '../../TwoStep.css'
import Access from "../../../../../assets/images/access.png";
import FingerPrint from "../../../../../assets/images/fingerprint.png";
import SmsFaq from "../../../../../assets/images/sms-faq.png";
import UserFaq from "../../../../../assets/images/user-faq.png";


export class FAQ extends React.Component {
    render() {
        return (
            <Fragment>
                <div className="two_step_faq__container">
                    <section className="two_step_faq__block">
                        <div className="twostep_faq-top">
                            <h3 className="two-step_faq-title"> Changing your 2-Step Authenticator</h3>
                            <p>To update your authentication device, identify the method you currently use with your
                                Fanbase account:</p>
                            <ol>
                                <li> Authenticator (Google Authenticator App, etc.)</li>
                                <li> Authy</li>
                                <li> SMS/Text</li>
                            </ol>
                        </div>
                        {/*<li> Authy app</li>
                            <li> Voice call</li>
                            <li> Security Key</li>*/}


                        {/*<h5 className="two-step_faq-title">Security Keys</h5>
                        <div className="two_step_faq__table">
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title"><b>Issue</b></div>
                                <div className="two_step_faq__cell"><b>Try these Actions</b></div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">My Security Key isn’t working</div>
                                <div className="two_step_faq__cell">
                                    <ul>
                                        <li>Remove the key from the port and insert again. A light should appear on the
                                            key.
                                        </li>
                                        <li>Close and reopen your browser and then try again</li>
                                        <li>Clear your cache and cookies from your browser and try again</li>
                                        <li>Check to make sure your key supports U2F/WebAuthN standard. You can confirm
                                            with your key manufacturer.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">I want to update my security key to a new
                                    key
                                </div>
                                <div className="two_step_faq__cell">
                                    <ol>
                                        <li>Sign in to your account normally with your username and password and 2FA
                                            code from your old number
                                        </li>
                                        <li>Go to your Security Settings page:
                                            https://fanthebase.org/settings/security
                                        </li>
                                        <li>Click Manage, next to the security key name</li>
                                        <li>Under your security key management window, click remove for the key you
                                            would like to detach from your account.
                                        </li>
                                        <li>Select the 2-step authentication method to replace the security key</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">I lost or broke my security key</div>
                                <div className="two_step_faq__cell">
                                    <ol>
                                        <li>You will need to first complete account recovery</li>
                                        <li>Once account recovery is complete, your previous security key automatically
                                            removed and your account will default to SMS as its primary security method
                                        </li>
                                        <li>You’ll then need to acquire another security key to add again</li>
                                    </ol>
                                </div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">
                                    How do I remove a security key?
                                </div>
                                <div className="two_step_faq__cell">
                                    <ol>
                                        <li> Go to Settings > Security in your Fanbase Account
                                            Select Manage > Remove
                                        </li>
                                        <li> Note, all security keys linked to your account will be removed</li>
                                        <li> Setup another 2FA method</li>
                                    </ol>
                                </div>
                            </div>
                        </div>*/}
                        <img src={Access} alt="access" className="access_image"/>
                        <h5 className="two-step_faq-title">Problems with Authenticator</h5>
                        <div className="two_step_faq__table">
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title"><b>Description of Problem</b></div>
                                <div className="two_step_faq__cell "><b>Steps to Solve Problem</b></div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">I have my old device but must transfer my
                                    authenticator codes to a new device
                                </div>
                                <div className="two_step_faq__cell faq-table-right_part">
                                    <ol>

                                        <li>First, log in to Fanbase using your old device

                                        </li>
                                        <li> Next, in your profile, under the security settings tab, you must invalidate
                                            your old device by regenerating your secret key.
                                            If you can't find the security tab, follow this link:
                                            https://fanthebase.com/settings/security
                                        </li>

                                        <li> Last, use your new Authenticator device to scan the secret key</li>

                                    </ol>
                                </div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">My authentication device and/or phone number
                                    are missing
                                </div>
                                <div className="two_step_faq__cell faq-table-right_part">
                                    <ol>
                                        <li> Email our support team at info@fanthebase.com, from the email address you
                                            have on file with Fanbase
                                        </li>

                                        <li> Please type "Login Issue" as the subject and describe your problem in the
                                            email
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">My authenticator codes do not verify</div>
                                <div className="two_step_faq__cell faq-table-right_part">
                                    <ol>
                                        <li> Be sure to hit send after entering the codes</li>
                                        <li> The timezone or clock on your authentication device could be incorrect</li>

                                        <li> Correct your timezone or clock to synchronize your authenticator with
                                            Fanbase
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>

                        <img src={FingerPrint} alt="fingerpint" className="access_image"/>
                        <h5 className="two-step_faq-title">Problems with Authy</h5>
                        <div className="two_step_faq__table">

                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title"><b>Description of Problem</b></div>
                                <div className="two_step_faq__cell"><b>Steps to Solve Problem</b></div>
                            </div>

                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">
                                    I have a new device with the same phone number
                                </div>
                                <div className="two_step_faq__cell faq-table-right_part">
                                    <ol>
                                        <li> On the new device, download and install the Authy app</li>
                                        <li> Register your phone number and information in the Authy app</li>
                                        <li> When you have completed the registration steps, you will see Fanbase in the
                                            Authy app
                                        </li>

                                    </ol>
                                </div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">
                                    I have a new phone number or my Authy is missing

                                </div>
                                <div className="two_step_faq__cell faq-table-right_part">
                                    <ol>
                                        <li>On the website, enter your username and password at the Fanbase login
                                            window, then follow the "Problems
                                            submitting a one time code" link
                                        </li>


                                        <li>In order to complete this process you will need a scannable state-issued ID,
                                            from a state in which Fanbase is licensed to conduct business. The ID must
                                            be valid; it cannot be expired.
                                            Once completed, your 2-step verification codes will be sent by SMS/text to
                                            the phone number you have on file with Fanbase
                                        </li>


                                        <li> After providing your email address and password, if you do not recieve an
                                            SMS/text, you must clear your cache or switch browsers and resubmit the
                                            email address and password

                                        </li>
                                        <li> If that does not work, or the process is confusing, email us at
                                            info@fanthebase.com with the subject "I have a new phone number" or "My
                                            authy is missing"

                                        </li>
                                    </ol>
                                </div>
                            </div>
                            {/*</div>*/}
                        </div>
                        <img src={SmsFaq} alt="fingerpint" className="access_image"/>
                        <h5 className="two-step_faq-title">Problems with SMS & Text Verification</h5>
                        <div className="two_step_faq__table">
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title"><b>Description of Problem</b></div>
                                <div className="two_step_faq__cell"><b>Steps to Solve Problem</b></div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">I’m not receiving SMS codes.</div>
                                <div className="two_step_faq__cell faq-table-right_part">
                                    <ol>
                                        <li> Users who have selected to use an app such as Authenticator or Authy as
                                            their 2-step verifier, should not expect
                                            SMS or text messages, because they have opted to use an alternative 2-step
                                            process. To log in, the user must access the app.
                                        </li>
                                        <li> Codes may be delinquent to some customers when SMS networks are down or
                                            slow.
                                            It is highly recommended that users switch to an Authenticator
                                            app as the 2-step process, in the verification section of their Fanbase
                                            account. Authenticator apps even work offline, and are therefore more
                                            reliable in most circumstances.
                                        </li>
                                        <li> Codes may be blocked because your messages are not reaching your phone.
                                            This can happen if there are too many messages on the phone. Delete messages
                                            from
                                            and then re-request your code.
                                        </li>
                                        <li> Fanbase ceases delivering login codes when a security alert is triggered.
                                            If you have made an attempt to insert your codes and been blocked, we will
                                            resume sending those codes to your phone within 24 hours.
                                            If 24 hours has lapsed since you've been blocked, it is likely an issue with
                                            your phone carrier; they may be blocking texts. If not, please email
                                            info@fanthebase.com with a subject that says "I'm not receiving SMS codes".
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">I have a new phone number but still have my
                                    old number.

                                </div>
                                <div className="two_step_faq__cell faq-table-right_part">
                                    <ol>
                                        <li> Enter your username, password and 2-step
                                            code from the older phone.
                                        </li>
                                        <li> Enter the Account Settings section of your profile at
                                            http://fanthebase.com/#/settings/security.
                                        </li>
                                        <li> Now, you must opt to change the primary phone number.</li>
                                        <li> Replace the old primary number with your new number.</li>
                                        <li> If you like, you may now delete your old number in the security settings.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="two_step_faq__row">
                                <div className="two_step_faq__cell-title">I have a new phone number and no longer have
                                    my
                                    old number.
                                </div>
                                <div className="two_step_faq__cell faq-table-right_part">
                                    <ol>
                                        <li> Enter your username and password.</li>
                                        <li> Choose the "My auhenticator codes are not working" option.
                                            <br/>

                                            Try clearing your cache or trying a different browser if you don't see this
                                            window, then
                                            try again.
                                        </li>

                                        <li> Enter the old phone number on the account and then enter
                                            the new phone number. Await the instuctions which require:<br/>

                                            1. Your state issued ID; the data on both sides are necessary including the
                                            scanned information. <br/>

                                            2. A picture of yourself; follow the instructions.<br/></li>

                                        <li> There is a three day waiting period to allow the update to the profile and
                                            to be verified.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="twostep_faq-top">
                            <img src={UserFaq} alt="user-faq" className="access_image"/>
                            <h5 className="two-step_faq-title">Best Practices to Recover your Account</h5>
                            <p>Follow this process for uploading and verifying your state issued identification:</p>
                            <ul>
                                <li> Pictures of the state issued, scannable identification can be taken with a phone
                                    and dropped on the site, however the webcam is the best approach.
                                </li>
                                <li> Choose a setting with natural light, or bright light, for the best outcome.</li>
                                <li> You must move the camera, not the ID, for the best outcome.</li>
                                <li> You must avoid glare.
                                </li>
                                <li> Do not hold the ID with your fingers; a plain background is best.
                                </li>
                                <li> Use Chrome Browser.</li>
                                <li> If at first you don't succeed, empty your cache, and try again.</li>
                                <li> You may need to wait an hour between attempts.</li>
                            </ul>

                            <p>Best practices for your headshot:</p>
                            <ul>
                                <li> Face a light source; do not keep a light source behind you.
                                </li>
                                <li> Do not take a picture from the side; you should directly look into the camera.
                                </li>
                                <li> A plain background or wall behind your head is ideal.</li>
                                <li> Remember to smile if you have smiled in other photos.</li>
                                <li> Do not wear any concealing facial wear light baseball caps or glasses.</li>
                                <li> However, if it is not working, replicate the other photos; for example if in one
                                    you wore glasses by mistake, be sure to wear glasses in the remaining photos.
                                </li>
                            </ul>
                        </div>


                    </section>
                </div>
            </Fragment>
        )
    }
}
