const {IsObjectExists} = require("./helpers");
const {ValidationArray} = require("./helpers");
const constants = require("../constant")
const axios = require('axios');
const {stringToPath, pathToString} = require("@cosmjs/crypto");
const {DirectSecp256k1HdWallet, coins, coin} = require("@cosmjs/proto-signing");
const {Coin} = require("@cosmjs/proto-signing/build/codec/cosmos/base/v1beta1/coin");
const {
    assertIsBroadcastTxSuccess,
    SigningStargateClient,
    StargateClient,
    GasPrice,
    parseCoins,
} = require("@cosmjs/stargate");
const {SigningCosmWasmClient, Contract,} = require("@cosmjs/cosmwasm-stargate");
const configs = require("./configs");


const {uniq} = require("lodash");

export const tendermintWalletFactory = (rpcEndpoint) => {
    const prefix = configs.tendermintAddressPrefix;

    async function deserializedJsonStr(jsonStr, password) {
        let returnObj = {
            addresses: [],
            walletObject: undefined,
        };

        {
            {
                // TODO: "fix string with JSON stringify"
            }
        }
        try {
            const deserializedWallet = await DirectSecp256k1HdWallet.deserialize(jsonStr, password);
            // const deserializedWallet = await DirectSecp256k1HdWallet.deserialize(JSON.stringify(jsonStr), password);
            console.log('deserializedWallet', deserializedWallet);
            if (deserializedWallet) {
                // const [{ address }] = await deserializedWallet.getAccounts();
                const accounts = await deserializedWallet.getAccounts();
                console.log(accounts, '---accounts')
                if (IsObjectExists(accounts)) returnObj.addresses = accounts.map((el) => el.address);
                returnObj.walletObject = deserializedWallet;
                console.log(returnObj, '--1returnObj')
                return returnObj;
            } else return ({error: "something wrong"})
        } catch (error) {
            return ({error: "incorrect password"})
        }
    }


    return {
        /**
         * Return info-object of created user wallet
         * @typedef {Object} WalletInfo
         * @property {string[]} addresses All addresses(bills) from new user wallet
         * @property {string} serJsonString Serialized Json-String
         */
        /**
         * @typedef {Object} DecryptWallet
         * @property {string[]} addresses All addresses(bills) from exists user wallet
         * @property {DirectSecp256k1HdWallet} walletObject Instance of DirectSecp256k1HdWallet
         */
        /**
         * Return structure of tendermint transaction
         * @typedef {Object} TransactionResult
         * @property {string} from Address from
         * @property {string} to Receiver address
         * @property {number} gasUsed blockchain sysinfo(never mind)
         * @property {number} blockNumber Number of current block
         * @property {string} transactionHash Transaction hash
         */

        /**
         * @Description Create user wallet
         * @param {string} mnemonic User mnemonic
         * @param {string} passwordForSerialize Keyword for encrypting serialization of wallet
         * @param {number} billCounter How much bills on current wallet need to create
         * @returns {Promise<WalletInfo>}
         */
        createWallet: async (mnemonic, passwordForSerialize, billCounter = 1) => {
            console.log(mnemonic, passwordForSerialize, billCounter)
            if (!IsObjectExists(mnemonic)) {
                throw new Error(`User mnemonic is invalid!`);
            }
            if (!IsObjectExists(passwordForSerialize)) {
                throw new Error(`Password is invalid!`);
            }
            if (!IsObjectExists(billCounter)) {
                throw new Error(`Bill counter is invalid!`);
            }

            let returnObj = {
                addresses: [],
                serJsonString: "",
            };
            try {
                let hdPaths = [];
                for (let i = 0; i < billCounter; i++) {
                    hdPaths.push(stringToPath(`m/44'/118'/0'/0/${i}`));
                }
                console.log(hdPaths, '--hdPaths---')
                //const hdPath = stringToPath(`m/44'/118'/0'/0/${index}`);

                const wallet = await DirectSecp256k1HdWallet.fromMnemonic(mnemonic, {hdPaths: hdPaths, prefix: prefix}); //hdPaths: [hdPath]
                console.log(wallet, '-----waallet')
                // const [{ address }] = await wallet.getAccounts();
                const accounts = await wallet.getAccounts();
                console.log(accounts, '----accounts')

                if (IsObjectExists(accounts)) returnObj.addresses = accounts.map((el) => el.address);
                console.log('returnObj', returnObj);

                returnObj.serJsonString = await wallet.serialize(passwordForSerialize);
                return returnObj;
            } catch (error) {
                throw new Error(`Error wallet creation. ${error.message}`);
            } finally {
                return returnObj;
            }
        },


        /**
         * @Description Decrypt serialize json-string for get wallet
         * @param {string} jsonStr Input serialized json-String
         * @param {string} password Keyword for encrypting serialization of wallet
         * @returns {DecryptWallet}
         */
        decryptSerializedJsonStr: async (jsonStr, password) => {
            if (!IsObjectExists(jsonStr)) {
                throw new Error(`json string is invalid!`);
            }
            if (!IsObjectExists(password)) {
                throw new Error(`Password is invalid!`);
            }

            return await deserializedJsonStr(jsonStr, password);
        },


        /**
         * @Description get USDW coins from fanbase (tendermint) wallet
         * @param {string} wallet - This wallet where store USDW (tendermint)
         * @returns {USDWBalance}
         */
        getUSDWBalance: async (wallet) => {
            if (wallet) {
                const result = await axios({
                    method: 'get',
                    url: `${constants.default.apiRoot}/contracts/usdw/balanceOf?address=${wallet}`,
                    responseType: 'json'
                });
                if (result) {
                    return ({balance: result.data.balance/100})
                }
            }
        },


        /**
         * @Description Import exists user wallet
         * @param {string} serString Serialized json-String
         * @param {string} serPassword Keyword for encrypting serialization of wallet
         * @returns {WalletInfo}
         */
        importWallet: async (serString, serPassword) => {


            if (!IsObjectExists(serString)) {
                throw new Error(`json string is invalid!`);
            }
            if (!IsObjectExists(serPassword)) {
                // throw new Error(`Password is invalid!`);  `z`
            }
            console.log('>>>IN FUNCTION serString, serPassword', serString, serPassword);
            let a = JSON.parse(serString)
            const getdecrypt = await deserializedJsonStr(a, serPassword);
            console.log('getdecrypt', getdecrypt);

            const returnObj = {
                addresses: getdecrypt.addresses,
                serJsonString: serString,
            };

            return returnObj;
        },

        /**
         * @Description Get balance of the currency in user wallet
         * @param {DecryptWallet} decryptWalletObject Decrypted object with wallet instance
         * @param {string} address You selected account address
         * @param {string} currencyName Type of currency(token)
         * @returns {Promise<string>}
         */
        getWalletBalance: async (decryptWalletObject, address, currencyName) => {
            if (!IsObjectExists(decryptWalletObject)) {
                throw new Error(`Wallet object is invalid!`);
            }
            if (!IsObjectExists(address)) {
                throw new Error(`Currency is invalid!`);
            }
            if (!IsObjectExists(currencyName)) {
                throw new Error(`Currency is invalid!`);
            }

            const client = await SigningStargateClient.connectWithSigner(rpcEndpoint, decryptWalletObject.walletObject, {
                broadcastTimeoutMs: 175000,
                gasPrice: GasPrice.fromString("0wally"),
                prefix: prefix,
            });


            const balance = await client.getBalance(address, currencyName);

            client.disconnect();
            return IsObjectExists(balance) ? balance.amount : "0";
        },

        /** FUNCTION FOR SENT WALLY (NOT USDW)
         * @Description Send amount of selected token from current account to recipient
         * @param {DecryptWallet} decryptWalletObject Decrypted object with wallet instance
         * @param {string} addressFrom You selected account address
         * @param {string} recipientAddress Address recipient of token
         * @param {number} amount Amount of token to be sent
         * @param {string} currencyName Type of currency(token)
         * @param {sting} memoMsg Custom message that sends with transaction
         * @returns {Promise<TransactionResult>} Transaction object with fields (from, to, gasUsed, blockNumber, transactionHash)
         */
        sentNativeToken: async (decryptWalletObject, addressFrom, recipientAddress, amount, currencyName, memoMsg) => {
            if (!IsObjectExists(decryptWalletObject)) {
                throw new Error(`Wallet object is invalid!`);
            }
            if (!IsObjectExists(recipientAddress)) {
                throw new Error(`Recipient address is invalid!`);
            }
            if (!IsObjectExists(currencyName)) {
                throw new Error(`Currency is invalid!`);
            }

            const client = await SigningStargateClient.connectWithSigner(rpcEndpoint, decryptWalletObject.walletObject, {
                broadcastTimeoutMs: 175000,
                gasPrice: GasPrice.fromString(`0${prefix}`),
                prefix: prefix,
            });

            try {
                const txResult = await client.sendTokens(addressFrom, recipientAddress, [coin(amount, currencyName)], memoMsg);
                assertIsBroadcastTxSuccess(txResult);

                if (IsObjectExists(txResult)) {
                    return {
                        from: addressFrom,
                        to: recipientAddress,
                        gasUsed: txResult.gasUsed,
                        blockNumber: txResult.height,
                        transactionHash: txResult.transactionHash,
                    };
                } else return null;
            } catch (error) {
                console.error(`${error.message}`);
            } finally {
                client.disconnect();
            }
        },


        sentUSDW: async (decryptWalletObject, addressTo, addressFrom, amount
        ) => {
            const client = await SigningCosmWasmClient.connectWithSigner(
                'http://3.236.143.194:26657',
                decryptWalletObject.walletObject,
                {
                    gasLimits: {exec: 200000000},
                    broadcastTimeoutMs: 175000,
                    gasPrice: GasPrice.fromString("0wally"),
                    prefix: '0wally',
                }
            );

            const res = await client.execute(
                addressFrom,
                'wally10pyejy66429refv3g35g2t7am0was7yar2ln3p',
                {
                    transfer: {recipient: addressTo, amount: Number(amount).toString()},
                }
            );

            console.log(res, '-------res')
            return IsObjectExists(res) ? res.transactionHash : null;
        },


        burnUSDW: async (decryptWalletObject, addressFrom, amount
        ) => {
            console.log(decryptWalletObject.walletObject, '    decryptWalletObject.walletObject    decryptWalletObject.walletObject')

            const client = await SigningCosmWasmClient.connectWithSigner(
                'http://3.236.143.194:26657',
                decryptWalletObject.walletObject,
                {
                    gasLimits: {exec: 200000000},
                    broadcastTimeoutMs: 175000,
                    gasPrice: GasPrice.fromString("0wally"),
                    prefix: '0wally',
                }
            );

            const res = await client.execute(
                addressFrom,
                'wally10pyejy66429refv3g35g2t7am0was7yar2ln3p',
                {
                    burn: {recipient: addressFrom, amount: Number(amount).toString()},
                }
            );

            console.log(res, '-------res')
            return IsObjectExists(res) ? res.transactionHash : null;


        },


        /**
         * @Description Send amount of selected token from current account to recipient
         * @param {DecryptWallet} decryptWalletObject Decrypted object with wallet instance
         * @param {string} addressFrom You selected account address
         * @param {string} recipientAddress Address recipient of token
         * @param {number[]} amountArray Array of tokens amount to be sent
         * @param {string[]} currenciesArray Array of type of currency(token)
         * @param {sting} memoMsg Custom message that sends with transaction
         * @returns {Promise<TransactionResult>} Transaction object with fields (from, to, gasUsed, blockNumber, transactionHash)
         */
        sentNativeTokenArray: async (
            decryptWalletObject,
            addressFrom,
            recipientAddress,
            amountArray,
            currenciesArray,
            memoMsg
        ) => {
            if (!IsObjectExists(decryptWalletObject)) {
                throw new Error(`Wallet object is invalid!`);
            }
            if (!IsObjectExists(recipientAddress)) {
                throw new Error(`Recipient address is invalid!`);
            }
            if (!ValidationArray(currenciesArray)) {
                throw new Error(`Array of currency is invalid!`);
            }
            if (!ValidationArray(amountArray)) {
                throw new Error(`Array of sending amount is invalid!`);
            }
            if (amountArray.length === 0 || currenciesArray.length === 0) {
                throw new Error(`Arrays (amounts and currencies) is empty!!`);
            }
            currenciesArray = uniq(currenciesArray);

            if (amountArray.length !== currenciesArray.length) {
                throw new Error(`Arrays (amounts and currencies) length not equals!`);
            }

            const client = await SigningStargateClient.connectWithSigner(rpcEndpoint, decryptWalletObject.walletObject, {
                broadcastTimeoutMs: 175000,
                gasPrice: GasPrice.fromString(`0${prefix}`),
                prefix: prefix,
            });

            //Move 'wally' to end of Array
            if (currenciesArray.indexOf(prefix) > -1) {
                if (currenciesArray.indexOf(prefix) == 0) {
                    amountArray.push(amountArray.shift());
                    currenciesArray.push(currenciesArray.shift());
                } else {
                    amountArray.push(amountArray.splice(currenciesArray.indexOf(prefix), 1)[0]);
                    currenciesArray.push(currenciesArray.splice(currenciesArray.indexOf(prefix), 1)[0]);
                }
            }

            let coinsToSend = "";
            for (let i = 0; i < amountArray.length; i++) {
                coinsToSend += `,${amountArray[i]}${currenciesArray[i]}`;
            }
            coinsToSend = coinsToSend.slice(1);

            try {
                const txResult = await client.sendTokens(addressFrom, recipientAddress, parseCoins(coinsToSend), memoMsg);
                assertIsBroadcastTxSuccess(txResult);

                if (IsObjectExists(txResult)) {
                    return {
                        from: addressFrom,
                        to: recipientAddress,
                        gasUsed: txResult.gasUsed,
                        blockNumber: txResult.height,
                        transactionHash: txResult.transactionHash,
                    };
                } else return null;
            } catch (error) {
                console.error(`${error.message}`);
            } finally {
                client.disconnect();
            }
        },
    };
};
