import React from "react"
import Parse from 'parse';
import {formatDate} from "../../../utils/formatDate";
import {sendParseRequest} from "../../../utils/sendParseRequest";
import './support.css';

export class Support extends React.Component {
    state = {
        message: "",
        category: "default",
        complete: false,
        caseNumber: "",
        currentDate: "",
        username: ""
    }

    componentDidMount() {
        document.title = "Support - FNBD";

        this.setState({username: Parse.User.current().id, currentDate: formatDate(new Date().toString())});

        const key = this.props.match.params.key;

        if (key) {
            if (key === "rsl") {
                this.setState({
                    category: "Login | My Account", "message": "I want to raise my sending limits to"
                });
            } else if (key === 'rwl') {
                this.setState({
                    category: "Login | My Account", "message": "I want to raise my withdrawal limits to"
                });
            } else {
                this.setState({
                    category: "Report | Compliant",
                    message: "I am reporting Post ID:" + key + " because"
                });
            }
        }
    }

    handleSubmit = () => {
        sendParseRequest('getUserInfoById', {id: Parse.User.current().id})
            .then(res => {
                sendParseRequest('submitSupportTicket', {
                    userEmail: res.get('email'),
                    name: res.get('fname'),
                    message: this.state.message,
                    category: this.state.category,
                    userId: res.id
                }).then(res => {
                    console.log(res)
                    this.setState({complete: true, caseNumber: res});
                });
            })
    }

    render() {
        return (
            <div className="page indexPageWrap" style={{minHeight: "calc(100vh - 219px)"}}>
                <div className="dashWrapperB" style={{margin: "0 auto"}}>
                    <div className="dashModal" style={{padding: '0'}}>
                        {!this.state.complete
                            ? <div className="content supportContainer" style={{"height": "auto"}}>
                                <p className="supportTitle">Support a Ticket</p>
                                <p className="supportDescription">
                                    Submit your questions below. We will respond within 24 hours.
                                </p>
                                <br/><br/>
                                <span className="pull-left usernameField">
                                    <b>User ID:</b> {this.state.username}
                                </span>
                                <span className="pull-right usernameField">
                                    {this.state.currentDate}
                                </span>
                                <select value={this.state.category}
                                        onChange={(e) => this.setState({category: e.target.value})}
                                        className="supportSelect withArrow">
                                    <option value="default">Choose a Category</option>
                                    <option>Login | My Account</option>
                                    <option>Feature Suggestion</option>
                                    <option>Accreditation | Verification</option>
                                    <option>Financial Transaction</option>
                                    <option>Technical Support</option>
                                    <option>Report | Compliant</option>
                                </select>
                                <textarea value={this.state.message}
                                          onChange={(e) => this.setState({message: e.target.value})} rows="10"
                                          placeholder="How can we help?"
                                />
                                {this.state.message.trim() === "" || this.state.category === "default"
                                    ? <a className="viewallA disabled">Choose category default</a>
                                    : <a onClick={this.handleSubmit} className="viewallA">Submit Request</a>
                                }
                            </div>
                            : <div className="content supportContainer successSupportContainer"
                                   style={{"height": "auto"}}>
                                <p className="supportImage"><i className="fa fa-check"/></p>
                                <p className="supportTitle">Case #{this.state.caseNumber}</p>
                                <p className="supportDescription">We received your request. Please allow 24
                                    hours for us to respond back. We also sent you an email for your reference.
                                </p>
                                <button className='blue-button'
                                        onClick={() => this.setState({complete: false})}
                                        style={{margin: "20px auto"}}
                                >
                                    New Ticket
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
