import React, {useState, useEffect} from 'react';
import uploadIcon from '../../../assets/images/cloud-file-upload.svg';

const UploadItemsToIpfs = ({licenseType, node, setItemsToIpfs, itemsName, fileWarning, onLoadFile, removeItem, blockName}) => {


  console.log('zzz', itemsName, '--itemsName.', node, '=====node');
  // console.log(licenseType, node, setItemsToIpfs, itemsName, fileWarning, onLoadFile, removeItem, blockName)
  // console.log(node, "--------------node222zzz2222")
  const [sizeWarning, setSizeWarning] = useState(false);
  const [pdfIssue, setPdfIssue] = useState(false);

  // console.log(onLoadFile, "--------------onLoadFile")

  const addToIpfs = async (file) => {
    console.log(333333333, "--------------333333333")
    console.log(1111, "--------------1111")
    console.log(file, "--------------file")
    console.log(node, "----------111яя----node")
    const {path} = await node.add(file);

    if (path) {
      console.log(path);
      // await node.stop();
      // setSNode(false)
      return path
    }
  };

  const getHash =async (inputFile)=> {
      if (inputFile && node) {
          let file = inputFile;
          let hash = await addToIpfs(file)
          console.log(hash, "--------------hash")
          setItemsToIpfs({
              file,
              addToIpfs,
              blockName,
              licenseType,
              hash
          });
      }
  };


  const onFileDownload = async (evt) => {
    setPdfIssue(false);
    if (blockName === 'additionalAttachmentsIpfs') {
      if (evt.target.files[0].type !== "application/pdf") {
        setPdfIssue(true)
      } else {
          getHash(evt.target.files[0]);
      }
    } else {
        getHash(evt.target.files[0]);
    }
  };

  return (
    <div className="section-input-wrap" style={{"paddingLeft": "30px"}}>
      {/* <input  name="file" type="file" className='ipfs-input'    ></input> */}
      <div className="s-input-title">
        {
          blockName === 'additionalAttachmentsIpfs' ? "Additional documents:"
            : licenseType === 'auction'
            ? 'Auction Item:'
            : 'Invest Item'

        }
        <span className="required-star">{blockName !== 'additionalAttachmentsIpfs' ? <>Required *</> : <>   </>}</span>
        {
          blockName === 'additionalAttachmentsIpfs' ? null
            : <i className="character-count" style={{'fontStyle': 'normal'}}>UP TO 6</i>

        }

      </div>

      {onLoadFile && <p className='download-message'>Download may take some time</p>}

      <div
        className={
          blockName === 'additionalAttachmentsIpfs' ? 'uploadItems-wrapper uploadAuctionItems'
            : licenseType === 'auction'
            ? 'uploadItems-wrapper uploadAuctionItems'
            : 'uploadItems-wrapper uploadInvestItems'}>
        {
          itemsName.length < 6
            ? <label>
              <input type={onLoadFile ? '' : 'file'}
                     hidden={true}
                     id="file"
                     onChange={(e) => onFileDownload(e)}
                     name="auctionItem"
                     data-isrequiredfield="false"
                     data-action="compare"
                     data-valueforcheck=""
              />
              {onLoadFile
                ? <a className="loadingButton loading-creators__wrapper"><span
                  className='spinner loading-creators__spinner'/></a>
                : <img src={uploadIcon} alt="icon"/>
              }
            </label>
            : <p style={{'margin': '0 auto'}}>You have uploaded the maximum number of items</p>
        }
      </div>
      {
        fileWarning &&
        <p className='warning-message'>Invalid File Format. Must be png /jpg /mp4 /wav /mp3 /pdf</p>
      } {
      pdfIssue &&
      <p className='warning-message'>Invalid File Format. Must be pdf</p>
    }
      {
        itemsName.length > 0
          ? <div className="section-input-wrap section-input-wrap__names-list">
            {itemsName.map(name => {
              return (
                <p key={name} style={{'marginRight': '10px', 'display': 'flex'}}>
                  {name}
                  <div className='delete-image-btn' onClick={() => removeItem({name, blockName})}>×</div>
                </p>
              )
            })}
          </div>
          : false
      }
      {sizeWarning && <p className='warning-message'>Maximum file size - 2gb</p>}
    </div>
  );
};

export default UploadItemsToIpfs;
