import React, {Component} from "react";
import {Link} from 'react-router-dom';
import Parse from "parse";
import {connect} from "react-redux";
import prepareImagesPathForMinify from '../../../utils/prepareImagesPathForMinify.js';
import EthApi from "../../api/tendermint";
import * as blockChainActions from "../../../modules/blockchain/blockchain.action";
import ProjectsPagination from "../../Components/ProjectsPagination";
import '../../../assets/style/Components/ImagePreview.css'
import "./Projects.css";
import {ModalWindow} from "../../Components/ModalWindow/ModalWindow";
import {mearsyRegister} from "../../../static/constants";
import {sendParseRequest} from "../../../utils/sendParseRequest";

class Projects extends Component {
  state = {
    projectIds: [],
    projectAuction: [],
    projectInvest: [],
    projectAuctionIsLoading: true,
    projectInvestIsLoading: true,
    limitItemsOnPage: 6,
    limitPaginationButtons: 6,
    nextPageNumber: 0,
    nextProjectInvestPage: [],
    nextProjectAuctionPage: [],
    showModal: false,
  };

  componentDidMount = async () => {
    if (this.props.mainWalletAddress) {
      await this.getProjectDataFromBlockchain();
    } else {
      this.setState({
        nextProjectInvestPage: false,
        nextProjectAuctionPage: false,
      });
    }

    if (this.props.location.search.startsWith('?id')) {
      this.setState({showModal: true})
    }
  };

  async componentDidUpdate(prevProps, prevState) {

    if (this.props.mainWalletAddress !== "" && this.props.mainWalletAddress !== prevProps.mainWalletAddress) {
      await this.getProjectDataFromBlockchain();
    }
  }

  async getProjectDataFromBlockchain() {
    const walletAddress = this.props.mainWalletAddress;
    const projects = await EthApi.contract.project.getProjects(walletAddress);
    console.log('projects', projects, 'PROJECTS');
    
    const projectsId = [];
    projects && projects.forEach(element => {
      // console.log(element, '---element')
      projectsId.push(element.metadata.tokenURI);
    });

    console.log(projects, "--------------projects", projectsId)
    if (projectsId.length === 0) {
      this.props.setProjects && this.props.setProjects(projects);
      this.classifyProjectToAccordingToType(projects, []);
    } else {
      sendParseRequest('getInfo', {
        query: "Projects",
        toJSON: true,
        // equal: [{field: "verification.approve", value: true}],
        containedIn: {field: "objectId", value: projectsId},
        select: ["objectId", "logoAttach", "projectTitle"],
      })
        .then(projectInfos => {
          {
      
            this.classifyProjectToAccordingToType(projects, projectInfos)
          }
        })
    }
  }

  classifyProjectToAccordingToType(projects, projectInfos) {
    
    const {
      nextPageNumber,
      limitItemsOnPage
    } = this.state;
    console.log(projectInfos, 'projectInfos',projects )
    
    let auctionProjects = [];
    let investoProjects = [];
    projects.forEach(element => {
      const projectInfo = EthApi.utils.findMatchedBlockChainInfo(projectInfos, 'objectId', element.metadata.tokenURI);
      if (!projectInfo) return;
      element.projectInfo = projectInfo;
      console.log(element, '----element')
      if (element.metadata.licenseType === 'unlimited') {
        investoProjects.push(element);
      } else {
        auctionProjects.push(element);
      }
      });

    this.setState({
      projectAuction: auctionProjects,
      projectInvest: investoProjects,
      projectAuctionIsLoading: false,
      projectInvestIsLoading: false,
      totalAuctionPages: Math.ceil(auctionProjects.length / this.state.limitItemsOnPage),
      totalInvestPages: Math.ceil(investoProjects.length / this.state.limitItemsOnPage),
      nextProjectInvestPage: investoProjects.length > 0 ? [...investoProjects.slice(nextPageNumber, nextPageNumber + limitItemsOnPage)] : false,
      nextProjectAuctionPage: auctionProjects.length > 0 ? [...auctionProjects.slice(nextPageNumber, nextPageNumber + limitItemsOnPage)] : false,
    });
  }


  onClickPagination = (number, type) => {
    const {projectInvest, limitItemsOnPage, projectAuction} = this.state;
    let projectList;
    let nextProjectPage;

    if (type === 'invest') {
      projectList = [...projectInvest];
      nextProjectPage = 'nextProjectInvestPage';
    } else {
      projectList = [...projectAuction];
      nextProjectPage = 'nextProjectAuctionPage';
    }

    this.setState({
      [nextProjectPage]: [
        ...projectList.slice(
          limitItemsOnPage * number, (limitItemsOnPage * number) + limitItemsOnPage
        )
      ],
    })
  };

  closeModal = (redirect) => {
    this.setState({showModal: false});
    this.props.history.push('/projects');

    if (redirect) window.open(mearsyRegister + `?fnbUser=${Parse.User.current().id}`, '_blank')
  }

  render() {
    const loader = (
      <div className='image-spinner-frame'>
        <div className="loader__blue">Loading...</div>
      </div>
    );
    return (
      <div className="page projectsPage indexPageWrap">
        <div className="creator-main-wrapper global-projects-wrapper">
          {this.state.showModal &&
          <ModalWindow hideModal={this.closeModal}
                       title='Congratulations!'
                       children={
                         <div>
                           <p>You have created a project and now you can promote it on our Mearsy
                             service.</p>
                           <p>Register
                             <a onClick={() => this.closeModal('redirect')}
                                style={{color: '#0667D0', cursor: 'pointer'}}> here </a>
                             to become an Editor.
                           </p>
                         </div>
                       }
          />
          }
          {
            typeof this.state.nextProjectAuctionPage === "boolean"
              ? <div className='section-subtitle__wrapper'>
                <p>No auctions yet</p>
                <button className='blue-button' onClick={() => this.props.history.push('/contract')}>
                  Create
                </button>
              </div>
              : <p className="section-title__projects-page">My Collectables</p>
          }
          <div className="projects-wrapper">
            {
              typeof this.state.nextProjectAuctionPage !== "boolean" && this.state.nextProjectAuctionPage.length === 0 && loader
            }
            {
              this.state.nextProjectAuctionPage.length > 0 &&
              this.state.nextProjectAuctionPage.map((pro, i) =>
                <ProjectBox key={i} data={pro} page={'collectormarket'}/>)

            }
            {this.state.projectAuction.length > 6 &&
            <ProjectsPagination onClickPagination={this.onClickPagination}
                                limit={this.state.totalAuctionPages}
                                limitForButtons={this.state.limitPaginationButtons}
                                type='auction'
            />}
          </div>
          {
            typeof this.state.nextProjectInvestPage === "boolean"
              ? <div className='section-subtitle__wrapper'>
                <p>No projects yet</p>
                <button className='blue-button' onClick={() => this.props.history.push('/contract')}>
                  Create
                </button>
              </div>
              : <p className="section-title__projects-page">My Investments</p>
          }
          <div className="projects-wrapper">
            {
              typeof this.state.nextProjectInvestPage !== "boolean" && this.state.nextProjectInvestPage.length === 0 && loader
            }
            {
              this.state.nextProjectInvestPage.length > 0 &&
              this.state.nextProjectInvestPage.map((pro, i) =>
                <ProjectBox key={i} data={pro} page={'massmarket'}/>)
            }
            {this.state.projectInvest.length > 6 &&
            <ProjectsPagination onClickPagination={this.onClickPagination}
                                limit={this.state.totalInvestPages}
                                limitForButtons={this.state.limitPaginationButtons}
                                type='invest'
            />}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({blockchain}) {
  return {
    tokens: blockchain.tokens,
    mainWalletAddress: blockchain.mainWalletAddress
  };
}

export default connect(
  mapStateToProps,
  {...blockChainActions}
)(Projects);


const ProjectBox = ({page, data}) => (
  <>{()=> {console.log(page, data, 'PAAAAAGE')}}
  <Link to={`/${page}/${data.metadata.tokenURI}`}>
    <div className="project-box">
      <div className="box-body">
        <img src={prepareImagesPathForMinify(data.projectInfo && data.projectInfo.logoAttach[0], 300, 300)}
             alt="Logo"/>
      </div>
      <div className="box-footer projectBoxFooter">
        <p className="foot-title">{data.projectInfo.projectTitle}</p>
        {
          data.projectInfo.isAuctionGoing &&
          <p className="foot-project-type foot-project-type-auction">Auction</p>
        }
        <p className="foot-project-type">{data.licenseType === '0' ? 'Mass Market' : 'Rare market'}</p>
      </div>
    </div>
  </Link>
  </>


);
