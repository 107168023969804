//OLD GOMEZ code for USDC=>old eth WALLY, eth-currencies balances like LOOM

import Web3 from "web3";
import constants from "./constants.js";
import axios from "axios";


const provider_url = constants.ROPSTEN_TEST_NETWORK

class EthClient {
    constructor(){

        this.web3 = new Web3(new Web3.providers.HttpProvider(provider_url))
        window.web3 = this.web3
    }

    //=================== WAFFLEMAKR ================================


    tokenNameToAddress(tokenName){
        switch (tokenName) {
            case "USDC":
                return constants.USDC_CONTRACT_ADDRESS
            case "LOOM":
                return constants.LOOM_CONTRACT_ADDRESS
            case "BAT":
                return constants.BAT_CONTRACT_ADDRESS
            case "ZRX":
                return constants.ZRX_CONTRACT_ADDRESS
            case "LINK":
                return constants.LINK_CONTRACT_ADDRESS
            case "REP":
                return constants.REP_CONTRACT_ADDRESS
            default:
                return null
        }
    }

    //get token contract, symbol, and decimals
    async getTokenDetails(tokenAddress){
        const contract = new this.web3.eth.Contract(constants.ERC20_ABI, tokenAddress);
        let symbol = "";
        let decimals ="";
        try{
            symbol = await contract.methods.symbol().call();
            decimals = await contract.methods.decimals().call();
            return {contract, symbol, decimals};
        }catch(e){
            console.log(e);
        }
        
    }

    // get Wallet's balances of each token passed in array of symbols (i.e. ["ETH", "USDC", "LOOM])
    async getBalances(walletAddress, tokensArray){
        if (!walletAddress.startsWith('wally')) {
            const balanceETH = await new this.web3.eth.getBalance(walletAddress);

            let result = {ETH: +this.web3.utils.fromWei(balanceETH)};

            for (let i = 0; i < tokensArray.length; i++) {
                const tokenAddress = this.tokenNameToAddress(tokensArray[i]);
                // ERC-20 Token
                if (tokenAddress) {
                    const token = await this.getTokenDetails(tokenAddress);
                    const balance = await token.contract.methods.balanceOf(walletAddress).call();
                    result[token.symbol] = balance / (10 ** token.decimals)
                }
            }

            return result;
        }
    }  
    
    async processWithdrawal(from, to, amount, password, tokenName){
        
        // Load Wallet from user
        const wallet = await this.web3.eth.accounts.wallet.load(password);

        let result;
        if(tokenName==="ETH") result = await this.sendETH(from, to, amount, wallet)
        else result = await this.sendToken(from, to, amount, tokenName, wallet);

        return result;
    }

     
    // Send ERC-20 token from user's Wallet
    async sendToken(from, to, amount, tokenName, wallet){

        try {
            // Get token address from symbol
            const tokenAddress = this.tokenNameToAddress(String(tokenName));            

            // Get token contract instance and details
            const token = new this.web3.eth.Contract(constants.ERC20_ABI, tokenAddress);
            const {decimals} = await this.getTokenDetails(tokenAddress);

            // Format amount according to decimals of token
            const amountToSend = amount *10**decimals;

            // Build transaction Object
            const nonce = await this.web3.eth.getTransactionCount(from);
            const _tx = token.methods.transfer(to, String(amountToSend));
            const gas = await _tx.estimateGas({from});
            const data = _tx.encodeABI();

            const txObj = {
                nonce,
                from,
                to:tokenAddress,
                gas,
                gasPrice:this.web3.utils.toHex(1e9),
                data
            }

            // Sign transaction with user Wallet
            const tx = await wallet[from].signTransaction(txObj)
            
            // Broadcast transaction to ethereum network
            return new Promise((resolve, reject) => {
                this.web3.eth.sendSignedTransaction(tx.rawTransaction)
                    .on("receipt", receipt => {
                        resolve(receipt);
                    })
                    .on("error", error => {
                        reject(error);
                    })
                })

        } catch (error) {
            console.log(error)
        }
    }

    // Send ether from user's Wallet
    async sendETH(from, to, amount, wallet){
        try {
            // Build Transaction Object to sign with Wallet
            const nonce = await this.web3.eth.getTransactionCount(from);
            const txObj = {
                nonce,
                from,
                to,
                gas:21000,
                gasPrice:this.web3.utils.toHex(1e9),
                value:this.web3.utils.toWei(String(amount))
            }

            // Sign transaction with user Wallet
            const tx = await wallet[from].signTransaction(txObj)

            // Broadcast transaction to ethereum network
            return new Promise((resolve, reject) => {
                this.web3.eth.sendSignedTransaction(tx.rawTransaction)
                    .on("receipt", receipt => {                        
                        resolve(receipt);
                    })
                    .on("error", error => {
                        reject(error);
                    })
            })
        } catch (error) {
            console.log(error)
        }        
    }

    // example =  await ethClient.getWithdrawalHistory(walletAddress, ["ETH","USDC"]);
    async getWithdrawalHistory(wallet, tokensArray){

        let withdrawals = [];
        for(let i=0;i<tokensArray.length; i++){

            if(tokensArray[i] !== "ETH"){
                const tokenAddress = this.tokenNameToAddress(tokensArray[i]);

                if(tokenAddress){

                    // const {data} = await axios.get(`http://api-ropsten.etherscan.io/api?module=account&action=tokentx&contractaddress=${tokenAddress}&address=${walletAddress}&startblock=100000&endblock=999999999&sort=asc&apikey=YourApiKeyToken`)
                    // console.log(data)

                    const token = await this.getTokenDetails(tokenAddress);

                    const events = await token.contract.getPastEvents("Transfer", {
                        filter:{
                            from:wallet
                        },
                        fromBlock:0
                    })                   


                    const tokenWithdrawals = [];
                    for(let i=0; i<events.length; i++){
                        const event = events[i];
                        const {timestamp} = await this.web3.eth.getBlock(event.blockNumber)
                        withdrawals.push({
                                asset:token.symbol,
                                txHash:event.transactionHash,
                                block:event.blockNumber,
                                to:event.returnValues.to,
                                value: event.returnValues.value / (10**token.decimals),
                                timestamp
                            })
                    }
                    withdrawals=[...withdrawals, ...tokenWithdrawals];
                }
            }else{
                const {data} = await axios.get(`http://api-ropsten.etherscan.io/api?module=account&action=txlist&address=${wallet}&startblock=0&endblock=99999999&sort=asc&apikey=YourApiKeyToken`)
                if(data.status !== "0"){
                    const ethWithdrawals = data.result
                            .filter(res => res.value !== "0" && this.web3.utils.toChecksumAddress(res.from) === wallet)
                            .map(res => {
                                return {
                                    asset:"ETH",
                                    txHash:res.hash,
                                    block:res.blockNumber,
                                    to:res.to,
                                    value: this.web3.utils.fromWei(res.value),
                                    timestamp:res.timeStamp
                                }
                            })
                   
                    withdrawals=[...withdrawals, ...ethWithdrawals];
                    
                }
            }           
        }

        console.log("withdrawals",withdrawals )
        return withdrawals;

    }

    // example =  await ethClient.getDepositHistory(walletAddress, ["ETH","USDC"]);
    async getDepositHistory(wallet, tokensArray){

        let deposits = [];

        for(let i=0;i<tokensArray.length; i++){

            if(tokensArray[i] !== "ETH"){
                const tokenAddress = this.tokenNameToAddress(tokensArray[i]);

                if(tokenAddress){
                    const token = await this.getTokenDetails(tokenAddress);

                    const events = await token.contract.getPastEvents("Transfer", {
                        filter:{
                            to: wallet
                        },
                        fromBlock:0
                    })

                    const tokenDeposits = [];
                    for(let i=0; i<events.length; i++){
                        const event = events[i];
                        const {timestamp} = await this.web3.eth.getBlock(event.blockNumber)
                        tokenDeposits.push({
                                asset:token.symbol,
                                txHash:event.transactionHash,
                                block:event.blockNumber,
                                to:event.returnValues.to,
                                value: event.returnValues.value / (10**token.decimals),
                                timestamp
                            })  

                    }
                    deposits=[...deposits, ...tokenDeposits];
                }
            }else{
                const {data} = await axios.get(`http://api-ropsten.etherscan.io/api?module=account&action=txlist&address=${wallet}&startblock=0&endblock=99999999&sort=asc&apikey=YourApiKeyToken`)
               
                if(data.status !== "0"){
                    const ethDeposits = data.result
                            .filter(res => res.value !== "0" && this.web3.utils.toChecksumAddress(res.to) === wallet)
                            .map(res => {
                                return {
                                    asset:"ETH",
                                    txHash:res.hash,
                                    block:res.blockNumber,
                                    from:res.from,
                                    value: this.web3.utils.fromWei(res.value),
                                    timestamp:res.timeStamp
                                }
                            })
                    
                    deposits=[...deposits, ...ethDeposits];
                }
            }          
        }
        console.log("deposits", deposits )
        return deposits;

    }

    // =============================================

        
}

const ethClient = new EthClient();
export default ethClient;