import React, {useCallback, useEffect, useState} from "react";
import constants2 from "../../../eth_client_public/constants"
import ethClient from "../../../eth_client";
import ethClient2 from "../../../eth_client_public";
import Parse from "parse";
import InfoTooltip from "../../../Components/InfoTooltip";
// import WyreDeposit from "./wyreDeposit";
import {
    portfolioDepositTitle,
    portfolioDWTitle,
    portfolioTab,
    portfolioTabDepositTickers,
    portfolioTabTitle,
    portfolioTabWithdrawTickets
} from "../MyTicketsData";
import QRCode from "qrcode.react";
import {generatePdfTable} from "../../../../utils/generatePdfTable";
import {sendParseRequest} from "../../../../utils/sendParseRequest";
import {message} from "../../../../utils/message";
// import QRCode from "qrcode.react";


const MyTicketsDepositModal = ({
                                   selectedCurrency,
                                   selectCurrency,
                                   activeTabPop,
                                   saveDigitalCheck,
                                   walletAddress,
                                   handleInputChange,
                                   addValue,
                                   amount,
                                   activeTabPortfolio,
                                   setCurrentTab,
                                   popupPage,
                                   closeModal,
                                   statusPopup,
                                   activeTab,
                                   setActiveTab,
                                   isLoading,
                                   makeDeposit,
                                   closeSvg,

                               }) => {

    const [activeTabDeposit, setactiveTabDeposit] = useState('deposit');
    const [currentDeposit, setCurrentDeposit] = useState("deposit");
    const [currentDepositTab, setcurrentDepositTab] = useState("Balances");
    const [depositAmount, setDepositAmount] = useState(0);
    const [depositFee, setDepositFee] = useState(0);
    const [depositTotalAmount, setdepositTotalAmount] = useState(0);
    const [activeSelect, setActiveSelect] = useState('USD');
    const [checkBookConnect, setcheckBookConnect] = useState('');
    // const [wyreDeposit, setWyreDeposit] = useState(false);

    const [mintAmount, setMintAmount] = useState("")
    const [burnAmount, setBurnAmount] = useState("")
    const [balances, setBalances] = useState({FNBD: 0, USDC: 0})

    const getBalances = useCallback(
        async () => {
            const response = await ethClient2.getBalances(walletAddress, ["USDC"])
            const response2 = await ethClient.eth.getFnbdBalance(walletAddress)
            setBalances({FNBD: response2, USDC: response.USDC})
        },
        []
    )


    const changeSelect = async (e) => {
        const token = e.target.value;

        if (token === "USDC" || token === "FNBD") {
            await getBalances();
        }

        setActiveSelect(token);

    };

    // const  getAuthData = ()=> {
    //     console.log('[--------www')
    //      let authData =  localStorage.getItem('checkbookTokens');
    //      return authData
    //   };

    useEffect(() => {
        let authData = localStorage.getItem('checkbookTokens');
        if (authData === null) {
            setcheckBookConnect(false)
        } else {
            setcheckBookConnect(true)
        }
    }, []);

    const connectCheckbook = () => {
        sendParseRequest('oAuth')
            .then(res => document.location.replace(res))
    }

    const handleBuyFNBD = async () => {

        //check date generate for universal digital check backend function
        const getdate = async () => {
            let dateNow = new Date();
            let year = dateNow.getFullYear();
            let day = dateNow.getDate()
            let month = dateNow.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month
                return year + ('-') + month + ('-') + day
            } else {
                return year + ('-') + month + ('-') + day
            }
        };

        getdate().then((date) => {
            let currentUser = Parse.User.current();
            let userId = currentUser.id;
            let check = {
                amount: parseInt(mintAmount),
                date: date,
                description: "fnbd",
                // fee: fee,
                // id: depositCheck.id,
                // image_uri: depositCheck.image_uri,
                // name: depositCheck.name,
                    // number: depositCheck.number,
                    // recipient: depositCheck.recipient,
                    // status: depositCheck.status,
                    userId: userId
                };

                saveDigitalCheck(check, 'usdc')

            }
        );

        try {
            const answer = window.confirm(`Are you sure you want to buy ${mintAmount} FNBD for ${mintAmount} USDC?`);

            if (answer) {
                // To buy FNBD, user sends USDC to ADMIN Wallet
                const res = await ethClient2.processWithdrawal(
                    walletAddress,
                    constants2.ADMIN_WALLET,
                    mintAmount,
                    "pipe123",
                    "USDC"
                );
                getBalances()
                message('success', "Buy order confirmed!")
                setMintAmount("")
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleSellFNBD = async () => {
        try {
            const answer = window.confirm(`Are you sure you want to sell ${burnAmount} USDW for ${burnAmount} USDC?`);

            if (answer) {
                // To sell FNBD, user burns FNBD and ADMIN Wallet sends USDC to user
                await ethClient.eth.burnFNBD(walletAddress, "pipe123", burnAmount)
                getBalances();
                message('success', "Sell order confirmed!");
                setBurnAmount("")
            }
        } catch (error) {
            console.log(error.message);

        }
    }

    const changeAmount = (e) => {
        let value = Number(e.target.value);
        setDepositAmount(value);
        let fee;
        let arr = [];
        let fees = 9.99;
        for (let i = 9999; i < 250000; i = i + 10000) {
            if ((i - 10000) >= 10000) {
                fees = fees + 10
                arr.push({
                    minimum: i - 9999,
                    maximum: i,
                    fees: fees.toFixed(2)
                })
            }
        }

        if (value > 100) {
            if (value <= 9999) {
                fee = 4.99;
            } else if (value > 9999 && value <= 19999) {
                fee = 9.99;
            } else {
                arr.forEach((i) => {
                    if (value < i.maximum && value >= i.minimum) {
                        fee = i.fees;
                    }
                })
            }

            setDepositFee(fee);
            setdepositTotalAmount(value + Number(fee));

        }
    };

    const downloadPdfTransactions = () => {
        const mail = 'ronald.mears.jr@gmail.com';
        const account = 'FANBOY7 Wallet';
        generatePdfTable({mail, account})
    };

    return (
        statusPopup && popupPage === "deposit" &&
        <div className="slideshowModal popup_ticket-block">
            <button className="slideshowModalWrapper__closeButton slideshowModalButton">
                <img src={closeSvg} alt="close" onClick={() => closeModal(false, null)}/>
            </button>
            <div className=" popup-withdraw popup_deposit">
                <div className={`myTicketsListContainer__header myTickets--title ${activeTabDeposit}`}>
                    <button
                        className={`btn-tab ${(currentDeposit === "deposit" || currentDeposit === "wyre") ? "active" : ""}`}
                        onClick={() => setCurrentDeposit("deposit")}>
                        Deposit
                    </button>
                    <button className={`btn-tab ${(currentDeposit === "portfolio") ? "active" : ""}`}
                            onClick={() => setCurrentDeposit("portfolio")}>
                        PORTFOLIO
                    </button>
                </div>
                {currentDeposit === 'deposit'
                &&
                <div className="withDraw-flex">
                    <div>
                        Fanbase account <InfoTooltip position="top"
                                                     message="The project title is the working title. You can edit, refine, or change this title at any time.">
                        <i className="fa fa-info-circle"/>
                    </InfoTooltip></div>
                    <select name="coin_name" onChange={(e) => changeSelect(e)}>
                        <option value="USD">Buy USDW with USD</option>
                        <option value="USDC">Buy USDW with USDC</option>
                        <option value="FNBD">Sell USDW for USDC</option>

                    </select>


                    {activeSelect === "USDC" &&
                    <div>
                        <div className="token_popup_leftflex">
                        </div>
                        <div className="wallets-block">
                            Wallet address
                            <p>{walletAddress}</p>
                        </div>
                        <QRCode value={`address:${walletAddress}`}/>
                        <br/>
                        <p>USDW Balance:{balances.FNBD} </p>
                        <p>USDC Balance:{balances.USDC} </p>
                        <br/>

                        <input
                            onChange={(e) => setMintAmount(e.target.value)}
                            type='number'
                            value={mintAmount}
                            placeholder="Amount USDW to Buy"
                        />
                        <br/>
                        <button type="button" className="popup_tokens-btn"
                                disabled={isLoading} onClick={handleBuyFNBD}>
                            Buy USDW
                        </button>
                        <br/>
                    </div>}

                    {activeSelect === "FNBD" &&
                    <div>
                        <div className="token_popup_leftflex">
                        </div>
                        <div className="wallets-block">
                            Wallet address
                            <p>{walletAddress}</p>
                        </div>
                        <QRCode value={`address:${walletAddress}`}/>
                        <br/>
                        <p>USDW Balance:{balances.FNBD} </p>
                        <p>USDC Balance:{balances.USDC} </p>
                        <br/>

                        <input
                            onChange={(e) => setBurnAmount(e.target.value)}
                            type='number'
                            value={burnAmount}
                            placeholder="Amount USDW to Sell"
                        />
                        <br/>
                        <button type="button" className="popup_tokens-btn"
                                disabled={isLoading} onClick={handleSellFNBD}>
                            Sell USDW
                        </button>
                        <br/>
                    </div>}


                    <div className="token_popup-inputs token_popup_leftflex">
                        {/*<p className="token_popup-amounts">Current ETH Portfolio</p>*/}
                        {/*<p className="token_popup-amounts">2233ETH</p>*/}
                    </div>
                    {activeSelect === "USD" &&
                    <div>
                        <input onChange={(e) => changeAmount(e)} type='number' minLength="3"/>
                        <p>Fee is:{depositFee} </p>
                        <p>You will pay: {depositTotalAmount} </p>


                        <div className="mt-3 popup_tickets-flex">
                            {!checkBookConnect && <button type="button" className="popup_tokens-btn"
                                                          disabled={isLoading} onClick={() => connectCheckbook()}>
                                Connect Checkbook Api
                            </button>
                            }


                            {checkBookConnect && depositTotalAmount && depositFee &&
                            <button type="button" className="popup_tokens-btn"
                                    disabled={isLoading}
                                    onClick={() => {
                                        makeDeposit(depositTotalAmount, depositFee)
                                    }}>

                                Make Deposit via Checkbook
                            </button>
                            }

                        </div>
                        <div className="mt-3 popup_tickets-flex">
                            {/*{wyreDeposit && <WyreDeposit wyreOpen={true}*/}
                            {/*    // depositTotalAmount={depositTotalAmount}*/}
                            {/*                             depositTotalAmount={depositAmount}*/}

                            {/*/>} */}
                            {/* {!wyreDeposit && <button type="button" className="popup_tokens-btn" */}
                            {/*                         disabled={isLoading}*/}
                            {/*                         onClick={*/}

                            {/*                             () => {*/}
                            {/*                                 setWyreDeposit(true);*/}
                            {/*                                 // setCurrentDeposit('wyre')*/}
                            {/*                             }*/}
                            {/*                         }>*/}

                            {/*    Make Deposit via Wyre*/}
                            {/*</button>}*/}
                        </div>
                    </div>}
                </div>

                }


                {currentDeposit === 'portfolio'
                && <div className="portfolio-popup">
                    <div className="portfolio-tab-block">
                        <button className={currentDepositTab === "Balances"
                            ? "portfolio-btn-active"
                            : "portfolio-tab-btn"}
                                onClick={() => setcurrentDepositTab("Balances")}>
                            Balances
                        </button>
                        <button className={currentDepositTab === "Deposits"
                            ? "portfolio-btn-active"
                            : "portfolio-tab-btn"}
                                onClick={() => setcurrentDepositTab("Deposits")}>
                            Deposits
                        </button>
                        <button className={currentDepositTab === "Withdraws"
                            ? "portfolio-btn-active"
                            : "portfolio-tab-btn"}
                                onClick={() => setcurrentDepositTab("Withdraws")}>
                            Withdraws
                        </button>
                        <button className='portfolio-btn-download'
                                onClick={downloadPdfTransactions}
                        >
                            Download Full Table
                        </button>
                    </div>
                    <table className="portfolio-table">
                        {currentDepositTab === "Balances" &&
                        portfolioTabTitle.map((i) =>
                            <td className="portfolio-table-title"><p>{i}</p></td>)
                        }
                        {currentDepositTab === "Deposits" &&
                        portfolioDepositTitle.map((i) =>
                            <td className="portfolio-table-title"><p>{i}</p></td>)
                        }


                        {currentDepositTab === "Withdraws" &&
                        portfolioDWTitle.map((i) =>
                            <td className="portfolio-table-title"><p>{i}</p></td>)
                        }
                        {currentDepositTab === "Balances" && portfolioTab.map((i, idx) =>
                            <tr key={idx}>
                                <td><p>{i.Asset}</p></td>
                                <td><p>{i.Holdings}%</p></td>
                                <td><p>${i.Balance}</p></td>
                                <td><p>{i.Available}</p></td>
                                <td><p>${i.Price}</p></td>
                            </tr>)
                        }
                        {currentDepositTab === "Deposits" && portfolioTabDepositTickers.map((i, idx) =>
                            <tr key={idx}>
                                <td><p>{i.Date}</p></td>
                                <td><p>{i.Asset}</p></td>
                                <td><p>{i.Quantity}</p></td>
                                <td><p>${(i.Amount / i.Quantity).toFixed(2)}</p></td>
                                <td><p>${i.Amount}</p></td>
                            </tr>)
                        }
                        {currentDepositTab === "Withdraws" && portfolioTabWithdrawTickets.map((i, idx) =>
                            <tr key={idx}>
                                <td><p>{i.Date}</p></td>
                                <td><p>{i.Asset}</p></td>
                                <td><p>{i.Quantity}</p></td>
                                <td><p>${(i.Amount / i.Quantity).toFixed(2)}</p></td>
                                <td><p>${i.Amount}</p></td>
                            </tr>)
                        }

                    </table>
                </div>}
                {/*{currentDeposit === 'wyre' && <WyreDeposit wyreOpen={true}/>}*/}
            </div>
        </div>
    );
};

export default MyTicketsDepositModal;
