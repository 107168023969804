import React from 'react';

/*
*
* Component for render principals and collaborations list
*
* */
const UserList = ({userList, listType, onEditUser, isOwnerOfTheProject}) => (
    <ul className="addUserList"
        style={{"listStyle": "none", "marginLeft": "-40px"}}>
        {
            userList.length === 0 ?
                <li className="s-input-descr addUserList__item"
                    style={{"fontSize": "15px", "marginLeft": '45px'}}>The list is empty</li>
                :
                userList.map(({names, roles, confirmed, id}, idx) => (
                    <li key={`principal-key-${idx}`}
                        className={`s-input-descr addUserList__item ${confirmed ? 'addUserList__item--confirmed' : ''}`}
                        style={{"fontSize": "15px"}}>
                        {`${names.fname} ${names.lname}, `}
                        {(roles && roles.length > 1) ?
                            roles.map((role, index) => {
                                if (index === roles.length - 1) {
                                    return role;
                                } else {
                                    return `${role}/ `;
                                }
                            }) : roles
                        }

                        {
                            listType === 'collaborator' && isOwnerOfTheProject &&
                            <button className="editBtn editBtn--withoutBorder"
                                    onClick={() => onEditUser({names, roles, id, confirmed})}>Edit</button>
                        }
                    </li>
                ))
        }
    </ul>
);

export default UserList;
