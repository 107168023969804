import React from 'react';

const InfoTooltip = ({position, message, children}) => (
    <span className="tooltip-global-wrap">
    <span className={`tooltip-inner-content ${position}`}>
					<span className="tooltip-content">
						{children}
					</span>
      <span className="tooltip-message">
        <span>{message}</span>
      </span>
    </span>
  </span>
);

export default InfoTooltip;