import {constants, fnbWeb3} from "./constants.js";
import FNBD from "../../assets/images/F-logo-in-White-Circle.png";
import axios from "axios";
import {amountInSubDecimal, amountInTIX, toFixed} from "./helper.js";
import LocalStore from "../../utils/localStore";

class EthClient {

    //=================== WAFFLEMAKR ================================

    tokenNameToAddress(tokenName) {
        switch (tokenName) {
            case "USDC":
                return constants.USDC_CONTRACT_ADDRESS
            case "LOOM":
                return constants.LOOM_CONTRACT_ADDRESS
            case "BAT":
                return constants.BAT_CONTRACT_ADDRESS
            case "ZRX":
                return constants.ZRX_CONTRACT_ADDRESS
            case "LINK":
                return constants.LINK_CONTRACT_ADDRESS
            case "REP":
                return constants.REP_CONTRACT_ADDRESS
            default:
                return null
        }
    }

    //get token contract, symbol, and decimals
    async getTokenDetails(tokenAddress) {
        const contract = new fnbWeb3.eth.Contract(constants.ERC20_ABI, tokenAddress);
        let symbol = "";
        let decimals = "";
        try {
            symbol = await contract.methods.symbol().call();
            decimals = await contract.methods.decimals().call();
            return {contract, symbol, decimals};
        } catch (e) {
            console.log(e);
        }
    }

    // get Wallet's balances of each token passed in array of symbols (i.e. ["ETH", "USDC", "LOOM])
    // async getBalances(walletAddress, tokensArray){
    //     const balanceETH = await new fnbWeb3.eth.getBalance(walletAddress);
    //
    //     let result={ETH:+fnbWeb3.utils.fromWei(balanceETH)};
    //
    //     for(let i=0;i<tokensArray.length; i++){
    //         const tokenAddress = this.tokenNameToAddress(tokensArray[i]);
    //
    //         console.log(tokensArray[i], tokenAddress)
    //
    //         // ERC-20 Token
    //         if(tokenAddress){
    //             const token = await this.getTokenDetails(tokenAddress);
    //             const balance = await token.contract.methods.balanceOf(walletAddress).call();
    //             console.log(balance)
    //             result[token.symbol] = balance / (10**token.decimals)
    //         }
    //     }
    //
    //     return result;
    // }

    // async processWithdrawal(from, to, amount, password, tokenName){
    //
    //     // Load Wallet from user
    //     const wallet = await fnbWeb3.eth.accounts.wallet.load(password);
    //
    //     let result;
    //     if(tokenName==="ETH") result = await this.sendETH(from, to, amount, wallet)
    //     else result = await this.sendToken(from, to, amount, tokenName, wallet);
    //
    //     return result;
    // }

    // Send ERC-20 token from user's Wallet
    // async sendToken(from, to, amount, tokenName, wallet){
    //
    //     try {
    //         // Get token address from symbol
    //         const tokenAddress = this.tokenNameToAddress(String(tokenName));
    //
    //         // Get token contract instance and details
    //         const token = new fnbWeb3.eth.Contract(constants.ERC20_ABI, tokenAddress);
    //         const {decimals} = await this.getTokenDetails(tokenAddress);
    //
    //         // Format amount according to decimals of token
    //         const amountToSend = amount *10**decimals;
    //
    //         // Build transaction Object
    //         const nonce = await fnbWeb3.eth.getTransactionCount(from);
    //         const _tx = token.methods.transfer(to, String(amountToSend));
    //         const gas = await _tx.estimateGas({from});
    //         const data = _tx.encodeABI();
    //
    //         const txObj = {
    //             nonce,
    //             from,
    //             to:tokenAddress,
    //             gas,
    //             gasPrice:fnbWeb3.utils.toHex(1e9),
    //             data
    //         }
    //
    //         // Sign transaction with user Wallet
    //         const tx = await wallet[from].signTransaction(txObj)
    //
    //         // Broadcast transaction to ethereum network
    //         return new Promise((resolve, reject) => {
    //             fnbWeb3.eth.sendSignedTransaction(tx.rawTransaction)
    //                 .on("receipt", receipt => {
    //                     resolve(receipt);
    //                 })
    //                 .on("error", error => {
    //                     reject(error);
    //                 })
    //             })
    //
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }

    // Send ether from user's Wallet
    async sendETH(from, to, amount, wallet) {
        try {
            // Build Transaction Object to sign with Wallet
            const nonce = await fnbWeb3.eth.getTransactionCount(from);
            const txObj = {
                nonce,
                from,
                to,
                gas: 21000,
                gasPrice: fnbWeb3.utils.toHex(1e9),
                value: fnbWeb3.utils.toWei(String(amount))
            }

            // Sign transaction with user Wallet
            const tx = await wallet[from].signTransaction(txObj)

            // Broadcast transaction to ethereum network
            return new Promise((resolve, reject) => {
                fnbWeb3.eth.sendSignedTransaction(tx.rawTransaction)
                    .on("receipt", receipt => {
                        resolve(receipt);
                    })
                    .on("error", error => {
                        reject(error);
                    })
            })
        } catch (error) {
            console.log(error)
        }
    }

    // example =  await ethClient.getWithdrawalHistory(walletAddress, ["ETH","USDC"]);
    async getWithdrawalHistory(walletAddress, tokensArray) {

        let withdrawals = [];
        for (let i = 0; i < tokensArray.length; i++) {

            if (tokensArray[i] !== "ETH") {
                const tokenAddress = this.tokenNameToAddress(tokensArray[i]);

                if (tokenAddress) {

                    // const {data} = await axios.get(`http://api-ropsten.etherscan.io/api?module=account&action=tokentx&contractaddress=${tokenAddress}&address=${walletAddress}&startblock=100000&endblock=999999999&sort=asc&apikey=YourApiKeyToken`)
                    // console.log(data)

                    const token = await this.getTokenDetails(tokenAddress);

                    const events = await token.contract.getPastEvents("Transfer", {
                        filter: {
                            from: walletAddress
                        },
                        fromBlock: 0
                    })


                    const tokenWithdrawals = [];
                    for (let i = 0; i < events.length; i++) {
                        const event = events[i];
                        const {timestamp} = await fnbWeb3.eth.getBlock(event.blockNumber)
                        withdrawals.push({
                            asset: token.symbol,
                            txHash: event.transactionHash,
                            block: event.blockNumber,
                            to: event.returnValues.to,
                            value: event.returnValues.value / (10 ** token.decimals),
                            timestamp
                        })
                    }
                    withdrawals = [...withdrawals, ...tokenWithdrawals];
                }
            } else {
                const {data} = await axios.get(`http://api-ropsten.etherscan.io/api?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&sort=asc&apikey=YourApiKeyToken`)
                if (data.result) {
                    const ethWithdrawals = data.result
                        .filter(res => res.value !== "0" && fnbWeb3.utils.toChecksumAddress(res.from) === walletAddress)
                        .map(res => {
                            return {
                                asset: "ETH",
                                txHash: res.hash,
                                block: res.blockNumber,
                                to: res.to,
                                value: fnbWeb3.utils.fromWei(res.value),
                                timestamp: res.timeStamp
                            }
                        })

                    withdrawals = [...withdrawals, ...ethWithdrawals];

                }
            }
        }

        return withdrawals;

    }

    // example =  await ethClient.getDepositHistory(walletAddress, ["ETH","USDC"]);
    // async getDepositHistory(walletAddress, tokensArray){
    //
    //     let deposits = [];
    //
    //     for(let i=0;i<tokensArray.length; i++){
    //
    //         if(tokensArray[i] !== "ETH"){
    //             const tokenAddress = this.tokenNameToAddress(tokensArray[i]);
    //
    //             if(tokenAddress){
    //                 const token = await this.getTokenDetails(tokenAddress);
    //
    //                 const events = await token.contract.getPastEvents("Transfer", {
    //                     filter:{
    //                         to: walletAddress
    //                     },
    //                     fromBlock:0
    //                 })
    //
    //                 const tokenDeposits = [];
    //                 for(let i=0; i<events.length; i++){
    //                     const event = events[i];
    //                     const {timestamp} = await fnbWeb3.eth.getBlock(event.blockNumber)
    //                     tokenDeposits.push({
    //                             asset:token.symbol,
    //                             txHash:event.transactionHash,
    //                             block:event.blockNumber,
    //                             to:event.returnValues.to,
    //                             value: event.returnValues.value / (10**token.decimals),
    //                             timestamp
    //                         })
    //
    //                 }
    //                 deposits=[...deposits, ...tokenDeposits];
    //
    //                 // deposits[token.symbol] = events.map(event => {
    //                 //     return {
    //                 //         txHash:event.transactionHash,
    //                 //         block:event.blockNumber,
    //                 //         to:event.returnValues.to,
    //                 //         value: event.returnValues.value / (10**token.decimals)
    //                 //     }
    //                 // })
    //             }
    //         }else{
    //             const {data} = await axios.get(`http://api-ropsten.etherscan.io/api?module=account&action=txlist&address=${walletAddress}&startblock=0&endblock=99999999&sort=asc&apikey=YourApiKeyToken`)
    //             if(data.result){
    //                 const ethDeposits = data.result
    //                         .filter(res => res.value !== "0" && fnbWeb3.utils.toChecksumAddress(res.to) === walletAddress)
    //                         .map(res => {
    //                             return {
    //                                 asset:"ETH",
    //                                 txHash:res.hash,
    //                                 block:res.blockNumber,
    //                                 from:res.from,
    //                                 value: fnbWeb3.utils.fromWei(res.value),
    //                                 timestamp:res.timeStamp
    //                             }
    //                         })
    //
    //                 deposits=[...deposits, ...ethDeposits];
    //             }
    //         }
    //     }
    //     console.log(deposits, "deposits")
    //     return deposits;
    //
    // }

    // =============================================

    async createWallet(count, password) {
        let walletJSON = await fnbWeb3.eth.accounts.wallet.create(count);
        await fnbWeb3.eth.accounts.wallet.save(password);
        return walletJSON;
    }

    // async decryptWallet(password){
    //     let encrypted_wallet_str = localStorage.getItem("encrypted_wallet");
    //     let encrypted_wallet_arr = JSON.parse(encrypted_wallet_str);
    //     return fnbWeb3.eth.accounts.wallet.decrypt(encrypted_wallet_arr, password);
    // }

    async createNewAccount(password) {
        let newAccount = await fnbWeb3.eth.accounts.create();
        let result = await fnbWeb3.eth.accounts.wallet.add(newAccount);
        await this.saveWallet(password);
        return result;
    }

    async importPrivateKey(private_key) {
        let result = await fnbWeb3.eth.accounts.wallet.add(private_key);
        await this.saveWallet("password");
        return result;
    }

    // async unlockAccount(walletAddress, password){
    //     let option = constants.OPTIONS;
    //     option.from = walletAddress;
    //     await fnbWeb3.eth.personal.unlockAccount(walletAddress, password, 5);
    // }

    async getETHBalance(walletAddress) {
        let name = "ETH";
        let symbol = "ETH";
        let balance = 0;
        let image = "https://cdn4.iconfinder.com/data/icons/cryptocoins/227/ETH-alt-512.png";
        try {
            balance = await new fnbWeb3.eth.getBalance(walletAddress);
        } catch (e) {
            console.log(e);
        }

        let balanceOfEth = fnbWeb3.utils.fromWei(`${balance}`, "ether");

        return {name, symbol, balance: balanceOfEth, image};
    }

    async getFNBCoin(walletAddress) {
        let fnbCoinContract = new fnbWeb3.eth.Contract(constants.FMB_COIN_ABI, constants.FNB_COIN);
        let symbol = "FNBD";
        let balance = 0;
        let name = "FNBD";
        let image = FNBD;
        let decimals = 18;
        try {
            balance = await fnbCoinContract.methods.balanceOf(walletAddress).call();
            decimals = await fnbCoinContract.methods.decimals().call();
        } catch (e) {
            console.log(e);
        }
        return {name, symbol, balance: amountInTIX(balance), image, decimals};
    }

    async getFnbdBalance(walletAddress) {
        try {
            const fnbdContract = new fnbWeb3.eth.Contract(constants.FNBD_ABI, constants.FNBD_MINTABLE);
            const balance = await fnbdContract.methods.balanceOf(walletAddress).call();
            const decimals = await fnbdContract.methods.decimals().call();
            return balance / 10 ** decimals
        } catch (e) {
            console.log(e);
        }
    }

    // async getUSDCBalance(walletAddress){
    //     let usdCoinContract = new fnbWeb3.eth.Contract(constants.ERC20_ABI, constants.USDC_CONTRACT_ADDRESS);
    //     let symbol = "USDC";
    //     let balance = 0;
    //     let name = "USD Coin";
    //     try{
    //         balance = await usdCoinContract.methods.balanceOf(walletAddress).call();
    //     }catch(e){
    //         console.log("error",e);
    //     }
    //     return {name, symbol, balance:fnbWeb3.utils.fromWei(String(balance)) };
    // }

    async saveWallet(password) {
        return await fnbWeb3.eth.accounts.wallet.save(password);
    }

    // async loadWallet(password){
    //     return await fnbWeb3.eth.accounts.wallet.load(password);
    // }

    async getWalletAccountAddresses(password) {
        var walletJSON = await fnbWeb3.eth.accounts.wallet.load(password);
        let arr = [];
        for (var i = 0; i < walletJSON.length; i++) {
            arr.push(walletJSON[i].address);
        }
        return arr;
    }

    async getAccountObj(password, account_address) {


        var walletJSON = await fnbWeb3.eth.accounts.wallet.load(password);
        for (var i = 0; i < walletJSON.length; i++) {
            if (walletJSON[i].address == account_address) return walletJSON[i];
        }
        return null;
    }

    setActiveAccount(account_address) {
        localStorage.setItem("active_address", account_address);
        // localStorage.setItem("active_priavte_key", account.privateKey);
    }

    getActiveAccountAddress() {
        try {
            return localStorage.getItem('active_address');
        } catch (e) {
            return "";
        }
    }

    getActivePrivateKey() {

        console.log(1111112222)
        try {
            let localStore = new LocalStore();
            let privKey = false;
            let arr = []
            let masterkey = localStore.getDecoded('userToken');
            if (masterkey) {
                const localStoreWallets = new LocalStore(masterkey);
                const walletsString = localStoreWallets.get('walletsList');
                console.log(walletsString, '-----walletsString')
                if (localStorage.getItem('active_address')) {
                    let activeAddress = localStorage.getItem('active_address');


                    if (walletsString) {
                        let arr = [];
                        let walletsArray = JSON.parse(walletsString);
                        walletsArray.map((wallet) => {
                            if (wallet) {
                                if (activeAddress === wallet.addresses[0]) {
                                    console.log(wallet)
                                    arr.push(wallet)


                                }

                            }


                        })


                        if (arr.length > 0) {
                            console.log(arr, '=======r====arr====r====')
                            return (arr)
                        }

                        // console.log(walletsArray, 'walletsArray110', activeAddress)
                        // return (walletsString)
                        // console.log(walletsArray, 'walletsArray')
                        // console.log(walletsArray[0], 'walletsArray00000000000')

                        // walletsArray.forEach((globalWallet)=> {
                        //     globalWallet.addresses.forEach((localWallet)=> {
                        //         if(localWallet===activeAddress) {
                        //             let obj = globalWallet;
                        //             // obj.
                        //             console.log(localWallet, globalWallet.serJsonString)
                        //             arr.push(globalWallet)
                        //         }
                        //     })
                        // })


                        // walletsArray[0].addresses.forEach((wallet) => {
// console.log(wallet, '------wallet-----')
                        // if (wallet.address === activeAddress) {
                        //     privKey=wallet.privKey;
                        // }
                        // })
                    }
                }
            }

            // if (arr[0]) return(arr[0])
        } catch (e) {
            return "";
        }
    }

    // getActiveAccountPrivateKey(){
    //     return localStorage.getItem("active_priavte_key");
    // }

    // Burn FNBD tokens to get USDC back
    async burnFNBD(from, password, amount) {

        try {

            const wallet = await this.getAccountObj(password, from);

            // Get token contract instance and details
            const fnbdToken = new fnbWeb3.eth.Contract(constants.FNBD_ABI, constants.FNBD_MINTABLE);

            // Build transaction Object
            const nonce = await fnbWeb3.eth.getTransactionCount(from);
            const _tx = fnbdToken.methods.burn(amount * 10 ** 6);
            const gas = await _tx.estimateGas({from});
            const data = _tx.encodeABI();

            const txObj = {
                nonce,
                from: wallet.address,
                to: constants.FNBD_MINTABLE,
                gas,
                gasPrice: fnbWeb3.utils.toHex(1e9),
                data
            }

            // Sign transaction with user Wallet
            const tx = await wallet.signTransaction(txObj)

            // Broadcast transaction to ethereum network
            return new Promise((resolve, reject) => {
                fnbWeb3.eth.sendSignedTransaction(tx.rawTransaction)
                    .on("receipt", receipt => {
                        // Send POST request to server
                        axios.post(constants.API_URL + "/sales/sell", {
                            txHash: receipt.transactionHash
                        })
                            .then(() => resolve(receipt))
                            .catch((e) => reject(e))
                    })
                    .on("error", error => {
                        reject(error);
                    })
            })

        } catch (error) {
            console.log(error)
        }
    }

    async approvieCoinsForMatcher(account_address, password, amount) {
        let account = await this.getAccountObj(password, account_address);
        let spender_address = constants.FNB_TICKET_MATCHER;
        let token_address = constants.FNB_COIN;
        amount = amountInSubDecimal(amount);
        if (account) {
            let tix = new fnbWeb3.eth.Contract(constants.FNB_TICKET_ABI, token_address);
            const _tx = tix.methods.increaseAllowance(
                spender_address,
                toFixed(amount)
            );
            const tx = {
                nonce: await fnbWeb3.eth.getTransactionCount(account.address),
                from: account.address,
                to: token_address,
                gas: await _tx.estimateGas({from: account.address}),
                gasPrice: fnbWeb3.utils.toHex(1e9),
                data: _tx.encodeABI()
            }

            try {
                const signedTx = await fnbWeb3.eth.accounts.signTransaction(tx, account.privateKey);
                const sentTx = await fnbWeb3.eth.sendSignedTransaction(signedTx.raw || signedTx.rawTransaction);
                return true;
            } catch (e) {
                console.log(e);
                return false;
            }
        }
    }

    async decreaseAllowancCoinsForMatcher(account_address, password, amount) {
        let account = await this.getAccountObj(password, account_address);
        let spender_address = constants.FNB_TICKET_MATCHER;
        let token_address = constants.FNB_COIN;
        let allowance = await this.getAllowanceOfUser(token_address, account_address, spender_address);
        amount = amountInSubDecimal(amount);
        let deAmnt = Math.min(amount, allowance);
        if (account) {
            let tix = new fnbWeb3.eth.Contract(constants.FNB_TICKET_ABI, token_address);
            const _tx = tix.methods.decreaseAllowance(
                spender_address,
                toFixed(deAmnt)
            );
            const tx = {
                nonce: await fnbWeb3.eth.getTransactionCount(account.address),
                from: account.address,
                to: token_address,
                gas: await _tx.estimateGas({from: account.address}),
                gasPrice: fnbWeb3.utils.toHex(1e9),
                data: _tx.encodeABI()
            }

            try {
                const signedTx = await fnbWeb3.eth.accounts.signTransaction(tx, account.privateKey);
                const sentTx = await fnbWeb3.eth.sendSignedTransaction(signedTx.raw || signedTx.rawTransaction);
                return true;
            } catch (e) {
                console.log(e);
                return false;
            }
        }
    }
}

const ethClient = new EthClient();
export default ethClient;
