import React, {useEffect, useRef, useState} from "react";
import InfoTooltip from "../../../Components/InfoTooltip";
import {portfolioDWTitle, portfolioTabTitle} from "../centralTokenData";
import closeSvg from "../../../../assets/images/close--blue.svg";
import ethClient2 from "../../../eth_client_public";
import constants from "../../../eth_client_public/constants";
import {generatePdfTable} from "../../../../utils/generatePdfTable";
import CentralTokenDepositModal from "../CentralTokenDepositModal/CentralTokenDepositModal";
import sighInTendermint from "../../../api/tendermint/signIn";
import {getWallyWalletObject, sentUSDWToWallet, callBurnTokensUSDW} from '../../../../utils/temndermintWalletsUtils'
import {walletService} from '../../../api/tendermint/main'
import {message} from "../../../../utils/message";

const CentralTokenWithdrawModal = ({
                                       walletAddress, ethWallet,
                                       selectedCurrency,
                                       selectCurrency,
                                       activeTabPop,
                                       addValue,
                                       amount,
                                       balances,
                                       activeTabPortfolio,
                                       setCurrentTab,
                                       popupPage,
                                       closeModal,
                                       statusPopup,
                                       makeWithdraw,
                                       updateAmount,
                                       updateTo,
                                       updatePassword,
                                       withdrawInfo
                                   }) => {
    const passwordRef = useRef();
    const [withdrawals, setWithdrawals] = useState([])
    const [deposits, setDeposits] = useState([])
    const [usdcValue, setUsdcValue] = useState(0)
    const [errorMessage, setErrorMessage] = useState(false)
    const [loading, setLoading] = useState(false)

    const getWithdrawalHistory = async () => {
        const res = await ethClient2.getWithdrawalHistory(walletAddress, ["ETH", "USDC", "LOOM"]);

        setWithdrawals(res)
        setCurrentTab("Withdraws", 'portfolio')
    }

    useEffect(() => {
        if (loading) {
            const resetProgressBar = () => {
                width = 1;
                clearInterval(interval)
                elem.style.width = width + '%';
            }

            let elem = document.getElementById("progress-bar");
            console.log(elem, '--elem')
            let width = 1;
            let interval;


            resetProgressBar();

            interval = setInterval(frame, 480);

            function frame() {
                if (width >= 100) {
                    clearInterval(interval);
                } else {
                    width++;
                    elem.style.width = width + '%';
                }
            }


        }
    }, [loading])


    const getDepositHistory = async () => {
        const res = await ethClient2.getDepositHistory(walletAddress, ["ETH", "USDC", "LOOM"]);

        setDeposits(res)
        setCurrentTab("Deposits", 'portfolio')
    }

    const formatDate = timestamp => {
        let date = new Date(null);
        date.setSeconds(timestamp);
        return date
            .toISOString()
            .slice(0, 19)
            .replace(/-/g, "/")
            .replace("T", " ");
    };

    const downloadPdfTransactions = () => {
        const mail = 'ronald.mears.jr@gmail.com';
        const account = 'FANBOY7 Wallet';
        generatePdfTable({mail, account})
    }


    const transferUSDWtoUSDW = async () => {
        if (usdcValue <= 0) {
            message('error', 'your input value should be more that 0 ', 3000)

        } else {
            let passwordForWally = passwordRef.current.value;

            // console.log(  walletAddress,ethWallet,'  walletAddress,ethWallet,',   passwordRef.current.value)
            let wallyDecryptedObject = await getWallyWalletObject(passwordForWally)
            if (wallyDecryptedObject) {
                setLoading(true)

                let withdrawReq = await callBurnTokensUSDW(passwordForWally, usdcValue)
                if (withdrawReq) {
                    message('success', 'your USDW withdraw to USDC success done', 3000)
                    setUsdcValue(0)
                    setLoading(false)
                }
            }
        }

    }

    const setupUSDCValue = (value) => {
        if (value && value > 0) {
            setUsdcValue(value) && setErrorMessage(false)
        } else {
            setUsdcValue(0) && setErrorMessage("you should input more, that 0");

        }
    }

    return (
        statusPopup && popupPage === "withdraw" &&
        <div className="slideshowModal popup_token-block">
            <button className="slideshowModalWrapper__closeButton slideshowModalButton">
                <img src={closeSvg} alt="close" onClick={() => closeModal(false, null)}/>
            </button>
            <div className=" popup-withdraw popup_tokens">
                <div className={`myTicketsListContainer__header myTickets--title ${activeTabPop}`}>
                    <button className={`btn-tab ${(activeTabPop === "Withdraw") ? "active" : ""}`}
                            onClick={() => setCurrentTab("Withdraw", 'pop')}>
                        Withdraw
                    </button>
                    <button className={`btn-tab ${(activeTabPop === "portfolio") ? "active" : ""}`}
                            onClick={() => setCurrentTab("portfolio", 'pop')}>
                        PORTFOLIO
                    </button>
                </div>
                {activeTabPop === 'Withdraw'
                    ? <>
                        {!loading &&
                        <div className="withDraw-flex">
                            USDW>USDC WITHDRAW
                            <div><input ref={passwordRef} type='password'/>Enter a password from your wallet</div>
                            You want to sell USDW <div>
                            <input type="number" min={0} defaultValue={0}
                                   onChange={(e) => setupUSDCValue(e.target.valueAsNumber)}/>
                            USDW</div>
                            this is {usdcValue} USDC
                            <div>Transaction gas is 0.00085 ETH</div>
                            <button
                                className="popup_tokens-btn"
                                // disabled={withdrawInfo.amount === "" || withdrawInfo.to === "" || withdrawInfo.password === ""}
                                onClick={() => transferUSDWtoUSDW()}
                            > {`Withdraw USDW`}</button>

                        </div>
                        }
                        {loading &&
                        <div style={{display: 'flex', flexDirection: "column", marginTop: '30px'}}>
                            Withdraw in progress. Please wait.
                            <div className="slide-progress-bar">
                                <div className="progress-bar" id="progress-bar"/>
                            </div>
                        </div>
                        }

                    </>


                    : <div className="portfolio-popup">
                        <div className="portfolio-tab-block">
                            <button className={activeTabPortfolio === "Balances"
                                ? "portfolio-btn-active"
                                : "portfolio-tab-btn"}
                                    onClick={() => setCurrentTab("Balances", 'portfolio')}>
                                Balances
                            </button>
                            <button className={activeTabPortfolio === "Deposits"
                                ? "portfolio-btn-active"
                                : "portfolio-tab-btn"}
                                    onClick={getDepositHistory}>
                                Deposits
                            </button>
                            <button className={activeTabPortfolio === "Withdraws"
                                ? "portfolio-btn-active"
                                : "portfolio-tab-btn"}
                                    onClick={getWithdrawalHistory}>
                                Withdraws
                            </button>
                        </div>
                        <table className="portfolio-table">
                            <thead>
                            <tr>
                                {activeTabPortfolio === "Balances" &&
                                portfolioTabTitle.map((i, k) =>
                                    <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
                                }
                                {activeTabPortfolio === "Deposits" &&
                                portfolioDWTitle.map((i, k) =>
                                    <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
                                }
                                {activeTabPortfolio === "Withdraws" &&
                                portfolioDWTitle.map((i, k) =>
                                    <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {activeTabPortfolio === "Balances" && constants.ASSETS.map((asset, k) =>
                                <tr key={k}>
                                    <td><p>{asset}</p></td>
                                    <td><p>{0}%</p></td>
                                    <td><p>${0}</p></td>
                                    <td><p>{balances[asset]}</p></td>
                                    <td><p>${0}</p></td>
                                </tr>)
                            }
                            {activeTabPortfolio === "Deposits" && deposits.map((i, k) =>
                                <tr key={k}>
                                    <td><p>{formatDate(i.timestamp)}</p></td>
                                    <td><p>{i.asset}</p></td>
                                    <td><p>{i.value}</p></td>
                                    <td><p>$0</p></td>
                                    <td><p>0</p></td>
                                </tr>)
                            }
                            {activeTabPortfolio === "Withdraws" && withdrawals.map((i, k) =>
                                <tr key={k}>
                                    <td><p>{formatDate(i.timestamp)}</p></td>
                                    <td><p>{i.asset}</p></td>
                                    <td><p>{i.value}</p></td>
                                    <td><p>$0</p></td>
                                    <td><p>0</p></td>
                                </tr>)
                            }
                            </tbody>
                        </table>
                    </div>}
            </div>
            }
        </div>







        // statusPopup && popupPage === "withdraw" &&
        // <div className="slideshowModal popup_token-block">
        //     <button className="slideshowModalWrapper__closeButton slideshowModalButton">
        //         <img src={closeSvg} alt="close" onClick={() => closeModal(false, null)}/>
        //     </button>
        //     <div className=" popup-withdraw popup_tokens">
        //         <div className={`myTicketsListContainer__header myTickets--title ${activeTabPop}`}>
        //             <button className={`btn-tab ${(activeTabPop === "Withdraw") ? "active" : ""}`}
        //                     onClick={() => setCurrentTab("Withdraw", 'pop')}>
        //                 Withdraw
        //             </button>
        //             <button className={`btn-tab ${(activeTabPop === "portfolio") ? "active" : ""}`}
        //                     onClick={() => setCurrentTab("portfolio", 'pop')}>
        //                 PORTFOLIO
        //             </button>
        //         </div>
        //         {activeTabPop === 'Withdraw'
        //             ? <div className="withDraw-flex">
        //                 Fanbase Account
        //                 <select onChange={(e) => selectCurrency(e.target.value)}>
        //                     <option>ETH</option>
        //                     <option>USDC</option>
        //                     <option>BAT</option>
        //                     <option>LINK</option>
        //                     <option>LOOM</option>
        //                     <option>REP</option>
        //                     <option>ZRX</option>
        //                     <option>DAI</option>
        //                 </select>
        //
        //                 <div className="wallets-block">
        //                     To
        //                     <InfoTooltip position="top"
        //                                  message="Input your destination wallet address (i.e. Coinbase or Metamask).
        //                                      Your Fanbase wallet is decentralized meaning you are in custody of these funds;
        //                                      we cannot retrieve them so be sure to copy and paste the correct address. ">
        //                         <i className="fa fa-info-circle"/>
        //                     </InfoTooltip>
        //                     <input className="popup-max-inpt-full"
        //                            placeholder="Copy and paste destination address"
        //                            onChange={e => updateTo(e.target.value)}
        //                            value={withdrawInfo.to}/>
        //                 </div>
        //                 <div className="token_popup-inputs">
        //                     <div className="token_popup-amounts ">
        //                         <button className="max-btn popup-max"
        //                                 onClick={() => updateAmount(balances[selectedCurrency])}>MAX
        //                         </button>
        //                         Amount
        //                         <input className="popup-max-inpt"
        //                                placeholder={selectedCurrency}
        //                                onChange={e => updateAmount(e.target.value)}
        //                                value={withdrawInfo.amount}/>
        //                     </div>
        //                     <div className="token_popup-amounts">
        //                         Amount {amount} USDW
        //                     </div>
        //                 </div>
        //                 <div className="popup_tokens-texts">
        //                     <div className="popup_tokens-text">
        //                         <p>Fee</p>
        //                         <p>0.0000</p>
        //                     </div>
        //                 </div>
        //                 <div className="popup_tokens-line"/>
        //                 <div className="popup_tokens-text">
        //                     <p>Available to withdraw</p>
        //                     <p>{balances[selectedCurrency]}</p>
        //                 </div>
        //                 <input className="popup-max-inpt"
        //                        placeholder="wallet password"
        //                        onChange={updatePassword}
        //                 />
        //                 <button
        //                     className="popup_tokens-btn"
        //                     disabled={withdrawInfo.amount === "" || withdrawInfo.to === "" || withdrawInfo.password === ""}
        //                     onClick={makeWithdraw}
        //                 > {`Withdraw ${selectedCurrency}`}</button>
        //             </div>
        //             : <div className="portfolio-popup">
        //                 <div className="portfolio-tab-block">
        //                     <button className={activeTabPortfolio === "Balances"
        //                         ? "portfolio-btn-active"
        //                         : "portfolio-tab-btn"}
        //                             onClick={() => setCurrentTab("Balances", 'portfolio')}>
        //                         Balances
        //                     </button>
        //                     <button className={activeTabPortfolio === "Deposits"
        //                         ? "portfolio-btn-active"
        //                         : "portfolio-tab-btn"}
        //                             onClick={getDepositHistory}>
        //                         Deposits
        //                     </button>
        //                     <button className={activeTabPortfolio === "Withdraws"
        //                         ? "portfolio-btn-active"
        //                         : "portfolio-tab-btn"}
        //                             onClick={getWithdrawalHistory}>
        //                         Withdraws
        //                     </button>
        //                 </div>
        //                 <table className="portfolio-table">
        //                     <thead>
        //                     <tr>
        //                         {activeTabPortfolio === "Balances" &&
        //                         portfolioTabTitle.map((i, k) =>
        //                             <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
        //                         }
        //                         {activeTabPortfolio === "Deposits" &&
        //                         portfolioDWTitle.map((i, k) =>
        //                             <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
        //                         }
        //                         {activeTabPortfolio === "Withdraws" &&
        //                         portfolioDWTitle.map((i, k) =>
        //                             <td key={k} className="portfolio-table-title"><p>{i}</p></td>)
        //                         }
        //                     </tr>
        //                     </thead>
        //                     <tbody>
        //                     {activeTabPortfolio === "Balances" && constants.ASSETS.map((asset, k) =>
        //                         <tr key={k}>
        //                             <td><p>{asset}</p></td>
        //                             <td><p>{0}%</p></td>
        //                             <td><p>${0}</p></td>
        //                             <td><p>{balances[asset]}</p></td>
        //                             <td><p>${0}</p></td>
        //                         </tr>)
        //                     }
        //                     {activeTabPortfolio === "Deposits" && deposits.map((i, k) =>
        //                         <tr key={k}>
        //                             <td><p>{formatDate(i.timestamp)}</p></td>
        //                             <td><p>{i.asset}</p></td>
        //                             <td><p>{i.value}</p></td>
        //                             <td><p>$0</p></td>
        //                             <td><p>0</p></td>
        //                         </tr>)
        //                     }
        //                     {activeTabPortfolio === "Withdraws" && withdrawals.map((i, k) =>
        //                         <tr key={k}>
        //                             <td><p>{formatDate(i.timestamp)}</p></td>
        //                             <td><p>{i.asset}</p></td>
        //                             <td><p>{i.value}</p></td>
        //                             <td><p>$0</p></td>
        //                             <td><p>0</p></td>
        //                         </tr>)
        //                     }
        //                     </tbody>
        //                 </table>
        //             </div>}
        /*    </div>*/
        /*    }*/
        /*</div>*/
    );
};

export default CentralTokenWithdrawModal;

