import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import Parse from "parse";
import axios from "axios";
import MyCollectibles from "../../Components/Collectibles/MyCollectibles";
import {CollectiblePortfolio} from "../../Components/Collectibles/Portfolio/Portfolio";
import CartCollectibles from "../../Components/Collectibles/CartCollectibles/CartCollectibles";
import {Link} from "react-router-dom";
import {DepositDetails, WithdrawalDetails} from "../../Components/Wallet/wallets";
import CollectiblesTransactionsTable
  from "../../Components/Collectibles/TransactionsTable/CollectiblesTransactionsTable";
import AdvertByCollectibles from '../../Components/AdvertByCollectibles';
import * as blockChainActions from "../../../modules/blockchain/blockchain.action";
// import * as cartActions from "../../../modules/cart/cart.action";
import TradeHistory from '../../Components/TradeHistory';
import CollectibleSummary from '../../Components/Collectibles/Summary/CollectibleSummary';
import ethClient from "../../eth_client";
import './Wallets3.css'
import {sendParseRequest} from "../../../utils/sendParseRequest";
import constants from "../../api/constant";
import {useHistory} from "react-router";
import LocalStore from "../../../utils/localStore";
import {Loader} from "../../Components/Loader";

const auth = constants.auth;

const Wallets3 = (props) => {
  const history = useHistory();


  const [state, setState] = useState({
    openActive: true,
    walletsChangeSummary: false,
    typeChange: "deposit",
    view2Summary: false,
    depositType: "none",
    verifiedUser: false,
    sendCoinSelected: "",
    totalLareBalance: "",
    numberOfElementOnOnePage: 10,
    projects: undefined,
    licenses: [],
    licenseIsLoading: true,
    isSellAllLicenses: false,
    selectedLicenses: [],
    allProjectsId: [],
    selectedProject: null,
    selectedToken: null,
    noneTokens: false
  });
  const [collectibles, setCollectibles] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [portfolioTab, setPortfolioTab] = useState('collectible');
  const [cartItemArr, setCartItemArr] = useState('collectible');
  const [showCart, setShowCart] = useState(false);
  const [preloader, setPreloader] = useState(true);
  const [privKey, setPrivKey] = useState(true);

  const blockchainRedux = useSelector(state => state.blockchain);
  const cartRedux = useSelector(state => state.blockchain.cart);



  useEffect(() => {
    //get private key from active address wallet

    let privKey = ethClient.eth.getActivePrivateKey();
    if (privKey) {
      setPrivKey(privKey);
    }

  }, []);



  useEffect(() => {
    if (cartRedux.length > 0) {
      setShowCart(true)
      console.log(collectibles, "--------------collectibles")
    }
    if (cartRedux.length === 0) {
      setShowCart(false)
    }
  }, [
    blockchainRedux
  ]);

  useEffect(() => {

    const currUser = Parse.User.current();
    let userId = currUser.id;
    if (currUser && userId) {
      sendParseRequest('getUserInfoById', {id: userId})
        .then(res => {
          setState({...state, verifiedUser: res.get('accountVerified')})
        })
    }

    window.scrollTo(0, 0);
    document.title = "My Collectibles – FNBD";
    console.log(state.verifiedUser, '---state.verifiedUser')
    if (state.verifiedUser) {
      getLimitedProjects();
    }
  }, [state.verifiedUser]);


  const getCollectibles = async (walletAddresse) => {
    const result1 = await axios({
      method: 'get',
      url: `${constants.apiRoot}/contracts/projects/licenseOwner?address=${walletAddresse}`,
      responseType: 'json',
      // auth
    });
    // console.log(result1.data.projects, "--------------result1")
    // const result = await axios({
    //   method: 'get',
    //   url: `${constants.apiRoot}/contracts/collectibles/collectiblesOfOwner?address=${walletAddresse}`,
    //   responseType: 'json',
    //   auth
    // });
    console.log(result1, "--------------result111111")
    return result1.data.projects
  };

  // console.log(state.selectedToken, "--------------state.selectedToken")

  const getLimitedProjects = async () => {

    const walletAddresse = await ethClient.eth.getActiveAccountAddress();
    setWalletAddress(walletAddresse);
    const gettedTokens = await getCollectibles(walletAddresse);
    console.log(gettedTokens, "--------------gettedTokens")
    let arrTokens = [];
    if (gettedTokens.length>0) {
      gettedTokens && gettedTokens.forEach((i) => {
        // console.log(i, '========i')
        arrTokens.push(i.metadata.tokenURI)
      });
      let combinePromise = new Promise((resolve) => {
// console.log(arrTokens, '===arrTokens')
        arrTokens.length > 0 && sendParseRequest('getInfo', {
          query: "Projects",
          toJSON: true,
          // equal: [{field: "verification.approve", value: true}],
          containedIn: {field: "objectId", value: arrTokens},
          select: ["objectId", "logoAttach", "projectTitle"],
        })
          .then(projectInfos => {
            // console.log(projectInfos, "--------------projectInfos========")
            let combineArr = [];
            projectInfos.forEach((item) => {
              // console.log(item, "--------------item==========")
              gettedTokens.forEach((i) => {
                // console.log(i, "--------------i")
                if ((item.objectId === i.metadata.tokenURI) && (i.metadata.licenseType === 'limited')) {

                  // console.log(i, "--------------i22")
                  let combineObj = {
                    auctionBuyNowPrice: i.metadata.auctionBuyNowPrice,
                    auctionEndDate: i.metadata.auctionEndDate,
                    auctionRequired: i.metadata.auctionRequired,
                    auctionStartPrice: i.metadata.auctionStartPrice,
                    auctioned: i.metadata.auctioned,
                    bidPrice: i.metadata.bidPrice,
                    candidate: i.metadata.candidate,
                    id: item.objectId,
                    metadata: i.metadata,
                    tokenURI: i.metadata.projectId,
                    createdAt: item.createdAt,
                    projectTitle: item.projectTitle,
                    logoAttach: item.logoAttach,
                  };
                  // console.log(combineObj, "--------------combineObj")
                  combineArr.push(combineObj)
                  // console.log(combineArr, "--------------combineArr11")
                }
              })
            });
            // combineArr && console.log(combineArr, "--------------combineArr")


            resolve(combineArr)
          })
      });
      combinePromise.then((combineRes) => {
        if (combineRes[0]) {
          setState({...state, selectedProject: combineRes[0]});

        } else {
          setState({...state, selectedProject: null});

        }
        setPreloader(false)
        console.log(combineRes, "--------------combineRes")
        setCollectibles(combineRes)
      });
    } else {
console.log(111111, "--------------111111")

      setState({...state, selectedProject: null})
      setPreloader(false)
    }
  };

  const onSelectProject = (project) => {
console.log(state.selectedProject, 'selected')
    console.log(project, "--------------project999")
    setState({...state, selectedProject: project});

  };

  const onSelectToken = (token) => {
    console.log(token, "--------------token")
    setState({...state, selectedToken: token});
  };

  const checkLicenseForReadyToSend = (project, licenses) => {
    const {
      remainedLicenses,
      licensesPercentageForCreator,
      numOfLicenses,
      creator,
      ipPercentage
    } = project;

    const licensesLength = licenses.length;
    const currUserId = Parse.User.current().id;
    const isOwner = creator.id === currUserId;

    if (!isOwner && !licensesPercentageForCreator && ipPercentage !== 'Unidentified') return false;

    const allRemainedLicenses = (+remainedLicenses) + (+licensesLength);
    const percentage = licensesPercentageForCreator.replace('%', '');
    const allRemainedLicensesInPercentage = (100 * allRemainedLicenses) / numOfLicenses;
    const result = allRemainedLicensesInPercentage <= parseInt(percentage);

    if (licensesLength > 1 && !result) return false;

    for (let i = 0; i < licenses.length; i++) {
      licenses[i].isReadyToSendToOtherWallets = true;
    }
  };

  // const runReferralLinks = () => {
  //     sendParseRequest('runReferralCode', {limit: 1000})
  // };

  const handleChangeOpen = () => {
    setState({...state, openActive: !state.openActive});
  };

  const handleBoxChange = (e) => {
    setState({
      ...state,
      "walletsChangeSummary": true,
      "typeChange": e.target.attributes.type.value,
      "sendCoinSelected": e.target.attributes.coinsend.value,
      "totalLareBalance": e.target.attributes.totalLareBalance.value
    });
  };

  const handleCancelWalletsChange = () => {
    setState({
      ...state,
      "walletsChangeSummary": false
    });
  };

  const handleSellAllLicenses = (flag = null) => {
    const {licenses} = state;
    const selectedLicenses = flag === false ? [] : licenses.map(license => license.id);

    setState(state => {
      return {isSellAllLicenses: flag !== null ? flag : !state.isSellAllLicenses, selectedLicenses}
    })
  };

  const setActivePage = () => {
  }

  const render = () => {
    const {
      projects,
      licenses,
      noneTokens,

      selectedProject,
      isSellAllLicenses,
      selectedLicenses,
      licenseIsLoading,
      allProjectsId
    } = state;

    // console.log(props, '=-======;PROPPPSSS')
    return (
      <div className="page wallets3Page" >
        <>
          {preloader ? <Loader showLoader={preloader} style={{
              width: "auto",
              height: "auto",
              backgroundColor: "transparent",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "static"
            }}/>
            : <>
              {!selectedProject
                ?
                <div className="dashWrapperB"
                     style={{marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div className="dashModal " style={{
                    height: "70%",
                    minHeight: '0 !important',
                    margin: '0 auto 0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    <div className="content tokens-content ff-content"
                         style={{
                           "height": "auto",
                           "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                           "borderRadius": "5px",
                           "width": "80%",
                           display: 'flex', justifyContent: 'center', alignItems: 'center'
                         }}>
                      <p>You havent collectibles yet.
                        <span className='link' onClick={() => history.push(`/`)}>Buy licenses &#160;</span>
                        or create project &#160;
                        <span className='link' onClick={() => history.push(`/contract`)}>here</span>.</p>
                    </div>
                  </div>
                </div>
                :
                <div className="dashWrapperB" style={{marginBottom: '20px'}}>
                  <AdvertByCollectibles allProjectsId={allProjectsId}/>
                  <div className="dashModal PortfolioPageWrap" style={{"width": "40%", marginTop: '10px'}}>
                    <div className="content tokens-content ff-content"
                         style={{
                           "height": "auto",
                           "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                           "borderRadius": "5px"
                         }}>
                      <div className="cHeader">
                        <p className="title sub-title">My Collectibles </p>
                      </div>
                      <div className="cBody">
                        {collectibles &&
                        <MyCollectibles
                          // tokens={props.tokens}
                          tokens={collectibles}
                          setActivePage={setActivePage}
                          onSelectProject={onSelectProject}
                          onSelectToken={onSelectToken}
                          handleSellAllLicenses={handleSellAllLicenses}
                          setPortfolioTab={setPortfolioTab}
                        />}
                      </div>
                    </div>
                  </div>
                  <div className="rightWrapperSide">
                    {state.walletsChangeSummary === false
                      ? <div className="dashModal PortfolioSummaryWrap" style={{marginTop: '10px'}}>
                        {state.view2Summary === false
                          ?

                          <div className="content"
                               style={{
                                 "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                                 "borderRadius": "5px"
                               }}>

                            <div className="cHeader portfolio-tab-header">
                              <p className={portfolioTab == 'collectible'
                                ? "title sub-title portfolio-tab-header-active"
                                : "title sub-title portfolio-tab-header-n-active"
                              }
                                 onClick={() => setPortfolioTab('collectible')}>
                                Collectible Portfolio
                                {/*****temporarily hidden*****/}
                                {/*<a onClick={(e) => setState({"view2Summary": !state.view2Summary})} className="pull-right viewallA">Collectible Summary</a>*/}
                              </p>
                              {showCart && <p className={portfolioTab == 'cart'
                                ? "title sub-title portfolio-tab-header-active "
                                : "title sub-title portfolio-tab-header-n-active portfolio-tab-header-right"}
                                              onClick={() => setPortfolioTab('cart')}
                              >Cart
                                {/*****temporarily hidden*****/}
                                {/*<a onClick={(e) => setState({"view2Summary": !state.view2Summary})} className="pull-right viewallA">Collectible Summary</a>*/}
                              </p>}
                            </div>
                            <>
                              <div className="cBody">
                                {portfolioTab == 'collectible' ?
                                  <>
                                    {state.selectedProject &&

                                    <CollectiblePortfolio
                                      privKey={privKey}
                                      properties={props}
                                      licenseIsLoading={licenseIsLoading}
                                      walletAddress={walletAddress}
                                      project={state.selectedProject}
                                      token={state.selectedToken}
                                      licenseIsLoading={!!(state.selectedToken)}
                                      handleSellAllLicenses={handleSellAllLicenses}
                                      isSellAllLicenses={isSellAllLicenses}
                                      type="h"/>
                                    }
                                  </> :
                                  <>
                                    {showCart && <CartCollectibles
                                        setPortfolioTab={setPortfolioTab}
                                        tokens={collectibles}
                                        onSelectProject={onSelectProject}
                                      privKey={privKey}
                                      properties={props}
                                    />}
                                  </>
                                }


                                {/*<PortfolioSummary type="h" />*/}
                              </div>
                            </>
                          </div>

                          : <div className="content">
                            <div className="cHeader">
                              <p className="title">Collectible Summary
                                {/*****temporarily hidden*****/}
                                {/*<a onClick={(e) => setState({"view2Summary": !state.view2Summary})} */}
                                {/*   className="pull-right viewallA">*/}
                                {/*  Collectible Portfolio*/}
                                {/*</a>*/}
                              </p>
                            </div>
                            <div className="cBody">
                              <CollectibleSummary project={state.selectedProject}/>
                            </div>
                          </div>
                        }
                      </div>
                      : state.typeChange === "deposit"
                        ? <div className="dashModal PortfolioSummaryWrap">
                          {state.depositType === "none"
                            ? state.verifiedUser === true
                              ? <div className="content" style={{"height": "auto"}}>
                                <div className="cHeader">
                                  <p className="title">Deposit Options
                                    <a onClick={() => setState({
                                      "walletsChangeSummary": !state.walletsChangeSummary
                                    })}
                                       className="pull-right"
                                       style={{"cursor": "pointer"}}>
                                      <i className="fa fa-close"/>
                                    </a>
                                  </p>
                                </div>
                                <div className="cBody">
                                  <div className="bttnContainerTransfer">
                                    <a className="buyNowBttn wireTransferBttn"
                                       onClick={() => setState({
                                         "typeChange": "deposit",
                                         "depositType": "wire"
                                       })}>
                                      Deposit via Wire Transfer
                                    </a>
                                    &emsp;
                                    <a className="buyNowBttn electronicCheckBttn"
                                       onClick={() => setState({
                                         "typeChange": "deposit",
                                         "depositType": "electronic"
                                       })}>
                                      Deposit via Electronic Check
                                    </a>
                                    &emsp;
                                    <a className="buyNowBttn debitCreditBttn"
                                       onClick={() => setState({
                                         "typeChange": "deposit",
                                         "depositType": "card"
                                       })}>
                                      Deposit via Debit/Credit Card
                                    </a>
                                  </div>
                                  <br style={{"clear": "both"}}/>
                                </div>
                              </div>
                              : <div className="content" style={{"height": "auto"}}>
                                <div className="cHeader">
                                  <p className="title">Deposit Options
                                    <a onClick={() => setState({
                                      "walletsChangeSummary": !state.walletsChangeSummary
                                    })}
                                       className="pull-right"
                                       style={{"cursor": "pointer"}}>
                                      <i className="fa fa-close"/>
                                    </a>
                                  </p>
                                </div>
                                <div className="cBody">
                                  <div className="verifyYourAccountAlert">
                                    <p className="title">Account Verification Required</p>
                                    <p className="subtitle">To deposit money using wire
                                      transfer, electronic check or a
                                      debit/credit card, you must verify your
                                      account.</p>
                                    <Link className="buyNowBttn verifyAccount"
                                          to="/settings/compliance">
                                      Verify Account
                                    </Link>
                                  </div>
                                  <br style={{"clear": "both"}}/>
                                </div>
                              </div>
                            : ""
                          }
                          {state.depositType === "wire" ?
                            <div className="content" style={{"height": "auto"}}>
                              <div className="cHeader">
                                <p className="title">Deposit by Wire Transfer
                                  <a onClick={() => setState({
                                    "typeChange": "deposit",
                                    "depositType": "none"
                                  })}
                                     className="pull-right" style={{"cursor": "pointer"}}>
                                    <i className="fa fa-close"/>
                                  </a>
                                </p>
                              </div>
                              <div className="cBody">
                                <DepositDetails/>
                                <br style={{"clear": "both"}}/>
                              </div>
                            </div>
                            : ""
                          }
                          {state.depositType === "electronic"
                            ? <div className="content" style={{"height": "auto"}}>
                              <div className="cHeader">
                                <p className="title">Deposit by Check
                                  <a onClick={() => setState({
                                    "typeChange": "deposit",
                                    "depositType": "none"
                                  })}
                                     className="pull-right" style={{"cursor": "pointer"}}><i
                                    className="fa fa-close"/>
                                  </a>
                                </p>
                              </div>
                              <div className="cBody">
                                <DepositDetails/>
                                <br style={{"clear": "both"}}/>
                              </div>
                            </div>
                            : ""
                          }
                          {state.depositType === "card"
                            ? <div className="content" style={{"height": "auto"}}>
                              <div className="cHeader">
                                <p className="title">Deposit by Credit/Debit Card
                                  <a onClick={() => setState({
                                    "typeChange": "deposit",
                                    "depositType": "none"
                                  })}
                                     className="pull-right" style={{"cursor": "pointer"}}>
                                    <i className="fa fa-close"/>
                                  </a>
                                </p>
                              </div>
                              <div className="cBody">
                                <DepositDetails/>
                                <br style={{"clear": "both"}}/>
                              </div>
                            </div>
                            :
                            ""
                          }
                        </div>
                        : <div className="dashModal PortfolioSummaryWrap">
                          <div className="content" style={{"height": "auto"}}>
                            <div className="cHeader">
                              <p className="title">Send {state.sendCoinSelected}
                                <a onClick={() => setState({
                                  "walletsChangeSummary": !state.walletsChangeSummary
                                })}
                                   className="pull-right" style={{"cursor": "pointer"}}>
                                  <i className="fa fa-close"/>
                                </a>
                              </p>
                            </div>
                            <div className="cBody">
                              <WithdrawalDetails sendtype={state.sendCoinSelected}
                                                 totalLareBalance={state.totalLareBalance}/>
                              <br style={{"clear": "both"}}/>
                             </div>
                          </div>
                        </div>
                    }
                    <div className="dashModal historySummaryWRap collectibleTrades__block">
                      <div className="content tokens-content " style={{
                        "maxHeight": "460px",
                        "boxShadow": "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
                        "borderRadius": "5px"
                      }}>
                        <div className="cHeader">
                          {state.openActive === false
                            ? <p className="title">Trade History
                              <a onClick={handleChangeOpen} className="pull-right viewallA">
                                Collectible Trades
                              </a>
                            </p>
                            : <p className="title">Collectible Trades
                              <a onClick={handleChangeOpen} className="pull-right viewallA">
                                Trade History
                              </a>
                            </p>
                          }
                        </div>
                        <div className="cBody" style={{"maxHeight": "430px", "overflow": "auto"}}>
                          {state.openActive === true
                            ? <CollectiblesTransactionsTable/>
                            : <TradeHistory/>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*<div className="dashModal PortfolioPageWrap mobileVersionShow myWallets__block" style={{"width": "40%"}}>*/}
                  {/*  <div className="content " style={{"height": "auto"}}>*/}
                  {/*    <div className="cHeader">*/}
                  {/*      <p className="title">My Wallets</p>*/}
                  {/*    </div>*/}
                  {/*    <div className="cBody">*/}
                  {/*      <MyCollectibles dummyData={licenses} Projects={Projects}*/}
                  {/*                      handleBoxChange={this.handleBoxChange}*/}
                  {/*                      setActivePage={this.setActivePage}*/}
                  {/*                      getLicenses={this.getLicenses}*/}
                  {/*                      handleSellAllLicenses={this.handleSellAllLicenses}*/}
                  {/*      />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  <br style={{"clear": "both"}}/>
                </div>
              }
            </>
          }
        </>
      </div>
    )
  };

  return render();
};

function mapStateToProps({blockchain}) {
  return {
    // tokens: blockchain.tokens,q2
    // walletAddresses: blockchain.walletAddresses,
    mainWalletAddress: blockchain.mainWalletAddress,
    // projects: blockchain.projects,
    cart: blockchain.cart
  };
}

export default connect(
  mapStateToProps,
  {...blockChainActions}
)(Wallets3);
