import React, { useEffect, useState } from "react";
import Parse from "parse";
import MyTicketsListContainer from './MyTicketsListContainer';
import MyTicketsOrderForm from './MyTicketsOrderForm';
import HistoryBlock from './HistoryBlock';
import OpenOrders from './OpenOrders';
import { chart, header } from "../../Components/Chart/data.json";
import { connect } from "react-redux";
import ethClient from "../../eth_client";
import * as blockchainAction from "../../../modules/blockchain/blockchain.action";
import { sendParseRequest } from "../../../utils/sendParseRequest";
import { message } from "../../../utils/message";
// import {MainHeader} from "../../header";
// import Chart from "./../../Chart/Chart.js";
// import EthApi from "../../../api/eth";
// import {Footer} from "../../Components/Footer/Footer";
// import constants from "../../../eth_client/constants";
// import axios from "axios";
// import MyTokensListContainer from "../CentralToken/MyTokensListContainer";
import axios from 'axios';

const MyTickets = (props) => {
    console.log(props, 'props')
    console.log(props.tickets, '----tickets')
    // let socket;
    // let endpoint = "";
    // let urlcon = '/wallets2/';
    // socket = socketIOClient(endpoint);
    // console.log("my userid", user_id)
    // socketresponse();
    useEffect(() => { console.log(props.tickets) }, [props])

    const user_id = Parse.User.current();

    let TradeDet = {
        buy_total: 0,
        pairurl: "",
        pair_id: "",
        buy_taker_fees: 0,
        buy_maker_fees: 0,
        sell_total: 0,
        sell_taker_fees: 0,
        sell_maker_fees: 0,
        MarkerFees: 0,
        TakerFees: 0,
        buy_amount: '',
        sell_amount: '',
        buy_price: '',
        sell_price: '',
        PairDet: [],
        frombal: 0,
        tobal: 0,
        activeOrder: [],
        userTradeHistory: [],
        usersHistory: [],
        tradeHistory: [],
        PairDetails: [],
        sellOrders: [],
        buyOrders: [],
        sellloader: 0,
        buyloader: 0,
        cancelloader: 0,
        fromcurrencyName: '',
        fromCurrency: '',
        toCurrency: 0,
        fromimg: '',
        price: 0,
        marketprice: 0,
        change: 0,
        high: 0,
        low: 0,
        volume: 0,
    };

    const [selectedItem, setSelectedItem] = useState([]);
    const [activeItem, setActiveItem] = useState([]);
    const [TradeDetails, setTradeDetails] = useState(TradeDet);
    const [axiosTickets, setAxiosTickets] = useState([]);
    // const [TradeTickersDet, setTradeTickersDet] = useState([]);
    let createEmit = 0;

    const Loaddatas = async () => {
        //get ticker symbols from blockchain
        let walletAddress = ethClient.eth.getActiveAccountAddress();

        console.log("walletAddress =============>", walletAddress);
        // props.getAllTicketSaga(walletAddress);
        // props.getFNBDCoinDetailSaga(walletAddress);
        //
        // props.getAavailableFeePercetageLevelsSaga();
        // props.getCreatedOrdersSaga();
        axios({
            method: "get",
            url: `http://3.220.210.43:8081/api/v3/trm/contracts/tickets/getTicket?address=${walletAddress}`,
            auth: {
                username: 'username',
                password: 'password'
            },
        })
            .then(res => {
                console.log(res, "--------------res2222")
                Promise.all(res.data.tickets && res.data.tickets.map((i) => {
                    return sendParseRequest('getInfo', {
                        query: "Projects",
                        equal: [{
                            field: "tickerSymbol", value: i.symbol
                        }],
                        // distinct: "logoAttach"
                    }).then((res) => {
                        console.log(res, 'resoooqa')
                        i.image = res[0];
                        return i
                    });
                })).then(values => {
                    console.log(values, 'valuuuuues')
                    setAxiosTickets(values)
                });
            }
            )
    };
    useEffect(() => {
        Loaddatas();
        getToken();
    }, [props.mainWalletAddress]);

    useEffect(() => {
        axiosTickets.length > 0 && console.log(axiosTickets[0], "----------22222222222222222222222222----axiosTickets")
        // axiosTickets.map((i)=> console.log(i, "--------------i"));

        if (axiosTickets.length > 0) {
            console.log(axiosTickets[0], '44444444444')
            setActiveItem(axiosTickets[0])
        }
    }, [axiosTickets]);



    const getToken = async () => {
        if (window.location.href.toString().split('code=')[1]) {
            let link = window.location.href.toString().split('code=')[1].split('#')[0];
            sendParseRequest('getCheckbookToken', { code: link })
                .then(res => localStorage.setItem('checkbookTokens', JSON.stringify(res)))
        }
    };

    function order_confirm(TradeType, OrderType, OrderDetails) {
        // console.log("order confirmmm", OrderDetails)
        let getAmount = OrderDetails[OrderType + "_amount"];
        let getPrice = OrderDetails[OrderType + "_price"];
        let marketprice = OrderDetails.PairDetails.marketPrice;
        let Orderamount = getAmount;
        let Orderprice = getPrice;
        let getpriceval;

        if (TradeType === "market") {
            getpriceval = marketprice;
        } else {
            getpriceval = Orderprice;
        }
        let multiply = parseFloat(Orderamount) * parseFloat(getpriceval);

        let tot;

        if (multiply > 0) {
            tot = multiply;
        } else {
            tot = 0;
        }

        if ((tot) < (OrderDetails.PairDetails.minTrade)) {
            message('error', 'Total trade amount must be ' + this.minTrade);
            return false;
        }

        let mul = (tot).toFixed(8);

        if (OrderType === "buy") {
            let to_currency1 = OrderDetails.tobal;

            if (parseFloat(mul) > to_currency1) {
                message('error', 'Insufficient balance');
                return false;
            } else {
                executeOrder(TradeType, OrderType, OrderDetails);
            }
        } else {
            let from_currency1 = (OrderDetails.frombal);
            if (from_currency1 < parseFloat(Orderamount)) {
                message('error', 'Insufficient balance');
                return false;
            } else {
                executeOrder(TradeType, OrderType, OrderDetails);
            }
        }
    }

    function executeOrder(TradeType, OrderType, OrderDetails) {
        message('info', 'executeOrder');

        let getAmount = OrderDetails[OrderType + "_amount"];
        let getPrice = OrderDetails[OrderType + "_price"];
        let Orderamount = getAmount;
        let Orderprice = getPrice;
        let OrderType1 = "";
        // calculation(OrderDetails, OrderType, TradeType);

        // let takerFees = OrderDetails.PairDetails.takerFee;
        let price;
        let getresult = OrderDetails.PairDetails;

        if (TradeType === 'market') {
            price = OrderDetails.PairDetails.marketPrice;
            if ((OrderType === 'buy' &&
                OrderDetails.PairDetails.sellOrders.length === 0) ||
                (OrderType === 'sell' && getresult.buyOrders.length === 0)) {
                if (OrderType === 'buy') {
                    OrderType1 = 'Sell';
                } else {
                    OrderType1 = 'Buy';
                }
                message('error', "No " + OrderType1 + " orders available!");
                return false;
            }
        } else {
            price = Orderprice;
        }
        const data = {};
        data.amount = parseFloat(Orderamount);
        data.price = parseFloat(price);
        data.pair = (getresult._id);
        data.ordertype = TradeType;
        data.type = OrderType;
        data.user_id = (user_id);
        OrderDetails[OrderType + "loader"] = 1
        setTradeDetails((prevState) => ({
            ...prevState,
            ...OrderDetails
        }));
        createEmit = 1;
        // console.log("socket for create order", data)
        // socket.emit("createOrder", data);
        unsetdata();
    }

    function unsetdata() {
        // let tradedet = TradeDetails;
        TradeDetails['buy_amount'] = '';
        TradeDetails['buy_price'] = '';
        TradeDetails['buy_maker_fees'] = '';
        TradeDetails['buy_taker_fees'] = '';
        TradeDetails['buy_total'] = '';
        TradeDetails['sell_amount'] = '';
        TradeDetails['sell_price'] = '';
        TradeDetails['sell_maker_fees'] = '';
        TradeDetails['sell_taker_fees'] = '';
        TradeDetails['sell_total'] = '';
        setTradeDetails(TradeDetails)
    }


    async function cancelOrder(orderObj) {
        let walletAddress = ethClient.eth.getActiveAccountAddress();
        props.cancelOrderSaga(walletAddress, "password", orderObj);
    }

    const place_order = (data) => {
        let walletAddress = ethClient.eth.getActiveAccountAddress();
        console.log("place_order ----->", data);

        /*An error occurs because the password is no longer a hardcode!*/
        props.createOrderSaga(walletAddress, "password", data);
    }

    /*    async function getLogos(ticket) {
            let tixArr = [];
            ticket.map((tix) => {
                tixArr.push(tix.symbol)
            });

            Parse.Cloud.run('getTickerLogos', {
                tickets: ticket
                // userId: userId,
                // projectId: projectId,
                // reportTarget: reportTarget,
                // targetField: targetField,
            }, {
                success: res => {
                    // console.log(res)
                }
            })

            // setActiveItem(ticket[0])
            // setActiveItem(tokensStatic)
        }

        function roundsof(n, decimal) {
            return (+n).toFixed(decimal)
        }*/

    function updateCurrentPair() {
        let detailspair = TradeDetails.PairDetails;
        detailspair['fromCurrency'] = detailspair.fromCurrency;
        detailspair['toCurrency'] = detailspair.toCurrency;
        detailspair['fromcurrencyName'] = detailspair.fromcurrencyName;
        detailspair['tocurrencyName'] = detailspair.tocurrencyName;
        detailspair['price'] = detailspair.price;
        detailspair['marketprice'] = detailspair.marketprice;
        detailspair['change'] = detailspair.change;
        detailspair['high'] = detailspair.high;
        detailspair['low'] = detailspair.low;
        detailspair['volume'] = detailspair.volume;
        detailspair['sellOrders'] = detailspair.sellOrders;
        detailspair['buyOrders'] = detailspair.buyOrders;
        detailspair['usersHistory'] = detailspair.usersHistory;
        detailspair['tradeHistory'] = detailspair.tradeHistory;
        detailspair['MarkerFees'] = detailspair.MarkerFees;
        detailspair['takerFee'] = detailspair.takerFee;

        setTradeDetails(detailspair)
    }

    function updateUserHistory() {

        let details = TradeDetails;

        const userTradeHistory = details['userTradeHistory'];
        let usersHistorys = [];
        let j = 0;
        for (let i = 0; i < userTradeHistory.length; i++) {
            if (user_id === userTradeHistory[i].buyerUserid) {
                usersHistorys[j] = {
                    datetime: userTradeHistory[i].datetime,
                    type: 'Buy',
                    filledAmount: userTradeHistory[i].filledAmount,
                    tradePrice: userTradeHistory[i].tradePrice,
                    fee: userTradeHistory[i].buyFee
                };
                j++;
            }
            if (user_id === userTradeHistory[i].sellerUserid) {
                usersHistorys[j] = {
                    datetime: userTradeHistory[i].datetime,
                    type: 'Sell',
                    filledAmount: userTradeHistory[i].filledAmount,
                    tradePrice: userTradeHistory[i].tradePrice,
                    fee: userTradeHistory[i].sellFee
                };
                j++;
            }
        }
        details.PairDetails['usersHistory'] = usersHistorys;
        setTradeDetails(details)
    }

    function updatePairDetails(val) {
        let allpairs = JSON.parse(localStorage.getItem('allPairs'));
        let emparray = [];
        emparray = allpairs;

        let details = TradeDetails;
        details['PairDet'] = emparray;
        setTradeDetails(details)
    }

    const chartsHeader = {
        ...header,
        logo: "selectedItem.logo",
        title: "selectedItem.tickerSymbol"
    };
    const dataForChart = chart['1d4h5j'];

    function onItemSelect(newSelectedItem) {
        // console.log("newSelectedItem ====>", newSelectedItem);
        // TradeDetails['PairDetails'] = newSelectedItem;
        setActiveItem(newSelectedItem)
        // props.history.push(urlcon + newSelectedItem.pair);
    }

    function order_placed(OrderDetails, OrderType, TradeType) {
        if (!user_id) {
            message('error', 'Please Login to continue');
            return false;
        }
        let getAmount = OrderDetails[OrderType + "_amount"];
        let getPrice = OrderDetails[OrderType + "_price"];
        let Orderamount = getAmount;
        let Orderprice = getPrice;
        let totget = OrderDetails[OrderType + "_total"]

        if (TradeType !== 'market') {

            if (isNaN(Orderamount) || isNaN(totget)) {
                message('error', 'Please enter valid amount and price');
                return false;

            } else if (Orderamount === "" || Orderamount <= 0 || totget <= 0) {
                message('error', 'Please enter valid amount and price');
                return false;
            }
        } else {

            if (isNaN(Orderamount)) {
                message('error', 'Please enter valid amount');
                return false;
            } else if (Orderamount === "" || Orderamount <= 0) {
                message('error', 'Please enter valid amount and price');
                return false;
            }
        }
        order_confirm(TradeType, OrderType, OrderDetails);
    }

    // function socketresponse() {
    //     socket.on("createResponse", function (res) {
    //         let details = TradeDetails;
    //         let pair_id = details['pair_id'];
    //         if (res.user_id == user_id && res.pair == pair_id && createEmit == 1) {
    //             createEmit = 0;
    //             if (res.status == 1) {
    //                 alert(res.msg);
    //             } else {
    //                 alert(res.msg);
    //             }
    //             details['buyloader'] = 0;
    //             details['sellloader'] = 0;
    //
    //             setTradeDetails((prevState) => ({
    //                 ...prevState,
    //                 ...details
    //             }));
    //         }
    //     }.bind(this));
    // }

    return (
        <div className="page page--myTickets">
            <div className="myTicketsPageBody">
                <div className="contentBodyContainer">
                    <div className="myTicketsLeftSide">
                        {axiosTickets.length > 0 && <MyTicketsListContainer
                            fnbCoin={props.fnbd}
                            tickets={axiosTickets}
                            activeItem={activeItem}
                            onItemSelect={onItemSelect}
                            walletAddress={props.mainWalletAddress}
                        />
                        }
                    </div>
                    <div className="myTicketsRightSide">
                        <div className="myTicketsChart">
                            {/*<Chart header={chartsHeader}*/}
                            {/*       Chart={dataForChart}*/}
                            {/*       footer={footer}*/}
                            {/*       chartOf={selectedItem.id}*/}
                            {/*       selectedpair={selectedItem}/>*/}

                            <OpenOrders cancelOrder={cancelOrder} orders={props.orders} activeItem={activeItem}
                                percetnageLevels={props.percetnageLevels} />
                        </div>

                        <div className="myTicketsRightSide__wrapperOfBottomBlocks">
                            <MyTicketsOrderForm selectedItem={selectedItem}
                                activeItem={activeItem}
                                order_place={place_order}
                                percetnageLevels={props.percetnageLevels}
                                fnbd={props.fnbd}
                            />
                            <HistoryBlock selectedItem={selectedItem} TradeDetails={TradeDetails} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps({ blockchain }) {
    return {
        mainWalletAddress: blockchain.mainWalletAddress,
        percetnageLevels: blockchain.percetnageLevels,
        fnbd: blockchain.fnbd,
        tickets: blockchain.tickets,
        orders: blockchain.orders
    };
}

export default connect(
    mapStateToProps,
    { ...blockchainAction }
)(MyTickets);
