import React, {useState} from 'react';
// import useIpfs from '../../../hooks/useIpfs';

import uploadIcon from '../../../assets/images/upload-button.png';
import checkMarkIcon from '../../../assets/images/tick.svg';

const UploadAttachItemToIpfs = ({setAttachItem, attachItem, attachFileType, name, node}) => {
    console.log(node, '===NODENODENODENODENODNODE')
    // const {ipfs, ipfsInitError} = useIpfs({commands: ['id']});
    //
    // const addToIpfs = async (file) => {
    //     console.log(111, "--------------111")
    //     for await (const inputFile of ipfs.add({ path: 'randompath.txt', content: file })) {
    //         console.log(inputFile.cid.toString(), 'inputFile.cid.toString()')
    //         return inputFile.cid.toString()
    //
    //     }
    // };
    //
    // const onFileDownload = (evt) => {
    //     const currEl = evt.target;
    //     const file = currEl.files[0];
    //
    //     if (!file) {
    //         setAttachItem({
    //             file: '',
    //             addToIpfs,
    //             name: name
    //         });
    //     }
    //
    //     setAttachItem({
    //         file,
    //         addToIpfs,
    //         tab: "projectAttach"
    //     });
    // };







    const [sizeWarning, setSizeWarning] = useState(false);

    const addToIpfs = async (file) => {
console.log(111111, "--------------111111")
        const {path} = await node.add(file);
        if (path) {
            console.log(path);
            // await node.stop();
            // setSNode(false)
            return path
        }
    };

    const onFileDownload = async (evt) => {
        if (evt.target.files[0] && node) {
            let file = evt.target.files[0];
            setAttachItem({
                file,
                addToIpfs,
                tab: "projectAttach"
            });
        }
    };
    return (

        <div className="section-input-wrap">
            <div className="uploadItems-wrapper additional-attach-download" style={{display: 'block'}}>
                <label className={(attachFileType || !attachItem)  ? "" : "creator_label-attach"}>
                    <input type="file"
                           id="test123"
                           hidden={true}
                           onChange={onFileDownload}
                           name="attachItem"
                           data-isrequiredfield={false}
                           data-action="compare"
                           data-valueforcheck=""/>

                    {attachFileType || !attachItem ? <img src={!attachItem ? uploadIcon : checkMarkIcon} alt="icon"/>
                        : <div className="creator_label-incorrect"> Incorrect format, please download pdf <br/>
                            <img src={uploadIcon} alt="icon"/>
                        </div>}
                </label>
                Click to add a pdf
            </div>
        </div>
    );
};

export default UploadAttachItemToIpfs;
