import axios from "axios";
import constants from "../../constant";

const auth = constants.auth;

class Tickets {

    constructor(){
        
    }

    async getMyTickets(address){
        const result = await axios({
            method:"get",
            url:`${constants.apiRoot}/contracts/tickets/getTicket`,
            auth,
            params: {
                address
            }
        })   
        return result.data.tickets;
    }


}

export default new Tickets();