import React from "react";
import InfoTooltip from "../../../Components/InfoTooltip";
import {
    portfolioDWTitle,
    portfolioTab,
    portfolioTabDepositTickers,
    portfolioTabTitle,
    portfolioTabWithdrawTickets,
    withdrawToken
} from "../MyTicketsData";
import closeSvg from "../../../../assets/images/close--blue.svg";

const MyTicketsWithdrawModal = ({withDraw,
                                       selectedCurrency,
                                       selectCurrency,
                                       activeTabPop,
                                       walletAddress,
                                       addValue,
                                       amount,
                                       balances,
                                       activeTabPortfolio,
                                       setCurrentTab,
                                       popupPage,
                                       closeModal,
                                       statusPopup
                                   }) => {



let fee=0;
    return (
        statusPopup && popupPage === "withdraw" &&
        <div className="slideshowModal popup_token-block">
            <button className="slideshowModalWrapper__closeButton slideshowModalButton">
                <img src={closeSvg} alt="close" onClick={() => closeModal(false, null)}/>
            </button>
            <div className=" popup-withdraw popup_tokens">
                <div className={`myTicketsListContainer__header myTickets--title ${activeTabPop}`}>
                    <button className={`btn-tab ${(activeTabPop === "Withdraw") ? "active" : ""}`}
                            onClick={() => setCurrentTab("Withdraw", 'pop')}>
                        Withdraw
                    </button>
                    <button className={`btn-tab ${(activeTabPop === "portfolio") ? "active" : ""}`}
                            onClick={() => setCurrentTab("portfolio", 'pop')}>
                        PORTFOLIO
                    </button>
                </div>
                {activeTabPop === 'Withdraw'
                    ?
                    <div className="withDraw-flex">
                        Fanbase Account
                        <select onChange={(e) => selectCurrency(e.target.value)}>
                            <option>USDW</option>

                        </select>
                        <div className="wallets-block">
                            From
                            <InfoTooltip position="top"
                                         message="Convert USDW to USD and withdraw">
                                <i className="fa fa-info-circle"/>
                            </InfoTooltip>
                            <p>{walletAddress}</p>
                        </div>
                        <div className="wallets-block">
                            To
                            <InfoTooltip position="top"
                                         message="User input bank account.
User input routing number
">
                                <i className="fa fa-info-circle"/>
                            </InfoTooltip>
                            <input className="popup-max-inpt-full"
                                   placeholder="Copy and paste destination address"/>
                        </div>
                        <div className="token_popup-inputs">
                            <div className="token_popup-amounts ">
                                <button className="max-btn popup-max">MAX</button>
                                Amount
                                <input className="popup-max-inpt" placeholder={selectedCurrency}
                                       onChange={(e) => addValue(e)}/>
                            </div>
                            <div className="token_popup-amounts">
                                Amount {amount} USD/USDW
                            </div>
                        </div>
                        <div className="popup_tokens-texts">
                            <div className="popup_tokens-text">
                                <p>Fee</p>
                                <p>0.0000</p>
                            </div>
                        </div>
                        <div className="popup_tokens-line"/>
                        <div className="popup_tokens-text">
                            <p>Available to withdraw</p>
                            <p>{balances[withdrawToken]}</p>
                        </div>
                        {/*<input */}
                        {/*    onChange={(e) => changeAmount(e)} */}
                        {/*    type='number' minLength="3"/>*/}
                        {/*<p>Fee is:{depositFee} </p>*/}
                        {/*<p>You will pay: {depositTotalAmount} </p>*/}
                        {/*{depositAmount}*/}

                        <div className="mt-3 popup_tickets-flex">
                            <button type="button" className="popup_tokens-btn"
                                    // disabled={isLoading} onClick={() => connectCheckbook()}
                            >
                                Connect Checkbook Api
                            </button>

                            <button type="button" className="popup_tokens-btn"
                                     onClick={() => withDraw(balances[withdrawToken],fee, 'withdraw')}
                            >
                                Withdraw ETH
                            </button>
                        </div>
                        {/*<button className="popup_tokens-btn"> Withdraw ETH</button>*/}
                    </div>
                    : <div className="portfolio-popup">
                        <div className="portfolio-tab-block">
                            <button className={activeTabPortfolio === "Balances"
                                ? "portfolio-btn-active"
                                : "portfolio-tab-btn"}
                                    onClick={() => setCurrentTab("Balances", 'portfolio')}>
                                Balances
                            </button>
                            <button className={activeTabPortfolio === "Deposits"
                                ? "portfolio-btn-active"
                                : "portfolio-tab-btn"}
                                    onClick={() => setCurrentTab("Deposits", 'portfolio')}>
                                Deposits
                            </button>
                            <button className={activeTabPortfolio === "Withdraws"
                                ? "portfolio-btn-active"
                                : "portfolio-tab-btn"}
                                    onClick={() => setCurrentTab("Withdraws", 'portfolio')}>
                                Withdraws
                            </button>
                        </div>
                        <table className="portfolio-table">
                            {activeTabPortfolio === "Balances" &&
                            portfolioTabTitle.map((i) =>
                                <td className="portfolio-table-title"><p>{i}</p></td>)
                            }
                            {activeTabPortfolio === "Deposits" &&
                            portfolioDWTitle.map((i) =>
                                <td className="portfolio-table-title"><p>{i}</p></td>)
                            }
                            {activeTabPortfolio === "Withdraws" &&
                            portfolioDWTitle.map((i) =>
                                <td className="portfolio-table-title"><p>{i}</p></td>)
                            }
                            {activeTabPortfolio === "Balances" && portfolioTab.map((i) =>
                                <tr>
                                    <td><p>{i.Asset}</p></td>
                                    <td><p>{i.Holdings}%</p></td>
                                    <td><p>${i.Balance}</p></td>
                                    <td><p>{i.Available}</p></td>
                                    <td><p>${i.Price}</p></td>
                                </tr>)
                            }
                            {activeTabPortfolio === "Deposits" && portfolioTabDepositTickers.map((i) =>
                                <tr>
                                    <td><p>{i.Date}</p></td>
                                    <td><p>{i.Asset}</p></td>
                                    <td><p>{i.Quantity}</p></td>
                                    <td><p>${i.Amount}</p></td>
                                </tr>)
                            }
                            {activeTabPortfolio === "Withdraws" && portfolioTabWithdrawTickets.map((i) =>
                                <tr>
                                    <td><p>{i.Date}</p></td>
                                    <td><p>{i.Asset}</p></td>
                                    <td><p>{i.Quantity}</p></td>
                                    <td><p>${i.Amount}</p></td>
                                </tr>)
                            }
                        </table>
                    </div>}
            </div>
            }
        </div>
    );
};

export default MyTicketsWithdrawModal;
