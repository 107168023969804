import React, {Component} from 'react';
import './layout.css';
import ShowImage from './showImages';
import ViewImage from './viewImages';
import Parse from "parse";

class ImagesLayout extends Component {
  imageWrapperEl = React.createRef();

  state = {
    isViewImage: false,
    bannerImages: [],
    startImageMove: false,
    showSpinner: true,
    selectedImage: ''
  };

  onViewImage = (selectedImage) => {
    this.setState({isViewImage: true, selectedImage});
  };

  onCloseViewImage = () => {
    this.setState({isViewImage: false});
  };

  onDeleteImg = (idx) => {
    this.setState(({bannerImages}) => ({
      bannerImages: [
        ...bannerImages.slice(0, idx),
        'deleted',
        ...bannerImages.slice(idx + 1),
      ]
    }));
  };

  saveImagesInDb = (newImages) => {
    const Projects = Parse.Object.extend("Projects");
    const projectQuery = new Parse.Query(Projects);

    projectQuery.get(this.props.projectID)
      .then(project => {
        project.set('promotionalBanners', newImages);
        project.save();
      });
  };

  saveImagesAfterMoving = () => {
    const {bannerImages} = this.state;
    const imageElements = this.imageWrapperEl.current.querySelectorAll('.image');
    let isSame = true;
    let newImages = [];

    if (bannerImages.length !== imageElements.length) return;

    imageElements.forEach((img, idx) => {
      isSame = isSame ? img.currentSrc === bannerImages[idx] : false;
      newImages.push(img.currentSrc);
    });

    if (!isSame) {
      this.saveImagesInDb(newImages);

      this.setState({startImageMove: false});
    } else {
      this.setState({startImageMove: false});
    }
  };

  onChangeImage = (idx, url) => {
    const {bannerImages} = this.state;
    const newBannerImages = [
      ...bannerImages.slice(0, idx),
      url,
      ...bannerImages.slice(idx + 1),
    ];

    this.saveImagesInDb(newBannerImages);

    this.setState({
      bannerImages: newBannerImages
    });
  };

  setStateByPropertyName = (propName, value) => {
    this.setState({
      [propName]: value
    });
  };

  onMoveImage = (evt) => {
    evt.preventDefault();

    const {startImageMove} = this.state;
    const draggableImg = evt.target.parentElement;

    if (!startImageMove || !draggableImg.classList.contains('showImageContainer')) return false;

    const currImageWrapper = evt.currentTarget;
    const initCurrImageStyles = {
      position: draggableImg.style.position,
      width: draggableImg.style.width,
      height: draggableImg.style.height,
      maxWidth: draggableImg.style.maxWidth,
      maxHeight: draggableImg.style.maxHeight,
      minWidth: draggableImg.style.minWidth,
      minHeight: draggableImg.style.minHeight,
      cursor: draggableImg.style.cursor,
      zIndex: draggableImg.style.zIndex,
    };
    let nextParentEl = draggableImg.parentElement;
    const initialParentEl = draggableImg.parentElement;
    let elForChange = draggableImg;

    currImageWrapper.classList.add('startDrag');

    currImageWrapper.appendChild(draggableImg);
    draggableImg.style.position = 'absolute';
    draggableImg.style.width = '300px';
    draggableImg.style.height = '200px';
    draggableImg.style.minWidth = '0';
    draggableImg.style.minHeight = '0';
    draggableImg.style.zIndex = '-1';

    const onImageMove = (evt) => {
      evt.preventDefault();

      draggableImg.style.left = evt.pageX - draggableImg.offsetWidth / 2 + 'px';
      draggableImg.style.top = evt.pageY - draggableImg.offsetHeight / 2 + 'px';
    };

    const onDropImg = () => {
      initialParentEl.appendChild(elForChange);
      nextParentEl.appendChild(draggableImg);

      draggableImg.style = {
        ...draggableImg.style,
        ...initCurrImageStyles
      };

      currImageWrapper.classList.remove('startDrag');

      clearListener();
    };

    const onMouseOver = (evt) => {
      const elUnderCursor = evt.target.parentElement;

      if (elUnderCursor !== draggableImg && elUnderCursor.classList.contains('showImageContainer')) {
        if (nextParentEl !== initialParentEl) {
          nextParentEl.appendChild(elForChange);
        }

        if (elUnderCursor === elForChange) {
          nextParentEl.appendChild(elForChange);

          nextParentEl = initialParentEl;
          elForChange = draggableImg;

          return;
        }

        nextParentEl = elUnderCursor.parentElement;

        initialParentEl.appendChild(elUnderCursor);

        elForChange = elUnderCursor;
      }
    };

    const clearListener = () => {
      document.removeEventListener('mousemove', onImageMove);
      currImageWrapper.removeEventListener('mouseover', onMouseOver);
      document.removeEventListener('mouseup', onDropImg);
    };

    document.addEventListener('mousemove', onImageMove);
    currImageWrapper.addEventListener('mouseover', onMouseOver);
    document.addEventListener('mouseup', onDropImg);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const bannerImagesLength = this.state.bannerImages.length;
    const {bannerImages: newBannerImages} = this.props;

    if (bannerImagesLength < 5 && newBannerImages.length >= 5) {
      this.setState({
        showSpinner: false,
        bannerImages: newBannerImages
      });
    }
  }

  render() {
    const {
      showSpinner,
      isViewImage,
      bannerImages,
      startImageMove,
      selectedImage
    } = this.state;
    const {isOwner} = this.props;
    return (
      <div className='main imagePreviewWrapper'>
        <div>
          {showSpinner
            ? <div className='image-spinner-frame'>
              <div className="loader__blue">Loading...</div>
            </div>
            : null}
          <div onMouseDown={(evt) => startImageMove ? this.onMoveImage(evt) : null}
               className="imagePreview"
               ref={this.imageWrapperEl}>
            <div className="imagePreviewFirstWrapper">
              <ShowImage source={bannerImages[0]}
                         isOwner={isOwner}
                         saveImages={this.saveImagesAfterMoving}
                         onChangeImg={this.onChangeImage}
                         isDraggable={startImageMove}
                         setStateByPropName={this.setStateByPropertyName}
                         imageIdx={0}
                         onDeleteImg={this.onDeleteImg}
                         onImageClick={this.onViewImage}
                         classCss="imagePreviewFirst"/>
            </div>
            <div className="imagePreviewSecondWrapper">
              <ShowImage source={bannerImages[1]}
                         isOwner={isOwner}
                         saveImages={this.saveImagesAfterMoving}
                         imageIdx={1}
                         isDraggable={startImageMove}
                         setStateByPropName={this.setStateByPropertyName}
                         onChangeImg={this.onChangeImage}
                         onDeleteImg={this.onDeleteImg}
                         onImageClick={this.onViewImage}
                         classCss="imagePreviewSecond"/>

              <ShowImage source={bannerImages[2]}
                         isOwner={isOwner}
                         saveImages={this.saveImagesAfterMoving}
                         imageIdx={2}
                         isDraggable={startImageMove}
                         setStateByPropName={this.setStateByPropertyName}
                         onChangeImg={this.onChangeImage}
                         onDeleteImg={this.onDeleteImg}
                         onImageClick={this.onViewImage}
                         classCss="imagePreviewSecond"/>
            </div>
            <div className="imagePreviewThirdWrapper">
              <ShowImage source={bannerImages[3]}
                         isOwner={isOwner}
                         saveImages={this.saveImagesAfterMoving}
                         imageIdx={3}
                         isDraggable={startImageMove}
                         setStateByPropName={this.setStateByPropertyName}
                         onChangeImg={this.onChangeImage}
                         onDeleteImg={this.onDeleteImg}
                         onImageClick={this.onViewImage}
                         classCss="imagePreviewSecond"/>

              <ShowImage source={bannerImages[4]}
                         isOwner={isOwner}
                         saveImages={this.saveImagesAfterMoving}
                         imageIdx={4}
                         isDraggable={startImageMove}
                         setStateByPropName={this.setStateByPropertyName}
                         onChangeImg={this.onChangeImage}
                         onDeleteImg={this.onDeleteImg}
                         onImageClick={this.onViewImage}
                         classCss="imagePreviewSecond"/>
            </div>
          </div>
        </div>
        {isViewImage &&
        <ViewImage sources={bannerImages} selectedImg={selectedImage} onClose={this.onCloseViewImage} onDeleteImg={this.onDeleteImg} onChangeImage={this.onChangeImage}/>}
      </div>
    )
  }
}

export default ImagesLayout;