import React, {useEffect, useRef, useState} from 'react';
import Parse from "parse";
import isEmail from "../../../../utils/isEmail";
import {Link} from "react-router-dom";
import {message} from "../../../../utils/message";
import {useHistory} from "react-router";

export const ForgotPassword = () => {
    const history = useHistory();
    const loginButton = useRef();
    const emailInput = useRef();

    const [email, setEmail] = useState('');

    const setUserData = (value) => {
        emailInput.current.style.backgroundColor = "white";
        setEmail(value)
    }

    useEffect(() => {
        if (Parse.User.current()) history.push("/")
    }, []);

    const _handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSubmit()
    }

    const handleSubmit = () => {
        loginButton.current.innerHTML = "<span class='spinner'/>";
        loginButton.current.classList.add('loadingState');

        if (!isEmail(email)) {
            emailInput.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";

            message('error', 'Your email is invalid');

            loginButton.current.innerText = "Reset Password";
            loginButton.current.classList.remove('loadingState');
        } else {
            Parse.User.requestPasswordReset(email)
                .then(() => {
                    loginButton.current.innerText = "Success";
                    message('success', 'We sent you an email.')
                })
                .catch(err => {
                    loginButton.current.innerText = "Reset Password";
                    loginButton.current.classList.remove('loadingState');
                    message('error', err.message)
                })
        }
    }

    return (
        <div className="loginScreen">
            <div className="screenWrapper">
                <p className="mainTitle">Forgot Password</p>
                <div className="loginWrap" style={{"height": "170px"}}>
                    <input ref={emailInput}
                           onKeyPress={_handleKeyPress}
                           onChange={e => setUserData(e.target.value)}
                           type="text"
                           placeholder="Email Address"
                    />
                    <a style={{"width": "100%", "textAlign": "center"}}
                       className="loadingButton"
                       ref={loginButton}
                       onClick={handleSubmit}>
                        Reset Password
                    </a>
                </div>
                <div className="extraSigns">
                    <Link className="pull-right" to='/login'>Back to login.</Link>
                </div>
            </div>
        </div>
    );
}
