import ETH from "../../../assets/images/logos/eth.png";
import BAT from "../../../assets/images/logos/bat.png";
import LINK from "../../../assets/images/logos/link.png";
import USDC from "../../../assets/images/logos/usdc.png";
import ZRX from "../../../assets/images/logos/zrx.png";
import LOOM from "../../../assets/images/logos/loom.png";
import REP from "../../../assets/images/logos/rep.png";

export const pk = '0x';
export const contractAddress = '0x6D2fC034e6133a3Dc729a105a007c98C433a976b';
export const deposit_amt = 1;
export const withdrawToken = "ETH";
export const depositJson = [
    {
        "constant": false,
        "inputs": [{"internalType": "uint256", "name": "_fromAmount", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "_toAmount",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "_makerPercentageAmount", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "_takerPercentageAmount",
            "type": "uint256"
        }],
        "name": "setPercentage",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [],
        "name": "orderId",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "name": "order",
        "outputs": [{"internalType": "uint256", "name": "orderID", "type": "uint256"}, {
            "internalType": "address",
            "name": "userAddress",
            "type": "address"
        }, {"internalType": "address", "name": "tokenAddress", "type": "address"}, {
            "internalType": "string",
            "name": "pair",
            "type": "string"
        }, {"internalType": "uint256", "name": "quantity", "type": "uint256"}, {
            "internalType": "uint8",
            "name": "Type",
            "type": "uint8"
        }, {"internalType": "uint256", "name": "tradeAmount", "type": "uint256"}, {
            "internalType": "uint8",
            "name": "flag",
            "type": "uint8"
        }, {"internalType": "uint8", "name": "status", "type": "uint8"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"internalType": "address payable", "name": "_feeAddress", "type": "address"}],
        "name": "changeFeeAddress",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [],
        "name": "feeAddress",
        "outputs": [{"internalType": "address", "name": "", "type": "address"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"internalType": "address", "name": "_tokenAddress", "type": "address"}, {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
        }],
        "name": "deposit",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": true,
        "stateMutability": "payable",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"internalType": "address", "name": "_tokenAddress", "type": "address"}, {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
        }],
        "name": "adminProfitWithdraw",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"internalType": "uint256", "name": "_orderID", "type": "uint256"}],
        "name": "cancelOrder",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"internalType": "address", "name": "_userAddress", "type": "address"}, {
            "internalType": "address",
            "name": "_tokenAddress",
            "type": "address"
        }, {"internalType": "string", "name": "_pair", "type": "string"}, {
            "internalType": "uint256",
            "name": "_quantity",
            "type": "uint256"
        }, {"internalType": "uint256[2]", "name": "_price", "type": "uint256[2]"}, {
            "internalType": "uint8",
            "name": "_type",
            "type": "uint8"
        }, {"internalType": "uint8", "name": "_flag", "type": "uint8"}],
        "name": "createOrder",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"internalType": "uint256", "name": "_makerOrderID", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "_takerOrderID",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "_marketPrice", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "_usdPrice",
            "type": "uint256"
        }],
        "name": "exchange",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [],
        "name": "percentID",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [{"internalType": "address", "name": "", "type": "address"}],
        "name": "adminFee",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [{"internalType": "address", "name": "", "type": "address"}, {
            "internalType": "address",
            "name": "",
            "type": "address"
        }],
        "name": "userBalance",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [{"internalType": "address", "name": "_userAddress", "type": "address"}],
        "name": "getUserOrders",
        "outputs": [{"internalType": "uint256[]", "name": "", "type": "uint256[]"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [{"internalType": "address", "name": "", "type": "address"}],
        "name": "token",
        "outputs": [{"internalType": "address", "name": "tokenAddress", "type": "address"}, {
            "internalType": "string",
            "name": "symbol",
            "type": "string"
        }, {"internalType": "uint256", "name": "decimals", "type": "uint256"}, {
            "internalType": "bool",
            "name": "status",
            "type": "bool"
        }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"internalType": "uint256", "name": "_percentID", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "_fromAmount",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "_toAmount", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "_makerPercentageAmount",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "_takerPercentageAmount", "type": "uint256"}],
        "name": "updatePercentage",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"internalType": "address payable", "name": "_newAdmin", "type": "address"}],
        "name": "changeAdmin",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "name": "percentageCalc",
        "outputs": [{"internalType": "uint256", "name": "percentageID", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "fromAmount",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "toAmount", "type": "uint256"}, {
            "internalType": "uint256",
            "name": "makerPercentageAmount",
            "type": "uint256"
        }, {"internalType": "uint256", "name": "takerPercentageAmount", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [{"internalType": "address", "name": "_userAddress", "type": "address"}, {
            "internalType": "address",
            "name": "_tokenAddress",
            "type": "address"
        }],
        "name": "userTokenBalance",
        "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"internalType": "address", "name": "_tokenAddress", "type": "address"}, {
            "internalType": "string",
            "name": "_symbol",
            "type": "string"
        }, {"internalType": "uint256", "name": "_decimals", "type": "uint256"}],
        "name": "addToken",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"internalType": "address", "name": "_tokenAddress", "type": "address"}, {
            "internalType": "uint256",
            "name": "_amount",
            "type": "uint256"
        }],
        "name": "withdraw",
        "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [],
        "name": "admin",
        "outputs": [{"internalType": "address payable", "name": "", "type": "address"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"internalType": "address payable", "name": "_feeAddress", "type": "address"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "constructor"
    }, {
        "anonymous": false,
        "inputs": [{
            "indexed": true,
            "internalType": "address",
            "name": "tokenAddress",
            "type": "address"
        }, {"indexed": true, "internalType": "string", "name": "symbol", "type": "string"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "decimal",
            "type": "uint256"
        }],
        "name": "Tokens",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "userAddress", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "token",
            "type": "address"
        }, {"indexed": false, "internalType": "uint256", "name": "deposited", "type": "uint256"}],
        "name": "Deposit",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "internalType": "address", "name": "userAddress", "type": "address"}, {
            "indexed": true,
            "internalType": "address",
            "name": "token",
            "type": "address"
        }, {"indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256"}],
        "name": "Withdraw",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{
            "indexed": true,
            "internalType": "address",
            "name": "adminAddress",
            "type": "address"
        }, {"indexed": true, "internalType": "address", "name": "token", "type": "address"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
        }],
        "name": "FeeWithdraw",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": false, "internalType": "uint256", "name": "orderID", "type": "uint256"}, {
            "indexed": true,
            "internalType": "address",
            "name": "userAddress",
            "type": "address"
        }, {"indexed": true, "internalType": "address", "name": "tokenAddress", "type": "address"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "quantity",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "Type", "type": "uint256"}, {
            "indexed": true,
            "internalType": "uint256",
            "name": "flag",
            "type": "uint256"
        }],
        "name": "Order",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": false, "internalType": "uint256", "name": "orderID", "type": "uint256"}, {
            "indexed": true,
            "internalType": "address",
            "name": "userAddress",
            "type": "address"
        }, {"indexed": true, "internalType": "address", "name": "tokenAddress", "type": "address"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "tradeAmount",
            "type": "uint256"
        }, {"indexed": false, "internalType": "uint256", "name": "adminFee", "type": "uint256"}, {
            "indexed": false,
            "internalType": "uint256",
            "name": "pairOrderID",
            "type": "uint256"
        }],
        "name": "Exchange",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": false, "internalType": "uint256", "name": "orderID", "type": "uint256"}],
        "name": "CancelOrder",
        "type": "event"
    }];
export const approveJson = [
    {
        "constant": true,
        "inputs": [],
        "name": "name",
        "outputs": [{"name": "", "type": "string"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"name": "_spender", "type": "address"}, {"name": "_value", "type": "uint256"}],
        "name": "approve",
        "outputs": [{"name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [],
        "name": "totalSupply",
        "outputs": [{"name": "", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"name": "_from", "type": "address"}, {"name": "_to", "type": "address"}, {
            "name": "_value",
            "type": "uint256"
        }],
        "name": "transferFrom",
        "outputs": [{"name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [],
        "name": "INITIAL_SUPPLY",
        "outputs": [{"name": "", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [],
        "name": "decimals",
        "outputs": [{"name": "", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"name": "_value", "type": "uint256"}],
        "name": "burn",
        "outputs": [{"name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [{"name": "_owner", "type": "address"}],
        "name": "balanceOf",
        "outputs": [{"name": "balance", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"name": "_from", "type": "address"}, {"name": "_value", "type": "uint256"}],
        "name": "burnFrom",
        "outputs": [{"name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [],
        "name": "symbol",
        "outputs": [{"name": "", "type": "string"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "constant": false,
        "inputs": [{"name": "_to", "type": "address"}, {"name": "_value", "type": "uint256"}],
        "name": "transfer",
        "outputs": [{"name": "", "type": "bool"}],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }, {
        "constant": true,
        "inputs": [{"name": "_owner", "type": "address"}, {"name": "_spender", "type": "address"}],
        "name": "allowance",
        "outputs": [{"name": "remaining", "type": "uint256"}],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    }, {
        "inputs": [{"name": "_name", "type": "string"}, {"name": "_symbol", "type": "string"}, {
            "name": "_decimals",
            "type": "uint256"
        }], "payable": false, "stateMutability": "nonpayable", "type": "constructor"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "name": "_burner", "type": "address"}, {
            "indexed": false,
            "name": "_value",
            "type": "uint256"
        }],
        "name": "Burn",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "name": "owner", "type": "address"}, {
            "indexed": true,
            "name": "spender",
            "type": "address"
        }, {"indexed": false, "name": "value", "type": "uint256"}],
        "name": "Approval",
        "type": "event"
    }, {
        "anonymous": false,
        "inputs": [{"indexed": true, "name": "from", "type": "address"}, {
            "indexed": true,
            "name": "to",
            "type": "address"
        }, {"indexed": false, "name": "value", "type": "uint256"}],
        "name": "Transfer",
        "type": "event"
    }];
export const getPrice = 214;
export const portfolioTabTitle = [
    "Asset",
    "Holdings",
    "Balance",
    "Available",
    "Price",
];
export const portfolioDepositTitle = [" Date/Time", "Asset", "Quantity", "Price", "Amount Bought"];
export const portfolioDWTitle = [" Date/Time", "Asset", "Quantity", "Price", "Amount Sold"];
/*export const portfolioTabDeposit = [
    {"Date": "  31 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  20 March 3:00 pm", "Asset": "BTC", "Quantity": "430 BTC", "Amount": 1300.16},
    {"Date": "  19 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 300.16},
    {"Date": "  16 March 3:00 pm", "Asset": "ZRX", "Quantity": "430 ZRX", "Amount": 400.16},
    {"Date": "  15 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 200.16},
    {"Date": "  13 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 900.22},
    {"Date": "  11 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  10 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  9 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  7 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  5 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  4 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  3 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  2 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  1 March 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
];*/

export const portfolioTabDepositTickers = [
    {"Date": "  31 March 3:00 pm", "Asset": "Fanboy7", "Quantity": 430, "Amount": 4300.16},

];
/*export const portfolioTabWithdraw = [
    {"Date": "  29 April 3:00 pm", "Asset": "ZRX", "Quantity": "430 ZRX", "Amount": 4300.16},
    {"Date": "  21 April 3:00 pm", "Asset": "USDC", "Quantity": "430 USDC", "Amount": 1300.16},
    {"Date": "  18 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 300.16},
    {"Date": "  16 April 3:00 pm", "Asset": "ZRX", "Quantity": "430 ZRX", "Amount": 400.16},
    {"Date": "  15 April 3:00 pm", "Asset": "BTC", "Quantity": "430 BTC", "Amount": 200.16},
    {"Date": "  13 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 900.22},
    {"Date": "  11 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  10 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  9 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  7 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  5 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  4 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  3 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  2 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
    {"Date": "  1 April 3:00 pm", "Asset": "ETH", "Quantity": "430 ETH", "Amount": 4300.16},
];*/


export const portfolioTabWithdrawTickets = [
    {"Date": "  29 April 3:00 pm", "Asset": "Fanboy7", "Quantity": 430, "Amount": 4300.16},


];



export const portfolioTab = [{"Asset": "FNBD", "Holdings": 70, "Balance": 11, "Available": 10, "Price": 2},
    {"Asset": "SPIDERMAN02", "Holdings": 10, "Balance": 100, "Available": 2, "Price": 4},
    {"Asset": "BARBY004", "Holdings": 80, "Balance": 321, "Available": 25, "Price": 19},
    {"Asset": "DOGSDOLL", "Holdings": 50, "Balance": 1441, "Available": 764, "Price": 3},
    {"Asset": "SANDMAN03", "Holdings": 50, "Balance": 1441, "Available": 764, "Price": 3},

];
export const ItemsPairs = [
    {
        imgFirst: ETH,
        imgSecond: BAT,
        nameFirst: "ETH",
        nameSecond: "BAT",
        valFirst: 10,
        valSecond: 20
    }, {
        imgFirst: ETH,
        imgSecond: LINK,
        nameFirst: "ETH",
        nameSecond: "LINK",
        valFirst: 10,
        valSecond: 20
    },
    {
        imgFirst: ETH,
        imgSecond: USDC,
        nameFirst: "ETH",
        nameSecond: "USDC",
        valFirst: 10,
        valSecond: 20
    },
    {
        imgFirst: ETH,
        imgSecond: ZRX,
        nameFirst: "ETH",
        nameSecond: "ZRX",
        valFirst: 10,
        valSecond: 20
    },
    {
        imgFirst: ETH,
        imgSecond: LOOM,
        nameFirst: "ETH",
        nameSecond: "LOOM",
        valFirst: 10,
        valSecond: 20
    },
    {
        imgFirst: ETH,
        imgSecond: REP,
        nameFirst: "ETH",
        nameSecond: "REP",
        valFirst: 10,
        valSecond: 20
    },
    {
        imgFirst: REP,
        imgSecond: USDC,
        nameFirst: "REP",
        nameSecond: "USDC",
        valFirst: 10,
        valSecond: 20
    }, {
        imgFirst: REP,
        imgSecond: BAT,
        nameFirst: "REP",
        nameSecond: "BAT",
        valFirst: 10,
        valSecond: 20
    }, {
        imgFirst: REP,
        imgSecond: ZRX,
        nameFirst: "REP",
        nameSecond: "ZRX",
        valFirst: 10,
        valSecond: 20
    }, {
        imgFirst: REP,
        imgSecond: LINK,
        nameFirst: "REP",
        nameSecond: "LINK",
        valFirst: 10,
        valSecond: 20
    },
    {
        imgFirst: ZRX,
        imgSecond: USDC,
        nameFirst: "ZRX",
        nameSecond: "USDC",
        valFirst: 10,
        valSecond: 20
    },
    {
        imgFirst: ZRX,
        imgSecond: BAT,
        nameFirst: "ZRX",
        nameSecond: "BAT",
        valFirst: 10,
        valSecond: 20
    }, {
        imgFirst: ZRX,
        imgSecond: LINK,
        nameFirst: "ZRX",
        nameSecond: "LINK",
        valFirst: 10,
        valSecond: 20
    },
    {
        imgFirst: ZRX,
        imgSecond: LOOM,
        nameFirst: "ZRX",
        nameSecond: "LOOM",
        valFirst: 10,
        valSecond: 20
    }, {
        imgFirst: BAT,
        imgSecond: LOOM,
        nameFirst: "BAT",
        nameSecond: "LOOM",
        valFirst: 10,
        valSecond: 20
    },  {
        imgFirst: BAT,
        imgSecond: USDC,
        nameFirst: "BAT",
        nameSecond: "USDC",
        valFirst: 10,
        valSecond: 20
    },
    {
        imgFirst: USDC,
        imgSecond: LOOM,
        nameFirst: "USDC",
        nameSecond: "LOOM",
        valFirst: 10,
        valSecond: 20
    },
    {
        imgFirst: LINK,
        imgSecond: USDC,
        nameFirst: "LINK",
        nameSecond: "USDC",
        valFirst: 10,
        valSecond: 20
    },
];