import React, {Fragment} from 'react';
import Parse from "parse";
import speakeasy from "speakeasy";
import QRCode from 'qrcode.react';
import authenticatorIcon from '../../../assets/images/google-auth.png'
import ReactDOM from "react-dom";
import {sendParseRequest} from "../../../utils/sendParseRequest";

export class AuthenticatorSettings extends React.Component {
    state = {
        id: '',
        secret: null,
        isCloseModal: false,
        isCodeValidated: true,
    };

    componentWillMount() {
        this.root = document.createElement('div');
        document.body.appendChild(this.root);
    }

    componentDidMount = () => {
        const self = this;
        const modal = this.modal;

        window.onclick = function (event) {
            if (event.target === modal) {
                self.hideModal();
            }
        };
        //generate new secret for current user
        if (Parse.User.current()) {
            this.setState({secret: speakeasy.generateSecret({length: 10})});
        }
    };

    //for using portal
    componentWillUnmount() {
        document.body.removeChild(this.root);
    }

    handleCodeChange = (e) => {
        this.setState({code: e.target.value});
    };

    onSubmit = (e) => {
        const {secret, code} = this.state;

        const verified = speakeasy.totp.verify({
            secret: secret.hex,
            encoding: 'hex',
            token: code
        });

        if (verified) {
            sendParseRequest('updateInfo', {
                searchData: {
                    query: 'User',
                    equal: [{field: 'objectId', value: Parse.User.current().id}]
                },
                updateData: [{field: 'secretKeyForGoogleAuthentication', value: secret}]
            }).then(() => {
                this.setVerifiedCode(true);
                this.hideModal();
            })
        } else {
            if (e.which === 13 || e === "submit") {
                this.setState({isCodeValidated: false})
            }
        }
    };

    hideModal = () => {
        this.props.handleAuthenticatorSettings()
    };

    setVerifiedCode = (bool) => {
        this.props.switchTwoStepAuthentication({isTwoStepAuthenticationEnabled: bool, isSMSAuthenticator: false})
    };

    render() {
        const {secret, isCodeValidated} = this.state;
        let urlForQRcode = null;
        if (secret) {
            urlForQRcode = speakeasy.otpauthURL({secret: secret.ascii, label: 'Fanbase'});
        }

        return ReactDOM.createPortal(
            <Fragment>
                <div className="auth-modal" ref={node => {
                    this.modal = node;
                }}>
                    <div className="two-step_first-wrapper">
                        <div className="two-step_header">
                            <span className="two-step_title">Enable Authenticator</span>
                        </div>
                        <div className="show-code">
                            {
                                secret ? (
                                    <Fragment>
                                        <QRCode size={150} renderAs={'svg'} value={urlForQRcode}/>
                                        <ul>
                                            <li>
                        <span className="qr_desc">
                          Install an authenticator app on your mobile device if you don't already have one.
                        </span>
                                            </li>
                                            <li>
                        <span className="qr_desc">
                           Scan QR code with the authenticator.
                        </span>
                                            </li>
                                        </ul>
                                    </Fragment>
                                ) : null
                            }
                        </div>
                        <div className="enter-code">
                            <div className="controls">
									<span>
										Enter the 2-step verification code provided by your authentication app:
									</span>
                                <div className="unput-two-step">
                                    <img src={authenticatorIcon} alt=''/>
                                    <input className={isCodeValidated ? "code-input" : "code-input red"} type="text"
                                           id="token"
                                           maxLength="7"
                                           autoComplete="off" onChange={this.handleCodeChange}
                                           onKeyPress={this.onSubmit}/>
                                </div>
                            </div>
                        </div>
                        <div className="two-step_footer">
                            <input className="btn-two-step" type="submit" value="Cancel" onClick={() => {
                                this.hideModal()
                            }}/>
                            <input className="btn-two-step blue" type="submit" value="Enable" onClick={() => {
                                this.onSubmit('submit')
                            }}/>
                        </div>
                    </div>
                </div>
            </Fragment>,
            this.root
        );
    }
}