import React, {useEffect, useState} from 'react';

const ProjectsPagination = ({onClickPagination, limit, limitForButtons, type}) => {
    const [fullPaginationButtonsList, setFullPaginationButtonsList] = useState([]);
    const [paginationButtonsList, setPaginationButtonsList] = useState([]);

    useEffect(() => {
        // Output of the initial list of buttons to a parent limited
        // As well as a second list, additionally limited by the button limit
        for (let i = 0; i < limit; i++) {
            setFullPaginationButtonsList(prev => {
                return [
                    ...prev,
                    <PaginationButton value={i + 1}
                                      key={i + Math.random()}
                                      onClickPagination={onClickPagination}
                                      type={type}
                                      setNewPage={setNewPage}
                    />
                ]
            })
        }

        for (let i = 0; i < limit && i < limitForButtons; i++) {
            setPaginationButtonsList(prev => {
                return [
                    ...prev,
                    <PaginationButton value={i + 1}
                                      key={i + Math.random()}
                                      onClickPagination={onClickPagination}
                                      type={type}
                                      setNewPage={setNewPage}
                    />
                ]
            })
        }
    }, []);

    useEffect(() => {
        if (paginationButtonsList.length > 0) {
            const paginationBlock = document.getElementById((type))
            if (paginationBlock) {
                paginationBlock.querySelector(".paginationNumbers__number")
                    .nextElementSibling
                    .className = 'paginationNumbers__number paginationNumbers__number_selected'
            }
        }
    }, [paginationButtonsList]);

    const [firstPageNumber, setFirstPageNumber] = useState(0);
    const [lastPageNumber, setLastPageNumber] = useState(0);

    useEffect(() => {
        // Saves the number of the first and last page from the current page array
        if (paginationButtonsList.length > 0) {
            const clonePaginationButtonsList1 = [...paginationButtonsList]
            setLastPageNumber(clonePaginationButtonsList1.pop().props.value);
            const clonePaginationButtonsList2 = [...paginationButtonsList]
            setFirstPageNumber(clonePaginationButtonsList2.shift().props.value);
        }
    }, [paginationButtonsList]);

    const [showNextButton, setShowNextButton] = useState(false);

    useEffect(() => {
        // Shows the ">" button depending on the number of the last item from the current list of buttons with respect to the maximum button limit
        if (lastPageNumber < limit) {
            setShowNextButton(true)
        } else {
            setShowNextButton(false)
        }
    }, [lastPageNumber]);

    const [showPrevButton, setShowPrevButton] = useState(false);

    useEffect(() => {
        // Shows the '<' button if the number of the first element from the current list of buttons is 1
        if (firstPageNumber !== 1) {
            setShowPrevButton(true)
        } else {
            setShowPrevButton(false)
        }
    }, [firstPageNumber]);


    const showNextPageList = () => {
        // Sets a new button array from (the last number of the current page + 1) to (the last number of the current page + button limit)
        const cloneFullPaginationButtonsList = [...fullPaginationButtonsList];
        const nextFullPaginationButtonsList = cloneFullPaginationButtonsList.slice(lastPageNumber, lastPageNumber + limitForButtons);
        setPaginationButtonsList(nextFullPaginationButtonsList)
    }

    const showPrevPageList = () => {
        // Sets a new list of buttons from (the first number in the current list - limit - 1, but not less than 0) to (the first number in the current list -  1)
        const cloneFullPaginationButtonsList = [...fullPaginationButtonsList];

        let start = firstPageNumber - limitForButtons - 1;
        if (start < 0) {
            start = 0
        }

        const prevFullPaginationButtonsList = cloneFullPaginationButtonsList.slice(start, firstPageNumber - 1);
        setPaginationButtonsList(prevFullPaginationButtonsList)
    }

    const setNewPage = (e, value) => {
        const btn = e.target.parentNode.querySelector('.paginationNumbers__number_selected');

        if (btn) {
            btn.className = 'paginationNumbers__number'
        }

        e.target.className = 'paginationNumbers__number paginationNumbers__number_selected'

        onClickPagination(value - 1, type)
    }

    return (
        <div className="pagination" id={type}>
            {showPrevButton &&
            <button className='paginationNumbers__number pagination__navigation-btn_arrow'
                    onClick={showPrevPageList}
            >
                {'<'}
            </button>}
            {paginationButtonsList.map(button => button)}
            {showNextButton &&
            <button className='paginationNumbers__number pagination__navigation-btn_arrow'
                    onClick={showNextPageList}
            >
                {'>'}
            </button>}
        </div>
    )
}

const PaginationButton = ({value, setNewPage}) => {
    return (
        <button className='paginationNumbers__number'
                key={value + Math.random()}
                onClick={e => setNewPage(e, value)}
                id={value}
        >
            {value}
        </button>
    );
};


export default ProjectsPagination;