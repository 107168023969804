import React, {Component, Fragment} from 'react';

class Footer extends Component {
    render() {
        const {closeModal} = this.props;
        return (
            <Fragment>
                <div className="calendar-footer">
                    <input
                        type="button"
                        readOnly={true}
                        className="calendar-btn-done"
                        onClick={closeModal}
                        value={"Done"}/>
                </div>
            </Fragment>
        );
    }
}


export default class Calendar extends Component {
    constructor(props) {
        super(props);
console.log(props, "--------------props --CALENDAR")
        this.state = {
            month: new Date(Date.parse(new Date().toDateString())), //Translation of the now time to '00.00' format
            selected: props.date || new Date(Date.parse(new Date().toDateString()))
        };

        this.previous = this.previous.bind(this);
        this.next = this.next.bind(this);
    }

    previous() {
        const {
            month,
        } = this.state;
        month.setMonth(month.getMonth() - 1);
        this.setState({
            month: month,
        });
    }

    next() {
        const {
            month,
        } = this.state;
        // console.log(month, "--------------month")
        // console.log(month.getMonth(), "--------------month.getMonth()")
        // let a = month.getMonth();
        // console.log(a, "--------------a")
        // let b = a+1;
        // console.log(b, "--------------b")
        // console.log(month.getFullYear(), "--------------month.getFullYear()")
        // month.setMonth(b, 27)


        month.setMonth((month.getMonth() +1), 27);
        // console.log(month, "--------------month22")
        // console.log(month.getMonth(), "--------------month.getMonth()22")
        this.setState({
            month: month,
        });
    }

    select(day) {
        this.props.setDate(day.date);
        this.setState({
            // selected: day.date,
            month: cloneDate(day.date),
        });
    }

    renderWeeks() {
        const {
            month,
        } = this.state;
        const selected = this.props.date;
        let date = cloneDate(month);
        date.setDate(0);
        while (date.getDay() !== 0) {
            date.setDate(date.getDate() - 1);
        }

        let weeks = [];
        let done = false;
        let count = 0;
        let monthIndex = date.getMonth();


        while (!done) {
            weeks.push(
                <Week key={date}
                      date={cloneDate(date)}
                      month={month}
                      select={(day) => this.select(day)}
                      selected={selected}/>
            );

            date.setDate(date.getDate() + 7);

            // date.add(1, "w");

            done = count++ > 2 && monthIndex !== date.getMonth();
            monthIndex = date.getMonth();
        }

        return weeks;
    };

    renderMonthLabel() {
        const {
            month,
        } = this.state;

        return <span className="month-label">{month.toString().slice(4, 7) + ' ' + month.getFullYear()}</span>;
    }

    render() {
        console.log(this.state, this.props, '------prooops' )
        const {handleCalendar} = this.props;
        return (
            <section className="calendar">
                <header className="header">
                    <div className="month-display row">
                        <i className="arrow fa fa-angle-left" onClick={this.previous}/>
                        {this.renderMonthLabel()}
                        <i className="arrow fa fa-angle-right" onClick={this.next}/>
                    </div>
                    <DayNames/>
                </header>
                {this.renderWeeks()}
                <Footer closeModal = {handleCalendar}/>
            </section>
        );
    }
}

class DayNames extends Component {
    render() {
        return (
            <div className="row day-names">
                <span className="day">Sun</span>
                <span className="day">Mon</span>
                <span className="day">Tue</span>
                <span className="day">Wed</span>
                <span className="day">Thu</span>
                <span className="day">Fri</span>
                <span className="day">Sat</span>
            </div>
        );
    }
}

class Week extends Component {
    render() {
        let days = [];
        let {
            date,
        } = this.props;

        const {
            month,
            selected,
            select,
        } = this.props;

        for (let i = 0; i < 7; i++) {
            const isSameYear = date.getFullYear() === new Date().getFullYear();
            const isSameMonth = date.getMonth() === new Date().getMonth();
            const isSameDay = date.getDate() === new Date().getDate();

            const isToday = isSameDay && isSameMonth && isSameYear;
            const day = {
                name: date.toLocaleString('en-us', {month: 'long'}),
                number: date.getDate(),
                isCurrentMonth: date.getMonth() === month.getMonth(),
                isToday,
                date: date
            };
            days.push(
                <Day day={day}
                     selected={selected}
                     select={select}/>
            );

            date = cloneDate(date);
            date.setDate(date.getDate() + 1);
        }

        return (
            <div className="row week" key={days[0]}>
                {days}
            </div>
        );
    }

}

class Day extends Component {
    render() {
        const {
            day,
            day: {
                date,
                isCurrentMonth,
                isToday,
                number
            },
            select,
            selected
        } = this.props;

        const todayClass = isToday ? " today" : "";
        const currentMonthClass = isCurrentMonth ? "" : " different-month";
        const selectedClass = JSON.stringify(new Date(this.props.day.date)).slice(1, 11) === JSON.stringify(new Date(this.props.selected)).slice(1, 11) ? " selected" : "";
        const disabledClass = date < new Date().setDate(new Date().getDate() + 1) ? " disabled" : "";
        return (
            <Fragment>
                <input
                    type="button"
                    readOnly={true}
                    key={date.toString()}
                    // disabled={date < new Date().setDate(new Date().getDate() + 1)}
                    disabled={date < new Date().setDate(new Date().getDate() -1)}
                    className={"day" + todayClass + currentMonthClass + selectedClass + disabledClass}
                    onClick={() => select(day)}
                    value={number}/>
            </Fragment>
        );
    }
}



function cloneDate(date) {
    return new Date(date.getTime());
}

