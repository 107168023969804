import React, {useEffect, useState} from 'react';
import closeSvg from '../../../assets/images/close--blue.svg';
import prepareImagesPathForMinify from '../../../utils/prepareImagesPathForMinify';
import defaultLogo from "../../../assets/images/F_in_White.png";
import {sendParseRequest} from "../../../utils/sendParseRequest";
import {useHistory} from "react-router";

const AdvertByCollectibles = ({allProjectsId}) => {
    const history = useHistory();
    const advertContainerDomEl = React.createRef();
    const [showAdvert, setShowAdvert] = useState(false);
    const [advertInfo, setAdvertInfo] = useState({});
    let arr = [];

    const getAdvertInfo = () => {
        if (Object.keys(advertInfo).length === 0 && allProjectsId.length > 0) {
            sendParseRequest('getAdvertByCollectibles', {allProjectsId})
                .then(project => {
                    setAdvertInfo(project);
                    setShowAdvert(true);
                }).catch(() => setShowAdvert(false))
        }
    };

    const openAdProject = (i) => {
        history.push(`/collectormarket/${i.projectId}?auctionId=${i.auctionId}`);
    };

    const showAndHideAnimation = (show) => {
        if (advertContainerDomEl.current === null) return;

        if (show) {
            advertContainerDomEl.current.classList.add('advertByCollectibles--show');
        } else {
            advertContainerDomEl.current.classList.remove('advertByCollectibles--show');
        }
    };

    useEffect(() => {
        showAndHideAnimation(showAdvert);
    });

    useEffect(() => {
        getAdvertInfo();
    }, []);


    const onClose = () => {
        showAndHideAnimation(false);
        advertContainerDomEl.current.addEventListener('transitionend', () => setShowAdvert(false));
    };

    advertInfo.advArray && advertInfo.advArray.map((i) => {
        i.map((index) => {
                arr.push(index)
            }
        )
    });

    arr && arr.sort((a, b) => a.DateTill - b.DateTill);

    if (!showAdvert) return null;

    return (
        <div className="advertByCollectibles"
             ref={advertContainerDomEl}>
            <p className="adv_sub-title">Reminders</p>
            <button className="advertInfo__closeBtn btn--close"
                    onClick={onClose}>
                <img src={closeSvg} alt="Close"/>
            </button>
            <div className="advertInfo">
                {
                    advertInfo.advArray && arr.map((i, idx) =>
                        <div className="adv_containter"
                             key={`advert-info-key-${idx}`}>
                            <div className="adv_line"><img
                                src={prepareImagesPathForMinify(i.logo, 35, 35) || defaultLogo} alt="Logo"/></div>
                            <div className="adv_line">
                                The {i.tickerSymbol} auction by {i.fname} {i.lname} ends on {i.DateTillUSA}.
                            </div>
                            <div className="adv_line">
                                <div className="adv_line-button" onClick={() => openAdProject(i)}>buy it now</div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};

export default AdvertByCollectibles;
