import React, {Fragment, useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import InfoTooltip from "../../Components/InfoTooltip";

const CustomUsersFields = ({fieldsArr, customFieldsState, UpdateFieldsArr, removeCustomFIeldItem}) => {
  const [arrayFields, setArrayFields] = useState([]);
  const [fieldArr, setFieldArr] = useState([]);



  const addFeildData = (type, data, id, key) => {
    let currentFields = fieldArr;
    let findedObj = currentFields.find(n => n.id === key);
    if (findedObj) {
      if (currentFields.length > 0) {
        currentFields.forEach((i) => {
          if (i.id === key) {
            if (i[type] !== type) {
              i[type] = data;
            }
          }
        })
      }
    }

    if (currentFields.length < 1) {
      let newObj = {
        id: key,
        [type]: data
      };
      currentFields.push(newObj)
    }
    setFieldArr(currentFields);
    UpdateFieldsArr(currentFields, "customFieldsState");
  };


  const addNewField = (i) => {
    let a = fieldsArr;
    a.push(({id: i}));
    UpdateFieldsArr(a, "arrayFeilds");
  };


  return (
    <Fragment>
      <div className="section-input-wrap custom-fields-container">
        {fieldsArr.length > 0 && <p style={{marginTop: '20px'}}>Additional Metadata</p>}
        {fieldsArr.length > 0 && fieldsArr.map((i, key) =>
          <div key={key} style={{display: 'flex', flexDirection: 'column', width: '100%', marginTop: '20px'}}>
            <div className=" custom-left">
              <div className="s-input-title">
                <span id='typeOfDownloads'>Custom category</span>
                <InfoTooltip position="top"
                             message="You can add custom fields for your project right here.">
                  <i className="fa fa-info-circle"/></InfoTooltip>
                {/*<span className="required-star">Required *</span>*/}
              </div>
              <div style={{"display": "flex", "flexDirection": "column"}}>
                <input
                  maxLength="100"
                  value={i.name}
                  onChange={(e) => addFeildData("name", e.target.value, "name", key)}
                  type="text"
                  placeholder="i.e. Color"/>
              </div>
            </div>
            <div className="">
              <div className="s-input-title">
                <span id='typeOfDownloads'>Custom description</span>
                <InfoTooltip position="top"
                             message="The custom fields allow you to include additional categories, descriptions, or contract text visible in your metadata.">
                  <i className="fa fa-info-circle"/></InfoTooltip>
                {/*<span className="required-star">Required *</span>*/}
              </div>
              <div className="section-input-wrap">
          <textarea
            onChange={(e) => addFeildData("text", e.target.value, "text", key)}
            maxLength="2500"
            value={i.text}
            rows="5"
            placeholder="i.e. Blue"/>
              </div>
            </div>
            <div style={{display: 'flex'}}>
              {/*<button className='blue-button' style={{marginRight: '15px'}}> Save field</button>*/}
              {fieldsArr.length >= 1 &&
              <button className='blue-button' style={{marginRight: '15px'}} onClick={() => addNewField(key+1)}> Add one more
                field</button>}
              {key >= 0 && <button className='red-button' onClick={() => removeCustomFIeldItem(key)}>Remove field</button>}
              {/*{key <= 0 && <button className='red-button' onClick={() => {*/}
              {/*  setArrayFields([]);*/}
              {/*  setFieldArr([])*/}
              {/*}}>Clear field</button>}*/}

            </div>
          </div>)

        }

        {fieldsArr.length < 1 &&
        <div style={{"display": "flex", "marginTop": "20px", "alignItems": "center", "cursor": "pointer"}}
             onClick={() => addNewField(1)}>
          <a className="add-collab-bttn">
            <svg width="20" height="20" viewBox="0 0 24 24">
              <path
                d="M14.8284 12l4.2427 4.2426c.781.781.781 2.0474 0 2.8285-.781.781-2.0474.781-2.8285 0L12 14.8284l-4.2426 4.2427c-.781.781-2.0474.781-2.8285 0-.781-.781-.781-2.0474 0-2.8285L9.1716 12 4.9289 7.7574c-.781-.781-.781-2.0474 0-2.8285.781-.781 2.0474-.781 2.8285 0L12 9.1716l4.2426-4.2427c.781-.781 2.0474-.781 2.8285 0 .781.781.781 2.0474 0 2.8285L14.8284 12z"
                fillRule="evenodd"/>
            </svg>
          </a>
          <p>Additional Metadata</p>
        </div>}


      </div>
    </Fragment>
  );
};

export default CustomUsersFields;
