import React, {Component, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import prepareImagesPathForMinify from '../../../utils/prepareImagesPathForMinify';
import ethClient from "../../eth_client";
import {amountInTIX} from "../../eth_client/helper";
import './Index.css';
import {sendAxiosRequest} from "../../../utils/sendAxiosRequest";

class NewSlider extends Component {
    state = {
        elementCount: 4,
        position: 0,
        defDescription: true,
        defProgress: true,
        defStatus: true,
        projectsArr: [],
    };

    onLoadImage = async () => {
        const {onChangeQueue, numberOfSlider,} = this.props;

       let getProjectsResponse = await sendAxiosRequest('getInfo', {
            query: 'Projects',
            toJSON: true,
            select: ['projectTitle', 'mediaCategory', 'licenseType', 'tickerSymbol',
                'ipPercentage', 'objectId', 'financingGoal', 'promotionalBanners'
            ],

            equal: [
                {field: 'mediaCategory', value: this.props.title},
                {field: 'licenseType', value: 'limited'},
                // {field: 'verification.approve', value: true}
            ],
            // notEqual: [{field: 'ipPercentage', value: 'Unidentified'}]
        })

        if(getProjectsResponse.result) {
            this.setState({
                projectsArr: getProjectsResponse.result.map(obj => ({
                        ...obj,
                        id: obj.objectId,
                        image: obj.promotionalBanners[0]
                    })
                )
            });

            if (onChangeQueue) {
                onChangeQueue(numberOfSlider + 1);
            }

            this.resizeSlider(); 
        }
        
      
        
        // .catch(() => {
        //     if (onChangeQueue) {
        //         onChangeQueue(numberOfSlider + 1);
        //     }
        // })
    };

    resizeSlider = () => {
        const {projectsArr} = this.state;

        if (projectsArr.length === 0) return;

        const {elementCount} = this.state;
        const width = this._divSliderContainer.offsetWidth;
        let newElCount = elementCount;

        if (window.innerWidth > 1030) {
            newElCount = 4;
        } else if (window.innerWidth <= 1030 && window.innerWidth >= 770) {
            newElCount = 3;
        } else if (window.innerWidth < 770 && window.innerWidth >= 500) {
            newElCount = 2;
        } else if (window.innerWidth < 500) {
            newElCount = 1;
        }

        const elementWidth = width / newElCount;
        const sliderElementsDOM = this._ulElement.querySelectorAll('li');
        const sliderWidth = elementWidth * sliderElementsDOM.length;

        sliderElementsDOM.forEach(el => el.style.width = `${elementWidth}px`);

        this._ulElement.style.width = `${sliderWidth}px`;

        if (elementCount !== newElCount) {
            this.setState({elementCount: newElCount});
        }
    };

    next = () => {
        const {
            position,
            elementCount,
            projectsArr
        } = this.state;
        const slider = this._ulElement;
        const elementWidth = slider.firstChild.offsetWidth;
        let slideTo = position - elementWidth;

        if (projectsArr.length < elementCount) return false;

        if ((-slider.offsetWidth) >= (slideTo - (elementWidth * (elementCount - 1)))) {
            slideTo = 0;
        }

        slider.style.transform = `translateX(${slideTo}px)`;

        this.setState({position: slideTo});
    };

    prev = () => {
        const {
            position,
            elementCount,
            projectsArr
        } = this.state;
        const slider = this._ulElement;
        const elementWidth = slider.firstChild.offsetWidth;
        let slideTo = position + elementWidth;

        if (projectsArr.length < elementCount) return false;

        if (slideTo > 0 && projectsArr.length > elementCount) {
            slideTo = (-slider.offsetWidth + (elementWidth * elementCount));
        }

        slider.style.transform = `translateX(${slideTo}px)`;

        this.setState({position: slideTo});
    };

    checkIsActiveImg = (imageIdx) => {
        const {
            elementCount,
            position
        } = this.state;
        let isActiveImg = position === 0 && elementCount > imageIdx;

        if (position !== 0) {
            const slider = this._ulElement;
            const elementWidth = slider.firstChild.offsetWidth;
            const idxPosition = Math.abs(position) / elementWidth;

            isActiveImg = imageIdx >= idxPosition && imageIdx <= idxPosition + (elementCount - 1);
        }

        return isActiveImg;
    };

    componentDidMount() {
        const {
            numberOfSlider,
            sliderIsDownloading
        } = this.props;

        if (sliderIsDownloading === numberOfSlider) this.onLoadImage();

        const {description, progress, status} = this.props.display || false;

        this.resizeSlider();

        this.setState({
            defDescription: description,
            defProgress: progress,
            defStatus: status,
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {projectsArr} = this.state;
        const {
            numberOfSlider,
            sliderIsDownloading
        } = this.props;

        if (numberOfSlider === sliderIsDownloading && prevProps.sliderIsDownloading !== sliderIsDownloading) {
            this.onLoadImage();
        }

        if (projectsArr.length === 0) return false;

        this.resizeSlider();

        window.addEventListener('resize', this.resizeSlider);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeSlider);
        document.removeEventListener('scroll', this.onLoadImage);
    }

    render() {
        const {
            defStatus,
            defProgress,
            defDescription,
            projectsArr,
            elementCount,
        } = this.state;
        const {title} = this.props;
        let slides = [];

        if (projectsArr.length === 0) {
            return null;
        } else {
            projectsArr.forEach((item, idx) => {
                slides.push(
                    <Slide slideId={item.id}
                           showImg={this.checkIsActiveImg(idx)}
                           key={`slideKey-${idx}`}
                           item={item}
                           defDescription={defDescription}
                           defProgress={defProgress}
                           defStatus={defStatus}/>
                );
            });
        }

        const isHideArrow = projectsArr.length < (elementCount + 1);

        return (
            <div className="new-slider">
                <section className="new-slider_title">
                    <h3>{title}</h3>
                </section>

                <section>
                    <div className="new-slider_icon new-slider_prev"
                         onClick={this.prev}
                         hidden={isHideArrow}/>
                    <div className="new-slider_icon new-slider_next"
                         onClick={this.next}
                         hidden={isHideArrow}/>
                </section>

                <div className="new-slider_page"
                     ref={(node) => this._divSliderContainer = node}>
                    <ul ref={(node) => this._ulElement = node}>
                        {slides}
                    </ul>
                </div>
            </div>
        );
    }
}

const Slide = ({item, defDescription, defProgress, defStatus, showImg}) => {
    const [savedImage, setImage] = useState('');
    let image = showImg ? prepareImagesPathForMinify(item.image, 250, 250) : savedImage;
    const [state, setState] = useState({goal: 0, risingPercentage: 0});

    const getTixDetail = async () => {
        const project = await ethClient.project.getProjectDetailByTokenURI(`${item.id}`);
        const goal = parseInt(project.goal);
        const tixInfo = await ethClient.ticket.getTixDetail(project.tokenAddress);
        const leftGoal = tixInfo.preSellAmount * amountInTIX(tixInfo.price);
        let risingPercentage = ((1 - leftGoal / goal) * 100).toFixed(2);
        if (risingPercentage < 0) risingPercentage = 0;
        setState({...state, goal, risingPercentage});
    }





    useEffect(() => {
        if (defStatus) {
            getTixDetail();
        }
    }, [state.goal]);
    // if (!project[1]) {
    //     this.props.history.push('/404');
    //     return
    // }

    if (showImg && savedImage === '') setImage(image);
console.log(item, "--------------item")
    return (
        <li className="new-slider_item new-slider_item987">
            <Link to={`/collectormarket/${item.id}`}>
                <div className="new-slider_picture" style={item ? {
                    width: '100%',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundImage: `url('${image}')`,
                    backgroundColor: 'transparent',
                } : {}}/>
            </Link>
            {item && (
                <div className="new-slider_content">
                    <p className="new-slider_content_creator">
                        {/*{item.creatorName}*/}
                        {/*<span>{item.projectFormat}</span>*/}
                    </p>
                    <p className="new-slider_content_title">
                        {item.projectTitle}
                    </p>
                    {
                        defDescription === true ? (
                            <span className="new-slider_content_description">
                            {item.projectDescription}
                        </span>) : null
                    }
                    {
                        defProgress === true ? (<div className="new-slider_progress">
                            <div
                                className="new-slider_progress_bar"
                                // style={{width: `${item.percentage}`}}
                                style={{width: `${state.risingPercentage}%`}}
                            />
                        </div>) : null
                    }
                    {
                        defStatus === true ? (<p className="new-slider_status">
                        <span className="new-slider_status_raised">
                            {/*${item.raisedValue} raised*/}
                            </span>
                            ${(state.risingPercentage * state.goal) / 100} of ${state.goal} raised
                            ({state.risingPercentage}%)
                            {/*{item.totalValue}*/}
                        </p>) : null
                    }
                </div>
            )}
        </li>
    );
};

export default NewSlider;
