import React, { useEffect, useState } from 'react'
// import Header from "../../Components/Header/Header";
// import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";
import { useHistory } from "react-router";
// import {saveAs} from 'file-saver';
import '../walletPage.sass'
import './backupPhrase.sass'
// import "./backupPhrase.sass";
import { CopyToClipboard } from "react-copy-to-clipboard";
// import {useAuth} from '../../../../context/authContext'
import ConfirmPhrase from "../confirmPhrase/ConfirmPhrase";

const BackupPhrase = ({ mnemonic, acc, password, walletType }) => {
    console.log(mnemonic, "--------------9999")
    console.log(walletType, '----walletType')

    const history = useHistory();
    const [isShow, setIsShow] = useState(false);
    const [confirmPopup, setConfirmPopup] = useState(false);
    const [copied, setCopied] = useState(false);

    const downloadPhrase = () => {
        // const phrase = user.mnemonic;
        // const blob = new Blob([mnemonic], {type: "text/plain;charset=utf-8"});
        // console.log(blob, "--------------blob")
        // saveAs(blob, "Wallybase-phrase.txt");
    };

    return (
        <div className='backupPhrase__wrapper'>
            {/*<Header/>*/}
            {!confirmPopup ?
                <div className='backupPhrase__content-wrapper'>
                    <div className='backupPhrase__content-left'>
                        <h1>Alternate Phrase</h1>
                        <p>Your alternate phrase restores your account if you lose your first phrase. Do not share this
                            phrase with anyone.</p>
                        <p>Anyone with this phrase can open your account and take your collectibles, assets, media and
                            certificates of authenticity.
                            <br />Once gone, those items are irretrievable.
                        </p>
                        <div className='backupPhrase__words-wrapper'>
                            <div className={!isShow ? 'backupPhrase__words-block blur' : 'backupPhrase__words-block'}>
                                {mnemonic}
                            </div>
                            <div className={!isShow ? 'backupPhrase__words-lock' : 'backupPhrase__words hide'}
                                onClick={() => setIsShow(!isShow)}>
                                {/*<LockOutlined style={{fontSize: '30px', marginTop: '15px'}}/>*/}
                                {!isShow ? <p>GENERATE PHRASE</p> : null}

                            </div>
                        </div>

                        <CopyToClipboard text={mnemonic}
                            onCopy={() => setCopied(true)}>
                            {copied === true
                                ?

                                <button className={isShow ? 'primaryButton blue-btn copyLink copied' : 'disableButton blue-btn '}
                                    style={{ marginLeft: '10px', marginTop: '20px' }}
                                    disabled={!isShow}

                                >
                                    Copied!
                                </button>

                                : <button className={isShow ? 'primaryButton blue-btn' : 'disableButton blue-btn'}
                                    style={{ marginLeft: '10px', marginTop: '20px' }}
                                    disabled={!isShow}

                                >
                                    Copy phrase
                                </button>}
                        </CopyToClipboard>
                        <button className={isShow ? 'primaryButton blue-btn' : 'disableButton blue-btn'}
                            style={{ marginLeft: '10px', marginTop: '20px' }}
                            disabled={!isShow}
                            onClick={() => setConfirmPopup(true)

                                // history.push('/confirmPhrase')
                            }
                        >
                            Next
                        </button>

                    </div>
                    <div className='backupPhrase__content-right'>
                        <p>To store your alternate phrase:</p>
                        <p>Use a password manager like <a href="https://1password.com/">1Password.</a></p>
                        <p>Write your phrase on a piece of paper, or multiple pieces of paper, and store them in secret
                            locations.

                        </p>

                        <p className='downloadCodeLink' onClick={downloadPhrase}>
                            Store your phrase on an encrypted external drive.


                        </p>
                    </div>
                </div>

                : <ConfirmPhrase mnemonic={mnemonic} acc={acc} password={password} walletType={walletType} />
            }
        </div>
    );
};

export default BackupPhrase;
