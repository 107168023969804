import createSagaMiddleware from "redux-saga";
import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import blockchain from "./blockchain/blockchain.reducer";
import {watchFetchBlockchain} from "./blockchain/blockchain.saga";

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({blockchain});

const store = createStore(
    rootReducer,
    undefined,
    compose(
      applyMiddleware(sagaMiddleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f,
      // window.devToolsExtension ? window.devToolsExtension() : f => f
    )
);
sagaMiddleware.run(watchFetchBlockchain);

export default store;
