import React, {useEffect} from "react";
import closeSvg from "../../../assets/images/close--blue.svg";
import Parse from "parse";
import {sendParseRequest} from "../../../utils/sendParseRequest";
import {useHistory} from "react-router";

const CreatorPopup = ({closeModal, statusPopup, type, additionalDataForLimited, projectId}) => {
    const history = useHistory();

    useEffect(() => {
        if (type === "unlimited" || (type === "limited"
            && additionalDataForLimited === "I am pre-selling a percentage of the intellectual property; I am not pre-selling certificates of authenticity for this collectible.")) {

            sendParseRequest('getUserInfoById', {id: Parse.User.current().id})
                .then(user => {
                    const userRole = user.get('type');

                    const verificationObj = {};

                    switch (userRole) {
                        case 'User':
                            verificationObj.adminVerificate = false;
                            verificationObj.ccoVerificate = false;
                            verificationObj.approve = false;
                            break;
                        case 'Admin':
                            verificationObj.adminVerificate = true;
                            verificationObj.ccoVerificate = false;
                            verificationObj.approve = false;
                            break;
                        case 'CCO':
                            verificationObj.adminVerificate = true;
                            verificationObj.ccoVerificate = true;
                            verificationObj.approve = true;
                            break;
                    }

                    sendParseRequest('updateInfo', {
                        searchData: {
                            query: 'Projects',
                            equal: [{field: 'objectId', value: projectId}]
                        },
                        updateData: [{field: 'verification', value: verificationObj}]
                    })
                })
        } else {
            history.push('/Projects')
        }
    }, []);

    return (
        statusPopup &&
        <div className="slideshowModal popup_token-block">
            <button className="slideshowModalWrapper__closeButton slideshowModalButton">
                <img src={closeSvg} alt="close" onMouseDown={() => closeModal(false)}/>
            </button>
            <div className=" popup-withdraw popup_tokens">
                <div className="creator-popup-block">
                    <h2> Thank you for submitting your project. </h2>
                    <p>All equity crowdfunding projects are reviewed prior to release. We will confirm, suggest edits,
                        or deny within 7 days. Do not resubmit until receiving notice. Thanks!
                    </p>
                </div>
                <button type="button" className="popup_tokens-btn" onClick={() => closeModal(false)}>Close</button>
            </div>
        </div>
    );
};

export default CreatorPopup;