import './Login.css';
import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import Parse from 'parse';
import {isEmpty} from "../../../../utils/isEmpty";
import isEmail from "../../../../utils/isEmail";
import startLogin from "../../../../utils/startLogin";
import shouldUserConfirmStatus from "../../../../utils/shouldUserConfirmStatus";
import * as blockChainActions from "../../../../modules/blockchain/blockchain.action";
import {message} from "../../../../utils/message";

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.email = React.createRef();
        this.password = React.createRef();
        this.loginButton = React.createRef();
    }

    state = {email: "", password: "",}

    componentDidMount = () => {
        if (Parse.User.current()) this.props.history.push('/');
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    };

    setUserData = (value, type) => {
        this.email.current.style.backgroundColor = "white";
        this.password.current.style.backgroundColor = "white";

        this.setState({[type]: value})
    }

    handleSubmit = () => {
        const loginButton = this.loginButton.current;

        loginButton.innerHTML = "<span class='spinner'/>";
        loginButton.classList.add('loadingState');

        if (!isEmail(this.state.email)) {
            this.email.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";

            message('error', 'Your email address is invalid');

            loginButton.innerText = "Login";
            loginButton.classList.remove('loadingState');
        } else if (isEmpty(this.state.password)) {
            this.password.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";

            message('error', 'Fill in the password field')

            loginButton.innerText = "Login";
            loginButton.classList.remove('loadingState');
        } else {
            startLogin(this.state.email, this.state.password, false, this.props.history,)
                .then((user) => {
                    const isTwoStepAuthenticationEnabled = user.get('isTwoStepAuthenticationEnabled');
                    const isSMSAuthenticator = user.get('isSMSAuthenticator');

                    if (isTwoStepAuthenticationEnabled) {
                        const isCodeVerified = JSON.parse(localStorage.getItem('isCodeVerified'));

                        if (isCodeVerified === true) {
                            localStorage.setItem('isCodeVerified', JSON.stringify(!isCodeVerified));
                        }
                        if (isSMSAuthenticator) {
                            this.props.history.push("/signin_step_two_sms");
                        } else {
                            this.props.history.push("/signin_step_two");
                        }
                    } else {
                        startLogin(this.state.email, this.state.password, true)
                            .then(() => {
                                shouldUserConfirmStatus(this.props.history.push, "/");
                            });
                    }
                })
                .catch((() => {
                    localStorage.removeItem('email');
                    localStorage.removeItem('password');
                    loginButton.innerText = "Login";
                    loginButton.classList.remove('loadingState');
                }))
        }
    }

    render() {
        return (
            <div className="loginScreen">
                <div className="screenWrapper loginPartWrap">
                    <p className="mainTitle">Login to your account</p>
                    <div className="loginWrap">
                        <input type="text"
                               onKeyPress={this._handleKeyPress}
                               value={this.state.email}
                               ref={this.email}
                               onChange={(e) => this.setUserData(e.target.value, 'email')}
                               placeholder="Email"
                        />
                        <input type="password"
                               onKeyPress={this._handleKeyPress}
                               value={this.state.password}
                               ref={this.password}
                               onChange={(e) => this.setUserData(e.target.value, 'password')}
                               placeholder="Password"
                        />
                        <a className="loadingButton" ref={this.loginButton} onClick={this.handleSubmit}>
                            Login
                        </a>
                    </div>
                    <div className="extraSigns">
                        {/*<Link className="pull-left" to='/forgot'>Forgot password?</Link>*/}
                        {/*{TODO: unhide register link}*/}
                        {/*<Link className="pull-right" to='/register'>Don't have an account? Register.</Link>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    null,
    {...blockChainActions}
)(Login);
