import React from 'react';

import deleteSvg from '../../../assets/images/error.svg';

const rolesArray = ["Actor", "Actor (Lead)", "Animator", "Artist (Visual)", "Author", "Author (Lead)", "Casting Director", "Cinematographer", "Designer (Costume)", "Designer (Graphics)", "Designer (Motion Graphics)", "Director", "DJ/ Turntablist", "Editor", "Engineer (Audio)", "Engineer (Software)", "Executive Producer", "Game Designer", "Host (On-Air)", "Instrumentalist ", "Lighting", "Producer (Audio)", "Producer (Video)", "Production Manager", "Programmer", "Vocalist (Rapper, Singer)", "Writer"];

/*
*
* Component for render a form for adding principals and collaborators
*
* */
const AddUser = ({onGetDataFromForm, isShowForm, isValidForm, newUser, onDeleteRole, onUserReset}) => {


  const {
    roles: selectedRoles,
    names
  } = newUser;
  let cssClassForm = '';

  if (isShowForm) {
    cssClassForm = 'addUsersForm--show';
  } else {
    cssClassForm = 'addUsersForm--hide';
  }

  return (
    <form className={`addUsersForm ${cssClassForm}`}
          onSubmit={(evt) => onUserReset(evt)}>
      <div className="addUserInputBlock">
        <label htmlFor="fname"
               className="addUserInputBlock__label">Name</label>

        <input type="text"
               value={names.fname}
               name="fname"
               onChange={onGetDataFromForm}/>
      </div>

      <div className="addUserInputBlock">
        <label htmlFor="lname"
               className="addUserInputBlock__label">Last Name</label>

        <input type="text"
               value={names.lname}
               name="lname"
               onChange={onGetDataFromForm}/>
      </div>

      <div className="addUserInputBlock">
        <label htmlFor="email"
               className="addUserInputBlock__label">Email</label>

        <input type="text"
               value={names.email}
               name="email"
               onChange={onGetDataFromForm}/>
      </div>

      <div className="addUserInputBlock">
        <label htmlFor="role"
               className="addUserInputBlock__label ">Role</label>

        <select name="role"
                onChange={onGetDataFromForm}
                className='withArrow'
        >
          {/*<option value=""></option>*/}
          {
            rolesArray.map((role, idx) => (
              <option key={`role-key-${idx}`}
                      value={role}>{role}</option>
            ))
          }
        </select>

        {
          selectedRoles.length > 0 &&
          <div className="addUserInputBlock__selectedRoles">
            {
              selectedRoles.map((role, idx) => (
                <span key={`selected-role-key-${idx}`}
                      onClick={() => onDeleteRole(idx)}>
                  {role}
                  <img src={deleteSvg} alt="delete"/>
                </span>
              ))
            }
          </div>
        }
      </div>

      <input type="submit"
             value="Add"
             disabled={!isValidForm}
             className={`addUsersForm__button ${!isValidForm ? 'btn--redBtn' : 'btn--greenBtn'}`}/>
    </form>
  );
};

export default AddUser;
