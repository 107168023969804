import React, {useEffect, useRef, useState} from "react";
import ethClient from "../../eth_client";
import NavButtons from './NavButtons';
import {message} from "../../../utils/message";

const MyTicketsOrderForm = ({calculation, order_place, activeItem, ...props}) => {
    const [orderFormType, setOrderFormType] = useState('buy');
    const [activeTab, setActiveTab] = useState('market');
    const buttonSettings = {
        text: 'Place buy order',
        cssClassForButton: 'myTicketsFinalizeOrder__doOrderBtn'
    };

    if (orderFormType === 'sell') {
        buttonSettings.text = 'Place sell order';
        buttonSettings.cssClassForButton += ' myTicketsFinalizeOrder__doOrderBtn--sell';
    } else {
        buttonSettings.cssClassForButton += ' myTicketsFinalizeOrder__doOrderBtn--buy';
    }

    let tabElement;
    const buttonsSssClasses = {
        marketBtn: 'orderSettingsNav__btn',
        stopBtn: 'orderSettingsNav__btn',
        limitBtn: 'orderSettingsNav__btn',
        parentOfButtons: 'orderSettingsNav'
    };

    switch (activeTab) {
        case 'limit':
            tabElement = <SubTab calculation={calculation} orderFormType={orderFormType} tokenDetail={activeItem}
                                 activeTab={activeTab} order_place={order_place} activeItem={activeItem}
                                 percetnageLevels={props.percetnageLevels} fnbd={props.fnbd}/>;
            // document.querySelector('.stopprice').style.display = 'none';
            // document.querySelector('.limitprice').style.display = '';
            buttonsSssClasses.limitBtn += ' orderSettingsNav__btn--active';
            buttonsSssClasses.parentOfButtons += ' orderSettingsNav--secondSelected';
            break;
        case 'stop':
            tabElement = <SubTab calculation={calculation} orderFormType={orderFormType} tokenDetail={activeItem}
                                 activeTab={activeTab} order_place={order_place} activeItem={activeItem}
                                 percetnageLevels={props.percetnageLevels} fnbd={props.fnbd}/>;
            // document.querySelector('.stopprice').style.display = '';
            // document.querySelector('.limitprice').style.display = 'none';
            buttonsSssClasses.stopBtn += ' orderSettingsNav__btn--active';
            buttonsSssClasses.parentOfButtons += ' orderSettingsNav--thirdSelected';
            break;
        default:
            if (document.querySelector('.stopprice') != null) {
                // document.querySelector('.stopprice').style.display = 'none';
                // document.querySelector('.limitprice').style.display = 'none';
            }
            tabElement = <SubTab calculation={calculation} orderFormType={orderFormType} tokenDetail={activeItem}
                                 activeTab={activeTab} order_place={order_place} activeItem={activeItem}
                                 percetnageLevels={props.percetnageLevels} fnbd={props.fnbd}/>;
            buttonsSssClasses.marketBtn += ' orderSettingsNav__btn--active';
            buttonsSssClasses.parentOfButtons += ' orяяяяяяderSettingsNav--firstSelected';
    }

    return (
        <div className="myTicketsOrderForm">
            <NavButtons orderFormType={orderFormType}
                        setOrderFormType={setOrderFormType}/>
            <div className="myTicketsOrderSettings">
                <div className={buttonsSssClasses.parentOfButtons}>
                    <button className={buttonsSssClasses.marketBtn}
                            onClick={() => setActiveTab('market')}>
                        Market
                    </button>

                    <button className={buttonsSssClasses.limitBtn}
                            onClick={() => setActiveTab('limit')}>
                        Limit
                    </button>

                    <button className={buttonsSssClasses.stopBtn}
                            onClick={() => setActiveTab('stop')}>
                        Stop
                    </button>
                </div>
                {tabElement}
            </div>
        </div>
    );
};

const SubTab = ({calculation, orderFormType, tokenDetail, activeTab, order_place, activeItem, ...props}) => {
    const [amount, setAmount] = useState(0);
    const [price, setPrice] = useState(0);
    const [makerFee, setMakerFee] = useState();
    const [takerFee, setTakerFee] = useState();
    const [total, setTotal] = useState();

    const amountInputRef = useRef();
    const priceInputRef = useRef();

    useEffect(() => {
        updateOrderForm();
    }, [orderFormType, activeTab]);

    const buttonSettings = {
        text: 'Place buy order',
        cssClassForButton: 'myTicketsFinalizeOrder__doOrderBtn'
    };

    if (orderFormType === 'sell') {
        buttonSettings.text = 'Place sell order';
        buttonSettings.cssClassForButton += ' myTicketsFinalizeOrder__doOrderBtn--sell';
    } else {
        buttonSettings.cssClassForButton += ' myTicketsFinalizeOrder__doOrderBtn--buy';
    }

    async function updateOrderForm() {

        let value = (amountInputRef.current) ? amountInputRef.current.value : 0;
        let userPrice = (priceInputRef.current) ? priceInputRef.current.value : 0;

        if (isNaN(parseFloat(value))) value = 0;
        if (isNaN(parseFloat(userPrice))) userPrice = 0;

        // calculationFee(value, userPrice);
    }

    const calculationFee = (value, user_price) => {
        let price = 1;
        if (activeItem.price) price = parseInt(activeItem.price);
        if (activeTab === "limit" && user_price !== 0) {
            price = user_price;
        }
        if (activeTab === "stop" && user_price !== 0) {
            // price = Math.min(user_price, price);
            price = user_price;
        }

        const userType = (orderFormType === "buy") ? "buyer" : "seller";
        const calcFeeObj = ethClient.matcher.calculateFee(value, price, activeTab, userType, props.percetnageLevels);

        setAmount(value);
        setMakerFee(calcFeeObj.maker);
        setTakerFee(calcFeeObj.taker);
        setTotal(calcFeeObj.total);
        setPrice(price)
    };

    async function PlaceOrder() {
        if (amount > 0) {
            let orderType = 0;
            let userType = 0;

            if (activeTab === "market") orderType = 0;
            if (activeTab === "limit") orderType = 1;
            if (activeTab === "stop") orderType = 2;

            if (orderFormType === "buy") userType = 0;
            if (orderFormType === "sell") userType = 1;

            order_place({
                tokenAddress: activeItem.address,
                quantity: amount,
                price: price,
                userType,
                orderType
            });
        } else {
            message('error', "You should provide amount to place order!");
        }
    }

    return (
        <>
            <div className="myTicketsOrderSettingsInput">
                <h4>Amount</h4>
                <div className="buy-tickets-block">
                    <button className="max-btn">MAX</button>
                    <input type="number"
                           className="buy-tickets-inputs"
                           ref={amountInputRef}
                           placeholder="0.00"
                           onKeyUp={updateOrderForm}/>
                    <label> {activeItem ? activeItem.symbol : "USDC"}</label>
                </div>
            </div>
            {activeTab === "stop" && <>
                <div className="myTicketsOrderSettingsInput">
                    <h4>Stop Price </h4>
                    <div>
                        <input type="number" placeholder="0.00" ref={priceInputRef} onKeyUp={updateOrderForm}/>
                        <label>USDC</label>
                    </div>
                </div>


                <div className="myTicketsFinalizeOrder">
                    <div className="myTicketsFinalizeOrder__total">
                        <div>
                            <span>Taker fee</span>
                            <span>{takerFee} {(orderFormType === "sell") ? activeItem.symbol : "USDC"}</span>
                        </div>
                        <div>
                            <span>Total</span>
                            <span>{total} USDC</span>
                        </div>
                    </div>
                </div>
            </>
            }


            {activeTab === "limit" &&
            <>
                <div className="myTicketsOrderSettingsInput">
                    <h4>Limit Price </h4>
                    <div>
                        <input type="number" ref={priceInputRef} placeholder="0.00" onKeyUp={updateOrderForm}/>
                        <label> USDC </label>
                    </div>

                </div>
                <div className="myTicketsFinalizeOrder">
                    <div className="myTicketsFinalizeOrder__total">
                        <div>
                            <span>Maker fee</span>
                            <span>{makerFee} {(orderFormType === "sell") ? activeItem.symbol : "USDC"}</span>
                        </div>
                        <div>
                            <span>Total</span>
                            <span>{total} USDC</span>
                        </div>
                    </div>
                </div>
            </>
            }

            {activeTab === "market" &&
            <>

                <div className="myTicketsFinalizeOrder">
                    <div className="myTicketsFinalizeOrder__total">
                        <div>
                            <span>Taker fee</span>
                            <span>{takerFee} {(orderFormType === "sell") ? activeItem.symbol : "USDC"}</span>
                        </div>
                        <div>
                            <span>Total</span>
                            <span>{total} USDC</span>
                        </div>
                    </div>
                </div>
            </>
            }
            {/*<div className={buttonSettings.cssClassForButton}/>*/}
            <div className="myTicketsFinalizeOrder">

                <div className={buttonSettings.cssClassForButton}>
                    <button onClick={PlaceOrder}>{buttonSettings.text}</button>
                </div>
            </div>
        </>
    );
};

export default MyTicketsOrderForm;
