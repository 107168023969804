import React, {Component} from 'react';
import closeSvg from '../../../assets/images/close--blue.svg';
import rightArrowSvg from '../../../assets/images/right-chevron.svg';
import leftArrowSvg from '../../../assets/images/left-chevron.svg';
import './viewImages.css';
import InternalControlPanel from "../InternalControlPanel";

class ViewImage extends Component {
    state = {
        imageList: [],
        index: 0,
    };

    componentDidMount() {
        const {
            sources,
            selectedImg
        } = this.props;

        if (sources.length > 0) {
            this.setState({
                index: sources.findIndex(src => src === selectedImg),
                imageList: sources
            });
        }
    }

    onClose = () => {
        this.props.onClose();
    };

    onNext = () => {
        this.setState(({index, imageList}) => ({
            index: imageList.length - 1 === index ? 0 : index + 1
        }));
    };

    onPrev = () => {
        this.setState(({index, imageList}) => ({
            index: index === 0 ? imageList.length - 1 : index - 1
        }));
    };

    render() {
        const {
            imageList,
            index
        } = this.state;

        // const image = typeof imageList[Exchange] === 'object' ? imageList[Exchange].src : imageList[Exchange];

        return (
            <div className="slideshowModal">
                <button className="slideshowModalWrapper__closeButton slideshowModalButton"
                        onClick={this.onClose}>
                    <img src={closeSvg} alt="close"/>
                </button>

                <div className="slideshowModalWrapper">
                    <button className="slideshowModalWrapper__rightArrow slideshowModalButton"
                            onClick={this.onNext}>
                        <img src={rightArrowSvg} alt="right"/>
                    </button>

                    <button className="slideshowModalWrapper__leftArrow slideshowModalButton"
                            onClick={this.onPrev}>
                        <img src={leftArrowSvg}
                             alt="left"/>
                    </button>

                    <div className="slideshowModalImgBox">
                        <img src={this.props.sources[index] ? this.props.sources[index] : ""} alt=""/>
                    </div>
                </div>
                <InternalControlPanel index={index} onDeleteImg={this.props.onDeleteImg}
                                      onChangeImage={this.props.onChangeImage} isDeleted={this.props.sources[index] === 'deleted'}/>
            </div>
        );
    }
}

export default ViewImage;