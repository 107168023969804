import axios from "axios";
import constants from "../../constant";
import {proofOfWork} from "../../../Pages/Contract/dataForSelects";

const auth = constants.auth;

class Projects {

    constructor() {}

    createProject(data, callback) {
        // ipPercentage:this.state.ipPercentage,
        // licenseType:this.state.licenseType
        console.log(data, '--------project: data')
        if (isNaN(data.count)) {
            data.count = 0;
        }
        const pow = proofOfWork.indexOf(data.pow);
        data.pow = Math.max(0, pow);

        if (data.licenseType === "unlimited") {
            data.licenseType = 1;
        } else {
            data.licenseType = 0;
        }

        axios({
            method: 'post',
            url: `${constants.apiRoot}/contracts/projects/createProject`,
            responseType: 'json',
            // auth,
            data
        }).then((response) => {
            callback(response.data);
        });
        // axios({
        //     method: 'post',
        //     url: `${constants.apiRoot}/contracts/projects/createProject`,
        //     responseType: 'json',
        //     auth,
        //     data
        // }).then((response) => {
        //     callback(response.data);
        // });
    }

    async getProjects(address) {
        const result = await axios({
            method: "get",
            url: `${constants.apiRoot}/contracts/projects/getProjects`,
            responseType: 'json',
            // auth,
            params: {
                address
            }
        });
        return result.data.projects;
    }

    // async getProjectById(id) {
    //     const result = await axios({
    //         method: "get",
    //         url: `${constants.apiRoot}/contracts/projects/getProjectById`,
    //         auth,
    //         params: {
    //             id
    //         }
    //     });
    //     return result.data.project;
    // }

    async getProjectsByIds(ids) {
        const result = await axios({
            method: "post",
            url: `${constants.apiRoot}/contracts/projects/getProjectsByIds`,
            auth,
            data: {
                ids
            }
        });
        return result;
    }

    // async getProjectsByTokenURIs(tokenUrls) {
    //     const result = await axios({
    //         method: "post",
    //         url: `${constants.apiRoot}/contracts/projects/getProjectsByTokenURIs`,
    //         auth,
    //         data: {
    //             tokenUrls
    //         }
    //     });
    //     return result.data.projects;
    // }

    // async investOnProject(pid, account, money) {
    //
    //     const result = await axios({
    //         method: "post",
    //         url: `${constants.apiRoot}/contracts/projects/investOnProject`,
    //         auth,
    //         data: {
    //             pid,
    //             account,
    //             money
    //         }
    //     });
    //     return result;
    // }

    async getPollData(pid) {
        const result = await axios({
            method: "get",
            url: `${constants.apiRoot}/contracts/projects/getPollData`,
            auth,
            params: {
                pid
            }
        });
        return result.data.poll_data;
    }

    async getAllProjects(offset, limit, licenseType) {
        const result = await axios({
            method: 'get',
            url: `${constants.apiRoot}/contracts/projects/getAllProjects`,
            responseType: 'json',
            auth,
            params: {
                offset,
                limit,
                licenseType
            }
        });
        return result.data.projects
    }
}

export default new Projects();
