import React, {useCallback, useEffect, useState} from "react";
import ethClient from "../../eth_client";
import ethClient2 from "../../eth_client_public";
import constants from "../../eth_client_public/constants"
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import web3 from 'web3';
import closeSvg from "../../../assets/images/close--blue.svg";
import ETH from "../../../assets/images/logos/eth.png";
import USDC from "../../../assets/images/logos/usdc.png";
import LOOM from "../../../assets/images/logos/loom.png";
import {approveJson, contractAddress, deposit_amt, depositJson, getPrice, ItemsPairs, pk,} from "./centralTokenData";
import CentralTokenDepositModal from "./CentralTokenDepositModal/CentralTokenDepositModal";
import CentralTokenWithdrawModal from "./CentralTokenWithdrawModal/CentralTokenWithdrawModal";
import {message} from "../../../utils/message";
import {walletService} from "../../api/tendermint/main";
import { getAltCoinsBalance} from '../../../utils/temndermintWalletsUtils';
const ASSETS = ["ETH", "USDC", "LOOM", "BAT", "ZRX"]


const MyTokensListContainer = ({tokens, walletAddress, ethWallet}) => {

    console.log(tokens, '----------------------tokens-----------------------tknpage')


    const [activeTab, setActiveTab] = useState('token');
    const [statusPopup, setStatusPopup] = useState(false);
    const [popupPage, setPopupPage] = useState(null);
    const [activeTabPop, setActiveTabPop] = useState('Withdraw');
    const [activeTabDeposit, setactiveTabDeposit] = useState('deposit');
    const [activeTabPortfolio, setActiveTabPortfolio] = useState('Balances');
    const [amount, setAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [coin_name, setCoin_name] = useState(null);
    const [contractAddr, setContractAddr] = useState('');
    const [balances, setBalances] = useState({ETH: "", USDC: "", LOOM: ""});
    const [selectedCurrency, selectCurrency] = useState('ETH');
    const [withdrawInfo, setWithdrawInfo] = useState({amount: "", to: ""})
    const [walletPassword, setWalletPassword] = useState("")
    const [wallyBalance, setWallyBalance] = useState("")



// const foo2 = async()=> {
//         console.log('___UWUWU')
//     let q = await getWallyBalance()
//     q && setWallyBalance(q)
//    let a = await getWallyWalletAddress();
//         let b = await getEthWalletAddress()
//    let c = await getWallyWalletBalance()
//
//
//    console.log(a, '--', b, '--b', c, '-------a,b, 🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶', q)
//
// }
//
//     useEffect(()=> {
//         foo2()
//     }, [])

    // Fetch balances of ETH, USDC and LOOM
    // Data returned is already formatted with corresponding decimals
    const getBalances = useCallback(async () => {
        //need to uncomment==>
        // const response = await ethClient2.getBalances('0x0a574141f1fd348B49CE10782EE1AA6a03B1268f', ASSETS);
        // const response = 100;
        //need to uncomment==>
        //==> this is balance of FNB (USDW)
        // const response2 = await ethClient.eth.getFnbdBalance(walletAddress)
        // const response2 = await ethClient.eth.getFnbdBalance('0x0a574141f1fd348B49CE10782EE1AA6a03B1268f')
        // console.log(response2, ' 🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝')
        //need to uncomment==>
        // const _balances = {FNBD: response2};
        const _balances = {FNBD: 1000};
        let a = await getAltCoinsBalance()
        console.log(a, ' 🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝🐝')
        //
        // constants.ASSETS.map(a => {
        //     console.log(a, '----a', 'USDW')
        //     if (a !== "USDW")
        //         if (a!=="FNBD") {
        //             _balances[a] = response[a]
        //         }
        // })
        //
        // console.log(_balances, '----_balances')
        // setBalances(_balances)
    }, []);

    useEffect(() => {
        if (walletAddress !== null) {
            getBalances()
        }
        // handleSellFNBD()
    }, [getBalances]);

    // withdraw ETH or ERC-20 tokens to another Wallet "to"
    const handleWithdrawal = async () => {
        try {
            message('info', `Withdrawing ${withdrawInfo.amount} ${selectedCurrency}...`);

            const res = await ethClient2.processWithdrawal(
                walletAddress,
                withdrawInfo.to,
                withdrawInfo.amount,
                walletPassword,
                selectedCurrency
            );

            if (res.status) {
                message('success', "Withdrawal Confirmed!");
                setWithdrawInfo({amount: "", to: ""})
                setWalletPassword("")
                getBalances();
            }

        } catch (error) {
            console.log(error);
        }
    }


    const setCurrentTab = (activeTab, type) => {
        type === "pop"
            ? setActiveTabPop(activeTab)
            : type === "portfolio" ?
            setActiveTabPortfolio(activeTab)
            : setActiveTab(activeTab)
    };


    const openModal = (value, page, item) => {
        setStatusPopup(value);
        setPopupPage(page);
        setCoin_name(item.symbol);
        setContractAddr(item.contractAddress);
    };

    const closeModal = (value, page) => {
        setStatusPopup(value);
        setPopupPage(page);
        setCoin_name(null);
    };

    const handleInputChange = (event, setEvent) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setEvent(value);
    };

    // =====>
    // its old lavanya`s code
    const depositMethod = (address, amount) => {
        const deposit_amt = deposit_amt;
        const coin_name = coin_name;
        const Contract = new web3.eth.Contract(depositJson, contractAddress);
        const Account = web3.eth.accounts.privateKeyToAccount(pk);
        const Address = Account.address;
        const Response = Contract.methods.deposit(address, amount).encodeABI();

        web3.eth.getTransactionCount(Address, "pending").then(_nonce => {
            const tx = {
                from: Address,
                to: contractAddress,
                gasPrice: 10,
                gasLimit: 70000,
                data: Response,
                nonce: _nonce
            };

            if (coin_name === 'ETH') {
                tx.value = (deposit_amt * 1000000000000000000).toString()
            }

            try {
                web3.eth.accounts.signTransaction(tx, pk).then(signed => {
                    try {
                        web3.eth.sendSignedTransaction(signed.rawTransaction, (err, hash) => {
                            if (!err && hash) {
                                toastr.success('Successfully Deposited. Your hash value is ' + hash)
                            } else {
                                toastr.error(err)
                            }
                            setIsLoading(false)
                        });
                    } catch (e) {
                        toastr.error(e);
                        setIsLoading(false)
                    }
                });
            } catch (e) {
                toastr.error(e);
                setIsLoading(false)
            }
        });
    };

    const approveMethod = (address, amount) => {
        const coin_address = contractAddr;
        const approveAmt = (amount * 1000000000000000000).toString();
        const Contract = new web3.eth.Contract(approveJson, contractAddress);
        const Account = web3.eth.accounts.privateKeyToAccount(pk);
        const Address = Account.address;
        const Response = Contract.methods.approve(coin_address, approveAmt).encodeABI();

        web3.eth.getTransactionCount(Address, "pending").then(_nonce => {
            const tx = {
                from: Address,
                to: contractAddress,
                gasPrice: 10,
                gasLimit: 70000,
                data: Response,
                nonce: _nonce
            };

            try {
                web3.eth.accounts.signTransaction(tx, pk).then(signed => {
                    try {
                        web3.eth.sendSignedTransaction(signed.rawTransaction, (err, hash) => {
                            if (!err &&  hash) {
                                toastr.success('Successfully Approved. Your hash value is ' + hash);
                                depositMethod(coin_address, approveAmt);
                            } else {
                                toastr.error(err)
                            }
                            setIsLoading(false)
                        });
                    } catch (e) {
                        toastr.error(e);
                        setIsLoading(false)
                    }
                });
            } catch (e) {
                toastr.error(e);
                setIsLoading(false)
            }
        });
    };

    const addValue = (e) => {
        setAmount(e.target.value * getPrice)
    };

    const updateAmount = (_amount) => {
        setWithdrawInfo({...withdrawInfo, amount: _amount})
    };

    const updateTo = (_to) => {
        setWithdrawInfo({...withdrawInfo, to: _to})
    };

    const updatePassword = (e) => {
        setWalletPassword(e.target.value)
    };


    const makeDeposit = () => {
        setIsLoading(true);

        toastr.clear();

        if (!coin_name) {
            toastr.error('Invalid deposit coin address.');
            return false;
        }

        if (deposit_amt > 0) {
            if (coin_name === 'ETH') {
                depositMethod('0x0000000000000000000000000000000000000000', 0);
            } else {
                approveMethod(contractAddr, deposit_amt);
            }
        } else {
            toastr.error('Enter the valid amount');
            setIsLoading(false)
        }
    };

    // <-------
    // its old lavanya`s code end


    // withdraw ETH or ERC-20 tokens to another Wallet "to"
    // const handleWithdrawal = async () => {
    //     try {
    //         console.log(`Withdrawing ${withdraw.amount} ${withdrawToken}...`);
    //         const res = await 2.processWithdrawal(state.walletAddress, withdraw.to, withdraw.amount, "password", withdrawToken)
    //
    //         if (res.status) {
    //             alert("Withdrawal Confirmed!", res);
    //             setWithdraw({amount: "", to: ""});
    //             getBalances();
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const changePairsTab = (value) => {
    //     state.pairsList.forEach((i) => {
    //         if (i.tab === value) {
    //             setState({
    //                 ...state,
    //                 pairsListArray: i.convert
    //             });
    //             console.debug('_________i:', i)
    //         }
    //     })
    // };

    // const sendEth = (e) => {
    //     e.preventDefault();
    //     console.log(walletAddressInput.current.value);
    //     console.log(passwordInput.current.value);
    //     console.log(ethAmountInput.current.value);
    //     let toAddress = walletAddressInput.current.value;
    //     let fromAddress = state.walletAddress;
    //     let walletPassword = passwordInput.current.val;
    //     let ethAmount = ethAmountInput.current.value;
    //     2.sendETH(fromAddress, toAddress, ethAmount, walletPassword).then((result) => {
    //         console.log("result --->", result);
    //     });
    // };
    return (
        <div className="myTicketsListContainer">
            <div className={`myTicketsListContainer__header myTickets--title ${activeTab}`}>
                <button className={`btn-tab ${(activeTab === "token") ? "active" : ""}`}
                        onClick={() => setCurrentTab("token")}>
                    My Tokens
                </button>
                <button
                    className={`btn-tab ${(activeTab === "pair") ? "active" : ""}`}
                    onClick={() => setCurrentTab("pair")}>
                    My Pairs
                </button>
            </div>
            {activeTab === "token" &&
            <ul className="myTicketsList">
                {tokens.map((oneItem, idx) => (
                    <li className={`myTicketsListItem`} key={`my_tickets_item_key_${idx}`}>
                        <div className="myTickets_flex">
                            <div className={oneItem.symbol == "WALLY" || oneItem.symbol=="USDW" ? "myTicketsListItem__logo_wout_border" : "myTicketsListItem__logo"}>
                                <img src={oneItem.image} alt="Logo"/>
                            </div>
                            <div className="myTicketsListItemInfo">
                                <p className="myTicketsListItemInfo__title">{oneItem.symbol}</p>
                                <p className="myTicketsListItemInfo__value">{oneItem.balance}</p>
                            </div>
                        </div>
                        <div className='control-buttons__block'>
                            {/*<button>Buy</button>*/}
                            <button onClick={() => openModal(true, 'deposit', oneItem)}>Deposit</button>
                            {/*<button>Sell</button>*/}
                            <button onClick={() => openModal(true, 'withdraw', oneItem)}>Withdraw</button>
                        </div>
                    </li>
                ))}
            </ul>}
            {activeTab === "pair" &&
            <ul className="myTicketsList">
                {ItemsPairs && ItemsPairs.map((pairs) =>
                    <div className="myTokens_flex__block">
                        <div className="myTokens_pairs__block">
                            <div className="myTokens_pairs__right">
                                <img src={pairs.imgFirst} alt='imgFirst'/>
                                {pairs.nameFirst}
                            </div>
                            <span className="myTokens_pairs__arrow"> → </span>
                            <div className="myTokens_pairs__right">
                                <img src={pairs.imgSecond} alt='imgSecond'/>{pairs.nameSecond}
                            </div>
                        </div>
                        {/*<div className="myTokens_buttons__block">*/}

                        {/*</div>*/}
                    </div>
                )}
            </ul>}
            <CentralTokenDepositModal walletAddress={walletAddress}
                                      ethWallet={ethWallet}
                                      activeTabDeposit={activeTabDeposit}
                                      selectedCurrency={selectedCurrency}
                                      selectCurrency={selectCurrency}
                                      handleInputChange={handleInputChange}
                                      isLoading={isLoading}
                                      makeDeposit={makeDeposit}
                                      popupPage={popupPage}
                                      setactiveTabDeposit={setactiveTabDeposit}
                                      closeSvg={closeSvg}
                                      balances={balances}
                                      closeModal={closeModal}
                                      setCurrentTab={setCurrentTab}
                                      activeTabPortfolio={activeTabPortfolio}
                                      statusPopup={statusPopup}
            />
            <CentralTokenWithdrawModal walletAddress={walletAddress}
                                       ethWallet={ethWallet}
                activeTabPop={activeTabPop}
                                       walletAddress={walletAddress}
                                       selectedCurrency={selectedCurrency}
                                       selectCurrency={selectCurrency}
                                       addValue={addValue}
                                       amount={amount}
                                       balances={balances}
                                       activeTabPortfolio={activeTabPortfolio}
                                       setCurrentTab={setCurrentTab}
                                       popupPage={popupPage}
                                       closeModal={closeModal}
                                       statusPopup={statusPopup}
                                       makeWithdraw={handleWithdrawal}
                                       updateAmount={updateAmount}
                                       updateTo={updateTo}
                                       withdrawInfo={withdrawInfo}
                                       updatePassword={updatePassword}
            />
        </div>
    )
};

export default MyTokensListContainer;
