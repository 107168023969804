import React from "react"
import {sendParseRequest} from "../../../../utils/sendParseRequest";
import Parse from "parse";
import SettingsPageHeader from "../../../Components/SettingsPageHeader";
import {ModalWrapper} from "../../../Components/ModalWrapper/modal";
import IntlTelInput from "react-intl-tel-input";
import phoneImg from "../../../../assets/images/smartphone.png";
import {AuthenticatorSettings} from "../../../Components/AuthenticatorSettings/AuthenticatorSettings";
import {AuthenticatorManager} from "../../../Components/AuthenticatorManager/AuthenticatorManager";
import {message} from "../../../../utils/message";

export class Security extends React.Component {
    state = {
        phone: "",
        phonesList: [],
        phoneVerified: false,
        changePasswordReset: false,
        messageType1: "",
        messageType: "",
        addNewPhone: false,
        newNumber: "",
        phonesArray: [],
        email: "",
        numberAdd: false,
        verifyCode: "",
        codeEntered: "",
        isTwoStepAuthenticationEnabled: false,
        showAuthenticatorSettings: false,
        showAuthenticatorManager: false,
        isSMSAuthenticator: false,
        emailVerification: null,
        loadingUserData: true,
        fname: ''
    };

    componentDidMount() {
        document.title = "Security – FNBD";

        sendParseRequest('getUserInfoById', {id: Parse.User.current().id})
            .then(res => {
                const currUser = res;

                const phones = currUser.get('phones') || [];
                let phone = '';

                phones.forEach(item => {
                    if (item.split(':::')[2] === "primary") {
                        phone = item.split(':::')[0];
                    }
                });

                phone = currUser.get('phone') === undefined ? phone : currUser.get('phone');
                const email = currUser.get('email');
                const phoneVerified = currUser.get('phoneVerified');
                const isTwoStepAuthenticationEnabled = currUser.get('isTwoStepAuthenticationEnabled');
                const isSMSAuthenticator = currUser.get('isSMSAuthenticator');
                const phonesArray = currUser.get('phones') || [];
                const emailVerification = currUser.get('eVerified');
                const fname = currUser.get('fname');

                this.setState({
                    email,
                    phone,
                    phoneVerified,
                    isTwoStepAuthenticationEnabled,
                    isSMSAuthenticator,
                    phonesArray,
                    emailVerification,
                    loadingUserData: false,
                    fname,
                });

                localStorage.setItem('isSMSAuthenticator', JSON.stringify(isSMSAuthenticator));
                localStorage.setItem('isTwoStepAuthenticationEnabled', JSON.stringify(isTwoStepAuthenticationEnabled));
            })
    }

    handleChangePassword = () => {
        this.setState({changePasswordReset: !this.state.changePasswordReset});
    };

    handleResetP = () => {
        Parse.User.requestPasswordReset(this.state.email)
            .then(() => {
                Parse.User.logOut().then(() => {
                    this.props.history.push('/login');
                    window.location.reload();
                });
            })
            .catch(error => message('error', error.message))
    };

    handlePhone = (status, value, countryData, number) => {
        this.setState({"newNumber": number});
    };

    sendNewNumberV = () => {
        const phoning = this.state.newNumber.replace(/-/g, '').replace(/ /g, '');
        if (this.state.newNumber !== "" && phoning !== this.state.phone && this.state.phonesArray.includes(phoning) === false) {
            sendParseRequest('sendAdditionalPhoneCode', {pn: this.state.newNumber})
                .then(res => this.setState({verifyCode: res, "numberAdd": true}))
        } else {
            message('error', 'Number is already added.')
        }
    };

    verifyCodeNumber = () => {
        const {phone, verifyCode, codeEntered} = this.state;

        if (verifyCode.toString() === codeEntered.toString()) {
            const phones = [...this.state.phonesArray];
            const email = this.state.newNumber.replace(/-/g, '').replace(/ /g, '');
            phones.push(email + ":::" + "verified" + (!phone ? ":::primary" : ""));

            sendParseRequest('updateInfo', {
                searchData: {
                    query: 'User',
                    equal: [{field: 'objectId', value: Parse.User.current().id}]
                },
                updateData: [{field: 'phoneVerified', value: true}, {field: 'phones', value: phones}]
            }).then(() => {
                sendParseRequest('sendEmail', {
                    emailSendFrom: 'info@fanthebase.com',
                    emailToSend: this.state.email,
                    message: 'Thanks for adding your phone number!'
                }).then(() => window.location.reload())
            })
        } else {
            message('error', 'Incorrect code');
        }
    };

    switchTwoStepAuthentication = (options) => {
        const {isSMSAuthenticator, isTwoStepAuthenticationEnabled} = options;
        sendParseRequest('updateInfo', {
            searchData: {
                query: 'User',
                equal: [{field: 'objectId', value: Parse.User.current().id}]
            },
            updateData: [
                {field: 'isSMSAuthenticator', value: isSMSAuthenticator},
                {field: 'isTwoStepAuthenticationEnabled', value: isTwoStepAuthenticationEnabled}
            ]
        }).then(() => {
            localStorage.setItem('isSMSAuthenticator', JSON.stringify(isSMSAuthenticator));
            localStorage.setItem('isTwoStepAuthenticationEnabled', JSON.stringify(isTwoStepAuthenticationEnabled));

            this.setState({
                isTwoStepAuthenticationEnabled: isTwoStepAuthenticationEnabled,
                isSMSAuthenticator: isSMSAuthenticator
            })
        })
    };

    handleAuthenticatorSettings = () => {
        this.setState({showAuthenticatorSettings: !this.state.showAuthenticatorSettings});
    };

    handleAuthenticatorManager = () => {
        const {showAuthenticatorManager} = this.state;
        this.setState({showAuthenticatorManager: !showAuthenticatorManager});
    };

    getHiddenNumber = (number = '') => {
        return number.length > 1 && typeof (number) === "string" ? number.replace(/\d+/, 'x'.repeat(number.length - 3)) + number.slice(number.length - 2) : '';
    };

    handleSMSAuthenticator = () => {
        const {isSMSAuthenticator} = this.state;
        this.switchTwoStepAuthentication({
            isTwoStepAuthenticationEnabled: !isSMSAuthenticator,
            isSMSAuthenticator: !isSMSAuthenticator
        })
    };

    changePrimaryPhone = () => {
        const phones = this.state.phonesArray;
        const currUserId = Parse.User.current().id;
        const idxOfPrevPrimaryPhone = phones.findIndex((phone) => /primary/i.test(phone));
        const nextIdxOfPhones = phones.length - 1 === idxOfPrevPrimaryPhone ? 0 : idxOfPrevPrimaryPhone + 1;

        phones[idxOfPrevPrimaryPhone] = phones[idxOfPrevPrimaryPhone].replace(/:::primary/g, '');
        phones[nextIdxOfPhones] = phones[nextIdxOfPhones] + ':::primary';

        sendParseRequest('updateInfo', {
            searchData: {
                query: 'User',
                equal: [{field: 'objectId', value: currUserId}]
            },
            updateData: [{field: 'phones', value: phones}]
        }).then(() => {
            this.setState({
                phonesArray: phones,
                phone: phones[nextIdxOfPhones].match(/\d{10,13}/)[0]
            });
        })
    };

    sendVerifyLetter = () => {
        sendParseRequest('verifyEmailSignup', {
            email: this.state.email,
            objectId: Parse.User.current().id,
            fname: this.state.fname
        }).then(res => {
            if (res.SendEmailResult) {
                message('success', 'The letter was sent')
            }
        })
    }

    saveCodeEntered = e => {
        if (e.target.value.toString().length < 7) {
            this.setState({codeEntered: e.target.value})
        }
    }

    render() {
        const {
            showAuthenticatorSettings,
            showAuthenticatorManager,
            isTwoStepAuthenticationEnabled,
            isSMSAuthenticator,
            phone,
            phonesArray,
            emailVerification,
            loadingUserData
        } = this.state;


        return (
            <div className="page settingsPage">
                <div className="dashWrapperB" style={{marginBottom: '20px'}}>
                    <div className="dashModal seperator" style={{"width": "10%"}}/>
                    <div className="dashModal contentPage" style={{"width": "80%"}}>
                        <div className="content " style={{"height": "auto"}}>
                            <SettingsPageHeader/>
                            <div className="settingsWrapper">
                                <p className='title mobile-title'>Email</p>
                                {loadingUserData
                                    ? <p className='settings__email-block'>Data is loading...</p>
                                    : <div className='settings__email-block'>
                                        {emailVerification
                                            ? <p>Your email address has been verified</p>
                                            : <div>
                                                    <span className='settings__email-block__send-link'
                                                          onClick={this.sendVerifyLetter}>Click</span>
                                                <span>to send an email with a link to the mail</span>
                                            </div>}
                                    </div>}
                                <p className="title mobile-title">Phone Number</p>
                                <div className="instantChangesCo"
                                     style={{"paddingLeft": "0px", "paddingTop": "20px", "paddingBottom": "20px"}}>
                                    <ul>
                                        {phone !== ("" && undefined)
                                            ? <li style={{"padding": "0", "width": "50%"}}>
                                                <div className="phoneNumber">
                                                    {this.state.phonesArray.map((p, i) => (
                                                        <span key={i} className='phoneNumber__number-block'>
                                                                            <span className="phoneNumb">
                                                                                {this.getHiddenNumber(p.split(':::')[0])}
                                                                            </span>
                                                            {p.split(':::')[1] === "verified" &&
                                                            <span className="verified">Verified</span>}

                                                            <span className="primaryPhone">
                                                                            {p.split(':::')[2] || 'secondary'} Phone
                                                                        </span>
                                                                        <br/><br/>
                                                                    </span>
                                                    ))}
                                                    <div className='uploadButton__block'>
                                                        <p className="uploadButton">
                                                            <input
                                                                onClick={() => this.setState({"addNewPhone": true})}
                                                                type="submit"
                                                                value="Verify another phone"/>
                                                        </p>
                                                        <p className="uploadButton phoneNumber__uploadBtn--white">
                                                            <input onClick={this.changePrimaryPhone}
                                                                   type="submit"
                                                                   value="Change primary phone"/>
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            : <li style={{"padding": "0", "width": "50%"}}>
                                                <p className="uploadButton">
                                                    <input onClick={() => this.setState({"addNewPhone": true})}
                                                           type="submit"
                                                           value="Add a Primary Phone"/>
                                                </p>
                                            </li>}
                                        <li style={{"width": "50%"}}>
                                            <div className="changePictureCon changePasswordContainer">
                                                <p className="changePictureText pull-left ">
                                                    <span>{this.state.changePasswordReset === true ? "Are you sure?" : "Change Password"}</span>
                                                    <span>{this.state.changePasswordReset === true ? "We will send a reset password email." : "We send you a reset password email."}</span>
                                                </p>
                                                <p className="uploadButton pull-right">
                                                    {this.state.changePasswordReset === true
                                                        ? <span>
                                                            <a onClick={this.handleChangePassword}>Never Mind</a>&emsp;
                                                            <a onClick={this.handleResetP} style={{
                                                                "background": "rgb(0, 106, 208)",
                                                                "border": "0px",
                                                                "color": "white"
                                                            }}>Reset</a>
                                                        </span>
                                                        : <a onClick={this.handleChangePassword}>Change Password</a>
                                                    }
                                                </p>
                                                <br style={{"clear": "both"}}/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <br/>
                                <p className="title mobile-title">Two Factor Authentication</p>
                                <br/>
                                <div className="twoFactorAuth">
                                    <div className="pull-left twoFactor1">
                                        {/*<p className="changePictureText" style={{ "paddingLeft": "0" }}>*/}
                                        {/*<span ref="changePictureTab">Your two factor method is: SMS</span>*/}
                                        {/*<span>For more security, enable an authenticator app.</span>*/}
                                        {/*<br/>*/}

                                        {/*<i className="fa fa-check" style={{ "color": "#28cd6a" }}></i>*/}
                                        {/*</p>*/}
                                        <div className="enable-authenticator">
                                            <div className="enable-text-group">
                                                <div>
                                                    <span className="enable_title">Authenticator App</span>
                                                    {isTwoStepAuthenticationEnabled && !isSMSAuthenticator ?
                                                        <span className="enable_desc green">
                                                                Enabled</span>
                                                        : null}
                                                </div>
                                                <span
                                                    className="enable_desc">Install an authenticator app on your phone</span>
                                            </div>
                                            <div>
                                                {isTwoStepAuthenticationEnabled && !isSMSAuthenticator
                                                    ? <input className="show-pop-up-authenticator" size={7}
                                                             type="button" value={"Manage"}
                                                             onClick={this.handleAuthenticatorManager}
                                                    />
                                                    : <input className="show-pop-up-authenticator" size={6}
                                                             type="button" value={"Enable"}
                                                             onClick={this.handleAuthenticatorSettings}
                                                    />}
                                            </div>
                                        </div>
                                        <div className="enable-authenticator">
                                            <div className="enable-text-group">
                                                <div>
                                                    <span className="enable_title">Text Message / SMS</span>
                                                    {isTwoStepAuthenticationEnabled && isSMSAuthenticator ?
                                                        <span className="enable_desc green">
                                                                Enabled
                                                            </span>
                                                        : null}
                                                </div>
                                                <span className="enable_desc">
                                                    Phone Number: {this.getHiddenNumber(phone)}
                                                </span>
                                            </div>
                                            <div>
                                                {isTwoStepAuthenticationEnabled && isSMSAuthenticator
                                                    ? <input className="show-pop-up-authenticator" size={7}
                                                             type="button" value={"Disable"}
                                                             onClick={this.handleSMSAuthenticator}
                                                    />
                                                    : <input disabled={phonesArray.length === 0}
                                                             className="show-pop-up-authenticator" size={6}
                                                             type="button" value={"Enable"}
                                                             onClick={this.handleSMSAuthenticator}
                                                    />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="dashModal seperator" style={{"width": "10%"}}/>
                    <br style={{"clear": "both"}}/>
                </div>
                <ModalWrapper active={this.state.addNewPhone} hide={() => this.setState({"addNewPhone": false})}>
                    <div className="addNewPhoneWrapper">
                        {!this.state.numberAdd
                            ? <span>
                                <p className="title">Verify a new number</p>
                                <div className="uploadButton">
                                    <IntlTelInput onPhoneNumberChange={this.handlePhone}
                                                  id="phone"
                                                  format="false"
                                                  inputClassName='IntlTelInput__input'
                                    />
                                    <a onClick={this.sendNewNumberV}>Send verification code</a>
                                </div>
                            </span>
                            : <span>
                                <p className="title">What's the 6 digit number?</p>
                                <span className="addNewPhoneWrapper__descr">Your code texted to phone number:</span>
                                <div className="addNewPhoneWrapper__img">
                                    <img src={phoneImg} alt="phone"/>
                                </div>
                                <input value={this.state.codeEntered}
                                       onChange={(this.saveCodeEntered)}
                                       type="number" style={{"fontSize": "20px"}} max="7"
                                       className="numberInput"
                                />
                                <p className="uploadButton">
                                    <a onClick={this.verifyCodeNumber}>Verify Number</a>
                                </p>
                            </span>}
                    </div>
                </ModalWrapper>
                {showAuthenticatorSettings
                    ? <AuthenticatorSettings handleAuthenticatorSettings={this.handleAuthenticatorSettings}
                                             switchTwoStepAuthentication={this.switchTwoStepAuthentication}/>
                    : null}
                {showAuthenticatorManager
                    ? <AuthenticatorManager handleAuthenticatorManager={this.handleAuthenticatorManager}
                                            handleAuthenticatorSettings={this.handleAuthenticatorSettings}
                                            switchTwoStepAuthentication={this.switchTwoStepAuthentication}/>
                    : null}
            </div>
        )
    }
}
