import React from "react";
import Parse from "parse";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";
import { isEmpty } from "../../../../utils/isEmpty";
import isEmail from "../../../../utils/isEmail";
import DownloadToIpfsOrAws from "../../../Components/DownloadToIpfsOrAws/DownloadToIpfsOrAws";
import InfoTooltip from "../../../Components/InfoTooltip";
import {
    tooltipBeneficialAddress,
    tooltipBeneficialId,
    tooltipBeneficialMessage,
    tooltipBeneficialNumber,
    tooltipPdfMessage
} from "../../../Components/DownloadToIpfsOrAws/constants";
import { set_city_state, setRegions } from "../../../../utils/city_state";
import { sendParseRequest } from "../../../../utils/sendParseRequest";
import { message } from "../../../../utils/message";

export class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.loginButton = React.createRef();
        this.fname = React.createRef();
        this.lname = React.createRef();
        this.emailAddress = React.createRef();
        this.password = React.createRef();
        this.vpassword = React.createRef();
        this.countries = React.createRef();
        this.countriesState = React.createRef();
        this.downloadPdf = React.createRef();
    }

    state = {
        fname: '',
        lname: '',
        pepChecking: null,
        emailAddress: '',
        password: '',
        vpassword: '',
        country: 'default',
        state: "default",
        checked: false,
        validPassword: false,
        selectedUserType: '',
        uploaded: false,
        transactionID: null,
        pdfHash: '',
        beneficialList: [],
        showBeneficialForm: false,
        beneficialName: '',
        beneficialBirthday: '',
        beneficialAddress: '',
        beneficialNumber: '',
        frontImgId: '',
        backImgId: '',
        beneficialWarning: false,
        checkedBirthday: '',
        userBirthday: null
    }

    componentDidMount = () => {

//{TODO: unhide registration}
        window.location.href = "/#/login";
        if (Parse.User.current()) this.props.history.push("/")
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    }

    handlePassword = () => {
        let passwordInput = this.password.current;

        let rulesItems = document.querySelector(".passwordVerify").childNodes;
        let rules = {
            "1 lowercase": /[a-z]/,
            "1 uppercase": /[A-Z]/,
            "1 number": /[0-9]/,
            "8 characters": /.{9,}/
        };

        passwordInput.classList.toggle('hasValue', passwordInput.value);

        rulesItems = Array.prototype.slice.call(rulesItems);
        rulesItems.forEach((elm) => {
            let valid,
                ruleName = elm.innerText.toLowerCase();
            if (rules[ruleName]) {
                valid = new RegExp(rules[ruleName]).test(passwordInput.value);
                elm.classList.toggle('pass', valid);
            }
        });
    }

    handleCountryChange = (e) => {
        this.setState({ country: e.target.value });
        set_city_state(this.countries.current, this.countriesState.current)
    }

    returnRegisterBtnText = () => {
        this.loginButton.current.innerHTML = "Create Account";
        this.loginButton.current.classList.remove('loadingState');
    }

    setAcceptedColor = (fields) => {
        fields.forEach(field => {
            this[field].current.style.backgroundColor = "#f3f3f3";
        })
    }

    validateFields = (field) => {
        if (isEmpty(this.state[field])) {
            this[field].current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";
            return 'false'
        } else {
            this[field].current.style.backgroundColor = "#f3f3f3";
            return 'true'
        }
    }


    checkPepDataFromGetid = async () => {
        let gettedPepPromise = await new Promise((resolve) => {
            let data = {
                "verificationTypes": [
                    "watchlists"
                ],
                "application": {
                    "fields": [
                        {
                            "category": "First name",
                            "contentType": "string",
                            "content": this.state.fname
                        },
                        {
                            "category": "Last name",
                            "contentType": "string",
                            "content": this.state.lname
                        },
                        {
                            "category": "Date of birth",
                            "contentType": "string",
                            "content": this.state.userBirthday
                        }
                    ]
                }
            }
            axios({
                method: 'post',
                url: `https://fanclear.sb.getid.dev/api/v1/application/`,
                data,
                responseType: 'json',
                "headers": {
                    "X-API-Key": "e6043655335290331259fa0ba2c10ba5181fa13f5d7dc11657e6ae819f4eb637",
                    "Content-Type": "application/json"
                },
            }).then((response) => {
                let gettedPepResponse = response.data.id.toString()
                if (response.data.id) {
                    resolve(gettedPepResponse)
                }
            })
        })
        if (gettedPepPromise) {
            return gettedPepPromise
        }
    }


    getCheckedIdStatus = (savedPepData) => {
        let link = `https://fanclear.sb.getid.dev/api/v1/application/${savedPepData}`
        const options = {
            method: 'GET',
            url: link,
            headers: {
                "Access-Control-Allow-Origin": "*",
                'X-API-Key': 'e6043655335290331259fa0ba2c10ba5181fa13f5d7dc11657e6ae819f4eb637',
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        };

        axios.request(options).then((response) => {
            if (response.data.processingState === 'processing') {
                this.getCheckedIdStatus(savedPepData)
            } else {
                this.setState({ pepChecking: { id: response.data.id, status: response.data.overallResult.status } })
            }

        }).catch(function (error) {
            console.error(error);
        });

    }
    handleSubmit = async () => {
// =====> user's Pep validation
        /**
         * Represents a book.
         * @checkPepDataFromGetid - function for checking PEP (political person) from Get id with user's name, lastname and birthday
         * @returns from checkPepDataFromGetid  - function return ID at getId service
         * @getCheckedIdStatus - function for get checked status and information from previous function with provided id
         * @returns from getCheckedIdStatus -  function return ID of getId checked field, checked status
        */

        let savedPepData = await this.checkPepDataFromGetid()
        this.getCheckedIdStatus(savedPepData)


 // ====> here starts create user in database function

if (this.state.pepChecking){
        let key = this.props.match.params.key;
        let loginButton = this.loginButton.current;
        let iLg = "Create Account";
        loginButton.innerHTML = "<span class='spinner'/>";
        loginButton.classList.add('loadingState');

        let checks = document.querySelectorAll(".pass");

        const fields = ['fname', 'lname', 'emailAddress', 'password', 'vpassword']

        const isFilled = fields.map(field => {
            return this.validateFields(field)
        })

        if (isFilled.includes('false')) {
            this.returnRegisterBtnText();
        } else if (!isEmail(this.state.emailAddress)) {
            this.emailAddress.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";
            message('error', 'Email is invalid');
            this.returnRegisterBtnText();
        } else if (this.state.password !== this.state.vpassword) {
            message('error', 'Passwords do not match');
            this.password.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";
            this.vpassword.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";
            this.returnRegisterBtnText();
        } else if (checks.length !== 4) {
            this.setAcceptedColor(['fname', 'lname', 'emailAddress'])
            this.password.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";
            this.vpassword.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";
            this.returnRegisterBtnText();
        } else if (this.state.country === "default") {
            this.setAcceptedColor(['fname', 'lname', 'emailAddress', 'password', 'vpassword', 'countries'])
            this.countries.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";
            this.returnRegisterBtnText();
        } else if (this.state.state === "default") {


            this.setAcceptedColor(['fname', 'lname', 'emailAddress', 'password', 'vpassword', 'countries', 'countriesState'])
            this.countriesState.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";
            this.returnRegisterBtnText();
        } else if (!this.state.checked) {
            this.setAcceptedColor(['fname', 'lname', 'emailAddress', 'password', 'vpassword', 'countries', 'countriesState'])
            message('error', 'Please agree to the Terms & Conditions');
            this.returnRegisterBtnText();
        } else if (this.state.selectedUserType === 'legalEntity' && this.state.pdfHash === '') {
            this.setAcceptedColor(['fname', 'lname', 'emailAddress', 'password', 'vpassword', 'countries', 'countriesState'])
            this.downloadPdf.current.style.backgroundColor = "rgba(255, 0, 0, 0.05)";
            this.returnRegisterBtnText();
        } else {
            this.setAcceptedColor(['fname', 'lname', 'emailAddress', 'password', 'vpassword', 'countries', 'countriesState'])
console.log(this.state.pepChecking, '-----this.state.pepChecking=====')

if (this.state.pepChecking) {
    console.log(this.state.pepChecking, '-----this.state.pepChecking=====')
}
            const saveData = [
                { field: 'fname', value: this.state.fname },
                { field: 'lname', value: this.state.lname },
                { field: 'email', value: this.state.emailAddress },
                { field: 'username', value: this.state.emailAddress },
                { field: 'pepChecking', value: this.state.pepChecking },
                { field: 'password', value: this.state.password },
                { field: 'country', value: this.state.country },
                { field: 'state', value: this.state.state },
                { field: 'corporateDocuments', value: this.state.pdfHash },
                { field: 'beneficialOwners', value: this.state.beneficialList },
                { field: 'phoneVerified', value: false },
                { field: 'eVerified', value: false },
                { field: 'tampered', value: true },
                { field: 'accountVerified', value: false },
                { field: 'firstTimeLoggedIn', value: true },
                { field: 'evSent', value: true },
                { field: 'accountVerifiedStatus', value: "Not Completed" },
                { field: 'type', value: "User" },
            ]

            if (this.state.selectedUserType === 'legalEntity') {
                saveData.push(
                    { field: 'legalEntityApproveStatus', value: false },
                    { field: 'legalAffiliation', value: 'legalEntity' }
                )
            } else {
                saveData.push({ field: 'legalAffiliation', value: 'individual' })
            }

            sendParseRequest('createDatabaseObject', { query: '_User', saveData })
                .then(res => {
                    sendParseRequest('verifyEmailSignup', {
                        email: res.getUsername(),
                        objectId: res.id,
                        fname: res.get('fname')
                    }).then(() => this.props.history.push({
                        pathname: '/thankyou',
                        state: { status: res.get('legalAffiliation'), user: res }
                    }))
                }).catch(() => {
                    loginButton.innerHTML = iLg;
                    loginButton.classList.remove('loadingState');
                })
        }
    }

    }

    savePdfFile = (hash) => {
        this.setState({ pdfHash: hash });
        this.downloadPdf.current.style.backgroundColor = 'white'
    };

    saveFrontImgId = (url) => {
        this.setState({ frontImgId: url })
    };

    saveBackImgId = (url) => {
        this.setState({ backImgId: url })
    };

    saveBeneficial = () => {
        this.setState({ beneficialWarning: false });

        const newBeneficial = {
            name: this.state.beneficialName,
            date: this.state.beneficialDate,
            address: this.state.beneficialAddress,
            number: this.state.beneficialNumber,
            frontImgId: this.state.frontImgId,
            backImgId: this.state.backImgId,
        };

        for (let key in newBeneficial) {
            if (newBeneficial[key] === '') {
                return this.setState({ beneficialWarning: true });
            }
        }

        if (!this.state.beneficialWarning) {
            this.setState(prev => {
                return {
                    ...prev,
                    beneficialList: [...prev.beneficialList, newBeneficial],
                    showBeneficialForm: false
                }
            })
        }
    };

    deleteBeneficial = number => {
        const beneficialList = [...this.state.beneficialList];
        this.state.beneficialList.forEach((beneficial, idx) => {
            if (beneficial.number === number) {
                beneficialList.splice(idx, 1)
            }
        })
        this.setState({ beneficialList })
    }

    checkBirthday = (e) => {
        let input = e.target.value;
        // YYYY-MM-DD regExp ==>
        let pattern = /^([0-9]{4})\-([0-9]{2})\-([0-9]{2})$/;
        if (!pattern.test(input)) { this.setState({ checkedBirthday: false }) } else {
            this.setState({ checkedBirthday: true })
            this.setState({ userBirthday: e.target.value })
        }
    }

    render() {
        return (
            <div className="loginScreen createAccountWrap  register_wrapper-container"
                style={{ paddingBottom: '0px' }}
            >
                {this.state.selectedUserType === ''
                    ? <div className='requiredCustomer__wrapper'>
                        <p className='requiredCustomer__title'>
                            Required Customer Information Prior to Opening an Account
                        </p>
                        <p>
                            Our Compliance Officer will collect the following information for all accounts,
                            if applicable, for any person, entity or organization that is opening a new
                            account and whose name is on the account:
                        </p>
                        <ol className='requiredCustomer__list'>
                            <li>The name</li>
                            <li>Date of birth (for an individual)</li>
                            <li>An address, which will be a residential or business street
                                address (for an individual), an Army Post Office (APO) or Fleet Post Office
                                (FPO) box number, or residential or business street address of next of kin
                                or another contact individual (for an individual who does not have a
                                residential or business street address), or a principal place of business, local
                                office, or
                                other physical location (for a person other than an individual)
                            </li>
                            <li>An identification number, which will be a taxpayer identification number
                               , or one or more of the following: a taxpayer identification
                                number,
                                passport number and country of issuance, alien identification card number,
                                or
                                number and country of issuance of any other government-issued document
                                evidencing nationality or residence and bearing a photograph or other
                                similar
                                safeguard
                            </li>
                        </ol>
                        <div className='register_wrapper-block__legal-status-block__wrapper'>
                            <p className="legal-status-block__title">Select your legal status</p>
                            <div className='register_wrapper-block__legal-status-block'>
                                <button className='blue-button'
                                    onClick={() => {
                                        this.setState({ selectedUserType: 'individual' })
                                    }}>
                                    Individual
                                </button>
                                <button className='white-button'
                                    onClick={() => {
                                        this.setState({ selectedUserType: 'legalEntity' })
                                    }}>
                                    Legal entity
                                </button>
                            </div>
                        </div>
                        <NavLink to='/login' className='register_wrapper-container__back-btn'>
                            No, Thanks
                        </NavLink>
                    </div>
                    : <div className="screenWrapper" style={{ padding: '0' }}>
                        <p className="mainTitle" style={{ marginTop: '70px', paddingBottom: '0' }}>
                            Create your account
                        </p>
                        <div className="loginWrap" style={{ "height": "auto" }}>
                            <div className="rgTextW fifty">
                                <input type="text" ref={this.fname}
                                    onKeyPress={this._handleKeyPress}
                                    onChange={e => this.setState({ "fname": e.target.value })}
                                    placeholder="First Name"
                                />
                            </div>
                            <div className="rgTextW fifty">
                                <input type="text" ref={this.lname}
                                    onKeyPress={this._handleKeyPress}
                                    onChange={e => this.setState({ "lname": e.target.value })}
                                    placeholder="Last Name"
                                />
                            </div>
                            <div className="rgTextW">
                                <input type="text" id="checkBirthday"
                                    ref={this.birth}
                                    onKeyPress={this._handleKeyPress}
                                    onChange={e => this.checkBirthday(e)}
                                    placeholder="YYYY-MM-DD for example, 1980-01-03"
                                />
                                {this.state.checkedBirthday === false && <p style={{ color: 'red',  marginTop: '-20px', marginBottom: '10px', fontSize: '10px', textAlign: 'left', marginLeft: '5px' }}>Type your birthday in this format: 01/07/1990</p>}
                            </div>
                            <div className="rgTextW">
                                <input type="text" id="emailAddressS"
                                    ref={this.emailAddress}
                                    onKeyPress={this._handleKeyPress}
                                    onChange={e => this.setState({ "emailAddress": e.target.value })}
                                    placeholder="Email Address"
                                />
                            </div>
                            <div className="rgTextW field">
                                <p className="mustHaveAtLeast">Must have at least</p>
                                <ul className="passwordVerify">
                                    <li>1 lowercase</li>
                                    <li>1 uppercase</li>
                                    <li>1 number</li>
                                    <li>8 characters</li>
                                </ul>
                                <input type="password"
                                    ref={this.password}
                                    onKeyPress={this._handleKeyPress}
                                    onKeyUp={this.handlePassword}
                                    onChange={e => this.setState({ "password": e.target.value })}
                                    placeholder="Choose a Password"
                                />
                            </div>
                            <div className="rgTextW">
                                <input type="password"
                                    ref={this.vpassword}
                                    onKeyPress={this._handleKeyPress}
                                    onChange={e => this.setState({ "vpassword": e.target.value })}
                                    placeholder="Verify Password"
                                />
                            </div>
                            <div className="rgTextW" style={{
                                display: "flex",
                                justifyContent: 'space-between'
                            }}>
                                <p className="stateLabel pull-left">Select your country</p>
                                <select id="countries"
                                    ref={this.countries}
                                    className="stateSelect pull-right withArrow"
                                    onChange={this.handleCountryChange}
                                    style={{ paddingLeft: '10px' }}
                                >
                                    <option value="default" />
                                    {setRegions()}
                                </select>
                            </div>
                            <br />
                            <div className="rgTextW" style={{
                                display: this.state.country === "default" ? "none" : "flex",
                                marginBottom: "20px",
                                justifyContent: 'space-between'
                            }}>
                                <p className="stateLabel pull-left">Select your state</p>
                                <select ref={this.countriesState}
                                    className="stateSelect pull-right withArrow"
                                    style={{ paddingLeft: '10px' }}
                                    onChange={e => this.setState({ "state": e.target.value })}
                                />
                            </div>
                            {this.state.selectedUserType === 'legalEntity' &&
                                <>
                                    <div ref={this.downloadPdf}
                                        className='register_wrapper-block__download-pdf'
                                        style={{ height: this.state.pdfHash === '' ? '105px' : '80px' }}
                                    >
                                        {this.state.pdfHash === '' &&
                                            <p className='register_wrapper-block__download-pdf__title'>
                                                Corporate Documents
                                                <span className='download-pdf__title_required'> * required</span>
                                                <InfoTooltip message={tooltipPdfMessage}>
                                                    <i className="fa fa-info-circle" />
                                                </InfoTooltip>
                                            </p>
                                        }
                                        {/*<DownloadToIpfsOrAws formats={['pdf']}*/}
                                        {/*                     sizeInMegabyte={100}*/}
                                        {/*                     saveResult={this.savePdfFile}*/}
                                        {/*                     downloadType='ipfs'*/}
                                        {/*/>*/}
                                    </div>
                                    <div className='register_wrapper-block__addBeneficial'>
                                        <div className='register_wrapper-block__addBeneficial__title-block'>
                                            <div style={{ display: 'flex' }}>
                                                <p>Add Beneficial Owners</p>
                                                <InfoTooltip message={tooltipBeneficialMessage}>
                                                    <i className="fa fa-info-circle" />
                                                </InfoTooltip>
                                            </div>
                                            <div
                                                className='register_wrapper-block__addBeneficial__title-block__beneficial-list'>
                                                {this.state.beneficialList.map(beneficial => {
                                                    return (
                                                        <div key={beneficial.number}
                                                            className='register_wrapper-block__addBeneficial__title-block__beneficial-list__item'
                                                            onClick={() => this.deleteBeneficial(beneficial.number)}
                                                        >
                                                            <p>{beneficial.name}</p>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {this.state.showBeneficialForm &&
                                                <div onClick={() => {
                                                    this.setState({ showBeneficialForm: !this.state.showBeneficialForm })
                                                }}
                                                    className="delete-attach-box">
                                                    <svg width="20" height="20" viewBox="0 0 24 24">
                                                        <path
                                                            d="M14.8284 12l4.2427 4.2426c.781.781.781 2.0474 0 2.8285-.781.781-2.0474.781-2.8285 0L12 14.8284l-4.2426 4.2427c-.781.781-2.0474.781-2.8285 0-.781-.781-.781-2.0474 0-2.8285L9.1716 12 4.9289 7.7574c-.781-.781-.781-2.0474 0-2.8285.781-.781 2.0474-.781 2.8285 0L12 9.1716l4.2426-4.2427c.781-.781 2.0474-.781 2.8285 0 .781.781.781 2.0474 0 2.8285L14.8284 12z"
                                                            fillRule="evenodd"
                                                        />
                                                    </svg>
                                                </div>}
                                        </div>
                                        <div onClick={() => {
                                            this.setState({ showBeneficialForm: !this.state.showBeneficialForm })
                                        }}
                                            className={this.state.showBeneficialForm ? "add-collab-bttn close" : "add-collab-bttn"}
                                            style={{ margin: '10px 0' }}>
                                            <svg width="20" height="20" viewBox="0 0 24 24">
                                                <path
                                                    d="M14.8284 12l4.2427 4.2426c.781.781.781 2.0474 0 2.8285-.781.781-2.0474.781-2.8285 0L12 14.8284l-4.2426 4.2427c-.781.781-2.0474.781-2.8285 0-.781-.781-.781-2.0474 0-2.8285L9.1716 12 4.9289 7.7574c-.781-.781-.781-2.0474 0-2.8285.781-.781 2.0474-.781 2.8285 0L12 9.1716l4.2426-4.2427c.781-.781 2.0474-.781 2.8285 0 .781.781.781 2.0474 0 2.8285L14.8284 12z"
                                                    fillRule="evenodd"
                                                />
                                            </svg>
                                        </div>
                                        {this.state.showBeneficialForm &&
                                            <div className='register_wrapper-block__addBeneficial__form'>
                                                <label htmlFor="beneficialName">Beneficial Name</label>
                                                <input id='beneficialName'
                                                    type="text"
                                                    placeholder='Name'
                                                    onChange={e => this.setState({ beneficialName: e.target.value })}
                                                />
                                                <label htmlFor="beneficialBirthday">Beneficial Birthday</label>
                                                <input id='beneficialBirthday'
                                                    type="date"
                                                    className='register_wrapper-block__addBeneficial__form__date-input'
                                                    onChange={e => this.setState({ beneficialDate: e.target.value })}
                                                />
                                                <label htmlFor="beneficialAddress">
                                                    Beneficial Address
                                                    <InfoTooltip message={tooltipBeneficialAddress}>
                                                        <i className="fa fa-info-circle" />
                                                    </InfoTooltip>
                                                </label>
                                                <input id='beneficialAddress'
                                                    type="text"
                                                    placeholder='Address'
                                                    onChange={e => this.setState({ beneficialAddress: e.target.value })}
                                                />
                                                <label htmlFor="beneficialNumber">
                                                    Beneficial Number
                                                    <InfoTooltip message={tooltipBeneficialNumber}>
                                                        <i className="fa fa-info-circle" />
                                                    </InfoTooltip>
                                                </label>
                                                <input id='beneficialNumber'
                                                    type="text"
                                                    placeholder='Number'
                                                    onChange={e => this.setState({ beneficialNumber: e.target.value })}
                                                />
                                                <label className='register_wrapper-block__addBeneficial__form__id-item'>
                                                    <div
                                                        className='register_wrapper-block__addBeneficial__form__id-item__title'>
                                                        Download Beneficial Id or Passport
                                                        <InfoTooltip message={tooltipBeneficialId}>
                                                            <i className="fa fa-info-circle" />
                                                        </InfoTooltip>
                                                    </div>
                                                    <div
                                                        className='register_wrapper-block__addBeneficial__form__id-item__download-block'>
                                                        <p>Front page</p>
                                                        {/*<DownloadToIpfsOrAws downloadType='aws'*/}
                                                        {/*                     saveResult={this.saveFrontImgId}*/}
                                                        {/*                     formats={['jpeg', 'png', 'jgp',]}*/}
                                                        {/*                     sizeInMegabyte={20}*/}
                                                        {/*/>*/}
                                                    </div>
                                                    <div
                                                        className='register_wrapper-block__addBeneficial__form__id-item__download-block'>
                                                        <p>Back page</p>
                                                        {/*<DownloadToIpfsOrAws downloadType='aws'*/}
                                                        {/*                     saveResult={this.saveBackImgId}*/}
                                                        {/*                     formats={['jpeg', 'png', 'jgp',]}*/}
                                                        {/*                     sizeInMegabyte={20}*/}
                                                        {/*/>*/}
                                                    </div>
                                                </label>
                                                <button className='white-button' onClick={this.saveBeneficial}>
                                                    Add
                                                </button>
                                                {this.state.beneficialWarning &&
                                                    <p style={{ display: 'flex', marginTop: '10px', color: 'red' }}>
                                                        All beneficiary fields must be filled
                                                    </p>
                                                }
                                            </div>
                                        }
                                    </div>
                                </>
                            }
                            <hr style={{ "marginTop": "0", "clear": "both" }} />
                            <label>
                                <input style={{ "marginTop": "18px" }}
                                    type="checkbox"
                                    onClick={() => this.setState({ "checked": !this.state.checked })}
                                    defaultChecked={this.state.checked}
                                />
                                <span style={{ "width": "95%" }}>
                                    I certify that I am 18 years or older, and that I have read, understand and agree to the
                                    <NavLink target="_blank" to="/terms"> Terms of Use </NavLink>
                                    and
                                    <NavLink target="_blank" to="/privacy"> Privacy Policy.</NavLink>
                                </span>
                            </label>
                            <br style={{ "clear": "both" }} />
                            <a style={{
                                width: "100%",
                                textAlign: "center",
                                marginTop: "20px",
                                float: "none",
                                display: "block"
                            }} onClick={this.handleSubmit}
                                ref={this.loginButton}
                                className="loadingButton">Create
                                Account
                            </a>
                        </div>
                        <div className="extraSigns">
                            <Link className="pull-right" to='/login'>Already have an account? Login</Link>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
