const host = '3.220.210.43:8081';
// const host = 'localhost:8081';
const apiRoot = 'http://'+host+'/api/v3/trm';
const usdwHost = 'http://54.87.107.113:8800/'
const adminUSDW = "wally1efx0r5pysr2xvveeung22ezwsxj6mcltk50jtu"

const auth = {
            username: 'username',
            password: 'password'
    }

export default {
    host,
    apiRoot,
    auth,
    usdwHost,
    adminUSDW
}
