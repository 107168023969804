import React from "react";
import Parse from "parse";
import FLogo from "../../../assets/images/F-logo-in-White-Circle.png";
import {comma} from "../../../utils/comma";
import {CopyToClipboard} from "react-copy-to-clipboard";
import isEmail from "../../../utils/isEmail";
import {ReferralsTables} from "./ReferralsTables/ReferralsTables";
import {mail} from "../../../utils/referralInvite";
import {sendParseRequest} from "../../../utils/sendParseRequest";

export class Referrals extends React.Component {
    constructor(props) {
        super(props);
        this.emailAddresses = React.createRef();
        this.errorMessageEmail = React.createRef();
    }

    state = {
        emailSend: "",
        referralLink: "",
        totalReferred: 0,
        copied: false,
        results: [],
        userFullName: '',
        userName: ''
    }

    componentDidMount() {
        document.title = "Referrals – FNBD";

        const userId = Parse.User.current().id;

        sendParseRequest('getUserInfoById', {id: userId})
            .then(res => {
                this.setState({
                    userFullName: res.get('fname') + " " + res.get('lname'),
                    userName: res.get('fname'),
                    referralLink: res.get('refURL')
                })
            })

        const searchData = {
            query: "Referrals",
            equal: [
                {
                    field: 'referee',
                    value: {
                        "__type": 'Pointer',
                        "className": '_User',
                        "objectId": userId
                    }
                }, {
                    field: 'active', value: true
                }
            ]
        }

        sendParseRequest('getInfo', searchData)
            .then(res => {
                for (let i = 0; i < res.length; i++) {
                    this.setState({
                        totalReferred: this.state.totalReferred + parseFloat(res[i].get('totalRewarded'))
                    })
                }
            })
    }

    handleInput = (e) => {
        this.errorMessageEmail.current.innerText = "";
        this.setState({emailSend: e.target.value})
    }

    handleEmailSend = async () => {
        const messageField = this.errorMessageEmail.current;

        messageField.innerText = "Sending..."
        messageField.style.color = "black";

        if (this.state.emailSend.trim() === "") {
            messageField.innerText = "Type an email to get started!"
            messageField.style.color = "red";
        } else {
            const emailsArr = this.state.emailSend.replace(/ /g, '').split(',');

            if (emailsArr.length > 40) {
                messageField.innerText = "The maximum to send is 40 people at a time."
                messageField.style.color = "red";
            } else {
                for (let i = 0; i < emailsArr.length; i++) {
                    const em = emailsArr[i];

                    if (!isEmail(em)) {
                        messageField.innerText = "Some of your emails are invalid!"
                        messageField.style.color = "red";
                        return
                    }
                }

                await sendParseRequest('sendEmail', {
                    emailSendFrom: 'info@fanthebase.com',
                    emailToSend: emailsArr,
                    htmlCode: mail(this.state.userFullName, this.state.userName, 'http://localhost:3000/#/login'),
                }).then(res => {
                    if (res.success) {
                        this.setState({emailSend: ''});
                        this.emailAddresses.current.value = "";

                        messageField.innerText = "All emails delivered successfully"
                        messageField.style.color = "green";
                    }
                })
            }
        }
    }

    render() {
        const {referralLink, totalReferred} = this.state;

        return (
            <div className="page referralsPage">
                <div className="dashWrapperB referralWraps">
                    <div className="dashModal" style={{"width": "100%", "padding": "20px 0px 20px"}}>
                        <div className="content noBorder contents-invite-container" style={{"height": "auto"}}>
                            <div className="dashModal seperator" style={{"width": "15%"}}/>
                            <div className="dashModal contentPage" style={{"width": "85%", "margin": "0 auto"}}>
                                <div className="referralWrap contentPage" style={{"paddingTop": "0"}}>
                                    <img src={FLogo} style={{"height": "120px", "paddingBottom": "20px"}} alt='FLogo'/>
                                    <p className="title">Invite friends to recieve rewards!</p>
                                    <p className="description">
                                        Earn $10 of FNBD when a friend buys $100 or more in tokens.
                                    </p>
                                </div>
                                <div className="invited_total">
                                    <p className="subtitle">Total Earned</p>
                                    <p className="totalEarned">{comma(totalReferred)} FNBD</p>
                                </div>
                                <div className="inviteFriendWrap-container">
                                    <div className="inviteFriendWrap-block_flex">
                                        <div className="inviteFriendWrap">
                                            <label id='inviteInput'>Enter your friends email addresses:</label>
                                            <input value={this.state.emailSend}
                                                   id='inviteInput'
                                                   ref={this.emailAddresses}
                                                   onChange={this.handleInput}
                                                   type="text"
                                                   placeholder="Invite friends by typing email address here"/>
                                            <a onClick={this.handleEmailSend} className="referralsButton">
                                                Invite
                                            </a>
                                            <span className="referralsComman">
                                                Use a comma "," between each email address.
                                            </span>
                                            <p className="referralsComman" ref={this.errorMessageEmail}/>
                                        </div>
                                        <div className="inviteFriendWrap-block">
                                            <p className="title" style={{"fontSize": "15px"}}>My Referral Link:</p>
                                            <input style={{"marginTop": "20px", "width": "83%"}}
                                                   type="text"
                                                   disabled
                                                   value={referralLink}/>
                                            <CopyToClipboard text={referralLink}
                                                             onCopy={() => this.setState({copied: true})}>
                                                {this.state.copied === true
                                                    ? <a style={{"marginTop": "20px", "padding-bottom": "14px"}}
                                                         className="referralsButton copyLink copied">
                                                        Copied!
                                                    </a>
                                                    : <a style={{"marginTop": "20px"}}
                                                         className="referralsButton copyLink">
                                                        Copy
                                                    </a>}
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                    <div className="inviteFriendWrap-block_flex">
                                        <div className="inviteFriendWrap-block_right auction-card">
                                            <div className="auction-card__header__title">
                                                <ReferralsTables/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashModal contentPage  invite_block ">
                                <div className="socialLinks" style={{"textAlign": "center"}}>
                                    <p className="title" style={{"fontSize": "15px", "paddingBottom": "20px"}}>
                                        Share Link:
                                    </p>
                                    <a href={referralLink}>
                                        <div style={{"textAlign": "center"}} className="facebook">
                                            <i className="fa fa-facebook"/>
                                        </div>
                                    </a>
                                    <a href={referralLink}>
                                        <div style={{"textAlign": "center"}} className="twitter">
                                            <i className="fa fa-twitter"/>
                                        </div>
                                    </a>
                                    <a href={referralLink}>
                                        <div style={{"textAlign": "center"}} className="linkedin">
                                            <i className="fa fa-linkedin"/>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}