import React, {useEffect, useState,} from "react";
import closeSvg from "../../../../assets/images/close--blue.svg";
import './royalty.css'
import {sendParseRequest} from "../../../../utils/sendParseRequest";


const RoyaltyPopup = ({closeModal, statusPopup, popupPage, selectedItem}) => {
    let itemsArray = [];
    const [royaltyArray, setRoyaltyArray] = useState([]);

    const getRoyaltyList = (selectedItem, royaltyInfo) => {
        royaltyInfo && royaltyInfo.map((month) => {
            itemsArray.push({
                name: selectedItem.name,
                symbol: selectedItem.symbol,
                preSellCount: selectedItem.preSellCount,
                totalSupply: selectedItem.totalSupply,
                date: Object.keys(month),
                royalty: ((selectedItem.preSellCount + (selectedItem.totalSupply - selectedItem.preSellCount)) / 1000000) * month[Object.keys(month)]
            })
        });
        setRoyaltyArray(itemsArray);
    };

    const getRoyaltyInfo = () => {
        let promise = new Promise(function (resolve) {
            sendParseRequest('getInfo', {
                query: 'Projects',
                equal: [{field: "tickerSymbol", value: selectedItem.symbol}],
                select: ["royalty"],
            }).then(res => {
                console.log(res, "--------------res")

                resolve(res[0].get('royalty'))
            })
        });
        promise.then((royaltyInfo) => {console.log(royaltyInfo, "--------------royaltyInfo")
            getRoyaltyList(selectedItem, royaltyInfo)

        })
    };

    useEffect(() => {
        getRoyaltyInfo()
    }, []);


    return (
        selectedItem && statusPopup && popupPage === "royalty" &&
        <div className="slideshowModal popup_token-block">
            <button className="slideshowModalWrapper__closeButton slideshowModalButton">
                <img src={closeSvg} alt="close" onClick={() => closeModal(false, null)}/>
            </button>
            <div className=" popup-withdraw popup_tokens" style={{"maxWidth": "600px"}}>
                <div className="popup_royalty-container">

                    <div className='popup_royalty-container__cell-h2'>Royalty information</div>
                    <button className='blue-button-royalty'>previous years</button>
                    <div className="popup_royalty-container__line-title">
                        <div className="popup_royalty-container__cell-title">Month</div>
                        {/*<div className="popup_royalty-container__cell">name</div>*/}
                        <div className="popup_royalty-container__cell-title">Ticker</div>
                        <div className="popup_royalty-container__cell-title">Royalty $</div>
                    </div>


                    {
                        royaltyArray.map((royalty, idx) =>
                            <div className="popup_royalty-container__line" key={idx}>
                                <div className="popup_royalty-container__cell">{royalty.date}</div>
                                <div className="popup_royalty-container__cell">{royalty.symbol}</div>
                                <div className="popup_royalty-container__cell"> ${royalty.royalty}</div>
                            </div>
                        )
                    }

                </div>

            </div>
            }
        </div>
    );
};

export default RoyaltyPopup;