const shouldUserConfirmStatus = (pushTo, defUrl) => {
    const shouldUserConfirm = localStorage.getItem('principals_or_collaborators_confirm');

    if (!shouldUserConfirm) {
        window.location.href = defUrl;
    } else {
        pushTo(shouldUserConfirm);
    }
};

export default shouldUserConfirmStatus;
