import React, { useEffect, useState } from 'react';
import './walletPage.sass'
import PadLock from '../../../assets/images/padlock.png'
import Unlock from '../../../assets/images/unlock.png'


const UnlockWallets = ({ setMasterKeyInput, checkWalletKey, error, errorMasterKey }) => {
  const onKeyDown = e => {
    console.log(e)
    // обработайте нажатие клавиши. 
  }

  const handleKeypress = e => {
    console.log(e.keyCode, 11, e, e.key)
    //it triggers by pressing the enter key
    if (e.key === 'Enter' ){
      checkWalletKey()
    }
  };
  return (
    <div className='wallyHome_container'>
      <div className='wallyHome_block'>
        <>
          <img src={PadLock} className='unlock_wallet_ing' />
          <h2>Unlock your wallet</h2>


          <div className={('pswd-input__block' + ' ') + (error ? 'error' : '')}>
            <label htmlFor='confirm' style={{ width: '300px' }}>The password to access the wallet page, on this specific device, is called your passcode:</label>
            <input type="password"
              id='confirm'
              autoComplete='false'
              onKeyPress={e => handleKeypress(e)}
              onChange={e => {
                setMasterKeyInput(e.target.value)
              }} />
          </div>

          <button className={'blue-btn'} type='submit'
            onClick={() => checkWalletKey()}
          > Enter
          </button>

        </>
        {errorMasterKey && <p style={{ 'color': 'red', 'fontSize': '10px', 'marginTop': '50px' }}>{errorMasterKey}</p>}
      </div>


    </div>
  );
};


export default UnlockWallets;
