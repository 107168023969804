import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';

export class Loader extends Component {
  
  render() {
    const {
      children,
      showLoader,
      style,
    } = this.props;

    const loader = (
      <div className='image-spinner-frame' style={style}>
        <div className="loader__blue">Loading...</div>
      </div>
    );

    return(
      <Fragment>
        {
          showLoader ? loader : children
        }
      </Fragment>
    );
  }
}

Loader.propTypes = {
  showLoader: PropTypes.bool,
};
