import React from 'react';
import './thumbnailChart.css';

export default class thumbnailChart extends React.Component {
    constructor(props) {
        super(props);

        this.smoothing = 0.2;
        this.chart = this.buildChart();
    }

    buildChart() {
        this.lastIndex = this.props.chart.length - 1;
        this.lastDate =
            new Date(this.props.chart[this.lastIndex].date.replace(/ /g, 'T'));

        const startDate = new Date(this.lastDate);
        startDate.setDate(this.lastDate.getDate() - 1);

        let points = 0;
        this.props.chart.forEach(point => {
            if (new Date(point.date.replace(/ /g, 'T')) > startDate) points++;
        });

        // 1440 minutes in 24 hours
        const step = 1440 / points;
        const chart = [];

        for (let i = 1; i <= points; i++) chart.push(this.buildPoint(chart, step));
        chart.reverse().forEach((point, index) => point.index = index + 1);

        return chart;
    }

    buildPoint(chart, step) {
        const currentDate = chart.length
            ? new Date(chart[chart.length - 1].date)
            : this.lastDate;

        const point = {
            date: currentDate,
            value: this.props.chart[this.lastIndex].value
        };

        chart.length && currentDate.setMinutes(currentDate.getMinutes() - step);

        while (this.lastIndex >= 0 && new Date(currentDate) <=
        new Date(this.props.chart[this.lastIndex].date.replace(/ /g, 'T')))
            this.lastIndex--;

        return point;
    }

    buildPath(chart) {
        const maxY = this.chart.reduce((max, point) =>
            point.value > max ? point.value : max, this.chart[0].value);

        const points =
            [[this.getX(chart[0].index - 1), this.getY(maxY, chart[0].value)]];

        chart.map(point => {
            const x = this.getX(point.index);
            const y = this.getY(maxY, point.value);

            points.push([x, y]);

            return `L ${x} ${y} `;
        }).join('');

        return <path
            d={this.getSvgPath(points, this.getBezierCommand)}
            style={{stroke: '#FFAD00'}}
            className="thumbnailChart_path"
        />;
    }

    getX(x) {
        return x / this.chart.length * 900;
    }

    getY(maxY, y) {
        return 230 - (y / maxY * 230);
    }

    getSvgPath(points, command) {
        return points.reduce((acc, point, i, a) => i === 0
            ? `M ${point[0]},${point[1]}`
            : `${acc} ${command.call(this, point, i, a)}`
            , '');
    }

    getBezierCommand(point, i, a) {
        const cps = this.getControlPoint(a[i - 1], a[i - 2], point);
        const cpe = this.getControlPoint(point, a[i - 1], a[i + 1], true);

        return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}`;
    }

    getControlPoint(current, previous, next, reverse) {
        const p = previous || current;
        const n = next || current;

        const o = this.getOppositeLine(p, n);

        const angle = o.angle + (reverse ? Math.PI : 0);
        const length = o.length * this.smoothing;

        const x = current[0] + Math.cos(angle) * length;
        const y = current[1] + Math.sin(angle) * length;

        return [x, y];
    }

    getOppositeLine(pointA, pointB) {
        const lengthX = pointB[0] - pointA[0];
        const lengthY = pointB[1] - pointA[1];

        return {
            length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
            angle: Math.atan2(lengthY, lengthX)
        };
    }

    render() {
        const {title, currency, amount, delta} = this.props.header;
        const {logo} = this.props;
        const deltaABS = Math.abs(delta);
        const isNegative = delta < 0;

        return (
            <section className="thumbnailChart">
                <div className="thumbnailChart_header">
                    <div className="thumbnailChart_logo"
                         style={{backgroundImage: `url(${logo})`,}}>{/*<img src={logo} alt=""/>*/}</div>
                    <span className="thumbnailChart_title">{title}</span>
                    <p className="thumbnailChart_amount">{currency}{amount}
                        <span
                            className="thumbnailChart_delta"
                            style={{color: isNegative ? '#FF4949' : '#61CA00'}}>
              {isNegative && '-'}
                            {(deltaABS * 100 / amount).toFixed(2)}%
            </span>
                    </p>
                </div>

                <div className="thumbnailChart_chart">
                    <svg
                        viewBox="0 -100 900 215"
                        preserveAspectRatio="none">
                        {this.buildPath(this.chart)}
                    </svg>
                </div>

                <div className="thumbnailChart_footer">24H</div>
            </section>
        );
    }
}
