import React, {useEffect} from 'react';
import "./legalPages.css";

export function Privacy() {
  useEffect(() => {
    document.querySelector('#fanbase').scrollTop = 0;
  }, [])
  return (
    <div className="page" style={{"paddingBottom": "0", backgroundColor: 'white'}}>
      <div className="pageContent">

        <div
          style={{boxShadow: 'rgba(0, 0, 0, 0.4) 4px 5px 13px -1px  ', borderRadius: "15px"}}
        >
          <div className="mainHeaderTerms">
            <p style={{"padding": "40px 0"}} className="mainTitle">
              Privacy Policy
            </p>
          </div>

          <div className="row2">
            <div className='privacyPolicy__page'>
              {/* <img src={Logo} className="fwally-logo"/> */}
              {/*<Header/>*/}

              <div className='privacyPolicy__page_mainTitle'><h3>PRIVACY POLICY</h3></div>
              <div className='privacyPolicy__page_title lst-up'><p>Last Updated Feb. 5th, 2023</p></div>
              <p>This policy details the ways that Fanbase International Ltd. ("Fanbase", "We", “we”, "Us", “us”, "Our", “our”
                or the "Company"), located at
                https://chrome.google.com/webstore/detail/fanbaseio/ehkdpgmlbmklaiibhcdggpbohgggjibl and
                https://www.fanbase.io, collects and uses your data in accordance with GDPR guidelines.
              </p>
              <p>By using the Services, you agree to our use of your personal data in the manner described in this
                Privacy Policy and to be bound by our Terms of Use. If you do not agree with any parts of this Privacy
                Policy, or the Terms of Use, you must decline the use of the Service.
              </p>
              <p>
                This Privacy Policy does not apply to any information collected through third-party services that you
                may access through the Service, that you submit to us through email, or that is shared through any other
                means of electronic communication.
              </p>
              <p>
                If you are accessing this site from within the European Union (EU), see our Notice to EU Data Subjects
                below for information regarding your personal data protection rights under General Data Protection
                Regulation (GDPR) and our legal basis for processing and transferring your personal data.
              </p>
              <div className='privacyPolicy__page_title'><h4>THE DATA WE COLLECT</h4></div>
              <p>The Company values and respects the privacy of its Users. We manage and control your personal data for
                a range of reasons, from understanding how you engage with our Services to informing our marketing. We
                use this information to administer the Services and we analyze this information to improve the Services
                by tailoring its features and functions to your needs and preferences.
              </p>
              <p>
                We process certain amounts of sensitive data while carrying out identity verification, where such
                processing is necessary for reasons of public interest, in accordance with the provisions of article
                9(2)(g) of the GDPR and provision 14, Part 2, Schedule 1 of the DPA 2018.
              </p>
              <p>
                1. <span style={{fontStyle: "italic", fontFamily: "Sans-serif"}}>Information you provide.</span> We
                collect a variety of information that you provide directly to us. Data we collect from you includes:
              </p>
              <ol type='A'>
                <li>Your contact information;</li>
                <li>Your network information to process transactions;</li>
                <li>Your communications and dealings with us;</li>
                <li>Your participation in Company surveys or research studies;</li>
                <li>Your requests for customer support and technical assistance;</li>
                <li>Your information and behaviors concerning your use of the Service for marketing purposes.</li>
              </ol>
              <p>
                You may access information that you have voluntarily provided through your account on the Services at
                any point in time. If you wish to review, correct, alter or delete it, you can send a request to
                admin@fanbase.io.
              </p>
              <p>
                2. <span style={{fontStyle: "italic", fontFamily: "Sans-serif", marginRight: '5px'}}>Information we get from other sources.
            </span>
                We may receive your personal data from third party sources because of the Services we provide. Our
                Services include social media features, like the ability to link your account and sell items on
                BonJourno.com, or elsewhere, using our widget. The third party sites where our widget is embedded, may
                require and collect your personal data. Your interactions with third parties are subject to their
                privacy policies.
              </p>
              <p>
                3. <span style={{fontStyle: "italic", fontFamily: "Sans-serif"}}>Log Data.</span> We may automatically
                record certain information about how you use our Service. Information we automatically record may
                include:
              </p>
              <ol type='A'>
                <li>User’s Internet Protocol (IP) address;</li>
                <li>User’s device, browser type, and operating system;</li>
                <li>Cookies and local storage</li>
                <li>The pages or features of our Site accessed by a User,
                  the time spent on those pages or features, search terms,
                  and other statistics.
                </li>
              </ol>
              <p>
                Regular cookies may be disabled by selecting certain settings in most commercial browsers. For more
                information, please see the “Cookies Policy” and “Local Storage Policy” sections below.
              </p>
              <p>
                We also use Google Analytics to optimize the user experience on our platform. Google Analytics is a web
                analytics service used to track and report website traffic. This data is shared with other Google
                services. You can opt-out of having made your activity on the Service available to Google Analytics by
                installing the Google Analytics opt-out browser add-on. You can find more information about its use of
                your personal data here: <a
                href='https://policies.google.com/privacy?hl=en'>https://policies.google.com/privacy?hl=en.</a>
              </p>
              <p>
                4. <span style={{fontStyle: "italic", fontFamily: "Sans-serif"}}>
                Information we will never collect. </span> We will never ask you to share your private key or secret
                phrase. Never trust anyone or any site that asks you to enter your private key or secret phrase.
              </p>
              <div className='privacyPolicy__page_title'><h4>USE OF PERSONAL DATA</h4></div>
              <p>We will use your personal data in the following ways:</p>
              <p>
                1. <span style={{fontStyle: "italic", fontFamily: "Sans-serif"}}>To provide our service.</span> We
                collect and use your personal data to provide, improve, and protect our Services. It enables us to send
                information, including technical notices and confirmations, security alerts, technical support,
                administrative messages and updates.
              </p>
              <p>
                2.<span style={{fontStyle: "italic", fontFamily: "Sans-serif"}}> To comply with laws and law enforcement. </span> We
                use your personal data to comply with applicable laws, lawful requests and legal processes. We may also
                use your personal data to protect you and deter hackers and scammers from committing fraudulent and
                unauthorized activity.
              </p>
              <p>
                3. <span style={{fontStyle: "italic", fontFamily: "Sans-serif"}}>To communicate with you. </span>We use
                your personal data to communicate with you about promotions, upcoming events, news about our Service,
                products provided by our platform or offerings, opportunities and discounts from our selected partners.
              </p>
              <div className='privacyPolicy__page_title'><h4>DISCLOSURE OF PERSONAL DATA</h4></div>
              <p>We may use or share your personal data with your consent, for example, when you instruct us to take a
                specific action with regard to your personal data. We do not disclose or sell the personal data that you
                provide without your explicit consent, except as described in this Policy. We may share your personal
                data with others under the following circumstances:
              </p>
              <ol type='A'>
                <li>For legal, protection, and safety purposes. This includes compliance with relevant laws, responding
                  to lawful requests and legal processes.
                </li>
                <li>To protect the rights and property of the Company, our customers, our employees, our agents, and
                  others. This includes enforcing our agreements, policies, and Terms of Use.
                </li>
                <li>In emergency situations. This includes protecting the safety of our customers, our employees, our
                  agents, and others.
                </li>
                <li>When we do business deals which involve the transfer of all or part of our business assets. These
                  deals can include mergers, acquisitions, financing deals, or bankruptcy proceedings.
                </li>

              </ol>
              <p style={{marginTop: '10px'}}>We may share your personal data to our subsidiaries and corporate
                affiliates for purposes consistent with this Policy. We may share information with third party companies
                or individuals that act as our advisors and service providers, such as customer support, lawyers,
                auditors, insurers, and bankers. We may also share aggregated and/or anonymized data such as sharing our
                total number of users to demonstrate our growth.
              </p>

              <div className='privacyPolicy__page_title'><h4>INTERNATIONAL TRANSFER OF PERSONAL DATA</h4></div>
              <p>We are a global business. Your personal data may be stored and processed in any country where we have
                operations or where we are engaged with service providers. Your personal data may be transferred to or
                from the European Union, the United States or other locations outside of your state, country or
                governmental jurisdiction where privacy laws and data protection rules are different from those of your
                country. However, we will take measures to ensure that any transfer of personal data remains protected
                to the standards described in this Policy.
              </p>
              <p>EU users should read the important information provided below about transfer of personal data outside
                of the European Economic Area (EEA).</p>

              <div className='privacyPolicy__page_title'><h4>SECURITY AND RETENTION OF PERSONAL DATA</h4></div>
              <p>We make reasonable efforts to ensure a level of security appropriate to the risk associated with the
                processing of personal data and we employ industry standard security measures designed to assure the
                security of all personal data shared through the Services. Unfortunately, the transmission or storage of
                information through the internet cannot be guaranteed to be 100% secure. We are not responsible for the
                interception of communications, the interruption of communications, for changes to data, or losses of
                data. You are responsible for maintaining the security of your passwords, user IDs, and forms of
                authentication involved in obtaining access to any of our Services. We may suspend your use of Our
                Services without notice, if a breach of security is suspected, or for any other reason. If you feel that
                the security of your account has been compromised, please contact us at admin@fanbase.io immediately.
              </p>
              <p>
                We retain your personal information as long as it is necessary to fulfill the purposes outlined in this
                Policy. In addition, we retain your personal data to prevent fraudulent activities, comply with
                applicable laws, resolve disputes, assist with investigations, enforce our Terms of Use, and any other
                actions necessary to provide Our Service, as permitted by law. When determining the period of retention
                of personal data, we consider the following relevant factors such as the amount of the personal data,
                the nature of the personal data, the potential risk of deleting the data versus the potential harm of
                not deleting the data. We do not consider the value of the data as part of our decision because we do
                not sell your data. When we anonymize your personal data we use this information indefinitely without
                further notice to you, like when we examine our total number of users from one year to the next, to
                understand year over year growth.
              </p>

              <div className='privacyPolicy__page_title'><h4>PRIVACY POLICY CHANGES</h4></div>
              <p>We may change this Policy from time to time to reflect changes in our personal data practices or
                relevant laws. The “Last Updated” note at the top of this Policy indicates when this Policy was last
                revised. We encourage you to periodically review this page for the latest information on our privacy
                practices. Any modifications to this Privacy Policy will be effective upon our posting of the new Terms
                or upon implementation of the changes to the Service. In all cases, your continued use of the Service,
                after the posting of any revised Privacy Policy, indicates your acceptance of the revised Terms.
              </p>

              <div className='privacyPolicy__page_title'><h4>ELIGIBILITY</h4></div>
              <p>If you are under the age of majority in your jurisdiction of residence, you may use the Service only
                with the consent of or under the supervision of your parent or legal guardian. Consistent with the
                requirements of the Children's Online Privacy Protection Act (COPPA), if we learn that we have received
                any information directly from a child under age 13 without first receiving his or her parent's verified
                consent, we will use that information only to respond directly to that child (or his or her parent or
                legal guardian) to inform the child that he or she cannot use the Service. We will subsequently delete
                any personal information.</p>

              <div className='privacyPolicy__page_title'><h4>JURISDICTION-SPECIFIC PROVISIONS</h4></div>
              <p>1. <span
                style={{fontStyle: "italic", fontFamily: "Sans-serif"}}>Notice to California residents.</span> Under
                California Civil Code Section 1789.3, California users are entitled to the following consumer rights
                notice: California residents may reach the Complaint Assistance Unit of the Division of Consumer
                Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd.,
                Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.</p>
              <p>2. <span
                style={{fontStyle: "italic", fontFamily: "Sans-serif"}}> Notice to EU DATA subjects.</span> For the
                purpose of this Privacy Policy, we are a Data Controller of your personal data. The information you
                provide us may include sensitive data as defined in the GDPR, for example, your race or ethnicity on
                government-issued identification documents.
              </p>
              <p>
                3. <span
                style={{fontStyle: "italic", fontFamily: "Sans-serif"}}> Legal Basis for Processing in Europe. </span>The
                legal basis upon which we may collect and process your personal data as required by current legislation
                includes when:
              </p>
              <ol type='A'>
                <li>We need to optimize our platform;</li>
                <li>You have given us permission to do so;</li>
                <li>The processing is in our legitimate interests and it is not overridden by your rights or
                  interests;
                </li>
                <li>Payment processing purposes are required;</li>
                <li>We need to comply with the law;</li>
                <li>We need to prevent fraudulent activity;</li>
                <li>We need to perform a contract with you to provide our service.</li>
              </ol>
              <p>Where our use of your personal data is based upon your consent, you have the right to withdraw it
                anytime in the manner indicated in the Service. If you have questions about the legal basis for
                processing your personal data, contact us at admin@fanbase.io.</p>
              <p>4. <span style={{fontStyle: "italic", fontFamily: "Sans-serif"}}>Your Rights. </span>Under the GDPR,
                you have certain rights regarding your personal data. In certain circumstances you have the following
                data protection rights; (A) the right to access, correct or to delete your personal data; (B) the right
                to object to our reliance on our legitimate interests; (C) the right to restrict the processing of your
                personal data; (D) the right to data portability; (E) the right to withdraw consent.</p>
              <p>You can submit these requests by email to admin@fanbase.io. Please note that we may ask you to verify
                your identity before responding to such requests. You have the right to complain to a Data Protection
                Authority about our collection and use of your personal information. For more information, please
                contact your local data protection authority in the European Economic Area (EEA).</p>
              <p>
                5. <span
                style={{fontStyle: "italic", fontFamily: "Sans-serif"}}>Data Transfers Across Borders.</span> Please be
                aware that your personal data in some cases will be transferred to, processed, and stored in the United States. Data
                protection laws in the U.S. may be different from those in your country of residence. By using our
                Services, you consent to the transfer of personal data to the U.S. as stated in this Policy. Whenever we
                transfer your personal data out of the EEA to the U.S., the transfer will be based on a data transfer
                mechanism recognized by the European Commission as providing adequate protection for personal data.
              </p>
              <div className='privacyPolicy__page_title'><h4>COOKIES POLICY</h4></div>
              <p>1. <span style={{fontStyle: "italic", fontFamily: "Sans-serif"}}>Use of Cookies. </span>In order to
                ensure that every user of our Service can enjoy a smooth user experience, the Company, its marketing
                team, associates, analytics and service providers use cookies, web beacons, and other similar
                technologies.
              </p>
              <p>A cookie (“Cookie”) is a small piece of data retrieved from a website and stored on your device while
                you are accessing that site.</p>
              <p>Cookies are built to recollect data that can later be read by a web server in the domain that issued
                the cookie to you. Such information includes browsing activity, log-in tasks and visited pages. Some
                cookies are regularly used while others are only used when you select certain preferences or activate
                certain browser features.</p>
              <p>You can view our list below to find out more about each cookie. To help us deliver cookies, count
                visits, understand usage, monitor effectiveness and ascertain whether an email has been seen and acted
                upon, the Company may use web beacons, tags and scripts on emails. We may also receive analytics on an
                individual and aggregated basis.</p>
              <p>2.<span style={{fontStyle: "italic", fontFamily: "Sans-serif"}}> Disabling Cookies.</span> You can
                generally activate or deactivate cookies via the settings tab in your web browser. Third party
                advertising systems, including Google, allow users to opt out. To get more information about this
                feature, login to Google and use the following link:
                https://adssettings.google.com/u/0/authenticated?hl=en. </p>
              <p>In some cases, the Company links cookie data with other data we collect in accordance with the Privacy
                Policy and Terms. When you decline the use of cookies, some functionalities on the Services may become
                unavailable. If those Services are unavailable, the Site may function poorly.</p>
              <p>Third parties who serve cookies to us may link some of your data. For example, your name and email
                address may be linked to other information. That information can include past purchases and data related
                to your general online presence. You have specific rights that are explained above under the header
                “Notice to EU Data Subjects”, including the right to inspect, edit or delete any data collected if you
                are resident within the European Economic Area.</p>
              <p>3. <span style={{fontStyle: "italic", fontFamily: "Sans-serif"}}>Cookies Justification. </span>We use
                Cookies for the following:</p>
              <ol type='A'>
                <li>It helps us optimize our platform and serve you better by recognizing you as a recent customer and
                  suggesting ways to help you get along on the Site.
                </li>
                <li>It helps us incorporate with third party social media platforms and serve your interests with
                  targeted advertising.
                </li>
                <li>It helps us retain passwords for your convenience to avoid the hassle of multiple logins, where you
                  have already registered on our Site.
                </li>
                <li>It helps us improve our Site and tailor our priorities to better understand your visits on our
                  platforms and it helps to indicate your presence on our Site.
                </li>
                <li>It helps us notice the conduct, browsing patterns, and activities of our users over time across
                  multiple websites and platforms.
                </li>
                <li>It helps us understand the needs and market interests of our customers as well as our website
                  visitors.
                </li>
              </ol>

              <p>Some cookies are necessary. These cookies assist us in operating our Service so that you can have
                immediate access. Some cookies let us recognize your account and that you have logged into that account
                previously. Some cookies help us to remember your previous actions so that we can secure our Services.
                Some cookies help to keep our customers, and ourselves, adequately protected. Finally, cookies allow us
                to analyze how people use our Service for marketing and to make improvements. We like to know which
                adverts, search engines, websites, products and keyword searches generate new users for us. In some
                cases, third parties may share cookies with us. Those Cookies will remain for two years or less, such as
                with Google Analytics, unless cleared or disabled by you. Session cookies remain for a short visit,
                while persistent cookies remain for your next and future visits. Generally, without cookies, reloading
                periods would be inefficient. </p>

              <div className='privacyPolicy__page_title'><h4>LOCAL STORAGE POLICY</h4></div>

              <p><span className='privacyPolicy__page_italic'>Local Storage.</span> Local Storage, is a typical way for
                a website to store a small file of letters and numbers in your browser. We use Local Storage and assign
                you a unique user ID for the purpose of communicating with you and making our Service easily accessible.
                Local Storage data is deleted when the website that stores the data deletes the data. You can also
                delete or clear Local Storage from your browser at any time by visiting your web browser settings, or by
                the following methods:</p>
              <ol type="A">
                <li>You can clear Firefox when the following conditions are met: (i) clear recent history, (ii) select
                  the cookies to be cleared, and (iii) set time range to “Everything.”
                </li>
                <li>You can clear Chrome when the following conditions are met: (i) clear browsing data, (ii) select
                  "cookies and other site data", and (iii) set time frame to “from beginning of time.” You can now
                  delete local storage for one specific site.
                </li>
                <li>You can clear Explorer if you (i) click on Tools--Internet Options, (ii) General tab, (iii) delete
                  browsing history on exit, (iv) ensure “Cookies and website data” (or “temporary internet files and
                  website files”) is selected , and (v) consider unchecking "Preserve Favorites website data" at the
                  top.
                </li>
                <li>You can clear Safari if you (i) choose Safari, (ii) click Preferences, (iii) select the Privacy tab,
                  (iv) then click “Remove all website data” and then (v) click “Remove Now”.
                </li>
              </ol>

              <div className='privacyPolicy__page_title'><h4>CONTACT INFORMATION</h4></div>

              <p>If there are any questions regarding this Privacy Policy you may contact us at admin@fanbase.io.
                You can also contact the external consultants at hi@fanbaseconsulting.com with legal or business inquiries. Fanbase Consulting represents a cross section of decentralized artists, media companies, and technologies.</p>
              <p>-or-</p>
              <p>Fanbase Network UAB
              </p>
              <p>C/O Legal</p>
              <p>Girulių g. 20, 12123</p>
              <p>Vilnius, Lithuania</p>
              <p>European Union.</p>
              <p className='privecyPage__page_footer_title'>Last Updated Feb. 5th, 2023
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
