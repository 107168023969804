import React from "react";
import Parse from "parse";
import {sendParseRequest} from "../../../../utils/sendParseRequest";

export class ReferralsTables extends React.Component {
    state = {
        referralsList: []
    }

    componentDidMount() {
        const searchData = {
            query: 'Referrals',
            equal: [{
                field: 'referee',
                value: {
                    "__type": 'Pointer',
                    "className": '_User',
                    "objectId": Parse.User.current().id
                }
            }]
        }

        sendParseRequest('getInfo', searchData)
            .then(res => {
                this.setState({referralsList: res});
            })
    }

    render() {
        return (
            <div className="invitedFriends">
                <div className="noReferralsYet">
                    <p className="noReferralsYetTitle">My Referrals</p>
                </div>
                <table>
                    <tbody>
                    {this.state.referralsList.map(object => {
                        return (
                            <tr>
                                <td>{object.get('emailAddress')}</td>
                                {object.get('userReferred') === undefined ?
                                    <td className="inviteSent">Invite Sent</td>
                                    : object.get('active') === true ?
                                        <td className="registered">Registered</td>
                                        : object.get('active') === false ?
                                            <td className="rewardedAmount">{object.get('totalRewarded') + " FNBD"}</td>
                                            : ""
                                }
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}
