import React from "react"
import {comma} from "../../../utils/comma";
import {DepositDetails} from "../../Components/Wallet/wallets.js";
import {ExchangeDetailsBuy, ExchangeDetailsSell, ExchangeLeftSide} from "../../Components/Exchange/exchangeContainer";
import {Exchange2Details, Exchange2LeftSide} from "../../Components/Exchange2/exchange2";
import './index.css';
import '../../Components/Widgets/widgets.css';
import Parse from 'parse';
import QRCode from "qrcode.react";
import backButton from "../../../assets/images/backButton.png";
import {Link} from 'react-router-dom';
import {sendParseRequest} from "../../../utils/sendParseRequest";
import {message} from "../../../utils/message";

/*export class Index extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = "All Coins – USDW";
    }

    render() {
        return (
            <div className="page indexPageWrap">
                <div className="dashWrapperB">
                    <div className="dashModal indexPagePortofilo" style={{"width": "40%"}}>
                        <div className="content" style={{"height": "700px"}}>
                            <div className="cHeader">
                                <p className="title sub-title">Portfolio Summary1</p>
                            </div>
                            <div className="cBody">
                                <PortfolioSummary type="v"/>
                            </div>
                        </div>
                    </div>
                    <div className="dashModal TokenSummaryPorfolio" style={{"width": "60%"}}>
                        <div className="content" style={{"height": "331px"}}>
                            <div className="cHeader">
                                <p className="title">Token Summary</p>
                            </div>
                            <div className="cBody">
                                <TokenSummary/>
                            </div>
                        </div>
                        <div className="content" style={{"height": "auto", "marginTop": "20px"}}>
                            <div className="cHeader">
                                <p className="title sub-title">Open Orders</p>
                            </div>
                            <div className="cBody">
                                <OpenTransactionsTable/>
                            </div>
                        </div>
                    </div>
                    <br style={{"clear": "both"}}/>
                </div>
            </div>
        )
    }
}*/

export class Exchange extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "pCurrency": "BTC",
      "verifiedUser": false,
      "fullCurrency": "Bitcoin",
      "amount": "",
      "exchangeRate": "1",
      "bonusRate": "100",
      "feeRate": "1",
      "USDFeeRate": "1",
      "minimum": 100,
      "btcinDollar": "",
      "rates": null,
      "totalLare": "",
      "completeTrans": false,
      "usdPopup": false,
      "usdPopupPlace": 0,
      "finalResults": null,
      "loadingScreen": false,
      "bonusExchangeRate": "1",
      "coinBuyUSA": false,
      "activeTab": "buy"
    }
  }

  updateTab = (value) => {
    this.setState({activeTab: value})
  };

  componentDidMount() {
    var r = this;
    fetch('https://blockchain.info/tobtc?currency=USD&value=1')
      .then(response => response.json()
        .then(result => r.setState({"btcinDollar": result})));

    // $.getJSON('https://blockchain.info/tobtc?currency=USD&value=1', function (data) {
    //     r.setState({"btcinDollar": data});
    // });

    sendParseRequest('getRates')
      .then(results => {
        let arrCrypto = [];

        for (let crypt in results) {
          arrCrypto.push(crypt + ":" + results[crypt]["rate_btc"])
        }

        r.setState({"rates": arrCrypto});
      })

    window.scrollTo(0, 0);
    document.title = "Buy Tokens – USDW";

    sendParseRequest('getUserInfoById', {id: Parse.User.current().id})
      .then(res => {
        r.setState({
          verifiedUser: res.get('accountVerified'),
          accreditedStatus: res.get('accreditedStatus')
        })
      })
  }

  handPaymentSelection = (e) => {
    var value = e.target.attributes.id.value.split(':');
    this.setState({"pCurrency": value[0], "fullCurrency": value[1]});
  }
  handleInputAmount = (e) => {
    this.setState({"amount": e.target.value.replace(/\D/g, '')});
  };
  setTotalLare = (e) => {
    this.setState({"totalLare": e.target.value});
  }
  // handleCreateTransaction = () => {
  //     var r = this;
  //     r.setState({"loadingScreen": true});
  //     var Config = Parse.Object.extend("config");
  //     var ConfigQ = new Parse.Query(Config);
  //     ConfigQ.get('k7t0S4tWWZ', {
  //         success: function (CQResults) {
  //             var coinBuyUSA = CQResults.get('coinBuyUSA');
  //             if (coinBuyUSA == true) {
  //                 if (r.state.verifiedUser == true && r.state.accreditedStatus == "Complete") {
  //
  //                     if (r.state.pCurrency != "USD") {
  //                         if (r.state.amount < r.state.minimum) {
  //                         } else {
  //                             r.setState({
  //                                 "totalLare": $('#totalFnbAMount').html().split(' ')[0],
  //                                 // "totalLare": document.getElementById("totalFnbAMount").innerHTML.split(' ')[0],
  //                                 "loadingScreen": true
  //                             });
  //                             var userPointer = {
  //                                 "__type": 'Pointer',
  //                                 "className": '_User',
  //                                 "objectId": Parse.User.current().id
  //                             }
  //                             Parse.Cloud.run('createTransaction', {
  //                                 "user": userPointer,
  //                                 "email": Parse.User.current().get('username'),
  //                                 "bonusPercent": r.state.bonusRate,
  //                                 "totalLare": $('#totalFnbAMount').html().split(' ')[0],
  //                                 "feePercent": r.state.feeRate,
  //                                 "c1": "USD",
  //                                 "c2": r.state.pCurrency,
  //                                 "amount": r.state.amount
  //                             }, {
  //                                 success: function (results) {
  //                                     r.setState({
  //                                         "finalResults": results,
  //                                         "completeTrans": true,
  //                                         "loadingScreen": false
  //                                     });
  //                                 }, error: function (err) {
  //                                     r.setState({"loadingScreen": false});
  //                                     alert(err)
  //                                 }
  //                             });
  //                         }
  //                     } else {
  //                         if (r.state.amount < r.state.minimum) {
  //                         } else {
  //                             r.setState({"totalLare": $('#totalFnbAMount').html().split(' ')[0]});
  //                             r.setState({"usdPopup": true, "usdPopupPlace": 0});
  //                         }
  //                     }
  //                 } else {
  //                     r.setState({"coinBuyUSA": true, "loadingScreen": false})
  //                 }
  //             } else {
  //
  //                 if (r.state.pCurrency != "USD") {
  //                     if (r.state.amount < r.state.minimum) {
  //                     } else {
  //                         r.setState({"totalLare": $('#totalFnbAMount').html().split(' ')[0], "loadingScreen": true});
  //                         var userPointer = {
  //                             "__type": 'Pointer',
  //                             "className": '_User',
  //                             "objectId": Parse.User.current().id
  //                         }
  //                         Parse.Cloud.run('createTransaction', {
  //                             "user": userPointer,
  //                             "email": Parse.User.current().get('username'),
  //                             "bonusPercent": r.state.bonusRate,
  //                             "totalLare": $('#totalFnbAMount').html().split(' ')[0],
  //                             "feePercent": r.state.feeRate,
  //                             "c1": "USD",
  //                             "c2": r.state.pCurrency,
  //                             "amount": r.state.amount
  //                         }, {
  //                             success: function (results) {
  //                                 r.setState({
  //                                     "finalResults": results,
  //                                     "completeTrans": true,
  //                                     "loadingScreen": false
  //                                 });
  //                             }, error: function (err) {
  //                                 r.setState({"loadingScreen": false});
  //                                 alert(err)
  //                             }
  //                         });
  //                     }
  //                 } else {
  //                     if (r.state.amount < r.state.minimum) {
  //                     } else {
  //                         r.setState({"totalLare": $('#totalFnbAMount').html().split(' ')[0]});
  //                         r.setState({"usdPopup": true, "usdPopupPlace": 0});
  //                     }
  //                 }
  //             }
  //         }, error: function (err) {
  //             console.log(err)
  //         }
  //     });
  //
  // }

  render() {
    // console.log(this.state.activeTab);
    const {activeTab, minimum, amount, pCurrency} = this.state;
    return (
      <div className="page exchangePageWrap">
        {this.state.completeTrans == false ?
          <div className="dashWrapperB exchangeWrap">

            <div className="dashModal ExchangeLeftSide" style={{"width": "50%"}}>
              <div className="content"
                   style={{"height": "auto", "width": "100%", "float": "right"}}>
                <ExchangeLeftSide minimum={minimum} activeTab={activeTab}
                                  updateTab={this.updateTab} pCurrency={pCurrency}
                                  handleCreateTransaction={this.handleCreateTransaction}
                                  amount={amount} handleInputAmount={this.handleInputAmount}
                                  handPaymentSelection={this.handPaymentSelection}/>
              </div>
            </div>
            <div className="dashModal exchnageDetails" style={{"width": "35%"}}>
              <div className="content" style={{
                "height": "auto",
                "width": "100%",
                "margin": "0 ",
                "boxShadow": "inset -1px 0 0 #e0e6ed, inset 1px 0 0 #e0e6ed",
                "border": "0"
              }}>
                {
                  activeTab === "buy"
                    ? <ExchangeDetailsBuy updateData={this.updateTab} activeTab={activeTab}
                                          stateInfo={this.state}
                                          handleCreateTransaction={this.handleCreateTransaction}
                                          setTotalLare={this.setTotalLare}/>

                    : <ExchangeDetailsSell updateData={this.updateTab} activeTab={activeTab}
                                           stateInfo={this.state}
                                           handleCreateTransaction={this.handleCreateTransaction}
                                           setTotalLare={this.setTotalLare}/>
                }
              </div>
            </div>
            <div className="dashModal seperator" style={{"width": "7.5%"}}></div>
            <br style={{"clear": "both"}}/>
          </div>
          :
          <div className="dashWrapperB exchangeResultsPage" style={{"margin": "0 auto"}}>
            <div className="dashModal seperator"
                 style={{"width": "15%", "margin": "0 auto", "textAlign": "right"}}><Link
              to="/exchange/1"><img style={{"width": "20px", "cursor": "pointer"}}
                                    src={backButton}/></Link></div>
            <div className="dashModal contentPage" style={{"width": "30%", "margin": "0 auto"}}>
              <div className="content" style={{"height": "auto", "margin": "0 auto"}}>
                <div className="completeTransactionWrap">
                  <p className="smallUppercaseTitle">Amount to Send</p>
                  <p className="bigTitle red"
                     style={{"paddingBottom": "10px"}}>{comma(this.state.finalResults.amount)} {this.state.pCurrency}</p>
                  <p className="bigTitle smalldescription">Send the <b>EXACT</b> amount only
                  </p>

                  <hr/>
                  <p className="smallUppercaseTitle gray">USD Amount</p>
                  <p className="bigTitle ">${amountRounding(this.state.amount)}</p>
                  <hr/>
                  <p className="smallUppercaseTitle gray">Purchased USDW</p>
                  <p className="bigTitle ">{comma(this.state.totalLare)}</p>
                  <hr/>
                  <p className="smallUppercaseTitle">Your payment id is</p>
                  <p className="bigTitle small">{this.state.finalResults.txn_id}</p>
                </div>
              </div>
            </div>
            <div className="dashModal contentPageDetails"
                 style={{"width": "40%", "margin": "0 auto"}}>
              <div className="content" style={{"height": "auto", "margin": "0 auto"}}>
                <div className="completeTransactionWrap">
                  <p className="smallUppercaseTitle">Send your coins to this address</p>
                  <p className="bigTitle small">{this.state.finalResults.address}</p>
                  <p className="bigTitle smalldescription">Please allow 2 - 4 hours for the
                    coins to show on your dashboard.</p>
                  <hr/>
                  <p className="smallUppercaseTitle">Scan QR Code</p>
                  <p className="svgQRCODE">
                    <QRCode value={this.state.finalResults.address} size="150"
                            renderAs="svg"/>
                  </p>
                  <hr/>

                  <Timer seconds={this.state.finalResults.timeout}/>
                </div>
              </div>
            </div>
            <div className="dashModal seperator" style={{"width": "10%", "margin": "0 auto"}}></div>
            <br style={{"clear": "both"}}/>
          </div>
        }
        {
          this.state.usdPopup === true ?
            <div>
              <div onClick={(e) => this.setState({
                "usdPopup": !this.state.usdPopup,
                "loadingScreen": false
              })} style={{"z-index": "123"}} className="loadingScreen"></div>
              {
                this.state.verifiedUser === false ?

                  <div className="usdPopupmethodsWrap">
                    <div onClick={(e) => this.setState({
                      "usdPopup": !this.state.usdPopup,
                      "loadingScreen": false
                    })} className="closeButton"><i className="fa fa-close"></i></div>
                    <div className="verifyYourAccountAlert" style={{"paddingBottom": "0"}}>
                      <p className="title">Account Verification Required</p>
                      <p className="subtitle">To deposit money using electronic check or a
                        debit/credit card, you must verify your account.</p>
                      <Link className="buyNowBttn verifyAccount" to="/settings/compliance">Verify
                        Account</Link>
                    </div>
                  </div>
                  :
                  <div>
                    <div className="usdPopupmethodsWrap"
                         style={{"display": this.state.usdPopupPlace == 0 ? "block" : "none"}}>
                      <div onClick={(e) => this.setState({
                        "usdPopup": !this.state.usdPopup,
                        "loadingScreen": false
                      })} className="closeButton"><i className="fa fa-close"></i></div>
                      <p className="title">Select Checkout Method</p>
                      <div className="bttnContainers">
                        <a onClick={(e) => this.setState({"usdPopupPlace": 1})}>Wire
                          Transfer</a>
                        <a style={{"backgroundColor": "#28c101"}}
                           onClick={(e) => this.setState({"usdPopupPlace": 2})}>Cash App</a>
                      </div>
                    </div>
                    <div className="usdPopupmethodsWrap"
                         style={{"display": this.state.usdPopupPlace == 1 ? "block" : "none"}}>
                      <div onClick={(e) => this.setState({
                        "usdPopup": !this.state.usdPopup,
                        "loadingScreen": false
                      })} className="closeButton"><i className="fa fa-close"></i></div>
                      <div onClick={(e) => this.setState({"usdPopupPlace": 0})}
                           className="leftButton"><i className="fa fa-arrow-left"></i></div>

                      <div className="electronicCheckWrap" style={{"paddingTop": "0"}}>
                        <DepositDetails amount={this.state.amount} type="electronic"/>
                      </div>
                    </div>
                    <div className="usdPopupmethodsWrap"
                         style={{"display": this.state.usdPopupPlace == 2 ? "block" : "none"}}>
                      <div onClick={(e) => this.setState({
                        "usdPopup": !this.state.usdPopup,
                        "loadingScreen": false
                      })} className="closeButton"><i className="fa fa-close"></i></div>
                      <div onClick={(e) => this.setState({"usdPopupPlace": 0})}
                           className="leftButton"><i className="fa fa-arrow-left"></i></div>
                      <DepositDetails amount={this.state.amount} type="cashapp"/>
                    </div>
                  </div>
              }
            </div>
            :
            ""
        }
        {
          this.state.coinBuyUSA === true ?
            <div>
              <div onClick={(e) => this.setState({"coinBuyUSA": !this.state.coinBuyUSA})}
                   className="loadingScreen"></div>
              <div className="usdPopupmethodsWrap">
                <div onClick={(e) => this.setState({"coinBuyUSA": !this.state.coinBuyUSA})}
                     className="closeButton"><i className="fa fa-close"></i></div>
                <div className="verifyYourAccountAlert" style={{"paddingBottom": "0"}}>
                  <p className="title">Account Verification Required</p>
                  <p className="subtitle">To buy USDW using these cryptocurrencies, you must verify
                    and become accredited.</p>
                  <Link className="buyNowBttn verifyAccount" to="/settings/compliance">Verify
                    Account</Link>
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          this.state.loadingScreen == true ?
            <div className="loadingScreen">
              <div className="loading-spinner"></div>
            </div>
            :
            ""
        }
      </div>
    )
  }
}


export class Exchange2 extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputAmount = this.handleInputAmount.bind(this);
    this.handPaymentSelection = this.handPaymentSelection.bind(this);
    this.handleCreateTransaction = this.handleCreateTransaction.bind(this);
    this.setTotalLare = this.setTotalLare.bind(this);
    this.state = {
      "pCurrency": "BTC",
      "verifiedUser": false,
      "fullCurrency": "Bitcoin",
      "amount": "",
      "exchangeRate": "0.56085",
      "bonusRate": "100",
      "feeRate": "2",
      "USDFeeRate": "0",
      "minimum": 50.00,
      "btcinDollar": "",
      "rates": null,
      "totalLare": "",
      "completeTrans": false,
      "usdPopup": false,
      "usdPopupPlace": 0,
      "finalResults": null,
      "loadingScreen": false,
      "bonusExchangeRate": "0.28425",
      "coinBuyUSA": false
    }
  }

  componentDidMount() {
    var r = this;
    // $.getJSON('https://blockchain.info/tobtc?currency=USD&value=1', function (data) {
    //     r.setState({"btcinDollar": data});
    // });

    fetch('https://blockchain.info/tobtc?currency=USD&value=1')
      .then(response => response.json()
        .then(result => r.setState({"btcinDollar": result})));
    sendParseRequest('getRates')
      .then(results => {
        let arrCrypto = [];

        for (let crypt in results) {
          arrCrypto.push(crypt + ":" + results[crypt]["rate_btc"])
        }

        r.setState({"rates": arrCrypto});
      })

    window.scrollTo(0, 0);
    document.title = "Buy Tickets – USDW";
    var Users = Parse.Object.extend("_User");
    var query = new Parse.Query(Users);
    query.get(Parse.User.current().id, {
      success: function (results) {
        r.setState({
          "verifiedUser": results.get('accountVerified'),
          "accreditedStatus": results.get('accreditedStatus')
        })
      }, error: function (err) {
        message('error', err)
      }
    });
  }

  handPaymentSelection(e) {
    var value = e.target.attributes.id.value.split(':');
    this.setState({"pCurrency": value[0], "fullCurrency": value[1]});
  }

  handleInputAmount(e) {
    this.setState({"amount": e.target.value.replace(/\D/g, '')});
  }

  setTotalLare(e) {
    this.setState({"totalLare": e.target.value});
  }

  // handleCreateTransaction() {
  //     var r = this;
  //     r.setState({"loadingScreen": true});
  //     var Config = Parse.Object.extend("config");
  //     var ConfigQ = new Parse.Query(Config);
  //     ConfigQ.get('k7t0S4tWWZ', {
  //         success: function (CQResults) {
  //             var coinBuyUSA = CQResults.get('coinBuyUSA');
  //             if (coinBuyUSA == true) {
  //                 if (r.state.verifiedUser == true && r.state.accreditedStatus == "Complete") {
  //
  //                     if (r.state.pCurrency != "USD") {
  //                         if (r.state.amount < r.state.minimum) {
  //                         } else {
  //                             r.setState({
  //                                 "totalLare": $('#totalFnbAMount').html().split(' ')[0],
  //                                 "loadingScreen": true
  //                             });
  //                             var userPointer = {
  //                                 "__type": 'Pointer',
  //                                 "className": '_User',
  //                                 "objectId": Parse.User.current().id
  //                             }
  //                             Parse.Cloud.run('createTransaction', {
  //                                 "user": userPointer,
  //                                 "email": Parse.User.current().get('username'),
  //                                 "bonusPercent": r.state.bonusRate,
  //                                 "totalLare": $('#totalFnbAMount').html().split(' ')[0],
  //                                 "feePercent": r.state.feeRate,
  //                                 "c1": "USD",
  //                                 "c2": r.state.pCurrency,
  //                                 "amount": r.state.amount
  //                             }, {
  //                                 success: function (results) {
  //                                     r.setState({
  //                                         "finalResults": results,
  //                                         "completeTrans": true,
  //                                         "loadingScreen": false
  //                                     });
  //                                 }, error: function (err) {
  //                                     r.setState({"loadingScreen": false});
  //                                     alert(err)
  //                                 }
  //                             });
  //                         }
  //                     } else {
  //                         if (r.state.amount < r.state.minimum) {
  //                         } else {
  //                             r.setState({"totalLare": $('#totalFnbAMount').html().split(' ')[0]});
  //                             r.setState({"usdPopup": true, "usdPopupPlace": 0});
  //                         }
  //                     }
  //                 } else {
  //                     r.setState({"coinBuyUSA": true, "loadingScreen": false})
  //                 }
  //             } else {
  //
  //                 if (r.state.pCurrency != "USD") {
  //                     if (r.state.amount < r.state.minimum) {
  //                     } else {
  //                         r.setState({"totalLare": $('#totalFnbAMount').html().split(' ')[0], "loadingScreen": true});
  //                         var userPointer = {
  //                             "__type": 'Pointer',
  //                             "className": '_User',
  //                             "objectId": Parse.User.current().id
  //                         }
  //                         Parse.Cloud.run('createTransaction', {
  //                             "user": userPointer,
  //                             "email": Parse.User.current().get('username'),
  //                             "bonusPercent": r.state.bonusRate,
  //                             "totalLare": $('#totalFnbAMount').html().split(' ')[0],
  //                             "feePercent": r.state.feeRate,
  //                             "c1": "USD",
  //                             "c2": r.state.pCurrency,
  //                             "amount": r.state.amount
  //                         }, {
  //                             success: function (results) {
  //                                 r.setState({
  //                                     "finalResults": results,
  //                                     "completeTrans": true,
  //                                     "loadingScreen": false
  //                                 });
  //                             }, error: function (err) {
  //                                 r.setState({"loadingScreen": false});
  //                                 alert(err)
  //                             }
  //                         });
  //                     }
  //                 } else {
  //                     if (r.state.amount < r.state.minimum) {
  //                     } else {
  //                         r.setState({"totalLare": $('#totalFnbAMount').html().split(' ')[0]});
  //                         r.setState({"usdPopup": true, "usdPopupPlace": 0});
  //                     }
  //                 }
  //             }
  //         }, error: function (err) {
  //             console.log(err)
  //         }
  //     });
  //
  // }

  render() {
    return (
      <div className="page exchangePageWrap">
        {
          this.state.completeTrans == false ?
            <div className="dashWrapperB exchangeWrap">
              <div className="dashModal seperator" style={{"width": "7.5%"}}></div>
              <div className="dashModal Exchange2LeftSide" style={{"width": "50%"}}>
                <div className="content"
                     style={{"height": "auto", "width": "100%", "float": "right"}}>
                  <Exchange2LeftSide minimum={this.state.minimum} pCurrency={this.state.pCurrency}
                                     handleCreateTransaction={this.handleCreateTransaction}
                                     amount={this.state.amount}
                                     handleInputAmount={this.handleInputAmount}
                                     handPaymentSelection={this.handPaymentSelection}/>
                </div>
              </div>
              <div className="dashModal exchnageDetails" style={{"width": "35%"}}>
                <div className="content" style={{
                  "height": "auto",
                  "width": "100%",
                  "margin": "0 ",
                  "boxShadow": "inset -1px 0 0 #e0e6ed, inset 1px 0 0 #e0e6ed",
                  "border": "0"
                }}>
                  <Exchange2Details stateInfo={this.state}
                                    handleCreateTransaction={this.handleCreateTransaction}
                                    setTotalLare={this.setTotalLare}/>
                </div>
              </div>
              <div className="dashModal seperator" style={{"width": "7.5%"}}></div>
              <br style={{"clear": "both"}}/>
            </div>
            :
            <div className="dashWrapperB exchangeResultsPage" style={{"margin": "0 auto"}}>
              <div className="dashModal seperator"
                   style={{"width": "15%", "margin": "0 auto", "textAlign": "right"}}><Link
                to="/exchange/1"><img style={{"width": "20px", "cursor": "pointer"}}
                                      src={backButton}/></Link></div>
              <div className="dashModal contentPage" style={{"width": "30%", "margin": "0 auto"}}>
                <div className="content" style={{"height": "auto", "margin": "0 auto"}}>
                  <div className="completeTransactionWrap">
                    <p className="smallUppercaseTitle">Amount to Send</p>
                    <p className="bigTitle red"
                       style={{"paddingBottom": "10px"}}>{comma(this.state.finalResults.amount)} {this.state.pCurrency}</p>
                    <p className="bigTitle smalldescription">Send the <b>EXACT</b> amount only
                    </p>

                    <hr/>
                    <p className="smallUppercaseTitle gray">USD Amount</p>
                    <p className="bigTitle ">${amountRounding(this.state.amount)}</p>
                    <hr/>
                    <p className="smallUppercaseTitle gray">Purchased USDW</p>
                    <p className="bigTitle ">{comma(this.state.totalLare)}</p>
                    <hr/>
                    <p className="smallUppercaseTitle">Your payment id is</p>
                    <p className="bigTitle small">{this.state.finalResults.txn_id}</p>
                  </div>
                </div>
              </div>
              <div className="dashModal contentPageDetails"
                   style={{"width": "40%", "margin": "0 auto"}}>
                <div className="content" style={{"height": "auto", "margin": "0 auto"}}>
                  <div className="completeTransactionWrap">
                    <p className="smallUppercaseTitle">Send your coins to this address</p>
                    <p className="bigTitle small">{this.state.finalResults.address}</p>
                    <p className="bigTitle smalldescription">Please allow 2 - 4 hours for the
                      coins to show on your dashboard.</p>
                    <hr/>
                    <p className="smallUppercaseTitle">Scan QR Code</p>
                    <p className="svgQRCODE">
                      <QRCode value={this.state.finalResults.address} size="150"
                              renderAs="svg"/>
                    </p>
                    <hr/>

                    <Timer seconds={this.state.finalResults.timeout}/>
                  </div>
                </div>
              </div>
              <div className="dashModal seperator" style={{"width": "10%", "margin": "0 auto"}}></div>
              <br style={{"clear": "both"}}/>
            </div>
        }
        {
          this.state.usdPopup === true ?
            <div>
              <div onClick={(e) => this.setState({
                "usdPopup": !this.state.usdPopup,
                "loadingScreen": false
              })} style={{"z-index": "123"}} className="loadingScreen"></div>
              {
                this.state.verifiedUser === false ?

                  <div className="usdPopupmethodsWrap">
                    <div onClick={(e) => this.setState({
                      "usdPopup": !this.state.usdPopup,
                      "loadingScreen": false
                    })} className="closeButton"><i className="fa fa-close"></i></div>
                    <div className="verifyYourAccountAlert" style={{"paddingBottom": "0"}}>
                      <p className="title">Account Verification Required</p>
                      <p className="subtitle">To deposit money using electronic check or a
                        debit/credit card, you must verify your account.</p>
                      <Link className="buyNowBttn verifyAccount" to="/settings/compliance">Verify
                        Account</Link>
                    </div>
                  </div>
                  :
                  <div>
                    <div className="usdPopupmethodsWrap"
                         style={{"display": this.state.usdPopupPlace === 0 ? "block" : "none"}}>
                      <div onClick={(e) => this.setState({
                        "usdPopup": !this.state.usdPopup,
                        "loadingScreen": false
                      })} className="closeButton"><i className="fa fa-close"></i></div>
                      <p className="title">Select Checkout Method</p>
                      <div className="bttnContainers">
                        <a onClick={(e) => this.setState({"usdPopupPlace": 1})}>Wire
                          Transfer</a>
                        <a style={{"backgroundColor": "#28c101"}}
                           onClick={(e) => this.setState({"usdPopupPlace": 2})}>Cash App</a>
                      </div>
                    </div>
                    <div className="usdPopupmethodsWrap"
                         style={{"display": this.state.usdPopupPlace === 1 ? "block" : "none"}}>
                      <div onClick={(e) => this.setState({
                        "usdPopup": !this.state.usdPopup,
                        "loadingScreen": false
                      })} className="closeButton"><i className="fa fa-close"></i></div>
                      <div onClick={(e) => this.setState({"usdPopupPlace": 0})}
                           className="leftButton"><i className="fa fa-arrow-left"></i></div>

                      <div className="electronicCheckWrap" style={{"paddingTop": "0"}}>
                        <DepositDetails amount={this.state.amount} type="electronic"/>
                      </div>
                    </div>
                    <div className="usdPopupmethodsWrap"
                         style={{"display": this.state.usdPopupPlace === 2 ? "block" : "none"}}>
                      <div onClick={(e) => this.setState({
                        "usdPopup": !this.state.usdPopup,
                        "loadingScreen": false
                      })} className="closeButton"><i className="fa fa-close"></i></div>
                      <div onClick={(e) => this.setState({"usdPopupPlace": 0})}
                           className="leftButton"><i className="fa fa-arrow-left"></i></div>
                      <DepositDetails amount={this.state.amount} type="cashapp"/>
                    </div>
                  </div>
              }
            </div>
            :
            ""
        }
        {
          this.state.coinBuyUSA === true ?
            <div>
              <div onClick={(e) => this.setState({"coinBuyUSA": !this.state.coinBuyUSA})}
                   className="loadingScreen"></div>
              <div className="usdPopupmethodsWrap">
                <div onClick={(e) => this.setState({"coinBuyUSA": !this.state.coinBuyUSA})}
                     className="closeButton"><i className="fa fa-close"></i></div>
                <div className="verifyYourAccountAlert" style={{"paddingBottom": "0"}}>
                  <p className="title">Account Verification Required</p>
                  <p className="subtitle">To buy USDW using these currencies, you must verify
                    and become accredited.</p>
                  <Link className="buyNowBttn verifyAccount" to="/settings/compliance">Verify
                    Account</Link>
                </div>
              </div>
            </div>
            :
            ""
        }

        {
          this.state.loadingScreen === true ?
            <div className="loadingScreen">
              <div className="loading-spinner"></div>
            </div>
            :
            ""
        }
      </div>
    )
  }
}


export class ExchangeDetailsRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "amount": 500,
      "totalSend": 0,
      "txn_id": "123123123123",
      "address": "123123123",
      "totalFNB": 1000,
      "timeout": null,
      "pCurrency": "USDW",
      "details": ""
    }
  }

  componentDidMount() {
    var key = this.props.match.params.key;
    var r = this;
    var Transactions = Parse.Object.extend("Transactions");
    var query = new Parse.Query(Transactions);
    query.get(key, {
      success: function (data) {
        var a = new Date();
        var b = new Date(data.get('ExpireDate'));
        var difference = (b - a) / 1000;
        r.setState({
          "amount": data.get('amount'),
          "totalSend": data.get('totalSend'),
          "txn_id": data.get('txn_id'),
          "address": data.get('address'),
          "totalLare": data.get('totalLare'),
          "pCurrency": data.get('currencyTo'),
          "timeout": difference,
          "details": data.get('Details') === undefined ? "" : data.get('Details')
        });
      },
      error: (object, error) => {
        if (error.message === "Object not found.") {
          this.props.history.push('/exchange');
        } else {
          message('error', error.message)
        }
      }
    });
    window.scrollTo(0, 0)
    document.title = "Exchange Details – USDW";

  }

  render() {
    return (
      <div className="page">

        <div className="dashWrapperB exchangeResultsPage" style={{"margin": "0 auto"}}>
          <div className="dashModal seperator"
               style={{"width": "15%", "margin": "0 auto", "textAlign": "right"}}><Link to="/wallets"><img
            style={{"width": "20px"}} src={backButton}/></Link></div>
          <div className="dashModal contentPage" style={{"width": "30%", "margin": "0 auto"}}>
            <div className="content" style={{"height": "auto", "margin": "0 auto"}}>
              <div className="completeTransactionWrap">
                <p className="smallUppercaseTitle">Amount to Send</p>
                <p className="bigTitle red"
                   style={{"paddingBottom": "10px"}}>{comma(this.state.totalSend)} {this.state.pCurrency}</p>
                <p className="bigTitle smalldescription">Send the <b>EXACT</b> amount only</p>
                <hr/>
                <p className="smallUppercaseTitle gray">USD Amount</p>
                <p className="bigTitle ">${amountRounding(this.state.amount)}</p>
                <hr/>
                <p className="smallUppercaseTitle gray">Purchased USDW</p>
                <p className="bigTitle ">{comma(this.state.totalLare)}</p>
                <hr/>
                <p className="smallUppercaseTitle">Your payment id is</p>
                <p className="bigTitle small">{this.state.txn_id}</p>
              </div>
            </div>
          </div>
          <div className="dashModal contentPageDetails" style={{"width": "40%", "margin": "0 auto"}}>
            <div className="content" style={{"height": "auto", "margin": "0 auto"}}>
              <div className="completeTransactionWrap">
                <p className="smallUppercaseTitle">Send your coins to this address</p>
                <p className="bigTitle small">{this.state.address}</p>
                <p className="bigTitle smalldescription">Please allow 2 - 4 hours for the coins to
                  show on your dashboard.</p>
                <hr/>
                <p className="smallUppercaseTitle" style={{"paddingTop": "10px"}}>Scan QR Code</p>
                <p className="svgQRCODE">
                  <QRCode value={this.state.address} size={150} renderAs="svg"/>
                </p>

                {
                  this.state.details != "Cancelled / Timed Out" || this.state.details != "Complete" || this.state.details != "Canceled By User" ?
                    <span>
                    <hr/>
                      {
                        this.state.timeout != null ?
                          <Timer seconds={this.state.timeout}/>
                          : ""
                      }
                  </span>
                    : ""
                }
                {
                  this.state.details != "" ?
                    <span>
                    <hr/>
                    <p className="smallUppercaseTitle">Status</p>
                    <p className="bigTitle small">{this.state.details}</p>
                  </span>
                    : <br/>
                }
              </div>
            </div>
          </div>
          <div className="dashModal seperator" style={{"width": "10%", "margin": "0 auto"}}></div>
          <br style={{"clear": "both"}}/>
        </div>
      </div>
    )
  }
}


function amountRounding(amount) {
  return comma(parseFloat(amount).toFixed(2))
}


export class Timer extends React.Component {
  constructor() {
    super();
    this.state = {time: {}, seconds: null};
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);
    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);
    return {
      "h": hours,
      "m": minutes,
      "s": seconds
    };
  }

  componentWillMount() {
    this.setState({"seconds": this.props.seconds});
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({time: timeLeftVar});
    if (this.timer == 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  render() {
    return (
      <div className="totalTimerWRap">
        {
          this.props.hide == undefined || this.props.hide == "false" ?
            <p className="smallUppercaseTitle" style={{"paddingTop": "10px"}}>Deposit the amount in</p>
            :
            ""
        }
        <p className="totalTimer"><b>{this.state.time.h}</b> {this.state.time.h == 1 ? "hour" : "hours"}
          <b>{this.state.time.m}</b> minutes <b>{this.state.time.s}</b> seconds</p>
      </div>
    );
  }
}

/*
function laretoAnother(object, t) {
    if (object.totalLare == "0") {
        return "0.00"
    } else {
        var a = parseFloat(object.totalLare);
                       var exchangeRate = parseFloat(object.exchangeRate);
        var totalPerDollar = Math.abs(1 / parseFloat(object.exchangeRate));

        var amount = "";
        if (t == "USD") {
            amount = (object.totalLare * exchangeRate).toFixed(2);
        } else if (t == "BTC") {
            var dollar = (object.totalLare * exchangeRate);
            amount = (object.btcinDollar * dollar).toFixed(8);
        } else {
            var dollar = (object.totalLare * exchangeRate);
            var btc = (object.btcinDollar * dollar);

            if (object.rates.length != 0) {

                var rate = startsWith(object.rates, t)[0].split(':')[1];
                amount = ((btc) / rate).toFixed(5);
            }
        }
        return amount;
    }
}
*/

/*function startsWith(array, key) {
    const matcher = new RegExp(`^${key}`, 'g');
    return array.filter(word => word.match(matcher));
}*/
