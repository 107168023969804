import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import * as blockChainActions from "../../../modules/blockchain/blockchain.action";
import FNBD from "../../../assets/images/f-in-blue1.png";
// import MyTicketsDepositModal from "./MyTicketsDepositModal/MyTicketsDepositModal";
import MyTicketsDepositModal from "./MyTicketsDepositModal/MyTicketsDepositModal";
import closeSvg from "../../../assets/images/close--blue.svg";
import MyTicketsWithdrawModal from "./MyTicketsWithdrawModal/MyTicketsWithdrawModal";
import RoyaltyPopup from "./RoyaltyPopup/RoyaltyPopup";
import {numberWithCommas} from "../../eth_client/helper";
import Parse from "parse";
import {sendParseRequest} from "../../../utils/sendParseRequest";

// import {getPrice} from "../CentralToken/centralTokenData";

const MyTicketsListContainer = ({tickets, fnbCoin, onItemSelect, walletAddress, activeItem}) => {
    console.log('🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁🦁')
    // console.log(fnbCoin, '<=====fnbCoin', onItemSelect, tickets)
    // const tickets = [{'sss':'ddd'}]
    console.log(tickets, '---tickets')
    // const [tick, setTick] = useState([]);

    // useEffect(() => {
    //     tickets && console.log(tickets, "!!!!!!!!!!!!--------------tickets");
    //
    //     tickets.forEach((i) => console.log(i , "--------------i"))
    //     setTick(tickets);
    //     setActiveTab('tickets')
    // }, [tickets]);


    // console.log(tick, "--------------tick")
    const [activeTab, setActiveTab] = useState('tickets');
    const [selectedItem, setSelectedItem] = useState(null);
    const [statusPopup, setStatusPopup] = useState(false);
    const [popupPage, setPopupPage] = useState(null);
    const [activeTabPop, setActiveTabPop] = useState('Withdraw');
    const [activeTabPortfolio, setActiveTabPortfolio] = useState('Balances');
    const [amount, setAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [coin_name, setCoin_name] = useState(null);
    const [contractAddr, setContractAddr] = useState('');
    const [balances, setBalances] = useState({ETH: "", USDC: "", LOOM: ""});
    const [selectedCurrency, selectCurrency] = useState('USDW');
    const [royaltyPopup, setRoyaltyPopup] = useState(false)


    const sendCheck = (amount) => {
        let bearerToken = JSON.parse(localStorage.getItem('checkbookTokens')).access_token;
        return sendParseRequest('sendCheckbookDigitalCheck', {token: bearerToken, amount: amount})
    };

    const sendCheckWithdraw = (amount) => {
        let mail = Parse.User.current().getUsername();
        return sendParseRequest('sendWithdrawDigitalCheck', {amount, mail})
    };

    const saveDigitalCheck = (check) => {
        sendParseRequest('saveDigitalCheck', {depositCheck: check})
    };

    const saveCheck = async (amount, fee, type) => {
        if (type === 'withdraw') {
            let withdrawCheck = await sendCheckWithdraw(amount);

            sendParseRequest('saveWithdrawCheck', {withDraw: withdrawCheck})
            console.log(withdrawCheck)
        } else {
            let depositCheck = await sendCheck(amount);
            let currentUser = Parse.User.current();
            let userId = currentUser.id;

            console.log(depositCheck)
            let check = {
                amount: depositCheck.amount,
                date: depositCheck.date.toString().split(' ')[0],
                description: depositCheck.description,
                fee: fee,
                id: depositCheck.id,
                image_uri: depositCheck.image_uri,
                name: depositCheck.name,
                number: depositCheck.number,
                recipient: depositCheck.recipient,
                status: depositCheck.status,
                userId: userId
            };
            saveDigitalCheck(check, 'usd');
            console.log(depositCheck)
        }

    };

    const makeDeposit = (amount, depositFee) => {
        saveCheck(amount, depositFee);
        console.log(amount);
    };


    const withDraw = (amount, fee, withdraw) => {
        saveCheck(amount, fee, withdraw);
    };

    const openModal = (value, page) => {
        setStatusPopup(value);
        setPopupPage(page);
    };


    const setCurrentTab = (activeTab, type) => {
        type === "pop"
            ? setActiveTabPop(activeTab)
            : type === "portfolio" ?
            setActiveTabPortfolio(activeTab)
            : setActiveTab(activeTab)
    };

    const closeModal = (value, page) => {
        setStatusPopup(value);
        setPopupPage(page);
        setCoin_name(null);
    };


    const handleInputChange = (event, setEvent) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setEvent(value);
    };

    const addValue = (e) => {
        // setAmount(e.target.value * getPrice)
    };

    // const sendEmail = (proj) => {
    //     // Test function of sending letters!
    //     // Later need to connect it to automatic sending when it reaches zero proj.preSellCount
    //     sendParseRequest('sendEmail', {
    //         emailToSend: Parse.User.current().getUsername(),
    //         emailSendFrom: 'info@fanthebase.com',
    //         message: `The goal has been met! This is the first message for the ${proj.symbol} community and will be followed by one from the production team, once the first installment conveys. As a member of this community, it is important that you participate when votes arise, provide feedback when requested, ask meaningful questions in the project forum, and behave ethically and professionally in any promotional capacity, come time to share or advertise the production in your social networks. This is an exciting time for everyone! Expect an introductory message from leadership in the coming weeks, updates when milestones are met and votes on additional installments if the financing is scheduled in tiers. Good Luck! ♡ Fanbase`
    //     })
    // };

    return (
        <div className="myTicketsListContainer">
            <div className="myTicketsListContainer__header myTickets--title">
                <h2 className={`btn-tab ${(activeTab === "tickets") ? "active" : ""}`}
                    onClick={() => setActiveTab("tickets")}>
                    My Tickets
                </h2>
                <h2 className={`btn-tab ${(activeTab === "pairs") ? "active" : ""}`}
                    onClick={() => setActiveTab("pairs")}>
                    Pairs
                </h2>
            </div>
            {activeTab === "tickets" &&
            <ul className="myTicketsList">
                {fnbCoin &&
                <li className={
                    activeItem === fnbCoin ? 'collectibleItem highlight' : 'collectibleItem'}
                    onClick={() => onItemSelect(fnbCoin)}>
                    <div className="myTickets_flex">
                        <div className="myTicketsListItem__logo fnbd__logo">
                            <img src={FNBD} alt='FNBD'/>
                        </div>
                        <div className="myTicketsListItemInfo">
                            <div className="myTicketsListItemInfo__title">
                                {fnbCoin.symbol}
                            </div>
                            <div className="myTicketsListItemInfo__value">
                                {fnbCoin.balance}
                            </div>
                        </div>
                    </div>
                    <div className='control-buttons__block'>
                        <button onClick={() => openModal(true, 'deposit', fnbCoin)}>
                            Deposit
                        </button>
                        <button onClick={() => openModal(true, 'withdraw', fnbCoin)}>Withdraw</button>
                    </div>
                </li>
                }
                {tickets && tickets.map((oneItem, idx) => (
                    <li className={
                        activeItem === oneItem ? 'collectibleItem highlight' : 'collectibleItem'

                    }
                        key={`my_tickets_item_key_${idx}`}
                        onClick={() => onItemSelect(oneItem)}
                    >
                        <div className="myTickets_flex">
                            <div className="myTicketsListItem__logo tickets-round"
                                 // style={{'background': `url(${oneItem.image})`}}
                            >
                                {/*<img src={oneItem.image} alt="Logo"/>*/}

                            </div>
                            <div className="myTicketsListItemInfo">
                                <div className="myTicketsListItemInfo__title tickets-round_descr">
                                    вв
                                    {/*{oneItem.symbol}*/}
                                </div>

                                <div className="myTicketsListItemInfo__value">
                                    <div className="myTicketsListItemInfo__balance">
                                        {/*{numberWithCommas(oneItem.balance)}*/}333
                                    </div>
                                    <div className="myTicketsListItemInfo__supply">
                                        {/*{numberWithCommas(oneItem.totalSupply - oneItem.preSellCount)}*/}444
                                    </div>
                                    <div className="myTicketsListItemInfo__presell">
                                        {/*{numberWithCommas(Math.round(oneItem.preSellCount))}*/}55
                                    </div>
                                </div>
                                <div className="myTicketsListItemInfo__royalty_block">
                                    <button onClick={() => {
                                        setStatusPopup(true)
                                        setPopupPage('royalty')
                                        setSelectedItem(oneItem)
                                    }
                                    }>Royalty
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/*{oneItem.preSellCount === 0 &&*/}
                        {/*<div className='myTicketsListItemInfo__value__controls-block'>*/}
                        {/*    <button>Send</button>*/}
                        {/*    <button>Sell</button>*/}
                        {/*</div>}*/}
                    </li>
                ))
                }
            </ul>
            }
            {/*{activeTab === "pairs" &&*/}
            {/*<ul className="myTicketsList">*/}
            {/*    {tickets && tickets.map((pairs) =>*/}
            {/*        <>*/}
            {/*            <div className="myTokens_flex__block">*/}
            {/*                <div className="myTokens_pairs__block">*/}
            {/*                    <div>*/}
            {/*                        /!*<div  style={{'background': `url(${pairs.image})`}}/>*!/*/}
            {/*                        <img src={pairs.image} alt='imgFirst'/>*/}
            {/*                        {pairs.symbol}</div>*/}
            {/*                    <span> → </span>*/}
            {/*                    <div>*/}
            {/*                        <img src={FNBD} alt='FNBD'/> USDW*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </>*/}
            {/*    )*/}
            {/*    }*/}
            {/*</ul>*/}
            {/*}*/}

            <MyTicketsDepositModal walletAddress={walletAddress}
                                   activeTab={activeTab}
                                   saveDigitalCheck={saveDigitalCheck}
                                   balances={balances}
                                   selectedCurrency={selectedCurrency}
                                   selectCurrency={selectCurrency}
                                   handleInputChange={handleInputChange}
                                   isLoading={isLoading} makeDeposit={makeDeposit}
                                   setCurrentTab={setCurrentTab}
                                   popupPage={popupPage}
                                   setActiveTab={setActiveTab}
                                   closeSvg={closeSvg}
                                   closeModal={closeModal}
                                   statusPopup={statusPopup}
            />
            <MyTicketsWithdrawModal activeTabPop={activeTabPop}
                                    withDraw={withDraw}
                                    walletAddress={walletAddress}
                                    selectedCurrency={selectedCurrency}
                                    selectCurrency={selectCurrency}
                                    addValue={addValue}
                                    amount={amount}
                                    balances={balances}
                                    activeTabPortfolio={activeTabPortfolio}
                                    setCurrentTab={setCurrentTab}
                                    popupPage={popupPage}
                                    closeModal={closeModal}
                                    statusPopup={statusPopup}
            />
            {selectedItem && <RoyaltyPopup
                selectedItem={selectedItem}
                statusPopup={statusPopup}
                closeModal={closeModal}
                popupPage={popupPage}/>}

        </div>
    );
};

function mapStateToProps({blockchain}) {
    return {
        tokens: blockchain.tokens,
        walletAddresses: blockchain.walletAddresses,
        mainWalletAddress: blockchain.mainWalletAddress
    };
}

export default connect(
    mapStateToProps,
    {...blockChainActions}
)(MyTicketsListContainer);

