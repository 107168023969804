import React, {useEffect, useState} from "react";
import MyTokensListContainer from './MyTokensListContainer';
import {connect} from "react-redux";
import "./style.scss";
import constants from "../../eth_client_public/constants";
import {imagesAltCoinsArray} from './centralTokenData'
import OpenOrders from './OpenOrders';
import MyTokensOrderForm from "./MyTokensOrderForm";
import {chart, footer, header} from "../../Components/Chart/data.json";
import HistoryBlock from "../WalletMyTicketsPage/HistoryBlock";
import Chart from "../../Components/Chart/chart.js";
import ethClient from "../../eth_client";

import {sendParseRequest} from "../../../utils/sendParseRequest";
import {
    getEthWalletAddress,
    getWallyBalance,
    getWallyWalletAddress,
    getUSDWWalletBalance,
    getAltCoinsBalance
} from "../../../utils/temndermintWalletsUtils";

const CentralToken = (props) => {
    const [activeItem, setActiveItem] = useState([]);
    const [tokensData, setTokensData] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const [ethWallet, setEthWallet] = useState([]);


    useEffect(() => {
        const getBalances = async () => {
            // let a = await getWallyWalletAddress();
            let gettedEthWallet = await getEthWalletAddress();
            if (gettedEthWallet) setEthWallet(gettedEthWallet)
            let balancesArray = []

            //get WALLY balance from tendermint wallet ==>
            let WallyBalance = await getWallyBalance()
            if (WallyBalance) {
                balancesArray.push({
                    symbol: 'WALLY',
                    balance: WallyBalance,
                    image: imagesAltCoinsArray.WALLY
                })
            }
            //get USDW balance from tendermint wallet ==>
            let USDWBalance = await getUSDWWalletBalance()
            if (USDWBalance) {
                balancesArray.push({
                    symbol: 'USDW',
                    balance: USDWBalance,
                    image: imagesAltCoinsArray.USDW
                })
            }

            //get altcoins (i.e. ETH, LOOM, USDC..) from eth-wallet ==>
            let AltCoinsBalance = await getAltCoinsBalance()
            //constants.ASSETS - list of altcoins names=>
            let altCoinsNamesArr = constants.ASSETS;
            if (AltCoinsBalance) {
                altCoinsNamesArr.forEach((token) => {
                    if (AltCoinsBalance[token] !== undefined) {
                     //+add images from constants for altcoins
                        balancesArray.push({
                            symbol: token,
                            balance: AltCoinsBalance[token],
                            image: imagesAltCoinsArray[token]
                        })
                    }
                })
            }
            setTokensData(balancesArray)
        }

        getBalances()
    }, [])

    const chartsHeader = {
        ...header,
        logo: "selectedItem.logo",
        title: "selectedItem.tickerSymbol"
    };
    const dataForChart = chart['1d4h5j'];


    const place_order_by_sheng = (data) => {
        let walletAddress = ethClient.eth.getActiveAccountAddress();
        props.createOrderSaga(walletAddress, "password", data);

    };
    console.log(tokensData, '--------tokensData-------')

    return (
        <div className="page page--myTickets">
            <div className="myTicketsPageBody">
                <div className="contentBodyContainer">
                    <div className="myTicketsLeftSide">
                        <MyTokensListContainer
                            tokens={tokensData}
                            ethWallet={ethWallet}
                            walletAddress={props.mainWalletAddress}
                        />
                    </div>
                    {/*<div className="myTicketsRightSide">*/}
                    {/*    <div className="myTicketsChart">*/}
                    {/*        <Chart header={chartsHeader}*/}
                    {/*               chart={dataForChart}*/}
                    {/*               footer={footer}*/}
                    {/*               chartOf={selectedItem.id}*/}
                    {/*               selectedpair={selectedItem}/>*/}

                    {/*        <OpenOrders orders={props.orders}*/}
                    {/*                    percetnageLevels={props.percetnageLevels}/>*/}
                    {/*    </div>*/}

                    {/*    <div className="myTicketsRightSide__wrapperOfBottomBlocks">*/}
                    {/*        <MyTokensOrderForm*/}
                    {/*            selectedItem={selectedItem}*/}
                    {/*            activeItem={activeItem}*/}
                    {/*            order_place={place_order_by_sheng}*/}
                    {/*            percetnageLevels={props.percetnageLevels}*/}
                    {/*            fnbd={props.fnbd}*/}
                    {/*        />*/}
                    {/*        <HistoryBlock*/}
                    {/*            // selectedItem={selectedItem}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
            </div>
        </div>
    );
};

function mapStateToProps({blockchain}) {
    return {
        mainWalletAddress: blockchain.mainWalletAddress,
        percetnageLevels: blockchain.percetnageLevels,
        fnbd: blockchain.fnbd,
        tickets: blockchain.tickets,
        orders: blockchain.orders
    };
}


export default connect(
    mapStateToProps,
    null
)(CentralToken);
