import React, {Component} from 'react';

export default class PopupWithProgressBar extends Component {
  popupWrapperEl = React.createRef();
  progressBarEl = React.createRef();
  percentCounter = React.createRef();

  intervalId = '';

  showAndHideAnimation = (show = true) => {
    if (show) {
      this.popupWrapperEl.current.classList.add('progressPopupWrapper--show');
    } else {
      this.popupWrapperEl.current.classList.remove('progressPopupWrapper--show');
    }
  };

  setOrRemoveBlur = (set = true) => {
    const {showPopup} = this.props;
    const bodyEl = document.querySelector('body');

    if (set && showPopup) {
      bodyEl.classList.add('body--blur');
    } else {
      bodyEl.classList.remove('body--blur');
    }
  };

  loadingProcess = () => {
    const progressBar = this.progressBarEl.current;
    const progressBarWidth = progressBar.offsetWidth;
    const progressBarParentWidth = progressBar.parentElement.offsetWidth;

    if (progressBarWidth >= progressBarParentWidth) return;

    const newWidth = progressBarWidth + (Math.random() * (10 - 0.5) + 0.5);

    progressBar.style.width = `${newWidth}px`;
    this.percentCounter.current.innerHTML = `${this.getProgressInPercent(newWidth)}%`;
  };

  getProgressInPercent = (width) => {
    const {loaded} = this.props;
    const progressBar = this.progressBarEl.current;

    if (loaded) return 100;

    if (!progressBar) return 0;

    const progressBarParentWidth = progressBar.parentElement.offsetWidth;

    const finalWidth = Math.floor((width * 100) / progressBarParentWidth);

    return finalWidth < 100 ? finalWidth : 99
  };

  componentDidUpdate({showPopup: showPopupPrev, onLoadingEnd}, prevState, snapshot) {
    const {showPopup, loaded} = this.props;

    if (showPopupPrev !== showPopup && showPopup) {
      this.setOrRemoveBlur();
      this.showAndHideAnimation();
      this.intervalId = setInterval(this.loadingProcess, 100);
    }

    if (loaded) {
      clearInterval(this.intervalId);
      const progressBar = this.progressBarEl.current;
      const progressBarParentWidth = progressBar.parentElement.offsetWidth;

      progressBar.style.width = `${progressBarParentWidth}px`;
      this.percentCounter.current.innerHTML = `${this.getProgressInPercent(progressBarParentWidth)}%`;

      setTimeout(() => {
        this.setOrRemoveBlur(false);
        onLoadingEnd();
      }, 2000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  render() {
    const {showPopup} = this.props;

    if (!showPopup) return null;

    return (
      <div className="progressPopupWrapper"
           ref={this.popupWrapperEl}>
        <div className="progressPopup">
          <h3 className="progressPopup__title">Wait please, data are saving...</h3>

          <p ref={this.percentCounter}
             className="progressPopup__percent">{this.getProgressInPercent()}%</p>

          <div className="popupProgressBar">
            <div ref={this.progressBarEl}
                 style={{width: '20px'}}></div>
          </div>
        </div>
      </div>
    );
  }
}
