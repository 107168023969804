import React, {Component, Fragment} from 'react';
import Parse from "parse";
import googleLibphonenumber from "google-libphonenumber";
import Phone from "../../../../assets/images/smartphone.png"
import {NavLink} from "react-router-dom";
import {sendParseRequest} from "../../../../utils/sendParseRequest";
import startLogin from "../../../../utils/startLogin";
import shouldUserConfirmStatus from "../../../../utils/shouldUserConfirmStatus";


const phoneUtil = googleLibphonenumber.PhoneNumberUtil.getInstance();
const PNF = googleLibphonenumber.PhoneNumberFormat;
const TIMER_COUNT = 60 * 1000;
let intervalId;

export class TwoStepVerificationSMS extends Component {
    state = {
        isCodeValidated: true,
        verifyCode: null,
        code: '',
        thirtyDayCheckbox: false,
        phone: '',
        phones: [],
        showBtn: true,
        timer: 0,
    };

    componentDidMount = () => {
        this.checkTimer();
        let self = this;
        const userEmail = atob(localStorage.getItem('email'));

        if (userEmail) {
            sendParseRequest('getInfo', {
                query: 'User',
                select: ['phones'],
                equal: [
                    {field: 'email', value: userEmail}
                ]
            }).then(res => {
                let phone = '';
                const phones = res[0].get('phones');
                phones.forEach(item => {
                    if (item.split(':::')[2] === 'primary') {
                        phone = item.split(':::')[0];
                        self.setState({phone: phone})
                        return phone
                    }
                });
            })
        }
    };

    handleCodeChange = (e) => {
        this.setState({code: e.target.value});
    };

    onSubmit = (e) => {
        const {verifyCode, code} = this.state;
        let verified;
        const userEmail = atob(localStorage.getItem('email'));
        const userPassword = atob(localStorage.getItem('password'));

        try {
            verified = code.toString() === verifyCode.toString();
        } catch (e) {
            verified = false
        }

        if (verified) {
            startLogin(userEmail, userPassword, true)
                .then(() => {
                    shouldUserConfirmStatus(this.props.history.push, '/');
                })
        } else {
            if (e.which === 13 || e === "submit") {
                this.setState({
                    isCodeValidated: false
                })
            }
        }
    };

    sendCode = () => {
        const self = this;
        const {phone} = this.state;
        self.setTimer(true);
        this.setState({timer: TIMER_COUNT});
        if (phone !== '') {
            sendParseRequest('sendAdditionalPhoneCode', {pn: phone})
                .then(result => {
                    self.setState({verifyCode: result, phone: phone, timer: TIMER_COUNT});
                })
        }
    };

    getHiddenNumber = (phone = '') => {
        let number;
        try {
            number = phoneUtil.parse(phone);
            number = phoneUtil.format(number, PNF.INTERNATIONAL);
            number = number.split('').map(letter => {
                return letter.replace(/\d/, 'x');
            });
            return number.join('').slice(0, number.length - 2) + phone.slice(phone.length - 2);
        } catch (e) {
            if (phone !== '') console.log('There is error in getHiddenNumber', e.message);
            return phone.length > 1 && typeof (phone) === "string" ? phone.replace(/\d+/, 'x'.repeat(phone.length - 3)) + phone.slice(phone.length - 2) : '';
        }
    };

    // the blocking of the 'verify' btn and the showing a countdown
    setTimer = (newTimer) => {
        if (newTimer) {
            const newTime = addMinutes(new Date(), 1);
            sessionStorage.setItem('timer', JSON.stringify(newTime.getTime()));
        }
        intervalId = setInterval(() => {
                const timerCount = this.state.timer;
                const time = new Date().getTime();
                const oldTimer = JSON.parse(sessionStorage.getItem('timer'));
                if (time > oldTimer) {
                    //when a countdown is over we show the 'verify' btn and update a timer
                    this.setState({showBtn: true, timer: TIMER_COUNT});
                    clearInterval(intervalId)
                } else {
                    this.setState({showBtn: false, timer: timerCount - 1000})
                }
            },
            1000);
    };

    //if timer already started we are show a countdown
    checkTimer = () => {
        const oldTimer = JSON.parse(sessionStorage.getItem('timer'));
        const nowTime = new Date().getTime();

        if (nowTime > oldTimer) {
            this.setState({showBtn: true, timer: TIMER_COUNT});
        } else {
            const count = oldTimer - nowTime;
            this.setState({showBtn: false, timer: count});
            this.setTimer(false)
        }
    };

    componentWillUnmount() {
        clearInterval(intervalId)
    }

    render() {
        const {isCodeValidated, phone, verifyCode, showBtn, timer} = this.state;

        return (
            <Fragment>
                <div className="loginScreen thankyouScreen"
                     style={{minHeight: Parse.User.current() ? 'calc(100% - 201px)' : 'calc(100% - 62px)'}}>
                    <div className="screenWrapper">
                        <div className="two-step_first-wrapper">
                            <div className="two-step_second-wrapper">
                                <span className="two-step_title">2-Step Verification</span>
                                <span
                                    className="two-step_desc">{`Your code will be texted to phone number:`} <br/>{
                                    ` ${this.getHiddenNumber(phone)}`}</span>
                            </div>
                            <div className="auth-img_block"><img src={Phone} alt=''/></div>
                            <div className="enter-code sms">
                                <div className="controls">
									<span>
										<strong>Press 'VERIFY' button to receive code:</strong>
                                        {/*<strong>Enter 2-step verification code:</strong>*/}
									</span>

                                    <div className="unput-two-step">
                                        {
                                            showBtn ?
                                                <input className="btn-two-step" type="submit" value="Verify"
                                                       id="step_two_verify"
                                                       onClick={this.sendCode}
                                                />
                                                : <input className="btn-two-step" type="submit" value={msToTime(timer)}
                                                         id="step_two_verify"
                                                />

                                        }

                                        <input className={isCodeValidated ? "code-input" : "code-input red"} type="text"
                                               id="token"
                                               maxLength="6"
                                               autoComplete="off" onChange={this.handleCodeChange}
                                               onKeyPress={this.onSubmit}/>
                                    </div>
                                </div>
                            </div>
                            <div className="two-step_footer">
                                {/*<input type="checkbox" checked={thirtyDayCheckbox} onChange={this.handleThirtyDayCheckbox}/>*/}
                                {/*<span*/}
                                {/*className="two-step_desc">Don't ask me for the code again for 30 days when I use this computer.</span>*/}
                            </div>
                            <NavLink to='/signin_step_two'>Problems? Use Google authenticator. </NavLink>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60 * 1000);
}

function msToTime(duration) {
    let seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60);
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return minutes + ":" + seconds;
}
