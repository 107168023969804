import React, {Fragment, useState} from 'react';

import isEmail from '../../../utils/isEmail';
import UserList from './UserList';
import AddUser from './AddUser';
import {addPrincipalOrCollaborator} from '../../../utils/addPrincipalOrCollaborator';

/*
*
* Main component in this file.
* It include handlers for show form and getting data from principals and collaborations form
*
* */

const INITIAL_STATE = {
  isShowForm: false,
  isValidForm: false,
  newUser: {
    names: {
      fname: '',
      lname: '',
      email: ''
    },
    roles: [],
    id: ''
  }
};

const AddUserListContainer = ({userList, listType, title, isOwnerOfTheProject, projectId, projectTitle}) => {
  const [userListState, setUserListState] = useState(INITIAL_STATE);

  const {
    isShowForm,
    newUser,
    isValidForm
  } = userListState;

  const onDeleteRole = (idxRoleToDelete) => {
    const {roles: selectedRoles} = newUser;

    setUserListState((state) => ({
      ...state,
      isValidForm: selectedRoles.length > 1 && state.isValidForm,
      newUser: {
        ...newUser,
        roles: [
          ...selectedRoles.slice(0, idxRoleToDelete),
          ...selectedRoles.slice(idxRoleToDelete + 1),
        ]
      }
    }));
  };

  const onGetDataFromForm = (evt) => {
    const fieldDomEl = evt.target;
    const fieldName = evt.target.name;
    const fieldValue = fieldName === 'role' ? [...newUser.roles, evt.target.value] : evt.target.value;
    let userData;

    if (fieldName === 'role') {
      userData = {
        ...newUser,
        roles: fieldValue
      }
    } else {
      userData = {
        ...newUser,
        names: {
          ...newUser.names,
          [fieldName]: fieldValue
        }
      }
    }

    if (fieldName === 'role' && fieldValue.length > 6) return;

    if (fieldName === 'email') {
      if (!isEmail(fieldValue)) {
        fieldDomEl.classList.add('addUsersInputBlock--input__notValid');
      } else {
        fieldDomEl.classList.remove('addUsersInputBlock--input__notValid');
      }
    }

    const isValidField = checkStr(userData.names.fname) &&
      checkStr(userData.names.lname) &&
      checkStr(userData.names.email) &&
      !fieldDomEl.classList.contains('addUsersInputBlock--input__notValid') &&
      userData.roles.length > 0;

    setUserListState((state) => ({
      ...state,
      isValidForm: isValidField,
      newUser: userData
    }));
  };

  const onUserReset = (evt) => {
    evt.preventDefault();
    
    addPrincipalOrCollaborator(newUser.names, projectId, projectTitle, listType, newUser.roles)

    setUserListState(({isShowForm}) => ({
      ...INITIAL_STATE,
      isShowForm
    }));

    evt.target.reset();
  };

  const onEditUser = (newUser) => {
    setUserListState({
      isShowForm: true,
      newUser,
      isValidForm: true,
    });
  };

  const toggleForm = () => {
    setUserListState((state) => ({
      ...state,
      isShowForm: !state.isShowForm,
      newUser: {
        names: {
          fname: '',
          lname: '',
          email: ''
        },
        roles: [],
        id: ''
      },
      isValidForm: false
    }));
  };

  return (
    <Fragment>
      <p className="s-input-title" style={{"fontSize": "18px"}}>
        <label style={{paddingRight: '10px'}}>{title} List</label>
        {
          isOwnerOfTheProject &&
          <button onClick={toggleForm} className="editBtn editBtn--withoutBorder">{isShowForm ? 'Hide' : 'Add'}</button>
        }
      </p>

      {
        <AddUser onGetDataFromForm={onGetDataFromForm}
                 isShowForm={isShowForm}
                 isValidForm={isValidForm}
                 newUser={newUser}
                 onDeleteRole={onDeleteRole}
                 onUserReset={onUserReset}/>
      }

      <UserList userList={userList}
                listType={listType}
                onEditUser={onEditUser}
                isOwnerOfTheProject={isOwnerOfTheProject}
      />
    </Fragment>
  );
};

const checkStr = (str) => str.trim() !== '';

export default AddUserListContainer;
