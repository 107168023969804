import React, {Component, Fragment, useState, useEffect} from "react";
import {connect} from "react-redux";
import Parse from 'parse';
import {Loader} from "../Loader";

import refreshSvg from '../../../assets/images/refresh-button.svg';
import {amountInTIX, convertUinxTimeStampToDate, buyNowPlusFee} from "../../eth_client/helper";
import * as blockchainActions from "../../../modules/blockchain/blockchain.action";

import './index.css'
import ethClient from "../../eth_client";
import axios from "axios";
import constants from "../../api/constant";
import {sendParseRequest} from "../../../utils/sendParseRequest";
import {sendAxiosRequest} from "../../../utils/sendAxiosRequest";
import Arrow from '../../../assets/images/right-chevron.svg'
import {walletService} from "../../api/tendermint/main";
import sighInTendermint from "../../api/tendermint/signIn";
import encryptBack from "../../helpers/encryptBack";
import closeSvg from "../../../assets/images/close--blue.svg";
import {getWallyWalletObject, sentUSDWToWallet, callBurnTokensUSDW} from '../../../utils/temndermintWalletsUtils'

const auth = constants.auth;

const Card = ({activeAuctions, getAuctionLisences, projectID}) => {

    const statusMessage = React.createRef();
    const passwordRef = React.createRef();
    const [bid, setStateBid] = useState(0);
    const [usersBid, setUsersBid] = useState(false);
    const [maxBid, setMaxBid] = useState(0);
    const [fee, setFee] = useState(0.01);
    const [currentBid, setCurrentBid] = useState(10);
    const [waitForResponse, setWaitForResponse] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [array, setArray] = useState([1, 2, 3]);
    const [selectedAuction, setSelectedAuction] = useState(null)
    const [auctionDate, setAuctionDate] = useState(null)
    const [activeWallet, setActiveWallet] = useState(null)
    const [maxBidMessage, setMaxBidMessage] = useState(null)
    const [licenseIndex, setLicenseIndex] = useState(null)
    const [animationCount, setAnimationCount] = useState(null)
    const [usdwWallet, setUsdwWallet] = useState('loading');
    const [statusPopup, setStatusPopup] = useState(false);
    const [selectedTokenObj, setSelectedTokenObj] = useState(null);

    const getUsdw = async () => {
        getAddress()
        const walletAddress = await ethClient.eth.getActiveAccountAddress();
        console.log(walletAddress, '------walletAddress')
        console.log(activeWallet, '--activeWallet')
        let gettedBalance = await walletService.getUSDWBalance(walletAddress)
        if (gettedBalance) {
            console.log(gettedBalance, '--gettedBalance')
            setUsdwWallet(gettedBalance.balance)
        }
    }

    useEffect(() => {
        getUsdw()
    }, [])
    console.log(activeAuctions, licenseIndex, "--------------activeAuctions")
    const id = '22';
    const auctionEndDate = '2021, 05, 20';
    const auctionStartPrice = 444;
    const bidPrice = 100;
    const auctionBuyNowPrice = 5000;

    // state = {
    //   bid: 0,
    //   usersBid: 1500,
    //   fee: 0.01,
    //   currentBid: -1,
    //   waitForResponse: false,
    //   isLoading: false
    // };

    const getAddress = async () => {
        const walletAddress = await ethClient.eth.getActiveAccountAddress();
        setActiveWallet(walletAddress)
    };

    useEffect(() => {
        console.log(activeAuctions, "--------------activeAuctions")
        setSelectedAuction(activeAuctions[0]);
        setLicenseIndex(0);

        getAddress()
        // console.log(selectedAuction.auctionDate)
    }, [activeAuctions])


    useEffect(() => {
        if (selectedAuction) {
            let year = +selectedAuction.auctionDate.getFullYear();
            let month = +selectedAuction.auctionDate.getMonth() + 1;
            let day = +selectedAuction.auctionDate.getDate();
            let hr = +selectedAuction.auctionDate.getHours();
            let minute = +selectedAuction.auctionDate.getMinutes();
            let changeTimeFormat = [year, month, day]
            changeTimeFormat.forEach((item, idx) => {
                if (item < 10) {
                    changeTimeFormat[idx] = `0${item}`;
                }
            });
            let selectedMonth;
            let monthArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            monthArr.forEach((i, idx) => {
                    if ((idx + 1) === month) {
                        selectedMonth = i;
                    }
                    console.log(i, idx, "--------------i, idx")
                }
            )
            console.log(month)
            // const auctionDatePrepare = changeTimeFormat[2] + " " + changeTimeFormat[1] + " " + changeTimeFormat[0] + " " + hr + ":" + minute;
            const auctionDatePrepare = changeTimeFormat[2] + " " + selectedMonth + " " + changeTimeFormat[0] + " " + hr + ":" + minute;
            console.log(auctionDatePrepare, "--------------auctionDatePrepare")
            setAuctionDate(auctionDatePrepare)
        }


        // console.log(selectedAuction.auctionDate)
    }, [selectedAuction]);


    const handleBid = (evt, type) => {
        const inputEl = evt.target;
        const value = inputEl.value.replace(/\D/g, "");
        if (type === 'bid') {

            if (+value > +selectedAuction.currentBid[0].currentBid) {
                setUsersBid(+value)
            } else {
                setUsersBid(false)
            }
            setStateBid(+value);
        } else if (type === 'maxBid') {
            setMaxBid(value)
            console.log(value, "--------------value")
        }

    };

    const saveUserBid = (userId) => {
        console.log(111, "--------------111")
        let bidObj = {
            selectedAuction: selectedAuction,
            usersBid: usersBid,
            activeWallet: activeWallet,
            userId: userId,
        };


        sendParseRequest('setBidforActiveAuction', bidObj)
            .then(res => {

                if (res) {
                    getAuctionLisences(projectID);
                    setWaitForResponse(false)
                }
                console.log(res, '---res--')

            }).catch((e) => console.log(e));
    };

    const setBid = async () => {
        const userId = Parse.User.current().id;

        if (maxBid) {
            let statusValue = false;
            const promise = await new Promise((resolve, reject) => {
                let maxBidObj = {
                    selectedAuction: selectedAuction,
                    maxBid: maxBid,
                    activeWallet: activeWallet,
                    userId: userId
                };
                // selectedAuction,maxBid, activeWallet, userId
                sendParseRequest('setMaxBid', maxBidObj)
                    .then(res => {
                        if (res) {
                            console.log(res, "--------------res")
                            if (res.status) {
                                saveUserBid(userId);
                            } else {
                                setMaxBidMessage(res);
                                setMaxBid(null)
                            }
                            // statusValue = true
                        }
                        resolve(true)


                    }).catch((e) => console.log(e));
                // statusValue && resolve(true);

            });
            setStateBid(false)
            console.log(promise, "--------------promise")
            // if (statusValue) {
            //   console.log(statusValue, "--------------statusValue")
            //   console.log('=====SOOOOOOOQAAAA====')
            //   saveUserBid(userId);
            // }
        } else {
            saveUserBid(userId);
            if (!Parse.User.current()) return false;


            // const {bid} = this.state;
            setWaitForResponse(true)
            // this.setState({waitForResponse: true});

            // Parse.Cloud.run('addBid', {userId: currUser.id, licenseId: lastAuction.id, bid},
            //   {
            //     success: ({currentBid}) => {
            //       this.setState({
            //         currentBid,
            //         bid: 0,
            //         waitForResponse: false
            //       });
            //       this.getStatusMessage(true);
            //     },
            //     error: () => this.getStatusMessage(false),
            //   });
        }
    };

    // getStatusMessage = (success) => {
    //   const statusMessage = this.statusMessage.current;
    //   const message = !success ? 'An error has occurred' : 'Bid accepted';
    //
    //   statusMessage.innerHTML = message;
    //   statusMessage.classList.add('auctionCardStatusMessage__show');
    //
    //   this.closeMessageTimeoutId = setTimeout(() => {
    //     this.statusMessage.current.classList.remove('auctionCardStatusMessage__show');
    //   }, 7000);
    // };


    const getBidInfo = (id) => {
        setStateBid(false)
        getAuctionLisences();
        setSelectedAuction(activeAuctions[licenseIndex])
        console.log(111, "--------------111");
        // const auctionId = id ? id : lastAuction.id;

        // Parse.Cloud.run('getBidInfo', {licenseId: auctionId},
        //   {
        //     success: ({bidsInfo}) => {
        //       if (!bidsInfo) {
        //         return this.setState({
        //           bid: 0,
        //           isLoading: false
        //         });
        //       }

        //       const currentBid = bidsInfo[0].bid;

        //       this.setState({
        //         currentBid,
        //         bid: 0,
        //         isLoading: false
        //       });
        //     },
        //     error: (err) => {
        //       console.log('----------err', err);
        //     }
        //   });
    };


    const buyNowBlockchain = async (address) => {
        // let data = {"count": 1, "to": "0x017d72869aeed2f66223871839de1d673a85b165", "id": `0x${address}`};
        // console.log(data, "--------------data")
        // const result1 = await axios({
        //   method: 'post',
        //   url: `${constants.apiRoot}/contracts/fanbase/mint`,
        //   data,
        //   dataType: 'json',
        //   auth,
        //
        // });
        // console.log(result1, '==================result 1')
    };

    const buyNowFunc = async () => {
        // let password = passwordRef.current.value;
        let walletAddress = ethClient.eth.getActiveAccountAddress();
        let walletsObj = await ethClient.eth.getActivePrivateKey()
        let price = buyNowPlusFee(selectedTokenObj.priceBuyNow);
        // let checkedWallet = JSON.parse(walletsObj[0].serJsonString)
        // let importedObj = await walletService.decryptSerializedJsonStr(checkedWallet, password)


        let passwordForWally = passwordRef.current.value;

        // console.log(  walletAddress,ethWallet,'  walletAddress,ethWallet,',   passwordRef.current.value)
        let wallyDecryptedObject = await getWallyWalletObject(passwordForWally)

console.log(wallyDecryptedObject, '--wallyDecryptedObject')

        let signed = await sighInTendermint(wallyDecryptedObject.walletObject, walletAddress, undefined, 1)
        let signEncrypted = encryptBack(signed);
        let dataForRequest = {
            id: selectedTokenObj.idOfLicense,
            signed: signEncrypted,
            wallet: walletAddress,
            price: price
        };

        // buyNowCollectible
        console.log('--dataForRequest', dataForRequest)
        // sendAxiosRequest('auction/buyNowCollectible', dataForRequest, 'nonParse')
        //     .then(res => {
        //         if (res) {
        //             // hideModal()
        //         }
        //         console.log(res)
        //     }).catch((e) => console.log(e));
    }
    const buyNow = async (selectedToken) => {

        setStatusPopup(!statusPopup)
        setSelectedTokenObj(selectedToken)

        console.log(selectedTokenObj, '--selected obj <<', selectedToken)


        // let data = {
        //     "tokenUrls": selectedToken.projectId
        // };
        //
        // // console.log(data, "--------------data")
        // console.log(selectedToken.projectId, "--------------selectedToken.projectId")
        // const result2 = await axios({
        //     method: 'post',
        //     url: `${constants.apiRoot}/contracts/projects/getProjectsByTokenURIs `,
        //     responseType: 'json',
        //     auth,
        //     data
        // });
        //
        // console.log(result2.data.projects[0], "--------------result2")
        // if (result2) {
        //     // buyNowBlockchain(result2.data.projects[0].properties.id)
        // }


        // setBidforActiveAuction

        // const {lastAuction, mainWalletAddress} = this.props;
        // const price = lastAuction.auctionBuyNowPrice;
        // this.props.buyCollectibleNowSaga(mainWalletAddress, "password", lastAuction.id, lastAuction.auctionBuyNowPrice);
        // const currUser = Parse.User.current();

        // if (!currUser || !lastAuction.id) return false;

        // Parse.Cloud.run('buyNow', {licenseId: lastAuction.id, userId: currUser.id},
        //   {
        //     success: () => {
        //       alert('The license purchased');
        //     },
        //     error: () => {
        //     }
        //   });
    };

    // componentWillUpdate({lastAuction}, nextState, nextContext) {
    // const {
    //   lastAuction: currLastAuction
    // } = this.props;

    // if (lastAuction.id && lastAuction.id !== currLastAuction.id) {
    //   this.getBidInfo(lastAuction.id);
    // }
    // }

    // componentWillUnmount() {
    // clearTimeout(this.closeMessageTimeoutId);
    // }

    const setNextLicense = async (type) => {
        let nextLicense;
        if (type === '+') {
            nextLicense = licenseIndex + 1

        } else {
            nextLicense = licenseIndex - 1
        }
        if (licenseIndex === 0 || licenseIndex < activeAuctions.length) {

            let a = await new Promise((resolve) => {

                function sayHi() {
                    // bounceInRevertDuplicate
                    document.querySelector('.animblock').classList.remove('bounceInRight');
                    document.querySelector('.animblock').classList.add('bounceOutLeft');
                }

                setTimeout(sayHi, 10);

                function sayHi2() {
                    document.querySelector('.animblock').classList.remove('bounceOutLeft');

                    resolve(true)
                }

                setTimeout(sayHi2, 400);

            });

            if (a) {

                let a2 = ((document.querySelector('.animblock').className.split('animblock')[1]).toString()).trim();
                if (!a2) {
                    document.querySelector('.animblock').classList.add('bounceInLeft');
                    console.log("--------------111111==")
                }
                if (a2 == 'bounceInLeft') {
                    document.querySelector('.animblock').classList.remove('bounceInLeft');
                    document.querySelector('.animblock').classList.add('bounceInRight');
                }
                if (a2 == 'bounceInRight') {
                    document.querySelector('.animblock').classList.remove('bounceInRight');
                    document.querySelector('.animblock').classList.add('bounceInLeft')
                }

                console.log(a2, "--------------a")
                // document.querySelector('.animblock').classList.add('bounceInRight')
            }

            setSelectedAuction(activeAuctions[nextLicense]);
            setLicenseIndex(nextLicense)

        }

    };

    const newBid = currentBid < 0 ? amountInTIX(auctionStartPrice) : currentBid;
    const buyItNowBtn = (selectedToken) => {
        return (
            newBid < auctionBuyNowPrice && (
                <Fragment>


                    {usdwWallet > 0 ? <>  <span>or</span>
                            <button className="do-bid__btn do-bid__btn--buyNow"
                                    onClick={() => buyNow(selectedToken)}>
                                Buy it now for {buyNowPlusFee(selectedToken.priceBuyNow)} USDW
                            </button>
                        </>
                        : <p>Add USDW to your wallet to purchase this item {usdwWallet}</p>}
                </Fragment>
            )
        );
    };
    const dateObj = convertUinxTimeStampToDate(auctionEndDate);
// console.log(auctionEndDate, "--------------auctionEndDate")

    // if (selectedAuction) {
    // }
    // selectedAuction &&console.log(selectedAuction.auctionDate, '---auctionDate')
    selectedAuction && console.log(selectedAuction.priceBuyNow, '11111111')
    console.log(selectedAuction, "--------------selectedAuction")
    return (

        <>{statusPopup &&
        <div className="slideshowModal ">
            <button className="slideshowModalWrapper__closeButton slideshowModalButton">
                <img src={closeSvg} alt="close"
                     onMouseDown={() => setStatusPopup(false)}
                />
            </button>
            <div className=" popup-withdraw popup_tokens" style={{height: "40%"}}>
                <div className="creator-popup-block" style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                    <h2> Enter your wallet's password</h2>
                    <p style={{marginTop: "10px"}}>for <b>{activeWallet}</b></p>
                    <input type="password" className="your-bid" ref={passwordRef} style={{marginTop: "10px", width: '100%', padding: "0 10px", height: "40px"}}/>
                </div>
                <button type="button" className="popup_tokens-btn" onClick={() => buyNowFunc()}>Buy it now!</button>
            </div>
        </div>}
            {selectedAuction &&
            <div className="auction-card" style={{position: 'relative'}}>
                <div className={'animblock bounceInRight'}>
                    <button className="auction-card__updateBtn"
                            onClick={() => getBidInfo()}>
                        <img src={refreshSvg} alt="Update"/>
                    </button>

                    <Loader showLoader={isLoading} style={{height: "100%", position: "static"}}>
                        <div>
                            <div className="auction-card__header">
                                <div className="card-header__title">
                                    <div className='card-header__description'>
                                        you are now bidding on...
                                    </div>
                                    <div
                                        className="auction-card__header__title justify-content__center tickersymbol-title">
                                        {selectedAuction.tickerSymbol}(#{selectedAuction.idOfLicense.split('-')[1]})
                                    </div>
                                </div>
                                <div
                                    className="auction-card__header__sub-title justify-content__center tickersymbol-title">
                                    {/*{id} of {activeAuctions.length}*/}
                                </div>
                            </div>

                            <div className="auction-card__body">
                                <BodySector title={"Current bid"} value={selectedAuction.currentBid[0].currentBid}
                                            oppositeValue={"USDW"}/>
                                <div className="body-sector">
                                    <div className="bid-header">
                                        input max autobid
                                    </div>
                                    <div
                                        className={`bid-value flex justify-content__space-between align-items__center ${(bid <= newBid) ? '' : 'border-green'}`}>
                                        <input className="your-bid"
                                               type='number'
                                               placeholder={`50000`}
                                               value={maxBid ? maxBid : ''}
                                               onChange={(e) => handleBid(e, 'maxBid')}/>
                                        <span>USDW</span>
                                    </div>
                                    {maxBidMessage &&
                                    <p style={{'fontSize': '10px', 'color': 'red'}}>{maxBidMessage}</p>}
                                </div>
                                <div className="body-sector">
                                    <div className="bid-header">
                                        Your bid
                                    </div>
                                    <div
                                        className={`bid-value flex justify-content__space-between align-items__center ${(bid <= newBid) ? '' : 'border-green'}`}>
                                        <input className="your-bid"
                                               type='number'
                                               placeholder={`${selectedAuction.currentBid[0].currentBid + 1} or more`}
                                               value={bid ? bid : ''}

                                               onChange={(e) => handleBid(e, 'bid')}/>
                                        <span>USDW</span>
                                    </div>
                                </div>
                                {auctionDate && <BodySector title={"Auction closes"} value={auctionDate}/>}
                                <BodySector title={"Item Id"} value={selectedAuction.idOfLicense.split('-')[1]}/>
                            </div>
                            <div className="auction-card__btn-section">
                                <DiscountSector value={"Subtotal"} oppositeValue={bid}/>
                                <DiscountSector value={"Transaction Fee (1%)"} oppositeValue={bid * fee}/>
                                <DiscountSector value={"Total"} oppositeValue={bid + bid * fee}/>

                                {waitForResponse ?
                                    <div className='image-spinner-frame'>
                                        <div className="loader__blue">Loading...</div>
                                    </div>
                                    :
                                    <Fragment>
                                        {activeWallet &&
                                        <button className={usersBid ? "do-bid__btn" : "do-bid__btn disable-bid-btn"}
                                            // disabled={usersBid}
                                                onClick={setBid}>
                                            Authorize Bid
                                        </button>}

                                        {selectedAuction && activeWallet && buyItNowBtn(selectedAuction)}
                                    </Fragment>
                                }
                            </div>


                            <p className="auctionCardStatusMessage"
                               ref={statusMessage}/>
                        </div>

                    </Loader>
                </div>
                {licenseIndex + 1 < activeAuctions.length &&
                <img src={Arrow} className='card_right-arrow' onClick={() => {
                    setNextLicense('+')

                }}/>}
                {licenseIndex !== 0 && <img src={Arrow} className='card_right-left' onClick={() => {
                    setNextLicense('-')

                }}/>}
            </div>
            }
        </>
    );

}

const BodySector = (
{
    title, value, oppositeValue = ''
}
) => (
<div className="body-sector">
    <div className="bid-header">
        {title}

    </div>
    <div className="bid-value flex justify-content__space-between align-items__center">
        <span>{value}</span>
        <span>{oppositeValue}</span>
    </div>
</div>
);

const DiscountSector = (
{
    value, oppositeValue = 0
}
) => (
<div className="discount-description">
    <span className="pull-left title">{value}</span>
    <span className="pul l-right title">{oppositeValue}</span>
    <div className="dashedVerticalLine"/>
</div>
);

function mapStateToProps(
{
    blockchain
}
)
{
    return {
        mainWalletAddress: blockchain.mainWalletAddress
    };
}

export default connect(
mapStateToProps,
{...
    blockchainActions
}
)(Card);
