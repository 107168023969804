import axios from "axios";
import constants from "../../constant";

const auth = constants.auth;

class Collectibles {

    constructor() {

    }

    totalSupply(callback) {
        axios({
            method: 'get',
            url: `${constants.apiRoot}/contracts/collectibles/totalSupply`,
            responseType: 'json',
            auth: constants.auth
        }).then((response) => {
            callback(response.data);
        });
    }

    balanceOf(address, callback) {
        axios({
            method: 'get',
            url: `${constants.apiRoot}/contracts/collectibles/balanceOf`,
            params: {
                address
            },
            responseType: 'json',
            auth
        }).then((response) => {
            callback(response.data);
        });
    }

    async tokensOfOwner(address) {
        const result = await axios({
            method: "get",
            url: `${constants.apiRoot}/contracts/collectibles/collectiblesOfOwner`,
            params: {
                address
            },
            responseType: 'json',
            auth
        });
        return result.data.collectibles;
    }

    // async getCollectibleInfo(tokenID) {
    //     const collectibleInfo = await axios({
    //         method: "get",
    //         url: `${constants.apiRoot}/contracts/collectibles/getCollectibleInfo`,
    //         params: {
    //             tokenID
    //         },
    //         responseType: 'json',
    //         auth
    //     });
    //
    //     return collectibleInfo.data.data;
    //
    // }
}

export default new Collectibles();
