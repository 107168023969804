import React, {Component, Fragment, useState, useEffect} from "react";
import {connect} from "react-redux";
import Parse from 'parse';
import {Loader} from "../../Components/Loader";
import CloseImg from '../../../assets/images/close--blue.svg'
import {amountInTIX, convertUinxTimeStampToDate, buyNowPlusFee} from "../../eth_client/helper";
import * as blockchainActions from "../../../modules/blockchain/blockchain.action";
import ethClient from "../../eth_client";
import axios from "axios";
import constants from "../../api/constant";
import {sendParseRequest} from "../../../utils/sendParseRequest";
import {Link} from "react-router-dom";


const auth = constants.auth;

const LotAuctionCard = ({
                          activeAuctions, projectID, getAuctionLisences, setShowTransfer,
                          setShowModal
                        }) => {

  const statusMessage = React.createRef();
  const [bid, setStateBid] = useState(0);
  const [usersBid, setUsersBid] = useState(false);
  const [maxBid, setMaxBid] = useState(0);
  const [fee, setFee] = useState(0.01);
  const [currentBid, setCurrentBid] = useState(10);
  const [waitForResponse, setWaitForResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [array, setArray] = useState([1, 2, 3]);
  const [selectedAuction, setSelectedAuction] = useState(null)
  const [auctionDate, setAuctionDate] = useState(null)
  const [activeWallet, setActiveWallet] = useState(null)
  const [maxBidMessage, setMaxBidMessage] = useState(null)
  const [licenseIndex, setLicenseIndex] = useState(null)
  const [animationCount, setAnimationCount] = useState(null)
  const [showList, setShowList] = useState(false)


  const id = '22';
  const auctionEndDate = '2021, 05, 20';
  const auctionStartPrice = 444;
  const bidPrice = 100;
  const auctionBuyNowPrice = 5000;

  const getAddress = async () => {
    const walletAddress = await ethClient.eth.getActiveAccountAddress();
    setActiveWallet(walletAddress)
  };

  useEffect(() => {
    setSelectedAuction(activeAuctions.licenses[0]);
    setLicenseIndex(0);

    getAddress()
    // console.log(selectedAuction.auctionDate)
  }, [activeAuctions])


  useEffect(() => {
    console.log(activeAuctions, selectedAuction, '----selectedAuction')
    // if (selectedAuction) {
    //   let year
    //     = +activeAuctions.auctionDate.getFullYear();
    //   let month
    //     = +activeAuctions.auctionDate.getMonth() + 1;
    //   let day
    //     = +activeAuctions.auctionDate.getDate();
    //   let hr
    //     = +activeAuctions.auctionDate.getHours();
    //   let minute
    //     = +activeAuctions.auctionDate.getMinutes();
    //   let changeTimeFormat = [year, month, day]
    //   changeTimeFormat.forEach((item, idx) => {
    //     if (item < 10) {
    //       changeTimeFormat[idx] = `0${item}`;
    //     }
    //   });
    //   let selectedMonth;
    //   let monthArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    //   monthArr.forEach((i, idx) => {
    //       if ((idx + 1) === month) {
    //         selectedMonth = i;
    //       }
    //     }
    //   );
    //
    //   const auctionDatePrepare = changeTimeFormat[2] + " " + selectedMonth + " " + changeTimeFormat[0] + " " + hr + ":" + minute;
    //   setAuctionDate(auctionDatePrepare)
    // }
  }, [selectedAuction]);


  const handleBid = (evt, type) => {
    const inputEl = evt.target;
    const value = inputEl.value.replace(/\D/g, "");
    if (type === 'bid') {

      if (+value > +activeAuctions.currentBid[0].currentBid) {
        setUsersBid(+value)
      } else {
        setUsersBid(false)
      }
      setStateBid(+value);
    } else if (type === 'maxBid') {
      setMaxBid(value);
    }

  };

  const saveUserBid = (userId) => {

    activeAuctions.licenses && activeAuctions.licenses.forEach((item)=> {
      let bidObj = {
        selectedAuction: item,
        usersBid: usersBid,
        activeWallet: activeWallet,
        userId: userId,
      };
      sendParseRequest('setBidforActiveAuction', bidObj)
        .then(res => {

          if (res) {
            getAuctionLisences(projectID);
            setWaitForResponse(false)
          }
          console.log(res, '---res--')

        }).catch((e) => console.log(e));
    })

  };

  const setBid = async () => {
    const userId = Parse.User.current().id;
    console.log(111, "--------------111")
console.log(maxBid, "--------------maxbid")
    if (maxBid) {
      console.log(11111, "--------------11111")
      let statusValue = false;
      const promise = await new Promise((resolve, reject) => {
        let maxBidObj = {
          selectedAuction: selectedAuction,
          maxBid: maxBid,
          activeWallet: activeWallet,
          userId: userId
        };
        console.log(maxBidObj, "--------------maxBidObj")
        // selectedAuction,maxBid, activeWallet, userId
        // sendParseRequest('setMaxBid', maxBidObj)
        //   .then(res => {
        //     if (res) {
        //       console.log(res, "--------------res")
        //       if (res.status) {
        //         saveUserBid(userId);
        //       } else {
        //         setMaxBidMessage(res);
        //         setMaxBid(null)
        //       }
        //       // statusValue = true
        //     }
        //     resolve(true)
        //
        //
        //   }).catch((e) => console.log(e));
        // statusValue && resolve(true);

      });

    } else {
      saveUserBid(userId);
      if (!Parse.User.current()) return false;


      // const {bid} = this.state;
      setWaitForResponse(true)
      // this.setState({waitForResponse: true});

      // Parse.Cloud.run('addBid', {userId: currUser.id, licenseId: lastAuction.id, bid},
      //   {
      //     success: ({currentBid}) => {
      //       this.setState({
      //         currentBid,
      //         bid: 0,
      //         waitForResponse: false
      //       });
      //       this.getStatusMessage(true);
      //     },
      //     error: () => this.getStatusMessage(false),
      //   });
    }
  };


  const buyNowBlockchain = async (address) => {
    let data = {"count": 1, "to": "0x017d72869aeed2f66223871839de1d673a85b165", "id": `0x${address}`}
    console.log(data, "--------------data")
    const result1 = await axios({
      method: 'post',
      url: `${constants.apiRoot}/contracts/fanbase/mint`,
      data,
      dataType: 'json',
      auth,

    });
    console.log(result1, '==================result 1')
  };

  const buyNow = async (selectedToken) => {
    console.log(activeWallet, "--------------activeWallet")
    console.log(selectedToken.licenseID, 'selectedTokenselectedTokenselectedTokenselectedToken')
    let data = {
      "tokenUrls": "zLdtgwKnih"
    };

    // console.log(data, "--------------data")
    console.log(selectedToken.projectId, "--------------selectedToken.projectId")
    const result2 = await axios({
      method: 'post',
      url: `${constants.apiRoot}/contracts/projects/getProjectsByTokenURIs `,
      responseType: 'json',
      auth,
      data
    });

    // console.log(result2.data.projects[0].properties.id, "--------------result2")
    // if (result2) {
    //   buyNowBlockchain(result2.data.projects[0].properties.id)
    // }


    // setBidforActiveAuction

    // const {lastAuction, mainWalletAddress} = this.props;
    // const price = lastAuction.auctionBuyNowPrice;
    // this.props.buyCollectibleNowSaga(mainWalletAddress, "password", lastAuction.id, lastAuction.auctionBuyNowPrice);
    // const currUser = Parse.User.current();

    // if (!currUser || !lastAuction.id) return false;

    // Parse.Cloud.run('buyNow', {licenseId: lastAuction.id, userId: currUser.id},
    //   {
    //     success: () => {
    //       alert('The license purchased');
    //     },
    //     error: () => {
    //     }
    //   });
  };


  const newBid = currentBid < 0 ? amountInTIX(auctionStartPrice) : currentBid;
  const buyItNowBtn = (selectedToken) => {
    return (
      newBid < auctionBuyNowPrice && (
        <Fragment>
          <span>or</span>

          <button className="do-bid__btn do-bid__btn--buyNow"
                  onClick={() => buyNow(selectedToken)}>
            Buy it now for {buyNowPlusFee(activeAuctions.priceBuyNow)} USDW
          </button>
        </Fragment>
      )
    );
  };
  const dateObj = convertUinxTimeStampToDate(auctionEndDate);


  return (

    <>
      {selectedAuction &&
      <div className="auction-card lot-auction-card lot-showlist-card">
        <img src={CloseImg} style={{
          position: 'absolute', top: '-30px', right: '0px', width: '50px', height: '50px',
          cursor: 'pointer'
        }}
             onClick={() => {
               setShowTransfer(false);
               setShowModal(false)
             }}
        />
        {showList &&
        <div className="card__details-btns_block" style={{position: 'absolute', bottom: '20px', width: '100%'}}>

          <button
            style={{width: '200px'}}
            className="btn btn_link"
            onClick={() => {
              setShowList(false);
            }}
          >
            BACK
          </button>

        </div>}

        {!showList ? <>
            <Loader showLoader={isLoading} style={{height: "100%", position: "static"}}>
              <div>
                <div className="auction-card__header">
                  <div className="card-header__title">
                    <div className='card-header__description' style={{textAlign: 'left', width: '100%'}}>
                      you are now bidding on...
                    </div>
                    <div className="auction-card__header__title justify-content__center tickersymbol-title"
                         style={{textAlign: 'left'}}>
                      LOT include:
                      {/*{selectedAuction.tickerSymbol}(#{selectedAuction.idOfLicense})*/}
                    </div>
                    <div style={{width: '100%', textAlign: 'left', display: 'flex', flexWrap: 'wrap'}}>

                      {activeAuctions && activeAuctions.licenses.map((item, idx) =>
                        idx <= 3 && (
                          <div key={idx} style={{marginLeft: '10px'}}>
                            {item.projectTitle}#{item.idOfLicense}
                          </div>)
                      )}
                      {activeAuctions.licenses.length > 2 && <>...
                        <button className='blue-button' style={{padding: '1px 4px', marginLeft: '10px'}}
                                onClick={() => setShowList(true)}>See
                          more</button></>}
                    </div>
                  </div>
                  <div className="auction-card__header__sub-title justify-content__center tickersymbol-title">
                    {/*{id} of {activeAuctions.length}*/}
                  </div>
                </div>

                <div className="auction-card__body">

                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <BodySector title={"Current bid"}
                                value={activeAuctions.currentBid[0].currentBid}
                                oppositeValue={"USDW"}/>
                    <div className="body-sector" style={{width: '48%'}}>
                      <div className="bid-header">
                        input max autobid
                      </div>
                      <div
                        className={`bid-value flex justify-content__space-between align-items__center ${(bid <= newBid) ? '' : 'border-green'}`}>
                        <input className="your-bid"
                               type='number'
                               placeholder={`50000`}
                               value={maxBid ? maxBid : ''}
                               onChange={(e) => handleBid(e, 'maxBid')}/>
                        <span>USDW</span>
                      </div>
                      {maxBidMessage && <p style={{'fontSize': '10px', 'color': 'red'}}>{maxBidMessage}</p>}
                    </div>
                  </div>


                  <div style={{display: 'flex', justifyContent: 'space-between'}}>

                    <div className="body-sector" style={{width: '48%'}}>
                      <div className="bid-header">
                        Your bid
                      </div>
                      <div
                        className={`bid-value flex justify-content__space-between align-items__center ${(bid <= newBid) ? '' : 'border-green'}`}>
                        <input className="your-bid"
                               type='number'
                               placeholder={`${activeAuctions.currentBid[0].currentBid + 1} or more`}
                               value={bid ? bid : ''}

                               onChange={(e) => handleBid(e, 'bid')}/>
                        <span>USDW</span>
                      </div>
                    </div>
                    {auctionDate && <BodySector title={"Auction closes"} value={auctionDate}/>}   </div>
                  {/*<BodySector title={"Item Id"} value={id}/>*/}


                </div>


                <div className="auction-card__btn-section">
                  <DiscountSector value={"Subtotal"} oppositeValue={bid}/>
                  <DiscountSector value={"Transaction Fee (1%)"} oppositeValue={bid * fee}/>
                  <DiscountSector value={"Total"} oppositeValue={bid + bid * fee}/>

                  {waitForResponse ?
                    <div className='image-spinner-frame'>
                      <div className="loader__blue">Loading...</div>
                    </div>
                    :
                    <Fragment>
                      <button className={usersBid ? "do-bid__btn" : "do-bid__btn disable-bid-btn"}
                        // disabled={usersBid}
                              onClick={setBid}>
                        Authorize Bid
                      </button>

                      {selectedAuction && buyItNowBtn(selectedAuction)}
                    </Fragment>
                  }
                </div>


                <p className="auctionCardStatusMessage"
                   ref={statusMessage}/>
              </div>

            </Loader></>

          :
          <>
            <div style={{height: '100%'}}>

              <div style={{maxHeight: '900px', overflow: 'auto'}}>
                <table style={{width: '100%'}} className='lot-list-table'>
                  <tr style={{width: '100%'}} className="lot-list-tr">
                    <td>Logo</td>
                    <td>Project</td>
                    <td>Ticker Symbol</td>
                    <td>License Id</td>

                  </tr>
                  {activeAuctions && activeAuctions.licenses.map((item, idx) =>
                    <tr key={idx} style={{width: '90%'}} className="lot-list-tr">
                      <td className='lot-list-logo ' style={{backgroundImage: `url("${item.logo}")`}}/>
                      <td><Link to={`/collectormarket/${item.projectId}`} target="_blank">{item.projectTitle}</Link>
                      </td>
                      <td>{item.tickerSymbol}</td>
                      <td>{item.idOfLicense}</td>

                    </tr>
                  )}


                </table>
              </div>


            </div>

          </>

        }

        {/*{licenseIndex+1<activeAuctions.length && <img src={Arrow} className='card_right-arrow' onClick={()=>  {*/}
        {/*  setNextLicense('+')*/}

        {/*}}/>}*/}
        {/*{licenseIndex!==0 && <img src={Arrow} className='card_right-left' onClick={()=>  {*/}
        {/*  setNextLicense('-')*/}

        {/*}}/>}*/}
      </div>
      }
    </>
  );

}

const BodySector = ({title, value, oppositeValue = ''}) => (
  <div className="body-sector" style={{width: '48%'}}>
    <div className="bid-header">
      {title}

    </div>
    <div className="bid-value flex justify-content__space-between align-items__center">
      <span>{value}</span>
      <span>{oppositeValue}</span>
    </div>
  </div>
);

const DiscountSector = ({value, oppositeValue = 0}) => (
  <div className="discount-description">
    <span className="pull-left title">{value}</span>
    <span className="pull-right title">{oppositeValue}</span>
    <div className="dashedVerticalLine"/>
  </div>
);

function mapStateToProps({blockchain}) {
  return {
    mainWalletAddress: blockchain.mainWalletAddress
  };
}

export default connect(
  mapStateToProps,
  {...blockchainActions}
)(LotAuctionCard);
