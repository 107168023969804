import React from 'react';
import notFound from '../../../assets/images/404-not-found.png';
import './PageNotFound.css';

const PageNotFound = () => {
    return (
        <div>

            <div className='error__wrapper'>
                <img className='errorImg' src={notFound} alt="not found"/>
                <p className='error-message'>The requested URL was not found on this server.</p>
            </div>
        </div>
    );
};

export default PageNotFound;
