import React from 'react';
import InfoTooltip from '../../Components/InfoTooltip';
import closeSvg from '../../../assets/images/close--blue.svg';

const SubmitContract = ({props, closeSubmit, createProject, createProReview}) => {
	return (
		<div className="slideshowModal popup_token-block">
			<button className="slideshowModalWrapper__closeButton slideshowModalButton">
				<img src={closeSvg} alt="close" onMouseDown={() => closeSubmit(false)} />
			</button>
			<div className=" popup-withdraw popup_tokens submit-wrap" style={{ overflow: 'auto' }} id="projectCreators">
				<h2>Your project:</h2>
				<div className="submit-container">

					<div className="submit-container__header">
						<div style={{"width": "30%", "margin-left": "10%",}}>
							{props.logoAttach.length > 0 ? (
								<img src={props.logoAttach[0].src} className="submit_avatar" alt="" />
							) : null}
						</div>

						<div className='submit-container__general-info'>
							{props.projectTitle && (
								<div className="submit-container__item-wrapper">
									<div className="submit-container__item-wrapper__title">Project Title:</div>
									<div className="submit-container__item-wrapper__value">{props.projectTitle}</div>
								</div>
							)}

							{props.tickerSymbol && (
								<div className="submit-container__item-wrapper">
									<div className="submit-container__item-wrapper__title">Ticker Symbol:</div>
									<div className="submit-container__item-wrapper__value">{props.tickerSymbol}</div>
								</div>
							)}

							{props.projectSubtitle && (
								<div className="submit-container__item-wrapper">
									<div className="submit-container__item-wrapper__title">Project Subtitle:</div>
									<div className="submit-container__item-wrapper__value">{props.projectSubtitle}</div>
								</div>
							)}
						</div>
					</div>

					{props.projectDescription && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Project Description:</div>
							<div className="submit-container__item-wrapper__value">{props.projectDescription}</div>
						</div>
					)}

					{props.stageName && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Stage Name:</div>
							<div className="submit-container__item-wrapper__value">{props.stageName}</div>
						</div>
					)}

					{props.projectPerks && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Project Perks:</div>
							<div className="submit-container__item-wrapper__value">{props.projectPerks}</div>
						</div>
					)}

					{props.projectWebsite[0].link && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Project Website:</div>
							<div className="submit-container__item-wrapper__value">
								{props.projectWebsite[0].link}
							</div>
						</div>
					)}

					{props.companyWebsite && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Company Website:</div>
							<div className="submit-container__item-wrapper__value">{props.companyWebsite}</div>
						</div>
					)}

					{props.licenseType && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">License Type:</div>
							<div className="submit-container__item-wrapper__value">{props.licenseType}</div>
						</div>
					)}

					{props.mainLanguage && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Main Language:</div>
							<div className="submit-container__item-wrapper__value">{props.mainLanguage}</div>
						</div>
					)}

					{props.ageRestrictions && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Age Restrictions:</div>
							<div className="submit-container__item-wrapper__value">{props.ageRestrictions}</div>
						</div>
					)}

					{
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Banners:</div>
						</div>
					}

					<div className="subnit_flex">
						{props.promotionalBanners.map((i, idx) => (
							<img key={i.src + idx} src={i.src} className="submit_banners" alt="" />
						))}
					</div>

					{props.additionalLanguage.map((i, idx) => (
						<div className="submit-container__item-wrapper" key={i + idx}>
							<div className="submit-container__item-wrapper__title">Languages:</div>
							<div className="submit-container__item-wrapper__value">{i}</div>
						</div>
					))}

					{props.insuranceFileName && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Insurance File Name:</div>
							<div className="submit-container__item-wrapper__value">{props.insuranceFileName}</div>
						</div>
					)}

					{props.attachFileName && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Attach File Name:</div>
							<div className="submit-container__item-wrapper__value">{props.attachFileName}</div>
						</div>
					)}

					{props.socialMedia && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Social Media:</div>
							<div className="submit-container__item-wrapper__value">
								{props.socialMedia[0].link}
							</div>
						</div>
					)}

					{props.videoPitch && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">videoPitch:</div>
							<div className="submit-container__item-wrapper__value">
								{props.videoPitch[0].videoLink}
							</div>
						</div>
					)}

					{props.videoPromo && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Video Promo:</div>
							<div className="submit-container__item-wrapper__value">
								{props.videoPromo[0].videoLink}
							</div>
						</div>
					)}

					{props.musicContentQuestion && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">
								Is there a music video included as part of the collictable?:
							</div>
							<div className="submit-container__item-wrapper__value">
								{props.musicContentQuestion}
							</div>
						</div>
					)}

					{props.ipPercentage === 'Unidentified' ? (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">None, this project is market-ready33</div>
						</div>
					) : (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__value">{props.ipPercentage}</div>
						</div>
					)}

					{
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Media Category:</div>
							<div className="submit-container__item-wrapper__value">{props.mediaCategory}</div>
						</div>
					}

					{
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Roles:</div>
							<div className="submit-container__item-wrapper__value">
								{props.roles.map((i, idx) => <span key={i + idx}>{i},</span>)}
							</div>
						</div>
					}

					{
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Release Classification:</div>
							<div className="submit-container__item-wrapper__value">{props.rookieRank}</div>
						</div>
					}

					{
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">
								Allow Buyers to Download Media Files:
							</div>
							<div className="submit-container__item-wrapper__value">{props.typeOfDownload}</div>
						</div>
					}

					{
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Num Of Licenses:</div>
							<div className="submit-container__item-wrapper__value">{props.numOfLicenses}</div>
						</div>
					}

					{props.licensesPercentageForCreator && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">
								Licenses percentage for creator:
							</div>
							<div className="submit-container__item-wrapper__value">
								{props.licensesPercentageForCreator}
							</div>
						</div>
					)}

					{props.additionalLicenseForUnlimited && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">
								Additional license for unlimited:
							</div>
							<div className="submit-container__item-wrapper__value">
								{props.additionalLicenseForUnlimited}
							</div>
						</div>
					)}

					{Object.keys(props.additionalData).length !== 0 && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Additional Data:</div>
							<div className="submit-container__item-wrapper__value">
								{Object.keys(props.additionalData).map((data) => {
									return (
										<p key={data} className="card-panel">
											{data}: {props.additionalData[data]}
										</p>
									);
								})}
							</div>
						</div>
					)}

					{props.auctionTime && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Time limit of auction:</div>
							<div className="submit-container__item-wrapper__value">{props.auctionTime}</div>
						</div>
					)}

					{props.DownloadDataForLimited && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Download Data For Limited:</div>
							<div className="submit-container__item-wrapper__value">
								{props.DownloadDataForLimited}
							</div>
						</div>
					)}

					{props.licensesPercentageForCreator && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">
								Licenses Creator is Entitled to Retain:
							</div>
							<div className="submit-container__item-wrapper__value">
								{props.licensesPercentageForCreator}
							</div>
						</div>
					)}

					{
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Number of Universal Licenses:</div>
							<div className="submit-container__item-wrapper__value">{props.numOfLicenses}</div>
						</div>
					}

					{props.additionalDataForUnlimited.unlimitedLicenseType && (
						<div className="submit-container__item-wrapper">
							<div className="submit-container__item-wrapper__title">Mass market:</div>
							<div className="submit-container__item-wrapper__value">
								{props.additionalDataForUnlimited.unlimitedLicenseType}
							</div>
						</div>
					)}
				</div>
				<div className="subnit_btns">
					<button type="button" className=" popup_tokens-btn finalBttn" onClick={createProject}>
						Mint!
					</button>
					<button type="button" className="popup_tokens-btn" onClick={()=>createProReview(true, createProject)}>
						Pro Review
						<InfoTooltip
							position="top"
							message="All crowdfunding projects should submit their project for Pro Review prior to minting. It is extremely rare that Fanbase would exhibit a project without prior review by our compliance team because most projects require registering with regulators (like the SEC). If you publish straight to the blockchain, your project will be minted on the Fanbase blockchain but not likely promoted on the site."
						>
							<i className="fa fa-info-circle" />
						</InfoTooltip>
					</button>
				</div>
			</div>
		</div>
	);
};

export default SubmitContract;
