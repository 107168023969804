import React, { useEffect, useState } from 'react';
import './walletPage.sass'
import FanWallyLogo from "../../../assets/images/fanwally_logo.png";


const WelcomeWallets = ({   selectedTypeOfWallet, setSelectedTypeOfWallet, walletFunc, setWalletfunc, setCreateWallet, setOpenImport, setWelcomeWalletPage, setCreatePasswordPage, setWalletType }) => {

  const [termsConfirmed, setTermsConfirmed] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);


  const moveToNextStep = () => {
    if (selectedOption === 'create') {
      setCreateWallet(true);
      setCreatePasswordPage(true)
    } else setOpenImport(true)
  };

  return (
    <>
      {!termsConfirmed ?
        <div className='wallyHome_container'>
          <div className='wallyHome_block'>
            {/* <span onClick={()=>setWelcomeWalletPage(true)}>Back</span> */}
            <img src={FanWallyLogo} alt="fanWally logo" />
            <h2>Welcome! Are you new to Fanbase?</h2>
            <p>Fanbase is a non-custodial wallet which lives on your device and connects you to the
              decentralized web.
              View your media, collectibles, certificates of authenticity, digital assets and digital
              rights
              all on Fanbase.</p>
            <div className='wallyHome_block-flex'>
              <div className='wallyHome_block-flex-column'>
                {/*<PlusOutlined style={{fontSize: '86px', color: '#08c'}}/>*/}
                <h4>Yes, I want to create a new wallet!</h4>
                <button className="blue-btn" onClick={() => {
                  setTermsConfirmed(true);
                  setSelectedOption('create')
                }}>Create a
                  Wallet
                </button>
              </div>
              <div className='wallyHome_block-flex-column'>
                {/*<DownloadOutlined style={{fontSize: '86px', color: '#08c'}}/>*/}
                <h4>No, I already have a wally phrase.</h4>
                <button className="blue-btn" onClick={() => {
                  setTermsConfirmed(true);
                  setSelectedOption('import')
                }
                }>Import Wallet
                </button>
              </div>

            </div>
          </div>
        </div>


        :
        <>
          {!selectedTypeOfWallet ?
            <div className='wallyHome_container'>
              <div style={{ 'display': "flex", "flexDirection": "column", 'width': '100%', height: '80%' }}>

                <div className='wallyHome_block' style={{ overflow: 'auto', 'padding': '30px 0', position: 'relative', minHeight: '40%' }}>
                  <span  style={{position: 'absolute', top: '20px', left: '20px'}}  onClick={() => setTermsConfirmed(false)}>&lsaquo; Back</span>
                  <img src={FanWallyLogo} alt="fanWally logo" />
                  <h2>Which wallet do you want to {selectedOption}?</h2>


                  <p>You can create two types of wallets on fanbase. First type (fanbase waller) needs for work with licenses and projects. The second type (eth) needs for work with eth-tokens, exchange and transfer coins . </p>

                  <div className='wallyHome_block-flex' style={{ "width": '350px' }}>
                    <button className="blue-btn" style={{ "width": '150px' }}
                      onClick={() => {
                        setCreateWallet(false);
                        setSelectedTypeOfWallet(true);
                        setWalletType('fanbase')
                      }}
                    >Fanbase wallet
                    </button>
                    <button className="blue-btn" style={{ "width": '150px' }} onClick={() => {
                      // moveToNextStep();
                      setWalletType('eth')
                      // setCreatePasswordPage(true);
                      setSelectedTypeOfWallet(true)
                    }}>Ethereum wallet
                    </button>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className='wallyHome_container'>
              <div style={{ 'display': "flex", "flexDirection": "column", 'width': '100%' }}>
                <div className='wallyHome_block' style={{ overflow: 'auto', 'padding': '30px 0', position: 'relative' }}>
                  <span style={{position: 'absolute', top: '20px', left: '20px'}} onClick={() => setSelectedTypeOfWallet(false)}>&lsaquo; Back</span>
                  <img src={FanWallyLogo} alt="fanWally logo" />
                  <h2>We need your help.</h2>
                  <p style={{ width: '90%' }}>Fanbase collects user data according to law and to improve the useability of the application.
                    You can...</p>
                  <ul style={{ marginTop: '10px', 'texAlign': 'left', 'width': '83%' }}>
                    <li>Opt-out via Settings.</li>
                    <li>View pages anonymously.</li>
                    <li>Review our decentralized principals.</li>
                    <li>Make suggestions on our mearsy.com board.</li>
                    <li>Engage with our chats regularly on mearsy.com.</li>
                  </ul>
                  <p>Fanbase aims to limit the collection of data to the minimum allowable by law. This includes addresses,
                    keys, amounts, hashes, transaction histories and personal information. We never sell data for profit. If
                    you believe we are collecting some data unnecessarily please write us on our mearsy.com board.</p>
                  <p style={{ marginTop: '10px' }}>To be clear, we must harmonise our data collection processes. For example, if we are required by law to have the email address of EU customers we will generally collect that data for all customers.</p>
                  <div className='wallyHome_block-flex' style={{ "width": '350px' }}>
                    <button className="blue-btn" style={{ "width": '150px' }}
                      onClick={() => setCreateWallet(false)}>I disagree
                    </button>
                    <button className="blue-btn" style={{ "width": '150px' }} onClick={() => {
                      moveToNextStep();
                      // setCreatePasswordPage(true);
                      setWelcomeWalletPage(false)
                    }}>I agree
                    </button>
                  </div>
                </div>
              </div>
            </div>

          }
        </>
      }
    </>
  );
};

export default WelcomeWallets;
