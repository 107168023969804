import React from "react";
import MyCollectibleItem from "../MyCollectiblesItem";

export default class MyCollectibles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      "totalLare": "0",
      "exchangeRate": "0.56085",
      "totalTimeLeft": "",
      activePage: 0,
      numberOfElementOnOnePage: 8,
      selectedProject: null,
      selectedToken: null,
      tokensArr: null
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    console.log(prevProps, "--------------prevProps", this.props, prevState, snapshot)
    if (this.props.tokens != prevProps.tokens) {
      console.log(true, this.props.tokens, "--------------true")
    }
    if (this.props.tokens.length > prevProps.tokens.length) {
      console.log(666, "--------------666", this.props.tokens)
      if (this.props.tokens.length > 0) {
        console.log(7777, "--------------7777", this.props.tokens)
        this.setState({tokensArr: this.props.tokens})
      }
      console.log(this.props, "--------------this.props22")
    }
  }


  prevLink = () => {
    const {activePage} = this.state;
    const {setActivePage} = this.props;
    const newActivePage = activePage - 1;

    if (setActivePage) {
      setActivePage(newActivePage);
    }

    this.setState({activePage: newActivePage});
  };

  nextLink = () => {
    const {activePage} = this.state;
    const {setActivePage} = this.props;

    if (setActivePage) {
      setActivePage(activePage + 1);
    }

    this.setState({activePage: activePage + 1});
  };

  currentPage = (page) => {
    const {setActivePage} = this.props;

    if (setActivePage) {
      setActivePage(page);
    }

    this.setState({activePage: page})
  };

  onSelectProject = (project) => {
    // console.log(1111111111,project, "--------------1111111111")
    this.props.onSelectProject(project);
    this.setState({selectedProject: project.id});
  };

  // onSelectToken = (token) => {
  //   this.props.onSelectToken(token);
  //   this.setState({selectedToken: token.id});
  // }

  render() {

    const {
      numberOfElementOnOnePage,
      activePage,
      selectedProject,
      selectedToken,
      tokensArr,
    } = this.state;

    const {tokens, setPortfolioTab} = this.props;
    console.log(tokens, "--------------token2222s")
    const projectsRequestResult = tokens !== undefined ? (
      <div className='image-spinner-frame'>
        <div className="loader__blue">Loading...</div>
      </div>
    ) : (
      <div className="processDataMessage">Buy collectibles to add to this wallet</div>
    );

    const projectsLength = tokens ? tokens.length : 0;
    const numberOfPages = Math.ceil(projectsLength / numberOfElementOnOnePage);

    return (
      <div className="my-collectibles-body">
        {
          projectsLength === 0 ?
            projectsRequestResult
            :
            <div>
              <div className="porfolioTable">
                {
                  tokens && tokens.map((item, index) => {
                    if (index >= numberOfElementOnOnePage * (activePage) && index < numberOfElementOnOnePage * (activePage + 1)) {
                      const firstSelected = index === 0;
                      const isSelected = selectedProject === item.id;

                      return <MyCollectibleItem
                          setPortfolioTab={setPortfolioTab}
                        handleSellAllLicenses={this.props.handleSellAllLicenses}
                        key={`porfolio-table-key-${index}`}
                        onSelectProject={this.onSelectProject}
                        isSelected={selectedProject ? isSelected : firstSelected}
                        index={index}
                        tokenURI={item.tokenURI}
                        project={item}
                      />
                    }
                  })
                }
              </div>
              <Paginate activePage={activePage}
                        prevLink={this.prevLink}
                        nextLink={this.nextLink}
                        setActivePage={this.currentPage}
                        numberOfPages={numberOfPages}/>
            </div>
        }
      </div>
    )
  }
}

const Paginate = ({activePage, prevLink, nextLink, numberOfPages, setActivePage}) => {
  if (numberOfPages < 2) return null;

  return (
    <div className="paginate">
      <div className="paginate-space">
        {	//link to previous page
          activePage >= 1 ?
            <div className="paginate-prev paginate-unit" onClick={() => prevLink()}>{'Previous'}</div>
            :
            <div className="disabled paginate-prev paginate-unit">{'Previous'}</div>
        }
        { //selecting a page
          [...Array(numberOfPages)].map((e, index) => {
            if (index >= activePage - 2 && index <= activePage + 2)
              return <div onClick={() => {
                setActivePage(index);
              }}
                          className={"paginate-unit " + (activePage === index ? "active" : "")}
                          key={index}>{index + 1}</div>
          }, this)}
        {	//link to next page
          activePage >= 0 && activePage + 1 !== numberOfPages ?
            <div className="paginate-next paginate-unit" onClick={() => nextLink()}>{'Next'}</div>
            :
            <div className="paginate-next paginate-unit disabled">{'Next'}</div>
        }
      </div>
    </div>
  );
};
