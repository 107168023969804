//Actual tendermint (WALLY) wallets server. Oct 2021

"use strict";
const helper = require("./helpers");
const GetGasPrices = helper.GetRecommendedGasPrices;

const IsERC1155TicketId = helper.IsERC1155TicketId;
const isCollectibleId = helper.isCollectibleId;

const erc721Factory = require("./erc721").erc721Factory('https://ropsten.infura.io/v3/a06e5e0ea4ea4d9587c31757413a6e4b'
);


const ticketsFactory = require("./tickets");

const erc1155Factory = require("./erc1155");

const swapTokens_Main_WallyFactory = require("./swaptokens_main_wally");


// const walletService = require("./cosmoswalletfuncs").tendermintWalletFactory('http://3.236.249.5:8081/')
const walletService = require("./cosmoswalletfuncs").tendermintWalletFactory('54.227.31.39:26657')
// http://3.236.143.194:26657
module.exports = {
  GetGasPrices,
  IsERC1155TicketId,
  isCollectibleId,
  erc721Factory,
  ticketsFactory,
  erc1155Factory,
  swapTokens_Main_WallyFactory,
  walletService
};
