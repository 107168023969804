import React, {useEffect, useState} from "react";
import {calculateIP} from "../../../eth_client/helper";

const CollectibleSummary = (props) => {
    const [state, setState] = useState({
        "milestone": "Auction Phase",
        "tier": "Open for Business",
        "bonus": "100",
        "totaltobeIssued": "unlimited",
        "rate": "1.00",
        "particapants": "Friends & Family",
        "issued": "1%",
        globalLicenses: 100,
        openedAuctions: false,
        stateDate: false,
        globalLength: null
    })

    const getAuctionsFunc = () => {
        // Parse.Cloud.run('getAuctionsInfo', { projectId:this.props.licenses[0].projectId, dateTill: new Date()},{
        //     success: result => {
        //         this.setState({
        //             openedAuctions: result.allAuctions,
        //             globalLength: result.globalLength
        //         })
        //     },
        //     error: err => {
        //         console.log('err', err);
        //     }
        // }).then(() => {
        //     let propsId;
        //     let stateDate;
        //     let arr = [];

        //     this.state.openedAuctions.map((i) => {
        //         for (let Exchange = 0; Exchange < 2; Exchange++) {
        //             propsId = (this.props.licenses[Exchange].projectId);
        //         }

        //         i.licenses.forEach((it) => {
        //             if (it.idProject === propsId) {
        //                 arr.push(
        //                     {
        //                         id: it.idProject,
        //                         dateTill: it.dateTill
        //                     }
        //                 )
        //             }
        //         })
        //     });

        //     for (let Exchange = 0; Exchange < 2; Exchange++) {
        //         let stateDatePrepair = (arr[0].dateTill).toISOString().split('T')[0].split('-');
        //         stateDate = stateDatePrepair[1] + (".") + stateDatePrepair[2] + (".") + stateDatePrepair[0]
        //     }

        //     this.setState({
        //         stateDate: stateDate,
        //     });
        // })
    };

    useEffect(()=>{

    })


    // componentDidMount() {
    //     this.getAuctionsFunc();

        // const self = this;
        // const GameScore = Parse.Object.extend("_User");
        // const query = new Parse.Query(GameScore);
        // query.count({
        //     success: (results) => {
        //         const usersTotal = results;
        //         const config = Parse.Object.extend("config");
        //         const query2 = new Parse.Query(config);
        //         query2.get("k7t0S4tWWZ", {
        //             success: (ob) => {
        //                 const data = ob.get('totalLare');
        //                 self.setState({"particapants": usersTotal, "issued": parseFloat(data).toFixed(5)})
        //             },
        //             error: (object, err) => {
        //                 if (err.code === 209) {
        //                     Parse.User.logOut().then(() => {
        //                         window.location.href = "/login";
        //window.location.reload();
        //                     });
        //                 } else {
        //                     console.log(err)
        //                 }
        //             }
        //         });
        //     },
        //     error: (err) => {
        //         if (err.code === 209) {
        //             Parse.User.logOut().then(() => {
        //                 window.location.href = "/login";
        //window.location.reload();
        //             });
        //         } else {
        //             console.log(err)
        //         }
        //     }
        // });
    // }


    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (prevProps !== this.props) {
    //         this.getAuctionsFunc();
    //         this.setState({
    //             stateDate: false,
    //             count: false
    //         })
    //     }
    // }


    const render = ()=>{
        const {globalLicenses, stateDate, globalLength} = this.state;
        const items = this.props.licenses || [
            {point: 1},
            {point: 12},
            {point: 15},
            {point: 28},
            {point: 35},
            {point: 45}];
        return (
            <div>
                <div className="tSWrap TokenSummaryWrapper">
                    <div className="innerTS">
                        <div className="upperTSDash">
                            <ul>
                                <li>
                                    <p className="title">Your Licenses</p>
                                    <p className="subtitle">
                                        {
                                            items.map((item, index) => {
                                                return index !== items.length - 1 ? item.auctionId + ', ' : item.auctionId;
                                            })
                                        }
                                    </p>
                                </li>
                                <li className="pull-right tobeIssued">
                                    <p className="title">Global Licenses</p>
                                    <p className="subtitle">{ globalLength && globalLength}</p>
                                </li>
                            </ul>
                        </div>
                        <div className="milestoneBarWrap">
                            <div className="milestonebar">
                                <div className="activeBar" style={{"width": calculateIP(items.length, globalLength)}}>
                                    <span className="milestoneTitle">{calculateIP(items.length, globalLength)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="upperTSDash lowerTSDash">
                            <ul style={{"textAlign": "center"}}>
                                <li className="pull-left">
                                    <p className="title">Recently Auctioned</p>
                                    <p className="subtitle">515 USDW</p>
                                </li>

                                <li>
                                    <p className="title">Next Auction</p>
                                    {stateDate ?
                                        (<p className="subtitle">{stateDate}</p>)
                                        :
                                        (<p className="subtitle">---</p>)}
                                </li>
                                <li className="pull-right">
                                    <p className="title">Owned</p>
                                    <p className="subtitle">{calculateIP(items.length, globalLicenses)}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return render();
}

export default CollectibleSummary;
