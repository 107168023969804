const configs = require("./configs");
const {
  IsObjectExists,
  valueDivPow10__18,
  valueMulPow10__18,
  ValidationArray,
} = require("./helpers");
const { ethers } = require("ethers");

export const erc1155Factory = (_networkEndPoint, _tokenAddress) => {
  const provider = new ethers.providers.JsonRpcProvider(_networkEndPoint);
  let TokenERC1155Contract = new ethers.Contract(
    _tokenAddress,
    configs.CONTRACT_ABI.ERC1155Token_ABI,
    provider
  );

  return {
    /**
     * @Description Get balance of user token
     * @param {string} walletAddress User wallet address
     * @param {string} id Identifier of ERC-1155 token
     * @returns {string}
     */
    getBalance: async (walletAddress, id) => {
      const balance = await TokenERC1155Contract.balanceOf(walletAddress, id);

      return IsObjectExists(balance)
        ? ethers.utils.formatUnits(balance, "ether")
        : "0";
    },

    /**
     * @Description Get Uniform Resource Identifier (URI) with custom JSON-object
     * @param {string} id Identifier of ERC-1155 token
       ]     * @returns {any}
     */
    getTokenUri: async (id) => {
      const uri = await TokenERC1155Contract.uri(id);

      if (IsObjectExists(uri)) {
        return uri;
      } else {
        return {};
      }
    },

    /**
     * Return structure of mined transaction
     * @typedef {Object} TransactionResult
     * @property {string} from Address from
     * @property {string} to Receiver address
     * @property {string} gasUsed blockchain sysinfo(never mind)
     * @property {number} blockNumber Number of current block
     * @property {string} blockHash Hash-Header of current block
     * @property {string} transactionHash Transaction hash
     * @property {string} contractAddress Contract address
     * @property {Array} logs Transaction logs
     */
    /**
     * Send value of token ERC-1155 from current account to receiver
     * @param {string} privateKey Wallet private key from Mnemonic
     * @param {string} addressFrom You selected account address
     * @param {string} toAddress Address Receiver of token
     * @param {string} tokenId Selected ERC-1155 token (uint256)
     * @param {string} value Amount of ERC-1155 token to be sent (uint256)
     * @param {number} gasPrice Selected gas price (Gwei)
     * @returns {Promise<TransactionResult>} Transaction object with fields (from, to, gasUsed, blockNumber, blockHash, transactionHash, contractAddress, logs)
     */
    safeTransferFrom: async (
      privateKey,
      addressFrom,
      toAddress,
      tokenId,
      value,
      gasPrice
    ) => {
      if (!IsObjectExists(addressFrom)) {
        throw new Error(`User wallet address is invalid!`);
      }
      if (!IsObjectExists(toAddress)) {
        throw new Error(`User-Receiver address is invalid!`);
      }
      const _data = "0x00";
      const wallet = new ethers.Wallet(privateKey, provider);

      const calcGasPrice = ethers.utils.parseUnits(`${gasPrice}`, "gwei");

      try {
        const tx = await TokenERC1155Contract.connect(wallet).safeTransferFrom(
          addressFrom,
          toAddress,
          tokenId,
          value,
          _data,
          {
            gasPrice: calcGasPrice.toHexString(),
          }
        );
        console.debug("not mined tx: ", tx);
        const _txResult = await tx.wait();
        if (IsObjectExists(_txResult)) {
          return {
            from: _txResult.from,
            to: _txResult.to,
            gasUsed: IsObjectExists(_txResult.gasUsed)
              ? _txResult.gasUsed.toString()
              : "",
            blockNumber: _txResult.blockNumber,
            blockHash: _txResult.blockHash,
            transactionHash: _txResult.transactionHash,
            contractAddress: IsObjectExists(_txResult.contractAddress)
              ? _txResult.contractAddress.toString()
              : "",
            logs: ValidationArray(_txResult.logs) == true ? _txResult.logs : [],
          };
        } else return {};
      } catch (e) {
        console.error(`${e.message}`);
      }
    },
  };
};
