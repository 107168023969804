import React, {useEffect, useState} from "react";
import {sendParseRequest} from "../../../../utils/sendParseRequest";
import Parse from "parse";
import {useHistory} from "react-router";

export const VerificationSummary = () => {
    const user = Parse.User.current();
    const history = useHistory();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const key = window.location.hash.split('/')[2];

        sendParseRequest('checkEmailVerified', {key})
            .then(() => setLoading(false))
            .catch(err => {
                setLoading(false)
                setError(err.message || err.error || 'error');
            })
    }, []);

    const redirect = () => {
        if (!user) {
            history.push('/login')
            window.location.reload();
        } else {
            history.push('/')
        }
    }

    return (
        <div className="loginScreen thankyouScreen"
             style={{minHeight: user ? 'calc(100% - 201px)' : 'calc(100% - 62px)'}}
        >
            {loading ?
                <div className="loadingScreen">
                    <div className="loading-spinner"/>
                </div>
                : <div className="screenWrapper">
                    <div className="wrapper-1">
                        <div className="wrapper-2">
                            {error === ''
                                ? <>
                                    <span className="thankyouMessage">Success!</span>
                                    <p>Your email address has been <b>verified</b>!</p>
                                </>
                                : <p>{error}</p>}
                            <button className="blue-button"
                                    onClick={redirect}
                                    style={{margin: "20px auto 0"}}
                            >
                                {!user ? 'Login to account' : 'Go to Home Page'}
                            </button>
                        </div>
                    </div>
                </div>}
        </div>
    )
}