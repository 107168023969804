import React, {Fragment} from 'react';

const DateInputs = ({inputDateHandler}) => {
  const handleDateInput = (evt) => {
    const currElem = evt.target;
    const date = new Date(currElem.value);
    const elementName = currElem.getAttribute('name');

    inputDateHandler({[elementName]: date});
  };

  return(
    <Fragment>
      <label>From:</label>

      <input type="date"
             name="dateFrom"
             onChange={handleDateInput}/>

      <label>To:</label>

      <input type="date"
             name="dateTo"
             onChange={handleDateInput}/>
    </Fragment>
  );
};

export default DateInputs;