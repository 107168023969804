import React from 'react';
import "./legalPages.css";

export function AML_CFT() {
    return (
        <div className="page">
            <div className="pageContent">
                <div className="mainHeaderTerms">
                    <p style={{"padding": "80px 0"}} className="mainTitle">
                        Anti-Money Laundering the Financing of Terrorism (AML/CFT) Policy
                    </p>
                </div>
                <br/><br/>
                <div className="row2">
                    <p className="paraDescr">The USDW AML Policy is designed to prevent money laundering, including
                        the
                        need to have adequate systems and controls in place to mitigate the risk of the firm being
                        used
                        to facilitate financial crime. This AML Policy sets out the minimum standards which must be
                        complied with and includes:</p>
                    <ul style={{"margin-left": "30px"}}>
                        <li className="paraDescrli">
                            The appointment of a Money Laundering Reporting Officer (MLRO) who has sufficient level
                            of
                            seniority and independence and who has responsibility for oversight of compliance with
                            relevant legislation, regulations, rules and industry guidance;
                        </li>
                        <br/>
                        <li className="paraDescrli">
                            Establishing and maintaining a Risk Based Approach (RBA) towards assessing and managing
                            the
                            money laundering and terrorist financing risks to the company;
                        </li>
                        <br/>
                        <li className="paraDescrli">
                            Establishing and maintaining risk based systems and procedures to monitor on-going
                            customer
                            activity;
                        </li>
                        <br/>
                        <li className="paraDescrli">
                            Procedures for reporting suspicious activity internally and to the relevant law
                            enforcement
                            authorities as appropriate;
                        </li>
                        <br/>
                        <li className="paraDescrli">
                            The maintenance of appropriate records for the minimum prescribed periods.
                        </li>
                        <br/>
                        <li className="paraDescrli">
                            Training and awareness for all relevant employees
                        </li>
                        <br/>
                    </ul>
                    <p className="paraTitle">Counter Financing of Terrorism (CFT)</p>
                    <p className="paraDescr">The Company takes a risk-based approach when adopting and implementing
                        counter financing of terrorism (CFT) measures and in conducting AML risk assessments.</p>
                    <p className="paraDescr">The company adopted internal CFT controls and make undefended decisions
                        regarding CFT matters supersedes any business, strategic or other operating task.</p>
                    <p className="paraTitle">International Sanctions Policy (ISP)</p>
                    <p className="paraDescr">Our company is prohibited from transacting with individuals, companies
                        and
                        countries that are on prescribed sanctions lists. </p>
                    <p className="paraTitle">Know Your Customer Procedures (KYC)</p>
                    <p className="paraDescr">Individuals can be identified by passport or other identification
                        document
                        and utility bills stating their current post address. Companies have to be identified by
                        extracts from the Chamber of Commerce or by notary deed. Copies have to be made and archived
                        in
                        files securely. </p>
                    <p className="paraTitle">Contacting Us</p>
                    <p className="paraDescr">If you have any questions about this Privacy Policy, the practices of
                        this
                        site, or your dealings with this site, please contact us at: admin@ifanbase.com</p>
                </div>
            </div>
        </div>
    )
}
